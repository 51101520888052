import React, { useState, useEffect } from 'react';

const Modal = (props) => {

  const [symbola, setsymbolA] = useState("");
  const [deca, setdecA] = useState("");
  const [owner, setowner] = useState("");
  const [time, settime] = useState(0);
  const [amount, setamount] = useState(0);

  useEffect(() => {
    getdata()
  }, []);
  async function getdata() {
    const ct = await props.Tokencontract(props.ctAbi, props.ct);
    const o = await ct.methods.ContractLock(props.Contract, 0).call()
    const data = await ct.methods.Locks(o, props.Contract).call()

    gettokendataA(props.Contract)
    setowner(o)
    setamount(data.amount)
    settime(data.endtime)
  }
 /* async function getdata() {
    const ct = await props.Tokencontract(props.ctAbi, props.ct);
    const o = await ct.methods.ContractLock(props.Contract, 0).call()
    const a = await ct.methods.Locks(o, props.Contract, 0).call()
    const t = await ct.methods.Locks(o, props.Contract, 1).call()
    gettokendataA(props.Contract)
    setamount(a)
    settime(t)
    setowner(o)
    //call(stakect.methods.ContractLock, getowner, props.Contract, 0);
  }*/

  async function gettokendataA(e) {
    const token = await props.Tokencontract(props.tkAbi, e);
    const a = await token.methods.symbol().call()
    const t = await token.methods.decimals().call()
    setsymbolA(a)
    setdecA(t)
  }

  function nextblock(accountBalancemctTB, d) {
    if ((accountBalancemctTB / (10 ** d)).toString().includes('.')) {
      if (accountBalancemctTB.toString().length >= d) {
        return (
          (accountBalancemctTB / (10 ** d)).toString().split('.')[0] +
          '.' +
          (accountBalancemctTB / (10 ** d)).toString().split('.')[1].slice(0, 2)
        );
      } else {
        return (
          '0.' +
          '0'.repeat(d - accountBalancemctTB.toString().length) +
          accountBalancemctTB.toString().slice(0, 2)
        );
      }
    } else {
      return accountBalancemctTB / (10 ** d);
    }
  }
  function getTimeRemaining(endtime) {
    const total = Date.parse(endtime) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    const days = Math.floor(total / (1000 * 60 * 60 * 24));
    if (total < 0) {

      return "EXPIRED";
    } else {
      return days + "D " + hours + "H "
        + minutes + "M " + seconds + "S"
    }

  }
  return (
    <div style={{
      height: "min-content",
      width: "100%",
      listStyleType: "none",
      display: amount > 0 ? "grid" : "none",
      padding: "10px 0px",
      margin: "auto",
      border: "1px solid #ffffff10",
      borderRadius: "10px",
      boxShadow: "1px 5px 5px 5px #16203120",
      color: "#fff",
      fontSize: "15px",
    }}>
      <div style={{
        height: "70px",
        width: "100%",
        display: "grid",

      }}>
        <div onClick={() => { window.location.href = "/lock/data/" + props.Contract + "?=0" }} style={{ cursor: "pointer", display: "grid", gridTemplateColumns: "50px auto auto auto auto", width: "100%", padding: "auto", margin: "auto" }}>
          <div style={{
            height: "70px",
            width: "100%",
            margin: "auto",
            background: "url('" + "https://bscscan.com/images/main/empty-token.png" + " ')  center / 40px no-repeat",
          }}>

          </div>

          <p style={{ textAlign: "left", height: "min-content", margin: "auto 0" }}>Contract Locked
            <br /> <span style={{ fontWeight: "bold" }}>{symbola}</span></p>
          <div style={{
            height: "min-content",
            width: "100%",
            margin: "auto",
          }}>
            <p style={{ textAlign: "center", height: "min-content" }}>Owner</p>
            <p style={{ fontWeight: "bold", textAlign: "center", height: "min-content" }}>{owner.slice(0, 4) + '...' + owner.slice(-4)}</p>
          </div>
          <div style={{
            height: "min-content",
            width: "100%",
            margin: "auto",
          }}>
            <p style={{ textAlign: "center", height: "min-content" }}>Locked Amount</p>
            <p style={{ fontWeight: "bold", textAlign: "center", height: "min-content" }}>{nextblock(amount, deca)}</p>
          </div>
          <div style={{
            height: "min-content",
            width: "100%",
            margin: "auto",
          }}>
            <p style={{ textAlign: "center", height: "min-content" }}>Finish in</p>
            <p style={{ fontWeight: "bold", textAlign: "center", height: "min-content" }}>{getTimeRemaining(new Date(time * 1000))}</p>
          </div>

        </div>

      </div>
    </div >
  );
}
export default Modal;