import React, { useState, useEffect, useContext } from 'react';
import '../../pages/farm.css';
import bnbabi from "../../abis/bnbabi.json";
import Stakemodal from "./modalmobile";
import stkfacabi from "../../abis/betfacabi.json";
import AppContext from '../../framework/helpers/AppContext';
const Web3 = require('web3-eth');

let w = "100%";



const Betpools = (props) => {

    const {
        account,
        provider,
        chain,
        chains,
        web3,
        Tokencontract,
        gasTX,
        aweb3,
        getID,
        callTX,
        sendTX,
        nSendTX,
        nSendCT
    } = useContext(AppContext)
    let pprovider = aweb3()
    let accounts = account;


    const [seconds, setSeconds] = useState(0);
    const [acc, setacc] = useState(0);
    const [list, setlist] = useState([]);
    useEffect(() => {
        //document.title = "MARTIK | PLAY TO WIN"
        loadlist()
    }, []);
    useEffect(() => {
        let interval = null;
        if (acc == accounts) {

        } else {
            interval = setInterval(() => {
                loadlist()
                setacc(accounts)
                setSeconds(seconds => seconds + 1)
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [seconds]);

    async function loadlist() {
        const stakefactory = await new web3.Contract(stkfacabi, '0xbbed36Ee908D2DeB7D16065aC9260Bd4E8Cd0958');
        const length = await stakefactory.methods.poolsview().call()
        const times = await stakefactory.methods.poolstimesv().call()
        let cu = []
        let tlist = []
        let titles = [
            "Price will be higher or lower than $35?",
            "Price will be higher or lower than $25?",
            "Price will be higher or lower than $20?",
            "Internacional x flamengo",
        ]
        for (var i = 0; i < length.length; i++) {
            if (cu.includes(i)) {
            } else {
                let obj = {
                    times: times[i],
                    pos: i,
                    title: titles[i],
                    cts: '0xbbed36Ee908D2DeB7D16065aC9260Bd4E8Cd0958',
                }
                tlist.push(obj)
            }

        };
        setlist(tlist)

    }

    let stakeh = <div style={{ width: "100%", margin: "auto", maxWidth: "1920px", display: "grid", padding: "2vh" }}>
        <div style={{
            height: "min-content",
            width: "100%",

            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(40vh, 1fr)",
            gap: "10px",
            borderRadius: "10px",
            marginTop: "auto",
            color: "#fff",

        }}>
            {list.map((e) => <Stakemodal title={e.title} pos={e.pos} heighti={"5vh"} bgcolor={"#ffffff20"} buttoncolor={"#ffffff20"} array={[pprovider, e.cts, stkfacabi, "0x116526135380E28836C6080f1997645d5A807FAE", bnbabi, accounts]} />)}

        </div>

        <p style={{ height: "10vh" }}></p>
    </div>

    function nextblock(accountBalancemctTB, d) {
        if ((accountBalancemctTB / (10 ** d)).toString().includes('.')) {
            if (accountBalancemctTB.toString().length >= d) {
                return (
                    (accountBalancemctTB / (10 ** d)).toString().split('.')[0] +
                    '.' +
                    (accountBalancemctTB / (10 ** d)).toString().split('.')[1].slice(0, 2)
                );
            } else {
                return (
                    '0.' +
                    '0'.repeat(d - accountBalancemctTB.toString().length) +
                    accountBalancemctTB.toString().slice(0, 2)
                );
            }
        } else {
            return accountBalancemctTB / (10 ** d);
        }
    }
    return (
        <div className="farms" >
            <div style={{
                height: "min-content",
                width: "95%",
                margin: "auto",
                display: "grid", gridTemplateColumns: "auto",
                borderRadius: "10px",
            }}>
                <div onClick={() => { }} style={{
                    display: "grid", gridTemplateColumns: "6vh auto", height: "min-content", width: "100%", border: "1px solid #FFFF0040", margin: "auto",
                    background: "#FFFF0010", borderRadius: "2px", color: "#fff"
                }}>

                    <svg style={{
                        height: "2vh",
                        width: "2vh",
                        margin: "auto",
                    }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-triangle" viewBox="0 0 16 16">
                        <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z" />
                        <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z" />
                    </svg>
                    <p style={{ fontSize: "1.5vh", textAlign: "left", padding: "2vh 0", height: "min-content", margin: "auto" }}>
                        IN A DRAW EVERYONE LOSES |
                        BET FEE {nextblock(2000000000000000, 18)} BNB |
                        BEWARE OF TOKENS FEES |
                        BET WITH RESPONSIBILITY!!</p>
                </div>
            </div>
            {stakeh}
        </div>
    );
}

export default Betpools;