import React, { useState, useEffect } from 'react';

const Modalwallet = (props) => {
    return (
        <div style={{ color: "#222", height: "100%", width: "100%", position: "fixed", display: "grid", background: "linear-gradient(to bottom right, #ffffff20, #ffffff20)", top: "0", left: "0", zIndex: "10000" }} onClick={() => { props.updateModals('wallet') }}>

            <div style={{ height: "min-content", width: "auto", display: "grid", gridTemplateRows: "50px 70px 70px 70px 70px", margin: "auto", width: "280px", height: "min-content", background: "linear-gradient(to bottom right, #fff, #fff)", boxShadow: "-5px 10px 10px 10px #00000020", borderRadius: "10px", border: "1px solid #ffffff40", paddingBottom: "10px" }}>
                <p style={{ margin: "auto", textAlign: "left", fontSize: "18px", fontWeight: "bold" }}>CONNECT WALLET</p>
                <div onClick={() => {
                    props.connecttometamask();
                    props.updateModals('wallet')
                }} style={{ height: "min-content", width: "90%", display: "grid", gridTemplateColumns: "60px auto", margin: "auto", border: "1px solid #00000020", borderRadius: "10px", cursor: "pointer" }}>
                    <div style={{ height: "60px", width: "60px", background: "url('https://cdn.iconscout.com/icon/free/png-256/metamask-2728406-2261817.png')", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "70%" }}
                    >
                    </div>
                    <p style={{ margin: "auto", textAlign: "left" }}>METAMASK</p>
                </div>
                <div onClick={() => {
                    props.connecttometamask();
                    props.updateModals('wallet')
                }} style={{ height: "min-content", width: "90%", display: "grid", gridTemplateColumns: "60px auto", margin: "auto", border: "1px solid #00000020", borderRadius: "10px", cursor: "pointer" }}>
                    <div style={{ height: "60px", width: "60px", background: "url('https://trustwallet.com/assets/images/media/assets/trust_platform.svg", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "80%" }}
                    >

                    </div>
                    <p style={{ margin: "auto", textAlign: "left" }}>TRUST WALLET</p>
                </div>
                <div onClick={() => {
                    props.connecttowalletconnect();
                    props.updateModals('wallet')
                }} style={{ height: "min-content", width: "90%", display: "grid", gridTemplateColumns: "60px auto", margin: "auto", border: "1px solid #00000020", borderRadius: "10px", cursor: "pointer" }}>
                    <div style={{ height: "60px", width: "60px", background: "url('https://seeklogo.com/images/W/walletconnect-logo-EE83B50C97-seeklogo.com.png')", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "80%" }}>
                    </div>
                    <p style={{ margin: "auto", textAlign: "left" }}>WALLET CONNECT</p>
                </div>
                <div onClick={() => {
                    props.Disconnect()
                    setTimeout(window.location.reload(), 2000);
                }} style={{ height: "60px", width: "90%", display: "grid", gridTemplateColumns: "60px auto", margin: "auto", border: "1px solid #00000020", borderRadius: "10px", cursor: "pointer" }}>
                    <svg style={{ height: "18px", width: "18px", margin: "auto" }}
                        xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#222" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z" />
                        <path fillRule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z" />
                    </svg>
                    <p style={{ margin: "auto", textAlign: "left" }}>DISCONNECT</p>
                </div>
            </div>
        </div>
    );
}

export default Modalwallet;