import React, { useState, useEffect } from 'react';

const EditorNft = (props) => {
    const {
        getdatacapa,
        ccap,
        getdatalogo,
        cava,
        getdatanm,
        cnm,
        getdatadesc,
        cdesc,
        list,
        MODAs,
        setcnm,
        setcdesc,
        formHandlerPI,
        formHandlerPB,
        uploadFilespf,
        nextblock,
        poolfee
      } = props.data

    return (
        <>
            {getdatacapa(ccap)}
            <div style={{
                height: "min-content",
                width: "100%",
                display: "grid",

                borderBottom: "1px solid #ffffff20",
                marginTop: "auto",
                color: "#fff",
            }}>
                <div style={{ display: "grid", gridTemplateColumns: "min-content auto", height: "80px", width: "100%", padding: "auto" }}>
                    <i style={{
                        height: " 70px",
                        width: " 70px",
                        margin: "auto",
                        background: 'url(' + getdatalogo(cava) + ') center center / 70px no-repeat',

                        borderRadius: "360px",
                    }}>
                    </i>

                    <p style={{ textAlign: "left", fontWeight: "bold", height: "min-content", margin: "auto", marginLeft: "18px", fontSize: "18px" }}>{getdatanm(cnm)}
                        <br /> <span style={{ fontSize: "15px", color: "#f5f5f5", }}>{getdatadesc(cdesc)}</span></p>

                </div>

            </div>
            <div style={{
                height: "min-content",

                width: "100%",
                display: "grid",
                gridTemplateRows: "auto",
                margin: "auto",
                marginTop: "10px",
                marginBottom: "10px",
                background: "#1e1f26",
                borderRadius: "2px",
                border: "1px solid #1e1f26",
                color: "#fff"
            }}>
                <div style={{
                    height: "min-height",
                    width: "100%",
                    display: "grid",
                    margin: "auto",

                    gap: "10px",
                }}>


                    <div style={{
                        height: "min-content",
                        width: "90%",
                        margin: "auto",
                        display: "grid",
                        gap: "10px"
                    }}>
                        <p style={{
                            textAlign: "justify",
                            fontSize: "15px",
                            color: "#e5e5e5"
                        }}><br /><span style={{
                            textAlign: "center",
                            fontSize: "25px",
                            fontWeight: "bold",
                            color: "#fff"
                        }}>PROFILE EDITOR
                                <br /></span>


                        </p>
                        <br />
                        <div style={{ height: "min-content", width: "100%", display: "grid", gridTemplateColumns: "auto", margin: "auto" }}>
                            <p style={{ margin: "auto", marginLeft: "0%", textAlign: "left", color: "#fff", fontSize: "12px" }}>Title</p>
                            <input onChange={(e) => { setcnm(e.target.value) }} placeholder="Title" style={{ fontSize: "15px", outline: "none", color: "#fff", textAlign: "left", height: "35px", width: "100%", display: "grid", gridTemplateColumns: "auto", margin: "auto", border: "1px solid #ffffff20", background: "#00000050", borderRadius: "2px", }}></input>
                        </div>
                        <div style={{ height: "min-content", width: "100%", display: "grid", gridTemplateColumns: "auto", margin: "auto", borderRadius: "10px", cursor: "pointer" }}>
                            <p style={{ margin: "auto", marginLeft: "0%", textAlign: "left", color: "#fff", fontSize: "12px" }}>Description</p>
                            <input onChange={(e) => { setcdesc(e.target.value) }} placeholder="Description" style={{ lineBreak: "normal", fontSize: "15px", outline: "none", color: "#fff", height: "min-content", minHeight: "70px", width: "100%", margin: "auto", border: "1px solid #ffffff20", background: "#00000050", borderRadius: "2px", }}></input>
                        </div>
                        <div style={{ height: "min-content", width: "100%", display: "grid", gridTemplateColumns: "auto auto", margin: "auto", borderRadius: "10px", cursor: "pointer" }}>
                            <div style={{ padding: " 0px", height: "100%", width: "100%", display: "grid", gridTemplateRows: "min-content " }}>

                                <label for="imagehubPI" style={{ height: "35px", width: "100%", display: "grid", gridTemplateColumns: "50px auto", margin: "auto", border: "1px solid #ffffff20", borderRadius: "10px", background: "#0f172a00", cursor: "pointer", margin: "auto" }}>
                                    <svg style={{ margin: "auto", height: "18px" }} xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-image" viewBox="0 0 16 16">
                                        <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                        <path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1h12z" />
                                    </svg>
                                    <p style={{ margin: "auto", textAlign: "left", color: "#fff", fontSize: "15px", display: "flex", gap: "2px" }}>SELECT PROFILE IMAGE</p>
                                </label>
                                <input onChange={formHandlerPI} accept="image/*" multiple="false" type="file" id={"imagehubPI"} className='input' style={{ height: "100%", width: "90%", margin: "auto", zIndex: "-1" }} />
                            </div>
                            <div style={{ padding: " 0px", height: "100%", width: "100%", display: "grid", gridTemplateRows: "min-content" }}>

                                <label for="imagehubPB" style={{ height: "35px", width: "100%", display: "grid", gridTemplateColumns: "50px auto", margin: "auto", border: "1px solid #0f172a", borderRadius: "10px", background: "#0f172a00", cursor: "pointer", margin: "auto" }}>
                                    <svg style={{ margin: "auto", height: "18px" }} xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-image" viewBox="0 0 16 16">
                                        <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                        <path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1h12z" />
                                    </svg>
                                    <p style={{ margin: "auto", textAlign: "left", color: "#fff", fontSize: "15px", display: "flex", gap: "2px" }}>SELECT PROFILE BANNER</p>
                                </label>
                                <input onChange={formHandlerPB} accept="image/*" multiple="false" type="file" id={"imagehubPB"} className='input' style={{ height: "100%", width: "90%", margin: "auto", zIndex: "-1" }} />
                            </div>
                        </div>

                        <button onClick={() => { uploadFilespf(cava, ccap) }} style={{
                            cursor: "pointer", display: "grid", gridTemplateColumns: "auto", height: "35px", width: "auto", border: "1px solid #ffffff20",
                            background: "#ffffff20", borderRadius: "2px", color: "#fff"
                        }}>
                            <p style={{ fontSize: "15px", textAlign: "left", height: "min-content", margin: "auto" }}><span style={{ textAlign: "left", fontWeight: "bold", fontSize: "15px" }}>SAVE CHANGES</span></p>
                        </button>
                        <p style={{
                            textAlign: "justify",
                            fontSize: "15px",
                            color: "#e5e5e5"
                        }}>
                            EDIT FEE {nextblock(poolfee, 18)} (BNB)
                            For now we do not support (.gif, .webm) or any animated images
                        </p>
                    </div>

                </div>
            </div>
        </>

    );


}
export default EditorNft;