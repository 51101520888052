import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import bnbabi from "../../../abis/bnbabi.json";
import Numberinput from "../../../components/Launchpad/numberInput";
import SAFE from "../../../components/Launchpad/textpar";
import InputBlock from "../../../components/Launchpad/InputBlock";
import DescBlock from "../../../components/Launchpad/descBlock";
import Button from "../../../components/Launchpad/Button";
import Check from "../../../components/Launchpad/Checkbox";
import Checklist from "../../../components/Launchpad/ListCheck";
import StepBar from "../../../components/Launchpad/Stepbar";
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import enUS from 'date-fns/locale/en-US';
import "react-datepicker/dist/react-datepicker.css";
import abi from "../../../abis/lock/lock.json";
import env from '../../../hooks/data/Config';
const Web3 = require('web3-eth');
registerLocale('en-US', enUS)
const Transfer = (props) => {

    const {
        account,
        provider,
        chain,
        chains,
        web3,
        Tokencontract,
        gasTX,
        getID,
        callTX,
        sendTX,
        nSendTX,
        nSendCT
    } = props.interact
    const [_poolfee, setpoofee] = useState(0);
    const [contract, setcontract] = useState(env.CONTRACTS[getID()].lockContract);
    const [nOwner, setNowner] = useState("");

    useEffect(() => {
        //document.title = "MARTIK | LOCK TRANSFER"
    }, []);

    async function _transfer() {
        nSendTX(contract, abi, getID(), 'transferLock', '0',0,
            props.ct, nOwner
        ).then(a => {
            //a.hash
            //console.log(a)
        }).catch(e => {
            //console.log(e)
        })
    }

    return (
        <div style={{ height: "min-content", padding: "20px 10px", width: "100%", border: "1px solid #ffffff10", borderRadius: "10px" }}>
            <div style={{ display: "grid", gap: "10px" }}>
                <InputBlock title={"New Owner *"} place={""} variavel={nOwner} setvariavel={setNowner} desc={""} />


                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Button func={() => {
                        _transfer()
                    }} text={"TRANSFER"} next={true} />

                </div>

            </div>
        </div>
    );


}
export default Transfer;