import React, { useState, useEffect, useRef } from 'react';
import ProjectData from '../config';
import Wlist from "./whitepapers"
let projectName = "Martik"
const {
  Name,
  Logo,
  backgroundColor,
  buttonsColor,
  buttonsBorders,
  inputsColor,
  inputsBorders,
  outbuttonsColor,
  Borders,
  outFont,
  innerFont,
  Routers
} = ProjectData[window.location.host.split('.')[0] != undefined 
? window.location.host.split('.')[0] == "app" 
? window.location.host.split('.')[0] + "." + window.location.host.split('.')[1] 
: window.location.host.split('.')[0] : "Martik"]
//


const Whitepaper = () => {

  useEffect(() => {
    //  document.title = "BFT | Whitepaper"
    // getQuery('chain') == null ? 'BSC' : getQuery('chain')].pink)
    /*if (getQuery('chain') == null) {
      window.location.href = "/?chain=ETH"
    }*/
  }, []);
  if(Name == "dgiroto"){window.location.href = "https://dgiroto-professional.gitbook.io/untitled/"}


  const _containerStyle = {
    height: "min-content",

    width: "95%",
    gap: "10px",
    //maxWidth: "1280px",
    display: "grid",
    gridTemplateColumns: 'repeat(auto-fit, minmax(280px, 1fr))',
    color:outFont,
    borderRadius: "2px",
    margin: "auto",

  };

  const contentContainerStyle = {
    height: "min-content",
    width: "100%",
    display: "grid",
    margin: "auto",
    gap: "10px",

  };

  const headingStyle = {
    textAlign: "left",
    fontSize: "25px",
    fontWeight: "bold",
    margin: "auto",

  };

  const descriptionStyle = {
    textAlign: "left",
    fontSize: "16px",

    height: "100%",
    width: "95%",
    minWidth: "280px",
    margin: "0 auto"
  };


  const Content0 = (props) => {
    const [isIntersecting, setIsIntersecting] = useState(false);

    const ref = useRef(null);

    useEffect(() => {

      const observer = new IntersectionObserver(([entry]) => {
        setIsIntersecting(entry.isIntersecting);
      });

      observer.observe(ref.current);
      return () => observer.disconnect();
    }, [isIntersecting]);

    useEffect(() => {
      if (isIntersecting) {
        ref.current.querySelectorAll("div").forEach((el) => {
          el.classList.add("slide-in");
        });
      }
    }, [isIntersecting]);
    return (
      <div ref={ref} style={{
        height: "min-content",

        width: "100%",
        margin: "auto",
        display: 'grid'
      }}>
        <div style={{
          maxWidth: "1280px",

        }}>

          <div style={_containerStyle}>
            <div style={contentContainerStyle}>
              <div>
                <p className='gradient' style={headingStyle}>{props.topico}</p>
                <span style={descriptionStyle}>

                  {props.desc}
                </span>

              </div>
            </div>

          </div>
        </div>
      </div>
    )
  }
  return (
    <div style={{ display: "grid", gap: "10px" }}>
      {Wlist[window.location.host.split('.')[0]].map((e, i) =>
        <Content0
          topico={e.title}
          desc={
            e.content
          }
        />
      )}

    </div>
  );

}
export default Whitepaper;