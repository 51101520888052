import React, { useState, useEffect } from 'react';

const CreateNft = (props) => {
    const {
        imageuri,
        formHandler,
        setN,
        setD,
        setP,
        setL,
        setwr,
        wr,
        setctr,
        setAPRC,
        nextblock,
        poolfee,
        imagefile,
        uploadFiles
      } = props.data

    return (
        <div style={{
            height: "min-content",
            width: "100%",
            display: "grid",
            gridTemplateRows: "auto",
            margin: "auto",
            marginTop: "10px",
            marginBottom: "10px",
            background: "#1e1f26",
            borderRadius: "2px",
            border: "1px solid #1e1f26",
            color: "#fff"
        }}>
            <div style={{
                height: "min-height",
                width: "100%",
                display: "grid",
                margin: "auto",
                gridTemplateColumns: "repeat(auto-fit, minmax(260px, 1fr)",
                gap: "10px",
            }}>
                <div style={{
                    height: "100%",
                    width: "90%",
                    color: "#fff",
                    margin: "auto",

                    display: "grid",
                    borderRadius: "10px",

                }}>
                    <p style={{
                        textAlign: "justify",
                        fontSize: "15px",
                        color: "#e5e5e5"
                    }}><br /><span style={{
                        textAlign: "center",
                        fontSize: "25px",
                        fontWeight: "bold",
                        color: "#fff"
                    }}>CREATE NFT
                            <br /></span>
                        Create, publish and sell NFTs

                    </p>
                    <div style={{ padding: "10px 0px", height: "100%", width: "100%", display: "grid", gridTemplateRows: "min-content 35px" }}>
                        <div style={{ height: "280px", width: "280px", margin: "auto", background: "url(" + imageuri + ") center center / 80% no-repeat" }}>
                        </div>
                        <label for="imagehub" style={{ height: "35px", width: "80%", display: "grid", gridTemplateColumns: "50px auto", margin: "auto", border: "1px solid #ffffff20", borderRadius: "2px", background: "#ffffff20", cursor: "pointer", margin: "auto" }}>
                            <svg style={{ margin: "auto", height: "18px" }} xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-image" viewBox="0 0 16 16">
                                <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                <path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1h12z" />
                            </svg>
                            <p style={{ margin: "auto", textAlign: "left", color: "#fff", fontSize: "15px", display: "flex", gap: "2px" }}>SELECT IMAGE</p>
                        </label>
                        <input onChange={formHandler} accept="image/*" multiple="false" type="file" id={"imagehub"} className='input' style={{ height: "100%", width: "90%", margin: "auto", zIndex: "-1" }} />
                    </div>
                </div>

                <div style={{
                    height: "min-content",
                    width: "90%",
                    margin: "auto",
                    display: "grid",
                    gap: "10px"
                }}>


                    <br />
                    <div style={{ height: "min-content", width: "100%", display: "grid", gridTemplateColumns: "auto", margin: "auto" }}>
                        <p style={{ margin: "auto", marginLeft: "0%", textAlign: "left", color: "#fff", fontSize: "12px" }}>Title</p>
                        <input onChange={(e) => { setN(e.target.value) }} placeholder="Title" style={{ fontSize: "15px", outline: "none", color: "#fff", textAlign: "left", height: "35px", width: "100%", display: "grid", gridTemplateColumns: "auto", margin: "auto", border: "1px solid #ffffff20", background: "#00000050", borderRadius: "2px", }}></input>
                    </div>
                    <div style={{ height: "min-content", width: "100%", display: "grid", gridTemplateColumns: "auto", margin: "auto", borderRadius: "10px", cursor: "pointer" }}>
                        <p style={{ margin: "auto", marginLeft: "0%", textAlign: "left", color: "#fff", fontSize: "12px" }}>Description</p>
                        <input onChange={(e) => { setD(e.target.value) }} placeholder="Description" style={{ lineBreak: "normal", fontSize: "15px", outline: "none", color: "#fff", height: "min-content", minHeight: "70px", width: "100%", margin: "auto", border: "1px solid #ffffff20", background: "#00000050", borderRadius: "2px", }}></input>
                    </div>
                    <div style={{ height: "min-content", width: "100%", display: "grid", gap: "5px", gridTemplateColumns: "auto auto", margin: "auto", borderRadius: "10px", cursor: "pointer" }}>
                        <div style={{ height: "min-content", width: "100%", display: "grid", gridTemplateColumns: "auto", margin: "auto", borderRadius: "10px", cursor: "pointer" }}>
                            <p style={{ margin: "auto", marginLeft: "0%", textAlign: "left", color: "#fff", fontSize: "12px" }}>Price (USD) (min 1)</p>
                            <input type={"number"} onChange={(e) => { setP(e.target.value * 10) }} min={1} placeholder="PRICE (USD)" style={{ fontSize: "15px", outline: "none", color: "#fff", textAlign: "center", height: "35px", width: "100%", display: "grid", gridTemplateColumns: "auto", margin: "auto", border: "1px solid #ffffff20", background: "#00000050", borderRadius: "2px", }}></input>
                        </div>
                        <div style={{ height: "min-content", width: "100%", display: "grid", gridTemplateColumns: "auto", margin: "auto", borderRadius: "10px", cursor: "pointer" }}>
                            <p style={{ margin: "auto", marginLeft: "0%", textAlign: "left", color: "#fff", fontSize: "12px" }}>Limit</p>
                            <input type={"number"} onChange={(e) => { setL(e.target.value) }} min={0} placeholder="LIMIT" style={{ fontSize: "15px", outline: "none", color: "#fff", textAlign: "center", height: "35px", width: "100%", display: "grid", gridTemplateColumns: "auto", margin: "auto", border: "1px solid #ffffff20", background: "#00000050", borderRadius: "2px", }}></input>
                        </div>
                    </div>

                    <div style={{ height: "min-content", width: "100%", display: "grid", gridTemplateColumns: "min-content auto ", margin: "auto" }}>
                        <label class="switch">
                            <input type="checkbox" onChange={(e) => { setwr(!wr) }} />
                            <span class="slider round"></span>
                        </label>
                        <p style={{ margin: "auto", textAlign: "left", color: "#fff", fontSize: "15px", fontWeight: "bold" }}>WITH REWARD SYSTEM</p>
                    </div>
                    {wr && <div style={{ height: "min-content", width: "100%", display: "grid", gap: "5px", gridTemplateColumns: "auto auto", margin: "auto", borderRadius: "10px", cursor: "pointer" }}>
                        <div style={{ height: "min-content", width: "100%", display: "grid", gridTemplateColumns: "auto", margin: "auto" }}>
                            <p style={{ margin: "auto", marginLeft: "0%", textAlign: "left", color: "#fff", fontSize: "12px" }}>Token contract to reward</p>
                            <input onChange={(e) => { setctr(e.target.value) }} placeholder="Contract: 0x000..." style={{ fontSize: "15px", outline: "none", color: "#fff", textAlign: "left", height: "35px", width: "100%", display: "grid", gridTemplateColumns: "auto", margin: "auto", border: "1px solid #0f172a", background: "#00000050", borderRadius: "2px", }}></input>
                        </div>
                        <div style={{ height: "min-content", width: "100%", display: "grid", gridTemplateColumns: "auto", margin: "auto" }}>
                            <p style={{ margin: "auto", marginLeft: "0%", textAlign: "left", color: "#fff", fontSize: "12px" }}>APY</p>
                            <input onChange={(e) => { setAPRC(e.target.value) }} placeholder="MAX 95%" type="number" style={{ fontSize: "15px", outline: "none", color: "#fff", textAlign: "left", height: "35px", width: "100%", display: "grid", gridTemplateColumns: "auto", margin: "auto", border: "1px solid #0f172a", background: "#00000050", borderRadius: "2px", }}></input>
                        </div>
                    </div>}

                    <button onClick={() => { uploadFiles(imagefile) }} style={{
                        cursor: "pointer", display: "grid", gridTemplateColumns: "auto", height: "35px", width: "auto", border: "1px solid #ffffff20",
                        background: "#ffffff20", borderRadius: "2px", color: "#fff"
                    }}>
                        <p style={{ fontSize: "15px", textAlign: "left", height: "min-content", margin: "auto" }}><span style={{ textAlign: "left", fontWeight: "bold", fontSize: "15px" }}>CREATE</span></p>
                    </button>
                    <p style={{
                        textAlign: "justify",
                        fontSize: "15px",
                        color: "#e5e5e5"
                    }}>
                        FEE {nextblock(poolfee, 18)} (BNB)<br />
                        For now we do not support (.gif, .webm) or any animated images
                    </p>
                </div>

            </div>
        </div>
    );


}
export default CreateNft;