const ModalCalc = (props) => {

    return (
        <div style={{ color: "#000", height: "100%", width: "100%", position: "fixed", display: "grid", background: "#00000050", top: "0", left: "0", zIndex: "4000" }} >

            <div style={{ height: "min-content", width: "360px", display: "grid", gap: "10px", margin: "auto", background: "#222", borderRadius: "10px", padding: "25px", color: "#fff" }}>

                <div style={{ display: "grid", gridTemplateColumns: "auto 50px", height: "50px", width: "100%", gap: "5px", margin: "auto", fontSize: "20px", }}>
                    <p style={{ width: "100%", margin: "auto", textAlign: "left", fontSize: "20px", fontWeight: "bold" }}>ROI</p>
                    <svg onClick={() => { props.sethubtx(false) }} xmlns="http://www.w3.org/2000/svg" style={{ cursor: "pointer", margin: "auto" }} width="25" height="25" fill="currentcolor" className="bi bi-x" viewBox="0 0 16 16">
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                </div>
                <p style={{ width: "100%", margin: "auto", textAlign: "left", fontSize: "12px" }}>
                    Calculated based on current rates. Rates are estimates provided for your convenience only, and by no means represent guaranteed returns.
                </p>

                <div style={{ display: "grid", gridTemplateColumns: "33% 33% 34%", height: "min-content", width: "100%", margin: "auto", }}>
                    <p style={{ width: "100%", margin: "auto", textAlign: "left", fontSize: "13px" }}>Timeframe</p>
                    <p style={{ width: "100%", margin: "auto", textAlign: "left", fontSize: "13px" }}>ROI</p>
                    <p style={{ width: "100%", margin: "auto", textAlign: "left", fontSize: "13px", }}>USD per $1000 in LP</p>
                </div>
                <div style={{ background: "#00000010", borderRadius: "10px", display: "grid", gridTemplateColumns: "33% 33% 34%", height: "50px", width: "100%", margin: "auto", fontSize: "13px", fontWeight: "bold" }}>
                    <p style={{ width: "100%", margin: "auto", textAlign: "left", padding: "0 5px" }}>1d</p>
                    <p style={{ width: "100%", margin: "auto", textAlign: "left", padding: "0 5px" }}>{props.nextpercent((props.APR / 365), 2)}%</p>
                    <p style={{ width: "100%", margin: "auto", textAlign: "left", padding: "5px 0" }}>${((1000 * (props.APR / 365)) / 100).toFixed(2)} USD</p>
                </div>
                <div style={{ background: "#00000000", borderRadius: "10px", display: "grid", gridTemplateColumns: "33% 33% 34%", height: "50px", width: "100%", margin: "auto", fontSize: "13px", fontWeight: "bold" }}>
                    <p style={{ width: "100%", margin: "auto", textAlign: "left", padding: "0 5px" }}>7d</p>
                    <p style={{ width: "100%", margin: "auto", textAlign: "left", padding: "0 5px" }}>{props.nextpercent(((props.APR / 12) / 4), 2)}%</p>
                    <p style={{ width: "100%", margin: "auto", textAlign: "left", padding: "0 5px" }}>${((1000 * ((props.APR / 12) / 4)) / 100).toFixed(2)} USD</p>
                </div>
                <div style={{ background: "#00000010", borderRadius: "10px", display: "grid", gridTemplateColumns: "33% 33% 34%", height: "50px", width: "100%", margin: "auto", fontSize: "13px", fontWeight: "bold" }}>
                    <p style={{ width: "100%", margin: "auto", textAlign: "left", padding: "0 5px" }}>30d</p>
                    <p style={{ width: "100%", margin: "auto", textAlign: "left", padding: "0 5px" }}>{props.nextpercent(((props.APR) / 12), 2)}%</p>
                    <p style={{ width: "100%", margin: "auto", textAlign: "left", padding: "0 5px" }}>$ {((1000 * ((props.APR) / 12)) / 100).toFixed(2)} USD</p>
                </div>
                <div style={{ background: "#00000000", borderRadius: "10px", display: "grid", gridTemplateColumns: "33% 33% 34%", height: "50px", width: "100%", margin: "auto", fontSize: "13px", fontWeight: "bold" }}>
                    <p style={{ width: "100%", margin: "auto", textAlign: "left", padding: "0 5px" }}>365d</p>
                    <p style={{ width: "100%", margin: "auto", textAlign: "left", padding: "0 5px" }}>{props.APR}%</p>
                    <p style={{ width: "100%", margin: "auto", textAlign: "left", padding: "0 5px" }}>$ {((1000 * props.APR) / 100).toFixed(2)} USD</p>
                </div>
            </div>


        </div>

    );
}

export default ModalCalc;