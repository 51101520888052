export default {
    "martik": {
        Name: "Martik",
        logoUrl: "https://martik.site/icons/martik.png",
        Logo: <img
            src={"https://martik.site/icons/martik.png"}
            style={{
                height: "50px",
                width: "50px",
                margin: "auto",
            }}
        />,
        backgroundColor: "linear-gradient(to bottom right, #161a1e, #161a1e)",
        buttonsColor: "#161a1e",
        modalsColor: "#161a1e",
        outbuttonsColor: "#fff",
        buttonsBorders: "1px solid #ffffff20",
        inputsColor: "#ffffff00",
        inputsBorders: "1px solid #ffffff20",
        Borders: "#ffffff20",
        outFont: "#fff",
        innerFont: "#fff",
        innerBFont: "#222",
        redirectTo: '/create/contract',
        Routers:
            [
                '/',
                '/projects',
                '/p2p',
                '/p2p/sell',
                '/pre-sales',
                '/fair-launchs',
                '/private-sales',
                '/pump-sales',
                '/pre-sale',
                '/fair-launch',
                '/private-sale',
                '/pump-sale',
                "/pools/nfts",
                "/pools/nfts/create",
                '/create/pre-sale',
                '/create/fair-launch',
                '/create/private-sale',
                '/create/pump-sale',
                '/pre-sale/edit',
                '/fair-launch/edit',
                '/private-sale/edit',
                '/pump-sale/edit',
                '/swap',
                '/smart-bridge',
                '/create/contract',
                '/betting',
                '/multistake',
                '/exchange',
                '/volume-bot',
                '/farms',
                '/manager',
                '/stake',
                '/stake/create',
                '/addlpv2',
                '/marketplace',
                '/marketplace/publish',
                '/marketplace/create',
                '/marketplace/edit/profile',
                '/marketplace/profile',
                '/lock',
                '/lock/data',
                '/lock/create',
                '/lock/update',
                '/lock/transfer',
                '/terms',
                '/whitepaper',
                '/nft/pools',
                '/explorer',
                '/explorer/address',
                '/explorer/token',
                '/explorer/block',
                '/explorer/tx',
            ],
        menudata: [
            {
                permit: true,//Trade
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                ]
            },
            {
                permit: true,//Pools
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                ]
            },
            {
                permit: true,//Dev Hub
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                ]
            },
            {
                permit: true,//Launchpad
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },

                ]
            },
            {
                permit: true,//lock
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },

                ]
            },
            {
                permit: false,//marketplace
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                ]
            },
            {
                permit: true,//lock
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                ]
            },
        ],
        rpc: "https://bsc-dataseed.binance.org",
        tokenContract: "0x116526135380E28836C6080f1997645d5A807FAE",
        info: {
            title: "if ( MARTIK ){ return TRUST; }",
            desc: "To leverage technology and achieve your business goals, Martik offers custom software verification and development services that put you light years ahead in security for your project and community.",
            buyon: "http://martik.site/swap?token=0x116526135380E28836C6080f1997645d5A807FAE",
            name: "martik",
            symbol: "MTK",
            telegram: "http://t.me/martik_en",
            twitter: "https://twitter.com/martikcrypto",
        },
        screens: {
            "createToken": {
                web3: {
                    receiver: "0xA4de20856640d59A146393ac8CcF3F8e2133DF33",
                    ccFees: [
                        0.1,
                        0.1,
                        0.1,
                        0.1,
                        0.1,
                        0.0
                    ],

                    tokenTo50Fee: "0x116526135380E28836C6080f1997645d5A807FAE"
                }
            },
            "swap": {
                web3: {
                    Router: "0xA1a39C041221765eb52BaEA2FDBbc77aa92c0dF3",
                    Tokens: {
                        "1999": [
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            },
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            },
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            }
                        ],
                        "97": [
                            {
                                "nome": "BNB",
                                "bname": "Binance Coin",
                                "icon": "https://bscscan.com/token/images/binance_32.png",
                                "contract": "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd"
                            }, {
                                "nome": "BUSD",
                                "bname": "BUSD Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/logo.png",
                                "contract": "0xaB1a4d4f1D656d2450692D237fdD6C7f9146e814"
                            }, {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0x116526135380E28836C6080f1997645d5A807FAE"
                            },

                            {
                                "nome": "CAKE",
                                "bname": "PancakeSwap Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82/logo.png",
                                "contract": "0xFa60D973F7642B748046464e165A65B7323b0DEE"
                            }

                        ],
                        "56": [
                            {
                                "nome": "BNB",
                                "bname": "Binance Coin",
                                "icon": "https://bscscan.com/token/images/binance_32.png",
                                "contract": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
                            },
                            {
                                "nome": "BUSD",
                                "bname": "BUSD Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/logo.png",
                                "contract": "0xe9e7cea3dedca5984780bafc599bd69add087d56"
                            }, {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0x116526135380E28836C6080f1997645d5A807FAE"
                            },

                            {
                                "nome": "USDT",
                                "bname": "USDT",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x55d398326f99059fF775485246999027B3197955/logo.png",
                                "contract": "0x55d398326f99059fF775485246999027B3197955"
                            }, {
                                "nome": "BTCB",
                                "bname": "BITCOIN",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c/logo.png",
                                "contract": "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c"
                            },
                            {
                                "nome": "ADA",
                                "bname": "Cardano Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47/logo.png",
                                "contract": "0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47"
                            },
                            {
                                "nome": "CAKE",
                                "bname": "PancakeSwap Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82/logo.png",
                                "contract": "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82"
                            }
                        ]
                    }
                }
            },
            "stakepools": {
                factory: "0xC1b4AcC0E4420369BE3C634777F890e42007327B",
                excludes: {
                    '0x4A51BA1Bb9526aa31b967b06Ec2F3557C3e1d3Cc': true,
                    "0x650e5B875035420C872b90cCE0Ee777De30D8C47": true,
                    "0xFC765F0C445b8a6436db9D3D9750C3799Bf58Fd0": true,
                    "0x025dC95E02e33c711B509485BA9233fb228036c4": true,
                    "0x7E6fDfF5487b0e9b97F3B618D3f76410779aA04f": true,
                    "0xEe664C9F3Ed8b4479eE4D98f6919bBc50a285082": true,
                    "0xC41D0610763dc677F704d6A14009Ca3954E4eA26": true,
                    "0xB4C3f82754c97FC43c2f01528BA26E131bd63ec8": true,
                    "0x590B534523dDDa612c58Bd05cdEb05bC1DE013ca": true,
                    "0x8dEc373bbcA468168c3E0bd2BEb5Bcd66731DDd8": true,
                    "0x436080202a6cDAdF2cbf83D6D5dFD33860ecC7cB": true,
                    "0x65D95b37Dd46D3Ab60715637aE91E5801AcABf39": true,
                    "0xC1122978D8cc23F06B26ade13fAdc10bB16aa0E4": true,
                    "0xcc730BB1306e17A6DB7C16a44180F5C1C4B90Cd7": true,

                    "0x98DF35D07f55A1e8885c6D23641546E73009001C": true,
                }
            },
            "nftpools": {
                factory: "0x449d637816DAe66c8707D7fdC5EfCe2F7540531B",
                excludes: {
                    '0x4A51BA1Bb9526aa31b967b06Ec2F3557C3e1d3Cc': true,
                    "0x650e5B875035420C872b90cCE0Ee777De30D8C47": true,
                    "0xFC765F0C445b8a6436db9D3D9750C3799Bf58Fd0": true,
                    "0x025dC95E02e33c711B509485BA9233fb228036c4": true,
                    "0x7E6fDfF5487b0e9b97F3B618D3f76410779aA04f": true,
                    "0xEe664C9F3Ed8b4479eE4D98f6919bBc50a285082": true,
                    "0xC41D0610763dc677F704d6A14009Ca3954E4eA26": true,
                    "0xB4C3f82754c97FC43c2f01528BA26E131bd63ec8": true,
                    "0x590B534523dDDa612c58Bd05cdEb05bC1DE013ca": true,
                    "0x8dEc373bbcA468168c3E0bd2BEb5Bcd66731DDd8": true,
                    "0x436080202a6cDAdF2cbf83D6D5dFD33860ecC7cB": true,
                    "0x65D95b37Dd46D3Ab60715637aE91E5801AcABf39": true,
                    "0xC1122978D8cc23F06B26ade13fAdc10bB16aa0E4": true,
                    "0xcc730BB1306e17A6DB7C16a44180F5C1C4B90Cd7": true,

                    "0x98DF35D07f55A1e8885c6D23641546E73009001C": true,
                }
            }

        }

    },
    "xshiba": {
        Name: "XHIBA",
        logoUrl: "https://ipfs.io/ipfs/QmW1rvRFjvTnddYJ1nBnsYMRiasf4WQQXpsqaa5oRxUmzb?filename=WhatsApp_Image_2023-09-07_at_13.50.18-removebg-preview.png",
        Logo: <img src={"https://ipfs.io/ipfs/QmW1rvRFjvTnddYJ1nBnsYMRiasf4WQQXpsqaa5oRxUmzb?filename=WhatsApp_Image_2023-09-07_at_13.50.18-removebg-preview.png"}
            style={{
                height: "50px",
                width: "50px",
                margin: "auto",
            }}
        />,
        backgroundColor: "linear-gradient(to bottom right, #161a1e, #161a1e)",
        buttonsColor: "#161a1e",
        modalsColor: "#161a1e",
        outbuttonsColor: "#fff",
        buttonsBorders: "1px solid #ffffff20",
        inputsColor: "#ffffff00",
        inputsBorders: "1px solid #ffffff20",
        Borders: "#ffffff20",
        outFont: "#fff",
        innerFont: "#fff",
        innerBFont: "#222",
        redirectTo: '/create/contract',
        Routers:
            [
                '/',
                '/swap',
                '/create/contract',
                '/farms',
                '/manager',
                '/stake',
                '/terms',
                '/whitepaper',
                '/stake/create',
            ],
        menudata: [
            {
                permit: true,//Trade
                subpermit: [
                    {
                        permit: false
                    },
                    {
                        permit: true
                    },
                    {
                        permit: false
                    },
                ]
            },
            {
                permit: true,//Pools
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: false
                    },
                    {
                        permit: true
                    },
                ]
            },
            {
                permit: true,//Dev Hub
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: false
                    },
                    {
                        permit: false
                    },
                ]
            },
            {
                permit: false,//Launchpad
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },

                ]
            },
            {
                permit: true,//lock
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },

                ]
            },
            {
                permit: false,//marketplace
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                ]
            },
            {
                permit: true,//lock
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                ]
            },
        ],
        rpc: "https://bsc-dataseed.binance.org",
        tokenContract: "0x414Af493C4e2D463B2FdA65A28d1631d25ECC298",
        info: {
            title: "Everything is possible",
            desc: "XHIBA is a community-driven meme token from Martik, missed the Martik private sale? Don't miss Xhiba's 🚀🌒",
            buyon: "https://martik.site/exchange?trade=XHIBA_USDT",
            name: "XHIBA",
            symbol: "XHIBA",
            telegram: "https://t.me/xhiba_pt",
            twitter: "https://twitter.com/xhiba_pt",
        },
        screens: {
            "createToken": {
                web3: {
                    receiver: "0xA4de20856640d59A146393ac8CcF3F8e2133DF33",
                    ccFees: [
                        0.1,
                        0.1,
                        0.1,
                        0.1,
                        0.1,
                        0.0
                    ],

                    tokenTo50Fee: "0x414Af493C4e2D463B2FdA65A28d1631d25ECC298"
                }
            },
            "swap": {
                web3: {
                    Router: "0xA1a39C041221765eb52BaEA2FDBbc77aa92c0dF3",
                    Tokens: {
                        "1999": [
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            },
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            },
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            }
                        ],
                        "97": [
                            {
                                "nome": "BNB",
                                "bname": "Binance Coin",
                                "icon": "https://bscscan.com/token/images/binance_32.png",
                                "contract": "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd"
                            }, {
                                "nome": "BUSD",
                                "bname": "BUSD Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/logo.png",
                                "contract": "0xaB1a4d4f1D656d2450692D237fdD6C7f9146e814"
                            }, {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0x116526135380E28836C6080f1997645d5A807FAE"
                            },

                            {
                                "nome": "CAKE",
                                "bname": "PancakeSwap Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82/logo.png",
                                "contract": "0xFa60D973F7642B748046464e165A65B7323b0DEE"
                            }

                        ],
                        "56": [
                            {
                                "nome": "BNB",
                                "bname": "Binance Coin",
                                "icon": "https://bscscan.com/token/images/binance_32.png",
                                "contract": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
                            },
                            {
                                "nome": "BUSD",
                                "bname": "BUSD Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/logo.png",
                                "contract": "0xe9e7cea3dedca5984780bafc599bd69add087d56"
                            }, {
                                "nome": "XHIBA",
                                "bname": "XHIBA",
                                "icon": "https://ipfs.io/ipfs/QmW1rvRFjvTnddYJ1nBnsYMRiasf4WQQXpsqaa5oRxUmzb?filename=WhatsApp_Image_2023-09-07_at_13.50.18-removebg-preview.png",
                                "contract": "0x414Af493C4e2D463B2FdA65A28d1631d25ECC298"
                            }, {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0x116526135380E28836C6080f1997645d5A807FAE"
                            },

                            {
                                "nome": "USDT",
                                "bname": "USDT",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x55d398326f99059fF775485246999027B3197955/logo.png",
                                "contract": "0x55d398326f99059fF775485246999027B3197955"
                            }, {
                                "nome": "BTCB",
                                "bname": "BITCOIN",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c/logo.png",
                                "contract": "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c"
                            },
                            {
                                "nome": "ADA",
                                "bname": "Cardano Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47/logo.png",
                                "contract": "0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47"
                            },
                            {
                                "nome": "CAKE",
                                "bname": "PancakeSwap Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82/logo.png",
                                "contract": "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82"
                            }
                        ]
                    }
                }
            },
            "stakepools": {
                factory: "0xC1b4AcC0E4420369BE3C634777F890e42007327B",
                excludes: {
                    '0x4A51BA1Bb9526aa31b967b06Ec2F3557C3e1d3Cc': true,
                    "0x650e5B875035420C872b90cCE0Ee777De30D8C47": true,
                    "0xFC765F0C445b8a6436db9D3D9750C3799Bf58Fd0": true,
                    "0x025dC95E02e33c711B509485BA9233fb228036c4": true,
                    "0x7E6fDfF5487b0e9b97F3B618D3f76410779aA04f": true,
                    "0xEe664C9F3Ed8b4479eE4D98f6919bBc50a285082": true,
                    "0xC41D0610763dc677F704d6A14009Ca3954E4eA26": true,
                    "0xB4C3f82754c97FC43c2f01528BA26E131bd63ec8": true,
                    "0x590B534523dDDa612c58Bd05cdEb05bC1DE013ca": true,
                    "0x8dEc373bbcA468168c3E0bd2BEb5Bcd66731DDd8": true,
                    "0x436080202a6cDAdF2cbf83D6D5dFD33860ecC7cB": true,
                    "0x65D95b37Dd46D3Ab60715637aE91E5801AcABf39": true,
                    "0xC1122978D8cc23F06B26ade13fAdc10bB16aa0E4": true,
                    "0xcc730BB1306e17A6DB7C16a44180F5C1C4B90Cd7": true,

                    "0x98DF35D07f55A1e8885c6D23641546E73009001C": true,
                    "0x82FE030AE76Dd614FE053ca36b80D49879fF5082": true,
                    "0x71C65050D2B48b911F6F869fBDd6d822eE15FF6b": true,
                    "0x560fF6a296D5F84b480aEbf57CFc54E9Faec6354": true,
                    "0xD7984989E7693a19C2358BE526266A76b8A2b7BC": true,
                    "0x46006BEbd30f3864A2B2555f6D88fa3EE8BE342E": true,
                    "0x7B30A0C75a9d0bC3849f6D5BD2F6Bdc921573aa9": true,
                    "0x8423995001780bB80ABBbF24845507e21cA56061": true,
                    "0x0427d0497fd82507666c186B50188526e02F2701": true,
                    "0x4316380ad94458DF90f527b790eDBC81292846db": true,

                    "0x701f1254c5cCb95a09Ed82C6b23e19782fAa47A4": true,
                    "0x46b821064370bDaD28eaA2ECdf2629384CECF820": true,
                    "0x27A634CaF303ec10D5558e585A3dD6354d8ea389": true,
                    "0xAef0332741872259EC50A3cbA1DE82d5790D0bC0": true,
                    "0x583D5Ece5e83816689d2FbD0543f6B962474C1fd": true,
                    "0x41897De6fCa8B42C7233eB42f27756CE0E85cC9e": true,
                    "0xB7d3D8f6D8cCaF58744dF547AAdFFe0CFC48f729": true,
                    "0xcfCf1E93c2E38d2F3328cBDA6b4f22410749aF01": true,
                }
            }
        }

    },
    "sbk": {
        Name: "NSBK",
        logoUrl: "https://newsbk.site/wp-content/uploads/2023/08/photo_2023-08-19_18-43-04-removebg-preview.png",
        Logo: <img
            src={"https://newsbk.site/wp-content/uploads/2023/08/photo_2023-08-19_18-43-04-removebg-preview.png"}
            style={{
                height: "50px",
                width: "50px",
                margin: "auto",
            }}
        />,

        backgroundColor: "url('" + "https://img.freepik.com/fotos-premium/um-close-up-de-um-monte-de-caixas-pretas-e-azuis-generativas-ai_974539-1304.jpg" + " ') center center / " + (window.innerWidth + window.innerHeight) + "px no-repeat",
        outFont: "#fff",
        buttonsColor: "#030017",
        modalsColor: "#030017",
        innerFont: "#fff",
        outbuttonsColor: "#FFAE00",
        innerBFont: "#222",
        buttonsBorders: "1px solid #00000020",
        inputsColor: "#ffffff00",
        inputsBorders: "1px solid #FFAE0050",
        Borders: "#FFAE0080",
        redirectTo: '/create/contract',
        Routers: [


            '/farms',

            '/stake',

            '/stake/create',

            '/',
            '/create/contract',
            '/manager',
            '/swap'
        ],
        menudata: [
            {
                permit: true,//Trade
                subpermit: [
                    {
                        permit: false
                    },
                    {
                        permit: true
                    },
                    {
                        permit: false
                    },
                ]
            },
            {
                permit: true,//Pools
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: false
                    },
                    {
                        permit: true
                    },
                ]
            },
            {
                permit: true,//Dev Hub
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: false
                    },
                    {
                        permit: false
                    },
                ]
            },
            {
                permit: false,//Launchpad
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },

                ]
            },
            {
                permit: false,//lock
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },

                ]
            },
            {
                permit: false,//marketplace
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                ]
            },
            {
                permit: false,//lock
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                ]
            },
        ],
        rpc: "https://bsc-dataseed.binance.org",
        tokenContract: '0xbE893B0C8d1b96666D17F344Dc095CCAFb9f0C9f',
        info: {
            title: "New Shiba Black 2.0 community",
            desc: "“Welcome to the New Shiba Black 2.0 universe! We are a passionate cryptocurrency community, pioneers in fair launches, and dedicated to the decentralized financial future. Join us today!”",
            buyon: "https://t.me/shibablackoficial",
            name: "SHIBA BLACK 2.0",
            symbol: "SBK",
            telegram: "https://t.me/shibablackoficial",
            twitter: undefined,
        },
        screens: {
            "createToken": {
                web3: {
                    receiver: "0x1B49f51F02711eC1F22B9ed7E3cD4337FAfEDF1C",
                    ccFees: [
                        0.2,
                        0.3,
                        0.3,
                        0.3,
                        0.4,
                        0.0
                    ],
                    tokenTo50Fee: "0xbE893B0C8d1b96666D17F344Dc095CCAFb9f0C9f"
                }
            },
            "swap": {
                web3: {
                    Router: "0xc9c1bf056E90d85dc8eb59717475Bd2b5C207696",
                    Tokens: {
                        "1999": [
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            },
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            },
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            }
                        ],
                        "97": [
                            {
                                "nome": "BNB",
                                "bname": "Binance Coin",
                                "icon": "https://bscscan.com/token/images/binance_32.png",
                                "contract": "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd"
                            }, {
                                "nome": "BUSD",
                                "bname": "BUSD Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/logo.png",
                                "contract": "0xaB1a4d4f1D656d2450692D237fdD6C7f9146e814"
                            }, {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0x116526135380E28836C6080f1997645d5A807FAE"
                            },

                            {
                                "nome": "CAKE",
                                "bname": "PancakeSwap Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82/logo.png",
                                "contract": "0xFa60D973F7642B748046464e165A65B7323b0DEE"
                            }

                        ],
                        "56": [
                            {
                                "nome": "BNB",
                                "bname": "Binance Coin",
                                "icon": "https://bscscan.com/token/images/binance_32.png",
                                "contract": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
                            },
                            {
                                "nome": "BUSD",
                                "bname": "BUSD Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/logo.png",
                                "contract": "0xe9e7cea3dedca5984780bafc599bd69add087d56"
                            }, {
                                "nome": "NSBK",
                                "bname": "Shiba Black 2.0",
                                "icon": "https://newsbk.site/wp-content/uploads/2023/08/photo_2023-08-19_18-43-04-removebg-preview.png",
                                "contract": "0xbE893B0C8d1b96666D17F344Dc095CCAFb9f0C9f"
                            }, {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0x116526135380E28836C6080f1997645d5A807FAE"
                            },

                            {
                                "nome": "USDT",
                                "bname": "USDT",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x55d398326f99059fF775485246999027B3197955/logo.png",
                                "contract": "0x55d398326f99059fF775485246999027B3197955"
                            }, {
                                "nome": "BTCB",
                                "bname": "BITCOIN",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c/logo.png",
                                "contract": "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c"
                            },
                            {
                                "nome": "ADA",
                                "bname": "Cardano Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47/logo.png",
                                "contract": "0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47"
                            },
                            {
                                "nome": "CAKE",
                                "bname": "PancakeSwap Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82/logo.png",
                                "contract": "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82"
                            }
                        ]
                    }
                }
            },
            "stakepools": {
                factory: "0x7FD6bb4b075fd4932655F417bFaaf4528D2dbcfb",
                excludes: {
                    "0x82FE030AE76Dd614FE053ca36b80D49879fF5082": true,
                    '0x4A51BA1Bb9526aa31b967b06Ec2F3557C3e1d3Cc': true,
                    "0x650e5B875035420C872b90cCE0Ee777De30D8C47": true,
                    "0xFC765F0C445b8a6436db9D3D9750C3799Bf58Fd0": true,
                    "0x025dC95E02e33c711B509485BA9233fb228036c4": true,
                    "0x7E6fDfF5487b0e9b97F3B618D3f76410779aA04f": true,
                    "0xEe664C9F3Ed8b4479eE4D98f6919bBc50a285082": true,
                    "0xC41D0610763dc677F704d6A14009Ca3954E4eA26": true,
                    "0xB4C3f82754c97FC43c2f01528BA26E131bd63ec8": true,
                    "0x590B534523dDDa612c58Bd05cdEb05bC1DE013ca": true,
                    "0x8dEc373bbcA468168c3E0bd2BEb5Bcd66731DDd8": true,
                    "0x436080202a6cDAdF2cbf83D6D5dFD33860ecC7cB": true,
                    "0x65D95b37Dd46D3Ab60715637aE91E5801AcABf39": true,
                    "0xC1122978D8cc23F06B26ade13fAdc10bB16aa0E4": true,
                    "0xa8b09b2cF2b1639c2A6aB0a32b5f73Dc61FA4318": true,
                    "0x9321eeE089364182C2A148FEb493CA0931d4Dab4": true,

                }
            }
        }
    },
    "xtiger": {
        Name: "xtiger",
        logoUrl: window.location.protocol + "//" + window.location.host + "/icons/xt.jpeg",
        Logo: <img src={window.location.protocol + "//" + window.location.host + "/icons/xt.jpeg"}
            style={{
                height: "50px",
                width: "50px",
                margin: "auto",
            }}
        />,
        backgroundColor: "linear-gradient(to bottom right, #000000,#000000)",
        buttonsColor: "#000000",
        modalsColor: "#000000",
        outbuttonsColor: "#fff",
        buttonsBorders: "1px solid #ffffff20",
        inputsColor: "#ffffff00",
        inputsBorders: "1px solid #ffffff20",
        Borders: "#ffffff20",
        outFont: "#fff",
        innerFont: "#fff",
        innerBFont: "#222",
        redirectTo: '/create/contract',
        Routers:
            [
                '/',
                '/swap',
                '/create/contract',
                '/farms',
                '/manager',
                '/stake',
                '/terms',
                '/whitepaper',
                '/lock',
                '/lock/create',
                '/stake/create',
                '/play/slot'
            ],
        menudata: [
            {
                permit: true,//Trade
                subpermit: [
                    {
                        permit: false
                    },
                    {
                        permit: true
                    },
                    {
                        permit: false
                    },
                ]
            },
            {
                permit: true,//Pools
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: false
                    },
                    {
                        permit: true
                    },
                ]
            },
            {
                permit: true,//Dev Hub
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: false
                    },
                    {
                        permit: false
                    },
                ]
            },
            {
                permit: false,//Launchpad
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },

                ]
            },
            {
                permit: true,//lock
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },

                ]
            },
            {
                permit: false,//marketplace
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                ]
            },
            {
                permit: true,//lock
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                ]
            },
        ],
        rpc: "https://bsc-dataseed.binance.org",
        tokenContract: "0xEb7B213aaded6B1AEd9e4dC49a687764C216B35c",
        info: {
            title: "Everything is possible",
            desc: "XTIGER is a community-driven meme token from Martik, missed the Martik private sale? Don't miss Xtiger's 🚀🌒",
            buyon: "https://martik.site/exchange?trade=XTIGER_USDT",
            name: "XTIGER",
            symbol: "XTIGER",
            telegram: "https://t.me/xtiger_pt",
            twitter: "https://twitter.com/xtiger_pt",
        },
        screens: {
            "createToken": {
                web3: {
                    receiver: "0xA4de20856640d59A146393ac8CcF3F8e2133DF33",
                    ccFees: [
                        0.1,
                        0.1,
                        0.1,
                        0.1,
                        0.1,
                        0.0
                    ],

                    tokenTo50Fee: "0xEb7B213aaded6B1AEd9e4dC49a687764C216B35c"
                }
            },
            "swap": {
                web3: {
                    Router: "0xA1a39C041221765eb52BaEA2FDBbc77aa92c0dF3",
                    Tokens: {
                        "1999": [
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            },
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            },
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            }
                        ],
                        "97": [
                            {
                                "nome": "BNB",
                                "bname": "Binance Coin",
                                "icon": "https://bscscan.com/token/images/binance_32.png",
                                "contract": "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd"
                            }, {
                                "nome": "BUSD",
                                "bname": "BUSD Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/logo.png",
                                "contract": "0xaB1a4d4f1D656d2450692D237fdD6C7f9146e814"
                            },
                            {
                                "nome": "XTIGER",
                                "bname": "XTIGER",
                                "icon": window.location.protocol + "//" + window.location.host + "/icons/xt.jpeg",
                                "contract": "0xEb7B213aaded6B1AEd9e4dC49a687764C216B35c"
                            },
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0x116526135380E28836C6080f1997645d5A807FAE"
                            },

                            {
                                "nome": "CAKE",
                                "bname": "PancakeSwap Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82/logo.png",
                                "contract": "0xFa60D973F7642B748046464e165A65B7323b0DEE"
                            }

                        ],
                        "56": [
                            {
                                "nome": "BNB",
                                "bname": "Binance Coin",
                                "icon": "https://bscscan.com/token/images/binance_32.png",
                                "contract": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
                            },
                            {
                                "nome": "BUSD",
                                "bname": "BUSD Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/logo.png",
                                "contract": "0xe9e7cea3dedca5984780bafc599bd69add087d56"
                            }, {
                                "nome": "XTIGER",
                                "bname": "XTIGER",
                                "icon": window.location.protocol + "//" + window.location.host + "/icons/xt.jpeg",
                                "contract": "0xEb7B213aaded6B1AEd9e4dC49a687764C216B35c"
                            }, {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0x116526135380E28836C6080f1997645d5A807FAE"
                            },

                            {
                                "nome": "USDT",
                                "bname": "USDT",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x55d398326f99059fF775485246999027B3197955/logo.png",
                                "contract": "0x55d398326f99059fF775485246999027B3197955"
                            }, {
                                "nome": "BTCB",
                                "bname": "BITCOIN",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c/logo.png",
                                "contract": "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c"
                            },
                            {
                                "nome": "ADA",
                                "bname": "Cardano Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47/logo.png",
                                "contract": "0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47"
                            },
                            {
                                "nome": "CAKE",
                                "bname": "PancakeSwap Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82/logo.png",
                                "contract": "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82"
                            }
                        ]
                    }
                }
            },
            "stakepools": {
                factory: "0xC1b4AcC0E4420369BE3C634777F890e42007327B",
                excludes: {
                    '0x4A51BA1Bb9526aa31b967b06Ec2F3557C3e1d3Cc': true,
                    "0x650e5B875035420C872b90cCE0Ee777De30D8C47": true,
                    "0xFC765F0C445b8a6436db9D3D9750C3799Bf58Fd0": true,
                    "0x025dC95E02e33c711B509485BA9233fb228036c4": true,
                    "0x7E6fDfF5487b0e9b97F3B618D3f76410779aA04f": true,
                    "0xEe664C9F3Ed8b4479eE4D98f6919bBc50a285082": true,
                    "0xC41D0610763dc677F704d6A14009Ca3954E4eA26": true,
                    "0xB4C3f82754c97FC43c2f01528BA26E131bd63ec8": true,
                    "0x590B534523dDDa612c58Bd05cdEb05bC1DE013ca": true,
                    "0x8dEc373bbcA468168c3E0bd2BEb5Bcd66731DDd8": true,
                    "0x436080202a6cDAdF2cbf83D6D5dFD33860ecC7cB": true,
                    "0x65D95b37Dd46D3Ab60715637aE91E5801AcABf39": true,
                    "0xC1122978D8cc23F06B26ade13fAdc10bB16aa0E4": true,
                    "0xcc730BB1306e17A6DB7C16a44180F5C1C4B90Cd7": true,

                    "0x98DF35D07f55A1e8885c6D23641546E73009001C": true,
                    "0x82FE030AE76Dd614FE053ca36b80D49879fF5082": true,
                    "0x71C65050D2B48b911F6F869fBDd6d822eE15FF6b": true,
                    "0x560fF6a296D5F84b480aEbf57CFc54E9Faec6354": true,
                    "0xD7984989E7693a19C2358BE526266A76b8A2b7BC": true,
                    "0x46006BEbd30f3864A2B2555f6D88fa3EE8BE342E": true,
                    "0x7B30A0C75a9d0bC3849f6D5BD2F6Bdc921573aa9": true,
                    "0x8423995001780bB80ABBbF24845507e21cA56061": true,
                    "0x0427d0497fd82507666c186B50188526e02F2701": true,
                    "0x4316380ad94458DF90f527b790eDBC81292846db": true,
                    "0xBa7Bb73233b90502bEfF57A3e257f71A5fDF6739": true,

                    "0x701f1254c5cCb95a09Ed82C6b23e19782fAa47A4": true,
                    "0x46b821064370bDaD28eaA2ECdf2629384CECF820": true,
                    "0x27A634CaF303ec10D5558e585A3dD6354d8ea389": true,
                    "0xAef0332741872259EC50A3cbA1DE82d5790D0bC0": true,
                    "0x583D5Ece5e83816689d2FbD0543f6B962474C1fd": true,
                    "0x41897De6fCa8B42C7233eB42f27756CE0E85cC9e": true,
                    "0xB7d3D8f6D8cCaF58744dF547AAdFFe0CFC48f729": true,
                    "0xcfCf1E93c2E38d2F3328cBDA6b4f22410749aF01": true,
                }
            }
        }

    },
    "jijiswap": {
        Name: "jiji",
        logoUrl: window.location.protocol + "//" + window.location.host + "/icons/jiji.jpg",
        Logo: <img src={window.location.protocol + "//" + window.location.host + "/icons/jiji.jpg"}
            style={{
                height: "50px",
                width: "50px",
                margin: "auto",
            }}
        />,
        backgroundColor: "linear-gradient(to bottom right, #110302, #110302)",
        buttonsColor: "#110302",
        modalsColor: "#110302",
        outbuttonsColor: "#fff",
        buttonsBorders: "1px solid #ffffff20",
        inputsColor: "#ffffff00",
        inputsBorders: "1px solid #ffffff20",
        Borders: "#ffffff20",
        outFont: "#fff",
        innerFont: "#fff",
        innerBFont: "#222",
        redirectTo: '/create/contract',
        Routers:
            [
                '/',
                '/swap',
                '/create/contract',
                '/farms',
                '/manager',
                '/stake',

                '/lock',
                '/lock/create',
                '/stake/create',
            ],
        menudata: [
            {
                permit: true,//Trade
                subpermit: [
                    {
                        permit: false
                    },
                    {
                        permit: true
                    },
                    {
                        permit: false
                    },
                ]
            },
            {
                permit: true,//Pools
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: false
                    },
                    {
                        permit: true
                    },
                ]
            },
            {
                permit: true,//Dev Hub
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: false
                    },
                    {
                        permit: false
                    },
                ]
            },
            {
                permit: false,//Launchpad
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },

                ]
            },
            {
                permit: true,//lock
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },

                ]
            },
            {
                permit: false,//marketplace
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                ]
            },
            {
                permit: false,//lock
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                ]
            },
        ],
        rpc: "https://bsc-dataseed.binance.org",
        tokenContract: "0x6a8cC171a671e0Aa7f84A0A36D7C1285D3A224D2",
        info: {
            title: "KURONEKO",
            desc: "$JIJI The token of New Era",
            buyon: "https://pancakeswap.finance/swap?outputCurrency=0x6a8cC171a671e0Aa7f84A0A36D7C1285D3A224D2",
            name: "KURONEKO ",
            symbol: "JIJI",
            telegram: "https://t.me/kuroneko1000xxx",
            twitter: "https://twitter.com/Kur0NekoCoin",
        },
        screens: {
            "createToken": {
                web3: {
                    receiver: "0xE17C02D39178DF74a860826ab33e93f694696849",
                    ccFees: [
                        0.1,
                        0.1,
                        0.1,
                        0.1,
                        0.1,
                        0.0
                    ],

                    tokenTo50Fee: "0x6a8cC171a671e0Aa7f84A0A36D7C1285D3A224D2"
                }
            },
            "swap": {
                web3: {
                    Router: "0x4F42B4E05B48AC23bE2902c0000a99bB2A32d2A0",
                    Tokens: {
                        "1999": [
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            },
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            },
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            }
                        ],
                        "97": [
                            {
                                "nome": "BNB",
                                "bname": "Binance Coin",
                                "icon": "https://bscscan.com/token/images/binance_32.png",
                                "contract": "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd"
                            }, {
                                "nome": "BUSD",
                                "bname": "BUSD Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/logo.png",
                                "contract": "0xaB1a4d4f1D656d2450692D237fdD6C7f9146e814"
                            }, {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0x116526135380E28836C6080f1997645d5A807FAE"
                            },

                            {
                                "nome": "CAKE",
                                "bname": "PancakeSwap Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82/logo.png",
                                "contract": "0xFa60D973F7642B748046464e165A65B7323b0DEE"
                            }

                        ],
                        "56": [
                            {
                                "nome": "BNB",
                                "bname": "Binance Coin",
                                "icon": "https://bscscan.com/token/images/binance_32.png",
                                "contract": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
                            },
                            {
                                "nome": "BUSD",
                                "bname": "BUSD Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/logo.png",
                                "contract": "0xe9e7cea3dedca5984780bafc599bd69add087d56"
                            }, {
                                "nome": "JIJI",
                                "bname": "KURONEKO",
                                "icon": window.location.protocol + "//" + window.location.host + "/icons/jiji.jpg",
                                "contract": "0x6a8cC171a671e0Aa7f84A0A36D7C1285D3A224D2"
                            }, {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0x116526135380E28836C6080f1997645d5A807FAE"
                            },

                            {
                                "nome": "USDT",
                                "bname": "USDT",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x55d398326f99059fF775485246999027B3197955/logo.png",
                                "contract": "0x55d398326f99059fF775485246999027B3197955"
                            }, {
                                "nome": "BTCB",
                                "bname": "BITCOIN",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c/logo.png",
                                "contract": "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c"
                            },
                            {
                                "nome": "ADA",
                                "bname": "Cardano Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47/logo.png",
                                "contract": "0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47"
                            },
                            {
                                "nome": "CAKE",
                                "bname": "PancakeSwap Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82/logo.png",
                                "contract": "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82"
                            }
                        ]
                    }
                }
            },
            "stakepools": {
                factory: "0xe5963660d581DBDcfCaD7a44Bd31716a87fff84c",
                excludes: {
                    '0x4A51BA1Bb9526aa31b967b06Ec2F3557C3e1d3Cc': true,
                    "0x650e5B875035420C872b90cCE0Ee777De30D8C47": true,
                    "0xFC765F0C445b8a6436db9D3D9750C3799Bf58Fd0": true,
                    "0x025dC95E02e33c711B509485BA9233fb228036c4": true,
                    "0x7E6fDfF5487b0e9b97F3B618D3f76410779aA04f": true,
                    "0xEe664C9F3Ed8b4479eE4D98f6919bBc50a285082": true,
                    "0xC41D0610763dc677F704d6A14009Ca3954E4eA26": true,
                    "0xB4C3f82754c97FC43c2f01528BA26E131bd63ec8": true,
                    "0x590B534523dDDa612c58Bd05cdEb05bC1DE013ca": true,
                    "0x8dEc373bbcA468168c3E0bd2BEb5Bcd66731DDd8": true,
                    "0x436080202a6cDAdF2cbf83D6D5dFD33860ecC7cB": true,
                    "0x65D95b37Dd46D3Ab60715637aE91E5801AcABf39": true,
                    "0xC1122978D8cc23F06B26ade13fAdc10bB16aa0E4": true,
                    "0xcc730BB1306e17A6DB7C16a44180F5C1C4B90Cd7": true,

                    "0x98DF35D07f55A1e8885c6D23641546E73009001C": true,
                    "0x82FE030AE76Dd614FE053ca36b80D49879fF5082": true,
                    "0x71C65050D2B48b911F6F869fBDd6d822eE15FF6b": true,
                    "0x560fF6a296D5F84b480aEbf57CFc54E9Faec6354": true,
                    "0xD7984989E7693a19C2358BE526266A76b8A2b7BC": true,
                    "0x46006BEbd30f3864A2B2555f6D88fa3EE8BE342E": true,
                    "0x7B30A0C75a9d0bC3849f6D5BD2F6Bdc921573aa9": true,
                    "0x8423995001780bB80ABBbF24845507e21cA56061": true,
                    "0x0427d0497fd82507666c186B50188526e02F2701": true,
                    "0x4316380ad94458DF90f527b790eDBC81292846db": true,

                    "0x701f1254c5cCb95a09Ed82C6b23e19782fAa47A4": true,
                    "0x46b821064370bDaD28eaA2ECdf2629384CECF820": true,
                    "0x27A634CaF303ec10D5558e585A3dD6354d8ea389": true,
                    "0xAef0332741872259EC50A3cbA1DE82d5790D0bC0": true,
                    "0x583D5Ece5e83816689d2FbD0543f6B962474C1fd": true,
                    "0x41897De6fCa8B42C7233eB42f27756CE0E85cC9e": true,
                    "0xB7d3D8f6D8cCaF58744dF547AAdFFe0CFC48f729": true,
                    "0xcfCf1E93c2E38d2F3328cBDA6b4f22410749aF01": true,
                }
            }
        }

    },
    "casinosworld": {
        Name: "casinosworld",
        logoUrl: window.location.protocol + "//" + window.location.host + "/icons/cw.png",
        Logo: <img src={window.location.protocol + "//" + window.location.host + "/icons/cw.png"}
            style={{
                height: "50px",
                width: "50px",
                margin: "auto",
            }}
        />,
        backgroundColor: "linear-gradient(to bottom right, #000, #000)",
        buttonsColor: "#000",
        modalsColor: "#00000000",
        outbuttonsColor: "#ffe804",
        buttonsBorders: "1px solid #33363f",
        inputsColor: "#16181d",
        inputsBorders: "1px solid #33363f",
        Borders: "#33363f",
        outFont: "#fff",
        innerFont: "#fff",
        innerBFont: "#000",
        redirectTo: '/create/contract',
        Routers:
            [
                '/',
                '/swap',
                '/create/contract',
                '/manager',
                '/pre-sales',
                '/fair-launchs',
                '/private-sales',
                '/pre-sale',
                '/fair-launch',
                '/private-sale',
                '/create/pre-sale',
                '/create/fair-launch',
                '/create/private-sale',
                '/pre-sale/edit',
                '/fair-launch/edit',
                '/private-sale/edit',
                '/lock',
                '/lock/create',

            ],
        menudata: [
            {
                permit: true,//Trade
                subpermit: [
                    {
                        permit: false
                    },
                    {
                        permit: true
                    },
                    {
                        permit: false
                    },
                ]
            },
            {
                permit: false,//Pools
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: false
                    },
                    {
                        permit: true
                    },
                ]
            },
            {
                permit: true,//Dev Hub
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: false
                    },
                    {
                        permit: false
                    },
                    {
                        permit: false
                    },
                    {
                        permit: false
                    },
                ]
            },
            {
                permit: true,//Launchpad
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },

                ]
            },
            {
                permit: true,//lock
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },

                ]
            },
            {
                permit: false,//marketplace
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                ]
            },
            {
                permit: false,//lock
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                ]
            },
        ],
        rpc: "https://bsc-dataseed.binance.org",
        tokenContract: "0x2Ea4f1E162a51b342A62Ae5F7D1506a6EBc98998",
        info: {
            title: "Casino World",
            desc: "",
            buyon: "https://pancakeswap.finance/swap?outputCurrency=0x2Ea4f1E162a51b342A62Ae5F7D1506a6EBc98998",
            name: "Casino World",
            symbol: "CW",
            telegram: "https://t.me/CasinooWorld",
            twitter: "https://x.com/CasinoWord",
        },
        screens: {
            "createToken": {
                web3: {
                    receiver: "0x9c2c848cb2917Df3410B7aaBc44d44735be324bd",
                    ccFees: [
                        0.15,
                        0.15,
                        0.15,
                        0.15,
                        0.15,
                        0.0,
                        0.0,

                        0.0
                    ],

                    tokenTo50Fee: "0x2Ea4f1E162a51b342A62Ae5F7D1506a6EBc98998"
                }
            },
            "swap": {
                web3: {
                    Router: "0x11498dc56b3E87edbD54133e9f7e1bF4637c6818",
                    Tokens: {
                        "1999": [
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            },
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            },
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            }
                        ],
                        "97": [
                            {
                                "nome": "BNB",
                                "bname": "Binance Coin",
                                "icon": "https://bscscan.com/token/images/binance_32.png",
                                "contract": "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd"
                            }, {
                                "nome": "BUSD",
                                "bname": "BUSD Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/logo.png",
                                "contract": "0xaB1a4d4f1D656d2450692D237fdD6C7f9146e814"
                            }, {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0x116526135380E28836C6080f1997645d5A807FAE"
                            },

                            {
                                "nome": "CAKE",
                                "bname": "PancakeSwap Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82/logo.png",
                                "contract": "0xFa60D973F7642B748046464e165A65B7323b0DEE"
                            }

                        ],
                        "56": [
                            {
                                "nome": "BNB",
                                "bname": "Binance Coin",
                                "icon": "https://bscscan.com/token/images/binance_32.png",
                                "contract": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
                            },
                            {
                                "nome": "BUSD",
                                "bname": "BUSD Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/logo.png",
                                "contract": "0xe9e7cea3dedca5984780bafc599bd69add087d56"
                            }, {
                                "nome": "CW",
                                "bname": "Casino World",
                                "icon": window.location.protocol + "//" + window.location.host + "/icons/cw.png",
                                "contract": "0x2Ea4f1E162a51b342A62Ae5F7D1506a6EBc98998"
                            }, {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0x116526135380E28836C6080f1997645d5A807FAE"
                            },

                            {
                                "nome": "USDT",
                                "bname": "USDT",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x55d398326f99059fF775485246999027B3197955/logo.png",
                                "contract": "0x55d398326f99059fF775485246999027B3197955"
                            }, {
                                "nome": "BTCB",
                                "bname": "BITCOIN",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c/logo.png",
                                "contract": "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c"
                            },
                            {
                                "nome": "ADA",
                                "bname": "Cardano Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47/logo.png",
                                "contract": "0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47"
                            },
                            {
                                "nome": "CAKE",
                                "bname": "PancakeSwap Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82/logo.png",
                                "contract": "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82"
                            }
                        ]
                    }
                }
            },
            "stakepools": {
                factory: "0xe5963660d581DBDcfCaD7a44Bd31716a87fff84c",
                excludes: {
                    '0x4A51BA1Bb9526aa31b967b06Ec2F3557C3e1d3Cc': true,
                    "0x650e5B875035420C872b90cCE0Ee777De30D8C47": true,
                    "0xFC765F0C445b8a6436db9D3D9750C3799Bf58Fd0": true,
                    "0x025dC95E02e33c711B509485BA9233fb228036c4": true,
                    "0x7E6fDfF5487b0e9b97F3B618D3f76410779aA04f": true,
                    "0xEe664C9F3Ed8b4479eE4D98f6919bBc50a285082": true,
                    "0xC41D0610763dc677F704d6A14009Ca3954E4eA26": true,
                    "0xB4C3f82754c97FC43c2f01528BA26E131bd63ec8": true,
                    "0x590B534523dDDa612c58Bd05cdEb05bC1DE013ca": true,
                    "0x8dEc373bbcA468168c3E0bd2BEb5Bcd66731DDd8": true,
                    "0x436080202a6cDAdF2cbf83D6D5dFD33860ecC7cB": true,
                    "0x65D95b37Dd46D3Ab60715637aE91E5801AcABf39": true,
                    "0xC1122978D8cc23F06B26ade13fAdc10bB16aa0E4": true,
                    "0xcc730BB1306e17A6DB7C16a44180F5C1C4B90Cd7": true,

                    "0x98DF35D07f55A1e8885c6D23641546E73009001C": true,
                    "0x82FE030AE76Dd614FE053ca36b80D49879fF5082": true,
                    "0x71C65050D2B48b911F6F869fBDd6d822eE15FF6b": true,
                    "0x560fF6a296D5F84b480aEbf57CFc54E9Faec6354": true,
                    "0xD7984989E7693a19C2358BE526266A76b8A2b7BC": true,
                    "0x46006BEbd30f3864A2B2555f6D88fa3EE8BE342E": true,
                    "0x7B30A0C75a9d0bC3849f6D5BD2F6Bdc921573aa9": true,
                    "0x8423995001780bB80ABBbF24845507e21cA56061": true,
                    "0x0427d0497fd82507666c186B50188526e02F2701": true,
                    "0x4316380ad94458DF90f527b790eDBC81292846db": true,

                    "0x701f1254c5cCb95a09Ed82C6b23e19782fAa47A4": true,
                    "0x46b821064370bDaD28eaA2ECdf2629384CECF820": true,
                    "0x27A634CaF303ec10D5558e585A3dD6354d8ea389": true,
                    "0xAef0332741872259EC50A3cbA1DE82d5790D0bC0": true,
                    "0x583D5Ece5e83816689d2FbD0543f6B962474C1fd": true,
                    "0x41897De6fCa8B42C7233eB42f27756CE0E85cC9e": true,
                    "0xB7d3D8f6D8cCaF58744dF547AAdFFe0CFC48f729": true,
                    "0xcfCf1E93c2E38d2F3328cBDA6b4f22410749aF01": true,
                }
            }
        }

    },
    "brazuka": {
        Name: "brazuka",
        logoUrl: window.location.protocol + "//" + window.location.host + "/icons/braz.png",
        Logo: <img src={window.location.protocol + "//" + window.location.host + "/icons/braz.png"}
            style={{
                height: "50px",
                width: "50px",
                margin: "auto",
            }}
        />,
        backgroundColor: "linear-gradient(to bottom right,#252831, #252831)",
        buttonsColor: "#33363f",
        modalsColor: "#00000000",
        outbuttonsColor: "#cbfe63",
        buttonsBorders: "1px solid #cbfe63",
        inputsColor: "#ffffff00",
        inputsBorders: "1px solid #ffffff20",
        Borders: "#ffffff20",
        outFont: "#fff",
        innerFont: "#fff",
        innerBFont: "#222",
        redirectTo: '/create/contract',
        Routers:
            [
                '/',
                '/create/contract',
                '/manager',
                '/pre-sales',
                '/fair-launchs',
                '/private-sales',
                '/pre-sale',
                '/fair-launch',
                '/private-sale',
                '/create/pre-sale',
                '/create/fair-launch',
                '/create/private-sale',
                '/pre-sale/edit',
                '/fair-launch/edit',
                '/private-sale/edit',
                '/lock',
                '/lock/create',
                '/swap',
                '/farms',
                '/stake',
                '/stake/create',
            ],
        menudata: [
            {
                permit: true,//Trade
                subpermit: [
                    {
                        permit: false
                    },
                    {
                        permit: true
                    },
                    {
                        permit: false
                    },
                ]
            },
            {
                permit: true,//Pools
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: false
                    },
                    {
                        permit: true
                    },
                ]
            },
            {
                permit: true,//Dev Hub
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: false
                    },
                    {
                        permit: false
                    },
                ]
            },
            {
                permit: true,//Launchpad
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },

                ]
            },
            {
                permit: true,//lock
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },

                ]
            },
            {
                permit: false,//marketplace
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                ]
            },
            {
                permit: false,//lock
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                ]
            },
        ],
        rpc: "https://bsc-dataseed.binance.org",
        tokenContract: undefined,
        info: {
            title: "Brazuka Sale",
            desc: "DESIGNED TO ANNOUNCE LAUNCHES, EXCHANGE KNOWLEDGE ABOUT THE CRYPTO MARKET ETC..",
            buyon: "https://t.me/brazukaBSC",
            name: "Brazuka Sale",
            symbol: "Brazuka Sale",
            telegram: "https://t.me/BrazukaBlockchain",
            twitter: "https://twitter.com/brazukafinance",
        },
        screens: {
            "createToken": {
                web3: {
                    receiver: "0x4AD3d8EbB831D5125Bae5629866a1442c11dB85B",
                    ccFees: [
                        0.1,
                        0.1,
                        0.1,
                        0.1,
                        0.1,
                        0.0
                    ],

                    tokenTo50Fee: "0x116526135380E28836C6080f1997645d5A807FAE"
                }
            },
            "swap": {
                web3: {
                    Router: "0xA1a39C041221765eb52BaEA2FDBbc77aa92c0dF3",
                    Tokens: {
                        "1999": [
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            },
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            },
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            }
                        ],
                        "97": [
                            {
                                "nome": "BNB",
                                "bname": "Binance Coin",
                                "icon": "https://bscscan.com/token/images/binance_32.png",
                                "contract": "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd"
                            }, {
                                "nome": "BUSD",
                                "bname": "BUSD Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/logo.png",
                                "contract": "0xaB1a4d4f1D656d2450692D237fdD6C7f9146e814"
                            }, {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0x116526135380E28836C6080f1997645d5A807FAE"
                            },

                            {
                                "nome": "CAKE",
                                "bname": "PancakeSwap Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82/logo.png",
                                "contract": "0xFa60D973F7642B748046464e165A65B7323b0DEE"
                            }

                        ],
                        "56": [
                            {
                                "nome": "BNB",
                                "bname": "Binance Coin",
                                "icon": "https://bscscan.com/token/images/binance_32.png",
                                "contract": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
                            },
                            {
                                "nome": "BUSD",
                                "bname": "BUSD Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/logo.png",
                                "contract": "0xe9e7cea3dedca5984780bafc599bd69add087d56"
                            },

                            {
                                "nome": "USDT",
                                "bname": "USDT",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x55d398326f99059fF775485246999027B3197955/logo.png",
                                "contract": "0x55d398326f99059fF775485246999027B3197955"
                            }, {
                                "nome": "BTCB",
                                "bname": "BITCOIN",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c/logo.png",
                                "contract": "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c"
                            },
                            {
                                "nome": "ADA",
                                "bname": "Cardano Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47/logo.png",
                                "contract": "0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47"
                            },
                            {
                                "nome": "CAKE",
                                "bname": "PancakeSwap Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82/logo.png",
                                "contract": "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82"
                            }
                        ]
                    }
                }
            },
            "stakepools": {
                factory: "0xa3eC2f9F6e2f1df9A66899F0E404E96854729CaC",
                excludes: {
                    '0x4A51BA1Bb9526aa31b967b06Ec2F3557C3e1d3Cc': true,
                    "0x650e5B875035420C872b90cCE0Ee777De30D8C47": true,
                    "0xFC765F0C445b8a6436db9D3D9750C3799Bf58Fd0": true,
                    "0x025dC95E02e33c711B509485BA9233fb228036c4": true,
                    "0x7E6fDfF5487b0e9b97F3B618D3f76410779aA04f": true,
                    "0xEe664C9F3Ed8b4479eE4D98f6919bBc50a285082": true,
                    "0xC41D0610763dc677F704d6A14009Ca3954E4eA26": true,
                    "0xB4C3f82754c97FC43c2f01528BA26E131bd63ec8": true,
                    "0x590B534523dDDa612c58Bd05cdEb05bC1DE013ca": true,
                    "0x8dEc373bbcA468168c3E0bd2BEb5Bcd66731DDd8": true,
                    "0x436080202a6cDAdF2cbf83D6D5dFD33860ecC7cB": true,
                    "0x65D95b37Dd46D3Ab60715637aE91E5801AcABf39": true,
                    "0xC1122978D8cc23F06B26ade13fAdc10bB16aa0E4": true,
                    "0xcc730BB1306e17A6DB7C16a44180F5C1C4B90Cd7": true,

                    "0x98DF35D07f55A1e8885c6D23641546E73009001C": true,
                    "0x82FE030AE76Dd614FE053ca36b80D49879fF5082": true,
                    "0x71C65050D2B48b911F6F869fBDd6d822eE15FF6b": true,
                    "0x560fF6a296D5F84b480aEbf57CFc54E9Faec6354": true,
                    "0xD7984989E7693a19C2358BE526266A76b8A2b7BC": true,
                    "0x46006BEbd30f3864A2B2555f6D88fa3EE8BE342E": true,
                    "0x7B30A0C75a9d0bC3849f6D5BD2F6Bdc921573aa9": true,
                    "0x8423995001780bB80ABBbF24845507e21cA56061": true,
                    "0x0427d0497fd82507666c186B50188526e02F2701": true,
                    "0x4316380ad94458DF90f527b790eDBC81292846db": true,

                    "0x701f1254c5cCb95a09Ed82C6b23e19782fAa47A4": true,
                    "0x46b821064370bDaD28eaA2ECdf2629384CECF820": true,
                    "0x27A634CaF303ec10D5558e585A3dD6354d8ea389": true,
                    "0xAef0332741872259EC50A3cbA1DE82d5790D0bC0": true,
                    "0x583D5Ece5e83816689d2FbD0543f6B962474C1fd": true,
                    "0x41897De6fCa8B42C7233eB42f27756CE0E85cC9e": true,
                    "0xB7d3D8f6D8cCaF58744dF547AAdFFe0CFC48f729": true,
                    "0xcfCf1E93c2E38d2F3328cBDA6b4f22410749aF01": true,
                }
            }
        }

    },
    "dodolabs": {
        Name: "dodolabs",
        logoUrl: window.location.protocol + "//" + window.location.host + "/icons/dodo.jpg",
        Logo: <img src={window.location.protocol + "//" + window.location.host + "/icons/dodo.jpg"}
            style={{
                height: "50px",
                width: "50px",
                margin: "auto",
            }}
        />,
        backgroundColor: "linear-gradient(to bottom right,#252831, #252831)",
        buttonsColor: "#252831",
        modalsColor: "#00000000",
        outbuttonsColor: "#ffe804",
        buttonsBorders: "1px solid #33363f",
        inputsColor: "#16181d",
        inputsBorders: "1px solid #33363f",
        Borders: "#33363f",
        outFont: "#fff",
        innerFont: "#fff",
        innerBFont: "#000",
        redirectTo: '/create/contract',
        Routers:
            [
                '/',
                '/create/contract',
                '/manager',
            ],
        menudata: [
            {
                permit: false,//Trade
                subpermit: [
                    {
                        permit: false
                    },
                    {
                        permit: true
                    },
                    {
                        permit: false
                    },
                ]
            },
            {
                permit: false,//Pools
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: false
                    },
                    {
                        permit: true
                    },
                ]
            },
            {
                permit: true,//Dev Hub
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: false
                    },
                    {
                        permit: false
                    },
                    {
                        permit: false
                    },
                    {
                        permit: false
                    },
                ]
            },
            {
                permit: false,//Launchpad
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },

                ]
            },
            {
                permit: false,//lock
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },

                ]
            },
            {
                permit: false,//marketplace
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                ]
            },
            {
                permit: false,//lock
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                ]
            },
        ],
        rpc: "https://bsc-dataseed.binance.org",
        tokenContract: undefined,
        info: {
            title: "DODO LABS",
            desc: "Welcome to Dodo labs",
            buyon: "",
            name: "DODO LABS",
            symbol: "DODO LABS",
            telegram: "https://t.me/Weslehv",
            twitter: "",
        },
        screens: {
            "createToken": {
                web3: {
                    receiver: "0x0d93bA835C467f986cA0A6557F2Ffd7cB45E4e19",
                    ccFees: [
                        0.15,
                        0.15,
                        0.15,
                        0.15,
                        0.15,
                        0.0,
                        0.15,
                        0.15
                    ],

                    tokenTo50Fee: "0x116526135380E28836C6080f1997645d5A807FAE"
                }
            },
            "swap": {
                web3: {
                    Router: "0xA1a39C041221765eb52BaEA2FDBbc77aa92c0dF3",
                    Tokens: {
                        "1999": [
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            },
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            },
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            }
                        ],
                        "97": [
                            {
                                "nome": "BNB",
                                "bname": "Binance Coin",
                                "icon": "https://bscscan.com/token/images/binance_32.png",
                                "contract": "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd"
                            }, {
                                "nome": "BUSD",
                                "bname": "BUSD Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/logo.png",
                                "contract": "0xaB1a4d4f1D656d2450692D237fdD6C7f9146e814"
                            }, {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0x116526135380E28836C6080f1997645d5A807FAE"
                            },

                            {
                                "nome": "CAKE",
                                "bname": "PancakeSwap Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82/logo.png",
                                "contract": "0xFa60D973F7642B748046464e165A65B7323b0DEE"
                            }

                        ],
                        "56": [
                            {
                                "nome": "BNB",
                                "bname": "Binance Coin",
                                "icon": "https://bscscan.com/token/images/binance_32.png",
                                "contract": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
                            },
                            {
                                "nome": "BUSD",
                                "bname": "BUSD Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/logo.png",
                                "contract": "0xe9e7cea3dedca5984780bafc599bd69add087d56"
                            },

                            {
                                "nome": "USDT",
                                "bname": "USDT",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x55d398326f99059fF775485246999027B3197955/logo.png",
                                "contract": "0x55d398326f99059fF775485246999027B3197955"
                            }, {
                                "nome": "BTCB",
                                "bname": "BITCOIN",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c/logo.png",
                                "contract": "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c"
                            },
                            {
                                "nome": "ADA",
                                "bname": "Cardano Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47/logo.png",
                                "contract": "0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47"
                            },
                            {
                                "nome": "CAKE",
                                "bname": "PancakeSwap Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82/logo.png",
                                "contract": "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82"
                            }
                        ]
                    }
                }
            },
            "stakepools": {
                factory: "0xa3eC2f9F6e2f1df9A66899F0E404E96854729CaC",
                excludes: {
                    '0x4A51BA1Bb9526aa31b967b06Ec2F3557C3e1d3Cc': true,
                    "0x650e5B875035420C872b90cCE0Ee777De30D8C47": true,
                    "0xFC765F0C445b8a6436db9D3D9750C3799Bf58Fd0": true,
                    "0x025dC95E02e33c711B509485BA9233fb228036c4": true,
                    "0x7E6fDfF5487b0e9b97F3B618D3f76410779aA04f": true,
                    "0xEe664C9F3Ed8b4479eE4D98f6919bBc50a285082": true,
                    "0xC41D0610763dc677F704d6A14009Ca3954E4eA26": true,
                    "0xB4C3f82754c97FC43c2f01528BA26E131bd63ec8": true,
                    "0x590B534523dDDa612c58Bd05cdEb05bC1DE013ca": true,
                    "0x8dEc373bbcA468168c3E0bd2BEb5Bcd66731DDd8": true,
                    "0x436080202a6cDAdF2cbf83D6D5dFD33860ecC7cB": true,
                    "0x65D95b37Dd46D3Ab60715637aE91E5801AcABf39": true,
                    "0xC1122978D8cc23F06B26ade13fAdc10bB16aa0E4": true,
                    "0xcc730BB1306e17A6DB7C16a44180F5C1C4B90Cd7": true,

                    "0x98DF35D07f55A1e8885c6D23641546E73009001C": true,
                    "0x82FE030AE76Dd614FE053ca36b80D49879fF5082": true,
                    "0x71C65050D2B48b911F6F869fBDd6d822eE15FF6b": true,
                    "0x560fF6a296D5F84b480aEbf57CFc54E9Faec6354": true,
                    "0xD7984989E7693a19C2358BE526266A76b8A2b7BC": true,
                    "0x46006BEbd30f3864A2B2555f6D88fa3EE8BE342E": true,
                    "0x7B30A0C75a9d0bC3849f6D5BD2F6Bdc921573aa9": true,
                    "0x8423995001780bB80ABBbF24845507e21cA56061": true,
                    "0x0427d0497fd82507666c186B50188526e02F2701": true,
                    "0x4316380ad94458DF90f527b790eDBC81292846db": true,

                    "0x701f1254c5cCb95a09Ed82C6b23e19782fAa47A4": true,
                    "0x46b821064370bDaD28eaA2ECdf2629384CECF820": true,
                    "0x27A634CaF303ec10D5558e585A3dD6354d8ea389": true,
                    "0xAef0332741872259EC50A3cbA1DE82d5790D0bC0": true,
                    "0x583D5Ece5e83816689d2FbD0543f6B962474C1fd": true,
                    "0x41897De6fCa8B42C7233eB42f27756CE0E85cC9e": true,
                    "0xB7d3D8f6D8cCaF58744dF547AAdFFe0CFC48f729": true,
                    "0xcfCf1E93c2E38d2F3328cBDA6b4f22410749aF01": true,
                }
            }
        }

    },
    "app.viperking": {
        Name: "VIPER",
        logoUrl: window.location.protocol + "/icons/viper.png",
        Logo: <img
            src={window.location.protocol + "/icons/viper.png"}
            style={{
                height: "50px",
                width: "50px",
                margin: "auto",
            }}
        />,

        backgroundColor: "url( " + window.location.protocol + "/icons/BGVIPER.jpg ) center center / " + (window.innerWidth + window.innerHeight) + "px no-repeat",
        outFont: "#fff",
        topFont: "#0e487b",
        buttonsColor: "#eff7fa",
        modalsColor: "#0e487b",
        innerFont: "#fff",
        outbuttonsColor: "#eff7fa",
        innerBFont: "#222",
        buttonsBorders: "1px solid #00000020",
        inputsColor: "#ffffff00",
        inputsBorders: "1px solid #eff7fa50",
        Borders: "#eff7fa80",
        redirectTo: '/create/contract',
        Routers: [

            '/stake',

            '/stake/create',
            '/projects',
            '/',

            '/swap'
        ],
        menudata: [
            {
                permit: true,//Trade
                subpermit: [
                    {
                        permit: false
                    },
                    {
                        permit: true
                    },
                    {
                        permit: false
                    },
                ]
            },
            {
                permit: true,//Pools
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: false
                    },
                    {
                        permit: false
                    },
                ]
            },
            {
                permit: true,//Dev Hub
                subpermit: [
                    {
                        permit: false
                    },
                    {
                        permit: false
                    },
                    {
                        permit: true
                    },
                    {
                        permit: false
                    },
                    {
                        permit: true
                    },
                    {
                        permit: false
                    },
                ]
            },
            {
                permit: false,//Launchpad
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },

                ]
            },
            {
                permit: false,//lock
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },

                ]
            },
            {
                permit: false,//marketplace
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                ]
            },
            {
                permit: false,//lock
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                ]
            },
        ],
        rpc: "https://bsc-dataseed.binance.org",
        tokenContract: '0x5C9Da47A16D87c675B33Da817572cc4a8508FD06',
        info: {
            title: "Viper King Token – Be part of the financial revolution!",
            desc: "“Viper King Token is a project with several functionalities ...crypto world information portal, own swap, own stake, exclusive NFTs in 3D, NFT stake, e-commerce, much of the portal's profitability will be reflected in the graph.”",
            buyon: window.location.protocol + "/swap?token=0x5C9Da47A16D87c675B33Da817572cc4a8508FD06",
            name: " VIPER KING TOKEN",
            symbol: "VIPER KING",
            telegram: "https://t.me/viperkingtoken",
            twitter: "https://twitter.com/ViperKingToken",
        },
        screens: {
            "createToken": {
                web3: {
                    receiver: "0xdb089b945C46eF1308C6D3BD197337849Dbb1AFE",
                    ccFees: [
                        0.15,
                        0.15,
                        0.15,
                        0.15,
                        0.15,
                        0.0,
                        0.15,
                        0.15
                    ],

                    tokenTo50Fee: "0x116526135380E28836C6080f1997645d5A807FAE"
                }
            },
            "swap": {
                web3: {
                    Fee: 0.0005,
                    Router: "0x8b04d3d30464db750eF113A5688268f0702d0bb9",
                    Tokens: {
                        "1999": [
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            },
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            },
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            }
                        ],
                        "97": [
                            {
                                "nome": "BNB",
                                "bname": "Binance Coin",
                                "icon": "https://bscscan.com/token/images/binance_32.png",
                                "contract": "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd"
                            }, {
                                "nome": "BUSD",
                                "bname": "BUSD Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/logo.png",
                                "contract": "0xaB1a4d4f1D656d2450692D237fdD6C7f9146e814"
                            }, {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0x116526135380E28836C6080f1997645d5A807FAE"
                            },

                            {
                                "nome": "CAKE",
                                "bname": "PancakeSwap Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82/logo.png",
                                "contract": "0xFa60D973F7642B748046464e165A65B7323b0DEE"
                            }
                        ],
                        "56": [
                            {
                                "nome": "BNB",
                                "bname": "Binance Coin",
                                "icon": "https://bscscan.com/token/images/binance_32.png",
                                "contract": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
                            },
                            {
                                "nome": "BUSD",
                                "bname": "BUSD Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/logo.png",
                                "contract": "0xe9e7cea3dedca5984780bafc599bd69add087d56"
                            }, {
                                "nome": "VIPER KING",
                                "bname": "VIPER KING TOKEN",
                                "icon": window.location.protocol + "/icons/viper.png",
                                "contract": "0x5C9Da47A16D87c675B33Da817572cc4a8508FD06"
                            }, {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0x116526135380E28836C6080f1997645d5A807FAE"
                            },

                            {
                                "nome": "USDT",
                                "bname": "USDT",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x55d398326f99059fF775485246999027B3197955/logo.png",
                                "contract": "0x55d398326f99059fF775485246999027B3197955"
                            }, {
                                "nome": "BTCB",
                                "bname": "BITCOIN",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c/logo.png",
                                "contract": "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c"
                            },
                            {
                                "nome": "ADA",
                                "bname": "Cardano Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47/logo.png",
                                "contract": "0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47"
                            },
                            {
                                "nome": "CAKE",
                                "bname": "PancakeSwap Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82/logo.png",
                                "contract": "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82"
                            }
                        ]
                    }
                }
            },
            "stakepools": {
                factory: "0x1099E249dbAFc3aAd6CC1A21B1368c6099e83863",
                excludes: {
                    "0x82FE030AE76Dd614FE053ca36b80D49879fF5082": true,
                    '0x4A51BA1Bb9526aa31b967b06Ec2F3557C3e1d3Cc': true,
                    "0x650e5B875035420C872b90cCE0Ee777De30D8C47": true,
                    "0xFC765F0C445b8a6436db9D3D9750C3799Bf58Fd0": true,
                    "0x025dC95E02e33c711B509485BA9233fb228036c4": true,
                    "0x7E6fDfF5487b0e9b97F3B618D3f76410779aA04f": true,
                    "0xEe664C9F3Ed8b4479eE4D98f6919bBc50a285082": true,
                    "0xC41D0610763dc677F704d6A14009Ca3954E4eA26": true,
                    "0xB4C3f82754c97FC43c2f01528BA26E131bd63ec8": true,
                    "0x590B534523dDDa612c58Bd05cdEb05bC1DE013ca": true,
                    "0x8dEc373bbcA468168c3E0bd2BEb5Bcd66731DDd8": true,
                    "0x436080202a6cDAdF2cbf83D6D5dFD33860ecC7cB": true,
                    "0x65D95b37Dd46D3Ab60715637aE91E5801AcABf39": true,
                    "0xC1122978D8cc23F06B26ade13fAdc10bB16aa0E4": true,
                    "0xa8b09b2cF2b1639c2A6aB0a32b5f73Dc61FA4318": true,
                    "0x9321eeE089364182C2A148FEb493CA0931d4Dab4": true,

                }
            }
        }
    },
    "babymartik": {
        Name: "Baby Martik",
        logoUrl: window.location.protocol + "/icons/bbmtk.jpeg",
        Logo: <img
            src={window.location.protocol + "/icons/bbmtk.jpeg"}
            style={{
                height: "50px",
                width: "50px",
                margin: "auto",
            }}
        />,
        backgroundColor: "linear-gradient(to bottom right, #24b574, #24b574)",
        buttonsColor: " #24b574",
        modalsColor: " #24b574",
        outbuttonsColor: "#fff",
        buttonsBorders: "1px solid #ffffff20",
        inputsColor: "#ffffff00",
        inputsBorders: "1px solid #ffffff20",
        Borders: "#ffffff20",
        outFont: "#fff",
        innerFont: "#fff",
        innerBFont: "#222",
        redirectTo: '/create/contract',
        Routers:
            [
                '/',

                '/stake',
                '/stake/create',

                '/terms',
                //'/whitepaper',

            ],
        menudata: [
            {
                permit: false,//Trade
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                ]
            },
            {
                permit: true,//Pools
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                ]
            },
            {
                permit: true,//Dev Hub
                subpermit: [
                    {
                        permit: false
                    },
                    {
                        permit: false
                    },
                    {
                        permit: true
                    },
                    {
                        permit: false
                    },
                    {
                        permit: false
                    },
                    {
                        permit: false
                    },
                ]
            },
            {
                permit: false,//Launchpad
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },

                ]
            },
            {
                permit: false,//lock
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },

                ]
            },
            {
                permit: false,//marketplace
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                ]
            },
            {
                permit: true,//lock
                subpermit: [
                    {
                        permit: false
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                ]
            },
        ],
        rpc: "https://bsc-dataseed.binance.org",
        tokenContract: "0x4F45263693e3a1bF0EF2920F409505431b3A6805",
        info: {
            title: "Martik/Baby Martik",
            desc: "Martik is innovation, several utilities light years away, a bridge that uses the same supply in several networks",
            buyon: "http://martik.site/swap?token=0x4F45263693e3a1bF0EF2920F409505431b3A6805",
            name: "Baby Martik",
            symbol: "BMTK",
            telegram: "http://t.me/martik_en",
            twitter: "https://twitter.com/martikcrypto",
        },
        screens: {
            "createToken": {
                web3: {
                    receiver: "0xA4de20856640d59A146393ac8CcF3F8e2133DF33",
                    ccFees: [
                        0.1,
                        0.1,
                        0.1,
                        0.1,
                        0.1,
                        0.0
                    ],

                    tokenTo50Fee: "0x116526135380E28836C6080f1997645d5A807FAE"
                }
            },
            "swap": {
                web3: {
                    Router: "0xA1a39C041221765eb52BaEA2FDBbc77aa92c0dF3",
                    Tokens: {
                        "1999": [
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            },
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            },
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            }
                        ],
                        "97": [
                            {
                                "nome": "BNB",
                                "bname": "Binance Coin",
                                "icon": "https://bscscan.com/token/images/binance_32.png",
                                "contract": "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd"
                            }, {
                                "nome": "BUSD",
                                "bname": "BUSD Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/logo.png",
                                "contract": "0xaB1a4d4f1D656d2450692D237fdD6C7f9146e814"
                            }, {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0x116526135380E28836C6080f1997645d5A807FAE"
                            },

                            {
                                "nome": "CAKE",
                                "bname": "PancakeSwap Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82/logo.png",
                                "contract": "0xFa60D973F7642B748046464e165A65B7323b0DEE"
                            }

                        ],
                        "56": [
                            {
                                "nome": "BNB",
                                "bname": "Binance Coin",
                                "icon": "https://bscscan.com/token/images/binance_32.png",
                                "contract": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
                            },
                            {
                                "nome": "BUSD",
                                "bname": "BUSD Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/logo.png",
                                "contract": "0xe9e7cea3dedca5984780bafc599bd69add087d56"
                            }, {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0x116526135380E28836C6080f1997645d5A807FAE"
                            },

                            {
                                "nome": "USDT",
                                "bname": "USDT",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x55d398326f99059fF775485246999027B3197955/logo.png",
                                "contract": "0x55d398326f99059fF775485246999027B3197955"
                            }, {
                                "nome": "BTCB",
                                "bname": "BITCOIN",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c/logo.png",
                                "contract": "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c"
                            },
                            {
                                "nome": "ADA",
                                "bname": "Cardano Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47/logo.png",
                                "contract": "0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47"
                            },
                            {
                                "nome": "CAKE",
                                "bname": "PancakeSwap Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82/logo.png",
                                "contract": "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82"
                            }
                        ]
                    }
                }
            },
            "stakepools": {
                factory: "0xC1b4AcC0E4420369BE3C634777F890e42007327B",
                excludes: {
                    '0x4A51BA1Bb9526aa31b967b06Ec2F3557C3e1d3Cc': true,
                    "0x650e5B875035420C872b90cCE0Ee777De30D8C47": true,
                    "0xFC765F0C445b8a6436db9D3D9750C3799Bf58Fd0": true,
                    "0x025dC95E02e33c711B509485BA9233fb228036c4": true,
                    "0x7E6fDfF5487b0e9b97F3B618D3f76410779aA04f": true,
                    "0xEe664C9F3Ed8b4479eE4D98f6919bBc50a285082": true,
                    "0xC41D0610763dc677F704d6A14009Ca3954E4eA26": true,
                    "0xB4C3f82754c97FC43c2f01528BA26E131bd63ec8": true,
                    "0x590B534523dDDa612c58Bd05cdEb05bC1DE013ca": true,
                    "0x8dEc373bbcA468168c3E0bd2BEb5Bcd66731DDd8": true,
                    "0x436080202a6cDAdF2cbf83D6D5dFD33860ecC7cB": true,
                    "0x65D95b37Dd46D3Ab60715637aE91E5801AcABf39": true,
                    "0xC1122978D8cc23F06B26ade13fAdc10bB16aa0E4": true,
                    "0xcc730BB1306e17A6DB7C16a44180F5C1C4B90Cd7": true,

                    "0x98DF35D07f55A1e8885c6D23641546E73009001C": true,
                }
            }

        }

    },
    "app.urusswap": {
        Name: "UrusPay",
        logoUrl: window.location.protocol + "/icons/urus.png",
        Logo: <img
            src={window.location.protocol + "/icons/urus.png"}
            style={{
                height: "50px",
                width: "50px",
                margin: "auto",
            }}
        />,
        backgroundColor: "linear-gradient(to bottom right, #021327, #021327)",
        buttonsColor: "#021327",
        modalsColor: "#021327",
        outbuttonsColor: "#fff",
        buttonsBorders: "1px solid #ffffff20",
        inputsColor: "#ffffff00",
        inputsBorders: "1px solid #ffffff20",
        Borders: "#ffffff20",
        outFont: "#fff",
        innerFont: "#fff",
        innerBFont: "#222",
        redirectTo: '/create/contract',
        Routers:
            [
                '/',

                '/pump-sales',

                '/pump-sale',

                '/create/pump-sale',

                '/pump-sale/edit',
                '/swap',

            ],
        menudata: [
            {
                permit: true,//Trade
                subpermit: [
                    {
                        permit: false
                    },
                    {
                        permit: true
                    },
                    {
                        permit: false
                    },
                ]
            },
            {
                permit: false,//Pools
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                ]
            },
            {
                permit: false,//Dev Hub
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                ]
            },
            {
                permit: true,//Launchpad
                subpermit: [
                    {
                        permit: false
                    },
                    {
                        permit: false
                    },
                    {
                        permit: false
                    },
                    {
                        permit: true
                    },
                    {
                        permit: false
                    },
                    {
                        permit: false
                    },
                    {
                        permit: false
                    },
                    {
                        permit: true
                    },

                ]
            },
            {
                permit: false,//lock
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },

                ]
            },
            {
                permit: false,//marketplace
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                ]
            },
            {
                permit: false,//lock
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                ]
            },
        ],
        rpc: "https://bsc-dataseed.binance.org",
        tokenContract: "0x2Bd6825b78073AcB4936215b1d8dce0B8ea8A505",
        info: {
            title: "Welcome to UrusPay",
            desc: "Your payments platform. Company related to payment solutions",
            buyon: window.location.href + "/swap?token=0x2Bd6825b78073AcB4936215b1d8dce0B8ea8A505",
            name: "UrusPay",
            symbol: "URPY",
            telegram: "https://t.me/UrusSwap",
            twitter: "https://twitter.com/TokenUrus",
        },
        screens: {
            "createToken": {
                web3: {
                    receiver: "0xF89f81D3FE23D9Ea0E5Ae5A6383370Cb0B229e21",
                    ccFees: [
                        0.1,
                        0.1,
                        0.1,
                        0.1,
                        0.1,
                        0.0
                    ],

                    tokenTo50Fee: "0x2Bd6825b78073AcB4936215b1d8dce0B8ea8A505"
                }
            },
            "swap": {
                web3: {
                    Router: "0x3Ec0a3c414987A2b0DeD6fE03C9a4Be5D193bCb8",
                    Tokens: {
                        "1999": [
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            },
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            },
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            }
                        ],
                        "97": [
                            {
                                "nome": "BNB",
                                "bname": "Binance Coin",
                                "icon": "https://bscscan.com/token/images/binance_32.png",
                                "contract": "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd"
                            }, {
                                "nome": "BUSD",
                                "bname": "BUSD Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/logo.png",
                                "contract": "0xaB1a4d4f1D656d2450692D237fdD6C7f9146e814"
                            }, {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0x116526135380E28836C6080f1997645d5A807FAE"
                            },

                            {
                                "nome": "CAKE",
                                "bname": "PancakeSwap Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82/logo.png",
                                "contract": "0xFa60D973F7642B748046464e165A65B7323b0DEE"
                            }

                        ],
                        "56": [
                            {
                                "nome": "BNB",
                                "bname": "Binance Coin",
                                "icon": "https://bscscan.com/token/images/binance_32.png",
                                "contract": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
                            },
                            {
                                "nome": "BUSD",
                                "bname": "BUSD Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/logo.png",
                                "contract": "0xe9e7cea3dedca5984780bafc599bd69add087d56"
                            }, {
                                "nome": "URPY",
                                "bname": "UrusPay",
                                "icon": window.location.protocol + "/icons/urus.png",
                                "contract": "0x2Bd6825b78073AcB4936215b1d8dce0B8ea8A505"
                            },

                            {
                                "nome": "USDT",
                                "bname": "USDT",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x55d398326f99059fF775485246999027B3197955/logo.png",
                                "contract": "0x55d398326f99059fF775485246999027B3197955"
                            }, {
                                "nome": "BTCB",
                                "bname": "BITCOIN",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c/logo.png",
                                "contract": "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c"
                            },
                            {
                                "nome": "ADA",
                                "bname": "Cardano Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47/logo.png",
                                "contract": "0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47"
                            },
                            {
                                "nome": "CAKE",
                                "bname": "PancakeSwap Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82/logo.png",
                                "contract": "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82"
                            }
                        ]
                    }
                }
            },
            "stakepools": {
                factory: "0xC1b4AcC0E4420369BE3C634777F890e42007327B",
                excludes: {
                    '0x4A51BA1Bb9526aa31b967b06Ec2F3557C3e1d3Cc': true,
                    "0x650e5B875035420C872b90cCE0Ee777De30D8C47": true,
                    "0xFC765F0C445b8a6436db9D3D9750C3799Bf58Fd0": true,
                    "0x025dC95E02e33c711B509485BA9233fb228036c4": true,
                    "0x7E6fDfF5487b0e9b97F3B618D3f76410779aA04f": true,
                    "0xEe664C9F3Ed8b4479eE4D98f6919bBc50a285082": true,
                    "0xC41D0610763dc677F704d6A14009Ca3954E4eA26": true,
                    "0xB4C3f82754c97FC43c2f01528BA26E131bd63ec8": true,
                    "0x590B534523dDDa612c58Bd05cdEb05bC1DE013ca": true,
                    "0x8dEc373bbcA468168c3E0bd2BEb5Bcd66731DDd8": true,
                    "0x436080202a6cDAdF2cbf83D6D5dFD33860ecC7cB": true,
                    "0x65D95b37Dd46D3Ab60715637aE91E5801AcABf39": true,
                    "0xC1122978D8cc23F06B26ade13fAdc10bB16aa0E4": true,
                    "0xcc730BB1306e17A6DB7C16a44180F5C1C4B90Cd7": true,

                    "0x98DF35D07f55A1e8885c6D23641546E73009001C": true,
                }
            }

        }

    },
    "dgiroto": {
        Name: "dgiroto",
        logoUrl: "https://martik.site/icons/multistake/DG.png",
        Logo: <img
            src={"https://martik.site/icons/multistake/DG.png"}
            style={{
                height: "50px",
                width: "50px",
                margin: "auto",
                borderRadius: "90px"
            }}
        />,
        backgroundColor: "linear-gradient(to bottom right, #fb39a4, #fb39a4)",
        buttonsColor: "#fb39a4",
        modalsColor: "#fb39a4",
        outbuttonsColor: "#fff",
        buttonsBorders: "1px solid #ffffff20",
        inputsColor: "#ffffff00",
        inputsBorders: "1px solid #ffffff20",
        Borders: "#ffffff20",
        outFont: "#fff",
        innerFont: "#fff",
        innerBFont: "#222",
        redirectTo: '/create/contract',
        Routers:
            [
                '/',
                '/projects',
                '/p2p',
                '/p2p/sell',
                '/pre-sales',
                '/fair-launchs',
                '/private-sales',
                '/pump-sales',
                '/pre-sale',
                '/fair-launch',
                '/private-sale',
                '/pump-sale',
                "/pools/nfts",
                "/pools/nfts/create",
                '/create/pre-sale',
                '/create/fair-launch',
                '/create/private-sale',
                '/create/pump-sale',
                '/pre-sale/edit',
                '/fair-launch/edit',
                '/private-sale/edit',
                '/pump-sale/edit',
                '/swap',
                '/smart-bridge',
                '/create/contract',
                '/betting',
                '/multistake',
                '/exchange',
                '/volume-bot',
                '/farms',
                '/manager',
                '/stake',
                '/stake/create',
                '/addlpv2',
                '/marketplace',
                '/marketplace/publish',
                '/marketplace/create',
                '/marketplace/edit/profile',
                '/marketplace/profile',
                '/lock',
                '/lock/data',
                '/lock/create',
                '/lock/update',
                '/lock/transfer',
                '/terms',
                '/whitepaper',
                '/nft/pools',
                '/explorer',
                '/explorer/address',
                '/explorer/token',
                '/explorer/block',
                '/explorer/tx',
            ],
        menudata: [
            {
                permit: true,//Trade
                subpermit: [
                    {
                        permit: false
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                ]
            },
            {
                permit: true,//Pools
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                ]
            },
            {
                permit: true,//Dev Hub
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                ]
            },
            {
                permit: true,//Launchpad
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },

                ]
            },
            {
                permit: true,//lock
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },

                ]
            },
            {
                permit: false,//marketplace
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: false
                    },
                    {
                        permit: false
                    },
                ]
            },
            {
                permit: true,//lock
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                ]
            },
        ],
        rpc: "https://bsc-dataseed.binance.org",
        tokenContract: "0xa071818bcb54b51b9a24ad6f8f13a3f481c2af58",
        info: {
            title: "D'Giroto Professional: Revolucionando a Indústria",
            desc: "Bem-vindo à D'Giroto Professional! Somos uma empresa em ascensão na indústria de produtos de cabelo, oferecendo uma ampla gama de produtos de alta qualidade. Estamos entusiasmados em trazer a tokenização para a nossa empresa, permitindo aos nossos clientes e investidores participarem do nosso crescimento e sucesso.",
            buyon: window.location.protocol + "/swap?token=0xa071818bcb54b51b9a24ad6f8f13a3f481c2af58",
            name: "D'Giroto Professional",
            symbol: "DGP",
            telegram: "https://t.me/dgirotoprofessional",
            twitter: "https://x.com/dgiroto_crypto",
        },
        screens: {
            "createToken": {
                web3: {
                    receiver: "0xA4de20856640d59A146393ac8CcF3F8e2133DF33",
                    ccFees: [
                        0.1,
                        0.1,
                        0.1,
                        0.1,
                        0.1,
                        0.0
                    ],

                    tokenTo50Fee: "0xa071818bcb54b51b9a24ad6f8f13a3f481c2af58"
                }
            },
            "swap": {
                web3: {
                    Router: "0xA1a39C041221765eb52BaEA2FDBbc77aa92c0dF3",
                    Tokens: {
                        "1999": [
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            },
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            },
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            }
                        ],
                        "97": [
                            {
                                "nome": "BNB",
                                "bname": "Binance Coin",
                                "icon": "https://bscscan.com/token/images/binance_32.png",
                                "contract": "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd"
                            }, {
                                "nome": "BUSD",
                                "bname": "BUSD Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/logo.png",
                                "contract": "0xaB1a4d4f1D656d2450692D237fdD6C7f9146e814"
                            }, {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0x116526135380E28836C6080f1997645d5A807FAE"
                            },

                            {
                                "nome": "CAKE",
                                "bname": "PancakeSwap Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82/logo.png",
                                "contract": "0xFa60D973F7642B748046464e165A65B7323b0DEE"
                            }

                        ],
                        "56": [
                            {
                                "nome": "BNB",
                                "bname": "Binance Coin",
                                "icon": "https://bscscan.com/token/images/binance_32.png",
                                "contract": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
                            },
                            {
                                "nome": "BUSD",
                                "bname": "BUSD Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/logo.png",
                                "contract": "0xe9e7cea3dedca5984780bafc599bd69add087d56"
                            }, {
                                "nome": "DGP",
                                "bname": "D'Giroto Professional",
                                "icon": "https://martik.site/icons/multistake/DG.png",
                                "contract": "0xa071818bcb54b51b9a24ad6f8f13a3f481c2af58"
                            },

                            {
                                "nome": "USDT",
                                "bname": "USDT",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x55d398326f99059fF775485246999027B3197955/logo.png",
                                "contract": "0x55d398326f99059fF775485246999027B3197955"
                            }, {
                                "nome": "BTCB",
                                "bname": "BITCOIN",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c/logo.png",
                                "contract": "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c"
                            },
                            {
                                "nome": "ADA",
                                "bname": "Cardano Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47/logo.png",
                                "contract": "0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47"
                            },
                            {
                                "nome": "CAKE",
                                "bname": "PancakeSwap Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82/logo.png",
                                "contract": "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82"
                            }
                        ]
                    }
                }
            },
            "stakepools": {
                factory: "0xC1b4AcC0E4420369BE3C634777F890e42007327B",
                excludes: {
                    '0x4A51BA1Bb9526aa31b967b06Ec2F3557C3e1d3Cc': true,
                    "0x650e5B875035420C872b90cCE0Ee777De30D8C47": true,
                    "0xFC765F0C445b8a6436db9D3D9750C3799Bf58Fd0": true,
                    "0x025dC95E02e33c711B509485BA9233fb228036c4": true,
                    "0x7E6fDfF5487b0e9b97F3B618D3f76410779aA04f": true,
                    "0xEe664C9F3Ed8b4479eE4D98f6919bBc50a285082": true,
                    "0xC41D0610763dc677F704d6A14009Ca3954E4eA26": true,
                    "0xB4C3f82754c97FC43c2f01528BA26E131bd63ec8": true,
                    "0x590B534523dDDa612c58Bd05cdEb05bC1DE013ca": true,
                    "0x8dEc373bbcA468168c3E0bd2BEb5Bcd66731DDd8": true,
                    "0x436080202a6cDAdF2cbf83D6D5dFD33860ecC7cB": true,
                    "0x65D95b37Dd46D3Ab60715637aE91E5801AcABf39": true,
                    "0xC1122978D8cc23F06B26ade13fAdc10bB16aa0E4": true,
                    "0xcc730BB1306e17A6DB7C16a44180F5C1C4B90Cd7": true,

                    "0x98DF35D07f55A1e8885c6D23641546E73009001C": true,
                }
            },
            "nftpools": {
                factory: "0xb8d12CD561Df7f78Fa6dD4630cef29d9A0d5490f",
                buypool: "0x0c982F60D66152Dd0c5Be9D72D86Ba1512A65184",
                nfts: [
                    "http://" + window.location.host + "/icons/game/common.jpeg",
                    "http://" + window.location.host + "/icons/game/rare.jpeg",
                    "http://" + window.location.host + "/icons/game/epic.jpeg",
                    "http://" + window.location.host + "/icons/game/legendary.jpeg",
                    "http://" + window.location.host + "/icons/game/slegendary.jpeg"
                ],
                excludes: {
                    '0x4A51BA1Bb9526aa31b967b06Ec2F3557C3e1d3Cc': true,
                    "0x650e5B875035420C872b90cCE0Ee777De30D8C47": true,
                    "0xFC765F0C445b8a6436db9D3D9750C3799Bf58Fd0": true,
                    "0x025dC95E02e33c711B509485BA9233fb228036c4": true,
                    "0x7E6fDfF5487b0e9b97F3B618D3f76410779aA04f": true,
                    "0xEe664C9F3Ed8b4479eE4D98f6919bBc50a285082": true,
                    "0xC41D0610763dc677F704d6A14009Ca3954E4eA26": true,
                    "0xB4C3f82754c97FC43c2f01528BA26E131bd63ec8": true,
                    "0x590B534523dDDa612c58Bd05cdEb05bC1DE013ca": true,
                    "0x8dEc373bbcA468168c3E0bd2BEb5Bcd66731DDd8": true,
                    "0x436080202a6cDAdF2cbf83D6D5dFD33860ecC7cB": true,
                    "0x65D95b37Dd46D3Ab60715637aE91E5801AcABf39": true,
                    "0xC1122978D8cc23F06B26ade13fAdc10bB16aa0E4": true,
                    "0xcc730BB1306e17A6DB7C16a44180F5C1C4B90Cd7": true,

                    "0x98DF35D07f55A1e8885c6D23641546E73009001C": true,
                }
            }

        }

    },
    "glowup": {
        Name: "Glow Up",
        logoUrl: window.location.protocol + "/icons/kUbjAwy.png",
        Logo: <img
            src={window.location.protocol + "/icons/kUbjAwy.png"}
            style={{
                height: "50px",
                width: "50px",
                margin: "auto",
                borderRadius: "90px"
            }}
        />,
        backgroundColor: "url('" + "https://i.imgur.com/GOKZPdr.jpeg" + " ') center center / " + (window.innerWidth + window.innerHeight) + "px no-repeat",
        buttonsColor: "#000000",
        modalsColor: "#000",
        outbuttonsColor: "#fff",
        buttonsBorders: "1px solid #ffffff20",
        inputsColor: "#ffffff00",
        inputsBorders: "1px solid #ffffff20",
        Borders: "#ffffff20",
        outFont: "#fff",
        innerFont: "#fff",
        innerBFont: "#222",
        redirectTo: '/create/contract',
        Routers:
            [
                '/',

                "/pools/nfts",
                "/pools/nfts/create",

                '/swap',


            ],
        menudata: [
            {
                permit: true,//Trade
                subpermit: [
                    {
                        permit: false
                    },
                    {
                        permit: true
                    },
                    {
                        permit: false
                    },
                ]
            },
            {
                permit: true,//Pools
                subpermit: [
                    {
                        permit: false
                    },
                    {
                        permit: false
                    },
                    {
                        permit: false
                    },
                    {
                        permit: true
                    },
                ]
            },
            {
                permit: false,//Dev Hub
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                ]
            },
            {
                permit: false,//Launchpad
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },

                ]
            },
            {
                permit: false,//lock
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },

                ]
            },
            {
                permit: false,//marketplace
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: false
                    },
                    {
                        permit: false
                    },
                ]
            },
            {
                permit: false,//lock
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                ]
            },
        ],
        rpc: "https://bsc-dataseed.binance.org",
        tokenContract: "0x32c5dc3cb1ae3911382e6a0aebfbcca3ea88318e",
        info: {
            title: "Glowup token",
            desc: "PROJECT BASED ON A PHYSICAL COMPANY STORES E-COMMERCE APP AND FRANCHISES LOYALTY SYSTEM WITH PARITY IN THE GLOW UP ECOSYSTEM CRYPTO PROJECT.",
            buyon: window.location.protocol + "/swap?token=0x32c5dc3cb1ae3911382e6a0aebfbcca3ea88318e",
            name: "Glowup token",
            symbol: "Glowup",
            telegram: "https://t.me/glowuptokencripto",
            twitter: "https://twitter.com/glowuptoken",
        },
        screens: {
            "createToken": {
                web3: {
                    receiver: "0xd4a4A8341353634Eff1D9531307508d0Ab86ee5F",
                    ccFees: [
                        0.1,
                        0.1,
                        0.1,
                        0.1,
                        0.1,
                        0.0
                    ],

                    tokenTo50Fee: "0x32c5dc3cb1ae3911382e6a0aebfbcca3ea88318e"
                }
            },
            "swap": {
                web3: {
                    Router: "0x769F314245D6c5c326a289691A6368DFd4aDE6FB",
                    Tokens: {
                        "1999": [
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            },
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            },
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            }
                        ],
                        "97": [
                            {
                                "nome": "BNB",
                                "bname": "Binance Coin",
                                "icon": "https://bscscan.com/token/images/binance_32.png",
                                "contract": "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd"
                            }, {
                                "nome": "BUSD",
                                "bname": "BUSD Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/logo.png",
                                "contract": "0xaB1a4d4f1D656d2450692D237fdD6C7f9146e814"
                            }, {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0x116526135380E28836C6080f1997645d5A807FAE"
                            },

                            {
                                "nome": "CAKE",
                                "bname": "PancakeSwap Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82/logo.png",
                                "contract": "0xFa60D973F7642B748046464e165A65B7323b0DEE"
                            }

                        ],
                        "56": [
                            {
                                "nome": "BNB",
                                "bname": "Binance Coin",
                                "icon": "https://bscscan.com/token/images/binance_32.png",
                                "contract": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
                            },
                            {
                                "nome": "BUSD",
                                "bname": "BUSD Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/logo.png",
                                "contract": "0xe9e7cea3dedca5984780bafc599bd69add087d56"
                            }, {
                                "nome": "Glowup",
                                "bname": "Glowup token",
                                "icon": window.location.protocol + "/icons/kUbjAwy.png",
                                "contract": "0x32c5dc3cb1ae3911382e6a0aebfbcca3ea88318e"
                            },

                            {
                                "nome": "USDT",
                                "bname": "USDT",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x55d398326f99059fF775485246999027B3197955/logo.png",
                                "contract": "0x55d398326f99059fF775485246999027B3197955"
                            }, {
                                "nome": "BTCB",
                                "bname": "BITCOIN",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c/logo.png",
                                "contract": "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c"
                            },
                            {
                                "nome": "ADA",
                                "bname": "Cardano Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47/logo.png",
                                "contract": "0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47"
                            },
                            {
                                "nome": "CAKE",
                                "bname": "PancakeSwap Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82/logo.png",
                                "contract": "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82"
                            }
                        ]
                    }
                }
            },
            "stakepools": {
                factory: "0xC1b4AcC0E4420369BE3C634777F890e42007327B",
                excludes: {
                    '0x4A51BA1Bb9526aa31b967b06Ec2F3557C3e1d3Cc': true,
                    "0x650e5B875035420C872b90cCE0Ee777De30D8C47": true,
                    "0xFC765F0C445b8a6436db9D3D9750C3799Bf58Fd0": true,
                    "0x025dC95E02e33c711B509485BA9233fb228036c4": true,
                    "0x7E6fDfF5487b0e9b97F3B618D3f76410779aA04f": true,
                    "0xEe664C9F3Ed8b4479eE4D98f6919bBc50a285082": true,
                    "0xC41D0610763dc677F704d6A14009Ca3954E4eA26": true,
                    "0xB4C3f82754c97FC43c2f01528BA26E131bd63ec8": true,
                    "0x590B534523dDDa612c58Bd05cdEb05bC1DE013ca": true,
                    "0x8dEc373bbcA468168c3E0bd2BEb5Bcd66731DDd8": true,
                    "0x436080202a6cDAdF2cbf83D6D5dFD33860ecC7cB": true,
                    "0x65D95b37Dd46D3Ab60715637aE91E5801AcABf39": true,
                    "0xC1122978D8cc23F06B26ade13fAdc10bB16aa0E4": true,
                    "0xcc730BB1306e17A6DB7C16a44180F5C1C4B90Cd7": true,

                    "0x98DF35D07f55A1e8885c6D23641546E73009001C": true,
                }
            },
            "nftpools": {
                factory:"0xDc8929A4bF6509A6363EFB4B38fbF12dC3720A67",//"0x998189f075c1388079BB6d1b81F2B077cAdE6E71",
                factoryv2: "0x998189f075c1388079BB6d1b81F2B077cAdE6E71",//"",
                buypool: "0x0D88d17fFE2E1615DdFE4Ea8887993706D789C47",//"0xD0187e14a3B0dE27E14fF9487f31eDe876DE518C", //"0xe79bf2aAA0BBe5F6c210CEE82BcF6cDb13b820e6",
                nfts: [
                    "https://martikstorage.onrender.com/uploads/AFhCyEHnjLgllgu9JCVoqYlDfrnHMzSq7ohVmK9REwq2J7HkrO3M1nstufekUlal.jpeg",
                    "https://martikstorage.onrender.com/uploads/M8mKOFF22QVChjzZri47J5PE5BBGPTYb60SOZmgQUh8WNFJG9cdFa2voIkaDmd0C.jpeg",
                    "https://martikstorage.onrender.com/uploads/6grvMYbveepmfpOAJdIMA3kmRy4OfUCZS0MUmU0BmbgxXtgn3xbFcPu0QopyRVGc.jpeg",
                    "https://martikstorage.onrender.com/uploads/wZ03mb2xZx4VH73ePDaLa0vSLVNnDFVBWNp4fuZCYCsKO8uueZ2aqtcw5x8fa3JA.jpeg",
                    "https://martikstorage.onrender.com/uploads/Uz4e1pdUbKgt7yI5fAQ8Ilr3ItAUAw36KTmn4H33otNxSPtmz3pkYYP2oZrCKBbu.jpeg"
                ],
                excludes: {
                    '0x4A51BA1Bb9526aa31b967b06Ec2F3557C3e1d3Cc': true,
                    "0x650e5B875035420C872b90cCE0Ee777De30D8C47": true,
                    "0xFC765F0C445b8a6436db9D3D9750C3799Bf58Fd0": true,
                    "0x025dC95E02e33c711B509485BA9233fb228036c4": true,
                    "0x7E6fDfF5487b0e9b97F3B618D3f76410779aA04f": true,
                    "0xEe664C9F3Ed8b4479eE4D98f6919bBc50a285082": true,
                    "0xC41D0610763dc677F704d6A14009Ca3954E4eA26": true,
                    "0xB4C3f82754c97FC43c2f01528BA26E131bd63ec8": true,
                    "0x590B534523dDDa612c58Bd05cdEb05bC1DE013ca": true,
                    "0x8dEc373bbcA468168c3E0bd2BEb5Bcd66731DDd8": true,
                    "0x436080202a6cDAdF2cbf83D6D5dFD33860ecC7cB": true,
                    "0x65D95b37Dd46D3Ab60715637aE91E5801AcABf39": true,
                    "0xC1122978D8cc23F06B26ade13fAdc10bB16aa0E4": true,
                    "0xcc730BB1306e17A6DB7C16a44180F5C1C4B90Cd7": true,

                    "0x98DF35D07f55A1e8885c6D23641546E73009001C": true,
                }
            }

        }

    },
    "buyback70": {
        Name: "Buyback70",
        logoUrl: "https://i.imgur.com/fU3cgF8.png",
        Logo: <img
            src={"https://i.imgur.com/fU3cgF8.png"}
            style={{
                height: "50px",
                width: "50px",
                margin: "auto",
                borderRadius: "90px"
            }}
        />,
        backgroundColor: "#ff3d00",
        buttonsColor: "#000000",
        modalsColor: "#000",
        outbuttonsColor: "#fff",
        buttonsBorders: "1px solid #ffffff20",
        inputsColor: "#ffffff00",
        inputsBorders: "1px solid #ffffff20",
        Borders: "#ffffff20",
        outFont: "#fff",
        innerFont: "#fff",
        innerBFont: "#222",
        redirectTo: '/create/contract',
        Routers:
            [
                '/',

                "/pools/nfts",
                "/pools/nfts/create",

                '/swap',


            ],
        menudata: [
            {
                permit: true,//Trade
                subpermit: [
                    {
                        permit: false
                    },
                    {
                        permit: true
                    },
                    {
                        permit: false
                    },
                ]
            },
            {
                permit: false,//Pools
                subpermit: [
                    {
                        permit: false
                    },
                    {
                        permit: false
                    },
                    {
                        permit: false
                    },
                    {
                        permit: true
                    },
                ]
            },
            {
                permit: false,//Dev Hub
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                ]
            },
            {
                permit: false,//Launchpad
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },

                ]
            },
            {
                permit: false,//lock
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },

                ]
            },
            {
                permit: false,//marketplace
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: false
                    },
                    {
                        permit: false
                    },
                ]
            },
            {
                permit: false,//lock
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                ]
            },
        ],
        rpc: "https://bsc-dataseed.binance.org",
        tokenContract: "0xF65A4183fe9E331122CF279d7A72D99E1452f629",
        info: {
            title: "BuyBack70Floki",
            desc: `
            💎We are a meme token 💎inspired by the buyback of Pink Sale 💎and the Floki meme token.💎 The first to use 70% buyback in Pink Sale. 💎We burn 70% of the initial supply of 70,000, with💎 5% manual LP burn to increase token value,💎 with a fixed purchase rate of 4💎% for burn, and in sales, being 4%,💎 1% goes to reward in Floki, 💎1.5% to marketing, 💎and 1.5% to the team💎. 🟢With no team tokens,🟢 a safe contract, only those who buy🟢 during the fair launch of Pink🟢 Sale will have this token.🟢
            `,
            buyon: window.location.protocol + "/swap?token=0xF65A4183fe9E331122CF279d7A72D99E1452f629",
            name: "BuyBack70Floki",
            symbol: "BUYBACK70",
            telegram: "https://t.me/buyback70Floki",
            twitter: "https://x.com/BuyBack70floki",
        },
        screens: {
            "createToken": {
                web3: {
                    receiver: "0xA4de20856640d59A146393ac8CcF3F8e2133DF33",
                    ccFees: [
                        0.1,
                        0.1,
                        0.1,
                        0.1,
                        0.1,
                        0.0
                    ],

                    tokenTo50Fee: "0xF65A4183fe9E331122CF279d7A72D99E1452f629"
                }
            },
            "swap": {
                web3: {
                    Router: "0xA1a39C041221765eb52BaEA2FDBbc77aa92c0dF3",
                    Tokens: {
                        "1999": [
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            },
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            },
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            }
                        ],
                        "97": [
                            {
                                "nome": "BNB",
                                "bname": "Binance Coin",
                                "icon": "https://bscscan.com/token/images/binance_32.png",
                                "contract": "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd"
                            }, {
                                "nome": "BUSD",
                                "bname": "BUSD Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/logo.png",
                                "contract": "0xaB1a4d4f1D656d2450692D237fdD6C7f9146e814"
                            }, {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0x116526135380E28836C6080f1997645d5A807FAE"
                            },

                            {
                                "nome": "CAKE",
                                "bname": "PancakeSwap Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82/logo.png",
                                "contract": "0xFa60D973F7642B748046464e165A65B7323b0DEE"
                            }

                        ],
                        "56": [
                            {
                                "nome": "BNB",
                                "bname": "Binance Coin",
                                "icon": "https://bscscan.com/token/images/binance_32.png",
                                "contract": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
                            },
                            {
                                "nome": "BUSD",
                                "bname": "BUSD Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/logo.png",
                                "contract": "0xe9e7cea3dedca5984780bafc599bd69add087d56"
                            }, {
                                "nome": "BUYBACK70",
                                "bname": "BuyBack70Floki",
                                "icon": "https://i.imgur.com/fU3cgF8.png",
                                "contract": "0xF65A4183fe9E331122CF279d7A72D99E1452f629"
                            },
                            {
                                "nome": "BUYBACK70",
                                "bname": "BuyBack70TokenFi",
                                "icon": "https://i.imgur.com/nttq2Zp.png",
                                "contract": "0xCfb27BEd118e7bE2a1D877757184cB0d511A92cE"
                            },
                            {
                                "nome": "USDT",
                                "bname": "USDT",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x55d398326f99059fF775485246999027B3197955/logo.png",
                                "contract": "0x55d398326f99059fF775485246999027B3197955"
                            }, {
                                "nome": "BTCB",
                                "bname": "BITCOIN",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c/logo.png",
                                "contract": "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c"
                            },
                            {
                                "nome": "ADA",
                                "bname": "Cardano Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47/logo.png",
                                "contract": "0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47"
                            },
                            {
                                "nome": "CAKE",
                                "bname": "PancakeSwap Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82/logo.png",
                                "contract": "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82"
                            }
                        ]
                    }
                }
            },
            "stakepools": {
                factory: "0xC1b4AcC0E4420369BE3C634777F890e42007327B",
                excludes: {
                    '0x4A51BA1Bb9526aa31b967b06Ec2F3557C3e1d3Cc': true,
                    "0x650e5B875035420C872b90cCE0Ee777De30D8C47": true,
                    "0xFC765F0C445b8a6436db9D3D9750C3799Bf58Fd0": true,
                    "0x025dC95E02e33c711B509485BA9233fb228036c4": true,
                    "0x7E6fDfF5487b0e9b97F3B618D3f76410779aA04f": true,
                    "0xEe664C9F3Ed8b4479eE4D98f6919bBc50a285082": true,
                    "0xC41D0610763dc677F704d6A14009Ca3954E4eA26": true,
                    "0xB4C3f82754c97FC43c2f01528BA26E131bd63ec8": true,
                    "0x590B534523dDDa612c58Bd05cdEb05bC1DE013ca": true,
                    "0x8dEc373bbcA468168c3E0bd2BEb5Bcd66731DDd8": true,
                    "0x436080202a6cDAdF2cbf83D6D5dFD33860ecC7cB": true,
                    "0x65D95b37Dd46D3Ab60715637aE91E5801AcABf39": true,
                    "0xC1122978D8cc23F06B26ade13fAdc10bB16aa0E4": true,
                    "0xcc730BB1306e17A6DB7C16a44180F5C1C4B90Cd7": true,

                    "0x98DF35D07f55A1e8885c6D23641546E73009001C": true,
                }
            },
            "nftpools": {
                factory: "0x998189f075c1388079BB6d1b81F2B077cAdE6E71",
                buypool: "0xe79bf2aAA0BBe5F6c210CEE82BcF6cDb13b820e6",
                nfts: [
                    "https://i.imgur.com/b9nyEcQ.png",
                    "https://i.imgur.com/AKQoA1k.png",
                    "https://i.imgur.com/GLDMFjH.png",
                    "https://i.imgur.com/EdPXgvf.png",
                    "https://i.imgur.com/56Utzbt.png"
                ],
                excludes: {
                    '0x4A51BA1Bb9526aa31b967b06Ec2F3557C3e1d3Cc': true,
                    "0x650e5B875035420C872b90cCE0Ee777De30D8C47": true,
                    "0xFC765F0C445b8a6436db9D3D9750C3799Bf58Fd0": true,
                    "0x025dC95E02e33c711B509485BA9233fb228036c4": true,
                    "0x7E6fDfF5487b0e9b97F3B618D3f76410779aA04f": true,
                    "0xEe664C9F3Ed8b4479eE4D98f6919bBc50a285082": true,
                    "0xC41D0610763dc677F704d6A14009Ca3954E4eA26": true,
                    "0xB4C3f82754c97FC43c2f01528BA26E131bd63ec8": true,
                    "0x590B534523dDDa612c58Bd05cdEb05bC1DE013ca": true,
                    "0x8dEc373bbcA468168c3E0bd2BEb5Bcd66731DDd8": true,
                    "0x436080202a6cDAdF2cbf83D6D5dFD33860ecC7cB": true,
                    "0x65D95b37Dd46D3Ab60715637aE91E5801AcABf39": true,
                    "0xC1122978D8cc23F06B26ade13fAdc10bB16aa0E4": true,
                    "0xcc730BB1306e17A6DB7C16a44180F5C1C4B90Cd7": true,

                    "0x98DF35D07f55A1e8885c6D23641546E73009001C": true,
                }
            }

        }

    },
    "blackcryptoplatform": {
        Name: "Black Sale",
        logoUrl: "https://i.imgur.com/lGcrhUa.png",
        Logo: <img
            src={"https://i.imgur.com/lGcrhUa.png"}
            style={{
                height: "50px",
                width: "50px",
                margin: "auto",
            }}
        />,

        backgroundColor: "url('" + "https://i.imgur.com/e6W5fzj.jpeg" + " ') center center / " + (window.innerWidth + window.innerHeight) + "px no-repeat",
        outFont: "#FFAE00",
        buttonsColor: "#030017",
        modalsColor: "#030017",
        innerFont: "#fff",
        outbuttonsColor: "#FFAE00",
        innerBFont: "#222",
        buttonsBorders: "1px solid #00000020",
        inputsColor: "#ffffff00",
        inputsBorders: "1px solid #FFAE0050",
        Borders: "#FFAE0080",
        redirectTo: '/create/contract',
        Routers: [


            '/farms',

            '/stake',

            '/stake/create',

            '/',
            '/create/contract',
            '/manager',
            '/swap'
        ],
        menudata: [
            {
                permit: true,//Trade
                subpermit: [
                    {
                        permit: false
                    },
                    {
                        permit: true
                    },
                    {
                        permit: false
                    },
                ]
            },
            {
                permit: true,//Pools
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: false
                    },
                    {
                        permit: true
                    },
                ]
            },
            {
                permit: true,//Dev Hub
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: false
                    },
                    {
                        permit: false
                    },
                ]
            },
            {
                permit: false,//Launchpad
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },

                ]
            },
            {
                permit: false,//lock
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },

                ]
            },
            {
                permit: false,//marketplace
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                ]
            },
            {
                permit: false,//lock
                subpermit: [
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                    {
                        permit: true
                    },
                ]
            },
        ],
        rpc: "https://bsc-dataseed.binance.org",
        tokenContract: '0x2Fd1Fa4395399F59fC1CEA7cd9C4C67eaC267422',
        info: {
            title: "Welcome to the world of Crypto Black Sale",
            desc: "“Welcome to the world of Crypto Black Sale, where the best investment opportunities in cryptocurrencies are found. Explore our ecosystem to discover unique and exciting offers in the digital asset market.",
            buyon: window.location.protocol + "/swap?token=0x2Fd1Fa4395399F59fC1CEA7cd9C4C67eaC267422",
            name: "Black Sale",
            symbol: "BLACKSALE",
            telegram: "https://t.me/blacksaleoficial",
            twitter: undefined,
        },
        screens: {
            "createToken": {
                web3: {
                    receiver: "0xBd266c87B778d05d69c36aC32b02FD7c60053859",
                    ccFees: [
                        0.2,
                        0.3,
                        0.3,
                        0.3,
                        0.4,
                        0.0
                    ],
                    tokenTo50Fee: "0x2Fd1Fa4395399F59fC1CEA7cd9C4C67eaC267422",
                    exempt:true
                }
            },
            "swap": {
                web3: {
                    Fee: 0.00017,
                    Router: "0x4df797AD29d9A693423c05901763fe4864b10532",
                    Tokens: {
                        "1999": [
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            },
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            },
                            {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0xB4dd14EC23442d8C16e82338BCa6D76f6c33DbE7"
                            }
                        ],
                        "97": [
                            {
                                "nome": "BNB",
                                "bname": "Binance Coin",
                                "icon": "https://bscscan.com/token/images/binance_32.png",
                                "contract": "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd"
                            }, {
                                "nome": "BUSD",
                                "bname": "BUSD Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/logo.png",
                                "contract": "0xaB1a4d4f1D656d2450692D237fdD6C7f9146e814"
                            }, {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0x116526135380E28836C6080f1997645d5A807FAE"
                            },

                            {
                                "nome": "CAKE",
                                "bname": "PancakeSwap Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82/logo.png",
                                "contract": "0xFa60D973F7642B748046464e165A65B7323b0DEE"
                            }

                        ],
                        "56": [
                            {
                                "nome": "BNB",
                                "bname": "Binance Coin",
                                "icon": "https://bscscan.com/token/images/binance_32.png",
                                "contract": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
                            },
                            {
                                "nome": "BUSD",
                                "bname": "BUSD Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/logo.png",
                                "contract": "0xe9e7cea3dedca5984780bafc599bd69add087d56"
                            }, {
                                "nome": "BLACKSALE",
                                "bname": "Black Sale",
                                "icon": "https://i.imgur.com/lGcrhUa.png",
                                "contract": "0x2Fd1Fa4395399F59fC1CEA7cd9C4C67eaC267422"
                            }, {
                                "nome": "MTK",
                                "bname": "MARTIK",
                                "icon": "https://martik.site/logo32x32.png",
                                "contract": "0x116526135380E28836C6080f1997645d5A807FAE"
                            },

                            {
                                "nome": "USDT",
                                "bname": "USDT",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x55d398326f99059fF775485246999027B3197955/logo.png",
                                "contract": "0x55d398326f99059fF775485246999027B3197955"
                            }, {
                                "nome": "BTCB",
                                "bname": "BITCOIN",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c/logo.png",
                                "contract": "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c"
                            },
                            {
                                "nome": "ADA",
                                "bname": "Cardano Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47/logo.png",
                                "contract": "0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47"
                            },
                            {
                                "nome": "CAKE",
                                "bname": "PancakeSwap Token",
                                "icon": "https://r.poocoin.app/smartchain/assets/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82/logo.png",
                                "contract": "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82"
                            }
                        ]
                    }
                }
            },
            "stakepools": {
                factory: "0x824a32aDaDf018F94a1E2A708c34d2b15A13d5D0",
                excludes: {
                    "0x82FE030AE76Dd614FE053ca36b80D49879fF5082": true,
                    '0x4A51BA1Bb9526aa31b967b06Ec2F3557C3e1d3Cc': true,
                    "0x650e5B875035420C872b90cCE0Ee777De30D8C47": true,
                    "0xFC765F0C445b8a6436db9D3D9750C3799Bf58Fd0": true,
                    "0x025dC95E02e33c711B509485BA9233fb228036c4": true,
                    "0x7E6fDfF5487b0e9b97F3B618D3f76410779aA04f": true,
                    "0xEe664C9F3Ed8b4479eE4D98f6919bBc50a285082": true,
                    "0xC41D0610763dc677F704d6A14009Ca3954E4eA26": true,
                    "0xB4C3f82754c97FC43c2f01528BA26E131bd63ec8": true,
                    "0x590B534523dDDa612c58Bd05cdEb05bC1DE013ca": true,
                    "0x8dEc373bbcA468168c3E0bd2BEb5Bcd66731DDd8": true,
                    "0x436080202a6cDAdF2cbf83D6D5dFD33860ecC7cB": true,
                    "0x65D95b37Dd46D3Ab60715637aE91E5801AcABf39": true,
                    "0xC1122978D8cc23F06B26ade13fAdc10bB16aa0E4": true,
                    "0xa8b09b2cF2b1639c2A6aB0a32b5f73Dc61FA4318": true,
                    "0x9321eeE089364182C2A148FEb493CA0931d4Dab4": true,

                }
            }
        }
    },
}