import React, { useState, useEffect } from 'react';
import ModalStake from '../stake/components/modalStake';
import ModalRemove from '../stake/components/modalRemove'
import Rewards from './rewardmodal';
import { ethers } from 'ethers';
const Web3 = require('web3-eth');
const { toChecksumAddress } = require('ethereum-checksum-address')

let localicon = ["BMTK", "WMTK", "PMTK", "MTK", "EMTK", "MMTK", "HMTK", "BTRADER", "DC", "BURN30", '$MOC', "WMARTIK", "PEPE", "MTKP", "DGT"]

const Stakemodal = (props) => {
  const {
    account,
    provider,
    chain,
    chains,
    web3,
    Tokencontract,
    gasTX,
    getID,
    callTX,
    sendTX,
    nSendTX,
    nSendCT
  } = props.interact;
  let contract = props.array[1]
  let stakesabi = props.array[2]
  let tokenct = props.array[3]
  let tokenabi = props.array[4]
  let logo = props.array[5]
  let logos = props.array[6]
  let logor = props.array[7]
  let TKS = props.array[8]
  //9 ja foi
  let TKSD = props.array[10]
  let TKRD = props.array[11]


  const [STAKECTS, setSTAKECTS] = useState(contract);
  const [AllowA, setAllowA] = useState(0);
  const [accountNRSA, setAccountNRSA] = useState(0);
  const [totalstaked, settotalstaked] = useState(0);
  const [accountBalancemTB, setAccountBalanceTB] = useState(0);
  const [APR, setAPR] = useState(0);
  const [poolfee, setpoofee] = useState(0);
  const [totalSupplyB, setTotalSupplyB] = useState(0);
  const [view_Add, setview_Add] = useState(false);
  const [view_Remove, setview_Remove] = useState(false);
  const [stakect, setstakect] = useState(undefined);
  const [tokenB, settokenB] = useState(undefined);
  const [accounts, setAccounts] = useState([account]);
  const [llenth, setllenth] = useState(0);

  const [list, setList] = useState(localStorage.getItem("multi-cache") != undefined ?
    JSON.parse(localStorage.getItem("multi-cache")) : []
  );


  useEffect(() => {
    getData()
  }, [])
  async function getData() {
    if (provider != null) {
      const ct = await Tokencontract(stakesabi, STAKECTS);
      const _tokenB = await Tokencontract(tokenabi, tokenct);
      setstakect(ct)
      settokenB(_tokenB)
      call(_tokenB.methods.totalSupply, setTotalSupplyB);
      call(ct.methods.poolfee, setpoofee);
      if (accounts[0] != undefined) {
        call(_tokenB.methods.allowance, setAllowA, accounts[0], STAKECTS);
        call(_tokenB.methods.balanceOf, setAccountBalanceTB, accounts[0]);
      }
      ct.methods._APR().call().then(string => {
        if (string == APR) { } else {
          setAPR(string)
        }
      }).catch(error => {
        if (APR == 0) { } else {
          setAPR(0)
        }
      })
      ct.methods._totalstaked().call().then(string => {
        if (string == totalstaked) { } else {
          settotalstaked(string)
        }
      }).catch(error => {

      })
      let l = []
      const _llenth = await ct.methods.getRCTLength().call()
      setllenth(_llenth - 12)
      for (var i = 0; i < _llenth; i++) {
        const _ct = await ct.methods.BEP20Rtoken(i).call()
        if (_ct != '0x0000000000000000000000000000000000000000') {
          const a = await Tokencontract(tokenabi, _ct);
          const sym = await a.methods.symbol().call()
          const dec = await a.methods.decimals().call()
          const logo = localicon.includes(sym)
            ? "/icons/multistake/" + sym + ".png"
            : "https://r.poocoin.app/smartchain/assets/" + toChecksumAddress(_ct) + "/logo.png"

          let obj = {
            pos: i,
            ct: _ct,
            symbol: sym,
            dec: dec,
            logo: logo,
          }
          l.push(obj)
        } else {

        }

      };
      setList(l)
      localStorage.setItem("multi-cache", JSON.stringify(l))
    }

  }

  function update() {
    call(tokenB.methods.totalSupply, setTotalSupplyB);
    call(stakect.methods.poolfee, setpoofee);

    if (accounts[0] != undefined) {
      call(tokenB.methods.allowance, setAllowA, accounts[0], STAKECTS);
      call(tokenB.methods.balanceOf, setAccountBalanceTB, accounts[0]);
    }
  }
  function nextpercent(accountBalancemctTB, d) {
    if ((accountBalancemctTB / 10).toString().includes('.')) {
      if (accountBalancemctTB.toString().length >= d) {
        return (
          (accountBalancemctTB / 10).toString().split('.')[0] +
          '.' +
          (accountBalancemctTB / 10).toString().split('.')[1].slice(0, 2)
        );
      } else {
        return (
          '0.' +
          '0'.repeat(d - accountBalancemctTB.toString().length) +
          accountBalancemctTB.toString().slice(0, 2)
        );
      }
    } else {
      return accountBalancemctTB / 10;
    }
  }
  function getmkerror(e) {
    let a = '';
    if (e.toString().includes('{')) {
      let b = e.toString().split('{');
      let c = '{' + b[1];
      let aaa = JSON.parse(c);
      return aaa.message;
    } else {
      a = e;
      return a;
    }
  }
  function nextblock(accountBalancemctTB, d) {

    if ((accountBalancemctTB / (10 ** d)).toString().includes('.')) {
      if (accountBalancemctTB.toString().length >= d) {

        return (
          (accountBalancemctTB / (10 ** d)).toString().split('.')[0] +
          '.' +
          (accountBalancemctTB / (10 ** d)).toString().split('.')[1].slice(0, 4)
        );
      } else {
        return (
          '0.' +
          '0'.repeat(d - accountBalancemctTB.toString().length) +
          accountBalancemctTB.toString().slice(0, 2)
        );
      }
    } else {
      return accountBalancemctTB / (10 ** d);
    }
  }

  function call(func, callback, ...args) {
    // Trigger the function with the arguments
    if (accounts != null) {
      if (accounts[0] != undefined) {
        func(...args).call()
          .then((result) => {
            // Apply given callback, this is our stateSetters
            callback(result);

          })
          .catch((error) => {
            //throw new Error(error);
            //console.log(error)

          })
      }

    }
  }

  async function nstake(value) {
    let a = []
    let e = value
    let b = false
    if (e.toString().includes(".")) {
      a = e.toString().split(".")
      e = a[0] + a[1] + "0".repeat((TKSD - a[1].toString().length))
    } else {
      if (e.toString().includes(",")) {
        a = e.toString().split(",")
        e = a[0] + a[1] + "0".repeat((TKSD - a[1].toString().length))
      } else {
        if (e <= 0) {
          e = e.toString() + "0".repeat(TKSD)
          b = true
        } else {
          e = e.toString() + "0".repeat(TKSD)
        }
      }
    }
    if (b) {
      alert("Input invalid")
    } else {

      nSendTX(STAKECTS, stakesabi, getID(), '_stake', poolfee, 0,
        e
      )
        .then(gg => {
          update()
          alert("Success")
        })
        .catch(error => {
          alert(getmkerror(error.message))
        });
    }
  }

  function approve() {
    nSendTX(tokenct, tokenabi, getID(), 'approve', '0', 0,
      STAKECTS, totalSupplyB
    )
      .then(gg => {
        update()
        alert("Success")
      })
      .catch(error => {
        alert(getmkerror(error.message))
      });
  }
  async function nwAstakem(value) {
    let a = []
    let e = value
    let b = false
    if (e.toString().includes(".")) {
      a = e.toString().split(".")
      if (a[0] <= 0) {
        e = 0
      } else {
        e = a[0] + a[1] + "0".repeat((TKRD - a[1].toString().length))
      }
    } else {
      if (e <= 0) {
        e = 0
      } else {
        e = e.toString() + "0".repeat(TKRD)
      }

    }
    nSendTX(STAKECTS, stakesabi, getID(), '_withdrawStakeA', poolfee, 0

    )
      .then(gg => {
        update()
        alert("Success")
      })
      .catch(error => {
        alert(getmkerror(error.message))
      });

  }

  
  const ButtonI = (props) => {
    return (
      <div style={{ display: "grid", gridTemplateColumns: "auto", height: '70px', width: "95%", margin: "auto" }}>
        <p onClick={props.func} style={{

          backgroundColor: '#44444495',
          borderRadius: "10px",
          height: '50px',
          width: "100%",
          display: "grid",
          margin: "auto",
          cursor: "pointer"
        }}><p style={{ margin: "auto", fontWeight: "bold" }}>{props.title}</p></p>
      </div>
    );
  }
  return (
    <div style={{
      height: "min-content",
      width: "98%",
      display: "grid",
      margin: "auto",
      marginTop: "auto",
      color: "#fff",

      paddingBottom: "15px",
      paddingTop: "15px"
    }}>
      {view_Add && <ModalStake
        ia={logos}
        sethubtx={setview_Add}
        nextpercent={nextpercent}
        nstake={nstake}
        nextblock={nextblock}
        id={TKS[0] + TKS[1]}
        symbola={TKS[0]}
        symbolb={TKS[1]}
        accountBalancemTB={accountBalancemTB}
        deca={TKSD} />}
      {view_Remove && <ModalRemove
        ia={logos}
        sethubtx={setview_Remove}
        nextpercent={nextpercent}
        nstake={nwAstakem}
        nextblock={nextblock}
        id={TKS[0] + TKS[1]}
        symbola={TKS[0]}
        symbolb={TKS[1]}
        accountBalancemTB={accountNRSA}
        deca={TKSD} />}
      <div style={{
        height: "min-content",
        width: "100%",
        listStyleType: "none",
        display: "grid",

      }}>
        <div style={{
          height: "min-content",
          width: "100%",
          display: "grid",
          backgroundColor: "#ffffff20",
          borderRadius: "2px",
          gridTemplateColumns: "repeat(auto-fit, minmax(280px, 1fr)",
          gap: "10px",
          borderRadius: "10px"

        }}>
          <div style={{ display: "grid", gridTemplateColumns: "50px auto auto auto", width: "100%", padding: "auto" }}>
            <i onClick={() => { window.open("https://poocoin.app/tokens/0x116526135380E28836C6080f1997645d5A807FAE") }} style={{
              height: "50px",
              width: "100%",
              margin: "auto",
              background: 'url(' + logo + ')',
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "40px",
              cursor: "pointer"
            }}>
            </i>

            <p style={{
              fontSize: "10px", textAlign: "left", height: "min-content", padding: "1vh",
              margin: "auto", marginLeft: "0px"
            }}>Stake {TKS[0]}
              <br /> <span style={{ fontSize: "15px", fontWeight: "bold" }}>EARN {TKS[1]}</span>
            </p>
            <div style={{
              height: "min-content",
              width: "100%",
              margin: "auto"
            }}>
              <p style={{ fontSize: "12px", textAlign: "center", height: "min-content" }}>TOTAL STAKED</p>
              <p style={{ fontSize: "15px", textAlign: "center", height: "min-content", fontWeight: "bold" }}>{nextblock(totalstaked, TKSD)}</p>
            </div>
            <div style={{
              height: "min-content",
              width: "100%",

              margin: "auto"
            }}>
              <p style={{ fontSize: "12px", textAlign: "center", height: "min-content" }}>APY</p>
              <p style={{ fontSize: "15px", textAlign: "center", height: "min-content", fontWeight: "bold" }}>{nextpercent(APR * llenth, 2)}%  </p>
            </div>
          </div>
          <div style={{ display: "grid", gridTemplateColumns: "auto auto", width: "100%", padding: "auto" }}>



          </div>
          {AllowA <= 0 && parseFloat(nextblock(accountNRSA, TKSD)) <= 0
            ? <ButtonI func={() => approve()} title={'APPROVE ' + TKS[0]} />
            : <div style={{ display: "grid", gridTemplateColumns: "auto auto", height: "70px", width: "95%", margin: "auto", }}>
              <p style={{
                display: "grid", margin: "auto", textAlign: "left",
                width: "100%",
                fontSize: "13px",
                color: "#ddd"
              }}>
                Staked {TKS[0]}
                <span style={{ fontSize: "16px", fontWeight: "bold", }}>
                  {nextblock(accountNRSA, TKSD)} {TKS[0]}
                </span>

              </p>
              <div style={{ display: "grid", gridTemplateColumns: "auto auto", height: "50px", width: "auto", gap: "5px", margin: "auto", fontSize: "20px", marginRight: "0", color: "#fff" }}>
                <button onClick={() => {
                  setview_Add(true)
                  setview_Remove(false)
                }} style={{ cursor: "pointer", border: "none", display: "grid", background: "#44444495", borderRadius: "10px", height: "50px", width: "50px", margin: "auto", color: "#fff" }}>
                  <p style={{ margin: "auto" }}>+</p>
                </button>
                <button onClick={() => {
                  setview_Add(false)
                  setview_Remove(true)
                }} style={{ cursor: "pointer", border: "none", display: "grid", background: accountNRSA > 0 ? "#44444495" : "#00000050", border: "none", borderRadius: "10px", height: "50px", width: "50px", margin: "auto", color: "#fff" }} disabled={!(accountNRSA > 0)}>
                  <p style={{ margin: "auto" }}>-</p>
                </button>
              </div>
            </div>}

        </div>
      </div>

      <div style={{
        height: "min-content",
        width: "100%",
        listStyleType: "none",
        display: "grid",
        padding: "1vh",

      }}>
        <div style={{
          height: "min-content",
          width: "100%",
          display: "grid",
          maxHeight: "90vh",
          gridTemplateColumns: "repeat(auto-fit, minmax(20vh, 1fr)",
          overflowY: "auto",
          overflowX: "hidden",
          marginTop: "auto",
          color: "#fff",
        }}>
          {list.map((e, i) => <Rewards
            pos={i}
            ct={e.ct}
            symbol={e.symbol}
            dec={e.dec}
            logo={e.logo}
            obj={{
              setAccountNRSA,
              stakect,
              nSendTX,
              STAKECTS,
              stakesabi,
              getID,
              poolfee,
              accounts,
              update,
              Tokencontract,
              nextblock,
              getmkerror
            }}
            />)}

        </div>
        <p style={{ fontSize: "12px", textAlign: "left", height: "10vh", marginTop: "auto", marginBottom: "auto", color: "#fff" }}>POOL FEE: {nextblock(poolfee, 18)} BNB <br />
          <br />
          CHECK TOKEN FEES BEFORE MOVING ON STAKE <br />
          REWARD VALUE MAY CHANGE WITH MARKET MOVEMENTS
        </p>
      </div>
    </div>

  );


}
export default Stakemodal;