import React, { useState, useEffect } from 'react';
import Main from "./Main";
import './App.css';
import ProjectData from './config';
let projectName = "Martik"
const {
  Name,
  Logo,
  backgroundColor,
  buttonsColor,
  buttonsBorders,
  inputsColor,
  inputsBorders,
  outbuttonsColor,
  Borders,
  outFont,
  innerFont,
  Routers
} = ProjectData[window.location.host.split('.')[0] != undefined 
? window.location.host.split('.')[0] == "app" 
? window.location.host.split('.')[0] + "." + window.location.host.split('.')[1] 
: window.location.host.split('.')[0] : "Martik"]

function App() {

  return (
    <div className="App" style={{overflow:"auto", background:backgroundColor}}>
      <Main/>
      
    </div>
  );

}

export default App;
