import React, { useEffect, useState } from "react";
import { ethers } from "ethers";
import ReactApexChart from 'react-apexcharts';

import env from '../../hooks/data/Config';
import pairabi from '../../abis/pairabi.json';
import pancakeabi from '../../abis/pankakeRouter.json';
import abi from '../../abis/bnbabi.json';
import Web3 from "web3";
function Chart(props) {
    const rpc = "https://bsc.blockpi.network/v1/rpc/public"
    const web3 = (new Web3(rpc)).eth;
    const router = '0x10ED43C718714eb63d5aA57B78B54704E256024E';
    const WETH = '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c';
    const USD = '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56';
    const [obj, setObj] = useState(props.obj);
    const [_data, setData] = useState([]);
    const [seconds, setSeconds] = useState(0);
    const [chartData, setChartData] = useState({
        series: [{
            data: [
                {
                    x: new Date(1538778600000),
                    y: [6629.81, 6650.5, 6623.04, 6633.33]
                }
            ]
        }],
        chart: {
            type: 'candlestick',
            height: '100%'
        },
        chart: {
            type: 'candlestick',
            height: " 100%"
        },
        xaxis: {
            type: 'datetime'
        },
        yaxis: {
            show: true,
            opposite: true,
            tooltip: {
                enabled: true
            }
        }
    }
    )
    useEffect(() => {
        data()
    }, [])
    useEffect(() => {
        // connectToSelectedNetwork()
        let interval = setInterval(() => {
            setSeconds(seconds => seconds + 1)
            //   connectToSelectedNetwork()
            //
        }, 1000);
        return () => clearInterval(interval);
    }, [seconds]);
    function nextblock(value, d) {
        return value == undefined ? 0 : parseFloat(ethers.utils.formatUnits(value, d))
    }

    function nNumber(valueA, valueB) {
        return valueB.mul(ethers.utils.parseUnits("1", 18)).div(valueA)
    }

    async function data() {
   



    }

    return (
        <>
            <div id="chart" style={{ width: "100%" }} >
                <ReactApexChart options={chartData} series={chartData.series} type={"candlestick"} width={"100%"} />
            </div>
        </>
    )
}
export default Chart;