import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import bnbabi from "../../../abis/bnbabi.json";
import Numberinput from "../../../components/Launchpad/numberInput";
import SAFE from "../../../components/Launchpad/textpar";
import InputBlock from "../../../components/Launchpad/InputBlock";
import DescBlock from "../../../components/Launchpad/descBlock";
import Button from "../../../components/Launchpad/Button";
import Check from "../../../components/Launchpad/Checkbox";
import Checklist from "../../../components/Launchpad/ListCheck";
import StepBar from "../../../components/Launchpad/Stepbar";
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import enUS from 'date-fns/locale/en-US';
import "react-datepicker/dist/react-datepicker.css";
import abi from "../../../abis/lock/lock.json";
import env from '../../../hooks/data/Config';
const Web3 = require('web3-eth');
registerLocale('en-US', enUS)
const Update = (props) => {
    const {
        account,
        provider,
        chain,
        chains,
        web3,
        Tokencontract,
        gasTX,
        getID,
        callTX,
        sendTX,
        nSendTX,
        nSendCT
    } = props.interact
    const [_ct, setCt] = useState("");
    const [address, setaddress] = useState(account);
    const [owner, setowner] = useState("");
    const [time, settime] = useState("");
    const [name, setName] = useState("");
    const [amount, setamount] = useState(0);
    const [_data, setdata] = useState({});
    const [tokenBname, setTokenBname] = useState("");
    const [tokenBsymbol, setTokenBsymbol] = useState("");
    const [tokenBdec, setTokenBdec] = useState(0);
    const [tokenBtotal, setTokenBtotalSupply] = useState(0);
    const [tokenBAllow, setTokenBAllow] = useState(0);


    const [endTime, setendTime] = useState();

    useEffect(() => {
        setendTime(_toUTC(new Date()))
    }, [])
    useEffect(() => {
        //document.title = "MARTIK | UPDATE LOCK"
    }, []);

    useEffect(() => {
        getdata()
    }, []);

    async function getdata() {
        const ct = await props.Tokencontract(props.ctAbi, props.ct);
        const tkdata = await gettokendataA(props.Contract)
        const o = await ct.methods.ContractLock(props.Contract, props.posi).call()
        const data = await ct.methods.Locks(o, props.Contract).call()
        setdata(data)
        gettokendataA(props.Contract)
        setowner(o)
        setName(data.name)
        setamount(nextblock(data.amount, tkdata))
        setendTime(new Date(data.endtime * 1000))
        setCt(ct)
    }


    async function gettokendataA(e) {
        const token = await props.Tokencontract(props.tkAbi, e);
        const a = await token.methods.symbol().call()
        const t = await token.methods.decimals().call()
        const allow = address != undefined ? await token.methods.allowance(address, props.ct).call() : 0
        setTokenBAllow(allow)
        setTokenBsymbol(a)
        setTokenBdec(t)
        return t
    }
    function nf(e) {
        return ('0' + e).slice(-2)
    }
    function toUTC(func, dateA) {
        func(dateA)
    } function _toUTC(dateA) {
        const time = new Date(
            dateA.getUTCFullYear()
            + "-" +
            nf(dateA.getUTCMonth() + 1)
            + "-" +
            nf(dateA.getUTCDate())
            + "T" +
            nf(dateA.getUTCHours())
            + ":" +
            nf(dateA.getUTCMinutes())
            + ":00");

        return (Date.parse(time))
    }
    function nextblock(accountBalancemctTB, d) {
        if ((accountBalancemctTB / (10 ** d)).toString().includes('.')) {
            if (accountBalancemctTB.toString().length >= d) {
                return (
                    (accountBalancemctTB / (10 ** d)).toString().split('.')[0] +
                    '.' +
                    (accountBalancemctTB / (10 ** d)).toString().split('.')[1].slice(0, 2)
                );
            } else {
                return (
                    '0.' +
                    '0'.repeat(d - accountBalancemctTB.toString().length) +
                    accountBalancemctTB.toString().slice(0, 2)
                );
            }
        } else {
            return accountBalancemctTB / (10 ** d);
        }
    }
    function now() {
        const dateA = new Date()
        const time = new Date(
            dateA.getUTCFullYear()
            + "-" +
            nf(dateA.getUTCMonth() + 1)
            + "-" +
            nf(dateA.getUTCDate())
            + "T" +
            nf(dateA.getUTCHours())
            + ":" +
            nf(dateA.getUTCMinutes())
            + ":00");

        return Date.parse(time)
    }
    function returnbignumber(value, dec) {
        let a = ['']
        let e = value + ""
        if (e.toString().includes(",")) {
            a = e.toString().split(",")
            e = ethers.utils.parseUnits(a[0] + "." + a[1], dec).toString()
        } else {
            e = ethers.utils.parseUnits(e, dec).toString()
        }
        return e
    }
    async function _update() {
        const end = parseInt(Date.parse(endTime) / 1000)
        nSendTX(props.ct, props.ctAbi, getID(), 'updateLock', '0', 0,
            name,
            props.Contract,
            returnbignumber(amount, tokenBdec),
            end
        ).then(a => {
            //a.hash
            //console.log(a)
        }).catch(e => {
            //console.log(e)
        })
    }
    async function approve() {
        nSendTX(props.Contract, bnbabi, getID(), 'approve', '0', 0, props.ct, '1' + '0'.repeat(50)).then(a => {

        }).catch(e => {

        })
    }


    const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
        <button ref={ref} onClick={onClick} className='inpult-primary'>
            {new Date(value).toUTCString()}
        </button>
    ));

    return (
        <div style={{ height: "min-content", padding: "20px 10px", width: "100%", border: "1px solid #ffffff10", borderRadius: "10px" }}>
            <div style={{ display: "grid", gap: "10px" }}>
                <InputBlock title={"New Lock Name"} place={""} variavel={name} setvariavel={setName} desc={""} />
                <div style={{ display: "grid", gridTemplateColumns: "repeat(auto-fit, minmax(280px, 1fr)", width: "100%" }}>
                    <InputBlock title={"New amount in lock *"} place={"0"} variavel={amount} setvariavel={setamount} desc={""} />
                    <div style={{ height: "min-content", width: "100%", display: "grid", gridTemplateColumns: "auto", margin: "auto", }}>
                        <p >End time </p>
                        <DatePicker
                            selected={endTime}
                            onChange={(date) => toUTC(setendTime, date)}
                            touchUi={true} locale="en-US"
                            showTimeSelect
                            timeFormat="HH:mm aa"
                            timeIntervals={5}
                            timeCaption="time"
                            dateFormat="MMMM d, yyyy h:mm aa"
                            customInput={<CustomInput />}
                        />
                        <p style={{ margin: "auto", marginLeft: "0%", textAlign: "left", color: "#999", fontSize: "10px" }}></p>
                    </div>
                </div>
                <SAFE what={"Old data"} result={''} />

                <SAFE what={"lock Name:"} result={_data.name} />
                <SAFE what={"Locked Amount"} result={nextblock(_data.amount, tokenBdec)} />
                <SAFE what={"Finish in"} result={(new Date(_data.endtime * 1000)).toString()} />
                <br />
                <SAFE what={"New data"} result={''} />

                <SAFE what={"lock Name:"} result={name} />
                <SAFE what={"Locked Amount"} result={amount} />
                <SAFE what={"Finish in"} result={(new Date(endTime)).toString()} />
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Button func={() => {
                        if (tokenBAllow > 1) {
                            _update()
                        } else {
                            approve()
                        }
                    }} text={tokenBAllow > 0 ? "UPDATE" : "APPROVE"} next={true} />

                </div>

            </div>

        </div>
    );


}
export default Update;