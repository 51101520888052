import React, { useState, useEffect, useContext } from "react";
import abiToken from "../abis/bnbabi.json";
import Modal from './farms/components/modal';
import ModalH from './farms/components/modalH';
import AppContext from "../framework/helpers/AppContext";
import farmAbi from '../abis/farms/factory.json'
import ProjectData from '../config';
const Web3 = require('web3-eth');

let projectName = "Martik"
const {
    Name,
    Logo,
    logoUrl,
    backgroundColor,
    buttonsColor,
    buttonsBorders,
    inputsColor,
    inputsBorders,
    outbuttonsColor,
    Borders,
    outFont,
    innerFont,
    Routers,
    screens
} = ProjectData[window.location.host.split('.')[0] != undefined 
? window.location.host.split('.')[0] == "app" 
? window.location.host.split('.')[0] + "." + window.location.host.split('.')[1] 
: window.location.host.split('.')[0] : "Martik"]

const Farm = (props) => {
    const {
        account,
        provider,
        chain,
        chains,
        web3,
        Tokencontract,
        gasTX,
        getID,
        callTX,
        sendTX,
        nSendTX,
        nSendCT
    } = useContext(AppContext)

    const [address, setAddress] = useState(account);


    const [list, setList] = useState([]);
    const [list_mode, setListMode] = useState(0);
    const [active_mode, setactiveMode] = useState(0);
    const [only_staked_mode, setOnly_Staked_Mode] = useState(false);
    const [filter, setFilter] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const [filterlay, setFilterLay] = useState('none');
    const [seconds, setSeconds] = useState(0);
    useEffect(() => {
        //document.title = "FARMS | MARTIK"
        setAddress(address)
        loadlist()
    }, []);

    async function farmData(contract, title) {
        const A = await Tokencontract(farmAbi, contract)
        return {
            title: title,
            cts: contract,
            apr: await A.methods._FLEXAPR().call(),//
            ts: await A.methods._totalstaked().call(),
            so: address != undefined ? (await A.methods.userInfo(address).call()).amount > 0 : false,
            desc: "",
            apydesc: "",
            official: 0
        }
    }

    async function loadlist() {

        const _t_list = {
            "martik": [],
            "xshiba": [],
            "sbk": [
                //   await farmData('0x66Ec788f686080a1557202242121C984626eAB49', 'BNB - NSBK'),//wall 0xDFBB396a3b809A9c3AC9d4043e978Eefd97f4ACd
                //     await farmData('0xCf6a88C98148c36a131368A1B6A54A8cCf75B397', 'BNB - NSBK')//wall 0x447Bc0636494fCBffF94B2e63883f1eBf514EC9a
            ]
        }
            ;
        setList(_t_list[window.location.host.split('.')[0]])
    }

    function liststaked() {
        let _list = [{
            title: undefined,
            cts: undefined,
            apr: undefined,
            ts: undefined,
            so: undefined,
            desc: "",
            apydesc: ""
        }]
        for (let index = 0; index < list.length; index++) {
            if (list[index]['so']) {
                if (_list[0]['so'] == undefined) {
                    _list = [list[index]]
                } else {
                    _list.push(list[index])
                }
            }
        }
        return _list[0]['so'] == undefined ? [] : _list
    }
    function listFromSearch() {
        let _list = [{
            title: undefined,
            cts: undefined,
            apr: undefined,
            ts: undefined,
            so: undefined,
            desc: "",
            apydesc: ""
        }]
        for (let index = 0; index < list.length; index++) {
            if (list[index]['title'].toString().toUpperCase().includes(searchValue.toUpperCase())) {
                if (_list[0]['so'] == undefined) {
                    _list = [list[index]]
                } else {
                    _list.push(list[index])
                }
            }
        }
        return _list[0]['so'] == undefined ? [] : _list
    }
    function listFromSearchStaked() {
        let _list = [{
            title: undefined,
            cts: undefined,
            apr: undefined,
            ts: undefined,
            so: undefined,
            desc: "",
            apydesc: ""
        }]
        for (let index = 0; index < list.length; index++) {
            if (list[index]['so'] && list[index]['title'].toString().toUpperCase().includes(searchValue.toUpperCase())) {
                if (_list[0]['so'] == undefined) {
                    _list = [list[index]]
                } else {
                    _list.push(list[index])
                }
            }
        }
        return _list[0]['so'] == undefined ? [] : _list
    }
    let stakeColumns = <div style={{ width: "100%", margin: "auto", display: "grid", padding: "2vh" }}>
        <div style={{
            height: "min-content",
            width: "100%",
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(280px, 1fr)",
            gap: "10px",
            borderRadius: "10px",
            marginTop: "auto",
            color: "#fff",

            borderRadius: "10px",
            padding: "5px"
        }}>
            {active_mode == 0 && <>
                {(active_mode == 0 && searchValue.toString().length == 0)
                    ? <>
                        {(!only_staked_mode && filter == 0) && list.map((e, index) => <Modal title={e.title} nSendTX={nSendTX} netId={getID()} Tokencontract={Tokencontract} poofee={"0.005"} pos={index} desc={e.desc} official={e.official} apydesc={e.apydesc} heighti={"6vh"} bgcolor={"#ffffff20"} buttoncolor={"#ffffff20"} array={[web3, e.cts, farmAbi, abiToken, address]} />)}
                        {(!only_staked_mode && filter == 1) && list.sort((a, b) => (a.apr > b.apr) ? 1 : -1).map((e, index) => <Modal nSendTX={nSendTX} netId={getID()} title={e.title} desc={e.desc} official={e.official} apydesc={e.apydesc} Tokencontract={Tokencontract} poofee={"0.005"} pos={index} heighti={"6vh"} bgcolor={"#ffffff20"} buttoncolor={"#ffffff20"} array={[web3, e.cts, farmAbi, abiToken, address]} />).reverse()}
                        {(!only_staked_mode && filter == 2) && list.sort((a, b) => (a.ts > b.ts) ? 1 : -1).map((e, index) => <Modal nSendTX={nSendTX} netId={getID()} title={e.title} desc={e.desc} official={e.official} apydesc={e.apydesc} Tokencontract={Tokencontract} poofee={"0.005"} pos={index} heighti={"6vh"} bgcolor={"#ffffff20"} buttoncolor={"#ffffff20"} array={[web3, e.cts, farmAbi, abiToken, address]} />).reverse()}

                        {(only_staked_mode && filter == 0) && liststaked().map((e, index) => <Modal title={e.title} Tokencontract={Tokencontract} poofee={"0.005"} nSendTX={nSendTX} netId={getID()} desc={e.desc} official={e.official} apydesc={e.apydesc} pos={index} heighti={"6vh"} bgcolor={"#ffffff20"} buttoncolor={"#ffffff20"} array={[web3, e.cts, farmAbi, abiToken, address]} />)}
                        {(only_staked_mode && filter == 1) && liststaked().sort((a, b) => (a.apr > b.apr) ? 1 : -1).map((e, index) => <Modal nSendTX={nSendTX} netId={getID()} desc={e.desc} official={e.official} apydesc={e.apydesc} title={e.title} Tokencontract={Tokencontract} poofee={"0.005"} pos={index} heighti={"6vh"} bgcolor={"#ffffff20"} buttoncolor={"#ffffff20"} array={[web3, e.cts, farmAbi, abiToken, address]} />)}
                        {(only_staked_mode && filter == 2) && liststaked().sort((a, b) => (a.ts > b.ts) ? 1 : -1).map((e, index) => <Modal nSendTX={nSendTX} netId={getID()} desc={e.desc} official={e.official} apydesc={e.apydesc} title={e.title} Tokencontract={Tokencontract} poofee={"0.005"} pos={index} heighti={"6vh"} bgcolor={"#ffffff20"} buttoncolor={"#ffffff20"} array={[web3, e.cts, farmAbi, abiToken, address]} />)}
                    </>
                    : <>
                        {(!only_staked_mode && filter == 0) && listFromSearch().map((e, index) => <Modal title={e.title} nSendTX={nSendTX} netId={getID()} Tokencontract={Tokencontract} poofee={"0.005"} pos={index} desc={e.desc} official={e.official} apydesc={e.apydesc} heighti={"6vh"} bgcolor={"#ffffff20"} buttoncolor={"#ffffff20"} array={[web3, e.cts, farmAbi, abiToken, address]} />)}
                        {(!only_staked_mode && filter == 1) && listFromSearch().sort((a, b) => (a.apr > b.apr) ? 1 : -1).map((e, index) => <Modal nSendTX={nSendTX} netId={getID()} title={e.title} desc={e.desc} official={e.official} apydesc={e.apydesc} Tokencontract={Tokencontract} poofee={"0.005"} pos={index} heighti={"6vh"} bgcolor={"#ffffff20"} buttoncolor={"#ffffff20"} array={[web3, e.cts, farmAbi, abiToken, address]} />).reverse()}
                        {(!only_staked_mode && filter == 2) && listFromSearch().sort((a, b) => (a.ts > b.ts) ? 1 : -1).map((e, index) => <Modal nSendTX={nSendTX} netId={getID()} title={e.title} desc={e.desc} official={e.official} apydesc={e.apydesc} Tokencontract={Tokencontract} poofee={"0.005"} pos={index} heighti={"6vh"} bgcolor={"#ffffff20"} buttoncolor={"#ffffff20"} array={[web3, e.cts, farmAbi, abiToken, address]} />).reverse()}

                        {(only_staked_mode && filter == 0) && listFromSearchStaked().map((e, index) => <Modal title={e.title} Tokencontract={Tokencontract} poofee={"0.005"} nSendTX={nSendTX} netId={getID()} desc={e.desc} official={e.official} apydesc={e.apydesc} pos={index} heighti={"6vh"} bgcolor={"#ffffff20"} buttoncolor={"#ffffff20"} array={[web3, e.cts, farmAbi, abiToken, address]} />)}
                        {(only_staked_mode && filter == 1) && listFromSearchStaked().sort((a, b) => (a.apr > b.apr) ? 1 : -1).map((e, index) => <Modal nSendTX={nSendTX} netId={getID()} desc={e.desc} official={e.official} apydesc={e.apydesc} title={e.title} Tokencontract={Tokencontract} poofee={"0.005"} pos={index} heighti={"6vh"} bgcolor={"#ffffff20"} buttoncolor={"#ffffff20"} array={[web3, e.cts, farmAbi, abiToken, address]} />)}
                        {(only_staked_mode && filter == 2) && listFromSearchStaked().sort((a, b) => (a.ts > b.ts) ? 1 : -1).map((e, index) => <Modal nSendTX={nSendTX} netId={getID()} desc={e.desc} official={e.official} apydesc={e.apydesc} title={e.title} Tokencontract={Tokencontract} poofee={"0.005"} pos={index} heighti={"6vh"} bgcolor={"#ffffff20"} buttoncolor={"#ffffff20"} array={[web3, e.cts, farmAbi, abiToken, address]} />)}
                    </>
                }
            </>

            }



        </div>

        <p style={{ height: "10vh" }}></p>
    </div>
    let stakeHorizontal = <div style={{ width: "100%", margin: "auto", display: "grid", padding: "2vh" }}>

        <div style={{
            height: "min-content",
            width: "100%",
            display: "grid",
            background: "transparent",
            gap: "10px",
            borderRadius: "10px",
            marginTop: "auto",
            color: "#fff",

        }}> {active_mode == 0 && <>
            {(searchValue.toString().length == 0)
                ? <>
                    {(!only_staked_mode && filter == 0) && list.map((e, index) => <ModalH title={e.title} nSendTX={nSendTX} netId={getID()} Tokencontract={Tokencontract} poofee={"0.005"} pos={index} desc={e.desc} official={e.official} apydesc={e.apydesc} heighti={"6vh"} bgcolor={"#ffffff20"} buttoncolor={"#ffffff20"} array={[web3, e.cts, farmAbi, abiToken, address]} />)}
                    {(!only_staked_mode && filter == 1) && list.sort((a, b) => (a.apr > b.apr) ? 1 : -1).map((e, index) => <ModalH nSendTX={nSendTX} netId={getID()} title={e.title} desc={e.desc} official={e.official} apydesc={e.apydesc} Tokencontract={Tokencontract} poofee={"0.005"} pos={index} heighti={"6vh"} bgcolor={"#ffffff20"} buttoncolor={"#ffffff20"} array={[web3, e.cts, farmAbi, abiToken, address]} />).reverse()}
                    {(!only_staked_mode && filter == 2) && list.sort((a, b) => (a.ts > b.ts) ? 1 : -1).map((e, index) => <ModalH nSendTX={nSendTX} netId={getID()} title={e.title} desc={e.desc} official={e.official} apydesc={e.apydesc} Tokencontract={Tokencontract} poofee={"0.005"} pos={index} heighti={"6vh"} bgcolor={"#ffffff20"} buttoncolor={"#ffffff20"} array={[web3, e.cts, farmAbi, abiToken, address]} />).reverse()}

                    {(only_staked_mode && filter == 0) && liststaked().map((e, index) => <ModalH title={e.title} Tokencontract={Tokencontract} poofee={"0.005"} nSendTX={nSendTX} netId={getID()} desc={e.desc} official={e.official} apydesc={e.apydesc} pos={index} heighti={"6vh"} bgcolor={"#ffffff20"} buttoncolor={"#ffffff20"} array={[web3, e.cts, farmAbi, abiToken, address]} />)}
                    {(only_staked_mode && filter == 1) && liststaked().sort((a, b) => (a.apr > b.apr) ? 1 : -1).map((e, index) => <ModalH nSendTX={nSendTX} netId={getID()} desc={e.desc} official={e.official} apydesc={e.apydesc} title={e.title} Tokencontract={Tokencontract} poofee={"0.005"} pos={index} heighti={"6vh"} bgcolor={"#ffffff20"} buttoncolor={"#ffffff20"} array={[web3, e.cts, farmAbi, abiToken, address]} />)}
                    {(only_staked_mode && filter == 2) && liststaked().sort((a, b) => (a.ts > b.ts) ? 1 : -1).map((e, index) => <ModalH nSendTX={nSendTX} netId={getID()} desc={e.desc} official={e.official} apydesc={e.apydesc} title={e.title} Tokencontract={Tokencontract} poofee={"0.005"} pos={index} heighti={"6vh"} bgcolor={"#ffffff20"} buttoncolor={"#ffffff20"} array={[web3, e.cts, farmAbi, abiToken, address]} />)}
                </>
                : <>
                    {(!only_staked_mode && filter == 0) && listFromSearch().map((e, index) => <ModalH title={e.title} nSendTX={nSendTX} netId={getID()} Tokencontract={Tokencontract} poofee={"0.005"} pos={index} desc={e.desc} official={e.official} apydesc={e.apydesc} heighti={"6vh"} bgcolor={"#ffffff20"} buttoncolor={"#ffffff20"} array={[web3, e.cts, farmAbi, abiToken, address]} />)}
                    {(!only_staked_mode && filter == 1) && listFromSearch().sort((a, b) => (a.apr > b.apr) ? 1 : -1).map((e, index) => <ModalH nSendTX={nSendTX} netId={getID()} title={e.title} desc={e.desc} official={e.official} apydesc={e.apydesc} Tokencontract={Tokencontract} poofee={"0.005"} pos={index} heighti={"6vh"} bgcolor={"#ffffff20"} buttoncolor={"#ffffff20"} array={[web3, e.cts, farmAbi, abiToken, address]} />).reverse()}
                    {(!only_staked_mode && filter == 2) && listFromSearch().sort((a, b) => (a.ts > b.ts) ? 1 : -1).map((e, index) => <ModalH nSendTX={nSendTX} netId={getID()} title={e.title} desc={e.desc} official={e.official} apydesc={e.apydesc} Tokencontract={Tokencontract} poofee={"0.005"} pos={index} heighti={"6vh"} bgcolor={"#ffffff20"} buttoncolor={"#ffffff20"} array={[web3, e.cts, farmAbi, abiToken, address]} />).reverse()}

                    {(only_staked_mode && filter == 0) && listFromSearchStaked().map((e, index) => <ModalH title={e.title} Tokencontract={Tokencontract} poofee={"0.005"} nSendTX={nSendTX} netId={getID()} desc={e.desc} official={e.official} apydesc={e.apydesc} pos={index} heighti={"6vh"} bgcolor={"#ffffff20"} buttoncolor={"#ffffff20"} array={[web3, e.cts, farmAbi, abiToken, address]} />)}
                    {(only_staked_mode && filter == 1) && listFromSearchStaked().sort((a, b) => (a.apr > b.apr) ? 1 : -1).map((e, index) => <ModalH nSendTX={nSendTX} netId={getID()} desc={e.desc} official={e.official} apydesc={e.apydesc} title={e.title} Tokencontract={Tokencontract} poofee={"0.005"} pos={index} heighti={"6vh"} bgcolor={"#ffffff20"} buttoncolor={"#ffffff20"} array={[web3, e.cts, farmAbi, abiToken, address]} />)}
                    {(only_staked_mode && filter == 2) && listFromSearchStaked().sort((a, b) => (a.ts > b.ts) ? 1 : -1).map((e, index) => <ModalH nSendTX={nSendTX} netId={getID()} desc={e.desc} official={e.official} apydesc={e.apydesc} title={e.title} Tokencontract={Tokencontract} poofee={"0.005"} pos={index} heighti={"6vh"} bgcolor={"#ffffff20"} buttoncolor={"#ffffff20"} array={[web3, e.cts, farmAbi, abiToken, address]} />)}
                </>
            }
        </>
            }

        </div>
        <p style={{ height: "10vh" }}></p>
    </div>

    let newdivabu = <div style={{
        height: "min-content",
        width: "100%",
        display: "grid",
        color: "#fff",
        borderRadius: "2px",
        margin: "auto"
    }}>

        <div style={{
            height: "100%",
            minHeight: "480px",
            width: "100%",
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(280px, 1fr)",
            gap: "10px",

        }}>
            <div style={{
                height: "100%",
                minHeight: "480px",
                width: "100%",
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(280px, 1fr)",
                gap: "10px",
                background: "url(" + logoUrl + ")",
                backgroundSize: "50%",
                backgroundRepeat: "no-repeat",
                backgroundPosition: window.innerWidth < 720 ? "center" : "center"
            }}></div>
            <div style={{
                height: "100%",
                width: "100%",
                display: "grid",
                margin: "auto",
                gap: "10px",
                gridTemplateColumns: "auto",
            }}>

                <span style={{
                    textAlign: "justify",
                    fontSize: "20px",

                    height: "min-content",
                    width: "90%",
                    minWidth: "280px",
                    margin: "auto",
                    display: "grid",
                    gridTemplateColumns: "repeat(auto-fit, minmax(280px, 1fr)",

                }}>

                    <p style={{
                        textAlign: "left",
                        fontSize: "40px",

                        margin: "auto",
                        width: "90%",
                        color: "#550882"
                    }}>
                        <span style={{ color: "#fff", fontWeight: "bold" }}>{Name} Pools</span>

                        <span style={{
                            textAlign: "justify",
                            fontSize: "20px",

                            height: "100%",
                            width: "100%",
                            minWidth: "280px",
                            margin: "0 auto",
                            color: "#fff"
                        }}>
                            <br></br>
                            Just share liquidity tokens to earn more tokens.
                        </span>
                    </p>
                </span>
            </div>
        </div>
    </div>
    return (
        <div >
            <div style={{ height: "min-content", }}>
                {newdivabu}
                {stakeColumns}
            </div>
        </div>
    );
}

export default Farm;