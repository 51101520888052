import React, { useState, useEffect, useContext } from 'react';
import AppContext from '../../framework/helpers/AppContext';
import env from '../../hooks/data/Config';
import ProjectData from '../../config';
import '../farm.css';
const Web3 = require('web3-eth');
const winwheel = require("winwheel");

let projectName = "Martik"
const {
  Name,
  Logo,
  backgroundColor,
  buttonsColor,
  modalsColor,
  buttonsBorders,
  inputsColor,
  inputsBorders,
  outbuttonsColor,
  Borders,
  outFont,
  innerFont,
  innerBFont,
  menudata,
  Routers,
  logoUrl,
  info,
  screens
} = ProjectData[window.location.host.split('.')[0] != undefined 
? window.location.host.split('.')[0] == "app" 
? window.location.host.split('.')[0] + "." + window.location.host.split('.')[1] 
: window.location.host.split('.')[0] : "Martik"]

const Games = (props) => {

  const GLOBAL = useContext(AppContext)
  const {
    account,
    provider,
    chain,
    chains,
    web3,
    aweb3,
    Tokencontract,
    gasTX,
    getID,
    callTX,
    sendTX,
    nSendTX,
    nSendCT
  } = useContext(AppContext)
  GLOBAL.provider = aweb3()
  GLOBAL.chain = {
    chainId: getID()
  }

  const [accounts, setaccounts] = useState([account]);
  const [tokenB, settokenB] = useState("0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c");
  const [hubtx, sethubtx] = useState(false);
  const [cake, setCake] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [conerror, setconerror] = useState(true);
  const [errornet, seterrornet] = useState(false);
  const [accountBNB, setBNBbalance] = useState(0);
  const [tokenA, setTokenA] = useState(0);
  const [NFTPOOLCT, setNFTPOOLCT] = useState(0);
  const [NFTPOOLCTC, setNFTPOOLCTC] = useState(0);
  const [NFTCTGV2, setNFTCTGV2] = useState(0);
  const [NFTCTSV2, setNFTCTSV2] = useState(0);
  const [NFTCTBV2, setNFTCTBV2] = useState(0);
  const [NFTCTG, setNFTCTG] = useState(0);
  const [NFTCTS, setNFTCTS] = useState(0);
  const [NFTCTB, setNFTCTB] = useState(0);
  const [NFTCTU, setNFTCTU] = useState(0);
  const [NFTCTUB, setNFTCTUB] = useState(0);
  const [tokenACT, setTokenACT] = useState("0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c");
  const [tokenBCT, setTokenBCT] = useState("0x1ED347B7543e0967a8e4CdcdC6da9b293fDf9643");
  const [NFTPOOL, setNFTPOOL] = useState("0x84a3bC09559D5719DD314dCe234D5c9bae1d693d");
  const [NFTPOOLC, setNFTPOOLC] = useState("0x62f199575a8297e5f6e5337be670613cb1f82978");
  const [taxnft, settaxnft] = useState(0);
  const [NFTCTGCT, setNFTCTGCT] = useState("0x6d0eb9908f5d3863198Fbc0e1238d1209eA870d5");
  const [NFTCTSCT, setNFTCTSCT] = useState("0x89d983Cc99AA498E45bA6C5520e6644E123c9CC2");
  const [NFTCTBCT, setNFTCTBCT] = useState("0x5F17CA812F702DdB716116324276bB4A126a9Bcd");
  const [NFTCTGCTV2, setNFTCTGCTV2] = useState("0x5Cc2d1b29da9D9D824753dd23b8921BfafD795A0");
  const [NFTCTSCTV2, setNFTCTSCTV2] = useState("0x0685e46562D12975E149E573693bD419927B6a71");
  const [NFTCTBCTV2, setNFTCTBCTV2] = useState("0x71eBe700c975B3811Cc20013292Cd96891a31E38");
  const [NFTCTUCT, setNFTCTUCT] = useState("0xbD5bE75e97FB819bB59B9CB8f04fb816829eC55c");
  const [NFTCTUBCT, setNFTCTUBCT] = useState("0x0E77DeF3291d7F5603998938336869cE0131B3d4");
  const [AllowANFT, setAllowANFT] = useState(0);
  const [AllowANFTUB, setAllowANFTUB] = useState(0);
  const [AllowANFTG, setAllowANFTG] = useState(0);
  const [AllowANFTS, setAllowANFTS] = useState(0);
  const [AllowANFTB, setAllowANFTB] = useState(0);
  const [pricenftg, setpricenftg] = useState(0);
  const [pricenfts, setpricenfts] = useState(0);
  const [pricenftb, setpricenftb] = useState(0);

  const [NFTRGV2, setNFTRGV2] = useState(0);
  const [NFTRSV2, setNFTRSV2] = useState(0);
  const [NFTRBV2, setNFTRBV2] = useState(0);
  const [NFTBGV2, setNFTBGV2] = useState(0);
  const [NFTBSV2, setNFTBSV2] = useState(0);
  const [NFTBBV2, setNFTBBV2] = useState(0);
  const [NFTRG, setNFTRG] = useState(0);
  const [NFTRS, setNFTRS] = useState(0);
  const [NFTRB, setNFTRB] = useState(0);
  const [NFTRU, setNFTRU] = useState(0);
  const [NFTRUB, setNFTRUB] = useState(0);
  const [accountBalancemTB, setAccountBalanceTB] = useState(0);
  const [accountRSA, setAccountRSA] = useState(0);
  const [accountRSC, setAccountRSC] = useState(0);

  const [accountBalancemTBOLD, setAccountBalanceTBOLD] = useState(0);
  const [accountRA, setAccountRA] = useState(0);
  const [accountRB, setAccountRB] = useState(0);

  const [decimalsTB, setCdecimalsTB] = useState(0);
  const [totalSupplyB, setTotalSupplyB] = useState(0);
  const [amountin, setAmountin] = useState("1000000000000000000");
  const [xin, setXin] = useState(1.01);
  const [seconds, setSeconds] = useState(0);
  const [texterror, seterrormenssage] = useState(<h1 className='errormsg'></h1>);
  const [tprice, settokenprice] = useState("$ 0");
  const [tname, settokenname] = useState("DC");

  const [catchh, setcatchh] = useState(false);
  const [userlist, setul] = useState([]);

  const [txtconfirm, settc] = useState("");
  const [runanim, setRunAnim] = useState(false);
  const [minputerror, setMerror] = useState(false);
  const [Iinputerror, setIerror] = useState(false);
  const [multivalue, setMV] = useState("");
  const [facevalue, setfacevalue] = useState(0);
  const [claimable, setClaimV] = useState(0);
  const [roll, setRoll] = useState(0);
  const [arraydouble, setArrayDouble] = useState([]);
  const [doublecolor, setCDouble] = useState("");
  const [blockselected, setblock] = useState([2, 0]);
  const [coinside, setCoinSide] = useState("CARA");
  const [buttonbetv, setbtbetv] = useState(false);
  const [progressbarview, setpbview] = useState(false);
  const [myalertview, setmaview] = useState(false);
  const [intestnet, setintest] = useState(false);
  const [contentmodal, setcttmodal] = useState(<div></div>);
  const [gamesscreen, setGview] = useState(false);
  const [lpscreen, setLPview] = useState(false);
  const [MKTscreen, setMKTview] = useState(false);
  const [Airscreen, setAIRview] = useState(false);
  const [fffscreen, setfffview] = useState(false);
  const [whitescreen, setwhiteview] = useState(false);
  const [SHOPscreen, setshopview] = useState(false);
  const [wlang, setwlang] = useState(0);

  const [lastresult, setres] = useState(0);
  const [getnftuts, setgetnftuts] = useState(0);
  const [getnftutsub, setgetnftubts] = useState(0);

  const [BALD, setBalder] = useState(0);


  // call takes in a function to execute and runs a given callback on the response
  function call(func, callback, ...args) {
    // Trigger the function with the arguments
    func(...args).call()
      .then((result) => {
        // Apply given callback, this is our stateSetters
        callback(result);

        setconerror(false)
      })
      .catch((error) => {
        //throw new Error(error);
        myalert("Wrong Network");
        setconerror(true)
      })
  }

  function update() {


  }
  function nextblock(accountBalancemctTB, d) {
    if ((accountBalancemctTB / (10 ** d)).toString().includes('.')) {
      if (accountBalancemctTB.toString().length >= d) {
        return (
          (accountBalancemctTB / (10 ** d)).toString().split('.')[0] +
          '.' +
          (accountBalancemctTB / (10 ** d)).toString().split('.')[1].slice(0, 2)
        );
      } else {
        return (
          '0.' +
          '0'.repeat(d - accountBalancemctTB.toString().length) +
          accountBalancemctTB.toString().slice(0, 2)
        );
      }
    } else {
      return accountBalancemctTB / (10 ** d);
    }
  }

  function getmkerror(e) {
    let a = ""
    if (e.includes("{")) {
      let b = e.split("{")
      let c = "{" + b[1]
      let aaa = JSON.parse(c)
      //json.parse(c)
      //console.log(aaa.message)
      return aaa.message;
      //console.log(c.message)
    }


    return a;
  }

  function bettinggo(index) {
    if (Iinputerror) {

    } else {
      if (minputerror) {

      } else {
        if (loaded && (accounts !== 0)) {
          const web3 = new Web3(window.ethereum);
          web3.net.getId().then(aaaaa => {
            if (aaaaa != 56) {
              myalert("Wrong network! Switch to BSC Mainnet (Binance Smart Chain Main Net)");
              console.log("aqui")
            } else {
              if (runanim) {

              } else {
                setRunAnim(true)
                setfacevalue(0)
                if (xin.toString().includes(".")) {
                  let a = xin.toString().split(".")
                  if (a[0] <= 0 || a[0] > 1000) {
                    myalert("Cashout cannot be less than 1.01 or greater than 1000x")
                  } else {
                    if (a[1] <= 0) {
                      myalert("Cashout cannot be less than 1.01x")
                    } else {
                      tokenB.methods.betting(a[0], a[1], amountin, index).estimateGas({ from: accounts[0] })
                        .then((gas) => {
                          tokenB.methods.betting(a[0], a[1], amountin, index).send({
                            from: accounts[0],
                            gas: gas
                          })
                            .then(gg => {
                              setRunAnim(false)
                              update()
                              showresult(index, gg.events.result.returnValues[0])
                              let z = checkclaim(index, gg.events.result.returnValues[0], a[0] + a[1], amountin)
                              addonlist(index, amountin, a[0] + a[1], accounts[0], z)

                            })
                            .catch(error => {
                              setRunAnim(false)
                              update()
                              myalert("Error: Bet cannot be placed")
                            });
                        }).catch((error) => {
                          setRunAnim(false)
                          update()
                          myalert("Error: Bet cannot be placed")
                        });
                    }

                  }
                } else {
                  if (xin <= 1) {
                    myalert("Cashout cannot be less than 1.01x")
                  } else {
                    tokenB.methods.betting(xin, "00", amountin, index).estimateGas({ from: accounts[0] })
                      .then((gas) => {

                        tokenB.methods.betting(xin, "00", amountin, index).send({
                          from: accounts[0],
                          gas: gas
                        })
                          .then(gg => {
                            update()
                            setRunAnim(false)
                            //console.log(gg.events.result.returnValues[0])
                            showresult(index, gg.events.result.returnValues[0])
                            //checkclaim(gg.events.result.returnValues[0], xin, amountin)
                            let z = checkclaim(index, gg.events.result.returnValues[0], xin * 100, amountin)
                            addonlist(index, amountin, xin * 100, accounts[0], z)
                          })
                          .catch(error => {

                            update()
                            setRunAnim(false)
                            myalert("Error: Bet cannot be placed")
                            // console.log(error.message)
                          });
                      }).catch((error) => {

                        update()
                        setRunAnim(false)
                        myalert("Error: Bet cannot be placed")
                        //console.log("aaa")
                      });
                  }

                }
              }

            }
          });

        }
      }
    }

  }

  function setdoublecolor(c) {
    if (runanim) {

    } else {
      setCDouble(c)
    }

  }

  function checkclaim(i, a, b, c) {
    let z = 0;
    if (i == 0) {
      if (result > b) {
        z = c * (parseFloat(b) / 100)
      } else {
        z = 0;

      }
    } else {
      if (i == 1) {
        if (a == b) {
          z = c * (parseFloat(b) / 100)
        } else {
          z = 0;
        }
      } else {
        if (i == 2) {
          if (a == b) {
            z = c * (parseFloat(b) / 100)
            calculatePrize(1, b)
          } else {
            z = 0;
            calculatePrize(0, b)
          }
        } else {
          if (i == 3) {

            if (a == b) {
              z = c * (parseFloat(b) / 100)
              let j = (parseFloat(b) / 100)
              eClick(j, doublecolor, 1)
              eClick(j, doublecolor, 1)
            } else {

              if ((parseFloat(b) / 100) == 15) {
                eClick(2, doublecolor, 0)
                eClick(2, doublecolor, 0)
              } else {
                let ccc = [2, 2, 15]
                eClick(ccc[generateRandomInteger(2)], doublecolor, 0)
                eClick(ccc[generateRandomInteger(2)], doublecolor, 0)
              }
              z = 0;

            }
          } else {
            if (i == 4) {
              if (a == 200) {
                z = c * (parseFloat(b) / 100)
              } else {
                z = 0;
              }
            } else {

            }
          }
        }
      }
    }
    return z;
  }

  function showresult(index, result) {
    if (index == 0) {
      //limbo
      setMV(result)
      setres(result)
    } else {

      if (index == 1) {
        //Dice
        setMV(result)
        setres(result)
      } else {
        if (index == 2) {
          //Roll
          setres(result)
        } else {
          if (index == 3) {
            //Double
            setres(result)
          } else {
            if (index == 4) {
              //Two faces
              setres(result)
              setfacevalue(result)
              if (document.getElementById("euro") != null) {
                let element = document.getElementById("euro")
                if (element.classList.contains('stop')) {
                  // element.classList.remove('rotatecoin');
                } else {
                  element.classList.add('stop');
                }
                //console.log(result)
                if (result == 200) {
                  if (coinside == "CARA") {
                    element.style.transform = "rotateY(0deg)"

                  } else {
                    if (coinside == "COROA") {
                      element.style.transform = "rotateY(180deg)"
                    } else {

                    }
                  }

                } else {
                  if (coinside == "CARA") {
                    element.style.transform = "rotateY(180deg)"

                  } else {
                    if (coinside == "COROA") {
                      element.style.transform = "rotateY(0deg)"

                    } else {

                    }
                  }
                }
              }
            } else {

            }
          }
        }
      }
    }
  }

  function addonlist(ab, b, cbc, d, e) {
    //amount={e.amount} bet={e.multi} result={e.user} reward={e.claimable}

    var obj = {
      "index": ab,
      "amount": b,
      "multi": cbc,
      "user": d,
      "claimable": e,
    };
    let a = []
    let cb = null
    if (intestnet) {
      cb = localStorage.getItem("t" + accounts[0])
    } else {
      cb = localStorage.getItem(accounts[0])
    }
    if (cb != null) {
      if (cb.length != 0) {
        //a = JSON.parse(cb)
        let bj = JSON.parse(cb)
        for (var h in bj) {

          if (bj.length > 10) {
            if (h == 0) {

            } else {
              a.push(bj[h]);
            }
          } else {
            //  a.push(bj[(bj.length - 1) - h]);
            a.push(bj[h]);
          }
        }
        a.push(obj);
      } else {
        a.push(obj);
      }
    } else {
      a.push(obj);
    }

    if (intestnet) {
      localStorage.setItem("t" + accounts[0], JSON.stringify(a));
    } else {
      localStorage.setItem(accounts[0], JSON.stringify(a));
    }

    setul(hv())
  }


  function hv() {
    let a = [];
    let b = null
    if (loaded && (accounts !== 0)) {

      let c = null
      if (intestnet) {
        c = localStorage.getItem("t" + accounts[0])
      } else {
        c = localStorage.getItem(accounts[0])
      }
      if (c != null) {
        if (c.length != 0) {
          b = JSON.parse(c)
          //  console.log(b)
          for (var i in b) {
            a.push(b[i])

            //console.log(b[i])
          }
        } else {
        }
      }

    } else {
    }

    return a;
  }

  if (catchh) {
  } else {

    if (hv().length == 0) {

    } else {
      setul(hv())
      setcatchh(true)
    }


  }

  const defamount = (e) => {
    setAmountin(amountin => amountin)
    let a = []
    if (e.toString().includes(".")) {
      a = e.toString().split(".")
      if (a[0] <= 0) {
        setAmountin(a[0] + "0".repeat(18));
        setIerror(true)
      } else {
        setAmountin(a[0] + "0".repeat(18));
        setIerror(false)
      }

    } else {
      if (e <= 0) {
        setIerror(true)
        setAmountin(e.toString() + "0".repeat(18));
      } else {
        setAmountin(e.toString() + "0".repeat(18));
        setIerror(false)
      }

    }

  }

  function setmultipler(e) {

    if (e.toString().includes(".")) {
      let a = e.toString().split(".")
      if (a[0] <= 0 || a[0] > 1000) {
        setXin(e)
        setMerror(true)
      } else {
        if (a[1] <= 0) {
          if (a[1] == 0) {
            if (a[0] > 1) {
              setXin(a[0]);
              setMerror(false)
            } else {
              setXin(e)
              setMerror(true)
            }
          } else {
            setXin(e)
            setMerror(true)
          }

        } else {
          if (a[1].toString().length > 2) {
            //console.log(a[1])
            setXin(a[0] + "." + a[1].toString().slice(0, -1));
            setMerror(false)
          } else {
            //console.log(e)
            setXin(e);
            setMerror(false)
          }

        }

      }
    } else {
      if (e <= 1) {
        setXin(e)
        setMerror(true)
      } else {
        setXin(e);
        setMerror(false)
      }

    }

  }

  function inputVerror() {
    let v = null
    if (Iinputerror) {
      v = { border: "1.5px solid #fb4f4f" }

    } else {
      v = { border: "1.5px solid #ffffff50" }
    }
    return v;
  }
  function inputVerrorinput() {
    let v = null
    if (Iinputerror) {
      v = { color: "#fb4f4f" }

    } else {
      v = { color: "#ffffff" }
    }
    return v;
  }

  function inputerror() {
    let v = null
    if (minputerror) {
      v = { border: "1.5px solid #fb4f4f" }

    } else {
      v = { border: "1.5px solid #ffffff50" }
    }
    return v;
  }
  function inputerrorinput() {
    let v = null
    if (minputerror) {
      v = { color: "#fb4f4f" }

    } else {
      v = { color: "#ffffff" }
    }
    return v;
  }

  function generateRandomInteger(max) {
    return Math.floor(Math.random() * max) + 1;
  }
  const result = () => {
    let aaa = 0
    let bal = 0
    if (loaded && (accounts !== 0)) {
      if (runanim) {
        bal = ((((1000 / generateRandomInteger(1000)) * 100) + generateRandomInteger(99)) / 100).toString().split(".")
        aaa = bal[0] + "." + bal[1].slice(0, (bal[1].length - (bal[1].length - 2)))
        return aaa;
      } else {

      }

    }
    return 0;
  }

  let tempv = result()


  function bnb() {
    if (loaded && (accounts !== 0)) {
      const web3 = new Web3(Web3.givenProvider);
      web3.net.getId().then(aaaaa => {
        if (aaaaa != 56) {
          myalert("Network errada! Troque para a Network BSC Mainnet (Binance Smart Chain)")
        } else {
          let valuep = null
          if (window.web3 != undefined) {
            window.web3 = new Web3(window.ethereum)
            window.web3.getBalance(accounts[0]).then(valor => {
              if (accountBNB == valor) {

              } else {
                setBNBbalance(valor)
              }

            }).catch(error => {

            })
          } else {
            window.web3 = new Web3(window.ethereum)
          }

        }
      })

    }
  }
  //bnb()

  function seechange() {
    let bal = null
    if (accountBalancemTB != accountBalancemTBOLD) {
      if (accountBalancemTB > accountBalancemTBOLD) {
        bal = <div id="coinbal" style={{ color: "green", fontWeight: "bold" }} className='coinbal'>
          <div>
            <i></i>
            <p>{nextblock(accountBalancemTB, 18)}</p>
          </div>

        </div>
        setTimeout(function () {
          setAccountBalanceTBOLD(accountBalancemTB)
          bal = <div id="coinbal" style={{ color: "green", fontWeight: "bold" }} className='coinbal'>
            <div>
              <i></i>
              <p>{nextblock(accountBalancemTB, 18)}</p>
            </div>

          </div>
        }, 2000)
      } else {
        bal = <div id="coinbal" style={{ color: "red", fontWeight: "bold" }} className='coinbal'>
          <div>
            <i></i>
            <p>{nextblock(accountBalancemTB, 18)}</p>
          </div>

        </div>
        setTimeout(function () {
          setAccountBalanceTBOLD(accountBalancemTB)
          bal = <div id="coinbal" style={{ color: "red", fontWeight: "bold" }} className='coinbal'>
            <div>
              <i></i>
              <p>{nextblock(accountBalancemTB, 18)}</p>
            </div>

          </div>
        }, 2000)
      }
    } else {
      bal = <div id="coinbal" style={{ color: "#8b8b8b" }} className='coinbal'>
        <div>
          <i></i>
          <p>{nextblock(accountBalancemTB, 18)}</p>
        </div>

      </div>
    }
    return bal;
  }

  let balancebnb = <div className='bnbbal'>
    <div>
      <i></i>
      <p>{nextblock(accountBNB, 18)}</p>
    </div>

  </div>
  let balancecoin = seechange()



  function ca() {
    let v = null

    if (runanim) {
      v = { height: "80%", display: "grid", fontSize: "12vh", animationName: "shake", animationDuration: "1s", transformOrigin: '50% 50%', animationIterationCount: "infinite", background: "url('/icons/rocketn.png')", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "80%" }


    } else {
      v = { height: "80%", display: "grid", animationDuration: "1s", transformOrigin: '50% 50%', animationIterationCount: "infinite", background: "url('/icons/rocketn.png')", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "80%" }
    }
    return v;
  }

  function c() {
    let v = null

    if (runanim) {
      v = { fontSize: "12vh", animationName: "shake", animationDuration: "1s", transformOrigin: '50% 50%', animationIterationCount: "infinite" }


    } else {
      v = { animationDuration: "1s", transformOrigin: '50% 50%', animationIterationCount: "infinite" }
    }
    return v;
  }

  function ddd() {

    if (tempv == 0) {
      return multivalue / 100;
    } else {
      return tempv;
    }
  }
  let dicecube = null


  function ddddice() {
    let a = 0
    if (multivalue / 100 <= 0) {
      a = 1;
    } else {
      let l = (multivalue / 100)
      a = l.toString().split(".")[0]
    }
    if (loaded && (accounts !== 0)) {
      if (runanim) {
        a = generateRandomInteger(6)
        if (a == 6) {
          dicecube = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-dice-6" viewBox="0 0 16 16">
            <path d="M13 1a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h10zM3 0a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V3a3 3 0 0 0-3-3H3z" />
            <path d="M5.5 4a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm8 0a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0 8a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-4a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm-8 4a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-4a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
          </svg>
        } else {
          if (a == 5) {
            dicecube = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-dice-5" viewBox="0 0 16 16">
              <path d="M13 1a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h10zM3 0a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V3a3 3 0 0 0-3-3H3z" />
              <path d="M5.5 4a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm8 0a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0 8a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm-8 0a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm4-4a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
            </svg>

          } else {
            if (a == 4) {
              dicecube = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-dice-4" viewBox="0 0 16 16">
                <path d="M13 1a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h10zM3 0a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V3a3 3 0 0 0-3-3H3z" />
                <path d="M5.5 4a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm8 0a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0 8a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm-8 0a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
              </svg>
            } else {
              if (a == 3) {
                dicecube = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-dice-3" viewBox="0 0 16 16">
                  <path d="M13 1a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h10zM3 0a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V3a3 3 0 0 0-3-3H3z" />
                  <path d="M5.5 4a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm8 8a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm-4-4a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                </svg>
              } else {
                if (a == 2) {
                  dicecube = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-dice-2" viewBox="0 0 16 16">
                    <path d="M13 1a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h10zM3 0a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V3a3 3 0 0 0-3-3H3z" />
                    <path d="M5.5 4a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm8 8a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                  </svg>
                } else {
                  if (a == 1) {
                    dicecube = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff" class="bi bi-dice-1" viewBox="0 0 16 16">
                      <circle cx="8" cy="8" r="1.5" />
                      <path d="M13 1a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h10zM3 0a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V3a3 3 0 0 0-3-3H3z" />
                    </svg>
                  } else {

                  }
                }
              }
            }
          }
        }

      } else {
        if (a == 6) {
          dicecube = <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-dice-6" viewBox="0 0 16 16">
            <path d="M13 1a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h10zM3 0a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V3a3 3 0 0 0-3-3H3z" />
            <path d="M5.5 4a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm8 0a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0 8a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-4a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm-8 4a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-4a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
          </svg>
        } else {
          if (a == 5) {
            dicecube = <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-dice-5" viewBox="0 0 16 16">
              <path d="M13 1a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h10zM3 0a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V3a3 3 0 0 0-3-3H3z" />
              <path d="M5.5 4a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm8 0a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0 8a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm-8 0a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm4-4a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
            </svg>

          } else {
            if (a == 4) {
              dicecube = <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-dice-4" viewBox="0 0 16 16">
                <path d="M13 1a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h10zM3 0a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V3a3 3 0 0 0-3-3H3z" />
                <path d="M5.5 4a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm8 0a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0 8a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm-8 0a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
              </svg>
            } else {
              if (a == 3) {
                dicecube = <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-dice-3" viewBox="0 0 16 16">
                  <path d="M13 1a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h10zM3 0a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V3a3 3 0 0 0-3-3H3z" />
                  <path d="M5.5 4a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm8 8a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm-4-4a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                </svg>
              } else {
                if (a == 2) {
                  dicecube = <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-dice-2" viewBox="0 0 16 16">
                    <path d="M13 1a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h10zM3 0a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V3a3 3 0 0 0-3-3H3z" />
                    <path d="M5.5 4a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm8 8a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                  </svg>
                } else {
                  if (a == 1) {
                    dicecube = <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-dice-1" viewBox="0 0 16 16">
                      <circle cx="8" cy="8" r="1.5" />
                      <path d="M13 1a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h10zM3 0a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V3a3 3 0 0 0-3-3H3z" />
                    </svg>
                  } else {

                  }
                }
              }
            }
          }
        }
      }
    } else {

    }

  }
  ddddice()


  let roleta = null

  useEffect(() => {
    // Create new wheel object specifying the parameters at creation time.
    var myWheel = new winwheel({       // Set outer radius so wheel fits inside the background.
      'innerRadius': 20,         // Make wheel hollow so segments dont go all way to center.        // Set default font size for the segments.
      // Align text to outside of wheel.    
      'numSegments': 10,
      'lineWidth': 5,  // Specify number of segments.
      'textFontSize': 20,    // Set font size as desired.
      'responsive': true,      // Font size.
      'segments':            // Definition of all the segments.
        [
          { 'fillStyle': '#ffffff', 'text': '2x' },
          { 'fillStyle': '#ff0000', 'text': 'loss' },
          { 'fillStyle': '#000000', 'text': '1.75x' },
          { 'fillStyle': '#ff0000', 'text': 'loss' },
          { 'fillStyle': '#555555', 'text': '1.5x' },
          { 'fillStyle': '#ff0000', 'text': 'loss' },
          { 'fillStyle': '#000000', 'text': '1.75x' },
          { 'fillStyle': '#ff0000', 'text': 'loss' },
          { 'fillStyle': '#555555', 'text': '1.5x' },
          { 'fillStyle': '#ff0000', 'text': 'loss' }


        ],
      'animation':               // Definition of the animation
      {
        'type': 'spinToStop',
        'duration': '5',
        'spins': 8,
        // Specify function to call when sound is to be triggered.
        'soundTrigger': 'pin',
        'callbackFinished': alertPrize
      },
      'pins':    // Specify pin parameters.
      {
        'number': 10,
        'outerRadius': 3,
        'margin': 0,
        'fillStyle': '#ffffff',
        'strokeStyle': '#ffffff'
      }
    });
    myWheel.segments[1].textFillStyle = '#000000'

    myWheel.segments[2].textFillStyle = '#FFFFFF'
    myWheel.segments[4].textFillStyle = '#ffffff'
    myWheel.segments[6].textFillStyle = '#FFFFFF'
    myWheel.segments[8].textFillStyle = '#ffffff'
    myWheel.segments[10].textFillStyle = '#ffffff'

    myWheel.segments[3].textFillStyle = '#ffffff'
    myWheel.segments[7].textFillStyle = '#ffffff'

    myWheel.segments[5].textFillStyle = '#ffffff'
    myWheel.segments[9].textFillStyle = '#ffffff'

    myWheel.draw();
    setRoll(myWheel)
  }, [])



  function calculatePrize(win, r) {
    resetroll()
    setRoll(roll => roll)
    // This formula always makes the wheel stop somewhere inside prize 3 at least
    // 1 degree away from the start and end edges of the segment.
    // let a = 36 * ((result - 1)/ 100)
    let stopAt = null
    if (win == 0) {
      //perdeu
      let a = [36, 108, 180, 252, 324]
      stopAt = (a[generateRandomInteger(4)] + Math.floor((Math.random() * 36)))
    } else {
      if (r == 200) {
        stopAt = (0 + Math.floor((Math.random() * 36)))
      } else {
        if (r == 175) {
          let a = [72, 216]
          stopAt = (a[generateRandomInteger(1)] + Math.floor((Math.random() * 36)))

        } else {
          if (r == 150) {
            let a = [144, 288]
            stopAt = (a[generateRandomInteger(1)] + Math.floor((Math.random() * 36)))
          } else {

          }
        }
      }
    }




    // Important thing is to set the stopAngle of the animation before stating the spin.
    roll.animation.stopAngle = stopAt;

    // May as well start the spin from here.
    roll.startAnimation();
  }

  function resetroll() {
    roll.stopAnimation(false);
    roll.rotationAngle = 0;
    roll.draw();
    drawTriangle();
  }
  drawTriangle()
  function drawTriangle() {
    // Get the canvas context the wheel uses.
    let ctx = roll.ctx;
    if (ctx != null) {


      ctx.strokeStyle = '#555555';     // Set line colour.
      ctx.fillStyle = '#ffffff';     // Set fill colour.
      ctx.lineWidth = 2;
      ctx.beginPath();              // Begin path.
      ctx.moveTo(120, 0);           // Move to initial position.
      ctx.lineTo(180, 0);           // Draw lines to make the shape.
      ctx.lineTo(150, 40);
      ctx.lineTo(120, 0);
      ctx.stroke();                 // Complete the path by stroking (draw lines).
      ctx.fill();
    }
    // Then fill.
  }

  function alertPrize() {
    // Do basic alert of the segment text.
    myalert("Voce ganhou " + "");
  }

  function dddroleta() {

    roleta = <canvas id='canvas' style={{ margin: "auto" }} width="300" height="300" data-responsiveMinWidth="180" data-responsiveScaleHeight="true">
      Canvas not supported, use another browser.
    </canvas>
  }
  dddroleta()


  function Blp(props) {

    if (props.reward == 0) {
      return <li >
        <h3>{props.result}</h3>
        <h1>{props.game}</h1>
        <h2 ><i className='logoto'></i>{props.amount / 1000000000000000000}</h2>
        <h5>{props.bet / 100}x</h5>
        <h4 style={{ color: "rgb(202, 0, 0)", borderRight: '0.1px solid rgb(202, 0, 0)' }}><i className='logoto'></i>{"-" + props.amount / 1000000000}</h4>

      </li>;
    } else {
      return <li >
        <h3>{props.result}</h3>
        <h1>{props.game}</h1>
        <h2 ><i className='logoto'></i>{props.amount / 1000000000000000000}</h2>
        <h5>{props.bet / 100}x</h5>
        <h4 style={{ color: "rgb(0, 202, 27)", borderRight: '0.1px solid #1eff00' }}><i className='logoto'></i>{(props.reward - props.amount) / 1000000000000000000}</h4>

      </li>;
    }
  }



  let doublehub = null
  // Scroll Into View API: https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView


  function ddddouble() {
    let width = (window.innerWidth - 15) + "px";
    doublehub = <ul id={"doublelist"} style={{ display: "inline-flex", width: "70vh", height: "12vh", overflow: "hidden", marginTop: "auto", marginBottom: "auto", backgroundColor: "#283234", borderRadius: "10px", boxShadow: "1px 1px 1px 1px #22242880", paddingLeft: "1vh", paddingRight: "1vh" }}>

      {arraydouble.map((e, index) => <Zezin ez={index} number={e.number} color={e.color} id={e.multi} />)}

    </ul>

  }


  function generatedouble() {
    let aaaaa = []
    if (arraydouble > 0) {

    } else {
      let a = ["#ff0000", "#000000", "#ff0000"]
      let m = ["2", "15", "2"]
      let aa = 0;
      let ab = 0;
      for (let i = 0; i < 100; i++) {
        if (m == 15) {
          aaaaa.push({
            number: 15,
            color: "#ffffff",
            multi: 15

          })
          aa = 0;
        } else {
          aa = aa + 1;
          if (aa > 20) {
            aaaaa.push({
              number: 15,
              color: "#ffffff",
              multi: 15

            })
            aa = 0;
          } else {

            if (ab == 0) {
              ab = ab + 1;
              aaaaa.push({
                number: 2,
                color: a[0],
                multi: 2
              })
            } else {
              ab = 0;
              aaaaa.push({
                number: 2,
                color: a[1],
                multi: 2
              })
            }

          }

        }

      }

      if (arraydouble <= 0) {
        setArrayDouble(aaaaa)
        ///ddddouble()
      }
      ddddouble()
    }


  }
  generatedouble()
  ///ddddouble()
  function resetdouble() {
    if (runanim) {

    } else {
      let a = document.getElementsByName(2)
      a[0].scrollIntoView(({
        block: 'center',
        inline: 'center'
      }));
    }

  }


  const eClickv2 = (e, c, win) => {
    let a = document.getElementById("doublelist").childNodes
    //console.log(a)
    let g = []
    var i = 1;                  //  set your counter to 1
    // console.log(a[0])
    function myLoop() {         //  create a loop function
      setTimeout(function () {   //  call a 3s setTimeout when the loop is called  //  your code here
        i++;                    //  increment the counter
        if (i < a.length) {
          if (a[i].childNodes[1] != null) {
            //   console.log(a[i].childNodes[1])
            a[i].childNodes[1].style.display = "none"
          }
          if (win == 0) {
            //perdeu           
            if (a[i].style.backgroundColor != c && a[i].name != e) {
              //g.push(a[e])
              a[i].scrollIntoView(({
                behavior: 'smooth',
                block: 'center',
                inline: 'center'
              }));
              g.push(a[i])

              for (var h in g) {
                if (g[h].childNodes[1] != null) {
                  //console.log(a[i].childNodes[1])
                  g[h].childNodes[1].style.display = "none"
                }
                if (h == g.length - 1) {
                  if (g[h].childNodes[1] != null) {
                    //console.log(a[i].childNodes[1])
                    g[h].childNodes[1].style.display = "block"
                  }
                }

              }


            } else {

              //a[i].getElementsByName("pointer")[0].style.display = "none"
            }
          } else {
            if (a[i].style.backgroundColor == c && a[i].name == e) {
              // g.push(a[e])
              a[i].scrollIntoView(({
                behavior: 'smooth',
                block: 'center',
                inline: 'center'
              }));
              if (a[i].childNodes[1] != null) {
                // console.log(a[i].childNodes[1])
                a[i].childNodes[1].style.display = "block"
              }
            } else {
              if (a[i].childNodes[1] != null) {
                //   console.log(a[i].childNodes[1])
                a[i].childNodes[1].style.display = "none"
              }
            }
          }


          //  if the counter < 10, call the loop function
          myLoop();             //  ..  again which will trigger another 
        }                       //  ..  setTimeout()
      }, 10)
    }
    myLoop()



  }

  function changesize() {
    let g = blockselected
    let a = document.getElementsByName(g[0])
    var i = g[1]
    if (a[i] != undefined) {
      a[i].style.height = "12vh"
    }


  }
  //changesize()
  function rgbToHex(r, g, b) {
    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
  }
  const hexToRgb = hex =>
    hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
      , (m, r, g, b) => '#' + r + r + g + g + b + b)
      .substring(1).match(/.{2}/g)
      .map(x => parseInt(x, 16))
  //alert(rgbToHex(0, 51, 255));

  const eClick = (e, c, win) => {
    let v = hexToRgb(c)

    let crgb = "rgb(" + v[0] + ", " + v[1] + ", " + v[2] + ")"

    let a = document.getElementsByName(e)
    var i = 1;

    let list = []
    function myLoop() {         //  create a loop function
      setTimeout(function () {   //  call a 3s setTimeout when the loop is called  //  your code here
        i++;                    //  increment the counter
        if (i < a.length) {
          if (win == 0) {
            //perdeu  
            //console.log(a[i].style.backgroundColor)         
            if (a[i].style.backgroundColor != crgb) {
              //g.push(a[e])

              a[i].scrollIntoView(({
                behavior: 'smooth',
                block: 'center',
                inline: 'center'
              }));
              list = [e, a[i].getAttribute("id")]

            }
          } else {
            //console.log(a[i].style.backgroundColor + "x" + crgb)   
            if (a[i].style.backgroundColor == crgb) {
              // g.push(a[e])
              a[i].scrollIntoView(({
                behavior: 'smooth',
                block: 'center',
                inline: 'center'
              }));
              list = [e, a[i].getAttribute("id")]

            }
          }


          //  if the counter < 10, call the loop function
          myLoop();             //  ..  again which will trigger another 
        } else {
          setblock(list)
          // console.log(blockselected)
        }                      //  ..  setTimeout()
      }, 10)
    }
    myLoop()



  }

  function Zezin(props) {
    let g = blockselected
    let a = document.getElementsByName(g[0])
    var i = g[1]
    if (props.color == "#ffffff") {
      if (props.ez == i) {
        //alert(props.ez)
        return <li name={props.id} id={props.ez} style={{ backgroundColor: props.color, border: "1px solid #ffffff20", color: "#ff0000", height: "12vh", minWidth: "12vh", display: "grid", margin: "auto", borderRadius: "10px" }}>
          <p style={{ fontSize: "2.5vh", height: "5vh", width: "5vh", margin: "auto", border: "3px solid #ff0000", borderRadius: "45px" }}>{props.number}</p>

        </li>;
      } else {
        return <li name={props.id} id={props.ez} style={{ backgroundColor: props.color, border: "1px solid #ffffff20", color: "#ff0000", height: "9.8vh", minWidth: "9.8vh", display: "grid", margin: "auto", borderRadius: "10px" }}>
          <p style={{ fontSize: "2.5vh", height: "5vh", width: "5vh", margin: "auto", border: "3px solid #ff0000", borderRadius: "45px" }}>{props.number}</p>

        </li>;
      }

    } else {
      if (props.ez == i) {
        //alert(props.ez)
        return <li name={props.id} id={props.ez} style={{ backgroundColor: props.color, border: "1px solid #ffffff20", height: "12vh", minWidth: "12vh", display: "grid", margin: "auto", borderRadius: "10px" }}>
          <p style={{ fontSize: "2.5vh", height: "5vh", width: "5vh", margin: "auto", border: "3px solid #ffffff", borderRadius: "45px" }}>{props.number}</p>

        </li>;
      } else {
        return <li name={props.id} id={props.ez} style={{ backgroundColor: props.color, border: "1px solid #ffffff20", height: "9.8vh", minWidth: "9.8vh", display: "grid", margin: "auto", borderRadius: "10px" }}>
          <p style={{ fontSize: "2.5vh", height: "5vh", width: "5vh", margin: "auto", border: "3px solid #ffffff", borderRadius: "45px" }}>{props.number}</p>

        </li>;
      }

    }


  }

  const runrotate = () => {
    var i = 1;

    function myLoop() {
      setTimeout(function () {
        i++;
        if (document.getElementById("euro") != null) {
          let element = document.getElementById("euro")
          //let aaa = {}
          if (runanim) {
            if (element.classList.contains('fast')) {

            } else {
              element.classList.add('fast');
              element.classList.remove('stop');
            }

          } else {
            //  element.style.transform = "rotateY(180deg)"
            //console.log(facevalue)
            if (facevalue != 0) {
              if (element.classList.contains('stop')) {
                // element.classList.remove('rotatecoin');
              } else {
                element.classList.add('stop');
              }
              //element.classList.add('stop');

            } else {


            }

          }
        }

        myLoop();
      }, 1000)
    }
    myLoop()
  }


  let coinfaces = null

  function dddcoinfaces() {
    coinfaces = <div id="euro" className='euro rotatecoin'>
      <article class="back" style={{ display: "grid", alignItens: "center", textAlign: "center" }}><i class="fa-solid fa-crown" style={{ height: "min-content", margin: "auto" }}></i></article>
      <article class="middle"></article>
      <article class="middle"></article>
      <article class="middle"></article>
      <article class="middle"></article>
      <article class="middle"></article>
      <article class="middle"></article>
      <article class="middle"></article>
      <article class="middle"></article>
      <article class="middle"></article>
      <article class="front" style={{ display: "grid", alignItens: "center", textAlign: "center" }}><i class="fa-solid fa-face-laugh-wink" style={{ height: "min-content", margin: "auto" }}></i></article>
    </div>
  }

  dddcoinfaces()
  //
  const listb = userlist

  let ulbets = null
  const ze = () => {

    if (listb.length == 0) {
      ulbets = <ul>
        <li >
          <h3 style={{ color: "white" }}>User</h3>
          <h1 style={{ color: "white" }}>Game</h1>
          <h2 style={{ color: "white" }}>Amount | DC</h2>
          <h5 style={{ color: "white" }}>Bet</h5>
          <h4 style={{ color: "white" }}>Return</h4>

        </li>
      </ul>
    } else {
      let lista = listb;
      ulbets = <ul>
        <li >
          <h3 style={{ color: "white", textAlign: "left" }}>User</h3>
          <h1 style={{ color: "white" }}>Game</h1>
          <h2 style={{ color: "white" }}>Amount | DC</h2>
          <h5 style={{ color: "white" }}>Bet</h5>
          <h4 style={{ color: "white" }}>Return</h4>
        </li>
        {lista.map((e) => <Blp game={e.index} amount={e.amount} bet={e.multi} result={e.user} reward={e.claimable} />).reverse()}
      </ul>
    }
    return ulbets;
  }

  let bets = null
  let infos = null
  let chart = null
  let bet = null
  let ctt = null

  function progressview() {
    if (runanim) {
      if (progressbarview) {

      } else {
        setpbview(true)

      }
    } else {
      if (progressbarview) {
        setpbview(false)
      } else {

      }
    }
  }
  progressview()
  let widtha = (window.innerWidth - (window.innerWidth / 10)) + "px";


  let ads = null
  if (window.innerWidth < 600) {
    ads = <iframe data-aa={2067509} src="//ad.a-ads.com/2067509?size=250x250" style={{ width: '250px', height: '250px', border: '0px', padding: 0, overflow: 'hidden', backgroundColor: 'transparent', margin: "auto" }} />

  } else {
    ads = <iframe data-aa={2067517} src="//ad.a-ads.com/2067517?size=728x90" style={{ width: '728px', height: '90px', border: '0px', padding: 0, overflow: 'hidden', backgroundColor: 'transparent', margin: "auto" }} />
  }
  let adsstake = null
  if (window.innerWidth < 600) {
    adsstake = <iframe data-aa={2067511} src="//ad.a-ads.com/2067511?size=250x250" style={{ width: '250px', height: '250px', border: '0px', padding: 0, overflow: 'hidden', backgroundColor: 'transparent' }} />

  } else {
    adsstake = <iframe data-aa={2067521} src="//ad.a-ads.com/2067521?size=728x90" style={{ width: '728px', height: '90px', border: '0px', padding: 0, overflow: 'hidden', backgroundColor: 'transparent' }} />
  }
  let adsmarketplace = null
  if (window.innerWidth < 600) {
    adsmarketplace = <iframe data-aa={2067513} src="//ad.a-ads.com/2067513?size=250x250" style={{ width: '250px', height: '250px', border: '0px', padding: 0, overflow: 'hidden', backgroundColor: 'transparent' }} />

  } else {
    adsmarketplace = <iframe data-aa={2067516} src="//ad.a-ads.com/2067516?size=728x90" style={{ width: '728px', height: '90px', border: '0px', padding: 0, overflow: 'hidden', backgroundColor: 'transparent' }} />
  }
  function myalert(e) {
    setmaview(true)
    setcttmodal(<div style={{ height: "100%", width: widtha, backgroundColor: "#00000000", position: "fixed", display: "grid", margin: "auto" }}>
      <div style={{ display: "grid", gridTemplateRows: "96% 4%", padding: "1vh", height: "min-content", width: "100%", maxWidth: "375px", backgroundColor: "#111111", border: "1px solid #0187c9", boxShadow: "2px 2px 2px 1px #0187c940", borderRadius: "10px", marginBottom: "20vh", marginTop: "auto", marginLeft: "auto", marginRight: "20vh" }}>
        <p style={{ textAlign: "left" }}>Warning:<br />
          {e}</p>
      </div>
    </div>)
    setTimeout(function () {
      setmaview(false)
    }, 2500)
  }

  function setscreen() {
    let aac = window.location.pathname;
    let hg = aac.split("/")
    let a = aac

    if (hg[1].includes("en-us")) {
      a = "/" + hg[2]
    } else {
      a = aac
    }

    if (a == "/play/Limbo") {
      if (gamesscreen) {

      } else {
        setGview(true)
        setMKTview(false)
        setLPview(false)
        setAIRview(false)
        setwhiteview(false)
        setshopview(false)
      }
      bets = <div className="bets">
        <p>Latest Bets<br />

        </p>

        {ze()}
      </div>


      infos = <div className="infos">
        <p className='titlegame'>Limbo</p>
        <div className='balances'>
          {balancecoin}
          {balancebnb}
        </div>
      </div>

      if (runanim) {
        chart = <div className="crt">
          <h1 style={ca()}>{ddd()}x
            <p style={{ fontSize: "1.8vh", margin: "auto" }}>Awaiting result...</p>
          </h1>

        </div>
      } else {
        chart = <div className="crt">
          <h1 style={ca()}>{ddd()}x
            <p style={{ fontSize: "1.8vh", margin: "auto" }}>Last confirmed result</p>
          </h1>
        </div>
      }
      let progress = <i class="fa-thin fa-c loading"></i>
      bet = <div className="bet">

        <div>
          <p>Amount</p>
          <div style={inputVerror()}>
            <i className='logoto'></i>
            <input style={inputVerrorinput()} id="aminput" type="number" onChange={(e) => defamount(e.target.value)} min={1} max={accountBalancemTB / 1000000000000000000} value={nextblock(amountin, 18)} />
            <p style={inputVerrorinput()} onClick={() => {
              document.getElementById("aminput").value = nextblock(accountBalancemTB, 18);
              defamount(accountBalancemTB / 1000000000000000000)
            }}>MAX</p>

          </div>
          <p style={{ textAlign: "right" }}>min: 1 DC</p>
        </div>
        <div>
          <p>Cashout</p>
          <div style={inputerror()}>
            <i></i>
            <input style={inputerrorinput()} type="number" onChange={(e) => setmultipler(e.target.value)} min={1.01} max={1000} value={xin} />
            <p style={inputerrorinput()}>x</p>
          </div>
          <p style={{ textAlign: "right" }}>Min: 1.01x | Max: 1000x</p>

        </div>

        <button onClick={() => { bettinggo(0) }}>
          {progressbarview && progress}
          BET
        </button>

      </div>
    } else {
      if (a == "/play/Dice") {
        if (gamesscreen) {

        } else {
          setGview(true)
          setMKTview(false)
          setLPview(false)
          setAIRview(false)
          setwhiteview(false)
          setshopview(false)
        }
        bets = <div className="bets">
          <p>Latest Bets<br />
          </p>
          {ze()}
        </div>


        infos = <div className="infos">
          <p className='titlegame'>Dice</p>
          <div className='balances'>
            {balancecoin}
            {balancebnb}
          </div>
        </div>

        if (runanim) {
          chart = <div className="crt">
            <h1 style={c()} >  {dicecube}
              <p style={{ fontSize: "1.8vh" }}>Awaiting result...</p>
            </h1>

          </div>
        } else {
          chart = <div className="crt">
            <h1 style={c()} >
              {dicecube}
              <p style={{ fontSize: "1.8vh" }}>Last confirmed result</p>
            </h1>
          </div>
        }


        let progress = <i class="fa-thin fa-c loading"></i>

        let buttonbet = <button onClick={() => { bettinggo(1) }}>
          {progressbarview && progress} BET IN {xin.toString().split(".")[0]}
        </button>

        bet = <div className="bet">

          <div>
            <p>Amount</p>
            <div style={inputVerror()}>
              <i className='logoto'></i>
              <input style={inputVerrorinput()} id="aminput" type="number" onChange={(e) => defamount(e.target.value)} min={1} max={accountBalancemTB / 1000000000000000000} value={nextblock(amountin, 18)} />
              <p style={inputVerrorinput()} onClick={() => {
                document.getElementById("aminput").value = nextblock(accountBalancemTB, 18);
                defamount(accountBalancemTB / 1000000000000000000)
              }}>MAX</p>

            </div>
            <p style={{ textAlign: "right" }}>min: 1 DC</p>
          </div>
          <div>
            <p>Which side will the die show?</p>
            <ul>
              <li onClick={() => {
                setmultipler(1.01)
                setbtbetv(true)
              }}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-dice-1" viewBox="0 0 16 16">
                  <circle cx="8" cy="8" r="1.5" />
                  <path d="M13 1a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h10zM3 0a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V3a3 3 0 0 0-3-3H3z" />
                </svg>
              </li>
              <li onClick={() => {
                setmultipler(2.01)
                setbtbetv(true)
              }}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-dice-2" viewBox="0 0 16 16">
                  <path d="M13 1a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h10zM3 0a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V3a3 3 0 0 0-3-3H3z" />
                  <path d="M5.5 4a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm8 8a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                </svg>

              </li>
              <li onClick={() => {
                setmultipler(3.01)
                setbtbetv(true)
              }}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-dice-3" viewBox="0 0 16 16">
                  <path d="M13 1a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h10zM3 0a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V3a3 3 0 0 0-3-3H3z" />
                  <path d="M5.5 4a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm8 8a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm-4-4a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                </svg>
              </li>
              <li onClick={() => {
                setmultipler(4.01)
                setbtbetv(true)
              }}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-dice-4" viewBox="0 0 16 16">
                  <path d="M13 1a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h10zM3 0a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V3a3 3 0 0 0-3-3H3z" />
                  <path d="M5.5 4a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm8 0a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0 8a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm-8 0a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                </svg>
              </li>
              <li onClick={() => {
                setmultipler(5.01)
                setbtbetv(true)
              }}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-dice-5" viewBox="0 0 16 16">
                  <path d="M13 1a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h10zM3 0a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V3a3 3 0 0 0-3-3H3z" />
                  <path d="M5.5 4a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm8 0a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0 8a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm-8 0a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm4-4a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                </svg>
              </li>
              <li onClick={() => {
                setmultipler(6.01)
                setbtbetv(true)
              }}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-dice-6" viewBox="0 0 16 16">
                  <path d="M13 1a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h10zM3 0a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V3a3 3 0 0 0-3-3H3z" />
                  <path d="M5.5 4a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm8 0a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0 8a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-4a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm-8 4a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-4a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                </svg>
              </li>
            </ul>

          </div>

          {buttonbetv && buttonbet}

          <p style={{ fontSize: "10px" }}>Maybe can ocurred transactions fail, its a network failed, please any problem report to developers.</p>
          <p style={{ fontSize: "10px" }}>Talvez ocorra erros na transação, mas é erro da propria network, por favor qualquer problema reporte ao desenvolvedores.</p>
        </div>
      } else {
        if (a == "/play/Circle") {
          if (gamesscreen) {

          } else {
            setGview(true)
            setMKTview(false)
            setLPview(false)
            setAIRview(false)
            setwhiteview(false)
            setshopview(false)
          }
          bets = <div className="bets">
            <p>Latest Bets<br />
            </p>
            {ze()}
          </div>


          infos = <div className="infos">
            <p className='titlegame'>Circle</p>
            <div className='balances'>
              {balancecoin}
              {balancebnb}
            </div>
          </div>

          if (runanim) {
            chart = <div className="crt">
              {roleta}

            </div>
          } else {
            chart = <div className="crt">
              {roleta}

            </div>
          }
          let progress = <i class="fa-thin fa-c loading"></i>

          let buttonbet = <button onClick={() => {

            bettinggo(2)
          }}>
            {progressbarview && progress}
            BET IN {xin}x
          </button>

          bet = <div className="bet">

            <div>
              <p>Amount</p>
              <div style={inputVerror()}>
                <i className='logoto'></i>
                <input style={inputVerrorinput()} id="aminput" type="number" onChange={(e) => defamount(e.target.value)} min={1} max={accountBalancemTB / 1000000000000000000} value={nextblock(amountin, 18)} />
                <p style={inputVerrorinput()} onClick={() => {
                  document.getElementById("aminput").value = nextblock(accountBalancemTB, 18);
                  defamount(accountBalancemTB / 1000000000000000000)
                }}>MAX</p>

              </div>
              <p style={{ textAlign: "right" }}>Min: 1 DC</p>
            </div>
            <div>
              <p>At what value will the wheel stop?</p>
              <ul style={{ gridTemplateColumns: "33% 33% 33%" }}>
                <li style={{ height: "45px", display: "grid", backgroundColor: "#555555", borderRadius: "10px", color: "#ffffff", textAlign: "center", alignItems: "center", width: "95%", margin: "auto" }} onClick={() => {
                  setmultipler(1.501)
                  setbtbetv(true)
                }}>
                  <p style={{ margin: "auto", textAlign: "center" }}>1.50x</p>
                </li>
                <li style={{ height: "45px", display: "grid", backgroundColor: "#000000", borderRadius: "10px", color: "#ffffff", textAlign: "center", alignItems: "center", width: "95%", margin: "auto" }} onClick={() => {
                  setmultipler(1.75)
                  setbtbetv(true)
                }}>
                  <p style={{ margin: "auto", textAlign: "center" }}>1.75x</p>
                </li>
                <li style={{ height: "45px", display: "grid", backgroundColor: "#ffffff", borderRadius: "10px", color: "#000000", textAlign: "center", alignItems: "center", width: "95%", margin: "auto" }} onClick={() => {
                  setmultipler(2)
                  setbtbetv(true)
                }}>
                  <p style={{ margin: "auto", textAlign: "center", color: "#000" }}>2x</p>

                </li>
              </ul>

            </div>

            {buttonbetv && buttonbet}
            <p style={{ fontSize: "10px" }}>Maybe can ocurred transactions fail, its a network failed, please any problem report to developers.</p>
            <p style={{ fontSize: "10px" }}>Talvez ocorra erros na transação, mas é erro da propria network, por favor qualquer problema reporte ao desenvolvedores.</p>
          </div>
        } else {
          if (a == "/play/Double") {
            if (gamesscreen) {

            } else {
              setGview(true)
              setMKTview(false)
              setLPview(false)
              setAIRview(false)
              setwhiteview(false)
              setshopview(false)
            }
            bets = <div className="bets">
              <p>Latest Bets<br />
              </p>
              {ze()}
            </div>


            infos = <div className="infos">
              <p className='titlegame'>Double</p>
              <div className='balances'>
                {balancecoin}
                {balancebnb}
              </div>
            </div>

            if (runanim) {
              chart = <div className="crt" style={{
                overflow: "hidden"
              }}>

                <h1 style={{
                  width: "100%",
                  margin: "0"
                }}>  {doublehub}

                </h1>

              </div>
            } else {
              chart = <div className="crt" style={{
                overflow: "hidden"
              }}>
                <h1 style={{
                  width: "100%",
                  margin: "0"
                }}>  {doublehub}
                </h1>

              </div>
            }
            let progress = <i class="fa-thin fa-c loading"></i>

            let buttonbet = <button onClick={() => {
              bettinggo(3)
              resetdouble()
            }}>
              {progressbarview && progress}
              BET IN {xin}x
            </button>

            bet = <div className="bet">

              <div>
                <p>Amount</p>
                <div style={inputVerror()}>
                  <i className='logoto'></i>
                  <input style={inputVerrorinput()} id="aminput" type="number" onChange={(e) => defamount(e.target.value)} min={1} max={accountBalancemTB / 1000000000000000000} value={nextblock(amountin, 18)} />
                  <p style={inputVerrorinput()} onClick={() => {
                    document.getElementById("aminput").value = nextblock(accountBalancemTB, 18);
                    defamount(accountBalancemTB / 1000000000000000000)
                  }}>MAX</p>

                </div>
                <p style={{ textAlign: "right" }}>Min: 1 DC</p>
              </div>
              <div>
                <p>Choose a color</p>
                <ul style={{ gridTemplateColumns: "33% 33% 33%" }}>
                  <li style={{ height: "45px", display: "grid", boxShadow: "1px 1px 1px 1px #00000050", backgroundColor: "#000000", borderRadius: "10px", color: "#ffffff", textAlign: "center", alignItems: "center", width: "95%", margin: "auto" }} onClick={() => {
                    setmultipler(2)
                    setdoublecolor("#000000")
                    setbtbetv(true)
                  }}>
                    2X
                  </li>
                  <li style={{ height: "45px", display: "grid", boxShadow: "1px 1px 1px 1px #00000050", backgroundColor: "#ffffff", borderRadius: "10px", color: "#ff0000", textAlign: "center", alignItems: "center", width: "95%", margin: "auto" }} onClick={() => {
                    setmultipler(15)
                    setdoublecolor("#ffffff")
                    setbtbetv(true)
                  }}>
                    15x
                  </li>
                  <li style={{ height: "45px", display: "grid", boxShadow: "1px 1px 1px 1px #00000050", backgroundColor: "#ff0000", borderRadius: "10px", color: "#ffffff", textAlign: "center", alignItems: "center", width: "95%", margin: "auto" }} onClick={() => {
                    setmultipler(2)
                    setdoublecolor("#ff0000")
                    setbtbetv(true)
                  }}>
                    2X
                  </li>
                </ul>

              </div>

              {buttonbetv && buttonbet}
              <p style={{ fontSize: "10px" }}>Maybe can ocurred transactions fail, its a network failed, please any problem report to developers.</p>
              <p style={{ fontSize: "10px" }}>Talvez ocorra erros na transação, mas é erro da propria network, por favor qualquer problema reporte ao desenvolvedores.</p>
            </div>
          } else {
            if (a == "/play/Twofaces") {
              if (gamesscreen) {

              } else {
                setGview(true)
                setMKTview(false)
                setLPview(false)
                setAIRview(false)
                setwhiteview(false)
                setshopview(false)
              }
              bets = <div className="bets">
                <p>Latest Bets<br />
                </p>
                {ze()}
              </div>


              infos = <div className="infos">
                <p className='titlegame'>Two Faces</p>
                <div className='balances'>
                  {balancecoin}
                  {balancebnb}
                </div>
              </div>

              if (runanim) {
                chart = <div className="crt">
                  <h1>  {coinfaces}
                    <p style={{ fontSize: "1.8vh" }}></p>
                  </h1>
                </div>
              } else {
                chart = <div className="crt">

                  <h1>  {coinfaces}
                    <p style={{ fontSize: "1.8vh" }}></p>
                  </h1>

                </div>
              }

              let progress = <i class="fa-thin fa-c loading"></i>

              let buttonbet = <button onClick={() => {
                bettinggo(4)
                if (document.getElementById("euro") != null) {
                  let element = document.getElementById("euro")
                  if (element.classList.contains('fast')) {
                    element.classList.remove('stop');
                  } else {
                    element.classList.add('fast');
                    element.classList.remove('stop');
                  }
                }

              }}>
                {progressbarview && progress}
                BET IN "{coinside}"
              </button>

              bet = <div className="bet">

                <div>
                  <p>Amount</p>
                  <div style={inputVerror()}>
                    <i className='logoto'></i>
                    <input style={inputVerrorinput()} id="aminput" type="number" onChange={(e) => defamount(e.target.value)} min={1} max={accountBalancemTB / 1000000000000000000} value={nextblock(amountin, 18)} />
                    <p style={inputVerrorinput()} onClick={() => {
                      document.getElementById("aminput").value = nextblock(accountBalancemTB, 18);
                      defamount(accountBalancemTB / 1000000000000000000)
                    }}>MAX</p>

                  </div>
                  <p style={{ textAlign: "right" }}>Min: 1 DC</p>
                </div>
                <div>
                  <p>which side will the coin show?</p>
                  <ul style={{ gridTemplateColumns: "50% 50%" }}>
                    <li style={{ backgroundColor: "#eae56f00", borderRadius: "10px", color: "#ffffff", textAlign: "center", alignItems: "center", width: "95%", margin: "auto", border: "1px solid #ffffff" }} onClick={() => {
                      setmultipler(2)
                      setCoinSide("CARA")
                      setbtbetv(true)
                    }}>
                      FACE
                    </li>
                    <li style={{ backgroundColor: "#89f26e00", borderRadius: "10px", color: "#ffffff", textAlign: "center", alignItems: "center", width: "95%", margin: "auto", border: "1px solid #ffffff" }} onClick={() => {
                      setmultipler(2)
                      setCoinSide("COROA")
                      setbtbetv(true)
                    }}>
                      CROWN
                    </li>
                  </ul>
                </div>
                {buttonbetv && buttonbet}
                <p style={{ fontSize: "10px" }}>Maybe can ocurred transactions fail, its a network failed, please any problem report to developers.</p>
                <p style={{ fontSize: "10px" }}>Talvez ocorra erros na transação, mas é erro da propria network, por favor qualquer problema reporte ao desenvolvedores.</p>
              </div>
            } else {
              if (a == "/play") {
                if (gamesscreen) {

                } else {
                  setGview(true)
                  setMKTview(false)
                  setLPview(false)
                  setAIRview(false)
                  setwhiteview(false)
                  setshopview(false)
                }
              } else {
                if (a == "/sniperbot") {
                  if (lpscreen) {

                  } else {
                    setGview(false)
                    setMKTview(false)
                    setLPview(true)
                    setAIRview(false)
                    setwhiteview(false)
                    setshopview(false)
                  }
                } else {
                  if (a == "/stake") {
                    if (Airscreen) {

                    } else {
                      setGview(false)
                      setMKTview(false)
                      setLPview(false)
                      setAIRview(true)
                      setwhiteview(false)
                      setshopview(false)
                    }
                  } else {
                    if (a == "/marketplace") {
                      if (MKTscreen) {

                      } else {
                        setGview(false)
                        setMKTview(true)
                        setLPview(false)
                        setAIRview(false)
                        setwhiteview(false)
                        setshopview(false)
                      }
                    } else {
                      if (a == "/whitepaper") {
                        if (whitescreen) {

                        } else {
                          setGview(false)
                          setMKTview(false)
                          setLPview(false)
                          setAIRview(false)
                          setwhiteview(true)
                          setshopview(false)
                        }
                      } else {
                        if (a == "/shopping") {
                          if (SHOPscreen) {

                          } else {
                            setGview(false)
                            setMKTview(false)
                            setLPview(false)
                            setAIRview(false)
                            setwhiteview(false)
                            setshopview(true)
                          }
                        } else {

                        }
                      }
                    }
                  }
                }
              }

            }
          }
        }
      }
    }
  }
  setscreen()
  let cpr = <div className='rcr' style={{
    height: "30vh",
    width: "100%",
    marginTop: "14vh",

  }}>
    <div></div>
    <p style={{ color: "#090e16" }}>
      Email: contact@dalmatacoin.com
      <br />
      Copyright © 2022 DALMATA COIN - All Rights Reserved</p>
  </div>
  let warning = <div style={{
    height: "min-content",
    width: "100%",
    listStyleType: "none",
    color: "#090e16",

    display: "grid",
    gridTemplateRows: "min-content 70%",
    gridArea: "wn"
  }}>
    <p style={{
      textAlign: "center",
      fontSize: "4vh",
      fontWeight: "bold"
    }}>TERMS OF USE</p>
    <p className="ctt1" style={{
      color: "#090e16",
    }}>
      This platform offers games with risky investments.
      <br />To use our website, please be aware that you must be over 18 years of age.
      <br />We are not responsible for your violation of this rule.
      <br />All games on the platform are fair and use a public code for reliability verification.
      <br />All your assets will not be moved or modified without your authorization.
      <br />All transactions on this site will only be carried out upon confirmation in the user's wallet.
      <br />Play responsibly and have fun.</p>

  </div>
  return (
    <div className="farms">
      {infos}
      <div style={{ gridArea: "chart", display: "grid", gridTemplateColumns: "repeat(auto-fit, minmax(45vh, 1fr)", gap: "10px", width: "99%", margin: "auto" }}>
        {chart}
        {bet}
      </div>
      {ctt}
      {warning}
 
      {myalertview && contentmodal}
    </div>
  );
}

export default Games;