import React, { useState, useEffect, Component, useContext } from 'react';

import cakeabi from "../../abis/pankakeRouter.json";
import botabi from "../../abis/botbuyabi.json";
import factoryabi from "../../abis/factoryabi.json";
import miniabi from "../../abis/coin.json";
import bnbabi from "../../abis/bnbabi.json";
import tokenslist from "../../tokens.json";
import AppContext from "../../framework/helpers/AppContext";
import { ethers } from 'ethers';

const Web3 = require('web3-eth');

const Trade = (props) => {
    const {
        account,
        provider,
        chain,
        chains,
        web3,
        Tokencontract,
        gasTX,
        getID,
        callTX,
        sendTX,
        nSendTX,
        nSendCT,
        _sync
    } = useContext(AppContext)


    const [hubinteract, setHint] = React.useState(false);
    const [accounts, setAccounts] = useState([account]);
    const [isa, setIsA] = useState(false);
    const [amount, setAmount] = useState(0);
    const [tokenAname, setTokenAname] = useState("MTK");
    const [tokenBname, setTokenBname] = useState("MTK");
    const [AllowA, setAllowA] = useState(0);
    const [amountin, setAmountin] = useState(0);
    const [amountminout, setAmountout] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const [AllowTPT, setATPT] = useState(0);
    const [Network, setNetwork] = useState(chain);
    const [NetworkB, setNetworkB] = useState(
        chain
    );
    const [userlist, setul] = useState(chains);
    const [netlist, setN] = useState([
        {
            "nome": "BSC",
            "ts": "0",
            "icon": "https://bscscan.com/token/images/binance_32.png",
            "ubalance": "0",
            "Allowance": "0",
        }, {
            "nome": "Arbitrum",
            "ts": "0",
            "icon": "https://arbiscan.io/images/svg/brands/arbitrum.svg?v=1.3",
            "ubalance": "0",
            "Allowance": "0",
        }, {
            "nome": "Fantom ",
            "ts": "0",
            "icon": "https://ftmscan.com/images/svg/brands/fantom.svg?v=1.3",
            "ubalance": "0",
            "Allowance": "0",
        }, {
            "nome": "Avalanche ",
            "ts": "0",
            "icon": "https://snowtrace.io/images/svg/brands/mainbrand-1.svg?v=23.2.4.1",
            "ubalance": "0",
            "Allowance": "0",
        }
    ]);
    useEffect(() => {
        //document.title = "MARTIK | SMART BRIDGE"
    }, []);
    useEffect(() => {
        let interval = null;
        interval = setInterval(() => {
            setSeconds(seconds => seconds + 1);
            if (seconds > 1) { } else {
                connectToSelectedNetwork();
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [seconds]);

    const providers = {
        56: new Web3(new Web3.providers.HttpProvider("https://bsc-dataseed.binance.org")),
        250: new Web3(new Web3.providers.HttpProvider("https://rpcapi.fantom.network/`")),
        42161: new Web3(new Web3.providers.HttpProvider("https://arb1.arbitrum.io/rpc")),
        43114: new Web3(new Web3.providers.HttpProvider("https://api.avax.network/ext/bc/C/rpc")),
    }

    function nextblock(accountBalancemctTB, d) {
        return accountBalancemctTB == undefined ? 0 : parseFloat(ethers.utils.formatUnits(accountBalancemctTB, d)).toFixed(2)
    }
    async function approve() {
        nSendTX(Network.contract, bnbabi, getID(), 'approve', '0', 0, '0x9a8C1Ac49fb0580272e5Be55552416D11755DfA0', (ethers.utils.parseUnits(amount, 18)).toString()).then(a => {
            connectToSelectedNetwork()
        }).catch(e => {

        })
    }
    async function callmigration() {
        let e = getID()
        let from = (e).toString()
        let to = (NetworkB.chainId).toString()
        let _amount = (ethers.utils.parseUnits(amount, 18)).toString()
        let who = accounts[0]
        const data = {
            'netidfrom': from,
            'netidto': to,
            'amount': _amount,
            'who': who
        }
        _sync('http://localhost:9116/migrate', data)
            .then(a => {
                connectToSelectedNetwork()
            }).catch(e => {

            })
    }
    async function connectToSelectedNetwork() {
        let e = getID()
        let pos = 0

        if ((e).toString() == '56') { pos = 0 }
        //  if ((networkId).toString() == '97') { pos = 1 }
        if ((e).toString() == '42161') { pos = 1 }
        if ((e).toString() == '250') { pos = 2 }
        if ((e).toString() == '43114') { pos = 3 }

        setNetwork(userlist[pos])
        const bsc = await new providers['56'].Contract(bnbabi, "0x116526135380E28836C6080f1997645d5A807FAE");
        const arb = await new providers['42161'].Contract(bnbabi, "0x47ab4242462087273716881A13714dE5d0235d67");
        const ftm = await new providers['250'].Contract(bnbabi, "0x116526135380E28836C6080f1997645d5A807FAE");
        // const brise = await new providers['32520'].Contract(bnbabi, "0x47ab4242462087273716881A13714dE5d0235d67");
        const avax = await new providers['43114'].Contract(bnbabi, "0x116526135380E28836C6080f1997645d5A807FAE");

        const allow = accounts[0] == undefined ? 0 : nextblock(await bsc.methods.allowance(accounts[0], '0xA44145FB8962bc5f2458e94139e7e88C09Ef54A6').call(), 18)
        setATPT(allow)
        const ts = [
            {
                "nome": "BSC",
                "ts": (parseFloat(nextblock(await bsc.methods.totalSupply().call(), 18)) - parseFloat(nextblock(await bsc.methods.balanceOf("0x000000000000000000000000000000000000dead").call(), 18))).toString(),
                "icon": "https://bscscan.com/token/images/binance_32.png",
                "ubalance": accounts[0] == undefined ? 0 : nextblock(await bsc.methods.balanceOf(accounts[0]).call(), 18),
                "Allowance": accounts[0] == undefined ? 0 : nextblock(await bsc.methods.allowance(accounts[0], '0x9a8C1Ac49fb0580272e5Be55552416D11755DfA0').call(), 18),
            }, {
                "nome": "Arbitrum",
                "ts": nextblock(await arb.methods.totalSupply().call(), 18),
                "icon": "https://arbiscan.io/images/svg/brands/arbitrum.svg?v=1.3",
                "ubalance": accounts[0] == undefined ? 0 : nextblock(await arb.methods.balanceOf(accounts[0]).call(), 18),
                "Allowance": accounts[0] == undefined ? 0 : nextblock(await arb.methods.allowance(accounts[0], '0x9a8C1Ac49fb0580272e5Be55552416D11755DfA0').call(), 18),
            }, /*{
                "nome": "Brise",
                "ts": nextblock(await brise.methods.totalSupply().call(), 18),
                "icon": "https://brisescan.com/js/2e726d3c758440b68ac4d8f7314e5acf-e809015dac7e5864bdc52440112a7acd.png?vsn=d",
                "ubalance": accounts[0] == undefined ? 0 : nextblock(await brise.methods.balanceOf(accounts[0]).call(), 18),
                "Allowance": accounts[0] == undefined ? 0 : nextblock(await brise.methods.allowance(accounts[0], '0x9a8C1Ac49fb0580272e5Be55552416D11755DfA0').call(), 18),
            },*/ {
                "nome": "Fantom",
                "ts": nextblock(await ftm.methods.totalSupply().call(), 18),
                "icon": "https://ftmscan.com/images/svg/brands/fantom.svg?v=1.3",
                "ubalance": accounts[0] == undefined ? 0 : nextblock(await ftm.methods.balanceOf(accounts[0]).call(), 18),
                "Allowance": accounts[0] == undefined ? 0 : nextblock(await ftm.methods.allowance(accounts[0], '0x9a8C1Ac49fb0580272e5Be55552416D11755DfA0').call(), 18),
            }, {
                "nome": "Avax",
                "ts": nextblock(await avax.methods.totalSupply().call(), 18),
                "icon": "https://snowtrace.io/images/svg/brands/mainbrand-1.svg?v=23.2.4.1",
                "ubalance": accounts[0] == undefined ? 0 : nextblock(await avax.methods.balanceOf(accounts[0]).call(), 18),
                "Allowance": accounts[0] == undefined ? 0 : nextblock(await avax.methods.allowance(accounts[0], '0x9a8C1Ac49fb0580272e5Be55552416D11755DfA0').call(), 18),
            }

        ]
        setN(ts)

    }
    function Car(props) {
        return <li onClick={(e) => {
            setHint(false)
            setAmountin(0)
            setAmountout(0)
            setNetworkB({
                "nome": props.data.nome,
                "bname": props.data.bname,
                "icon": props.data.icon,
                "contract": props.data.contract,
                "pos": props.data.pos,
                "chainId": props.data.chainId,
                "rpc": props.data.rpc,
                "url": props.data.url,
                "symbol": props.data.symbol,
                "dec": props.data.dec,

            })
        }}>
            <img src={props.icon}></img>
            <h1>{props.brand}<br />{props.symbol}</h1>

        </li>;
    }

    const cars = userlist;
    let ulconfig = <ul id='list'>
        {cars.map((car, index) => <Car data={car} brand={car.nome} i={index} symbol={car.bname} icon={car.icon} ct={car.contract} />)}
    </ul>

    let modaltokensls = <div className='Trademainboletamodalls' style={{ border: "1px solid #ffffff10", display: "grid", gridTemplateRows: "auto", position: "absolute", margin: "auto", zIndex: "100000000000" }}>
        <div>
            <h1>SELECT NETWORK</h1>
            <span className="fas fa-times" onClick={() => setHint(!hubinteract)}></span>
        </div>

        {ulconfig}

    </div>

    let tradebuttonjsbuy = Network["chainId"] != NetworkB["chainId"] && <>
        {parseFloat(netlist[Network["pos"]]['Allowance']) < parseFloat(amount)
            ? <button className="tradebb" type="button" onClick={() => {
                approve()
            }} >{"Approve"}
            </button>
            : <button className="tradebb" type="button" onClick={() => {
                callmigration()
            }} >
                {"Migrate"}
            </button>
        }
    </>

    let logo = <svg onClick={() => { window.location.href = "/" }} style={{
        height: "25px",
        width: "25px",
        margin: "auto",
        borderRadius: "360px"
    }} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        width="100%" viewBox="0 0 512 512" enableBackground="new 0 0 512 512" >
        <path fill="#171B1E" opacity="1.000000" stroke="none"
            d="
M1.000002,278.000000 
C1.000000,263.312439 1.000000,248.624878 1.387354,233.375961 
C2.500387,228.851822 3.429312,224.913971 3.918932,220.922226 
C7.224019,193.976761 15.036845,168.316879 27.083546,144.102798 
C41.233334,115.661491 59.781677,90.453018 83.404778,68.868477 
C105.372139,48.796783 129.903915,33.080418 157.146301,21.424257 
C180.479950,11.440520 204.805969,5.295873 230.089050,2.918592 
C231.110458,2.822552 232.031952,1.663684 233.000000,1.000003 
C248.020889,1.000000 263.041779,1.000000 278.623993,1.387359 
C283.152374,2.500634 287.094696,3.428169 291.090607,3.919375 
C314.993469,6.857679 338.014252,13.178589 359.902191,23.068634 
C392.347778,37.729149 421.027008,57.713017 445.013702,84.358612 
C462.501770,103.785248 476.804657,125.103470 487.985809,148.728119 
C501.012421,176.251846 508.763367,205.219070 510.645660,235.395340 
C511.767059,253.373199 510.661194,271.644226 508.869507,289.615265 
C505.785614,320.547607 495.697998,349.484406 480.673889,376.703156 
C467.169525,401.168640 450.537689,423.087677 429.749023,441.944366 
C417.136475,453.384766 403.734680,463.565277 389.262299,472.472961 
C354.492615,493.873627 316.572266,505.736816 276.059723,508.756256 
C261.040222,509.875702 245.791687,508.864258 230.704956,507.914032 
C209.398926,506.572174 188.806152,501.512268 168.776199,494.095398 
C129.914383,479.705231 96.332054,457.599518 68.277985,426.922577 
C47.622231,404.335602 31.411585,379.079254 19.935560,350.775787 
C10.904083,328.501373 4.940612,305.440308 2.923140,281.417023 
C2.823668,280.232544 1.665301,279.136963 1.000002,278.000000 
M97.000000,313.500000 
C97.000000,344.257080 97.000000,375.014160 97.000000,405.700409 
C127.318954,405.700409 157.051147,405.700409 186.707062,405.700409 
C186.707062,310.677582 186.707062,215.954620 186.707062,121.299629 
C156.680969,121.299629 126.948761,121.299629 97.000000,121.299629 
C97.000000,185.232666 97.000000,248.866333 97.000000,313.500000 
M327.000000,252.500000 
C327.000000,303.253845 327.000000,354.007721 327.000000,404.704437 
C356.977478,404.704437 386.376312,404.704437 415.711182,404.704437 
C415.711182,310.019043 415.711182,215.629501 415.711182,121.295517 
C386.022583,121.295517 356.623749,121.295517 327.000000,121.295517 
C327.000000,164.893768 327.000000,208.196884 327.000000,252.500000 
M215.000000,268.500000 
C215.000000,287.924225 215.000000,307.348480 215.000000,326.704315 
C243.315964,326.704315 271.045776,326.704315 298.707489,326.704315 
C298.707489,280.682617 298.707489,234.957184 298.707489,189.295624 
C270.684143,189.295624 242.954330,189.295624 215.000000,189.295624 
C215.000000,215.560394 215.000000,241.530197 215.000000,268.500000 
z"/>
        <path fill="#000000" opacity="1.000000" stroke="none"
            d="
M1.000002,278.468658 
C1.665301,279.136963 2.823668,280.232544 2.923140,281.417023 
C4.940612,305.440308 10.904083,328.501373 19.935560,350.775787 
C31.411585,379.079254 47.622231,404.335602 68.277985,426.922577 
C96.332054,457.599518 129.914383,479.705231 168.776199,494.095398 
C188.806152,501.512268 209.398926,506.572174 230.704956,507.914032 
C245.791687,508.864258 261.040222,509.875702 276.059723,508.756256 
C316.572266,505.736816 354.492615,493.873627 389.262299,472.472961 
C403.734680,463.565277 417.136475,453.384766 429.749023,441.944366 
C450.537689,423.087677 467.169525,401.168640 480.673889,376.703156 
C495.697998,349.484406 505.785614,320.547607 508.869507,289.615265 
C510.661194,271.644226 511.767059,253.373199 510.645660,235.395340 
C508.763367,205.219070 501.012421,176.251846 487.985809,148.728119 
C476.804657,125.103470 462.501770,103.785248 445.013702,84.358612 
C421.027008,57.713017 392.347778,37.729149 359.902191,23.068634 
C338.014252,13.178589 314.993469,6.857679 291.090607,3.919375 
C287.094696,3.428169 283.152374,2.500634 279.092651,1.387359 
C356.977142,1.000000 434.954315,1.000000 512.965698,1.000000 
C512.965698,171.593811 512.965698,342.187622 512.965698,512.890747 
C342.456238,512.890747 171.912384,512.890747 1.000000,512.890747 
C1.000000,434.979492 1.000000,356.958405 1.000002,278.468658 
z"/>
        <path fill="#000000" opacity="1.000000" stroke="none"
            d="
M232.531342,1.000003 
C232.031952,1.663684 231.110458,2.822552 230.089050,2.918592 
C204.805969,5.295873 180.479950,11.440520 157.146301,21.424257 
C129.903915,33.080418 105.372139,48.796783 83.404778,68.868477 
C59.781677,90.453018 41.233334,115.661491 27.083546,144.102798 
C15.036845,168.316879 7.224019,193.976761 3.918932,220.922226 
C3.429312,224.913971 2.500387,228.851822 1.387354,232.907303 
C1.000000,155.739624 1.000000,78.479240 1.000000,1.000000 
C78.020760,1.000000 155.041718,1.000000 232.531342,1.000003 
z"/>
        <path fill="#FFFFFF" opacity="1.000000" stroke="none"
            d="
M97.000000,313.000000 
C97.000000,248.866333 97.000000,185.232666 97.000000,121.299629 
C126.948761,121.299629 156.680969,121.299629 186.707062,121.299629 
C186.707062,215.954620 186.707062,310.677582 186.707062,405.700409 
C157.051147,405.700409 127.318954,405.700409 97.000000,405.700409 
C97.000000,375.014160 97.000000,344.257080 97.000000,313.000000 
z"/>
        <path fill="#FFFFFF" opacity="1.000000" stroke="none"
            d="
M327.000000,252.000000 
C327.000000,208.196884 327.000000,164.893768 327.000000,121.295517 
C356.623749,121.295517 386.022583,121.295517 415.711182,121.295517 
C415.711182,215.629501 415.711182,310.019043 415.711182,404.704437 
C386.376312,404.704437 356.977478,404.704437 327.000000,404.704437 
C327.000000,354.007721 327.000000,303.253845 327.000000,252.000000 
z"/>
        <path fill="#FFFFFF" opacity="1.000000" stroke="none"
            d="
M215.000000,268.000000 
C215.000000,241.530197 215.000000,215.560394 215.000000,189.295624 
C242.954330,189.295624 270.684143,189.295624 298.707489,189.295624 
C298.707489,234.957184 298.707489,280.682617 298.707489,326.704315 
C271.045776,326.704315 243.315964,326.704315 215.000000,326.704315 
C215.000000,307.348480 215.000000,287.924225 215.000000,268.000000 
z"/>
    </svg>
    let boleta = <div style={{ margin: "auto", width: "min-content", padding: "5px", borderRadius: "10px", display: "grid", gap: "10px", background: "#00000099", paddingTop: "20px", boxShadow: "2px 2px 2px 2px #00000040" }}>
        <div style={{ height: "50px", width: "100%", }}>

            <div style={{ height: "40px", width: "100%", background: "#000000", borderRadius: "10px", display: "grid", gridTemplateColumns: " 50px 75px auto 40px" }}>
                <button style={{ cursor: "pointer", height: "40px", width: "100%", background: "#111", borderRadius: "10px", color: "#fff", fontSize: "12px", display: "grid", gridTemplateColumns: "auto", border: "1px solid #000000" }} className="token" onClick={() => {

                }}>
                    <img src={Network["icon"]} style={{ height: "25px", width: "25px", margin: "auto" }} />

                </button>
                <button style={{ cursor: "pointer", height: "40px", width: "100%", background: "#000000", borderRadius: "10px", color: "#fff", fontSize: "12px", display: "grid", gridTemplateColumns: "30px auto", border: "none" }} className="token" onClick={() => {

                }}>
                    {logo}

                </button>
                <input style={{ background: "#00000000", border: "none", outline: "none", color: "#fff", textAlign: "right" }} type="number" onChange={(e) => { setAmount(e.target.value) }} min={0} value={amount} />
                <button style={{ background: "#00000000", border: "none", outline: "none", color: "#fff", cursor: "pointer" }} onClick={() => {
                    setAmount(netlist[Network["pos"]]['ubalance'])
                }}>MAX</button>
            </div>
            <h3 style={{ color: "#fff", fontSize: "12px", textAlign: "right" }}>{tokenAname} balance: {netlist[Network["pos"]]['ubalance']}</h3>
        </div>
        <div style={{ height: "50px", width: "100%", }}>

            <div style={{ height: "40px", width: "100%", background: "#000000", borderRadius: "10px", display: "grid", gridTemplateColumns: "60px 75px auto 30px" }}>
                <button style={{ cursor: "pointer", height: "40px", width: "100%", background: "#111", borderRadius: "10px", color: "#fff", fontSize: "12px", display: "grid", gridTemplateColumns: "40px 10px", border: "1px solid #000000" }} className="token" onClick={() => {
                    setHint(!hubinteract)
                    setIsA(false)
                }}>
                    <img src={NetworkB["icon"]} style={{ height: "25px", width: "25px", margin: "auto" }} />

                    <span className="fas fa-caret-down" style={{ margin: "auto" }}></span>
                </button>
                <button style={{ cursor: "pointer", height: "40px", width: "100%", background: "#000000", borderRadius: "10px", color: "#fff", fontSize: "12px", display: "grid", gridTemplateColumns: "30px auto 10px", border: "none" }} className="token" onClick={() => {
                    setHint(!hubinteract)
                    setIsA(false)
                }}>
                    {logo}


                </button>

                <input style={{ background: "#00000000", border: "none", outline: "none", color: "#fff", textAlign: "right" }} type="number" onChange={(e) => {/* defineain(e.target.value)*/ }} min={0} value={(parseFloat(amount) - ((parseFloat(amount) * 10) / 100)).toString()} disabled />
            </div>
            <h3 style={{ color: "#fff", fontSize: "12px", textAlign: "right" }}>{tokenBname} balance: {netlist[NetworkB["pos"]]['ubalance']}</h3>
        </div>
        {tradebuttonjsbuy}
        <div className='Trademainboletamodalls' style={{ border: "1px solid #ffffff00", background: "#00000000", display: "grid", gridTemplateColumns: "auto auto auto", gridTemplateRows: "auto", position: "relative", margin: "auto", width: "100%", height: "100%" }}>

            <ul id='list' style={{ border: "1px solid #ffffff00", background: "#00000000", display: "grid", gridTemplateColumns: "auto auto auto", gridTemplateRows: "auto", width: "100%", height: "100%" }}>
                <li style={{ cursor: "normal" }}>
                    <img src={"https://martik.site/logo32x32.png"}></img>
                    <h1 style={{ fontSize: "12px", textAlign: "left" }}>{"Total Supply"}<br />
                        <span style={{ fontSize: "12px", textAlign: "right" }}>{parseFloat(netlist[0].ts).toFixed(2)}</span>
                    </h1>
                </li>
                {netlist.map((car, index) => <li style={{ cursor: "normal" }}>
                    <img src={car.icon}></img>
                    <h1 style={{ fontSize: "12px", textAlign: "left" }}>{"MTK Supply"}<br />
                        <span style={{ fontSize: "12px", textAlign: "right" }}>
                            {index == 0 ? (parseFloat(car.ts) - parseFloat(netlist[1].ts) - parseFloat(netlist[2].ts) - parseFloat(netlist[3].ts)).toFixed(2) : car.ts}
                        </span>
                    </h1>
                </li>)}
            </ul>

        </div>
    </div>

    let botokens = <div className='botokens'>
        {hubinteract && modaltokensls}
        <p style={{ margin: "auto ", fontSize: "12px", width: "280px" }}>
            <span style={{ textAlign: "left", fontSize: "30px" }}>Smart</span>
            <span style={{ textAlign: "left", fontSize: "40px", fontWeight: "bold" }}> BRIDGE</span>
        </p>
        {boleta}
    </div>
    let corpo = <div style={{ display: "grid", gridTemplateColumns: "repeat(auto-fit, minmax(280px, 1fr)" }} >
        <div style={{ height: "min-content" }}>

        </div>
        {botokens}
        <div style={{ height: "min-content", minHeight: "480px" }}>

        </div>
    </div >
    return (
        <div >
            {corpo}
        </div>

    );
}

export default Trade;