import React, { useState, useEffect, useContext } from 'react';
import '../../pages/farm.css';
import Createmodal from "./cstakemodal";
import AppContext from "../../framework/helpers/AppContext";
let stkfacabi = [
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "pool",
                "type": "uint256"
            },
            {
                "internalType": "address",
                "name": "token",
                "type": "address"
            }
        ],
        "name": "_buy",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "priceUNIT",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "amountMTK",
                "type": "uint256"
            },
            {
                "internalType": "address",
                "name": "token",
                "type": "address"
            }
        ],
        "name": "createPool",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "gas",
                "type": "uint256"
            }
        ],
        "name": "getTax",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "pool",
                "type": "uint256"
            },
            {
                "internalType": "address",
                "name": "token",
                "type": "address"
            }
        ],
        "name": "remove",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "tax",
                "type": "uint256"
            }
        ],
        "name": "setTax",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "_tax",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "name": "_tokens",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "inlist",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "owner",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "name": "pools",
        "outputs": [
            {
                "internalType": "contract MartikSale",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "token",
                "type": "address"
            }
        ],
        "name": "poolsview",
        "outputs": [
            {
                "internalType": "contract MartikSale[]",
                "name": "",
                "type": "address[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "tokens",
        "outputs": [
            {
                "internalType": "address[]",
                "name": "",
                "type": "address[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    }
]
//0x582963bEA11c021122934b412dD7ea8aEcb02231
const Stakepools = (props) => {

    const {
        account,
        provider,
        chain,
        chains,
        web3,
        Tokencontract,
        gasTX,
        getID,
        callTX,
        sendTX,
        nSendTX,
        nSendCT
    } = useContext(AppContext)

    const [accounts, setAddress] = useState(account);


    useEffect(() => {
        //document.title = "MARTIK | CREATE P2P"
    }, []);

    let createmodal = <div style={{ width: "100%", margin: "auto", maxWidth: "1920px", display: "grid", padding: "2%" }}>
        <div style={{
            height: "min-content",
            width: "100%",
            maxWidth: "1920px",
            display: "grid",
            gap: "10px",
            borderRadius: "10px",
            marginTop: "auto",
            color: "#fff",

        }}>
            <Createmodal interact={{
                account,
                provider,
                chain,
                chains,
                web3,
                Tokencontract,
                gasTX,
                getID,
                callTX,
                sendTX,
                nSendTX,
                nSendCT
            }} heighti={"40px"} bgcolor={"#1e1f2680"} buttoncolor={"#00000050"} array={[provider, "0x19213da18cA445ea455dB96b5271d61519FdAF5b", stkfacabi, accounts]} />
        </div>

        <p style={{ height: "70px" }}></p>
    </div>

    return (
        <div className="farms" >
            {props.view == 1 && createmodal}
        </div>
    );
}

export default Stakepools;