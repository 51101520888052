import React, { useState, useEffect, useContext } from 'react';
import '../../pages/farm.css';
import Createmodal from "./cstakemodal";
import stkfacabi from "../../abis/nftsfactory.json";
import AppContext from "../../framework/helpers/AppContext";
import ProjectData from '../../config';
let projectName = "Martik"
const {
    Name,
    Logo,
    backgroundColor,
    buttonsColor,
    buttonsBorders,
    inputsColor,
    inputsBorders,
    outbuttonsColor,
    Borders,
    outFont,

    innerFont,
    Routers,
    screens
} = ProjectData[window.location.host.split('.')[0] != undefined 
? window.location.host.split('.')[0] == "app" 
? window.location.host.split('.')[0] + "." + window.location.host.split('.')[1] 
: window.location.host.split('.')[0] : "Martik"]


const StakeCreate = (props) => {

    const {
        account,
        provider,
        chain,
        chains,
        web3,
        Tokencontract,
        gasTX,
        getID,
        callTX,
        sendTX,
        nSendTX,
        nSendCT
    } = useContext(AppContext)

    let pprovider = provider;
    let accounts = account;

    return (
        <div className="farms" >
            <Createmodal interact={{
                account,
                provider,
                chain,
                chains,
                web3,
                Tokencontract,
                gasTX,
                getID,
                callTX,
                sendTX,
                nSendTX,
                nSendCT
            }} heighti={"40px"} bgcolor={"#1e1f2680"} buttoncolor={"#00000050"} array={[pprovider, screens["nftpools"].factory, stkfacabi, accounts]} />
        </div>
    );
}

export default StakeCreate;