import React, { useState, useEffect, Component } from 'react';
import '../../pages/trade.css';
import '../../App.css';
import cakeabi from "../../abis/pankakeRouter.json";
import botabi from "../../abis/botbuyabi.json";
import factoryabi from "../../abis/factoryabi.json";
import miniabi from "../../abis/coin.json";
import bnbabi from "../../abis/bnbabi.json";
import tokenslist from "../../tokens.json";
import { ethers } from 'ethers';
import InputBlock from '../../components/Launchpad/InputBlock';
import ProjectData from '../../config';
import Button from '../../components/Launchpad/Button';
import ModalTokens from '../swap/modalTokens';
let projectName = "Martik"
const {
    Name,
    Logo,
    backgroundColor,
    buttonsColor,
    buttonsBorders,
    inputsColor,
    inputsBorders,
    outbuttonsColor,
    Borders,
    outFont,
    innerFont,
    topFont,
    Routers,
    screens
} = ProjectData[window.location.host.split('.')[0] != undefined 
? window.location.host.split('.')[0] == "app" 
? window.location.host.split('.')[0] + "." + window.location.host.split('.')[1] 
: window.location.host.split('.')[0] : "Martik"]

const Web3 = require('web3-eth');

const Trade = (props) => {
    const {
        account,
        provider,
        chain,
        chains,
        web3,
        Tokencontract,
        gasTX,
        getID,
        callTX,
        sendTX,
        nSendTX,
        nSendCT
    } = props.interact
    const GLOBAL = {
        provider: provider,
        account: account,
        netid: getID()
    };

    const tokens_default = screens.swap.web3.Tokens
    let accounts = [account]
    const [hubinteract, setHint] = useState(false);
    const [settings, setSettings] = useState(false);
    const [active_mode, setactiveMode] = useState(0);
    const [cake, setCake] = useState(undefined);
    const [isa, setIsA] = useState(false);
    const [accountBNB, setBNBbalance] = useState(0);
    const [tokenAname, setTokenAname] = useState(tokens_default[GLOBAL['netid']][0].nome);
    const [tokenBname, setTokenBname] = useState(tokens_default[GLOBAL['netid']][2].nome);
    const [tokenA, setTokenA] = useState(0);
    const [tokenB, setTokenB] = useState(0);
    const [WBNB, setWBNB] = useState(tokens_default[GLOBAL['netid']][0].contract);
    const [tokenACT, setTokenACT] = useState(tokens_default[GLOBAL['netid']][0].contract);
    const [tokenBCT, setTokenBCT] = useState(tokens_default[GLOBAL['netid']][2].contract);
    const [accountBalancemTA, setAccountBalanceTA] = useState(0);
    const [accountBalancemTB, setAccountBalanceTB] = useState(0);
    const [decimalsTA, setCdecimalsTA] = useState(18);
    const [decimalsTB, setCdecimalsTB] = useState(18);
    const [imageurib, seturib] = useState(tokens_default[GLOBAL['netid']][0].icon);
    const [imageuri, seturi] = useState(tokens_default[GLOBAL['netid']][2].icon);
    const [totalSupplyA, setTotalSupplyA] = useState(0);
    const [price, setprice] = useState("");
    const [AllowA, setAllowA] = useState(0);
    const [totalSupplyB, setTotalSupplyB] = useState(0);
    const [splippage, setSlip] = useState(15);

    const [amountinswap, setAmountinSwap] = useState(0);
    const [amountoutswap, setAmountoutSwap] = useState(0);
    const [listsearch, setlistsearch] = useState(null);
    const [ctfind, setctcatch] = useState(null);

    const [hubtx, sethubtx] = useState(false);
    const [hubtxbt, sethubtxbt] = useState(false);

    const [amountin, setAmountin] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const [tname, settokenname] = useState("MTK");
    const [txtconfirm, settc] = useState("");
    const [poofee, setpoofee] = useState(0);
    const [apy, setapy] = useState(0);

    const [withdrawFee, setwithdrawFee] = useState(0);
    const [minstaketime, setminstaketime] = useState(0);
    const [limitperuser, setlimitperuser] = useState(0);
    const [totalLimit, settotalLimit] = useState(0);
    const [minstakeAmount, setminstakeAmount] = useState(0);
    const [Duration, setDuration] = useState(0);

    useEffect(() => {
        let interval = null;
        interval = setInterval(() => {
            setSeconds(seconds => seconds + 1);
            connectToSelectedNetwork();
        }, 2000);
        return () => clearInterval(interval);
    }, [seconds]);


    function getContractAddress() {
        return props.array[1];
    }

    function choicecoin(e, c) {
        if (isa) {
            setTokenAname(e)
            setTokenACT(c);
        } else {
            setTokenBname(e)
            settokenname(e)
            setTokenBCT(c);
        }
        update()
    }

    async function connectToSelectedNetwork() {
        if (accounts[0] !== undefined) {
            const cake = await Tokencontract(props.array[2], getContractAddress());
            const ta = await Tokencontract(bnbabi, tokenACT);
            const tb = await Tokencontract(bnbabi, tokenBCT);
            setTokenA(ta);
            setTokenB(tb);
            call(cake.methods.weivalue, setpoofee);
            call(ta.methods.totalSupply, setTotalSupplyA);
            call(tb.methods.totalSupply, setTotalSupplyB);
            call(ta.methods.allowance, setAllowA, accounts[0], getContractAddress());
            call(ta.methods.balanceOf, setAccountBalanceTA, accounts[0]);
            call(tb.methods.balanceOf, setAccountBalanceTB, accounts[0]);
            call(ta.methods.decimals, setCdecimalsTA);
            call(tb.methods.decimals, setCdecimalsTB);
            ////console.log(tokenACT, decimalsTA, accountBalancemTA)
        }
    }
    function call(func, callback, ...args) {
        func(...args).call()
            .then((result) => {
                callback(result);
            })
            .catch((error) => {
                ////console.log(...args, error)
            })
    }

    function update() {
        setSeconds(0)
        connectToSelectedNetwork()

    }
    function toBig(value) {
        let a = ['']
        let e = value + ""
        if (e.toString().includes(",")) {
            a = e.toString().split(",")
            e = ethers.utils.parseUnits(a[0] + "." + a[1], decimalsTB).toString()
        } else {
            e = ethers.utils.parseUnits(e, decimalsTB).toString()
        }
        return e;
    }
    function toBigD(value, d) {
        let a = ['']
        let e = value + ""
        if (e.toString().includes(",")) {
            a = e.toString().split(",")
            e = ethers.utils.parseUnits(a[0] + "." + a[1], d).toString()
        } else {
            e = ethers.utils.parseUnits(e, d).toString()
        }
        return e;
    }
    function lowfunctionjkljs() {
        nSendTX(getContractAddress(), props.array[2], getID(), 'createPool', poofee, 0,
            tokenBCT,
            tokenACT,
            imageuri,
            imageurib,
            [
                toBigD(amountin, decimalsTA),
                apy,
                withdrawFee,
                minstaketime,
                toBig(limitperuser),
                toBig(totalLimit),
                toBig(minstakeAmount),
                Duration
            ]
        )
            .then(gg => {
                update()
                window.location.href = "/stake"
            })
            .catch(error => {
                //console.log(error)
            });
    }

    function approveta() {
        setconfirmationtext("Wait for confirmation...")
        sethubtx(true)
        sethubtxbt(false)
        nSendTX(tokenA['_address'], bnbabi, getID(), 'approve', '0', 0, getContractAddress(), "1" + "0".repeat(50))
            .then(gg => {
                update()
                setconfirmationtext("Concluded")
            })
            .catch(error => {
                setconfirmationtext(error.message)

            });
    }

    const balancetokenA = () => {
        let value = "";
        if (tokenAname == "BNB") {
            if (accounts[0] != undefined) {
                const web3 = new Web3(provider);
                web3.getBalance(accounts[0])
                    .then(valor => {
                        setBNBbalance(nextblock(valor, decimalsTA))
                    })
                    .catch(error => {
                        //console.log(error)
                    })
                value = accountBNB;
            }
        } else {
            value = nextblock(accountBalancemTA, decimalsTA)
        }
        return value;
    }

    const defineain = (e) => {
        setAmountin(e)
    }

    function nextblock(accountBalancemctTB, d) {
        if (accountBalancemctTB != undefined && accountBalancemctTB != 0) {
            if (accountBalancemctTB.toString().length >= d) {
                return parseFloat(ethers.utils.formatUnits(accountBalancemctTB, d)).toFixed(5)
            } else {
                let cutin = (d - accountBalancemctTB.toString().length) + 2
                return parseFloat(ethers.utils.formatUnits(accountBalancemctTB, d)).toFixed(cutin)
            }
        } else {
            return 0.00
        }
    }

    const formHandler = (e, func) => {
        const file = e.target.files[0];
        function toDataURL(src, callback, outputFormat) {
            let image = new Image();
            image.crossOrigin = 'Anonymous';
            image.onload = function () {
                const [newWidth, newHeight] = calculateSize(this, 48, 48);
                let canvas = document.createElement('canvas');
                let ctx = canvas.getContext('2d');
                let dataURL;
                canvas.width = newWidth;
                canvas.height = newHeight;
                //ctx.drawImage(this, 0, 0);
                ctx.drawImage(this, 0, 0, newWidth, newHeight);
                dataURL = canvas.toDataURL(outputFormat);
                callback(dataURL);
            };
            image.src = src;
            if (image.complete || image.complete === undefined) {
                image.src = "data:image/gif;base64, R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
                image.src = src;
            }
            function calculateSize(img, maxWidth, maxHeight) {
                let width = img.width;
                let height = img.height;

                // calculate the width and height, constraining the proportions
                if (width > height) {
                    if (width > maxWidth) {
                        height = Math.round((height * maxWidth) / width);
                        width = maxWidth;
                    }
                } else {
                    if (height > maxHeight) {
                        width = Math.round((width * maxHeight) / height);
                        height = maxHeight;
                    }
                }
                return [width, height];
            }
        }
        toDataURL(URL.createObjectURL(e.target.files[0]),
            function (dataUrl) {
                func(dataUrl)
            }
        )

    }

    const search = async (e) => {
        if (e.length <= 0) {
            setlistsearch(null)
        } else {
            if (e.length == 42) {
                if (e.includes("0x")) {
                    const tk = await Tokencontract(miniabi, e);
                    const symbol = await tk.methods.symbol().call()
                    const name = await tk.methods.name().call()

                    setlistsearch({
                        name: name,
                        symbol: symbol
                    })
                    setctcatch(e)
                } else {
                }
            } else {
                setlistsearch(null)
            }
        }
    }

    const setiTA = () => { };
    const setiTB = () => { };
    let modaltokensls = <ModalTokens data={{
        choicecoin,
        setHint,
        setAmountinSwap,
        setAmountoutSwap,
        setiTA,
        isa,
        iTA: imageuri,
        iTB: imageurib,
        setiTB,
        listsearch,
        ctfind,
        tokens_default: undefined,
        GLOBAL,
        hubinteract,
        search
    }} />
    function setconfirmationtext(e) {
        settc(e)
    }

    const SAFE = (props) => {
        return (
            <p style={{ width: "95%", display: "grid", gridTemplateColumns: "auto auto", margin: "auto", marginTop: "5px" }}><span style={{
                textAlign: "left",
                width: "100%",
                fontWeight: "bold",

                fontSize: "11px",

            }}>{props.what} </span><span style={{
                textAlign: "right",
                width: "100%",
                fontSize: "11px",

            }}>
                    {props.result}
                </span></p>
        );
    }

    if (apy > 0) {
        if (apy <= 1000) { } else {
            if (apy != 1000) {
                setapy(1000)
            }
        }
    } else {
        if (apy != 0) {
            setapy(0)
        }
    }

    let boleta = <div className="Trademainboletam" style={{ minWidth: "280px", background: topFont == undefined ? buttonsColor : topFont, border: "1px solid " + Borders , borderRadius: "10px", gap: "20px", color: innerFont }}>
        <div style={{ height: props.heighti, display: "grid" }}>
            <p style={{ margin: "auto", fontSize: "18px", marginTop: "10px" }}>CREATE POOL</p>
        </div>
        <div style={{ display: "grid", gridTemplateColumns: "auto auto", height: "100px", width: "95% ", margin: "auto" }}>
            <span>
                <h3 style={{ fontSize: "12px" }}>STAKE</h3>
                <div style={{ display: "grid", gridTemplateColumns: "auto ", height: props.heighti }}>
                    <div style={{ display: "grid", gridTemplateColumns: "50px 0px auto", border: buttonsBorders, height: props.heighti }}>
                        <label for="imagehub" style={{ cursor: "pointer", height: props.heighti, width: "35px", margin: "auto", borderRadius: "360px", background: "url(" + imageuri + ") center center / 80% no-repeat" }}>
                        </label>
                        <input onChange={(e) => { formHandler(e, seturi) }} accept="image/*" multiple="false" type="file" id={"imagehub"} className='input' style={{ height: "0", width: "0", margin: "auto", zIndex: "-1" }} />
                        <button className="token" style={{ background: "#11111100", display: "grid", gridTemplateColumns: "auto 25px", color: innerFont, borderRadius: "10px", fontSize: "12px" }} onClick={() => {
                            setHint(!hubinteract)
                            setIsA(false)
                        }} >{tokenBname}<span className="fas fa-caret-down"></span> </button>
                    </div>
                </div>
            </span>
            <span>
                <h3 style={{ fontSize: "12px" }}> TO EARN</h3>
                <div style={{ display: "grid", gridTemplateColumns: "auto ", border: buttonsBorders, height: props.heighti }}>

                    <div style={{ display: "grid", gridTemplateColumns: "50px 0px auto", height: props.heighti }}>
                        <label for="imagehubb" style={{ cursor: "pointer", height: props.heighti, width: "35px", margin: "auto", borderRadius: "360px", background: "url(" + imageurib + ") center center / 80% no-repeat" }}>
                        </label>
                        <input onChange={(e) => { formHandler(e, seturib) }} accept="image/*" multiple="false" type="file" id={"imagehubb"} className='input' style={{ height: "0", width: "0", margin: "auto", zIndex: "-1" }} />
                        <button className="token" style={{ background: "#11111100", display: "grid", gridTemplateColumns: "auto 25px", color: innerFont, borderRadius: "10px", fontSize: "12px" }} onClick={() => {
                            setHint(!hubinteract)
                            setIsA(true)
                        }}>{tokenAname}<span className="fas fa-caret-down"></span> </button>
                    </div>
                </div>
            </span>
        </div>

        <div style={{ display: "grid", gridTemplateColumns: "50% 50%", height: "100px", width: "95% ", margin: "auto", gap: "5px" }}>
            <InputBlock
                title={"APR"}
                type="number"
                place={"0"}
                variavel={apy}
                setvariavel={setapy}
                desc={"0% = Flexible"}
            />
            <InputBlock
                title={'$' + tokenAname + ' TO REWARD'}
                type="number"
                place={"0"}
                variavel={amountin}
                setvariavel={defineain}
                desc={'Balance:' + balancetokenA()}
            />
        </div>
        <div style={{ display: "grid", gridTemplateColumns: "50% 50%", height: "75px", width: "95% ", margin: "auto", gap: "5px" }}>
            <InputBlock
                title={"Min Stake Time"}
                type="number"
                place={"0"}
                variavel={minstaketime}
                setvariavel={setminstaketime}
                desc={"In hours"}
            />
            <InputBlock
                title={"WithdrawFee"}
                type="number"
                place={"0"}
                variavel={withdrawFee}
                setvariavel={setwithdrawFee}
                desc={"in %"}
            />
        </div>

        <div style={{ display: "grid", gridTemplateColumns: "50% 50%", height: "75px", width: "95% ", margin: "auto", gap: "5px" }}>
            <InputBlock
                title={"Limit Staked Per User"}
                type="number"
                place={"0"}
                variavel={limitperuser}
                setvariavel={setlimitperuser}
                desc={"0 = Ilimited"}
            />
            <InputBlock
                title={"Total Stake Limit"}
                type="number"
                place={"0"}
                variavel={totalLimit}
                setvariavel={settotalLimit}
                desc={"0 = Ilimited"}
            />
        </div>
        <div style={{ display: "grid", gridTemplateColumns: "50% 50%", height: "75px", width: "95% ", margin: "auto", gap: "5px" }}>
            <InputBlock
                title={"Min Stake Amount"}
                type="number"
                place={"0"}
                variavel={minstakeAmount}
                setvariavel={setminstakeAmount}
                desc={tokenBname}
            />
            <InputBlock
                title={"Duration (Days)"}
                type="number"
                place={"0"}
                variavel={Duration}
                setvariavel={setDuration}
                desc={"How many days to finish"}
            />
        </div>
        <Button
            func={() => {
                { AllowA > 0 ? lowfunctionjkljs() : approveta() }
            }}
            text={AllowA > 0 ? "Create Pool" : "Approve " + tokenAname}
        />
        <div className='details' style={{ border: "1px solid #ffffff20", background: "#11111100", color: outFont, fontSize: "12px", marginBottom: "10px", height: "min-content" }}>
            <SAFE what={"POOL DETAILS:"} result={""} />
            <br />
            <SAFE what={"APR: "} result={apy == 0 ? "Flexible" : apy + " %"} />
            <SAFE what={"A T R: "} result={nextblock(amountinswap, decimalsTA) + " " + tokenAname} />
            <SAFE what={"Min Stake Time: "} result={minstaketime} />
            <SAFE what={"Withdraw Fee: "} result={withdrawFee + "%"} />
            <SAFE what={"Limit per user: "} result={limitperuser == 0 ? "Ilimited" : limitperuser + " " + tokenBname} />
            <SAFE what={"Pool total limit: "} result={totalLimit == 0 ? "Ilimited" : totalLimit + " " + tokenBname} />
            <SAFE what={"CREATE FEE: "} result={nextblock(poofee, 18) + " BNB"} />
        </div>
    </div>
    let botokens = <div className='botokens'>
        {hubinteract && modaltokensls}
        {boleta}
    </div>

    let corpo = <div style={{ width: "100%", color: outFont }} >
        <div style={{ margin: "auto", width: "100%" }}>
            {botokens}
            <p style={{ margin: "auto", fontSize: "12px" }}>Exclude from fees to safe stake creator </p>
            <p style={{ margin: "auto", fontSize: "12px" }}>{screens["stakepools"].factory}</p>
        </div>
    </div >

    return (
        <div style={{ width: "100%", maxWidth: "1024px", margin: "auto" }}>
            {corpo}
        </div>
    );
}

export default Trade;