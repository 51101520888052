import React from 'react';


function Checkbox(props) {
    return (
        <div style={{ display: "flex", gap: "10px" }}>
            <label class="container">{props.title}
                <input onChange={(e) => { props.setvariavel(props.id) }} type="checkbox" checked={props.title.includes('Affiliated') ? props.id == 0 ? props.variavel == props.id : props.variavel >= props.id : props.variavel == props.id} />
                <span class="checkmark"></span>
            </label>
        </div>
    );
}

export default Checkbox;