import '@rainbow-me/rainbowkit/styles.css';
import {
  getDefaultWallets,
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit';
import { ethers } from 'ethers';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';

import { bsc, bscTestnet } from 'wagmi/chains';
import { publicProvider } from 'wagmi/providers/public';
import { connectorsForWallets } from '@rainbow-me/rainbowkit';
import {
  injectedWallet,
  rainbowWallet,
  walletConnectWallet,
  braveWallet,
  coinbaseWallet,
  metaMaskWallet,
  trustWallet
} from '@rainbow-me/rainbowkit/wallets';
import Interact from '../web3/interact';



export function Connection({ content }) {
  const martik = {
    id: 1999,
    name: 'Martik Smart Chain',
    network: 'MSC',
    iconUrl: 'https://martik.site/logo32x32.png',
    iconBackground: '#000',
    nativeCurrency: {
      decimals: 18,
      name: 'Martik',
      symbol: 'MTK',
    },
    rpcUrls: {
      public: { http: ['https://rpc.martik.site/'] },
      default: { http: ['https://rpc.martik.site/'] },
    },
    blockExplorers: {
      default: { name: 'Martik Explorer', url: 'https://martik.site/explorer' },
      etherscan: { name: 'Martik Explorer', url: 'https://martik.site/explorer' },
    },
    contracts: {
      multicall3: {
        address: '0x97C86F37E9c3b0bF7F65968E82D7b96f00aFbab5',
        blockCreated: 308_231,
      },
    },
    testnet: false,
  };
  const { chains, publicClient } = configureChains(
    [bsc, bscTestnet,martik],
    [
      publicProvider()
    ]
  );

  const connectors = connectorsForWallets([
    {
      groupName: 'Recommended',
      wallets: [
        trustWallet({
          projectId: '3f529f8b097214c03d7190df245218c9',
          chains
        }),
        metaMaskWallet({
          projectId: '3f529f8b097214c03d7190df245218c9',
          chains
        }),
        injectedWallet({ chains }),
        rainbowWallet({ projectId: '3f529f8b097214c03d7190df245218c9', chains }),
        walletConnectWallet({ projectId: '3f529f8b097214c03d7190df245218c9', chains }),
        braveWallet({
          chains
        }),
        coinbaseWallet({
          appName: "MARTIK",
          chains
        })
      ],
    },
  ]);
  /*const { connectors } = getDefaultWallets({
    appName: 'Martik',
    projectId: '3f529f8b097214c03d7190df245218c9',
    chains
  });
*/
  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors,
    publicClient
  })


  return (
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider chains={chains}>
        <Interact content={content} />
      </RainbowKitProvider>
    </WagmiConfig>
  );
};
export default Connection;