import React from 'react';


function Stepbar(props) {
    let sty = 'w-6 h-6 border border-[#08adb3] rounded-full flex items-center justify-center text-primary dark:text-white'
    return (
        <div className='grid grid-cols-4 items-center justify-center h-40 '>
            <div className='flex flex-col items-center justify-center space-x-2  w-full'><div className={props.progress == "0%" ? 'bg-[#08adb3] ' + sty : sty}>1</div>
                <div className='flex flex-col items-center justify-center subititle-xs'>Verificar token <div className='title-sub'>Digite o endereço do token e verifique</div></div>
            </div>
            <div className='flex flex-col items-center justify-center space-x-2 w-full'><div className={props.progress == "33%" ? 'bg-[#08adb3] ' + sty : sty}>2</div>
                <div className='flex flex-col w-full text-center subititle-xs'>Informações DeFi <div className='title-sub'>Insira as informações da barra de lançamento que você deseja </div></div>
            </div>
            <div className='flex flex-col  items-center justify-center space-x-2  w-full'><div className={props.progress == "66%" ? 'bg-[#08adb3] ' + sty : sty}>3</div>
                <div className='flex flex-col text-center subititle-xs'>Adicionar informações <div className='title-sub'>Deixe as pessoas saberem sobre o token</div></div>
            </div>
            <div className='flex flex-col  items-center justify-center space-x-2 w-full'><div className={props.progress == "99%" ? 'bg-[#08adb3] ' + sty : sty}>4</div>
                <div className='flex flex-col text-center subititle-xs'>Terminar<div className='title-sub'>Revise as informações</div></div>
            </div>

        </div>
    );
}

export default Stepbar;