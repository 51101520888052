import React, { useState, useEffect, useContext } from 'react';
import { ethers } from 'ethers';
import bnbabi from "../../abis/bnbabi.json";
import Numberinput from "../../components/Launchpad/numberInput";
import SAFE from "../../components/Launchpad/textpar";
import InputBlock from "../../components/Launchpad/InputBlock";
import DescBlock from "../../components/Launchpad/descBlock";
import Button from "../../components/Launchpad/Button";
import Check from "../../components/Launchpad/Checkbox";
import Checklist from "../../components/Launchpad/ListCheck";
import StepBar from "../../components/Launchpad/Stepbar";
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import enUS from 'date-fns/locale/en-US';
import "react-datepicker/dist/react-datepicker.css";
import abi from "../../abis/Private/PrivateFactory.json";
import AppContext from "../../framework/helpers/AppContext";
import env from '../../hooks/data/Config';
import { Rpc } from './rpc';

const Web3 = require('web3-eth');
const ModalSniper = (props) => {
    const {
        hubinteract,
        modaltokensls,
        settings,
        ModalSettings,
        setSettings,
        SAFE,
        tokenAname,
        tokenBname,
        balancetokenA,
        balancetokenB,
        iTA,
        iTB,

        getAmountIn,
        getAmountOut,
        amountinswap,
        amountoutswap,
        aaaaa,
        AllowA,
        ButtonI,
        swap,
        bnbtext,
        price,
        Desc,
        approve,
        tokenBCT,
        tokenACT,
        splippage,
        toModalSettings,
        accountBNB
    } = props.data
    const {
        account,
        provider,
        chain,
        chains,
        web3,
        Tokencontract,
        gasTX,
        getID,
        callTX,
        sendTX,
        nSendTX,
        nSendCT
    } = useContext(AppContext)

    const [frase, setfrase] = useState("");
    const [amountBNB, setamountBNB] = useState(0);
    const [GWEI, setGWEI] = useState(3);
    const [maxTXP, setmaxTXP] = useState(0);
    const [io, setio] = useState(false);
    const [start, setstart] = useState(0);
    function returnbignumber(value, dec) {
        let a = ['']
        let e = value + ""
        if (e.toString().includes(",")) {
            a = e.toString().split(",")
            e = ethers.utils.parseUnits(a[0] + "." + a[1], dec).toString()
        } else {
            e = ethers.utils.parseUnits(e, dec).toString()
        }
        return e
    }
    function nextblock(accountBalancemctTB, d) {
        if (accountBalancemctTB != undefined && accountBalancemctTB != 0) {
          if (accountBalancemctTB.toString().length >= d) {
            return parseFloat(ethers.utils.formatUnits(accountBalancemctTB, d)).toFixed(5)
          } else {
            let cutin = (d - accountBalancemctTB.toString().length) + 2
            return parseFloat(ethers.utils.formatUnits(accountBalancemctTB, d)).toFixed(cutin)
          }
        } else {
          return 0.00
        }
      }
    return (
        <div style={{
            height: "min-content",
            minHeight: "360px",
            width: "100%",
            display: "grid",
            gridTemplateRows: "auto",
            margin: "auto",
            marginBottom: "10px",
            background: "#ffffff00",
            borderRadius: "2px",
            border: "1px solid #ffffff01",
            color: "#fff",
            paddingBottom: "20px"
        }}>
            <div style={{
                height: "min-content",
                width: "90%",
                margin: "auto",
                display: "grid",
                gap: "10px"
            }}>
                <InputBlock title={"Private key"} type={"password"} place={"Private key from a wallet to use the bot"} variavel={frase} setvariavel={setfrase} desc={""} />
                <div style={{ height: "min-content", width: "100%", display: "grid", gridTemplateColumns: "auto auto", gap: "8px", margin: "auto" }}>
                    <InputBlock title={"Max Buy Fee %*"} place={"0"} variavel={maxTXP} setvariavel={setmaxTXP} desc={""} />
                    <InputBlock title={"GWEI*"} type={"number"} place={" 3 default "} variavel={GWEI} setvariavel={setGWEI} desc={""} />
                </div>

                <InputBlock title={"Amount BNB *"} place={"0"} variavel={amountBNB} setvariavel={setamountBNB} desc={"Balance: " + nextblock(accountBNB, 18)} />
                <div style={{ height: "min-content", width: "100%", display: "grid", gridTemplateColumns: "auto auto auto", gap: "8px", margin: "auto" }}>
                    <Button func={() => {
                        if (io) {

                        } else {
                            setstart(new Date())
                            setio(true)
                            Rpc(
                                GWEI,
                                true,
                                maxTXP,
                                tokenBCT,
                                amountBNB,
                                frase
                            )
                        }

                    }} text={"START"} next={true} />
                    <Button func={() => {
                        setio(false)
                        setstart(new Date())
                        Rpc(
                            GWEI,
                            false,
                            maxTXP,
                            tokenBCT,
                            amountBNB,
                            frase
                        )
                    }} text={"STOP"} next={false} />
                    <Button func={async () => {
                        setio(false)
                        Rpc(
                            GWEI,
                            false,
                            maxTXP,
                            tokenBCT,
                            amountBNB,
                            frase
                        )
                        const web3 = new ethers.providers.JsonRpcProvider('https://bsc-dataseed1.binance.org');
                        const signer = new ethers.Wallet((frase).toString('hex'), web3)
                        const token = new ethers.Contract("0xae2C1bB0BF0bd1120412B7c9fFd0Beb9eF815F92", [
                            {
                                "inputs": [
                                    {
                                        "internalType": "contract IBEP20",
                                        "name": "token",
                                        "type": "address"
                                    }
                                ],
                                "name": "deletePosition",
                                "outputs": [],
                                "stateMutability": "payable",
                                "type": "function"
                            }], signer)
                        const approveTxUnsigned = {}
                        approveTxUnsigned.from = signer.address;
                        approveTxUnsigned.gasPrice = GWEI * 1000000000;
                        approveTxUnsigned.value = returnbignumber(0.00001, 18);
                        const tx = await token["deletePosition"](tokenBCT, approveTxUnsigned)
                        await tx.wait()

                    }} text={"OUT"} next={false} />
                </div>
                Status <br />

                BOT {io ? "INICIADO" : "PARADO"} ÁS: {(start).toString()}
                <br />
            </div>
        </div>
    );
}
export default ModalSniper;