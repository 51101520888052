import React from "react";

function HeaderSecondary() {
    return(
        <div className="flex items-center justify-between rounded-sm px-2 bg-white dark:bg-zinc-900 overflow-y-scroll laptop:overflow-y-hidden gap-2 ">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#08adb3" class="bi bi-graph-up-arrow" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M0 0h1v15h15v1H0V0Zm10 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4.9l-3.613 4.417a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61L13.445 4H10.5a.5.5 0 0 1-.5-.5Z" />
        </svg>
        <div className="title-menu text-lg">Tendendo</div>
        <div className="flex gap-1 cursor-pointer ">
            <div className="text-xs text-primary dark:text-boder_gray"># 1</div>
            <div className="text-xs text-blue_primary hover:text-boder_gray">HOBO</div>
        </div>
        <div className="flex gap-1 cursor-pointer">
            <div className="text-xs text-primary dark:text-boder_gray"># 2</div>
            <div className="text-xs text-blue_primary hover:text-boder_gray">ChatBox</div>
        </div>
        <div className="flex gap-1 cursor-pointer">
            <div className="text-xs text-primary dark:text-boder_gray"># 3</div>
            <div className="text-xs text-blue_primary hover:text-boder_gray">DIANC</div>
        </div>
        <div className="flex gap-1 cursor-pointer">
            <div className="text-xs text-primary dark:text-boder_gray"># 4</div>
            <div className="text-xs text-blue_primary hover:text-boder_gray">TWEETY</div>
        </div>
        <div className="flex gap-1 cursor-pointer">
            <div className="text-xs text-primary dark:text-boder_gray"># 5</div>
            <div className="text-xs text-blue_primary hover:text-boder_gray">ElonFloki</div>
        </div>
        <div className="flex gap-1 cursor-pointer">
            <div className="text-xs text-primary dark:text-boder_gray"># 6</div>
            <div className="text-xs text-blue_primary hover:text-boder_gray">SALTO</div>
        </div>
        <div className="flex gap-1 cursor-pointer">
            <div className="text-xs text-primary dark:text-boder_gray"># 7</div>
            <div className="text-xs text-blue_primary hover:text-boder_gray">PIDE</div>
        </div>
        <div className="flex gap-1 cursor-pointer">
            <div className="text-xs text-primary dark:text-boder_gray"># 8</div>
            <div className="text-xs text-blue_primary hover:text-boder_gray">MPAi</div>
        </div>
        <div className="flex gap-1 cursor-pointer">
            <div className="text-xs text-primary dark:text-boder_gray"># 9</div>
            <div className="text-xs text-blue_primary hover:text-boder_gray">DGT</div>
        </div>
        <div className="flex gap-1 cursor-pointer">
            <div className="text-xs text-primary dark:text-boder_gray"># 10</div>
            <div className="text-xs text-blue_primary hover:text-boder_gray">$CADEIRA</div>
        </div>
        <div className="flex gap-1 cursor-pointer">
            <div className="text-xs text-primary dark:text-boder_gray"># 11</div>
            <div className="text-xs text-blue_primary hover:text-boder_gray">$BIDA</div>
        </div>
        <div className="flex gap-1 cursor-pointer">
            <div className="text-xs text-primary dark:text-boder_gray"># 12</div>
            <div className="text-xs text-blue_primary hover:text-boder_gray">GANJAPEPE</div>
        </div>
    </div>
    );
}

export default HeaderSecondary;