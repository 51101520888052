import React, { useState, useEffect } from 'react';
import Numberinput from "../../../components/Launchpad/numberInput";
import SAFE from "../../../components/Launchpad/textpar";
import Buttons from "../../../components/Launchpad/Button";

const Modal = (props) => {
  const [symbola, setsymbolA] = useState("");
  const [_ct, setCt] = useState("");
  const [deca, setdecA] = useState("");
  const [owner, setowner] = useState("");
  const [time, settime] = useState(0);
  const [amount, setamount] = useState(0);

  useEffect(() => {
    getdata()
  }, []);

async function getdata() {
    const ct = await props.Tokencontract(props.ctAbi, props.ct);
    const o = await ct.methods.ContractLock(props.Contract, props.posi).call()
    const data = await ct.methods.Locks(o, props.Contract).call()

    setCt(ct)
    gettokendataA(props.Contract)
    setowner(o)
    setamount(data.amount)
    settime(data.endtime)
  }
  
  async function gettokendataA(e) {
    const token = await props.Tokencontract(props.tkAbi, e);
    const a = await token.methods.symbol().call()
    const t = await token.methods.decimals().call()
    setsymbolA(a)
    setdecA(t)
  }

  function nextblock(accountBalancemctTB, d) {
    if ((accountBalancemctTB / (10 ** d)).toString().includes('.')) {
      if (accountBalancemctTB.toString().length >= d) {
        return (
          (accountBalancemctTB / (10 ** d)).toString().split('.')[0] +
          '.' +
          (accountBalancemctTB / (10 ** d)).toString().split('.')[1].slice(0, 2)
        );
      } else {
        return (
          '0.' +
          '0'.repeat(d - accountBalancemctTB.toString().length) +
          accountBalancemctTB.toString().slice(0, 2)
        );
      }
    } else {
      return accountBalancemctTB / (10 ** d);
    }
  }

  function getTimeRemaining(endtime) {
    const total = Date.parse(endtime) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    const days = Math.floor(total / (1000 * 60 * 60 * 24));
    if (total < 0) {

      return "EXPIRED";
    } else {
      return days + "D " + hours + "H "
        + minutes + "M " + seconds + "S"
    }

  }

  return (
    <div style={{
      height: "360px",
      width: "100%",
      listStyleType: "none",
      display: "grid",
      padding: "10px 5px",
      margin: "auto",
      border: "1px solid #ffffff20",
      borderRadius: "5px",
      boxShadow: "1px 5px 5px 5px #16203120",
      color: "#fff",
      fontSize: "15px",
    }}>
      <div style={{
        height: "280px",
        width: "100%",
        display: "grid",
        gap: "5px",
        margin: "auto"
      }}>
        <SAFE what={"Contract Name:"} result={symbola} />
        <SAFE what={"Owner"} result={owner.slice(0, 4) + '...' + owner.slice(-4)} />
        <SAFE what={"Locked Amount"} result={nextblock(amount, deca)} />
        <SAFE what={"Finish in"} result={getTimeRemaining(new Date(time * 1000))} />

        {props.account == owner && <div style={{ display: "grid", gridTemplateColumns: "auto auto auto auto", gap: "7px", backgroundColor: "#090e1600 ", border: "1px solid #090e1650", height: props.heighti, width: "100%", margin: "auto" }}>

          <Buttons func={() => { window.location.href = "/lock/update/" + props.Contract + "?=" + props.posi }} text={"UPDATE LOCK"} />
          <Buttons func={() => { window.location.href = "/lock/transfer/" + props.Contract + "?=" + props.posi }} text={"TRANSFER LOCK"} />
          <Buttons func={() => {
            props.nSendTX(props.ct, props.ctAbi, props.netId, 'renounceLock', '0', 0,
              props.Contract
            ).then(a => {
              //a.hash
              //console.log(a)
            }).catch(e => {
              //console.log(e)
            })
            //props.nsendTX(_ct.methods.renounceLock, 0, props.Contract)
          }} text={"RENOUNCE LOCK"} />
          <Buttons func={() => {
            props.nSendTX(props.ct, props.ctAbi, props.netId, 'unLock', '0', 0,
              props.Contract
            ).then(a => {
              //a.hash
              //console.log(a)
            }).catch(e => {
              //console.log(e)
            })
            //  props.nsendTX(_ct.methods.unLock, 0, props.Contract)
          }} text={"UNLOCK"} />

        </div>
        }

      </div>
    </div >
  );
}
export default Modal;