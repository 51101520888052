import React, { useState, useEffect, useContext } from 'react';
import { Contract, ethers } from 'ethers';
import 'balloon-css';
import { useNavigate } from "react-router-dom";
import { toChecksumAddress } from 'ethereum-checksum-address';
import mtkswap from '../../abis/martikswap.json'
import Chart from './chart';
import TokenNomics from '../../components/Launchpad/TokenNomics';
import AppContext from '../../framework/helpers/AppContext';
import env from '../../hooks/data/Config';
import TokenInfo from './tokenInfo';
import Button from '../../components/Launchpad/Button';
import InputBlock from '../../components/Launchpad/InputBlock';

import ProjectData from '../../config';

let projectName = "Martik"
const {
  Name,
  Logo,
  backgroundColor,
  buttonsColor,
  buttonsBorders,
  inputsColor,
  inputsBorders,
  outbuttonsColor,
  Borders,
  outFont,
  innerFont,
  innerBFont,
  menudata,
  Routers,
  logoUrl,
  info,
  screens
} = ProjectData[window.location.host.split('.')[0] != undefined 
? window.location.host.split('.')[0] == "app" 
? window.location.host.split('.')[0] + "." + window.location.host.split('.')[1] 
: window.location.host.split('.')[0] : "Martik"]


function ModalSwap(data) {
  const {
    hubinteract,
    modaltokensls,
    settings,
    ModalSettings,
    setSettings,
    SAFE,
    tokenAname,
    tokenBname,
    balancetokenA,
    balancetokenB,
    iTA,
    iTB,
    props,
    getAmountIn,
    getAmountOut,
    amountinswap,
    amountoutswap,
    aaaaa,
    AllowA,
    ButtonI,
    swap,
    bnbtext,
    price,
    Desc,
    approve,
    tokenBCT,
    tokenACT,
    splippage,
    toModalSettings,
  } = data.data
  return (<div style={{
    height: "min-content",
    maxWidth: "480px",
    minWidth: "260px",
    listStyleType: "none",
    display: "grid",
    margin: "auto",
    borderRadius: "10px",

    color: "#ddd",
    fontSize: "12px",
    paddingTop: "10px"
  }}>

    {hubinteract && modaltokensls}
    {settings && <ModalSettings data={{
      hubinteract,
      modaltokensls,
      settings,
      ModalSettings,
      setSettings,
      SAFE,
      tokenAname,
      tokenBname,
      balancetokenA,
      balancetokenB,
      iTA,
      iTB,
      props,
      getAmountIn,
      getAmountOut,
      amountinswap,
      amountoutswap,
      aaaaa,
      AllowA,
      ButtonI,
      swap,
      bnbtext,
      price,
      Desc,
      approve,
      tokenBCT,
      tokenACT,
      splippage,
      toModalSettings,
    }} />}
    <div style={{ display: "grid", gridTemplateColumns: "auto 50px", width: "100%", height: "70px", marginBottom: "10px", padding: "auto", color:outFont }}>

      <div style={{
        height: "min-content",
        width: "100%",
        margin: "auto"
      }}>
        <div style={{
          height: "30px",
          width: "100%",
          display: "inline-flex"
        }}>
          <p style={{ textAlign: "left", height: "min-content", fontWeight: "bold", fontSize: "17px", margin: "auto 0" }}>{"Swap"}</p>
        </div>
        <p style={{ textAlign: "left", height: "min-content", fontSize: "12px", marginBottom: "2px" }}>Buy tokens in an instant</p>

      </div>
      <svg onClick={() => { setSettings(!settings) }} style={{ cursor: "pointer", margin: "auto" }} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-gear" viewBox="0 0 16 16">
        <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z" />
        <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z" />
      </svg>
    </div>

    <div style={{ display: "grid", gridTemplateColumns: "auto ", height: "100%", width: "100%", margin: "auto", borderRadius: "10px", paddingBottom: "20px", zIndex: "1", }}>
      <SAFE what={tokenAname} result={<p>balance: {balancetokenA()}</p>} />
      <div style={{ display: "grid", gridTemplateColumns: "auto 50px", gap: "5px", height: "100%", width: "100%", margin: "auto" }}>
        <div style={{
          height: "40px",
          width: "100%",
          margin: "auto",
          display: "grid",
          gridTemplateColumns: "35px auto",
       
          borderRadius: "10px"
        }}>
          <i style={{
            height: "100%",
            width: "100%",
            margin: "auto",
            background: "url('" + iTA + " ')  center center / 50% no-repeat",
          }}>
          </i>
          <InputBlock type={"number"}  id={props.id} title={""} place={"0.0"} variavel={amountinswap} setvariavel={getAmountOut} desc={""} />
         
        </div>
        <p onClick={() => {
          getAmountOut(balancetokenA())
        }} style={{
          height: "40px",

          borderRadius: "10px",
          margin: "auto",
          width: "100%",
          display: "grid",
          cursor: "pointer"
        }}><p style={{ margin: "auto", fontWeight: "bold" , color:outFont}}>MAX</p></p>
      </div>
      <svg style={{ margin: "auto", borderRadius: "90px", background: "#11111150", padding: "1vh", marginTop: "10px", cursor: "pointer" }} onClick={() =>
        aaaaa(tokenBname, tokenAname, tokenBCT, tokenACT, iTB, iTA)
      } xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-arrow-down" viewBox="0 0 16 16">
        <path fillRule={"evenodd"} d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z" />
      </svg>
      <SAFE what={tokenBname} result={<p>balance: {balancetokenB()}</p>} />

      <div style={{ display: "grid", gridTemplateColumns: "auto ", gap: "5px", height: "100%", width: "100%", margin: "auto" }}>
        <div style={{
          height: "40px",
          width: "100%",
          margin: "auto",
          display: "grid",
          gridTemplateColumns: "35px auto",

          borderRadius: "10px"
        }}>
          <i style={{
            height: "100%",
            width: "100%",
            margin: "auto",
            background: "url('" + iTB + " ')  center center / 50% no-repeat",
          }}>
          </i>
          <InputBlock  type={"number"} title={""} place={"0.0"} variavel={amountoutswap} setvariavel={getAmountIn} desc={""} />
         
        </div>
      </div>
      <div className="flex flex-row items-center gap-3 w-full" style={{ marginTop: "10px" }}>
        {<div style={{ display: "grid", gridTemplateColumns: AllowA <= 0 ? "auto auto" : "auto", gap: "5px", height: "100%", width: "100%", margin: "auto" }}>
          {AllowA <= 0 && <Button
            func={() => approve()}
            text={'APPROVE ' + tokenAname}
          />}
          <Button
            func={() => swap()}
            text={bnbtext()} />
        </div>}
      </div>

      <Desc what={"Price"} desc={""} result={price} />
      <br />
      <Desc what={"Slippage Tolerance"} desc={"Setting a high slippage tolerance can help transactions succeed, but you may not get such a good price. Use with caution."} result={splippage + "%"} />
      <Desc what={"Minimum received"} desc={"Your transaction will revert if there is a large, unfavorable price movement before it is confirmed."} result={(amountoutswap / ((parseFloat(splippage) / 100) + 1)) + ""} />
    </div>
  </div >)
}
export default ModalSwap;