import React, { useState, useEffect, useContext } from 'react';
//import List from "./contracts.json";
import { ethers } from 'ethers';
import Editor from '@monaco-editor/react'
import AppContext from "../../../framework/helpers/AppContext";
import CheckList from '../../../components/Launchpad/ListCheck';
import { Tokens } from './tokens.js'
import InputBlock from '../../../components/Launchpad/InputBlock';
import { useNavigate } from 'react-router-dom';
import ProjectData from '../../../config';
import Button from '../../../components/Launchpad/Button';

let projectName = "Martik"
const {
    Name,
    Logo,
    backgroundColor,
    buttonsColor,
    buttonsBorders,
    inputsColor,
    modalsColor,
    inputsBorders,
    outbuttonsColor,
    Borders,
    outFont,
    innerFont,
    Routers
} = ProjectData[window.location.host.split('.')[0] != undefined 
? window.location.host.split('.')[0] == "app" 
? window.location.host.split('.')[0] + "." + window.location.host.split('.')[1] 
: window.location.host.split('.')[0] : "Martik"]
let List = []
const Web3 = require('web3-eth');
let a = window.location.pathname.toString().split("/");
let w = window.innerWidth
const Devconsole = (props) => {
    const {
        account,
        provider,
        chain,
        chains,
        web3,
        Tokencontract,
        gasTX,
        aweb3,
        getID,
        callTX,
        sendTX,
        nSendTX,
        nSendCT
    } = useContext(AppContext)
    const navigate = useNavigate();
    let pprovider = aweb3()
    let accounts = account;

    const opssData = {
        "martik": [
            'Simples (without fees)',
            'Standart (with fees, buy: tokens transfer, sell: bnb transfer)',
            'Dividend (with fees, buy: tokens transfer, sell: bnb transfer, external token reflection)',
            'Reflection (with fees, buy: tokens transfer, Token reflection, sell: bnb transfer, Token reflection)',
            'Combo (with fees, buy: tokens transfer, Token reflection, sell: bnb transfer, external token reflection, Token reflection)',
            'Ultra (with fees, buy: tokens transfer, sell: Martik transfer)',

        ],
        "xshiba": [
            'Simples (without fees)',
            'Standart (with fees, buy: tokens transfer, sell: bnb transfer)',
            'Dividend (with fees, buy: tokens transfer, sell: bnb transfer, external token reflection)',
            'Reflection (with fees, buy: tokens transfer, Token reflection, sell: bnb transfer, Token reflection)',
            'Combo (with fees, buy: tokens transfer, Token reflection, sell: bnb transfer, external token reflection, Token reflection)',
            'Ultra (with fees, buy: tokens transfer, sell: Martik transfer)',

        ],
        "sbk": [
            'Simples (without fees)',
            'Standart (with fees, buy: tokens transfer, sell: bnb transfer)',
            'Dividend (with fees, buy: tokens transfer, sell: bnb transfer, external token reflection)',
            'Reflection (with fees, buy: tokens transfer, Token reflection, sell: bnb transfer, Token reflection)',
            'Combo (with fees, buy: tokens transfer, Token reflection, sell: bnb transfer, external token reflection, Token reflection)',
            'Ultra (with fees, buy: tokens transfer, sell: Martik transfer)',

        ],
        "blackcryptoplatform": [
            'Simples (without fees)',
            'Standart (with fees, buy: tokens transfer, sell: bnb transfer)',
            'Dividend (with fees, buy: tokens transfer, sell: bnb transfer, external token reflection)',
            'Reflection (with fees, buy: tokens transfer, Token reflection, sell: bnb transfer, Token reflection)',
            'Combo (with fees, buy: tokens transfer, Token reflection, sell: bnb transfer, external token reflection, Token reflection)',
            'Ultra (with fees, buy: tokens transfer, sell: Martik transfer)',

        ],
        "xtiger": [
            'Simples (without fees)',
            'Standart (with fees, buy: tokens transfer, sell: bnb transfer)',
            'Dividend (with fees, buy: tokens transfer, sell: bnb transfer, external token reflection)',
            'Reflection (with fees, buy: tokens transfer, Token reflection, sell: bnb transfer, Token reflection)',
            'Combo (with fees, buy: tokens transfer, Token reflection, sell: bnb transfer, external token reflection, Token reflection)',
            'Ultra (with fees, buy: tokens transfer, sell: Martik transfer)',
        ],
        "jijiswap": [
            'Simples (without fees)',
            'Standart (with fees, buy: tokens transfer, sell: bnb transfer)',
            'Dividend (with fees, buy: tokens transfer, sell: bnb transfer, external token reflection)',
            'Reflection (with fees, buy: tokens transfer, Token reflection, sell: bnb transfer, Token reflection)',
            'Combo (with fees, buy: tokens transfer, Token reflection, sell: bnb transfer, external token reflection, Token reflection)',
            'Ultra (with fees, buy: tokens transfer, sell: Martik transfer)',

        ],
        "casinosworld": [
            'Simples (without fees)',
            'Standart (with fees, buy: tokens transfer, sell: bnb transfer)',
            'Dividend (with fees, buy: tokens transfer, sell: bnb transfer, external token reflection)',
            'Reflection (with fees, buy: tokens transfer, Token reflection, sell: bnb transfer, Token reflection)',
            'Combo (with fees, buy: tokens transfer, Token reflection, sell: bnb transfer, external token reflection, Token reflection)',
            'Ultra (with fees, buy: tokens transfer, sell: Martik transfer)',

        ],
        "brazuka": [
            'Simples (without fees)',
            'Standart (with fees, buy: tokens transfer, sell: bnb transfer)',
            'Dividend (with fees, buy: tokens transfer, sell: bnb transfer, external token reflection)',
            'Reflection (with fees, buy: tokens transfer, Token reflection, sell: bnb transfer, Token reflection)',
            'Combo (with fees, buy: tokens transfer, Token reflection, sell: bnb transfer, external token reflection, Token reflection)',
            'Ultra (with fees, buy: tokens transfer, sell: Martik transfer)',

        ],
        "dodolabs": [
            'Simples (without fees)',
            'Trading Fees (with fees, buy: tokens transfer, sell: bnb transfer)',
        ],
    }
    const opss = opssData[window.location.host.split('.')[0] != undefined ? window.location.host.split('.')[0] : "Martik"]
    const [ct, sect] = useState("TOKENMANAGER");
    const [tokens, settokens] = useState(undefined);
    const [token, setToken] = useState(getQuery('type') == null ? 0 : getQuery('type'));
    const [address, setaddress] = useState(getQuery('ca') == null ? undefined : getQuery('ca'));

    const [openContracts, setopenContracts] = useState(getQuery('ca') != null ? [getQuery('ca')] : []);
    useEffect(() => {

        getTokens()

    }, [])
    const getTokens = async () => {

        settokens(await Tokens())

    }
    // const [ct, sect] = useState(window.location.pathname.toString().split("/")[a.length - 1]);
    const [userCode, setUserCode] = useState(``);

    // State variable to set editors default language
    const [userLang, setUserLang] = useState('sol');

    // State variable to set editors default theme
    const [userTheme, setUserTheme] = useState("vs-dark");

    // State variable to set editors default font size
    const [fontSize, setFontSize] = useState(20);

    // State variable to set users input
    const [userInput, setUserInput] = useState("");

    // State variable to set users output
    const [userOutput, setUserOutput] = useState("");


    const [contractOUTPUT, setContractOUTPUT] = useState(undefined);
    function getQuery(query) {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const foo = params.get(query);
        return foo
    }
    const removeCT = (props) => {
        let list = []
        for (let index = 0; index < openContracts.length; index++) {
            if (index != props) {
                list.push(openContracts[index])
            }

        }
        setopenContracts(list)
    }
    const compile = async (code) => {
        fetch('https://martikstorage.onrender.com/compile', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                'data': code
            }),
        })
            .then((response) => response.json())
            .then((a) => {
                //console.log(a)
                setContractOUTPUT(a)
            }).catch(e => {

            });

    }
    const Hvga = (props) => {
        const [obj, sobj] = useState(0);
        let json = props.abi
        let bytecode = props.bytecode
        async function getobj() {
            let web3 = new Web3(pprovider);
            if (props.contrato != undefined) {
                let tobj = await new web3.Contract(json, props.contrato);
                if (obj == 0) {
                    sobj(tobj)
                }
            }
        }
        getobj()

        const Hvgainside = (props) => {
            const [result, secallresult] = useState("output");

            async function dsend(func, _value, ...args) {

                const provider = new ethers.providers.Web3Provider(pprovider)
                const signer = provider.getSigner()
                const factory = new ethers.ContractFactory(json, bytecode, signer);
                const approveTxUnsigned = {}
                approveTxUnsigned.from = accounts;
                approveTxUnsigned.value = _value;

                const contract = await factory.deploy(...args)
                await contract.deployed()
                secallresult(contract.address)
                setaddress(contract.address)
                let l = openContracts
                l.push(contract.address)
                setopenContracts(l)
            }

            async function DEPLOY(value) {
                // const jg = props.contract;
                let a = [];
                for (let g = 0; g < document.getElementById(props.name).children.length; g++) {
                    a.push(document.getElementById(props.name).children[g].children[1].value)
                }
                if (a.length <= 0) {
                    dsend(``, value);
                } else {
                    if (a.length == 1) {
                        dsend(``, value, a[0]);
                    } else {
                        if (a.length == 2) {
                            dsend(``, value, a[0], a[1]);
                        } else {
                            if (a.length == 3) {
                                dsend(``, value, a[0], a[1], a[2]);
                            } else {
                                if (a.length == 4) {
                                    dsend(``, value, a[0], a[1], a[2], a[3]);
                                } else {
                                    if (a.length == 5) {
                                        dsend(``, value, a[0], a[1], a[2], a[3], a[4]);
                                    } else {
                                        if (a.length == 6) {
                                            dsend(``, value, a[0], a[1], a[2], a[3], a[4], a[5]);
                                        } else {
                                            if (a.length == 7) {
                                                dsend(``, value, a[0], a[1], a[2], a[3], a[4], a[5], a[6]);
                                            } else {
                                                if (a.length == 8) {
                                                    dsend(``, value, a[0], a[1], a[2], a[3], a[4], a[5], a[6], a[7]);
                                                } else {
                                                    if (a.length == 9) {
                                                        dsend(``, value, a[0], a[1], a[2], a[3], a[4], a[5], a[6], a[7], a[8]);
                                                    } else {
                                                        dsend(``, value, a[0], a[1], a[2], a[3], a[4], a[5], a[6], a[7], a[8], a[9]);
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

            }
            return (
                <>
                    {props.type == "constructor" &&
                        <div style={{ display: "grid", gap: "10px", height: "min-content", margin: "auto", width: "280px", border: "1px solid " + Borders, padding: "20px 0px", background: modalsColor, }}>
                            {props.ct == ct &&
                                <div style={{ display: "grid", gap: "5px", width: "280px", }}>
                                    <p style={{ height: "", margin: "auto ", fontWeight: "bold", width: "260px", textAlign: "left", marginBottom: "20px", fontSize: "25px" }}>{ct}</p>
                                    <div style={{ height: "", margin: "auto ", fontWeight: "bold", width: "260px", textAlign: "left", marginBottom: "20px", fontSize: "25px" }}>
                                        <CheckList title={""} untitle={opss[token]} ops={opss} token={token} setvariavel={(e) => {
                                            setToken(
                                                e.includes('Simples') ? 0 :
                                                    e.includes('Standart') ? 1 :
                                                        e.includes('Dividend') ? 2 :
                                                            e.includes('Reflection') ? 3 :
                                                                e.includes('Combo') ? 4 :
                                                                    e.includes('Ultra') ? 5 :
                                                                        e.includes('Trading Fees') ? 6 : 0)
                                        }} />
                                    </div>
                                    <p style={{ margin: "auto ", fontWeight: "bold", width: "260px", textAlign: "left" }}>{props.name != undefined && props.name.toString().toUpperCase()}</p>
                                    <div id={props.name} style={{ height: "min-content", margin: "auto ", width: "260px", display: "grid", gridTemplateColumns: "100%", gap: "2px" }}>
                                        {props.inputs.map((e) => <InputBlock variavel={undefined} setvariavel={() => { }} desc={""} title={""} place={e.name + " - " + e.type} />)}
                                    </div>

                                    {(props.type == "function" || props.type == "constructor") && props.mutability != "view"
                                        &&
                                        <div style={{ display: "grid", gridTemplateColumns: "auto auto", height: "30px", width: "260px", margin: "auto", gap: "10px" }}>
                                            <InputBlock id={"i" + props.name} variavel={undefined} setvariavel={() => { }} desc={""} title={""} place={"value"} />
                                            <Button func={() => {
                                                if (props.type == "constructor") {
                                                    navigate("/create/contract")
                                                    // DEPLOY(document.getElementById("i" + props.name).value)
                                                } else {
                                                    //senddata(document.getElementById("i" + props.name).value)
                                                }
                                            }} text={props.type == "constructor" ? "DEPLOY" : "SEND"} />
                                        </div>
                                    }
                                    <p style={{ margin: "auto 0px", fontWeight: "bold", textAlign: "right", fontSize: "12px", borderBottom: "1px solid " + Borders, padding: "10px 10px" }}>{result}</p>

                                    <p style={{ margin: "auto ", fontWeight: "bold", width: "260px", textAlign: "left" }}>{"OPEN AT ADDRESS"}</p>
                                    <div id={props.name} style={{ height: "min-content", width: "280px", display: "grid", gridTemplateColumns: "100%", gap: "2px", borderBottom: "1px solid " + Borders, paddingBottom: "10px" }}>
                                        <div style={{ display: "grid", gridTemplateColumns: "auto auto", height: "35px", width: "260px", margin: "auto", gap: "10px" }}>
                                            <InputBlock id={"FIXED_ADDRESS"} variavel={undefined} setvariavel={() => { }} desc={""} title={""} place={"value"} />
                                            <Button func={() => {
                                                let l = openContracts
                                                setaddress(document.getElementById('FIXED_ADDRESS').value)
                                                l.push(document.getElementById('FIXED_ADDRESS').value)
                                                setopenContracts(l)
                                            }} text={"OPEN"} />
                                        </div>
                                    </div>
                                    <p style={{ margin: "auto ", fontWeight: "bold", width: "260px", textAlign: "left" }}>{"OPEN CONTRACTS"}</p>
                                    {openContracts.length > 0 && openContracts.map((e, i) => <div style={{ display: "grid", gridTemplateColumns: "auto auto auto", height: "35px", width: "260px", margin: "auto", gap: "10px", borderRadius: "5px", border: "1px solid #ffffff50" }}>
                                        <p onClick={() => { setaddress(e) }} style={{ cursor: "pointer", margin: "auto ", fontWeight: "bold", width: "200px", textAlign: "left", fontSize: "12px" }}>{ct + " AT " + (e != undefined && e.slice(0, 4) + '...' + e.slice(-4))}</p>
                                        <svg onClick={() => { navigator.clipboard.writeText(e) }} viewBox="0 0 24 24" fill="#fff" style={{ height: "16px", width: "16px", margin: "auto", cursor: "pointer" }} xmlns="http://www.w3.org/2000/svg" className="sc-231a1e38-0 gUXTeE"><path d="M15 1H4C2.9 1 2 1.9 2 3V16C2 16.55 2.45 17 3 17C3.55 17 4 16.55 4 16V4C4 3.45 4.45 3 5 3H15C15.55 3 16 2.55 16 2C16 1.45 15.55 1 15 1ZM19 5H8C6.9 5 6 5.9 6 7V21C6 22.1 6.9 23 8 23H19C20.1 23 21 22.1 21 21V7C21 5.9 20.1 5 19 5ZM18 21H9C8.45 21 8 20.55 8 20V8C8 7.45 8.45 7 9 7H18C18.55 7 19 7.45 19 8V20C19 20.55 18.55 21 18 21Z" /></svg>
                                        <svg onClick={() => { if (address == e) (setaddress(undefined)); removeCT(i) }} xmlns="http://www.w3.org/2000/svg" fill="#fff" style={{ height: "16px", width: "16px", margin: "auto", cursor: "pointer" }}>
                                            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                                        </svg>
                                    </div>)}
                                </div>
                            }
                        </div >
                    }
                </>

            );
        }
        //(e.stateMutability == "view" || ((e.type == "function" || e.type == "constructor") && e.stateMutability != "view" ))
        return (
            <>
                {
                    <div style={{ display: "grid", gap: "5px" }}>
                        {json.map((e) =>
                            <Hvgainside ct={props.name} contract={obj} name={e.type == "constructor" ? 'constructor' : e.name} inputs={e.inputs} type={e.type} mutability={e.stateMutability} />
                        )}
                    </div>
                }
            </>

        );
    }
    const EditorA = (props) => {
        const [obj, sobj] = useState(0);
        let json = props.abi
        let bytecode = props.bytecode
        async function getobj() {
            let web3 = new Web3(pprovider);
            if (props.contrato != undefined) {
                let tobj = await new web3.Contract(json, props.contrato);
                if (obj == 0) {
                    sobj(tobj)
                }
            }

        }
        getobj()
        const Hvgainside = (props) => {
            const [result, secallresult] = useState("output");

            function scall(func, callback, ...args) {

                func(...args).call()
                    .then((result) => {
                        callback(result);
                    })
                    .catch((error) => {

                    })
            }
            function ssend(func, _value, ...args) {

                nSendTX(address, json, getID(), func, _value, 0, ...args)
            }

            async function getdata() {
                const jg = props.contract;
                let a = [];
                for (let g = 0; g < document.getElementById(props.name).children.length; g++) {
                    a.push(document.getElementById(props.name).children[g].children[1].value)
                }

                scall(jg.methods[props.name], secallresult, ...a);

            }
            async function senddata(value) {

                let a = [];
                for (let g = 0; g < document.getElementById(props.name).children.length; g++) {
                    a.push(document.getElementById(props.name).children[g].children[1].value)
                }
                ssend(props.name, value, ...a);
            }

            return (
                <>
                    {(props.mutability == "view" || ((props.type == "function") && props.mutability != "view")) &&
                        <div style={{ display: "grid", gap: "20px", margin: "auto 10px", width: "100%", margin: "auto", border: "1px solid " + Borders, borderRadius: "10px", padding: "20px 20px", background: modalsColor, }}>
                            {props.ct == ct && <div style={{ display: "grid", gap: "5px", }}>
                                <p style={{ margin: "auto ", fontWeight: "bold", width: "100%", textAlign: "left" }}>{props.name != undefined && props.name.toString().toUpperCase()}</p>
                                <div id={props.name} style={{ height: "min-content", width: "100%", display: "grid", gridTemplateColumns: "100%", gap: "2px" }}>
                                    {props.inputs.map((e) => <InputBlock variavel={undefined} setvariavel={() => { }} desc={""} title={""} place={e.name + " - " + e.type} />)}
                                </div>
                                {props.mutability == "view" &&
                                    <Button func={() => {
                                        getdata()
                                    }} text={"CALL"} />
                                }
                                {(props.type == "function" || props.type == "constructor") && props.mutability != "view" && <div style={{ display: "grid", gridTemplateColumns: "auto 150px", height: "35px", width: "100%", margin: "auto", gap: "10px" }}>
                                    <InputBlock id={"i" + props.name} variavel={undefined} setvariavel={() => { }} desc={""} title={""} place={"value"} />
                                    <Button func={() => {
                                        senddata(document.getElementById("i" + props.name).value)
                                    }} text={props.type == "constructor" ? "DEPLOY" : "SEND"} />
                                </div>}
                                <p style={{ margin: "auto 0px", fontWeight: "bold", textAlign: "right", fontSize: "12px" }}>{result}</p>
                            </div>}
                        </div >
                    }
                </>

            );
        }
        //(e.stateMutability == "view" || ((e.type == "function" || e.type == "constructor") && e.stateMutability != "view" ))

        return (
            <>
                {
                    props.name == ct
                        ? <div style={{ display: "grid", gap: "5px", padding: "10px 0px" }}>
                            <p style={{ cursor: "pointer", margin: "auto ", fontWeight: "bold", width: "100%", textAlign: "left", fontSize: "14px", padding: "10px 0px" }}>{ct + " AT " + address.slice(0, 4) + '...' + address.slice(-4)}</p>
                            {json.map((e) =>
                                <Hvgainside ct={props.name} contract={obj} name={e.type == "constructor" ? 'constructor' : e.name} inputs={e.inputs} type={e.type} mutability={e.stateMutability} />
                            )}
                        </div>
                        : <></>
                }
            </>
        );
    }

    return (
        <div className="farms" style={{ maxWidth: "1024px" }}>

            {tokens != undefined &&
                <div style={{
                    height: "min-content",
                    maxHeight: window.innerHeight + "px",
                    width: "100%",
                    maxWidth: "1920px",
                    display: "grid",
                    gridTemplateColumns: w < 720 ? 'auto' : "280px auto",
                    gap: "10px",
                    borderRadius: "10px",
                    marginTop: "40px",
                    color: outFont,
                    overflow: "auto",

                }}>
                    <div>
                        <Hvga name={"TOKENMANAGER"} abi={tokens[getID()][token].abi} bytecode={tokens[getID()][token].bytecode} contrato={address} />
                    </div>

                    {address != undefined && <EditorA name={"TOKENMANAGER"} abi={tokens[getID()][token].abi} bytecode={tokens[getID()][token].bytecode} contrato={address} />}
                </div>
            }

        </div>
    );
}

export default Devconsole;