import React, { useState, useEffect, useContext } from 'react';
import { ethers } from 'ethers';
import 'balloon-css';

const Modal = (props) => {
  let provider = props.array[0]
  let contract = props.array[1]
  let cakeabi = props.array[2]
  let tokenabi = props.array[3]
  let accounts = [props.array[4]]
  const [cake, setCake] = useState(undefined);
  const [accountBNB, setBNBbalance] = useState(0);
  const [tokenAname, setTokenAname] = useState(props.symbola);
  const [tokenBname, setTokenBname] = useState(props.symbolb);
  const [tokenA, setTokenA] = useState(0);
  const [tokenB, setTokenB] = useState(0);
  const [WBNB, setWBNB] = useState("0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c");

  const [tokenACT, setTokenACT] = useState(props.a_contract);
  const [tokenBCT, setTokenBCT] = useState(props.b_contract);
  const [accountBalancemTA, setAccountBalanceTA] = useState(props.accountBalancemTA);
  const [accountBalancemTB, setAccountBalanceTB] = useState(props.accountBalancemTB);
  const [decimalsTA, setCdecimalsTA] = useState(props.deca);
  const [decimalsTB, setCdecimalsTB] = useState(props.decb);
  const [iTA, setiTA] = useState(props.ia);
  const [iTB, setiTB] = useState(props.ib);
  const [AllowA, setAllowA] = useState(props.AllowA);
  const [AllowB, setAllowB] = useState(props.AllowB);
  const [amountinswap, setAmountinSwap] = useState(0);
  const [amountoutswap, setAmountoutSwap] = useState(0);
  useEffect(() => {
    connectToSelectedNetwork()
  }, [tokenACT, tokenBCT]);

  async function connectToSelectedNetwork() {
    const _cake = await props.Tokencontract(cakeabi, contract);
    const ta = await props.Tokencontract(tokenabi, tokenACT);
    const tb = await props.Tokencontract(tokenabi, tokenBCT);
    const bal = accounts[0] != null ? await provider['getBalance'](accounts[0]) : 0


    setCake(_cake);
    setTokenA(ta);
    setTokenB(tb);
    setBNBbalance(bal)
  }

  function nextblock(accountBalancemctTB, d, cs) {
    return (accountBalancemctTB != undefined && accountBalancemctTB != 0) ? parseFloat(ethers.utils.formatUnits(accountBalancemctTB, d)).toFixed(cs == undefined ? 6 : cs) : 0.00
  }
  const balancetokenA = () => {
    if (tokenAname == "WBNB") {
      return nextblock(accountBNB, decimalsTA);
    } else {
      return nextblock(accountBalancemTA, decimalsTA)
    }
  }
  const balancetokenB = () => {
    if (tokenBname == "WBNB") {
      return nextblock(accountBNB, decimalsTB);
    } else {
      return nextblock(accountBalancemTB, decimalsTB)
    }
  }
  function bnbtext() {
    return "Share"
  }
  async function swap() {
    if (tokenAname == "WBNB") {
      await props.nSendTX(amountinswap, amountoutswap, amountinswap)
    } else {
      await props.nSendTX(amountinswap, amountoutswap, 0)
    }
  }
  async function getAmountOut(value) {
    setAmountinSwap(value)
    try {


      if (cake != undefined) {

        let a = ['']
        let e = value + ""
        //console.log(value)
        //console.log(e, decimalsTA)
        if (e.toString().includes(",")) {
          a = e.toString().split(",")
          e = ethers.utils.parseUnits(a[0] + "." + a[1], decimalsTA).toString()
        } else {
          e = ethers.utils.parseUnits(e, decimalsTA).toString()
        }

        if (tokenACT != WBNB && tokenBCT != WBNB) {
          const _price = parseFloat(e) > 0 ? await cake.methods.getAmountsOut("1" + "0".repeat(parseInt(decimalsTA / 2)), [tokenACT, WBNB]).call() : 0
          const price = parseFloat(_price) > 0 ? await cake.methods.getAmountsOut(_price[1], [WBNB, tokenBCT]).call() : 0
          setAmountoutSwap(price[1] != 0 ? nextblock(price[1], decimalsTB) : 0)
        } else {
          if (tokenACT == WBNB && tokenBCT == WBNB) {
            setAmountoutSwap(value)
          } else {
            const price = parseFloat(e) > 0 ? await cake.methods.getAmountsOut("1" + "0".repeat(parseInt(decimalsTA / 4) * 3), [tokenACT, tokenBCT]).call() : 0
            const _value = ((parseFloat(value) / parseFloat(ethers.utils.formatUnits("1" + "0".repeat(parseInt(decimalsTA / 4) * 3), decimalsTA))) * nextblock(price[1], decimalsTB)).toFixed(4)
            setAmountoutSwap(price[1] != 0 ? parseFloat(_value) + ((parseFloat(_value) * 0.5) / 100) : 0)
          }
        }
      }
    } catch (error) {

    }

  }
  async function getAmountIn(value) {
    setAmountoutSwap(value)
    try {

      if (cake != undefined) {
        let a = ['']
        let e = value + ""
        if (e.toString().includes(",")) {
          a = e.toString().split(",")
          e = ethers.utils.parseUnits(a[0] + "." + a[1], decimalsTB).toString()
        } else {
          e = ethers.utils.parseUnits(e, decimalsTB).toString()
        }

        if (tokenBCT != WBNB && tokenACT != WBNB) {
          const _price = parseFloat(e) > 0 ? await cake.methods.getAmountsOut(e, [tokenBCT, WBNB]).call() : 0
          const price = parseFloat(_price) > 0 ? await cake.methods.getAmountsOut(_price[1], [WBNB, tokenACT]).call() : 0
          setAmountinSwap(price[1] != 0 ? nextblock(price[1], decimalsTA) : 0)
        } else {
          if (tokenACT == WBNB && tokenBCT == WBNB) {
            setAmountinSwap(value)
          } else {
            const price = parseFloat(e) > 0 ? await cake.methods.getAmountsOut(e, [tokenBCT, tokenACT]).call() : 0
            setAmountinSwap(price[1] != 0 ? nextblock(price[1], decimalsTA) : 0)
          }
        }
      }

    } catch (error) {

    }


  }

  const SAFE = (props) => {
    return (
      <div style={{ width: "95%", display: "grid", gridTemplateColumns: "auto auto", margin: "auto", marginTop: "5px" }}>
        <div style={{
          cursor: "pointer",
          textAlign: "left",
          height: "20px",
          width: "100%",
          fontWeight: "bold",
          borderRadius: "2px",
          color: "#ddd",
          fontSize: "13px",
          display: "grid", gridTemplateColumns: "50px 10px",
        }}> <span style={{
          overflow: "hidden"
        }}>
            {props.what}
          </span>

        </div>
        <span style={{
          textAlign: "right",
          width: "100%",
          fontSize: "13px",
          color: "#ddd",
        }}>
          {props.result}
        </span></div>
    );
  }
  const ButtonI = (props) => {
    return (
      <div style={{ display: "grid", gridTemplateColumns: "auto", height: '50px', width: "95%", margin: "auto", marginBottom: "2vh", marginTop: "20px" }}>
        <p onClick={props.func} style={{
          border: "1px solid #000",
          backgroundColor: '#ffffff50',
          borderRadius: "10px",
          height: '50px',
          width: "100%",
          display: "grid",
          margin: "auto",
          cursor: "pointer"
        }}><p style={{ margin: "auto", fontWeight: "bold", color: "#000" }}>{props.title}</p></p>
      </div>
    );
  }

  return (
    <div style={{ color: "#000", height: "100%", width: "100%", position: "fixed", display: "grid", background: "#00000050", top: "0", left: "0", zIndex: "999999999" }} >
      <div style={{
        height: "min-content",
        maxWidth: "480px",
        minWidth: "360px",
        listStyleType: "none",
        display: "grid",
        margin: "auto",
        borderRadius: "10px",
        background: "#222",
        color: "#ddd",
        fontSize: "12px",
        paddingTop: "10px"
      }}>
        <div style={{ display: "grid", gridTemplateColumns: "25px 280px 50px", width: "100%", height: "70px", marginBottom: "10px", padding: "auto" }}>
          <p></p>
          <div style={{
            height: "min-content",
            width: "100%",
            margin: "auto"
          }}>
            <div style={{
              height: "30px",
              width: "100%",
              display: "inline-flex"
            }}>
              <p style={{ textAlign: "left", height: "min-content", fontWeight: "bold", fontSize: "17px", margin: "auto 0" }}>{"Share Pool"}</p>
            </div>
            <p style={{ textAlign: "left", height: "min-content", fontSize: "12px", marginBottom: "2px" }}>Create lp position</p>

          </div>
          <svg onClick={() => { props.sethubtx(false) }} xmlns="http://www.w3.org/2000/svg" style={{ cursor: "pointer", margin: "auto" }} width="25" height="25" fill="#fff" className="bi bi-x" viewBox="0 0 16 16">
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
          </svg>
        </div>

        <div style={{ display: "grid", gridTemplateColumns: "auto ", backgroundColor: "#222", height: "100%", width: "100%", margin: "auto", borderRadius: "10px", paddingBottom: "20px", zIndex: "1", paddingRight: "20px", paddingLeft: "20px" }}>
          <SAFE what={tokenAname == "WBNB" ? "BNB" : tokenAname} result={<p>balance: {balancetokenA()}</p>} />
          <div style={{ display: "grid", gridTemplateColumns: "auto 50px", gap: "5px", height: "100%", width: "100%", margin: "auto" }}>
            <div style={{
              height: "40px",
              width: "100%",
              margin: "auto",
              display: "grid",
              gridTemplateColumns: "35px auto",
              backgroundColor: "#00000020",
              borderRadius: "10px"
            }}>
              <i style={{
                height: "100%",
                width: "100%",
                margin: "auto",
                background: "url('" + iTA + " ')  center center / 50% no-repeat",
              }}>
              </i>
              <div style={{ height: '40px', width: "100%", display: "grid", gridTemplateColumns: "100%" }}>
                <input type="number" id={props.id} onChange={(e) => getAmountOut(e.target.value)} placeholder='0.0' min={0} style={{ backgroundColor: "#00000000", outline: "none", textAlign: "right", border: "none", height: "100%", width: "100%", margin: "auto", borderRadius: "10px", color: "#eee", fontSize: "14px", paddingRight: "10px" }} value={amountinswap} />
              </div>
            </div>
            <p onClick={() => {
              getAmountOut(balancetokenA())
            }} style={{
              height: "40px",
              border: "1px solid #00000010",
              borderRadius: "10px",
              margin: "auto",
              width: "100%",
              display: "grid",
              cursor: "pointer"
            }}><p style={{ margin: "auto", fontWeight: "bold" }}>MAX</p></p>
          </div>

          <SAFE what={tokenBname} result={<p>balance: {balancetokenB()}</p>} />

          <div style={{ display: "grid", gridTemplateColumns: "auto ", gap: "5px", height: "100%", width: "100%", margin: "auto" }}>
            <div style={{
              height: "40px",
              width: "100%",
              margin: "auto",
              display: "grid",
              gridTemplateColumns: "35px auto",
              backgroundColor: "#00000020",
              borderRadius: "10px"
            }}>
              <i style={{
                height: "100%",
                width: "100%",
                margin: "auto",
                background: "url('" + iTB + " ')  center center / 50% no-repeat",
              }}>
              </i>
              <div style={{ height: '40px', width: "100%", display: "grid", gridTemplateColumns: "100%" }}>
                <input type="number" id={props.id} onChange={(e) => getAmountIn(e.target.value)} placeholder='0.0' min={0} style={{ backgroundColor: "#00000000", outline: "none", textAlign: "right", border: "none", height: "100%", width: "100%", margin: "auto", borderRadius: "10px", color: "#eee", fontSize: "14px", paddingRight: "10px" }} value={amountoutswap} />
              </div>
            </div>
          </div>

          {<div style={{ display: "grid", gridTemplateColumns: "auto", gap: "5px", height: "100%", width: "100%", margin: "auto" }}>
            <ButtonI func={() => swap()} title={bnbtext()} />
          </div>}

        </div>
      </div >

    </div>

  );
}
export default Modal;