import React from 'react';
import ProjectData from '../../config';
let projectName = "Martik"
const {
    Name,
    Logo,
    backgroundColor,
    buttonsColor,
    buttonsBorders,
    inputsColor,
    inputsBorders,
    outbuttonsColor,
    Borders,
    outFont,
    innerFont,
    innerBFont,
    Routers
} = ProjectData[window.location.host.split('.')[0] != undefined 
? window.location.host.split('.')[0] == "app" 
? window.location.host.split('.')[0] + "." + window.location.host.split('.')[1] 
: window.location.host.split('.')[0] : "Martik"]

function CheckList(props) {
    return (
        <div className='flex flex-col'>
            <div className='text-sm font-bold dark:text-white px-2'>{props.title}</div>
            <select onChange={(e) => { props.setvariavel(e.target.value) }} style={{ padding: "0px 10px", background: inputsColor, border: inputsBorders, borderRadius: "2px", height: "35px", width: "100%", color: "#ddd", outline: "none" }}><font></font>
                {props.ops.map((e, i) => <option style={{ height: "10px", background: innerBFont }}>{e}</option>)}
            </select>
        </div>
    );
}

export default CheckList;