import React from 'react';


const SAFE = (props) => {
  return (
    <p style={{ width: "95%", display: "grid", height: "25px", gridTemplateColumns: "auto auto", margin: "auto", borderBottom: "1px solid #ffffff00" }}><span style={{
      textAlign: "left",
      width: "100%",
      fontWeight: "bold",
      color: "#999",
      fontSize: "14px",
      margin: "auto"
    }}>{props.what} </span><span style={{
      textAlign: "right",
      width: "100%",
      fontSize: "14px",
      color: "#fff",
      margin: "auto"
    }}>{props.result}</span></p>

  );
}

export default SAFE;