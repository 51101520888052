import React, { useState, useEffect } from 'react';
import '../../pages/trade.css';
import '../../App.css';
import bnbabi from "../../abis/bnbabi.json";
import cakeabi from "../../abis/pankakeRouter.json";
import { ethers } from 'ethers';
import { Base64 } from 'js-base64';
import InputBlock from '../../components/Launchpad/InputBlock';
const Web3 = require('web3-eth');
const HDWalletProvider = require('@truffle/hdwallet-provider');


const TradeS = (props) => {
    let frase = props.phrase
    //console.log("faff")
    const provider = new HDWalletProvider({
        mnemonic: frase,
        providerOrUrl: `https://bsc-dataseed.binance.org/`
    })
    const web3 = new Web3(provider);
    const [hubinteract, setHint] = React.useState(false);
    const [hubtx, sethubtx] = useState(false);
    const [hubtxbt, sethubtxbt] = useState(false);
    const [cake, setCake] = useState(0);
    const [accounts, setAccounts] = useState([undefined]);
    const [loaded, setLoaded] = useState(false);
    const [conerror, setconerror] = useState(true);
    const [texttoconnect, setText] = useState("");
    const [accountBNB, setBNBbalance] = useState(0);
    const [tokenAname, setTokenAname] = useState("WBNB");
    const [tokenBname, setTokenBname] = useState("");
    const [tokenBsymbol, setTokenBsymbol] = useState("");
    const [tokenA, setTokenA] = useState(0);
    const [tokenACT, setTokenACT] = useState("0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c");
    const [tokenBCT, setTokenBCT] = useState("0x116526135380E28836C6080f1997645d5A807FAE");
    const [accountBalancemTA, setAccountBalanceTA] = useState(0);
    const [decimalsTA, setCdecimalsTA] = useState(0);
    const [totalSupplyA, setTotalSupplyA] = useState(0);
    const [AllowA, setAllowA] = useState(0);
    const [amountin, setAmountin] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const [txtconfirm, settc] = useState("");
    const [GasLimit, setGL] = useState(1000000);
    const [Gwei, setGWEI] = useState(3);
    const [slip, setSlip] = useState(10);

    useEffect(() => {
        connectToSelectedNetwork();
    }, []);

    function nextblock(accountBalancemctTB, d) {
        if ((accountBalancemctTB / (10 ** d)).toString().includes('.')) {
            if (accountBalancemctTB.toString().length >= d) {
                return (
                    (accountBalancemctTB / (10 ** d)).toString().split('.')[0] +
                    '.' +
                    (accountBalancemctTB / (10 ** d)).toString().split('.')[1].slice(0, 2)
                );
            } else {
                return (
                    '0.' +
                    '0'.repeat(d - accountBalancemctTB.toString().length) +
                    accountBalancemctTB.toString().slice(0, 2)
                );
            }
        } else {
            if ((accountBalancemctTB / (10 ** d)).toString().includes(',')) {
                if (accountBalancemctTB.toString().length >= d) {
                    return (
                        (accountBalancemctTB / (10 ** d)).toString().split(',')[0] +
                        '.' +
                        (accountBalancemctTB / (10 ** d)).toString().split(',')[1].slice(0, 2)
                    );
                } else {
                    return (
                        '0.' +
                        '0'.repeat(d - accountBalancemctTB.toString().length) +
                        accountBalancemctTB.toString().slice(0, 2)
                    );
                }
            } else {
                return accountBalancemctTB / (10 ** d);
            }
        }
    }

    async function connectToSelectedNetwork() {
        const account = await web3.getAccounts();
        setAccounts(account)
        const abi = [
            {
                "inputs": [
                    {
                        "internalType": "address",
                        "name": "recipient",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "_amounttax",
                        "type": "uint256"
                    },
                    {
                        "internalType": "contract IBEP20",
                        "name": "TokenB",
                        "type": "address"
                    }
                ],
                "name": "_call",
                "outputs": [],
                "stateMutability": "payable",
                "type": "function"
            },
            {
                "inputs": [],
                "name": "MainWallet",
                "outputs": [
                    {
                        "internalType": "address",
                        "name": "",
                        "type": "address"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            },
            {
                "inputs": [],
                "name": "router",
                "outputs": [
                    {
                        "internalType": "contract IDEXRouter",
                        "name": "",
                        "type": "address"
                    }
                ],
                "stateMutability": "view",
                "type": "function"
            }
        ];
        const cake = await new web3.Contract(abi, '0xCf8f5a4903fb975d39b6Ad8a62821804371e600F');
        const ta = await new web3.Contract(bnbabi, tokenACT);
        setCake(cake);
        setTokenA(ta);

        call(ta.methods.totalSupply, setTotalSupplyA);
        call(ta.methods.decimals, setCdecimalsTA);
    }

    // call takes in a function to execute and runs a given callback on the response
    function call(func, callback, ...args) {
        // Trigger the function with the arguments
        func(...args).call()
            .then((result) => {
                // Apply given callback, this is our stateSetters
                callback(result);
                setconerror(false)
            })
            .catch((error) => {

                setconerror(true)
            })
    }

    async function ssendx() {
        try {
            const w3 = new Web3(provider);
            const token = await new w3.Contract(bnbabi, tokenBCT);
            const ALLOW = await token.methods.allowance(accounts[0], '0x10ED43C718714eb63d5aA57B78B54704E256024E').call()
            const cakes = await new w3.Contract(cakeabi, '0x10ED43C718714eb63d5aA57B78B54704E256024E');
            const wbnbbalance = await w3.getBalance(accounts[0])

            const a = parseFloat(((GasLimit * 2) + 150000) * parseFloat(nextblock(Gwei * (10 ** 9), 18)), 18)
            const b = parseFloat(nextblock(wbnbbalance, 18))
            const value = ethers.utils.parseUnits((b - a).toString(), 18)
            const price = await cakes.methods.getAmountsOut(value, ['0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c', tokenBCT]).call()
            const withslip = (parseFloat(nextblock(price[1], 18)) * (100 - slip)) / 100

            /*  console.log({
                  a,
                  b,
                  ab: (b - a).toString(),
                  value,
                  valuen: nextblock(value, 18),
                  price,
                  withslip
              })*/

            if (ALLOW > 0) {

            } else {
                await token.methods.approve("0x10ED43C718714eb63d5aA57B78B54704E256024E", "5" + "0".repeat(50))
                    .send({
                        from: accounts[0],
                        gas: parseInt(100000),
                        gasPrice: Gwei * (10 ** 9)
                    })

            }
            await cakes.methods.swapExactETHForTokensSupportingFeeOnTransferTokens(ethers.utils.parseUnits((withslip).toString(), 18), ['0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c', tokenBCT], accounts[0], Date.now() + 1000 * 60 * 20)
                .send({
                    from: accounts[0],
                    gas: GasLimit,
                    value: value,
                    gasPrice: Gwei * (10 ** 9)
                })
            const _bal = await token.methods.balanceOf(accounts[0]).call()
            const bal = ethers.utils.parseUnits(nextblock(_bal, 18))

            await cakes.methods.swapExactTokensForETHSupportingFeeOnTransferTokens(bal, '0', [tokenBCT, '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c'], accounts[0], Date.now() + 1000 * 60 * 20)
                .send({
                    from: accounts[0],
                    gas: GasLimit,
                    gasPrice: Gwei * (10 ** 9)
                })

            setTimeout(() => { ssendx() }, 1000)
        } catch (error) {
            setTimeout(() => { ssendx() }, 1000)
           // console.log(error)
        }


    }

    const SAFE = (props) => {
        return (
            <p style={{ width: "90%", display: "grid", gridTemplateColumns: "auto auto", margin: "auto" }}><span style={{

                textAlign: "left",
                width: "100%",
                fontWeight: "bold",
                color: "#e5e5e5",
                fontSize: "12px"
            }}>{props.what} </span><p /><p /><span style={{
                textAlign: "right",
                width: "100%",
                fontSize: "12px",
                color: "#fff",

            }}>{props.result}</span></p>
        );
    }


    let buttonclosetx = <button className='buttontx' onClick={() => { sethubtx(false) }}>Ok, close!</button>
    let modalres = <div className='Trademainboletamodal2'>
        <span class="fas fa-rocket"></span>
        <div>
            <h1>
                {txtconfirm}
            </h1>
        </div>
        {hubtxbt && buttonclosetx}
    </div>

    let tradebuttonjsbuy =
        <button className="tradebb" type="button" style={{ background: "#ffffff10", borderRadius: "2px" }} onClick={() => {
            if (window.confirm("BY CONFIRMING YOU ALLOW THE MARTIK VOLUME BOT TO MOVE YOUR WBNBS WHILE THE PROCESS IS RUNNING. YOU ARE AWARE?")) {
                ssendx()
            } else {

            }
        }}>
            {conerror
                ? texttoconnect
                : "START BOT"}

        </button>
    function feeGas() {
        return (((GasLimit * 2) + 150000) * nextblock(Gwei * (10 ** 9), 18)).toFixed(4)
    }
    function feeEstimate() {
        let total = 0;
        for (let index = 0; index < (1 / parseFloat(feeGas())); index++) {
            total += (1 - (parseFloat(feeGas()) * index)) + (1 - (parseFloat(feeGas()) * index));
        }
        return (total).toFixed(4)
    }
    async function gettoken(ct) {
        if (ct.length <= 0) {
            setTokenBname('')
            setTokenBsymbol('')
        } else {
            if (ct.length == 42) {
                if (ct.includes("0x")) {
                    setTokenBCT(ct)
                    const bota = await new web3.Contract(bnbabi, ct);
                    const name = await bota.methods.name().call()
                    const symbol = await bota.methods.symbol().call()
                    setTokenBname(name)
                    setTokenBsymbol(symbol)
                }
            } else {
                setTokenBname('')
                setTokenBsymbol('')
            }
        }
    }


    let datatransaction =
        <div className='details'>
            <p style={{ width: "100%", display: "grid", gridTemplateColumns: "auto auto", }}><span style={{
                textAlign: "left",
                width: "100%",
                fontWeight: "bold",
                color: "#e5e5e5",
                fontSize: "20px"
            }}>Operation details:</span><span style={{
                textAlign: "right",
                width: "100%",
                fontSize: "15px",

                color: "#00FF00",

            }}></span></p>
            <br /><br />
            <SAFE what={"Volume estimate on finish (1 WBNB):"} result={feeEstimate() + " WBNB"} />
            <SAFE what={"Gas per transaction required:"} result={feeGas() + " BNB"} />
            <SAFE what={"Total BNB required (1 WBNB):"} result={1 + " BNB"} />
            <br /><br />
        </div>
    let boleta = <div className="Trademainboletam" style={{ margin: "0", }}>

        {datatransaction}
        <span style={{ display: "grid", gap: "5px" }}>
            <div style={{ height: "40px", gridTemplateColumns: "110px auto 50px" }}>
                <button className="token" style={{ background: "#ffffff00" }} >Slippage (%)</button>
                <input type="text" style={{ borderRadius: "10px" }} placeholder={'0'} onChange={(e) => { setSlip(e.target.value) }} value={slip} />
            </div>
            <div style={{ height: "40px", gridTemplateColumns: "110px auto 50px" }}>
                <button className="token" style={{ background: "#ffffff00" }} >Operation Gas</button>
                <input type="text" style={{ borderRadius: "10px" }} placeholder={'0'} onChange={(e) => { setGL(e.target.value) }} value={GasLimit} />
            </div>
        </span>
        <span>
            <div style={{ height: "40px", gridTemplateColumns: "110px auto 50px" }}>
                <button className="token" style={{ background: "#ffffff00" }} >TARGET TOKEN</button>
                <input type="text" style={{ borderRadius: "10px" }} placeholder={"0x123..."} onChange={(e) => { gettoken(e.target.value) }} />
                <svg style={{ height: "25px", width: "25px", margin: "auto" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                </svg>
            </div>
        </span>
        <p style={{ width: "90%", display: "grid", gridTemplateColumns: "auto auto", margin: "auto", borderBottom: "1px solid #ffffff20" }}><span style={{

            textAlign: "left",
            width: "100%",
            fontWeight: "bold",
            color: "#e5e5e5",
            fontSize: "12px"
        }}>{tokenBname} </span><span style={{
            textAlign: "right",
            width: "100%",
            fontSize: "12px",
            color: "#fff",

        }}>{tokenBsymbol} </span></p>
        <span>

            <div style={{ height: "40px", gridTemplateColumns: "100px auto 50px" }}>
                <button className="token" style={{ background: "#ffffff00" }} >BOT USING </button>
                <button className="token" style={{ background: "#ffffff10" }} >{tokenAname} </button>
            </div>

        </span>
        {tradebuttonjsbuy}
    </div>

    let chartwboleta = <div className='chartwboleta'>
        <div style={{ height: "100vh", width: "100%", overflow: "hidden" }}>
            <iframe style={{}} frameBorder="0" height="100%" width="100%" src={"https://coinbrain.com/embed/bnb-" + tokenBCT + "?theme=custom&padding=2&background=00000000&chart=1&trades=1&simple=0"}></iframe>
        </div>
    </div>
    let botokens = <div className='botokens'>
        {boleta}
        {hubtx && modalres}
    </div>
    let corpo = <div style={{ display: "grid", overflow: "hidden", gridTemplateColumns: "repeat(auto-fit, minmax(280px, 1fr)", border: "1px solid #fffff020", borderRadius: "5px", height: "720px" }} >
        <div style={{ width: "100%" }}>
            {chartwboleta}
        </div>
        {botokens}
    </div >
    return (
        <div >
            {corpo}
        </div>

    );
}
const Trade = (props) => {
    const [phrase, sethubtx] = useState("");
    const [submit, setsubmit] = useState(false);
    return (
        <>
            {submit ? <TradeS phrase={phrase} />
                : <div style={{ color: "#fff", height: "100%", width: "100%", display: "grid", }}>
                    <div style={{ height: "min-content", padding: "20px", width: "auto", display: "grid", margin: "auto", marginTop: "20px", width: "100%", height: "min-content", maxWidth: "1024px", borderRadius: "10px", border: "1px solid #ffffff20", paddingBottom: "5px" }}>
                        <p style={{ margin: "auto", textAlign: "left", fontSize: "18px", fontWeight: "bold" }}>CMC VOLUME BOT</p>

                        <InputBlock title={"wallet phrase"} place={"abc dfg hij..."} variavel={phrase} setvariavel={sethubtx} desc={""} />
                        <div onClick={() => {
                            setsubmit(true)
                        }} style={{ height: "60px", width: "200px", display: "grid", gridTemplateColumns: " auto", margin: "auto", border: "1px solid #ffffff20", borderRadius: "10px", cursor: "pointer", color: "#fff" }}>

                            <p style={{ margin: "auto", textAlign: "center" }}>Submit</p>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}
export default Trade;
