import React, { useState, useEffect, useContext } from "react";
import { ethers } from 'ethers';
import abiToken from "../../../abis/bnbabi.json";
import Timer from '../../../components/Timer/timer.js';
import bnbabi from "../../../abis/bnbabi.json";
import Numberinput from "../../../components/Launchpad/numberInput";
import SAFE from "../../../components/Launchpad/textpar";
import InputBlock from "../../../components/Launchpad/InputBlock";
import DescBlock from "../../../components/Launchpad/descBlock";
import Button from "../../../components/Launchpad/Button";
import Modal from "../../../components/Launchpad/SaleModal";
import abifac from "../../../abis/Private/PrivateFactory.json";
import abi from "../../../abis/Private/privateAbi.json";
import AppContext from "../../../framework/helpers/AppContext";
import env from '../../../hooks/data/Config';
import FilterSale from "../../../components/Launchpad/FilterSale";
import FilterSales from "../../../components/Launchpad/FilterSales";
import HeaderSecondary from "../../../components/Launchpad/HeaderSecondary";



const Web3 = require('web3-eth');
const SalePage = (props) => {
	const {
		account,
		provider,
		chain,
		chains,
		web3,
		Tokencontract,
		gasTX,
		getID,
		callTX,
		sendTX,
		nSendTX,
		nSendCT
	} = useContext(AppContext)
	const [address, setaddress] = useState(account);
	const [list, setList] = useState([{}]);
	const [filter, setFilter] = useState(0);
	const [listof, setlistof] = useState(0);
	const [searchValue, setSearchValue] = useState('');
	const [statusFilter, setstatusFilter] = useState('No filter');
	useEffect(() => {
		//document.title = "MARTIK | PRIVATES"
		loadlist()

	}, []);

	async function loadlist() {
		setList([])
		if (localStorage.getItem("pri-cache") != undefined && list[0]['so'] == undefined) {
			setList(JSON.parse(localStorage.getItem("pri-cache")))
		}
		const factory = await Tokencontract(abifac, env.CONTRACTS[getID()].priSaleContract)
		const _list = await factory.methods.privateSalesview().call()

		let _t_list = []
		for (let index = 0; index < _list.length; index++) {
			const Sale = await Tokencontract(abi, _list[index])

			const tokenct = await Sale.methods._contract().call()
			const tct = await Tokencontract(abiToken, tokenct)
			const obj = {
				title: '',
				cts: _list[index],
				contract: tokenct,
				tokenname: await tct.methods.name().call(),
				softcap: await Sale.methods.softcap().call(),
				hardcap: await Sale.methods.hardcap().call(),
				uToken: await Sale.methods.uToken().call(),
				Affiliated: await Sale.methods.Affiliated().call(),
				starttime: await Sale.methods.startTime().call(),
				endtime: await Sale.methods.endTime().call(),
				balance: await Sale.methods.contractBalance().call(),
				bal: await Sale.methods.balance().call(),
				LogoURL: await factory.methods.profile(_list[index], 0).call(),
				url: "/private-sale/",
				owner: await Sale.methods.owner().call(),
				invested: address != undefined ? await Sale.methods.user_amount_BNB(address).call() : 0
			}

			_t_list.push(obj)
		}
		setList(_t_list)
		localStorage.setItem("pri-cache", JSON.stringify(_t_list))
	}
	function endedccheck(object) {
		if (!object.ended) {
			if (parseInt(Date.parse(new Date()) / 1000) >= parseInt(object.starttime)) {
				if (parseInt(Date.parse(new Date()) / 1000) < parseInt(object.endtime)) {
					return "Sale live"
				} else {
					return "Ended"
				}
			} else {
				return "Comming"
			}
		} else {
			if (parseInt(object.bal) > 0) {
				return "Finalized"
			} else {
				return "Canceled"
			}
		}

	}
	return (
		<div style={{ width: "100%", margin: "auto", maxWidth: "1280px", display: "grid", padding: "2vh" }}>
			<div className="flex-col space-y-6 items-center justify-content">
				<FilterSales setlistof={
					setlistof
				} />
				<FilterSale opts={{
					setSearchValue,
					setstatusFilter,
					setFilter,
				}} />
			</div>
			<div style={{
				height: "min-content",
				width: "100%",
				display: "grid",
				gridTemplateColumns: "repeat(auto-fill, minmax(280px, 1fr)",
				gap: "10px",
				borderRadius: "10px",
				marginTop: "auto",
				color: "#fff",
				maxWidth: "1280px",
			}}>
				{list
					.sort((a, b) => (filter == 'No filter' ? true :
						filter == 'Hard Cap' ? a.hardcap > b.hardcap :
							filter == 'Soft Cap' ? a.softcap > b.softcap :
								filter == 'LP percent' ? a.LiquidifyPercent > b.LiquidifyPercent :
									filter == 'Start Time' ? a.starttime > b.starttime :
										filter == 'End Time' ? a.endtime > b.endtime :
											true) ? 1 : -1)
					.map((e, index) => <Modal obj={e} inlist={listof == 0 ? true : listof == 1 ? e.invested != 0 : e.owner == address} status={endedccheck(e)} nameSearch={searchValue} filter={statusFilter} isOnFilter={(statusFilter == 'No filter' ? true : statusFilter == endedccheck(e))} />).reverse()}


			</div>

			<p style={{ height: "10vh" }}></p>
		</div>
	);
}

export default SalePage;