import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import Connection from './hooks/wallet/connection';
import './pages/home.css';
import './pages/farm.css';
import { ethers } from 'ethers';
import cakeabi from "./abis/pankakeRouter.json";
import { ConnectButton } from '@rainbow-me/rainbowkit';
//screens
import Home from './pages/newhome';
import Terms from './pages/terms';
import Whitepaper from './pages/whitepaper';
import Audits from './pages/audits/audits';
import MultiSTK from './pages/multistake/multi';
import Stakepools from './pages/stake/stakepool';
import MobileStake from './pages/stake/mobilestake';
import CStakepools from './pages/stake/StakeCreate';

import StakepoolsNFT from './pages/nftsstake/stakepool';
import MobileStakeNFT from './pages/nftsstake/mobilestake';
import CStakepoolsNFT from './pages/nftsstake/StakeCreate';

import CSP2Ppools from './pages/p2p/cs-pool';
import P2Ppools from './pages/p2p/stakepool';
import Chain from './pages/chain/explorer';
import Marketplace from './pages/marketplace';
//import Modalwallet from "./pages/modalwallet";
import Exchange from "./pages/trade/exchange";
//import Priv1 from "./pages/privatev1/Private";
import Betpools from './pages/bet/pool';
import Trade from "./pages/trade/trade";
//import BotService from "./pages/bot.js";
import Volume from "./pages/volume/trade";
import Swap from "./pages/swap";
import TK from './pages/launchpad/token/create';
import Devconsole from './pages/launchpad/token/devconsole';
import Lock from './pages/launchpad/lock/lock';
import Farms from "./pages/FarmPools";

import Pre from './pages/launchpad/Pre/page';
import Fair from './pages/launchpad/Fair/page';
import Private from './pages/launchpad/Private/page';
import Pump from './pages/launchpad/pump/page';

import PreModal from './pages/launchpad/Pre/Private';
import FairModal from './pages/launchpad/Fair/Private';
import PrivateModal from './pages/launchpad/Private/Private';
import PumpModal from './pages/launchpad/pump/Private';

import PreC from './pages/launchpad/Pre/create';
import FairC from './pages/launchpad/Fair/create';
import PrivateC from './pages/launchpad/Private/create';
import PumpC from './pages/launchpad/pump/create';

import PreE from './pages/launchpad/Pre/editprofile';
import FairE from './pages/launchpad/Fair/editprofile';
import PrivateE from './pages/launchpad/Private/editprofile';
import PumpE from './pages/launchpad/pump/editprofile';

import { useNavigate } from "react-router-dom";
import { Alert } from './components/Alert/Alert';
import CreateLp from './pages/launchpad/token/createlp';
import ProjectData from './config';
import Stake from './pages/NftStake/stakepool';
import Games from './pages/games/Games';
import SlotGame from './pages/games/SlotGame';
let projectName = "Martik"
const {
  Name,
  Logo,
  backgroundColor,
  buttonsColor,
  buttonsBorders,
  modalsColor,
  inputsColor,
  inputsBorders,
  outbuttonsColor,
  Borders,
  outFont,
  topFont,
  innerFont,
  innerBFont,
  menudata,
  Routers,
  logoUrl,
  info
} = ProjectData[window.location.host.split('.')[0] != undefined
  ? window.location.host.split('.')[0] == "app"
    ? window.location.host.split('.')[0] + "." + window.location.host.split('.')[1]
    : window.location.host.split('.')[0] : "Martik"]
const Web3 = require('web3-eth');
const w = window.innerWidth;



let sideli = {
  height: "50px",
  width: "100%",
  display: "grid",
  cursor: "pointer",
  paddingLeft: "10px",
  paddingRight: "10px",

}
let sidelis = {
  height: "50px",
  width: "100%",
  margin: "auto",
  textAlign: "left",
  display: "grid",
  gridTemplateColumns: "40px auto 25px",
  fontWeight: "bold",
  color: topFont == undefined ? outFont : topFont,
  fontSize: "13px",
  cursor: "pointer"

}
let sidelistop = {
  height: "70px",
  width: "100%",
  minWidth: "120px",
  margin: "auto",
  textAlign: "left",
  display: "flex",
  fontWeight: "bold",
  color: topFont == undefined ? outFont : topFont,
  fontSize: "13px",
  cursor: "pointer"

}
let logo = Logo

const WButton = () => {
  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        authenticationStatus,
        mounted,
      }) => {
        const ready = mounted && authenticationStatus !== 'loading';
        const connected =
          ready &&
          account &&
          chain &&
          (!authenticationStatus ||
            authenticationStatus === 'authenticated');

        return (
          <div
            {...(!ready && {
              'aria-hidden': true,
              'style': {
                opacity: 0,
                pointerEvents: 'none',
                userSelect: 'none',
              },
            })}
          >
            {(() => {
              if (!connected) {
                return (
                  <div id="wallet" className="Wallet" style={{ background: buttonsColor, border: buttonsBorders, color: topFont == undefined ? outFont : topFont }} onClick={openConnectModal}>
                    <svg style={{ height: "20px", width: "20px", margin: "auto", }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-wallet" viewBox="0 0 16 16">
                      <path d="M0 3a2 2 0 0 1 2-2h13.5a.5.5 0 0 1 0 1H15v2a1 1 0 0 1 1 1v8.5a1.5 1.5 0 0 1-1.5 1.5h-12A2.5 2.5 0 0 1 0 12.5V3zm1 1.732V12.5A1.5 1.5 0 0 0 2.5 14h12a.5.5 0 0 0 .5-.5V5H2a1.99 1.99 0 0 1-1-.268zM1 3a1 1 0 0 0 1 1h12V2H2a1 1 0 0 0-1 1z" />
                    </svg>
                    <h1>Connect Wallet</h1>
                  </div>
                );
              }

              if (chain.unsupported) {
                return (
                  <div id="wallet" className="Wallet" style={{ background: buttonsColor, border: buttonsBorders, color: topFont == undefined ? outFont : topFont }} onClick={openChainModal}>
                    <svg style={{ height: "20px", width: "20px", margin: "auto", }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-wallet" viewBox="0 0 16 16">
                      <path d="M0 3a2 2 0 0 1 2-2h13.5a.5.5 0 0 1 0 1H15v2a1 1 0 0 1 1 1v8.5a1.5 1.5 0 0 1-1.5 1.5h-12A2.5 2.5 0 0 1 0 12.5V3zm1 1.732V12.5A1.5 1.5 0 0 0 2.5 14h12a.5.5 0 0 0 .5-.5V5H2a1.99 1.99 0 0 1-1-.268zM1 3a1 1 0 0 0 1 1h12V2H2a1 1 0 0 0-1 1z" />
                    </svg>
                    <h1>Wrong network</h1>
                  </div>
                );
              }

              return (
                <div style={{ display: 'flex', gap: 12 }}>
                  <div onClick={openChainModal} style={{ cursor: "pointer", borderRadius: "5px ", padding: "7px", margin: "auto", background: buttonsColor, border: buttonsBorders, color: topFont == undefined ? outFont : topFont, display: "grid" }}>
                    <img
                      alt={chain.name ?? 'Chain icon'}
                      src={chain.iconUrl}
                      style={{ borderRadius: "5px ", width: "20px", height: "20px", margin: "auto" }}></img>
                  </div>

                  <div id="wallet" className="Wallet" style={{ background: buttonsColor, border: buttonsBorders, color: topFont == undefined ? outFont : topFont }} onClick={openAccountModal}>
                    <svg style={{ height: "20px", width: "20px", margin: "auto", }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-wallet" viewBox="0 0 16 16">
                      <path d="M0 3a2 2 0 0 1 2-2h13.5a.5.5 0 0 1 0 1H15v2a1 1 0 0 1 1 1v8.5a1.5 1.5 0 0 1-1.5 1.5h-12A2.5 2.5 0 0 1 0 12.5V3zm1 1.732V12.5A1.5 1.5 0 0 0 2.5 14h12a.5.5 0 0 0 .5-.5V5H2a1.99 1.99 0 0 1-1-.268zM1 3a1 1 0 0 0 1 1h12V2H2a1 1 0 0 0-1 1z" />
                    </svg>
                    <h1>
                      {account.displayName}<p />
                      {account.displayBalance
                        ? ` (${account.displayBalance})`
                        : ''}</h1>
                  </div>
                </div>
              );
            })()}
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
};


const Main = (props) => {
  const navigate = useNavigate();
  const [prices, setprice] = useState(0);
  const [menu, setmenu] = useState(w > 700);
  const [seconds, setSeconds] = useState(0);
  const [menuData, setMenuData] = useState([
    {
      name: "Trade",
      onClick: () => { setOpen(0) },
      open: false,
      icon: <svg style={{ height: "20px", width: "20px", margin: "auto" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left-right" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z" />
      </svg>,
      submenu: [
        {
          name: "Exchange",
          onClick: () => { navigate("/exchange?trade=MTK_USDT") }
        },
        {
          name: "Swap",
          onClick: () => { navigate("/swap") }
        },
        {
          name: "Add Lp v2",
          onClick: () => { navigate("/addlpv2") }
        },
        /*{
          name: "P2P",
          onClick: () => { navigate("/p2p") }
        },
        {
          name: "P2P Sell",
          onClick: () => { navigate("/p2p/sell") }
        },
        {
          name: "Smart Bridge",
          onClick: () => { navigate("/smart-bridge") }
        }*/
      ],
      submenuinfo: [
        {
          desc: "Trade your preferred token using our Cex/Dex",
          icon: <svg style={{ height: "20px", width: "20px", margin: "auto" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-boxes" viewBox="0 0 16 16">
            <path d="M7.752.066a.5.5 0 0 1 .496 0l3.75 2.143a.5.5 0 0 1 .252.434v3.995l3.498 2A.5.5 0 0 1 16 9.07v4.286a.5.5 0 0 1-.252.434l-3.75 2.143a.5.5 0 0 1-.496 0l-3.502-2-3.502 2.001a.5.5 0 0 1-.496 0l-3.75-2.143A.5.5 0 0 1 0 13.357V9.071a.5.5 0 0 1 .252-.434L3.75 6.638V2.643a.5.5 0 0 1 .252-.434L7.752.066ZM4.25 7.504 1.508 9.071l2.742 1.567 2.742-1.567L4.25 7.504ZM7.5 9.933l-2.75 1.571v3.134l2.75-1.571V9.933Zm1 3.134 2.75 1.571v-3.134L8.5 9.933v3.134Zm.508-3.996 2.742 1.567 2.742-1.567-2.742-1.567-2.742 1.567Zm2.242-2.433V3.504L8.5 5.076V8.21l2.75-1.572ZM7.5 8.21V5.076L4.75 3.504v3.134L7.5 8.21ZM5.258 2.643 8 4.21l2.742-1.567L8 1.076 5.258 2.643ZM15 9.933l-2.75 1.571v3.134L15 13.067V9.933ZM3.75 14.638v-3.134L1 9.933v3.134l2.75 1.571Z" />
          </svg>
        },
        {
          desc: "Trade your preferred token using our exchange system pegged with the world's largest pancake exchange dex",
          icon: <svg style={{ height: "20px", width: "20px", margin: "auto" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left-right" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z" />
          </svg>
        },
        {
          desc: "Add liquidity on Pancake (BSC) e MTKSwap (MTK) swap v2",
          icon: <svg style={{ height: "20px", width: "20px", margin: "auto" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">
            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
          </svg>
        },
        {
          desc: "Buy tokens directly from other people easy and fast",
          icon: <svg style={{ height: "20px", width: "20px", margin: "auto" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-people-fill" viewBox="0 0 16 16">
            <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7Zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm-5.784 6A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216ZM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
          </svg>
        },
        {
          desc: "Sell tokens directly from other people easy and fast",
          icon: <svg style={{ height: "20px", width: "20px", margin: "auto" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-currency-dollar" viewBox="0 0 16 16">
            <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z" />
          </svg>
        }/*,
      {
        name: "buy tokens directly from other people easy and fast",
        icon: <svg  style={{ height: "20px", width: "20px", margin: "auto" }}xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-people-fill" viewBox="0 0 16 16">
        <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7Zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm-5.784 6A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216ZM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"/>
      </svg>
      }*/]

    },
    {
      name: "Pools",
      onClick: () => { setOpen(1) },
      open: false,
      icon: <svg style={{ height: "20px", width: "20px", margin: "auto" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-piggy-bank" viewBox="0 0 16 16">
        <path d="M5 6.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0zm1.138-1.496A6.613 6.613 0 0 1 7.964 4.5c.666 0 1.303.097 1.893.273a.5.5 0 0 0 .286-.958A7.602 7.602 0 0 0 7.964 3.5c-.734 0-1.441.103-2.102.292a.5.5 0 1 0 .276.962z" />
        <path fillRule="evenodd" d="M7.964 1.527c-2.977 0-5.571 1.704-6.32 4.125h-.55A1 1 0 0 0 .11 6.824l.254 1.46a1.5 1.5 0 0 0 1.478 1.243h.263c.3.513.688.978 1.145 1.382l-.729 2.477a.5.5 0 0 0 .48.641h2a.5.5 0 0 0 .471-.332l.482-1.351c.635.173 1.31.267 2.011.267.707 0 1.388-.095 2.028-.272l.543 1.372a.5.5 0 0 0 .465.316h2a.5.5 0 0 0 .478-.645l-.761-2.506C13.81 9.895 14.5 8.559 14.5 7.069c0-.145-.007-.29-.02-.431.261-.11.508-.266.705-.444.315.306.815.306.815-.417 0 .223-.5.223-.461-.026a.95.95 0 0 0 .09-.255.7.7 0 0 0-.202-.645.58.58 0 0 0-.707-.098.735.735 0 0 0-.375.562c-.024.243.082.48.32.654a2.112 2.112 0 0 1-.259.153c-.534-2.664-3.284-4.595-6.442-4.595zM2.516 6.26c.455-2.066 2.667-3.733 5.448-3.733 3.146 0 5.536 2.114 5.536 4.542 0 1.254-.624 2.41-1.67 3.248a.5.5 0 0 0-.165.535l.66 2.175h-.985l-.59-1.487a.5.5 0 0 0-.629-.288c-.661.23-1.39.359-2.157.359a6.558 6.558 0 0 1-2.157-.359.5.5 0 0 0-.635.304l-.525 1.471h-.979l.633-2.15a.5.5 0 0 0-.17-.534 4.649 4.649 0 0 1-1.284-1.541.5.5 0 0 0-.446-.275h-.56a.5.5 0 0 1-.492-.414l-.254-1.46h.933a.5.5 0 0 0 .488-.393zm12.621-.857a.565.565 0 0 1-.098.21.704.704 0 0 1-.044-.025c-.146-.09-.157-.175-.152-.223a.236.236 0 0 1 .117-.173c.049-.027.08-.021.113.012a.202.202 0 0 1 .064.199z" />
      </svg>,
      submenu: [

        {
          name: "Stake Pools",
          onClick: () => { navigate("/stake") }
        },
        {
          name: "Multi Stake",
          onClick: () => { navigate("/multistake") }
        },
        {
          name: "Farm Pools",
          onClick: () => { navigate("/farms") }
        },
        {
          name: "Game Pools",
          onClick: () => { navigate("/pools/nfts") }
        },
      ],
      submenuinfo: [
        {
          desc: "Store your tokens in partner pools and earn rewards for it",
          icon: <svg style={{ height: "20px", width: "20px", margin: "auto" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-piggy-bank" viewBox="0 0 16 16">
            <path d="M5 6.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0zm1.138-1.496A6.613 6.613 0 0 1 7.964 4.5c.666 0 1.303.097 1.893.273a.5.5 0 0 0 .286-.958A7.602 7.602 0 0 0 7.964 3.5c-.734 0-1.441.103-2.102.292a.5.5 0 1 0 .276.962z" />
            <path fillRule="evenodd" d="M7.964 1.527c-2.977 0-5.571 1.704-6.32 4.125h-.55A1 1 0 0 0 .11 6.824l.254 1.46a1.5 1.5 0 0 0 1.478 1.243h.263c.3.513.688.978 1.145 1.382l-.729 2.477a.5.5 0 0 0 .48.641h2a.5.5 0 0 0 .471-.332l.482-1.351c.635.173 1.31.267 2.011.267.707 0 1.388-.095 2.028-.272l.543 1.372a.5.5 0 0 0 .465.316h2a.5.5 0 0 0 .478-.645l-.761-2.506C13.81 9.895 14.5 8.559 14.5 7.069c0-.145-.007-.29-.02-.431.261-.11.508-.266.705-.444.315.306.815.306.815-.417 0 .223-.5.223-.461-.026a.95.95 0 0 0 .09-.255.7.7 0 0 0-.202-.645.58.58 0 0 0-.707-.098.735.735 0 0 0-.375.562c-.024.243.082.48.32.654a2.112 2.112 0 0 1-.259.153c-.534-2.664-3.284-4.595-6.442-4.595zM2.516 6.26c.455-2.066 2.667-3.733 5.448-3.733 3.146 0 5.536 2.114 5.536 4.542 0 1.254-.624 2.41-1.67 3.248a.5.5 0 0 0-.165.535l.66 2.175h-.985l-.59-1.487a.5.5 0 0 0-.629-.288c-.661.23-1.39.359-2.157.359a6.558 6.558 0 0 1-2.157-.359.5.5 0 0 0-.635.304l-.525 1.471h-.979l.633-2.15a.5.5 0 0 0-.17-.534 4.649 4.649 0 0 1-1.284-1.541.5.5 0 0 0-.446-.275h-.56a.5.5 0 0 1-.492-.414l-.254-1.46h.933a.5.5 0 0 0 .488-.393zm12.621-.857a.565.565 0 0 1-.098.21.704.704 0 0 1-.044-.025c-.146-.09-.157-.175-.152-.223a.236.236 0 0 1 .117-.173c.049-.027.08-.021.113.012a.202.202 0 0 1 .064.199z" />
          </svg>
        },
        {
          desc: "Store your " + Name + " tokens in the pool and get various rewards for it",
          icon: <svg style={{ height: "20px", width: "20px", margin: "auto" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-piggy-bank" viewBox="0 0 16 16">
            <path d="M5 6.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0zm1.138-1.496A6.613 6.613 0 0 1 7.964 4.5c.666 0 1.303.097 1.893.273a.5.5 0 0 0 .286-.958A7.602 7.602 0 0 0 7.964 3.5c-.734 0-1.441.103-2.102.292a.5.5 0 1 0 .276.962z" />
            <path fillRule="evenodd" d="M7.964 1.527c-2.977 0-5.571 1.704-6.32 4.125h-.55A1 1 0 0 0 .11 6.824l.254 1.46a1.5 1.5 0 0 0 1.478 1.243h.263c.3.513.688.978 1.145 1.382l-.729 2.477a.5.5 0 0 0 .48.641h2a.5.5 0 0 0 .471-.332l.482-1.351c.635.173 1.31.267 2.011.267.707 0 1.388-.095 2.028-.272l.543 1.372a.5.5 0 0 0 .465.316h2a.5.5 0 0 0 .478-.645l-.761-2.506C13.81 9.895 14.5 8.559 14.5 7.069c0-.145-.007-.29-.02-.431.261-.11.508-.266.705-.444.315.306.815.306.815-.417 0 .223-.5.223-.461-.026a.95.95 0 0 0 .09-.255.7.7 0 0 0-.202-.645.58.58 0 0 0-.707-.098.735.735 0 0 0-.375.562c-.024.243.082.48.32.654a2.112 2.112 0 0 1-.259.153c-.534-2.664-3.284-4.595-6.442-4.595zM2.516 6.26c.455-2.066 2.667-3.733 5.448-3.733 3.146 0 5.536 2.114 5.536 4.542 0 1.254-.624 2.41-1.67 3.248a.5.5 0 0 0-.165.535l.66 2.175h-.985l-.59-1.487a.5.5 0 0 0-.629-.288c-.661.23-1.39.359-2.157.359a6.558 6.558 0 0 1-2.157-.359.5.5 0 0 0-.635.304l-.525 1.471h-.979l.633-2.15a.5.5 0 0 0-.17-.534 4.649 4.649 0 0 1-1.284-1.541.5.5 0 0 0-.446-.275h-.56a.5.5 0 0 1-.492-.414l-.254-1.46h.933a.5.5 0 0 0 .488-.393zm12.621-.857a.565.565 0 0 1-.098.21.704.704 0 0 1-.044-.025c-.146-.09-.157-.175-.152-.223a.236.236 0 0 1 .117-.173c.049-.027.08-.021.113.012a.202.202 0 0 1 .064.199z" />
          </svg>
        },
        {
          desc: "Store your LP in partner pools and earn rewards for it",
          icon: <svg style={{ height: "20px", width: "20px", margin: "auto" }} viewBox="0 0 24 24" width="24px" fill="currentColor" xmlns="http://www.w3.org/2000/svg" class="sc-bdnxRM bTMcNm"><path d="M13.7803 2.71967C14.0732 3.01256 14.0732 3.48744 13.7803 3.78033L12.8107 4.75L14.0307 5.96999H20C21.1 5.96999 22 6.86999 22 7.96999V12.73C21.28 12.25 20.43 11.97 19.5 11.97C17.19 11.97 15.3 13.73 15.05 15.97H11.91C11.96 15.64 12 15.31 12 14.97C12 13.43 11.41 12.03 10.46 10.97H11C12.1 10.97 13 10.07 13 8.96999V7.06068L11.75 5.81066L10.7803 6.78033C10.4874 7.07322 10.0126 7.07322 9.71967 6.78033C9.42678 6.48744 9.42678 6.01256 9.71967 5.71967L12.7197 2.71967C13.0126 2.42678 13.4874 2.42678 13.7803 2.71967Z"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M11 15C11 17.7614 8.76142 20 6 20C3.23858 20 1 17.7614 1 15C1 12.2386 3.23858 10 6 10C8.76142 10 11 12.2386 11 15ZM9 15C9 16.6569 7.65685 18 6 18C4.34315 18 3 16.6569 3 15C3 13.3431 4.34315 12 6 12C7.65685 12 9 13.3431 9 15Z"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M19.5 12.97C17.57 12.97 16 14.54 16 16.47C16 18.4 17.57 19.97 19.5 19.97C21.43 19.97 23 18.4 23 16.47C23 14.54 21.43 12.97 19.5 12.97ZM19.5 17.97C18.67 17.97 18 17.3 18 16.47C18 15.64 18.67 14.97 19.5 14.97C20.33 14.97 21 15.64 21 16.47C21 17.3 20.33 17.97 19.5 17.97Z"></path><path d="M9 8.96997H4C3.45 8.96997 3 8.52997 3 7.96997C3 7.41997 3.45 6.96997 4 6.96997H7C8.1 6.96997 9 7.86997 9 8.96997Z"></path></svg>
        },
        {
          desc: "Buy and store nfts on pools and earn rewards for it",
          icon: <svg style={{ height: "20px", width: "20px", margin: "auto" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-controller" viewBox="0 0 16 16">
            <path d="M11.5 6.027a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0m-1.5 1.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1m2.5-.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0m-1.5 1.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1m-6.5-3h1v1h1v1h-1v1h-1v-1h-1v-1h1z" />
            <path d="M3.051 3.26a.5.5 0 0 1 .354-.613l1.932-.518a.5.5 0 0 1 .62.39c.655-.079 1.35-.117 2.043-.117.72 0 1.443.041 2.12.126a.5.5 0 0 1 .622-.399l1.932.518a.5.5 0 0 1 .306.729q.211.136.373.297c.408.408.78 1.05 1.095 1.772.32.733.599 1.591.805 2.466s.34 1.78.364 2.606c.024.816-.059 1.602-.328 2.21a1.42 1.42 0 0 1-1.445.83c-.636-.067-1.115-.394-1.513-.773-.245-.232-.496-.526-.739-.808-.126-.148-.25-.292-.368-.423-.728-.804-1.597-1.527-3.224-1.527s-2.496.723-3.224 1.527c-.119.131-.242.275-.368.423-.243.282-.494.575-.739.808-.398.38-.877.706-1.513.773a1.42 1.42 0 0 1-1.445-.83c-.27-.608-.352-1.395-.329-2.21.024-.826.16-1.73.365-2.606.206-.875.486-1.733.805-2.466.315-.722.687-1.364 1.094-1.772a2.3 2.3 0 0 1 .433-.335l-.028-.079zm2.036.412c-.877.185-1.469.443-1.733.708-.276.276-.587.783-.885 1.465a14 14 0 0 0-.748 2.295 12.4 12.4 0 0 0-.339 2.406c-.022.755.062 1.368.243 1.776a.42.42 0 0 0 .426.24c.327-.034.61-.199.929-.502.212-.202.4-.423.615-.674.133-.156.276-.323.44-.504C4.861 9.969 5.978 9.027 8 9.027s3.139.942 3.965 1.855c.164.181.307.348.44.504.214.251.403.472.615.674.318.303.601.468.929.503a.42.42 0 0 0 .426-.241c.18-.408.265-1.02.243-1.776a12.4 12.4 0 0 0-.339-2.406 14 14 0 0 0-.748-2.295c-.298-.682-.61-1.19-.885-1.465-.264-.265-.856-.523-1.733-.708-.85-.179-1.877-.27-2.913-.27s-2.063.091-2.913.27" />
          </svg>
        }
      ]
    },
    {
      name: "Dev Hub",
      onClick: () => { setOpen(2) },
      open: false,
      icon: <svg style={{ height: "20px", width: "20px", margin: "auto" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-text" viewBox="0 0 16 16">
        <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z" />
        <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
      </svg>,
      submenu: [
        {
          name: "Dev manager",
          onClick: () => { navigate("/manager/") }
        },
        {
          name: "Create token",
          onClick: () => { navigate("/create/contract") }
        },
        {
          name: "Create Stake Pool",
          onClick: () => { navigate("/stake/create") }
        },
        {
          name: "Create Farm Pool (soon)",
          onClick: () => { navigate("/") }
        }, {
          name: "KYC & Audit",
          onClick: () => { navigate('/projects') }
        }, {
          name: "Cex Listing",
          onClick: () => { window.open("https://t.me/fabiano_cointiger") }
        },


      ],
      submenuinfo: [
        {
          desc: "Dev token manager with all tools and blockchain security",
          icon: <svg style={{ height: "20px", width: "20px", margin: "auto" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-text" viewBox="0 0 16 16">
            <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z" />
            <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
          </svg>,
        },

        {
          desc: "An easy way to create your token with security and the quality of " + Name + "  devs",
          icon: <svg style={{ height: "20px", width: "20px", margin: "auto" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-text" viewBox="0 0 16 16">
            <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z" />
            <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
          </svg>,
        },
        {
          desc: "An easy way to create your stake pool, use yours or our platform for your holders to have access",
          icon: <svg style={{ height: "20px", width: "20px", margin: "auto" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-text" viewBox="0 0 16 16">
            <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z" />
            <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
          </svg>,
        },
        {
          desc: "An easy way to create your farm pool, use yours or our platform for your holders to have access",
          icon: <svg style={{ height: "20px", width: "20px", margin: "auto" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-text" viewBox="0 0 16 16">
            <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z" />
            <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
          </svg>,
        },
        {
          desc: "Smartk contract audits, line-by-line, kyc service",
          icon: <svg style={{ height: "20px", width: "20px", margin: "auto" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-text" viewBox="0 0 16 16">
            <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z" />
            <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
          </svg>
        },
        {
          desc: "Cointiger Listing, Bitmart Listing, CMC/CG Listing, KYC & Audit requests",
          icon: <svg style={{ height: "20px", width: "20px", margin: "auto" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-text" viewBox="0 0 16 16">
            <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z" />
            <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
          </svg>
        }]
    },
    {
      name: "Launchpad",
      onClick: () => { setOpen(3) },
      open: false,
      icon: <svg style={{ height: "20px", width: "20px", margin: "auto" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-rocket-takeoff" viewBox="0 0 16 16">
        <path d="M9.752 6.193c.599.6 1.73.437 2.528-.362.798-.799.96-1.932.362-2.531-.599-.6-1.73-.438-2.528.361-.798.8-.96 1.933-.362 2.532Z" />
        <path d="M15.811 3.312c-.363 1.534-1.334 3.626-3.64 6.218l-.24 2.408a2.56 2.56 0 0 1-.732 1.526L8.817 15.85a.51.51 0 0 1-.867-.434l.27-1.899c.04-.28-.013-.593-.131-.956a9.42 9.42 0 0 0-.249-.657l-.082-.202c-.815-.197-1.578-.662-2.191-1.277-.614-.615-1.079-1.379-1.275-2.195l-.203-.083a9.556 9.556 0 0 0-.655-.248c-.363-.119-.675-.172-.955-.132l-1.896.27A.51.51 0 0 1 .15 7.17l2.382-2.386c.41-.41.947-.67 1.524-.734h.006l2.4-.238C9.005 1.55 11.087.582 12.623.208c.89-.217 1.59-.232 2.08-.188.244.023.435.06.57.093.067.017.12.033.16.045.184.06.279.13.351.295l.029.073a3.475 3.475 0 0 1 .157.721c.055.485.051 1.178-.159 2.065Zm-4.828 7.475.04-.04-.107 1.081a1.536 1.536 0 0 1-.44.913l-1.298 1.3.054-.38c.072-.506-.034-.993-.172-1.418a8.548 8.548 0 0 0-.164-.45c.738-.065 1.462-.38 2.087-1.006ZM5.205 5c-.625.626-.94 1.351-1.004 2.09a8.497 8.497 0 0 0-.45-.164c-.424-.138-.91-.244-1.416-.172l-.38.054 1.3-1.3c.245-.246.566-.401.91-.44l1.08-.107-.04.039Zm9.406-3.961c-.38-.034-.967-.027-1.746.163-1.558.38-3.917 1.496-6.937 4.521-.62.62-.799 1.34-.687 2.051.107.676.483 1.362 1.048 1.928.564.565 1.25.941 1.924 1.049.71.112 1.429-.067 2.048-.688 3.079-3.083 4.192-5.444 4.556-6.987.183-.771.18-1.345.138-1.713a2.835 2.835 0 0 0-.045-.283 3.078 3.078 0 0 0-.3-.041Z" />
        <path d="M7.009 12.139a7.632 7.632 0 0 1-1.804-1.352A7.568 7.568 0 0 1 3.794 8.86c-1.102.992-1.965 5.054-1.839 5.18.125.126 3.936-.896 5.054-1.902Z" />
      </svg>,
      submenu: [
        {
          name: "Create Launchpad",
          onClick: () => { navigate("/create/pre-sale") }
        },
        {
          name: "Create fair launchpad",
          onClick: () => { navigate("/create/fair-launch") }
        },
        {
          name: "Create Private Sale",
          onClick: () => { navigate("/create/private-sale") }
        },
        {
          name: "Create Pump Sale",
          onClick: () => { navigate("/create/pump-sale") }
        },
        {
          name: "Private Sale List",
          onClick: () => { navigate("/private-sales") }
        },
        {
          name: "Launchpad list",
          onClick: () => { navigate("/pre-sales") }
        },
        {
          name: "Fairlaunch list",
          onClick: () => { navigate("/fair-launchs") }
        },
        {
          name: "Pump Sale list",
          onClick: () => { navigate("/pump-sales") }
        },

      ],
      submenuinfo: [
        {
          desc: "Create a Pre Sale to your token and make a big launch",
          icon: <svg style={{ height: "20px", width: "20px", margin: "auto" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-text" viewBox="0 0 16 16">
            <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z" />
            <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
          </svg>
        },
        {
          desc: "Create a Fair launch to your token and make a fair launch ",
          icon: <svg style={{ height: "20px", width: "20px", margin: "auto" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-text" viewBox="0 0 16 16">
            <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z" />
            <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
          </svg>
        },
        {
          desc: "Create a Private Sale to your token and accumulate funds for your project",
          icon: <svg style={{ height: "20px", width: "20px", margin: "auto" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-text" viewBox="0 0 16 16">
            <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z" />
            <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
          </svg>
        },
        {
          desc: "Create a Pump Sale to your token and accumulate funds for your project",
          icon: <svg style={{ height: "20px", width: "20px", margin: "auto" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-text" viewBox="0 0 16 16">
            <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z" />
            <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
          </svg>
        },
        {
          desc: "All Pre Sale projects",
          icon: <svg style={{ height: "20px", width: "20px", margin: "auto" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-text" viewBox="0 0 16 16">
            <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z" />
            <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
          </svg>
        },
        {
          desc: "All Fair launch projects",
          icon: <svg style={{ height: "20px", width: "20px", margin: "auto" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-text" viewBox="0 0 16 16">
            <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z" />
            <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
          </svg>
        },
        {
          desc: "All Private Sale projects",
          icon: <svg style={{ height: "20px", width: "20px", margin: "auto" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-text" viewBox="0 0 16 16">
            <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z" />
            <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
          </svg>
        },
        {
          desc: "All Pump Sale projects",
          icon: <svg style={{ height: "20px", width: "20px", margin: "auto" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-text" viewBox="0 0 16 16">
            <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z" />
            <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
          </svg>
        },
        {
          name: "Example",
          icon: <svg style={{ height: "20px", width: "20px", margin: "auto" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-text" viewBox="0 0 16 16">
            <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z" />
            <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
          </svg>
        },
        {
          name: "Example",
          icon: <svg style={{ height: "20px", width: "20px", margin: "auto" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-text" viewBox="0 0 16 16">
            <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z" />
            <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
          </svg>
        },
        {
          name: "Example",
          icon: <svg style={{ height: "20px", width: "20px", margin: "auto" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-text" viewBox="0 0 16 16">
            <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z" />
            <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
          </svg>
        },
      ]
    },
    {
      name: Name + "  lock",
      onClick: () => { setOpen(4) },
      open: false,
      icon: <svg style={{ height: "20px", width: "20px", margin: "auto" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-shield" viewBox="0 0 16 16">
        <path d="M5.338 1.59a61.44 61.44 0 0 0-2.837.856.481.481 0 0 0-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.725 10.725 0 0 0 2.287 2.233c.346.244.652.42.893.533.12.057.218.095.293.118a.55.55 0 0 0 .101.025.615.615 0 0 0 .1-.025c.076-.023.174-.061.294-.118.24-.113.547-.29.893-.533a10.726 10.726 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.775 11.775 0 0 1-2.517 2.453 7.159 7.159 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7.158 7.158 0 0 1-1.048-.625 11.777 11.777 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 62.456 62.456 0 0 1 5.072.56z" />
      </svg>,
      submenu: [
        {
          name: "Create Lock",
          onClick: () => { navigate("/lock/create") }
        },
        {
          name: "Lock list",
          onClick: () => { navigate("/lock") }
        }
      ],
      submenuinfo: [
        {
          desc: "Security and free lock of lp and tokens on " + Name + "  lock",
          icon: <svg style={{ height: "20px", width: "20px", margin: "auto" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-shield" viewBox="0 0 16 16">
            <path d="M5.338 1.59a61.44 61.44 0 0 0-2.837.856.481.481 0 0 0-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.725 10.725 0 0 0 2.287 2.233c.346.244.652.42.893.533.12.057.218.095.293.118a.55.55 0 0 0 .101.025.615.615 0 0 0 .1-.025c.076-.023.174-.061.294-.118.24-.113.547-.29.893-.533a10.726 10.726 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.775 11.775 0 0 1-2.517 2.453 7.159 7.159 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7.158 7.158 0 0 1-1.048-.625 11.777 11.777 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 62.456 62.456 0 0 1 5.072.56z" />
          </svg>
        },
        {
          desc: "List from all locks on our platform",
          icon: <svg style={{ height: "20px", width: "20px", margin: "auto" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-shield" viewBox="0 0 16 16">
            <path d="M5.338 1.59a61.44 61.44 0 0 0-2.837.856.481.481 0 0 0-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.725 10.725 0 0 0 2.287 2.233c.346.244.652.42.893.533.12.057.218.095.293.118a.55.55 0 0 0 .101.025.615.615 0 0 0 .1-.025c.076-.023.174-.061.294-.118.24-.113.547-.29.893-.533a10.726 10.726 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.775 11.775 0 0 1-2.517 2.453 7.159 7.159 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7.158 7.158 0 0 1-1.048-.625 11.777 11.777 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 62.456 62.456 0 0 1 5.072.56z" />
          </svg>
        }]

    },
    {
      name: "Marketplace",
      onClick: () => { setOpen(5) },
      open: false,
      icon: <svg style={{ height: "20px", width: "20px", margin: "auto" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-shop" viewBox="0 0 16 16">
        <path d="M2.97 1.35A1 1 0 0 1 3.73 1h8.54a1 1 0 0 1 .76.35l2.609 3.044A1.5 1.5 0 0 1 16 5.37v.255a2.375 2.375 0 0 1-4.25 1.458A2.371 2.371 0 0 1 9.875 8 2.37 2.37 0 0 1 8 7.083 2.37 2.37 0 0 1 6.125 8a2.37 2.37 0 0 1-1.875-.917A2.375 2.375 0 0 1 0 5.625V5.37a1.5 1.5 0 0 1 .361-.976l2.61-3.045zm1.78 4.275a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 1 0 2.75 0V5.37a.5.5 0 0 0-.12-.325L12.27 2H3.73L1.12 5.045A.5.5 0 0 0 1 5.37v.255a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0zM1.5 8.5A.5.5 0 0 1 2 9v6h1v-5a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v5h6V9a.5.5 0 0 1 1 0v6h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1V9a.5.5 0 0 1 .5-.5zM4 15h3v-5H4v5zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-3zm3 0h-2v3h2v-3z" />
      </svg>,
      submenu: [
        {
          name: "Marketplace",
          onClick: () => { navigate("/marketplace") }
        },
        {
          name: "Publish NFT",
          onClick: () => { navigate("/marketplace/publish") }
        },
        {
          name: "Create NFT",
          onClick: () => { navigate("/marketplace/create") }
        },
        {
          name: "Profile Editor",
          onClick: () => { navigate("/marketplace/editor/profile") }
        }
      ],
      submenuinfo: [
        {
          desc: "Buy nfts from others users",
          icon: <svg style={{ height: "20px", width: "20px", margin: "auto" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-shop" viewBox="0 0 16 16">
            <path d="M2.97 1.35A1 1 0 0 1 3.73 1h8.54a1 1 0 0 1 .76.35l2.609 3.044A1.5 1.5 0 0 1 16 5.37v.255a2.375 2.375 0 0 1-4.25 1.458A2.37 2.37 0 0 1 9.875 8 2.37 2.37 0 0 1 8 7.083 2.37 2.37 0 0 1 6.125 8a2.37 2.37 0 0 1-1.875-.917A2.375 2.375 0 0 1 0 5.625V5.37a1.5 1.5 0 0 1 .361-.976zm1.78 4.275a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 1 0 2.75 0V5.37a.5.5 0 0 0-.12-.325L12.27 2H3.73L1.12 5.045A.5.5 0 0 0 1 5.37v.255a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0M1.5 8.5A.5.5 0 0 1 2 9v6h1v-5a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v5h6V9a.5.5 0 0 1 1 0v6h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1V9a.5.5 0 0 1 .5-.5M4 15h3v-5H4zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1zm3 0h-2v3h2z" />
          </svg>
        },
        {
          desc: "Publish nfts to sell and earn money",
          icon: <svg style={{ height: "20px", width: "20px", margin: "auto" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-megaphone" viewBox="0 0 16 16">
            <path d="M13 2.5a1.5 1.5 0 0 1 3 0v11a1.5 1.5 0 0 1-3 0v-.214c-2.162-1.241-4.49-1.843-6.912-2.083l.405 2.712A1 1 0 0 1 5.51 15.1h-.548a1 1 0 0 1-.916-.599l-1.85-3.49-.202-.003A2.014 2.014 0 0 1 0 9V7a2.02 2.02 0 0 1 1.992-2.013 75 75 0 0 0 2.483-.075c3.043-.154 6.148-.849 8.525-2.199zm1 0v11a.5.5 0 0 0 1 0v-11a.5.5 0 0 0-1 0m-1 1.35c-2.344 1.205-5.209 1.842-8 2.033v4.233q.27.015.537.036c2.568.189 5.093.744 7.463 1.993zm-9 6.215v-4.13a95 95 0 0 1-1.992.052A1.02 1.02 0 0 0 1 7v2c0 .55.448 1.002 1.006 1.009A61 61 0 0 1 4 10.065m-.657.975 1.609 3.037.01.024h.548l-.002-.014-.443-2.966a68 68 0 0 0-1.722-.082z" />
          </svg>
        },
        {
          name: "Example",
          icon: <svg style={{ height: "20px", width: "20px", margin: "auto" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-text" viewBox="0 0 16 16">
            <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z" />
            <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
          </svg>
        },
        {
          name: "Example",
          icon: <svg style={{ height: "20px", width: "20px", margin: "auto" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-text" viewBox="0 0 16 16">
            <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z" />
            <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
          </svg>
        }]

    },
    {
      name: "Docs",
      onClick: () => { setOpen(6) },
      icon: <i style={{ height: "20px", width: "20px", margin: "auto" }}></i>,
      open: false,
      submenu: [
        {
          name: "Whitepaper",
          onClick: () => { navigate("/whitepaper") }
        },
        {
          name: "Terms & Privacy",
          onClick: () => { navigate("/terms") }
        },
        {
          name: "contact@martik.site",
          onClick: () => { window.open("mailto:contact@martik.site?subject=Hello%20Martik developers ...") }
        }],
      submenuinfo: [
        {
          desc: "All " + Name + "  information",
          icon: <svg style={{ height: "20px", width: "20px", margin: "auto" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-text" viewBox="0 0 16 16">
            <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z" />
            <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
          </svg>
        },
        {
          desc: "Detailed information on how you should understand the use of the platform",
          icon: <svg style={{ height: "20px", width: "20px", margin: "auto" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-text" viewBox="0 0 16 16">
            <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z" />
            <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
          </svg>
        },
        {
          name: "Our institutional email",
          icon: <svg style={{ height: "20px", width: "20px", margin: "auto" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope-at" viewBox="0 0 16 16">
            <path d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2H2Zm3.708 6.208L1 11.105V5.383l4.708 2.825ZM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2-7-4.2Z" />
            <path d="M14.247 14.269c1.01 0 1.587-.857 1.587-2.025v-.21C15.834 10.43 14.64 9 12.52 9h-.035C10.42 9 9 10.36 9 12.432v.214C9 14.82 10.438 16 12.358 16h.044c.594 0 1.018-.074 1.237-.175v-.73c-.245.11-.673.18-1.18.18h-.044c-1.334 0-2.571-.788-2.571-2.655v-.157c0-1.657 1.058-2.724 2.64-2.724h.04c1.535 0 2.484 1.05 2.484 2.326v.118c0 .975-.324 1.39-.639 1.39-.232 0-.41-.148-.41-.42v-2.19h-.906v.569h-.03c-.084-.298-.368-.63-.954-.63-.778 0-1.259.555-1.259 1.4v.528c0 .892.49 1.434 1.26 1.434.471 0 .896-.227 1.014-.643h.043c.118.42.617.648 1.12.648Zm-2.453-1.588v-.227c0-.546.227-.791.573-.791.297 0 .572.192.572.708v.367c0 .573-.253.744-.564.744-.354 0-.581-.215-.581-.8Z" />
          </svg>
        }]
    },
    /* {
       name: "MartikChain",
       onClick: () => { navigate("/explorer") },
       icon: <i style={{ height: "20px", width: "20px", margin: "auto" }}></i>,
       open: false,
       submenu: [
       ]
  
     }*/
  ]);

  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = logoUrl;
    document.title = (Name).toUpperCase() + getPATH()
  }, [window.location.pathname]);

  const getPATH = () => {
    if (window.location.pathname.includes("/")) {
      let f = window.location.pathname.split("/")
      let string = ""
      for (let index = 0; index < f.length; index++) {
        string = string.concat((" " + f[index]).toUpperCase())
      }
      return string
    } else {
      return ""
    }
  }

  const setOpen = (pos) => {
    let _menuData = menuData;
    _menuData[pos].open = !_menuData[pos].open
    setMenuData(_menuData);
  }
  const SubOption = (c) => {
    return (
      <div
        onClick={() => c.obj.onClick()}
        style={{
          height: "min-content",
          width: "100%",
          margin: "auto",
          textAlign: "left",
          color: topFont == undefined ? outFont : topFont,
          padding: "10px",
          paddingLeft: "40px",
          fontSize: "14px",
          cursor: "pointer",
          //  background: modalsColor
        }}>{c.obj.name}
      </div>
    );
  }

  const Option = (props) => {
    const [num, setNum] = useState(0);
    useEffect(() => {
      let interval = setInterval(() => {
        setNum(num + 1)
      }, 100);

      return () => clearInterval(interval);
    }, [num]);
    return (
      <>
        {
          menudata[props.index]?.permit &&
          <>
            <div onClick={() => { props.objm.onClick() }} style={sidelis}>
              <div style={sideli} >
                {props.objm.icon}
              </div>
              <p style={{ margin: "auto", marginLeft: "0px" }}>{props.objm.name}</p>

              {props.objm.submenu.length > 0 && <svg style={{ height: "10px", width: "10px", margin: "auto", rotate: props.objm.open ? "0deg" : "270deg" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
              </svg>}
            </div>
            {(props.objm.submenu.length > 0 && props.objm.open) && <div style={{}}>
              {props.objm.submenu.map((e, i) => menudata[props.index].subpermit[i]?.permit && <SubOption obj={e} />)}
            </div>}
          </>
        }

      </>
    )
  }


  const SubOptionTop = (c) => {
    return (
      <div className="itemtohover" onClick={() => c.obj.onClick()} style={{
        height: "35px",
        width: "200px",
        margin: "auto",
        textAlign: "left",
        display: "grid",
        gridTemplateColumns: "25px auto",
        fontWeight: "bold",
        color: topFont == undefined ? outFont : topFont,
        fontSize: "13px",
        cursor: "pointer",
        background: Borders,
        borderRadius: "10px"
      }}>
        <div style={{
          height: "min-content",
          width: "100%",
          display: "grid",
          cursor: "pointer",
          paddingLeft: "10px",
          paddingRight: "10px",
          margin: "auto"
        }} >
          {c.info != undefined && c.info.icon}
        </div>
        <div style={{
          height: "min-content",
          width: "100%",
          display: "grid",
          cursor: "pointer",
          paddingLeft: "10px",
          paddingRight: "10px",
          margin: "auto",

        }}>
          <p style={{ fontWeight: "none", fontSize: "12px" }}>{c.obj.name}</p>

        </div>
      </div>
    );
  }

  const OptionTop = (props) => {
    const [num, setNum] = useState(0);
    useEffect(() => {
      let interval = setInterval(() => {
        setNum(num + 1)
      }, 1000);

      return () => clearInterval(interval);
    }, [num]);
    // if(menudata[props.index].permit == undefined)
    return (
      <>
        {
          menudata[props.index]?.permit &&
          <>
            <div className='menuitem' onClick={() => { props.objm.onClick() }} style={sidelistop}>
              <p style={{ margin: "auto", marginLeft: "0px", fontWeight: "none", }}>{props.objm.name}</p>
              {props.objm.submenu.length > 0 && <svg style={{ height: "10px", width: "10px", margin: "auto", rotate: true ? "0deg" : "270deg" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
              </svg>}
              {(props.objm.submenu.length > 0) && <div className='menuitem onItemHover' style={{

                gap: "10px", padding: "5px", background: buttonsColor, position: "absolute", top: "70px", maxWidth: "600px",
                border: "1px solid" + Borders, gridTemplateColumns: "auto ", borderRadius: "10px"
              }}>
                {props.objm.submenu.map((e, i) => menudata[props.index].subpermit[i]?.permit && <SubOptionTop obj={e} info={props.objm.submenuinfo[i]} />)}
              </div>}
            </div>
          </>}
      </>
    )
  }
  function nextblock(accountBalancemctTB, d) {
    return accountBalancemctTB == undefined ? 0 : parseFloat(ethers.utils.formatUnits(accountBalancemctTB, d)).toFixed(2)
  }

  async function price() {
    const web3 = new Web3(new Web3.providers.HttpProvider("https://bsc-dataseed.binance.org"));
    const cake = await new web3.Contract(cakeabi, '0x10ed43c718714eb63d5aa57b78b54704e256024e');
    const p0 = await cake.methods.getAmountsOut("1" + "0".repeat(18), ['0x116526135380E28836C6080f1997645d5A807FAE', "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c"]).call()
    const p1 = await cake.methods.getAmountsOut(p0[1], ["0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c", '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56']).call()
    setprice(p1[1])
  }

  function menustyle(bool) {
    return <div style={{
      height: "70px",
      width: "100%",
      margin: "auto",
      overflow: "hidden",
      display: "grid",
      gridTemplateColumns: "150px auto 200px",

    }}>
      <div style={{
        height: "50px",
        width: "100%",
        overflow: "hidden",
        display: "grid",
        gridTemplateColumns: "50% 50%",
        cursor: "pointer",
        margin: "auto"
      }}>
        <svg onClick={() => { setmenu(!menu) }} style={{
          height: "30px",
          width: "30px",
          margin: "auto",
          fill: topFont == undefined ? outFont : topFont
        }} viewBox="0 0 24 24" width="24px" color="textSubtle" xmlns="http://www.w3.org/2000/svg" className="sc-bdnxRM cSawQi"><path d="M4 18H15C15.55 18 16 17.55 16 17C16 16.45 15.55 16 15 16H4C3.45 16 3 16.45 3 17C3 17.55 3.45 18 4 18ZM4 13H12C12.55 13 13 12.55 13 12C13 11.45 12.55 11 12 11H4C3.45 11 3 11.45 3 12C3 12.55 3.45 13 4 13ZM3 7C3 7.55 3.45 8 4 8H15C15.55 8 16 7.55 16 7C16 6.45 15.55 6 15 6H4C3.45 6 3 6.45 3 7ZM20.3 14.88L17.42 12L20.3 9.12C20.69 8.73 20.69 8.1 20.3 7.71C19.91 7.32 19.28 7.32 18.89 7.71L15.3 11.3C14.91 11.69 14.91 12.32 15.3 12.71L18.89 16.3C19.28 16.69 19.91 16.69 20.3 16.3C20.68 15.91 20.69 15.27 20.3 14.88Z" /></svg>
        {logo}
      </div>
      <div></div>
      <div style={{
        display: "grid", gridTemplateColumns: "auto", height: "min-content", width: "100%", margin: "auto",
        borderRadius: "10px"
      }}>
        <WButton />
      </div>
    </div>
  }

  function checkpathtitle(e) {
    let a = window.location.pathname.toString().split("/");

    if (window.location.pathname == "/") {
      return ""
    } else {
      if (e.toString().toUpperCase() == (window.location.pathname.toString().split("/")[a.length - 1]).toUpperCase()) {
        return ""
      } else {
        return "/" + (window.location.pathname.toString().split("/")[a.length - 1]).toUpperCase()
      }
    }
  }

  const Sidemenu = () => {
    return (
      <div style={{
        height: window.innerHeight - 100,
        width: "260px",
        color: topFont == undefined ? outFont : topFont,
        position: w < 720 ? "absolute" : "absolute",
        top: "0px",
        zIndex: "10000",
        borderRight: "1px solid" + Borders,
        background: w < 720 ? buttonsColor : "",
        bottom: "0",
        display: "grid",
      }}>
        <div style={{
          height: window.innerHeight - 100,
          overflowY: "auto",
          overflowX: "hidden",
        }}>
          <div style={{
            height: "70px",
            width: "100%",
            overflow: "hidden",
            display: "grid",
            gridTemplateColumns: w > 720 ? "70px auto" : "70px auto 70px",
            cursor: "pointer"
          }}>
            {logo}
            <p onClick={() => { window.location.href = "/" }} style={{
              height: "min-content",
              width: "100%",
              margin: "auto",
              textAlign: "left",
              fontWeight: "bold",
              fontSize: "18px",
            }}>{Name} </p>
            {w < 720 && <svg onClick={() => { setmenu(!menu) }} style={{
              height: "30px",
              width: "30px",
              margin: "auto",
              fill: topFont == undefined ? outFont : topFont
            }} viewBox="0 0 24 24" width="24px" color="textSubtle" xmlns="http://www.w3.org/2000/svg" className="sc-bdnxRM cSawQi"><path d="M4 18H15C15.55 18 16 17.55 16 17C16 16.45 15.55 16 15 16H4C3.45 16 3 16.45 3 17C3 17.55 3.45 18 4 18ZM4 13H12C12.55 13 13 12.55 13 12C13 11.45 12.55 11 12 11H4C3.45 11 3 11.45 3 12C3 12.55 3.45 13 4 13ZM3 7C3 7.55 3.45 8 4 8H15C15.55 8 16 7.55 16 7C16 6.45 15.55 6 15 6H4C3.45 6 3 6.45 3 7ZM20.3 14.88L17.42 12L20.3 9.12C20.69 8.73 20.69 8.1 20.3 7.71C19.91 7.32 19.28 7.32 18.89 7.71L15.3 11.3C14.91 11.69 14.91 12.32 15.3 12.71L18.89 16.3C19.28 16.69 19.91 16.69 20.3 16.3C20.68 15.91 20.69 15.27 20.3 14.88Z" /></svg>}

          </div>

          {menuData.map((e, i) => <Option objm={e} index={i} />)}
        </div>
        <div style={{
          height: "100px",
          width: "100%",
          display: "grid",
          gridTemplateColumns: "auto",
          background: buttonsColor,
          color: topFont == undefined ? outFont : topFont,
          padding: "0 1vh",
          borderTop: "1px solid " + Borders
        }}>
          {false && <div onClick={() => { }} style={{
            display: "grid", gridTemplateColumns: "50px auto", height: "50px", width: "100%", margin: "auto",
            background: "#00000050", borderRadius: "10px"
          }}>
            {logo}
            <p style={{ fontSize: "15px", padding: "0 1vh", textAlign: "right", height: "min-content", margin: "auto 0", fontWeight: "bold" }}>${(nextblock(prices, 18))} USD</p>
          </div>}
          <div style={{
            display: "grid",
            gridTemplateColumns: "auto auto"
          }}>
            <svg onClick={() => { window.open(info.telegram) }} viewBox="0 0 24 24" width="24px" color="textSubtle" xmlns="http://www.w3.org/2000/svg" className="sc-bdnxRM jgodFW" style={{ cursor: 'pointer', margin: "auto", height: "30px", width: "30px", fill: topFont == undefined ? outFont : topFont }}><path d="M12 3C7.02985 3 3 7.02985 3 11.9904C3 16.9606 7.02985 21 12 21C16.9701 21 21 16.9606 21 11.9904C21 7.02985 16.9701 3 12 3ZM16.4328 8.65139C16.3753 9.48614 14.8305 15.7228 14.8305 15.7228C14.8305 15.7228 14.7345 16.0874 14.3987 16.097C14.2836 16.097 14.1301 16.0874 13.9574 15.9243C13.6023 15.6269 12.7964 15.0512 12.0384 14.5235C12.0096 14.5522 11.9808 14.581 11.9424 14.6098C11.7697 14.7633 11.5107 14.984 11.2324 15.2527C11.1269 15.3486 11.0117 15.4542 10.8966 15.5693L10.887 15.5789C10.8198 15.6461 10.7623 15.694 10.7143 15.7324C10.3401 16.0394 10.3017 15.7804 10.3017 15.6461L10.5032 13.4488V13.4296L10.5128 13.4104C10.5224 13.3817 10.5416 13.3721 10.5416 13.3721C10.5416 13.3721 14.4659 9.87953 14.5714 9.50533C14.581 9.48614 14.5522 9.46695 14.5043 9.48614C14.2452 9.57249 9.72601 12.4318 9.22708 12.7484C9.19829 12.7676 9.11194 12.758 9.11194 12.758L6.91471 12.0384C6.91471 12.0384 6.65565 11.9328 6.742 11.693C6.76119 11.645 6.78998 11.597 6.89552 11.5299C7.38486 11.1844 15.8955 8.12367 15.8955 8.12367C15.8955 8.12367 16.1354 8.04691 16.2793 8.09488C16.3465 8.12367 16.3849 8.15245 16.4232 8.2484C16.4328 8.28678 16.4424 8.36354 16.4424 8.44989C16.4424 8.49787 16.4328 8.55544 16.4328 8.65139Z" /></svg>
            <svg onClick={() => { window.open(info.twitter) }} viewBox="0 0 24 24" width="24px" color="textSubtle" xmlns="http://www.w3.org/2000/svg" className="sc-bdnxRM jgodFW" style={{ cursor: 'pointer', margin: "auto", height: "30px", width: "30px", fill: topFont == undefined ? outFont : topFont }}><path d="M8.65854 19C15.4488 19 19.1659 13.2338 19.1659 8.23717C19.1659 8.07728 19.1659 7.91739 19.1561 7.7475C19.878 7.21785 20.5024 6.5483 21 5.78881C20.3366 6.08861 19.6244 6.28847 18.8732 6.38841C19.6341 5.91872 20.2195 5.17921 20.5024 4.28981C19.7902 4.72951 19 5.03931 18.1512 5.20919C17.478 4.45969 16.522 4 15.4585 4C13.4195 4 11.761 5.69887 11.761 7.78747C11.761 8.08728 11.8 8.36709 11.8585 8.6469C8.79512 8.497 6.07317 6.97801 4.24878 4.68954C3.92683 5.25916 3.75122 5.90873 3.75122 6.59827C3.75122 7.90739 4.40488 9.06662 5.39024 9.74617C4.78537 9.72618 4.21951 9.5563 3.72195 9.27648C3.72195 9.28648 3.72195 9.30646 3.72195 9.32645C3.72195 11.1552 5 12.6942 6.67805 13.034C6.36585 13.1239 6.0439 13.1639 5.70244 13.1639C5.46829 13.1639 5.23415 13.1439 5.00976 13.0939C5.47805 14.6029 6.8439 15.6922 8.46341 15.7222C7.19512 16.7415 5.60488 17.3411 3.87805 17.3411C3.58537 17.3411 3.28293 17.3311 3 17.2911C4.62927 18.3704 6.57073 19 8.65854 19Z" /></svg>
          </div>
        </div>
      </div>
    )
  }
  const Topmenu = () => {
    return (
      <>
        <div style={{
          height: w >= 1024 ? "70px" : "100px",
          width: "100%",
          color: topFont == undefined ? outFont : topFont,
          position: "absolute",
          top: "0px",
          zIndex: "10000",
          borderBottom: "1px solid" + Borders,
          bottom: "0",
          display: "grid",
          gridTemplateColumns: "auto min-content 200px",
          gridTemplateRows: w >= 1024 ? "auto" : "70px 30px",
          background: buttonsColor
        }}>
          <div style={{
            height: "70px",
            width: "200px",
            display: "grid",
            gridTemplateColumns: w > 720 ? "70px auto" : "70px auto 70px",
            cursor: "pointer"
          }}>
            {logo}
            <p onClick={() => { window.location.href = "/" }} style={{
              height: "min-content",
              width: "100%",
              margin: "auto",
              textAlign: "left",
              fontWeight: "bold",
              fontSize: "18px",
            }}>{Name} </p>

          </div>
          {
            w >= 1024 ? <div
              style={{
                height: "min-content",
                width: "100%",
                color: topFont == undefined ? outFont : topFont,
                //background: "#fff",
                bottom: "0",
                display: "flex",
                justifyContent: "flex-end",
                gap: "5px"
             

              }}>
              {menuData.map((e, i) => <OptionTop objm={e} index={i} />)}
            </div>
              : <div></div>
          }

          <div style={{
            display: "grid", gridTemplateColumns: "auto", height: "min-content", width: "100%", margin: "auto",

          }}>
            <WButton />
          </div>
        </div>
        {
          w < 1024 && <div
            style={{
              height: "50px",
              position: "absolute",
              top: "50px",
              zIndex: "10000",
              width: "100%",
              color: topFont == undefined ? outFont : topFont,
              borderRight: "1px solid" + Borders,
              left: "20px",
              display: "grid",
              gap: "5px",
              gridTemplateColumns: "130px 130px 130px 130px 130px 130px"
            }}>
            {menuData.map((e, i) => <OptionTop objm={e} index={i} />)}
          </div>
        }
      </>

    )
  }
  /* <Route path={'/explorer'} element={<Chain />}></Route>
  <Route path={'/explorer/address' + checkpathtitle("explorer/address")} element={<Chain />}></Route>
  <Route path={'/explorer/token' + checkpathtitle("explorer/token")} element={<Chain />}></Route>
  <Route path={'/explorer/block' + checkpathtitle("explorer/block")} element={<Chain />}></Route>
  <Route path={'/explorer/tx' + checkpathtitle("explorer/tx")} element={<Chain />}></Route>
  */
  const jef = {
    '/': {
      url: '/',
      el: <Home />
    },
    '/projects': {
      url: '/projects' + checkpathtitle('projects'),
      el: <Audits />
    },
    '/p2p': {
      url: '/p2p',
      el: <P2Ppools view={0} p={props.provider} acc={props.account} />
    },
    '/p2p/sell': {
      url: '/p2p/sell',
      el: <CSP2Ppools view={1} />
    },
    '/pre-sales': {
      url: '/pre-sales',
      el: <Pre />
    },
    '/fair-launchs': {
      url: '/fair-launchs',
      el: <Fair />
    },
    '/private-sales': {
      url: '/private-sales',
      el: <Private />
    },
    '/pump-sales': {
      url: '/pump-sales',
      el: <Pump />
    },
    '/pre-sale': {
      url: '/pre-sale' + checkpathtitle("pre-sale"),
      el: <PreModal />
    },
    '/fair-launch': {
      url: '/fair-launch' + checkpathtitle("fair-launch"),
      el: <FairModal />
    },
    '/private-sale': {
      url: '/private-sale' + checkpathtitle("private-sale"),
      el: <PrivateModal />
    },
    '/pump-sale': {
      url: '/pump-sale' + checkpathtitle("pump-sale"),
      el: <PumpModal />
    },
    '/create/pre-sale': {
      url: '/create/pre-sale',
      el: <PreC />
    },
    '/create/fair-launch': {
      url: '/create/fair-launch',
      el: <FairC />
    },
    '/create/private-sale': {
      url: '/create/private-sale',
      el: <PrivateC />
    },
    '/create/pump-sale': {
      url: '/create/pump-sale',
      el: <PumpC />
    },
    '/pre-sale/edit': {
      url: '/pre-sale/edit' + checkpathtitle("pre-sale/edit"),
      el: <PreE />
    },
    '/fair-launch/edit': {
      url: '/fair-launch/edit' + checkpathtitle("fair-launch/edit"),
      el: <FairE />
    },
    '/private-sale/edit': {
      url: '/private-sale/edit' + checkpathtitle("private-sale/edit"),
      el: <PrivateE />
    },
    '/pump-sale/edit': {
      url: '/pump-sale/edit' + checkpathtitle("pump-sale/edit"),
      el: <PumpE />
    },
    '/swap': {
      url: '/swap',
      el: <Swap />
    },
    '/smart-bridge': {
      url: '/smart-bridge',
      el: <Trade />
    },
    '/create/contract': {
      url: '/create/contract',
      el: <TK />
    },
    '/play-to-win': {
      url: '/play-to-win',
      el: <Betpools />
    },
    '/multistake': {
      url: '/multistake',
      el: <MultiSTK />
    },
    '/exchange': {
      url: '/exchange',
      el: <Exchange array={[props.provider, props.account]} />
    },
    '/volume-bot': {
      url: '/volume-bot',
      el: <Volume />
    },
    '/farms': {
      url: '/farms',
      el: <Farms />
    },
    '/manager': {
      url: '/manager' + checkpathtitle("/manager"),
      el: <Devconsole />
    },
    '/stake': {
      url: '/stake',
      el: <Stakepools view={0} />
    },
    '/stake/create': {
      url: '/stake/create',
      el: <CStakepools view={1} />
    },
    '/pools/nfts': {
      url: '/pools/nfts',
      el: <StakepoolsNFT view={0} />
    },
    '/pools/nfts/create': {
      url: '/pools/nfts/create',
      el: <CStakepoolsNFT view={1} />
    },
    '/addlpv2': {
      url: '/addlpv2',
      el: <CreateLp />
    },
    '/marketplace': {
      url: '/marketplace',
      el: <Marketplace view={0} />
    },
    '/marketplace/publish': {
      url: '/marketplace/publish',
      el: <Marketplace view={1} />
    },
    '/marketplace/create': {
      url: '/marketplace/create',
      el: <Marketplace view={2} />
    },
    '/marketplace/edit/profile': {
      url: '/marketplace/edit/profile' + checkpathtitle("profile"),
      el: <Marketplace view={4} />
    },
    '/marketplace/profile': {
      url: '/marketplace/profile' + checkpathtitle("profile"),
      el: <Marketplace view={3} />
    },
    '/lock': {
      url: '/lock',
      el: <Lock view={0} />
    },
    '/lock/data': {
      url: '/lock/data' + checkpathtitle("data"),
      el: <Lock view={2} />
    },
    '/lock/create': {
      url: '/lock/create',
      el: <Lock view={1} />
    },
    '/lock/update': {
      url: '/lock/update',
      el: <Lock view={3} />
    },
    '/lock/transfer': {
      url: '/lock/transfer',
      el: <Lock view={4} />
    },
    '/terms': {
      url: '/terms',
      el: <Terms />
    },
    '/whitepaper': {
      url: '/whitepaper',
      el: <Whitepaper />
    },
    '/nft/pools': {
      url: '/nft/pools',
      el: <Stake />
    },
    '/betting': {
      url: '/betting',
      el: <Betpools />
    },
    '/explorer': {
      url: '/explorer',
      el: <Chain />
    },
    '/explorer/address': {
      url: '/explorer/address' + checkpathtitle("explorer/address"),
      el: <Chain />
    },
    '/explorer/token': {
      url: '/explorer/token' + checkpathtitle("explorer/token"),
      el: <Chain />
    },
    '/explorer/block': {
      url: '/explorer/block' + checkpathtitle("explorer/block"),
      el: <Chain />
    },
    '/explorer/tx': {
      url: '/explorer/tx' + checkpathtitle("explorer/tx"),
      el: <Chain />
    },
    '/play/slot': {
      url: '/play/slot',
      el: <SlotGame />
    }
  }
  const router = <Routes>
    <>
      {Routers.map((e, i) =>
        <Route path={jef[e].url} element={jef[e].el} />
      )}

    </>
  </Routes>
  //    <Route path='/volume-bot' element={<BotService element={<Volume />} />}></Route>
  /* 
      <Route path='/farms' element={<Farms />}></Route>
  
  
    <Route path={'/explorer'} element={<Chain />}></Route>
    <Route path={'/explorer/address' + checkpathtitle("explorer/address")} element={<Chain />}></Route>
    <Route path={'/explorer/token' + checkpathtitle("explorer/token")} element={<Chain />}></Route>
    <Route path={'/explorer/block' + checkpathtitle("explorer/block")} element={<Chain />}></Route>
    <Route path={'/explorer/tx' + checkpathtitle("explorer/tx")} element={<Chain />}></Route>
  
  <Route path={'/manager' + checkpathtitle("/manager")} element={<Devconsole />}></Route> */
  const Content = (props) => {
    if (props.bool) {
      return (<div style={{
        width: "100%",
        display: "grid",
        maxWidth: "1920px",
      }}>
        {menu && <Sidemenu />}
        {menustyle(false)}
        <div className='b' style={{ height: "90vh" }}>
          {router}
        </div>
      </div>);
    } else {
      return (<div style={{
        display: "grid",
        gridTemplateRows: (w >= 1024 ? "70px auto" : "100px auto"),
      }}>
        <Topmenu />
        <div className='b' style={{ height: "90vh", display: "block", margin: "auto", marginTop: "0px", maxWidth: "1920px", }}>
          <div style={{ height: "70px" }}></div>
          {router}
        </div>
      </div>);
    }
  }
  return (
    <>
      {window.location.pathname.toString().toUpperCase() == ('/stake/embed' + checkpathtitle("embed")).toUpperCase()
        ? <MobileStake />
        : <div style={{
          width: "100%",
          margin: "auto",
          height: "100vh"
        }}>
          {<Content bool={w < 720} />}
        </div>
      }
      {<Alert />}
    </>
  );
}

const Content = () => {
  return (
    <Connection content={<Main />} />
  )
}

export default Content;