import React, { useState } from 'react';
import '../../pages/trade.css';
import '../../App.css';
import Modal from "./modal";
import bnbabi from "../../abis/bnbabi.json";
const Web3 = require('web3-eth');

const Modalm = (props) => {

  let provider = props.array[0]
  let contract = props.array[1]
  let stakesabi = props.array[2]

  const [STAKECTS, setSTAKECTS] = useState(contract);
  const [APR, setAPR] = useState(0);
  const [APRB, setAPRB] = useState(0);
  const [symbola, setsymbolA] = useState("");
  const [symbolb, setsymbolB] = useState("");
  const [hubt, sethubt] = useState(false);
  const [ia, setiA] = useState(0);
  const [ib, setiB] = useState(0);

  let web3 = null
  if (provider == "https://bsc-dataseed.binance.org") {
    web3 = new Web3(new Web3.providers.HttpProvider(provider));

  } else {
    web3 = new Web3(provider);

  }

  let stakect = 0;

  if (provider != null) {

    stakect = new web3.Contract(stakesabi, STAKECTS);
    getapr()
  }
  let modalres = null
  if (hubt) {
    modalres = <div style={{ height: "100%", width: "100%", position: "fixed", display: "grid", background: "linear-gradient(to bottom right, #0f172a50, #0f172a50)", top: "0", left: "0", zIndex: "100000000000000" }} onClick={() => { sethubt(false) }} >

      <div style={{ height: "min-content", width: "auto", display: "grid", gridTemplateRows: "50%", margin: "auto", width: "45vh", height: "70vh", background: "linear-gradient(to bottom right, #0f172a, #000)", boxShadow: "-5px 10px 10px 10px #0f172a20", borderRadius: "10px" }}>
        <Modal pos={props.pos} heighti={"5vh"} bgcolor={"#16203150"} buttoncolor={"#33415550"} array={[provider, contract, stakesabi, "0x116526135380E28836C6080f1997645d5A807FAE", bnbabi, props.array[5]]} />
        <div style={{
          cursor: "pointer", display: "grid", gridTemplateColumns: "auto", height: "5vh", width: "94%", border: "1px solid #334155", margin: "auto",
          background: "#33415550", borderRadius: "2px", color: "#fff"
        }}>

          <p style={{ fontSize: "2vh", textAlign: "center", height: "min-content", margin: "auto", fontWeight: "bold" }}>CLOSE</p>
        </div>
      </div>


    </div>
  }

  function nextpercent(accountBalancemctTB, d) {
    if ((accountBalancemctTB / 10).toString().includes('.')) {
      if (accountBalancemctTB.toString().length >= d) {
        return (
          (accountBalancemctTB / 10).toString().split('.')[0] +
          '.' +
          (accountBalancemctTB / 10).toString().split('.')[1].slice(0, 2)
        );
      } else {
        return (
          '0.' +
          '0'.repeat(d - accountBalancemctTB.toString().length) +
          accountBalancemctTB.toString().slice(0, 2)
        );
      }
    } else {
      return accountBalancemctTB / 10;
    }
  }

  function call(func, callback, ...args) {
    // Trigger the function with the arguments
    func(...args).call()
      .then((result) => {
        // Apply given callback, this is our stateSetters
        callback(result);
      })
      .catch((error) => {
        //throw new Error(error);
      })
  }

  function getapr() {
    call(stakect.methods._FLEXAPRA, setAPR, props.pos);
    call(stakect.methods._FLEXAPRB, setAPRB, props.pos);

    if (ia == 0) {
      stakect.methods.poolsview().call().then(string => {
        setsymbolA(string[props.pos][0])
        setsymbolB(string[props.pos][1])
        setiA(string[props.pos][2])
        setiB(string[props.pos][3])
      }).catch(error => {

      })
    }
  }

  return (
    <div title={"BET " + props.pos} style={{
      height: "min-content",
      width: "100%",
      maxWidth: "45vh",
      display: "grid",
      padding: "1vh",
      margin: "auto",
      border: "1px solid " + props.bgcolor,
      borderRadius: "10px",
      backgroundColor: props.bgcolor,
      boxShadow: "1px 5px 5px 5px #16203120",
      color: "#fff",
      fontSize: "1.8vh",
    }}>
      {hubt && <div style={{ height: "100%", width: "100%", position: "fixed", display: "grid", background: "linear-gradient(to bottom right, #0f172a50, #0f172a50)", top: "0", left: "0", zIndex: "100000000000000" }}  >

        <div style={{ height: "min-content", width: "auto", display: "grid", gridTemplateRows: "50%", margin: "auto", width: "45vh", height: "70vh", background: "linear-gradient(to bottom right, #0f172a, #000)", boxShadow: "-5px 10px 10px 10px #0f172a20", borderRadius: "10px" }}>
          <Modal pos={props.pos} heighti={"5vh"} bgcolor={"#16203150"} buttoncolor={"#33415550"} array={[provider, contract, stakesabi, "0x116526135380E28836C6080f1997645d5A807FAE", bnbabi, props.array[5]]} />
          <div onClick={() => { sethubt(false) }} style={{
            cursor: "pointer", display: "grid", gridTemplateColumns: "auto", height: "5vh", width: "94%", border: "1px solid #334155", margin: "auto",
            background: "#33415550", borderRadius: "2px", color: "#fff"
          }}>

            <p style={{ fontSize: "2vh", textAlign: "center", height: "min-content", margin: "auto", fontWeight: "bold" }}>CLOSE</p>
          </div>
        </div>


      </div>}
      <p style={{ fontSize: "1.4vh", textAlign: "left", padding: "1vh 0", height: "min-content", margin: "auto" }}>
        {props.title}</p>
      <div style={{
        height: "min-content",
        width: "100%",
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(25vh, 1fr)",
        gap: "10px",
      }}>

        <div style={{ display: "grid", gridTemplateColumns: "auto min-content auto", width: "100%", padding: "auto", height: "10vh" }}>
          <div style={{
            height: "8vh",
            width: "16vh",
            margin: "auto",
          }}>
            <p style={{ margin: "auto", fontWeight: "bold", textAlign: "center", fontSize: "1.5vh" }}>{symbola}</p>
            <div style={{
              borderRadius: "360px",
              height: "5vh",
              width: "5vh",
              margin: "auto",
              background: "url('" + ia + " ')  center center / 80% no-repeat",
            }}>

            </div>

            <button onClick={() => { }} style={{
              display: "grid", gridTemplateColumns: "auto", height: "2vh", width: "8vh", border: "1px solid #7CFC00", margin: "auto ",
              background: "#7CFC0010", borderRadius: "2px", color: "#7CFC00"
            }}>
              <p style={{ fontSize: "1.5vh", height: "min-content", margin: "auto" }}>{parseFloat(nextpercent(APR, 2) / 100).toFixed(4)}x</p>
            </button>
          </div>
          <p onClick={() => { }} style={{

            height: "min-content",
            width: "4vh",
            display: "grid",
            margin: "auto",
          }}><p style={{ margin: "auto", fontWeight: "bold", fontSize: "1.8vh" }}>VS</p></p>
          <div style={{


            height: "8vh",
            width: "16vh",

            margin: "auto",

          }}>
            <p style={{ margin: "auto", fontWeight: "bold", textAlign: "center", fontSize: "1.5vh" }}>{symbolb}</p>
            <div style={{

              borderRadius: "360px",
              height: "5vh",
              width: "5vh",

              margin: "auto",
              background: "url('" + ib + " ')  center center / 80% no-repeat",
            }}>
            </div>

            <button onClick={() => { }} style={{
              display: "grid", gridTemplateColumns: "auto", height: "2vh", width: "8vh", border: "1px solid #7CFC00", margin: "auto ",
              background: "#7CFC0010", borderRadius: "2px", color: "#7CFC00"
            }}>
              <p style={{ fontSize: "1.5vh", height: "min-content", margin: "auto" }}>{parseFloat(nextpercent(APRB, 2) / 100).toFixed(4)}x</p>
            </button>

          </div>

        </div>
      </div>
      <div style={{
        height: "min-content",
        width: "95%",
        margin: "auto",
        display: "grid", gridTemplateColumns: "auto",
        borderRadius: "10px",
      }}>
        <div onClick={() => { sethubt(true) }} style={{
          display: "grid", gridTemplateColumns: "auto", height: "min-content", width: "auto", margin: "auto", borderRadius: "2px", color: "#fff"
        }}>

          <p style={{ fontSize: "1.5vh", textAlign: "left", padding: "1vh 0", height: "min-content", margin: "auto" }}>
            CLICK TO SEE DETAILS</p>
          <svg style={{
            height: "2vh",
            width: "2vh",
            margin: "auto",
          }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
          </svg>
        </div>
      </div>
    </div>
  );
}
export default Modalm;
