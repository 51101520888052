import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
const Modal = (props) => {

  let provider = props.array[0]
  let contract = props.array[1]
  let stakesabi = props.array[2]
  let tokenabi = props.array[3]
  let accounts = [props.array[4]]

  const [STAKECTS, setSTAKECTS] = useState(contract);
  const [stakect, setStake] = useState(undefined);
  const [view_details, setview_details] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [price, setPrice] = useState(0);
  const [priceusd, setPriceUSD] = useState(0);
  const [owner, setOwner] = useState('');
  const [avaliable, setAmountAvaliable] = useState(0);
  useEffect(() => {
    let interval = setInterval(() => {
      setSeconds(seconds => seconds + 1)
      data()
    }, 1000);
    return () => clearInterval(interval);
  }, [seconds]);
  useEffect(() => {
    data()
  }, [])


  async function data() {

    if (STAKECTS != null) {
      const _stakect = await props.Tokencontract(stakesabi, STAKECTS, provider);
      setStake(_stakect)
      call(_stakect.methods.price, setPrice);
      call(_stakect.methods.__price, setPriceUSD);
      call(_stakect.methods.avaliable, setAmountAvaliable);
      call(_stakect.methods._owner, setOwner);
    }
  }


  function nextblock(accountBalancemctTB, d) {
    if (accountBalancemctTB != null) {
      return parseFloat(ethers.utils.formatUnits(accountBalancemctTB, d)).toFixed(4)
    }
  }


  function call(func, callback, ...args) {
    func(...args).call()
      .then((result) => {
        callback(result);
      })
      .catch((error) => {
        //console.log(func)
      })
  }
  async function nstake() {
    const value = price == '0' ? price : ethers.utils.parseUnits((parseFloat(nextblock(price, 18)) + (parseFloat(nextblock(price, 18)) * 1) / 100).toString(), 18).toString()
    await props.nSendTX(props.facAdress, props.factoryABI, props.netId, '_buy', value, 0, props.pos, props.token)
    data()
  }

  const ButtonI = (props) => {
    return (
      <div style={{ display: "grid", gridTemplateColumns: "auto", height: '50px', width: "95%", margin: "auto" }}>
        <p onClick={props.func} style={{

          backgroundColor: props.title.toString().includes('CANCEL') ? '#ff000050' : '#88888899',
          borderRadius: "90px",
          height: '50px',
          width: "100%",
          display: "grid",
          margin: "auto",
          cursor: "pointer"
        }}><p style={{ margin: "auto", fontWeight: "bold" }}>{props.title}</p></p>
      </div>
    );
  }

  let title = <div style={{ display: "grid", gridTemplateColumns: "60px auto", width: "100%", height: "50px", margin: "auto", padding: "auto" }}>
    <img src='https://bscscan.com/images/main/empty-token.png' style={{ height: "40px", width: "40px", margin: "auto" }}></img>
    <div style={{
      height: "min-content",
      width: "100%",
      margin: "auto",
      display: "grid",
      gridTemplateColumns: "auto"
    }}>
      <div style={{ display: "grid", gridTemplateColumns: "auto ", height: "100%", width: "100%", margin: "auto", borderRadius: "10px", }}>

        <p style={{
          display: "grid", gridTemplateColumns: " auto ", margin: "auto", textAlign: "left",
          width: "100%",
          fontSize: "12px",
          color: "#fff"
        }}>

          <p style={{ width: "100%", display: "grid", margin: "auto", }}>
            <span style={{
              textAlign: "left",
              width: "100%",
              fontSize: "14px",
              margin: "auto",
              fontWeight: "bold"
            }}>BUY {props.tokenname}</span>
            <span style={{
              textAlign: "left",
              width: "100%",
              fontSize: "10px",
              margin: "auto",
            }}>
              {"FROM SELLER"}
            </span>
          </p>
        </p>
      </div>
      <div style={{
        height: "25px",

        display: "grid",
        borderLeft: "1px solid #88888899",
        borderRadius: "2px",
        paddingLeft: "5px",
        paddingRight: "5px"
      }}>
        <p style={{ textAlign: "left", height: "min-content", fontSize: "12px", margin: "auto 0" }}> {owner.toString().slice(0, 4) + "..." + owner.toString().slice(-4)}</p>
      </div>
    </div>
  </div>

  let apr_recentProfit = <div style={{ display: "grid", gridTemplateColumns: "auto auto", height: "100%", width: "100%", margin: "auto", gap: "10px" }}>
    <div style={{ display: "grid", gridTemplateColumns: "auto ", height: "100%", width: "95%", margin: "auto", borderRadius: "10px", }}>

      <p style={{
        display: "grid", gridTemplateColumns: "50px auto ", margin: "auto", textAlign: "left",
        width: "100%",
        fontSize: "12px",
        color: "#fff"
      }}>
        <i style={{
          height: "50px",
          width: "50px",
          margin: "auto",
          background: "url('" + " https://bscscan.com/token/images/binance_32.png" + " ')  center center / 50% no-repeat",
        }}>
        </i>
        <p style={{ width: "95%", display: "grid", gridTemplateColumns: "30px ", margin: "auto", }}>
          <span style={{
            textAlign: "left",
            width: "100%",
            fontSize: "14px",
            margin: "auto",
            fontWeight: "bold"
          }}>PRICE</span>
          <span style={{
            textAlign: "right",
            width: "100%",
            fontWeight: "bold",
            fontSize: "12px",
            margin: "auto",
          }}>
            {nextblock(price, 18)}
          </span>
        </p>
      </p>
    </div>
    <div style={{ display: "grid", gridTemplateColumns: "auto ", height: "100%", width: "95%", margin: "auto", borderRadius: "10px", }}>
      <p style={{
        display: "grid", gridTemplateColumns: "50px auto ", margin: "auto", textAlign: "left",
        width: "100%",
        fontSize: "12px",
        color: "#fff"
      }}>
        <img src='https://bscscan.com/images/main/empty-token.png' style={{ height: "25px", width: "25px", margin: "auto" }}></img>
        <p style={{ width: "95%", display: "grid", gridTemplateColumns: "30px ", margin: "auto", }}>
          <span style={{
            textAlign: "left",
            width: "100%",
            fontSize: "14px",
            margin: "auto",
            fontWeight: "bold"
          }}>Receive</span>
          <span style={{
            textAlign: "right",
            width: "100%",
            fontWeight: "bold",
            fontSize: "12px",
            margin: "auto",
          }}>
            {nextblock(avaliable, 18)}
          </span>
        </p>
      </p>
    </div>
  </div>
  let details = window.innerWidth > 450 ? <div onClick={() => {
    setview_details(!view_details)
  }} style={{ cursor: "pointer", display: "inline-flex" }}>
    <div style={{ width: "auto", display: "inline-flex", padding: "10px 0" }}>
      <p style={{ width: "auto", margin: "auto", fontSize: "15px", color: "#eee", padding: "5px", fontWeight: "bold" }}>Details</p>
      <svg style={{ margin: "auto", height: "15px", width: "15px", fill: "#eee", transform: view_details ? "rotate(180deg)" : "rotate(0deg)" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
        <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
      </svg>
    </div>
  </div> : <div onClick={() => {
    setview_details(!view_details)
  }} style={{ cursor: "pointer", display: "inline-flex" }}>
    <div style={{ width: "auto", display: "inline-flex", padding: "10px 0" }}>
      <svg style={{ margin: "auto", height: "15px", width: "15px", fill: "#eee", transform: view_details ? "rotate(180deg)" : "rotate(0deg)" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
        <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
      </svg>
    </div>
  </div>
  let interactzone = <div style={{ display: "grid", gridTemplateColumns: "auto auto", gap: "10px", height: "70px", width: "95%", margin: "auto", color: "#fff" }}>
    {apr_recentProfit}
    {owner != accounts[0] && <ButtonI func={() => { nstake() }} title={'BUY'} />}
    {owner == accounts[0] && <ButtonI func={() => {
      if (window.confirm("WHEN YOU CANCEL AN ORDER YOUR MARTIKS WILL BE SENT TO THE MARTIK OWNER, REQUEST THEM BACK AS SOON AS YOU CANCEL, DO YOU WANT CONTINUE?")) {
        props.nSendTX(props.facAdress, props.factoryABI, props.netId, 'remove', '5000000000000000', 0, props.pos, props.token)
      } else {

      }

    }} title={'CANCEL ORDER'} />}
  </div>

  function isWidth(widthMax, widthMin) {
    return (window.innerWidth > widthMin && window.innerWidth <= widthMax)
  }
  if (avaliable == 0) {
    return (
      <></>
    );
  }
  return (
    <div style={{
      height: "min-content",
      width: "100%",
      listStyleType: "none",
      display: "grid",
      margin: "auto",
      borderRadius: "10px",
      background: "#222",
      /// boxShadow: "1px 5px 5px 5px #16203120",
      color: "#ddd",
      padding: "5px",
      fontSize: "12px",
    }}>

      {true && <div style={{
        height: "min-content",
        width: "100%",
        listStyleType: "none",
        display: "grid",
        gridTemplateColumns: "auto auto",
        margin: "auto",
        borderRadius: "10px",
        background: "#222",
        /// boxShadow: "1px 5px 5px 5px #16203120",
        color: "#fff",
        padding: "5px",
        fontSize: "12px",
      }}>
        {<>
          {title}
          <div style={{ display: "grid", gridTemplateColumns: "auto ", height: "100%", width: "95%", margin: "auto", borderRadius: "10px", }}>
            <p style={{
              display: "grid", gridTemplateColumns: "50px auto ", margin: "auto", textAlign: "left",
              width: "100%",
              fontSize: "12px",
              color: "#fff"
            }}>
              <i style={{
                height: "50px",
                width: "50px",
                margin: "auto",
                background: "url('" + "https://bscscan.com/token/images/busd_32_2.png" + " ')  center center / 50% no-repeat",
              }}>
              </i>
              <p style={{ width: "95%", display: "grid", gridTemplateColumns: "100% ", margin: "auto", }}>
                <span style={{
                  textAlign: "left",
                  width: "100%",
                  fontSize: "12px",
                  margin: "auto",
                  fontWeight: "bold"
                }}>Unit Price USD</span>
                <span style={{
                  textAlign: "left",
                  width: "100%",
                  fontWeight: "bold",
                  fontSize: "14px",
                  margin: "auto",
                  color: "#00ff0099"
                }}>
                  ${nextblock(priceusd, 18)}
                </span>
              </p>
            </p>
          </div>

        </>}

      </div>}
      {true && <div style={{
        height: "min-content",
        width: "100%",
        listStyleType: "none",
        display: "grid",
        gridTemplateColumns: "1fr",
        margin: "auto",
        borderRadius: "10px",
        background: "#111",
        /// boxShadow: "1px 5px 5px 5px #16203120",
        color: "#eee",
        padding: "5px",
        fontSize: "12px",
      }}>
        {interactzone}
      </div>}

    </div >
  );
}
export default Modal;