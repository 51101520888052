import ProjectData from '../../config';
const pos = window.location.host.split('.')[0] != undefined 
? window.location.host.split('.')[0] == "app" 
? window.location.host.split('.')[0] + "." + window.location.host.split('.')[1] 
: window.location.host.split('.')[0] : "Martik"

let projectName = "Martik"
const {
    Name,
    Logo,
    backgroundColor,
    buttonsColor,
    buttonsBorders,
    inputsColor,
    inputsBorders,
    outbuttonsColor,
    Borders,
    outFont,
    innerFont,
    Routers,
    logoUrl,
    tokenContract,
    rpc,
    info
} = ProjectData[pos]


const data = {
    "martik": {
        CONTRACTS: {
            1999: {
                router: "0xBaF95B33F8243b863EE6A7900cfc52875c874C93",
                providerURL: "https://rpc.martik.site",
                preSaleContract: "0x3c9A0Dc3BC2b41E5b29919f04abF800810FddC5d",
                fairSaleContract: "0x6b2aAac776A21Cf2e8b47552b378309c1e291a44",
                priSaleContract: "0x1099E249dbAFc3aAd6CC1A21B1368c6099e83863",
                pmpSaleContract: "0xc24fC68061ca2372c6DeD30F24B46Ad89a64C95d",
                lockContract: "0x4c479B58BfB03fB606caE7761A397035968b7c1e",
                // lockContract: "0xA0c139Ad62e1f5413F6D5fa1a3e5C8596315f1E4",
            },
            97: {
                router: "0xD99D1c33F9fC3444f8101754aBC46c52416550D1",
                providerURL: "https://data-seed-prebsc-1-s3.binance.org:8545",
                preSaleContract: "0x3c9A0Dc3BC2b41E5b29919f04abF800810FddC5d",
                fairSaleContract: "0x6b2aAac776A21Cf2e8b47552b378309c1e291a44",
                priSaleContract: "0x1099E249dbAFc3aAd6CC1A21B1368c6099e83863",
                pmpSaleContract: "0xc24fC68061ca2372c6DeD30F24B46Ad89a64C95d",
                lockContract: "0x4c479B58BfB03fB606caE7761A397035968b7c1e",
                // lockContract: "0xA0c139Ad62e1f5413F6D5fa1a3e5C8596315f1E4",
            },
            56: {
                router: "0x10ED43C718714eb63d5aA57B78B54704E256024E",
                providerURL: "https://bsc-dataseed.binance.org",
                preSaleContract: "0x233F8eBbA3C2A31402aCC2781720fC63409aB013",
                fairSaleContract: "0xc261B148a6414A380f50894e216Fd76F9d450491",
                priSaleContract: "0x7f00a9B4DAC1AA363F6543A801E794654C799ed4",
                pmpSaleContract: "0xc24fC68061ca2372c6DeD30F24B46Ad89a64C95d",
                lockContract: "0x3a374533b2c4DF342C78E1060494B968A6FF3792",
            },
        }
    },
    "buyback70": {
        CONTRACTS: {
            1999: {
                router: "0xBaF95B33F8243b863EE6A7900cfc52875c874C93",
                providerURL: "https://rpc.martik.site",
                preSaleContract: "0x3c9A0Dc3BC2b41E5b29919f04abF800810FddC5d",
                fairSaleContract: "0x6b2aAac776A21Cf2e8b47552b378309c1e291a44",
                priSaleContract: "0x1099E249dbAFc3aAd6CC1A21B1368c6099e83863",
                pmpSaleContract: "0xc24fC68061ca2372c6DeD30F24B46Ad89a64C95d",
                lockContract: "0x4c479B58BfB03fB606caE7761A397035968b7c1e",
                // lockContract: "0xA0c139Ad62e1f5413F6D5fa1a3e5C8596315f1E4",
            },
            97: {
                router: "0xD99D1c33F9fC3444f8101754aBC46c52416550D1",
                providerURL: "https://data-seed-prebsc-1-s3.binance.org:8545",
                preSaleContract: "0x3c9A0Dc3BC2b41E5b29919f04abF800810FddC5d",
                fairSaleContract: "0x6b2aAac776A21Cf2e8b47552b378309c1e291a44",
                priSaleContract: "0x1099E249dbAFc3aAd6CC1A21B1368c6099e83863",
                pmpSaleContract: "0xc24fC68061ca2372c6DeD30F24B46Ad89a64C95d",
                lockContract: "0x4c479B58BfB03fB606caE7761A397035968b7c1e",
                // lockContract: "0xA0c139Ad62e1f5413F6D5fa1a3e5C8596315f1E4",
            },
            56: {
                router: "0x10ED43C718714eb63d5aA57B78B54704E256024E",
                providerURL: "https://bsc-dataseed.binance.org",
                preSaleContract: "0x233F8eBbA3C2A31402aCC2781720fC63409aB013",
                fairSaleContract: "0xc261B148a6414A380f50894e216Fd76F9d450491",
                priSaleContract: "0x7f00a9B4DAC1AA363F6543A801E794654C799ed4",
                pmpSaleContract: "0xc24fC68061ca2372c6DeD30F24B46Ad89a64C95d",
                lockContract: "0x3a374533b2c4DF342C78E1060494B968A6FF3792",
            },
        }
    },
    "dgiroto": {
        CONTRACTS: {
            1999: {
                router: "0xBaF95B33F8243b863EE6A7900cfc52875c874C93",
                providerURL: "https://rpc.martik.site",
                preSaleContract: "0x3c9A0Dc3BC2b41E5b29919f04abF800810FddC5d",
                fairSaleContract: "0x6b2aAac776A21Cf2e8b47552b378309c1e291a44",
                priSaleContract: "0x1099E249dbAFc3aAd6CC1A21B1368c6099e83863",
                pmpSaleContract: "0xc24fC68061ca2372c6DeD30F24B46Ad89a64C95d",
                lockContract: "0x4c479B58BfB03fB606caE7761A397035968b7c1e",
                // lockContract: "0xA0c139Ad62e1f5413F6D5fa1a3e5C8596315f1E4",
            },
            97: {
                router: "0xD99D1c33F9fC3444f8101754aBC46c52416550D1",
                providerURL: "https://data-seed-prebsc-1-s3.binance.org:8545",
                preSaleContract: "0x3c9A0Dc3BC2b41E5b29919f04abF800810FddC5d",
                fairSaleContract: "0x6b2aAac776A21Cf2e8b47552b378309c1e291a44",
                priSaleContract: "0x1099E249dbAFc3aAd6CC1A21B1368c6099e83863",
                pmpSaleContract: "0xc24fC68061ca2372c6DeD30F24B46Ad89a64C95d",
                lockContract: "0x4c479B58BfB03fB606caE7761A397035968b7c1e",
                // lockContract: "0xA0c139Ad62e1f5413F6D5fa1a3e5C8596315f1E4",
            },
            56: {
                router: "0x10ED43C718714eb63d5aA57B78B54704E256024E",
                providerURL: "https://bsc-dataseed.binance.org",
                preSaleContract: "0x233F8eBbA3C2A31402aCC2781720fC63409aB013",
                fairSaleContract: "0xc261B148a6414A380f50894e216Fd76F9d450491",
                priSaleContract: "0x7f00a9B4DAC1AA363F6543A801E794654C799ed4",
                pmpSaleContract: "0xc24fC68061ca2372c6DeD30F24B46Ad89a64C95d",
                lockContract: "0x3a374533b2c4DF342C78E1060494B968A6FF3792",
            },
        }
    },
    "xshiba": {
        CONTRACTS: {
            97: {
                router: "0xD99D1c33F9fC3444f8101754aBC46c52416550D1",
                providerURL: "https://data-seed-prebsc-1-s3.binance.org:8545",
                preSaleContract: "0x3c9A0Dc3BC2b41E5b29919f04abF800810FddC5d",
                fairSaleContract: "0x6b2aAac776A21Cf2e8b47552b378309c1e291a44",
                priSaleContract: "0x1099E249dbAFc3aAd6CC1A21B1368c6099e83863",
                lockContract: "0x4c479B58BfB03fB606caE7761A397035968b7c1e",
                // lockContract: "0xA0c139Ad62e1f5413F6D5fa1a3e5C8596315f1E4",
            },
            56: {
                router: "0x10ED43C718714eb63d5aA57B78B54704E256024E",
                providerURL: "https://bsc-dataseed.binance.org",
                preSaleContract: "0x233F8eBbA3C2A31402aCC2781720fC63409aB013",
                fairSaleContract: "0xc261B148a6414A380f50894e216Fd76F9d450491",
                priSaleContract: "0x7f00a9B4DAC1AA363F6543A801E794654C799ed4",
                lockContract: "0x3a374533b2c4DF342C78E1060494B968A6FF3792",
            },
        }
    },
    "sbk": {
        CONTRACTS: {
            97: {
                router: "0xD99D1c33F9fC3444f8101754aBC46c52416550D1",
                providerURL: "https://data-seed-prebsc-1-s3.binance.org:8545",
                preSaleContract: "0x3c9A0Dc3BC2b41E5b29919f04abF800810FddC5d",
                fairSaleContract: "0x6b2aAac776A21Cf2e8b47552b378309c1e291a44",
                priSaleContract: "0x1099E249dbAFc3aAd6CC1A21B1368c6099e83863",
                lockContract: "0x4c479B58BfB03fB606caE7761A397035968b7c1e",
                // lockContract: "0xA0c139Ad62e1f5413F6D5fa1a3e5C8596315f1E4",
            },
            56: {
                router: "0x10ED43C718714eb63d5aA57B78B54704E256024E",
                providerURL: "https://bsc-dataseed.binance.org",
                preSaleContract: "0x233F8eBbA3C2A31402aCC2781720fC63409aB013",
                fairSaleContract: "0xc261B148a6414A380f50894e216Fd76F9d450491",
                priSaleContract: "0x7f00a9B4DAC1AA363F6543A801E794654C799ed4",
                lockContract: "0x3a374533b2c4DF342C78E1060494B968A6FF3792",
            },
        }
    },
    "blackcryptoplatform": {
        CONTRACTS: {
            97: {
                router: "0xD99D1c33F9fC3444f8101754aBC46c52416550D1",
                providerURL: "https://data-seed-prebsc-1-s3.binance.org:8545",
                preSaleContract: "0x3c9A0Dc3BC2b41E5b29919f04abF800810FddC5d",
                fairSaleContract: "0x6b2aAac776A21Cf2e8b47552b378309c1e291a44",
                priSaleContract: "0x1099E249dbAFc3aAd6CC1A21B1368c6099e83863",
                lockContract: "0x4c479B58BfB03fB606caE7761A397035968b7c1e",
                // lockContract: "0xA0c139Ad62e1f5413F6D5fa1a3e5C8596315f1E4",
            },
            56: {
                router: "0x10ED43C718714eb63d5aA57B78B54704E256024E",
                providerURL: "https://bsc-dataseed.binance.org",
                preSaleContract: "0x233F8eBbA3C2A31402aCC2781720fC63409aB013",
                fairSaleContract: "0xc261B148a6414A380f50894e216Fd76F9d450491",
                priSaleContract: "0x7f00a9B4DAC1AA363F6543A801E794654C799ed4",
                lockContract: "0x3a374533b2c4DF342C78E1060494B968A6FF3792",
            },
        }
    },
    "xtiger": {
        CONTRACTS: {
            97: {
                router: "0xD99D1c33F9fC3444f8101754aBC46c52416550D1",
                providerURL: "https://data-seed-prebsc-1-s3.binance.org:8545",
                preSaleContract: "0x3c9A0Dc3BC2b41E5b29919f04abF800810FddC5d",
                fairSaleContract: "0x6b2aAac776A21Cf2e8b47552b378309c1e291a44",
                priSaleContract: "0x1099E249dbAFc3aAd6CC1A21B1368c6099e83863",
                lockContract: "0x4c479B58BfB03fB606caE7761A397035968b7c1e",
                // lockContract: "0xA0c139Ad62e1f5413F6D5fa1a3e5C8596315f1E4",
            },
            56: {
                router: "0x10ED43C718714eb63d5aA57B78B54704E256024E",
                providerURL: "https://bsc-dataseed.binance.org",
                preSaleContract: "0x233F8eBbA3C2A31402aCC2781720fC63409aB013",
                fairSaleContract: "0xc261B148a6414A380f50894e216Fd76F9d450491",
                priSaleContract: "0x7f00a9B4DAC1AA363F6543A801E794654C799ed4",
                lockContract: "0x3a374533b2c4DF342C78E1060494B968A6FF3792",
            },
        }
    },
    "jijiswap": {
        CONTRACTS: {
            97: {
                router: "0xD99D1c33F9fC3444f8101754aBC46c52416550D1",
                providerURL: "https://data-seed-prebsc-1-s3.binance.org:8545",
                preSaleContract: "0x3c9A0Dc3BC2b41E5b29919f04abF800810FddC5d",
                fairSaleContract: "0x6b2aAac776A21Cf2e8b47552b378309c1e291a44",
                priSaleContract: "0x1099E249dbAFc3aAd6CC1A21B1368c6099e83863",
                lockContract: "0x4c479B58BfB03fB606caE7761A397035968b7c1e",
                // lockContract: "0xA0c139Ad62e1f5413F6D5fa1a3e5C8596315f1E4",
            },
            56: {
                router: "0x10ED43C718714eb63d5aA57B78B54704E256024E",
                providerURL: "https://bsc-dataseed.binance.org",
                preSaleContract: "0x233F8eBbA3C2A31402aCC2781720fC63409aB013",
                fairSaleContract: "0xc261B148a6414A380f50894e216Fd76F9d450491",
                priSaleContract: "0x7f00a9B4DAC1AA363F6543A801E794654C799ed4",
                lockContract: "0x3a374533b2c4DF342C78E1060494B968A6FF3792",
            },
        }
    },
    "casinosworld": {
        CONTRACTS: {
            97: {
                router: "0xD99D1c33F9fC3444f8101754aBC46c52416550D1",
                providerURL: "https://data-seed-prebsc-1-s3.binance.org:8545",
                preSaleContract: "0x3c9A0Dc3BC2b41E5b29919f04abF800810FddC5d",
                fairSaleContract: "0x6b2aAac776A21Cf2e8b47552b378309c1e291a44",
                priSaleContract: "0x1099E249dbAFc3aAd6CC1A21B1368c6099e83863",
                lockContract: "0x4c479B58BfB03fB606caE7761A397035968b7c1e",
                // lockContract: "0xA0c139Ad62e1f5413F6D5fa1a3e5C8596315f1E4",
            },
            56: {
                router: "0x10ED43C718714eb63d5aA57B78B54704E256024E",
                providerURL: "https://bsc-dataseed.binance.org",
                preSaleContract: "0x27c5e6ee284df2B0Ca03773C322eF55EFeF7E431",
                fairSaleContract: "0xbDf480a640FBe7f2B73226ad3caF5F1B92e51d0e",
                priSaleContract: "0x318f1D83e376244bE6947B2E50245117B62E0FA0",
                lockContract: "0x7CF60f79b140211cDc13e9a4E27701E29198f6ea",
            },
        }
    },
    "brazuka": {
        CONTRACTS: {
            97: {
                router: "0xD99D1c33F9fC3444f8101754aBC46c52416550D1",
                providerURL: "https://data-seed-prebsc-1-s3.binance.org:8545",
                preSaleContract: "0x3c9A0Dc3BC2b41E5b29919f04abF800810FddC5d",
                fairSaleContract: "0x6b2aAac776A21Cf2e8b47552b378309c1e291a44",
                priSaleContract: "0x1099E249dbAFc3aAd6CC1A21B1368c6099e83863",
                lockContract: "0x4c479B58BfB03fB606caE7761A397035968b7c1e",
                // lockContract: "0xA0c139Ad62e1f5413F6D5fa1a3e5C8596315f1E4",
            },
            56: {
                router: "0x10ED43C718714eb63d5aA57B78B54704E256024E",
                providerURL: "https://bsc-dataseed.binance.org",
                preSaleContract: "0x844E57489E2F99b662E67F93241B7CfCB3294365",
                fairSaleContract: "0xc52367B3180f27Bd4CE8e37668A67Af51c35eFf9",
                priSaleContract: "0xCd88ee193A21174DCf26F626281fAa97197cA938",
                lockContract: "0x527987a6223F50Ae9E05d2592Db9724279Cb6ec5",
            },
        }
    },
    "dodolabs": {
        CONTRACTS: {
            97: {
                router: "0xD99D1c33F9fC3444f8101754aBC46c52416550D1",
                providerURL: "https://data-seed-prebsc-1-s3.binance.org:8545",
                preSaleContract: "0x3c9A0Dc3BC2b41E5b29919f04abF800810FddC5d",
                fairSaleContract: "0x6b2aAac776A21Cf2e8b47552b378309c1e291a44",
                priSaleContract: "0x1099E249dbAFc3aAd6CC1A21B1368c6099e83863",
                lockContract: "0x4c479B58BfB03fB606caE7761A397035968b7c1e",
                // lockContract: "0xA0c139Ad62e1f5413F6D5fa1a3e5C8596315f1E4",
            },
            56: {
                router: "0x10ED43C718714eb63d5aA57B78B54704E256024E",
                providerURL: "https://bsc-dataseed.binance.org",
                preSaleContract: "0x233F8eBbA3C2A31402aCC2781720fC63409aB013",
                fairSaleContract: "0xc261B148a6414A380f50894e216Fd76F9d450491",
                priSaleContract: "0x7f00a9B4DAC1AA363F6543A801E794654C799ed4",
                lockContract: "0x3a374533b2c4DF342C78E1060494B968A6FF3792",
            },
        }
    },
    
    "app.viperking": {
        CONTRACTS: {
            97: {
                router: "0xD99D1c33F9fC3444f8101754aBC46c52416550D1",
                providerURL: "https://data-seed-prebsc-1-s3.binance.org:8545",
                preSaleContract: "0x3c9A0Dc3BC2b41E5b29919f04abF800810FddC5d",
                fairSaleContract: "0x6b2aAac776A21Cf2e8b47552b378309c1e291a44",
                priSaleContract: "0x1099E249dbAFc3aAd6CC1A21B1368c6099e83863",
                lockContract: "0x4c479B58BfB03fB606caE7761A397035968b7c1e",
                // lockContract: "0xA0c139Ad62e1f5413F6D5fa1a3e5C8596315f1E4",
            },
            56: {
                router: "0x10ED43C718714eb63d5aA57B78B54704E256024E",
                providerURL: "https://bsc-dataseed.binance.org",
                preSaleContract: "0x233F8eBbA3C2A31402aCC2781720fC63409aB013",
                fairSaleContract: "0xc261B148a6414A380f50894e216Fd76F9d450491",
                priSaleContract: "0x7f00a9B4DAC1AA363F6543A801E794654C799ed4",
                lockContract: "0x3a374533b2c4DF342C78E1060494B968A6FF3792",
            },
        }
    },
    "app.urusswap": {
        CONTRACTS: {
            97: {
                router: "0xD99D1c33F9fC3444f8101754aBC46c52416550D1",
                providerURL: "https://data-seed-prebsc-1-s3.binance.org:8545",
                preSaleContract: "0x3c9A0Dc3BC2b41E5b29919f04abF800810FddC5d",
                fairSaleContract: "0x6b2aAac776A21Cf2e8b47552b378309c1e291a44",
                priSaleContract: "0x1099E249dbAFc3aAd6CC1A21B1368c6099e83863",
                lockContract: "0x4c479B58BfB03fB606caE7761A397035968b7c1e",
                pmpSaleContract: "0xc24fC68061ca2372c6DeD30F24B46Ad89a64C95d",
                // lockContract: "0xA0c139Ad62e1f5413F6D5fa1a3e5C8596315f1E4",
            },
            56: {
                router: "0x10ED43C718714eb63d5aA57B78B54704E256024E",
                providerURL: "https://bsc-dataseed.binance.org",
                preSaleContract: "0x233F8eBbA3C2A31402aCC2781720fC63409aB013",
                fairSaleContract: "0xc261B148a6414A380f50894e216Fd76F9d450491",
                priSaleContract: "0x7f00a9B4DAC1AA363F6543A801E794654C799ed4",
                lockContract: "0x3a374533b2c4DF342C78E1060494B968A6FF3792",
                pmpSaleContract: "0x023260D813F6B4aa872D8eB0a08C1dF995925060",
            },
        }
    },
    "glowup": {
        CONTRACTS: {
            97: {
                router: "0xD99D1c33F9fC3444f8101754aBC46c52416550D1",
                providerURL: "https://data-seed-prebsc-1-s3.binance.org:8545",
                preSaleContract: "0x3c9A0Dc3BC2b41E5b29919f04abF800810FddC5d",
                fairSaleContract: "0x6b2aAac776A21Cf2e8b47552b378309c1e291a44",
                priSaleContract: "0x1099E249dbAFc3aAd6CC1A21B1368c6099e83863",
                lockContract: "0x4c479B58BfB03fB606caE7761A397035968b7c1e",
                pmpSaleContract: "0xc24fC68061ca2372c6DeD30F24B46Ad89a64C95d",
                // lockContract: "0xA0c139Ad62e1f5413F6D5fa1a3e5C8596315f1E4",
            },
            56: {
                router: "0x10ED43C718714eb63d5aA57B78B54704E256024E",
                providerURL: "https://bsc-dataseed.binance.org",
                preSaleContract: "0x233F8eBbA3C2A31402aCC2781720fC63409aB013",
                fairSaleContract: "0xc261B148a6414A380f50894e216Fd76F9d450491",
                priSaleContract: "0x7f00a9B4DAC1AA363F6543A801E794654C799ed4",
                lockContract: "0x3a374533b2c4DF342C78E1060494B968A6FF3792",
                pmpSaleContract: "0x023260D813F6B4aa872D8eB0a08C1dF995925060",
            },
        }
    },
}

export default data[pos];