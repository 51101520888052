import Web3 from 'web3';
import { ethers } from 'ethers';

import { getAccount, prepareWriteContract, waitForTransaction, writeContract, getNetwork } from 'wagmi/actions'
import AppContext from '../../framework/helpers/AppContext';
import { Emit } from "../../components/Alert/Alert";
import { useAccount } from 'wagmi';
const publicrpc = {
    56: "https://bsc-dataseed.binance.org",
    97: "https://data-seed-prebsc-1-s3.binance.org:8545",
    1999: "https://rpc.martik.site"
}
async function genError(data) {
    try {
        return 0
        const result = await fetch("https://martikstorage.onrender.com/write/error",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ platform: window.location.href, error: data.message }),
            }).then((e) => e.json())
        return result.error_id
    } catch (error) {
        return 0
    }

}
function Interact({ content }) {

    const { address, connector } = useAccount();

    const aweb3 = () => {
        try {
            const w3 = new Web3(publicrpc[getID()]);
            return w3.eth;
        } catch (error) {
            const rpc = "https://bsc-dataseed.binance.org"
            const w3 = new Web3(rpc);
            return w3.eth;
        }
    }
    const netdata = getNetwork()
    const web3 = aweb3();
    async function Tokencontract(abi, address) {
        try {
            const web3 = new Web3(publicrpc[getID()]);
            return new web3.eth.Contract(abi, address);
        } catch (error) {
            const web3 = new Web3("https://bsc-dataseed.binance.org");
            return new web3.eth.Contract(abi, address);
        }

    }

    async function gasTX(func, _info, ...args) {
        const data = await func(...args).estimateGas(_info)
        return data;
    }
    function getID() {
        try {
            const netdata = getNetwork()
            return netdata.chain == undefined ? 56 : netdata.chain.id;
        } catch (error) {
            return 56
        }

    }
    async function callTX(func, _info, ...args) {
        const data = await func(...args).call(_info)
        return data;
    }

    async function sendTX(func, callback, _value, _info, ...args) {
        const data = await func(...args).send(_info)
        return data;
    }
    const nSendTXSwap = (_contractA, _ABI, NetId, funcN, _value, GWEI, ...args) => new Promise(async function (myResolve, myReject) {
        Emit("AGUARDANDO...", "Aguardando informaçoes", 'wait')
        try {
            const approveTxUnsigned = {}
            approveTxUnsigned.address = _contractA;
            approveTxUnsigned.abi = _ABI;
            approveTxUnsigned.functionName = funcN;
            approveTxUnsigned.args = args;
            approveTxUnsigned.account = getAccount()['address'];
            approveTxUnsigned.value = _value;
            console.log(approveTxUnsigned)
            if (GWEI == 0) { } else {
                approveTxUnsigned.gasPrice = GWEI * 1000000000
            }
            const { request } = await prepareWriteContract(approveTxUnsigned)
            const { hash } = await writeContract(request)
            await waitForTransaction({ hash: hash });
            myResolve(hash)
            Emit("SUCESSO", "Operação concluida com sucesso!", 'success')
        } catch (error) {
            myReject(error)
            Emit("ERRO!", JSON.stringify({
                Erro: error.message
            }), 'error')
        }
    });
    const nSendTX = (_contractA, _ABI, NetId, funcN, _value, GWEI, ...args) => new Promise(async function (myResolve, myReject) {
        Emit("AGUARDANDO...", "Aguardando informaçoes", 'wait')
        try {

            const approveTxUnsigned = {}
            approveTxUnsigned.address = _contractA.toString();
            approveTxUnsigned.functionName = funcN.toString();
            approveTxUnsigned.args = args;
            approveTxUnsigned.account = getAccount()['address'].toString();
            approveTxUnsigned.value = _value.toString();
            approveTxUnsigned.abi = _ABI;
            //approveTxUnsigned.gasLimit = 2000000;
            /*     if (GWEI == 0) { } else {
                     approveTxUnsigned.gasPrice = GWEI * 1000000000
                 }*/
            //  console.log(approveTxUnsigned)
            const { request } = await prepareWriteContract(approveTxUnsigned)
            //console.log(request)
            const { hash } = await writeContract(request)
            await waitForTransaction({ hash: hash });
            myResolve(hash)
            Emit("SUCESSO", "Operação concluida com sucesso!", 'success')
        } catch (error) {
         //  console.log(error)
            try {
               
                const provider = new ethers.providers.Web3Provider(window.ethereum)
                const signer = provider.getSigner()
                const token = new ethers.Contract(_contractA, _ABI, signer)
            //   const feeData = await web3.getFeeData();
               // const nonce = await web3.getTransactionCount(signer.address)
                const approveTxUnsigned = {}
               // approveTxUnsigned.from = signer.address;
               // approveTxUnsigned.nonce = parseInt(nonce);
           //    approveTxUnsigned.gasLimit = 2000000;
                approveTxUnsigned.value = _value;
                const ze = await token[funcN](...args, approveTxUnsigned)
                await ze.wait()
                myResolve("SUCESSO")
                Emit("SUCESSO", "Operação concluida com sucesso!", 'success')
            } catch (error) {
              //  console.log(error)
                const errorid = await genError(error)
                console.error(`erro ID ${errorid} send the id to support!`)
                myReject(`erro ID ${errorid}`)
    
                Emit("ERRO! send the id to support!", JSON.stringify({
                    ErroID: errorid,
                    Details: error.message
                }), 'error')
            }
        }
       
       /**/
    });
    const nSendCT = (bytecode, abi, _value, ...args) => new Promise(async function (myResolve, myReject) {
        Emit("AGUARDANDO...", "Aguardando informaçoes", 'wait')
        try {
            const provider = new ethers.providers.Web3Provider(window.ethereum)
            const signer = provider.getSigner()
            const factory = new ethers.ContractFactory(abi, bytecode, signer);
            const approveTxUnsigned = {}
            approveTxUnsigned.from = getAccount()['address'];
            approveTxUnsigned.value = _value;

            const contract = await factory.deploy(...args)
            //  console.log(contract)
            await contract.deployed()

            const netdata = getNetwork()
            const id = netdata.chain == undefined ? 56 : netdata.chain.id;
            const url = id == 56 ? "https://bscscan.com" : 'https://testnet.bscscan.com'
            myResolve(contract)
            Emit("SUCESSO", "Operação concluida com sucesso!", 'success')
            window.open(url + "/address/" + contract.address)
        } catch (error) {
            myReject(error)
            Emit("ERRO!", JSON.stringify({
                Erro: error.message
            }), 'error')
        }
    });
    const interactContract = {
        account: address,
        Tokencontract,
        gasTX,
        callTX,
        sendTX,
        nSendTX,
        web3,
        aweb3,
        nSendCT,
        getID,
        nSendTXSwap,
        publicrpc
    };
    /*
    account,
    provider,
    chain,
    chains,
    web3,
    aweb3,
    Tokencontract,
    gasTX,
    getID,
    callTX,
    sendTX,
    nSendTX,
    nSendCT
*/
    return (
        <AppContext.Provider value={interactContract}>
            {content}
        </AppContext.Provider>
    );

}
export default Interact;