import React, { useEffect, useState } from "react";
import { ethers } from "ethers";
import ReactApexChart from 'react-apexcharts';

import env from '../../hooks/data/Config';
import pairabi from '../../abis/pairabi.json';
import pancakeabi from '../../abis/pankakeRouter.json';
import abi from '../../abis/bnbabi.json';
import Web3 from "web3";
function Chart(props) {
    const rpc = "https://bsc.blockpi.network/v1/rpc/public"
    const web3 = (new Web3(rpc)).eth;
    const router = '0x10ED43C718714eb63d5aA57B78B54704E256024E';
    const WETH = '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c';
    const USD = '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56';
    const [obj, setObj] = useState(props.obj);
    const [_data, setData] = useState([]);
    const [chartData, setChartData] = useState({
        series: [{
            data: _data
        }],
        options: {
            chart: {
                height: 350,
                type: 'line',
                zoom: {
                    enabled: false
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth',
                width: 2
            },

            grid: {
                row: {
                    colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
                    opacity: 0.5
                },
            },
            xaxis: {
                type: 'datetime'
            }
        },
    }
    )
    useEffect(() => {
        data()
    }, [])
    let USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    function nextblock(value, d) {
        return value == undefined ? 0 : parseFloat(ethers.utils.formatUnits(value, d))
    }

    function nNumber(valueA, valueB) {
        return valueB.mul(ethers.utils.parseUnits("1", 18)).div(valueA)
    }
    async function price(token, v) {
        try {
            const cake = new web3.Contract(pancakeabi, router);
            const p1 = await cake.methods.getAmountsOut(v, [WETH, USD]).call()
            return parseFloat(nextblock(p1[1], 18))
        } catch (error) {
            return 0.00
        }
    }
    async function _price(token, v) {
        try {
            if (token == WETH) {
                return await price(token, v)
            } else {

                const cake = new web3.Contract(pancakeabi, router);
                const p0 = await cake.methods.getAmountsOut(v, [token, WETH]).call()
                const p1 = await cake.methods.getAmountsOut(p0[1], [WETH, USD]).call()
                return parseFloat(nextblock(p1[1], 18))
            }
        } catch (error) {
            return 0.00
        }
    }

    async function _events(contract, block, _event, hours) {


        let event = []
        let plus = 130
        for (let index = 0; index < hours; index++) {
            try {
                const eva = web3.filter({
                    address: contract._address,
                    from: block - (plus + (plus * index)),
                    to: block - (plus * index)
                }).get(function (err, result) {
                    return result
                })
                /*console.log({
                    fromBlock: block - (plus + (plus * index)),
                    toBlock: block - (plus * index),
                    eva: eva
                })*/
                event.concat(eva)
            } catch (error) {
                //console.log(error)
                break;
            }
        }
        return event
        /*  let event = []
          let plus = 130
          for (let index = 0; index < hours; index++) {
              try {
                  const eva = await contract.getPastEvents(_event,
                      {
                          fromBlock: block - (plus + (plus * index)),
                          toBlock: block - (plus * index),
                      })
                  //console.log({
                      fromBlock: block - (plus + (plus * index)),
                      toBlock: block - (plus * index),
                      eva: eva
                  })
                  event.concat(eva)
              } catch (error) {
                  //console.log(error)
                  break;
              }
          }
          return event*/
    }
    async function data() {
        // const { rpc, id } = netdata()

        const block = await web3.getBlockNumber()
        const factory = new web3.Contract([
            {
                "constant": true,
                "inputs": [{ "internalType": "address", "name": "", "type": "address" },
                { "internalType": "address", "name": "", "type": "address" }],
                "name": "getPair",
                "outputs": [{ "internalType": "address", "name": "", "type": "address" }],
                "payable": false,
                "stateMutability": "view",
                "type": "function"
            }],
            "0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73");

        const pairA = await factory.methods.getPair(obj.Contract, WETH).call()

        if (pairA != '0x0000000000000000000000000000000000000000') {
            const w = new ethers.providers.JsonRpcProvider(rpc)
            const pair = new ethers.Contract(pairA, pairabi, w)
            const _pair = new web3.Contract(pairabi, pairA);
            const token0 = await _pair.methods.token0().call();
            const is0 = token0 == obj.Contract ? true : false

            const events = await _events(_pair, block, 'Swap', 1)
            //console.log(events)
            let tdata = []
            for (let index = 0; index < events.length; index++) {

                const txinfo = await web3.getTransactionReceipt(events[index].transactionHash)
                const block = await web3.getBlock(txinfo.blockNumber)
                let obj = []

                for (let _index = 0; _index < txinfo.logs.length; _index++) {
                    try {
                        if (txinfo.logs[_index].address == pairA) {
                            obj.push({
                                decodelog: pair.interface.parseLog(txinfo.logs[_index])
                            })
                        }
                    } catch (error) {

                    }
                }
                for (let _index = 0; _index < obj.length; _index++) {
                    if (obj[_index].decodelog.name == "Swap") {
                        tdata.push({
                            x: block.timestamp * 1000,
                            y: is0
                                ? (
                                    (obj[_index].decodelog.args[1] > 0)
                                        ? await price(props.obj.Contract, nNumber(obj[_index].decodelog.args[4], obj[_index].decodelog.args[1]))
                                        : await price(props.obj.Contract, nNumber(obj[_index].decodelog.args[2], obj[_index].decodelog.args[3]))
                                )

                                : (
                                    (obj[_index].decodelog.args[2] > 0)
                                        ? await price(props.obj.Contract, nNumber(obj[_index].decodelog.args[3], obj[_index].decodelog.args[2]))
                                        : await price(props.obj.Contract, nNumber(obj[_index].decodelog.args[1], obj[_index].decodelog.args[4]))
                                ),
                            type: is0
                                ? (obj[_index].decodelog.args[1] > 0) ? "sell" : "buy"
                                : (obj[_index].decodelog.args[2] > 0) ? "sell" : "buy",
                        })
                    } else {

                    }
                }
            }
            const sortlist = tdata.sort((a, b) => a.x - b.x)

            const _sortlist = tdata.sort((a, b) => a.y - b.y)
            if (_sortlist.length > 0) {
                props.setpriceAction(((_sortlist[_sortlist.length - 1].y - _sortlist[0].y) / _sortlist[0].y) * 100)
            }
            setChartData({
                series: [{
                    data: sortlist.sort((a, b) => a.x - b.x)
                }],
                options: {
                    chart: {
                        height: 350,
                        width: '100%',
                        type: 'line',
                        zoom: {
                            enabled: true,
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'smooth',
                        width: 2
                    },

                    grid: {
                        row: {
                            colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
                            opacity: 0.5
                        },
                    },
                    xaxis: {
                        type: 'datetime',
                        labels: {
                            datetimeFormatter: {
                                year: 'yyyy',
                                month: 'MMM \'yy',
                                day: 'dd MMM',
                                hour: 'HH:mm'
                            }
                        }
                    },
                    yaxis: {
                        labels: {
                            formatter: function (value) {
                                return parseFloat(value);
                            }
                        },
                        //  min: parseFloat(_sortlist[0].y) - ((parseFloat(_sortlist[0].y) * 1) / 100),
                        //max: parseFloat(_sortlist[_sortlist.length - 1].y) + ((parseFloat(_sortlist[0].y) * 1) / 100),
                    },
                },
            })

        }

    }

    return (
        <>
            <div id="chart" style={{ width: "100%" }} >
                <ReactApexChart options={chartData.options} series={chartData.series} type="line" width={"100%"} />
            </div>
        </>
    )
}
export default Chart;