import React, { useState, useEffect, useContext } from "react";
import { ethers } from 'ethers';
import abiToken from "../../../abis/bnbabi.json";
import checks from '../../../listverifys.json'
import Timer from '../../../components/Timer/timer.js';
import bnbabi from "../../../abis/bnbabi.json";
import Numberinput from "../../../components/Launchpad/numberInput";
import SAFE from "../../../components/Launchpad/textpar";
import InputBlock from "../../../components/Launchpad/InputBlock";
import DescBlock from "../../../components/Launchpad/descBlock";
import Button from "../../../components/Launchpad/Button";
import Modal from "../../../components/Launchpad/SaleModal";
import abifac from "../../../abis/presale/preFactory.json";
import abi from "../../../abis/presale/preAbi.json";
import AppContext from "../../../framework/helpers/AppContext";
import env from '../../../hooks/data/Config';
import Checkbox from "../../../components/Launchpad/Checkbox";
import TokenNomics from '../../../components/Launchpad/TokenNomics'; import AffHub from '../../../components/Launchpad/AffHub'
import ProjectData from '../../../config';
let projectName = "Martik"
const {
  Name,
  Logo,
  backgroundColor,
  buttonsColor,
  buttonsBorders,
  inputsColor,
  inputsBorders,
  outbuttonsColor,
  Borders,
  outFont,
  innerFont,
  Routers,
  screens
} = ProjectData[window.location.host.split('.')[0] != undefined
  ? window.location.host.split('.')[0] == "app"
    ? window.location.host.split('.')[0] + "." + window.location.host.split('.')[1]
    : window.location.host.split('.')[0] : "Martik"]

const Web3 = require('web3-eth');

const Page = (props) => {
  const {
    account,
    provider,
    chain,
    chains,
    web3,
    Tokencontract,
    gasTX,
    getID,
    callTX,
    sendTX,
    nSendTX,
    nSendCT
  } = useContext(AppContext)

  const [address, setaddress] = useState(account);
  const [object, setobject] = useState({});
  const [profile, setprofile] = useState({}); const [seconds, setSeconds] = useState({});
  const [allowance, setAllow] = useState(0);
  const [white, setwhite] = useState(0);
  const [whitelist, setwhitelist] = useState([]);
  const token = {
    97: [
      '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd',
      '0xaB1a4d4f1D656d2450692D237fdD6C7f9146e814',
      '0xFa60D973F7642B748046464e165A65B7323b0DEE'
    ],
    56: [
      '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      '0x55d398326f99059fF775485246999027B3197955'
    ],
  }

  useEffect(() => {
    //document.title = "Pre Sale"
    load()

  }, []);
  function nextpercent(accountBalancemctTB, d) {
    return parseFloat((accountBalancemctTB) + "").toFixed(2)
  }
  function nextblock(accountBalancemctTB, d) {
    return accountBalancemctTB == undefined ? 0 : parseFloat(ethers.utils.formatUnits(accountBalancemctTB, d)).toFixed(2)
  }

  function returnbignumber(value, dec) {
    let a = ['']
    let e = value + ""
    if (e.toString().includes(",")) {
      a = e.toString().split(",")
      e = ethers.utils.parseUnits(a[0] + "." + a[1], dec).toString()
    } else {
      e = ethers.utils.parseUnits(e, dec).toString()
    }

    return e
  }
  async function load() {
    const contractSALE = ethers.utils.getAddress(window.location.pathname.toString().split("/")[window.location.pathname.toString().split("/").length - 1])

    const fac = await Tokencontract(abifac, env.CONTRACTS[getID()].preSaleContract)
    const prf =
    {
      LogoURL: await fac.methods.profile(contractSALE, 0).call(),
      Website: await fac.methods.profile(contractSALE, 1).call(),
      Facebook: await fac.methods.profile(contractSALE, 2).call(),
      Twitter: await fac.methods.profile(contractSALE, 3).call(),
      Github: await fac.methods.profile(contractSALE, 4).call(),
      Telegram: await fac.methods.profile(contractSALE, 5).call(),
      Discord: await fac.methods.profile(contractSALE, 6).call(),
      Instagram: await fac.methods.profile(contractSALE, 7).call(),
      Reddit: await fac.methods.profile(contractSALE, 8).call(),
      YoutubeVideo: await fac.methods.profile(contractSALE, 9).call(),
      Description: await fac.methods.profile(contractSALE, 10).call()
    }
    setprofile(prf)
    const sale = await Tokencontract(abi, contractSALE)
    const tokenct = await sale.methods._contract().call()
    const tct = await Tokencontract(abiToken, tokenct)

    //document.title = await tct.methods.name().call() + " - Pre Sale"
    const obj = {
      title: '',
      cts: contractSALE,
      contract: tokenct,
      tokenname: await tct.methods.name().call(),
      tokesymbol: await tct.methods.symbol().call(),
      tokendec: await tct.methods.decimals().call(),
      tokents: await tct.methods.totalSupply().call(),
      presale_rate: await sale.methods.presale_rate().call(),
      contributors: await sale.methods.contributors().call(),
      uToken: await sale.methods.uToken().call(),
      listOP: await sale.methods.listOP().call(),
      Affiliated: await sale.methods.Affiliated().call(),
      AffiliatedList: await sale.methods.Refs().call(),
      listRate: await sale.methods.listRate().call(),
      refundType: await sale.methods.refundType().call(),
      softcap: await sale.methods.softcap().call(),
      hardcap: await sale.methods.hardcap().call(),
      minbuy: await sale.methods.minbuy().call(),
      maxbuy: await sale.methods.maxbuy().call(),
      LiquidifyPercent: await sale.methods.percentLiquidity().call(),
      Locktime: await sale.methods.liquidity_lockup().call(),
      starttime: await sale.methods.startTime().call(),
      endtime: await sale.methods.endTime().call(),
      balance: await sale.methods.contractBalance().call(),
      ubalance: address != undefined ? await balanceOf(sale, address) : "0",
      owner: await sale.methods._owner().call(),
      ended: await sale.methods.ended().call(),
      bal: await sale.methods.balance().call(),
      amount: address != undefined ? await sale.methods.user_amount(address).call() : 0,
      invested: address != undefined ? await sale.methods.user_amount_BNB(address).call() : 0
    }
    setobject(obj)
    AllowOf(obj)
  }
  function progress() {
    if (object.balance == undefined) { return '0' }
    if (object.hardcap == undefined) { return '0' }
    return ((nextblock(object.bal > 0 ? object.bal : object.balance, 18) / nextblock(object.hardcap, 18)) * 100).toString()
  }

  async function balanceOf(sale, acc) {
    const uk = await sale.methods.uToken().call()
    if (uk == 0) {
      const retorno = await web3.getBalance(acc)
      return retorno
    } else {
      const tct = await Tokencontract(abiToken, token[getID()][uk])
      const retorno = await tct.methods.balanceOf(acc).call()
      return retorno
    }
  }
  async function AllowOf(obj) {
    const sale = await Tokencontract(abi, obj.cts)
    const uk = await sale.methods.uToken().call()
    if (uk == 0) {
      setAllow("1" + "0".repeat(50))
    } else {
      const tct = await Tokencontract(abiToken, token[getID()][uk])
      const all = await tct.methods.allowance(address, obj.cts).call()
      setAllow(all)
    }
  }
  async function approve() {
    nSendTX(token[getID()][object.uToken], abiToken, getID(), 'approve', '0', 0, object.cts, '1' + '0'.repeat(50)).then(a => {
      AllowOf(object)
    }).catch(e => {
      //console.log(e)
    })
  }
  function endedccheck() {
    if (!object.ended) {
      if (parseInt(Date.parse(new Date()) / 1000) >= parseInt(object.starttime)) {
        if (parseInt(Date.parse(new Date()) / 1000) < parseInt(object.endtime)) {
          return "Sale live"
        } else {
          return "Ended"
        }
      } else {
        return "Comming"
      }
    } else {
      if (object.bal > 0) {
        return "FINALIZED"
      } else {
        return "Canceled"
      }
    }

  }

  function getQuery(query) {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const foo = params.get(query);
    return foo
  }

  const Modal = (props) => {
    const [investiment, setInvestiment] = useState(0);
    function progress() {
      if (props.obj.balance == undefined) { return '0' }
      if (props.obj.hardcap == undefined) { return '0' }
      return ((nextblock(props.obj.bal > 0 ? props.obj.bal : props.obj.balance, 18) / nextblock(props.obj.hardcap, 18)) * 100).toString()
    }
    return (
      <>
        <div style={{
          height: "min-content",
          minHeight: "70px",
          width: "280px",
          margin: "0px auto",
        }}>

          <div style={{
            height: "min-height",
            minHeight: "70px",
            width: "280px",
            display: "grid",
            gridTemplateRows: "auto",

            margin: "auto",
            marginTop: "15px",
            border: "1px solid #ffffff10",
            background: "#ffffff10",
            marginBottom: "15px",
            color: "#fff",
            borderRadius: "10px",
            padding: "0px 5px"
          }}>
            <div style={{
              height: "min-content",
              width: "100%",
              margin: "auto",
              display: "grid",
              gap: "5px"
            }}>
              <button style={{
                display: "grid", gridTemplateColumns: "auto", height: "45px", width: "90%", color: "#fff", background: "#00000000", borderRadius: "10px", border: "1px solid #00000000", margin: "auto"
              }}>
                <Timer endtime={parseInt(Date.parse(new Date()) / 1000) >= parseInt(props.obj.starttime) ? (new Date(parseInt(props.obj.endtime * 1000))).toString() : (new Date(parseInt(props.obj.starttime * 1000))).toString()} />
              </button>
              <div style={{
                position: " relative",
                width: "95%",
                height: "20px",
                backgroundColor: "#000",
                margin: "auto",
                marginTop: "30px",
                marginBottom: "2px",
                borderRadius: "5px",

              }}>
                <div style={{
                  position: "absolute",
                  width: progress() + "%",
                  height: "100%",
                  backgroundColor: "#999",
                  borderRadius: "5px"
                }}></div>
              </div>
              <p style={{ width: "95%", display: "grid", gridTemplateColumns: "auto auto", margin: "auto", marginTop: "0px" }}><span style={{
                textAlign: "left",
                width: "100%",

                color: "#ddd",
                fontSize: "12px",

              }}>{nextblock(props.obj.bal > 0 ? props.obj.bal : props.obj.balance, 18) + (object.uToken == 0 ? " BNB" : object.uToken == 1 ? " BUSD" : object.uToken == 2 ? " USDT" : " BNB")} </span><span style={{
                textAlign: "right",
                width: "100%",
                fontSize: "12px",
                color: "#ddd",
              }}>
                  {nextblock(props.obj.hardcap, 18) + (object.uToken == 0 ? " BNB" : object.uToken == 1 ? " BUSD" : object.uToken == 2 ? " USDT" : " BNB")}
                </span></p>
              {endedccheck() == "Sale live" && <div style={{ display: "grid", gridTemplateColumns: "auto ", backgroundColor: "#00000050", border: "1px solid #ffffff10", height: "40px", width: "95%", margin: "auto", marginBottom: "5px", marginTop: "15px", borderRadius: "10px" }}>
                <div style={{
                  height: "100%",
                  width: "100%",
                  margin: "auto",
                  display: "grid", gridTemplateColumns: " auto 50px",
                }}>

                  <div style={{ height: props.heighti, width: "100%", display: "grid", gridTemplateColumns: "100%" }}>
                    <input type="number" id={'investINPUT'} onChange={(e) => {
                      setInvestiment(e.target.value)
                    }} value={investiment} placeholder={'Amount ' + (object.uToken == 0 ? " BNB" : object.uToken == 1 ? " BUSD" : object.uToken == 2 ? " USDT" : " BNB") + ' to invest'} style={{ backgroundColor: "#1e293b00", outline: "none", textAlign: "right", border: "none", height: "100%", width: "100%", margin: "auto", color: "#fff", fontSize: "13px" }} />
                  </div>
                  <p onClick={() => {


                    setInvestiment(nextblock(props.obj.ubalance, 18))

                  }} style={{
                    margin: "auto",
                    height: "min-content",
                    width: "100%",
                    display: "grid",
                    margin: "auto",
                    cursor: "pointer"
                  }}><p style={{ margin: "auto", fontWeight: "bold", fontSize: "14px" }}>MAX</p></p>
                </div>
              </div>}

              {endedccheck() == "Sale live" ?
                (parseFloat(nextblock(allowance, 18)) > investiment)
                  ?
                  <Button func={() => {
                    nSendTX(
                      object.cts,
                      abi,
                      getID(),
                      "_buy",
                      object.uToken == 0 ? returnbignumber(investiment, 18) : '0',
                      0,
                      returnbignumber(investiment, 18),
                      getQuery('refFrom') == null ? '0x0000000000000000000000000000000000000000' : getQuery('refFrom')
                    ).then(a => {
                      load()
                    }).catch(e => {
                      //console.log(e)
                    })
                  }} text={"INVEST"} />

                  :
                  <Button func={() => {
                    approve()
                  }} text={"APPROVE"} /> : <></>}

              <p></p>
            </div>


          </div>
          <div style={{
            height: "min-height",
            minHeight: "70px",
            width: "280px",
            display: "grid",
            gridTemplateRows: "auto",

            margin: "auto",
            marginTop: "15px",
            border: "1px solid #ffffff10",
            background: "#ffffff10",
            marginBottom: "15px",
            color: "#fff",
            borderRadius: "10px",
            padding: " 15px 0px"
          }}>
            <div style={{
              height: "min-content",
              width: "100%",
              margin: "auto",
              display: "grid",
              gap: "10px"
            }}>
              <SAFE what={"Listing on"} result={"Pancake Swap"} />
              <SAFE what={"Sale Type"} result={"Public"} />
              <SAFE what={"Contributors"} result={object.contributors} />
              <SAFE what={"Minimum buy"} result={nextblock(props.obj.minbuy, 18) + (object.uToken == 0 ? " BNB" : object.uToken == 1 ? " BUSD" : object.uToken == 2 ? " USDT" : " BNB")} />
              <SAFE what={"Maximum buy"} result={nextblock(props.obj.maxbuy, 18) + (object.uToken == 0 ? " BNB" : object.uToken == 1 ? " BUSD" : object.uToken == 2 ? " USDT" : " BNB")} />


            </div>


          </div>
          {object.invested > 0 && <div style={{
            height: "min-height",
            minHeight: "70px",
            width: "280px",
            display: "grid",
            gridTemplateRows: "auto",

            margin: "auto",
            marginTop: "15px",
            border: "1px solid #ffffff10",
            background: "#ffffff10",
            marginBottom: "15px",
            color: "#fff",
            borderRadius: "10px",
            padding: " 15px 0px",
            gap: "5px"
          }}>

            <p style={{ textAlign: "left", height: "min-content", margin: "auto" }}><span style={{ textAlign: "left", fontWeight: "bold", fontSize: "14px" }}>MY CONTRIBUTION</span></p>
            <SAFE what={"Amount to receive"} result={nextblock(props.obj.amount, props.obj.tokendec) + " " + props.obj.tokesymbol} />
            <SAFE what={"Amount invested"} result={nextblock(props.obj.invested, 18) + (object.uToken == 0 ? " BNB" : object.uToken == 1 ? " BUSD" : object.uToken == 2 ? " USDT" : " BNB")} />
            {endedccheck() == "FINALIZED" &&
              <Button func={() => {
                nSendTX(
                  object.cts,
                  abi,
                  getID(),
                  "claim",
                  '0', 0
                ).then(a => {
                  load()
                }).catch(e => {
                  //console.log(e)
                })
              }} text={"CLAIM"} />
            }
            {endedccheck() != "FINALIZED" &&

              <Button func={() => {
                nSendTX(
                  object.cts,
                  abi,
                  getID(),
                  "uwithdraw",
                  '0', 0
                )
              }} text={"EMERGENCY WITHDRAW"} />
            }


            <p></p>
          </div>}
          {object.owner == address && <div style={{
            height: "min-height",
            minHeight: "70px",
            width: "280px",
            display: "grid",
            gridTemplateRows: "auto",

            margin: "auto",
            marginTop: "15px",
            border: "1px solid #ffffff10",
            background: "#ffffff10",
            marginBottom: "15px",
            color: "#fff",
            borderRadius: "10px",
            padding: " 15px 0px",
            gap: "5px"
          }}>

            <p style={{ textAlign: "left", height: "min-content", margin: "auto" }}><span style={{ textAlign: "left", fontWeight: "bold", fontSize: "14px" }}>Owner</span></p>
            <div style={{
              display: "grid", gridTemplateColumns: "auto", height: "min-content", width: "95%", color: "#fff", margin: "auto", background: "#ffff0010", borderRadius: "5px", border: "1px solid #ffff00", padding: "10px 0px"
            }}>
              <p style={{ textAlign: "center", fontSize: "12px", height: "min-content", margin: "auto", color: "#fff" }}>To make sure there will be no issues during the presale time, please do not send tokens to wallets before you finalize the presale pool.</p>
            </div>
            <div style={{
              display: "grid", gridTemplateColumns: "auto", height: "min-content", width: "95%", color: "#fff", margin: "auto", background: "#ffffff10", borderRadius: "5px", border: "1px solid #ffffff", padding: "10px 0px"
            }}>
              <p style={{ textAlign: "center", fontSize: "12px", height: "min-content", margin: "auto", color: "#fff" }}>To Finalize presale, you have to exclude token transfer fee for presale contract.</p>
            </div>
            <div className='text-sm font-bold px-2 mt-5 text-primary dark:text-white'>Whitelist</div>
            <div className='flex items-center text-xs laptop:text-sm gap-2 px-2 mb-6'>
              <Checkbox title={"Disable"} id={0} variavel={white} setvariavel={setwhite} />
              <Checkbox title={"Enable"} id={1} variavel={white} setvariavel={setwhite} />
            </div>

            {white == 1 && <DescBlock title={""} place={"[0x...]"} variavel={whitelist} setvariavel={(e) => { setwhitelist(e.split(",")) }} desc={""} />}
            {white == 1 && <Button func={() => {
              nSendTX(
                object.cts,
                abi,
                getID(),
                "add_to_whiteList",
                '0', 0,
                whitelist
              ).then(a => {
                load()
              }).catch(e => {
                console.log(e)
              })
            }} text={"ADD TO WHITELIST"} />}

            <Button func={() => {
              nSendTX(
                object.cts,
                abi,
                getID(),
                "cancelLaunchPad",
                '0', 0
              ).then(a => {
                load()
              }).catch(e => {
                //console.log(e)
              })
            }} text={"Cancel"} />

            <Button func={() => {
              nSendTX(
                object.cts,
                abi,
                getID(),
                "finalize",
                '0', 0
              ).then(a => {
                load()
              }).catch(e => {
                //console.log(e)
              })
            }} text={"Finalize"} />

            <p></p>
          </div>}
          <div style={{
            height: "min-height",
            minHeight: "70px",
            width: "280px",
            display: "grid",
            gridTemplateRows: "auto",

            margin: "auto",
            marginTop: "15px",
            border: "1px solid #ffffff10",
            background: "#ffffff10",
            marginBottom: "15px",
            color: "#fff",
            borderRadius: "10px",
            padding: " 15px 0px",
            gap: "5px"
          }}>
            {object.cts != undefined && <AffHub claimable={true} nSendTX={nSendTX} status={endedccheck} ctAbi={abi} ct={object.cts} Tokencontract={Tokencontract} account={address} netId={getID} />}
          </div>
          <div style={{
            height: "min-height",
            minHeight: "70px",
            width: "280px",
            display: "grid",
            gridTemplateRows: "auto",

            margin: "auto",
            marginTop: "15px",
            border: "1px solid #ffffff10",
            background: "#ffffff10",
            marginBottom: "15px",
            color: "#fff",
            borderRadius: "10px",
            padding: " 15px 0px",
            gap: "5px"
          }}>
            {object.contract != undefined && <TokenNomics obj={object} Tokencontract={Tokencontract} Contract={object.contract} ct={env.CONTRACTS[getID()].lockContract} />}
          </div>
          <p style={{
            textAlign: "left",
            width: "95%",
            fontSize: "13px",
            fontWeight: "bold",
            margin: "0 auto"
          }}>DISCLAIMER</p>

          <p style={{
            textAlign: "justify",
            width: "95%",
            fontSize: "11px",
            color: "#e5e5e5",
            margin: "0 auto"
          }}>
            THIS IS NOT AN INVESTMENT RECOMMENDATION! ALSO DO YOUR ANALYSIS AND INVEST ONLY IN YOUR RESPONSIBILITY.
            AT {Name} WE TAKE CARE OF OUR ANALYSIS SO THAT THEY ARE THE CLOSEST TO THE RESULT, BUT WE DON'T HAVE THE TOKENS THAT ARE HERE AND WE CANNOT CONTROL THE MARKET, SO ANY DECISION MADE FROM OUR ANALYSIS DOES NOT GUARANTEE RETURN, HAVE RECOGNITION THAT IT WILL BE YOURS RESPONSIBILITY.
            THANK YOU FOR UNDERSTANDING.
          </p>
        </div>

      </>


    );
  }
  function getTimeRemaining(endtime) {
    const total = Date.parse(endtime) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    const days = Math.floor(total / (1000 * 60 * 60 * 24));
    if (total < 0) {
      return "Ended";
    } else {
      return days + "D " + hours + "H "
        + minutes + "M " + seconds + "S"
    }

  }
  function getyoutubeVideo(e) {
    if (e == undefined) {
      return "";
    }
    if (e.toString().includes("youtu.be")) {
      return e.toString().split("/")[e.toString().split("/").length - 1];
    }
    if (e.toString().includes("watch?v")) {
      return e.toString().split("&")[0].toString().split("watch?v=")[
        e.toString().split("&")[0].toString().split("watch?v=").length - 1
      ];
    }
    return "img:" + e;
  }
  function endedccheck() {
    if (!object.ended) {
      if (parseInt(Date.parse(new Date()) / 1000) >= parseInt(object.starttime)) {
        if (parseInt(Date.parse(new Date()) / 1000) < parseInt(object.endtime)) {
          return "Sale live"
        } else {
          return "Ended"
        }
      } else {
        return "Comming"
      }
    } else {
      if (object.bal > 0) {
        return "FINALIZED"
      } else {
        return "Canceled"
      }
    }

  }
  let stakeColumns = <div style={{ width: "100%", margin: "auto", display: "grid" }}>
    <div style={{
      height: "min-content",
      width: "100%",
      display: "grid",
      gridTemplateColumns: window.innerWidth > 600 ? " auto 280px" : "auto",
      gap: "10px",
      borderRadius: "10px",
      marginTop: "auto",
      color: "#fff",

    }}>

      <div style={{
        height: "min-content",
        width: "100%",
        display: "grid",
        gap: "5px",

      }}>
        <div style={{
          height: "min-content",
          width: "90%",
          margin: "auto",
          display: "grid",
          gridTemplateColumns: "75px auto",
          gap: "5px",
          fontSize: "15px",

        }}>
          <div style={{
            height: "50px",
            width: "50px",
            color: "#fff",
            margin: "auto",
            background: "url('" + profile['LogoURL'] + " ') center center / 50px no-repeat",

            display: "grid",
            borderRadius: "360px",

          }}>

          </div>
          <div style={{
            height: "min-content",
            width: "100%",
            margin: "auto",
            display: "grid",

            gap: "5px",
            fontSize: "15px",

          }}>
            <div style={{
              height: "min-content",
              width: "100%",
              margin: "auto",
              display: "grid",
              gridTemplateColumns: "auto 180px",
              gap: "5px",


            }}>
              <p style={{
                textAlign: "left",
                width: "100%",
                height: "min-content",
                fontSize: "15px",
                margin: "auto",
                color: "#e5e5e5",
                paddingTop: "5px"
              }}>{object.tokenname} - Pre sale

              </p>
              <div style={{
                height: "25px",
                width: "180px",
                display: "inline-flex",
                gap: "7px"
              }}>
                {object.owner == address && <div onClick={() => { window.location.href = "/pre-sale/edit/" + object.cts }} style={{
                  height: "25px",
                  width: "20px",
                  display: "grid",
                  background: "#ffff0099",
                  borderRadius: "5px",

                  color: "#000",
                  cursor: "pointer"
                }}>

                  <svg xmlns="http://www.w3.org/2000/svg" style={{ height: "15px", width: "15px", margin: "auto" }} fill="currentColor" class="bi bi-pencil-fill" viewBox="0 0 16 16">
                    <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                  </svg>

                </div>}
                {object.Affiliated > 0 &&
                  <div style={{
                    height: "25px",
                    width: "80px",
                    display: "inline-flex",
                    background: "#000000",
                    borderRadius: "5px",
                    paddingRight: "10px"
                  }}>
                    <div style={{
                      height: "10px",
                      width: "10px",
                      margin: "auto",
                      background: "#fff",
                      borderRadius: "360px",
                    }}></div>
                    <p style={{ textAlign: "center", height: "min-content", fontSize: "10px", margin: "auto 0" }}>Affiliate {object.Affiliated} %</p>

                  </div>
                }

                <div style={{
                  height: "25px",
                  width: "80px",
                  display: "inline-flex",
                  background: "#000000",
                  borderRadius: "5px",
                  paddingRight: "10px"
                }}>
                  <div style={{
                    height: "10px",
                    width: "10px",
                    margin: "auto",
                    background: parseInt(Date.parse(new Date()) / 1000) >= parseInt(object.starttime) ? parseInt(Date.parse(new Date()) / 1000) < parseInt(object.endtime) ? "#0000ff" : "#ff0000" : "#ffff00",
                    borderRadius: "360px",
                  }}></div>
                  <p style={{ textAlign: "center", height: "min-content", fontSize: "10px", margin: "auto 0" }}>{endedccheck()}</p>

                </div>
              </div>
              <div style={{
                height: "min-content",
                width: "100%",
                margin: "auto",
                display: "flex",
                gap: "5px",
                fontSize: "15px",

              }}>
                <div style={{ display: "flex", gap: "5px" }}>
                  {(profile['Website'] == undefined ? "" : profile['Website']).toString().length > 0 && <a href={profile['Website']} rel="nofollow noreferrer" target="_blank" >
                    <svg
                      stroke="#fff" fill="none" viewBox="0 0 24 24"
                      height="1em" style={{ fill: "#ffffff00", height: "23px", width: "23px" }} xmlns="http://www.w3.org/2000/svg">
                      <circle cx={12} cy={12} r={10} />
                      <line x1={2} y1={12} x2={22} y2={12} />
                      <path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z" />
                    </svg>
                  </a>}
                  {(profile['Facebook'] == undefined ? "" : profile['Facebook']).toString().length > 0 && <a href={profile['Facebook']} rel="nofollow noreferrer" target="_blank" >
                    <svg stroke="currentColor" fill="currentColor" strokeWidth={0} viewBox="0 0 24 24"
                      height="1em" style={{ fill: "#fff", height: "23px", width: "23px" }} xmlns="http://www.w3.org/2000/svg">
                      <g>
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M13 19.938A8.001 8.001 0 0 0 12 4a8 8 0 0 0-1 15.938V14H9v-2h2v-1.654c0-1.337.14-1.822.4-2.311A2.726 2.726 0 0 1 12.536 6.9c.382-.205.857-.328 1.687-.381.329-.021.755.005 1.278.08v1.9H15c-.917 0-1.296.043-1.522.164a.727.727 0 0 0-.314.314c-.12.226-.164.45-.164 1.368V12h2.5l-.5 2h-2v5.938zM12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10z" />
                      </g>
                    </svg>
                  </a>}
                  {(profile['Twitter'] == undefined ? "" : profile['Twitter']).toString().length > 0 && <a href={profile['Twitter']} rel="nofollow noreferrer" target="_blank" >
                    <svg stroke="#222" fill="none" viewBox="0 0 24 24"
                      height="1em" style={{ fill: "#fff", height: "23px", width: "23px" }} xmlns="http://www.w3.org/2000/svg">
                      <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z" />
                    </svg>
                  </a>}
                  {(profile['Github'] == undefined ? "" : profile['Github']).toString().length > 0 && <a href={profile['Github']} rel="nofollow noreferrer" target="_blank" >
                    <svg stroke="#222" fill="none" viewBox="0 0 24 24"
                      height="1em" style={{ fill: "#fff", height: "23px", width: "23px" }} xmlns="http://www.w3.org/2000/svg">
                      <path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22" />
                    </svg>
                  </a>}
                  {(profile['Telegram'] == undefined ? "" : profile['Telegram']).toString().length > 0 && <a href={profile['Telegram']} rel="nofollow noreferrer" target="_blank" >
                    <svg stroke="currentColor" fill="currentColor" strokeWidth={0} viewBox="0 0 448 512"
                      height="1em" style={{ fill: "#fff", height: "23px", width: "23px" }} xmlns="http://www.w3.org/2000/svg">
                      <path d="M446.7 98.6l-67.6 318.8c-5.1 22.5-18.4 28.1-37.3 17.5l-103-75.9-49.7 47.8c-5.5 5.5-10.1 10.1-20.7 10.1l7.4-104.9 190.9-172.5c8.3-7.4-1.8-11.5-12.9-4.1L117.8 284 16.2 252.2c-22.1-6.9-22.5-22.1 4.6-32.7L418.2 66.4c18.4-6.9 34.5 4.1 28.5 32.2z" />
                    </svg>
                  </a>
                  }
                  {(profile['Instagram'] == undefined ? "" : profile['Instagram']).toString().length > 0 && <a href={profile['Instagram']} rel="nofollow noreferrer" target="_blank" >
                    <svg stroke="currentColor" fill="currentColor" strokeWidth={0}
                      viewBox="0 0 1024 1024" height="1em" style={{ fill: "#fff", height: "23px", width: "23px" }} xmlns="http://www.w3.org/2000/svg">
                      <path d="M512 306.9c-113.5 0-205.1 91.6-205.1 205.1S398.5 717.1 512 717.1 717.1 625.5 717.1 512 625.5 306.9 512 306.9zm0 338.4c-73.4 0-133.3-59.9-133.3-133.3S438.6 378.7 512 378.7 645.3 438.6 645.3 512 585.4 645.3 512 645.3zm213.5-394.6c-26.5 0-47.9 21.4-47.9 47.9s21.4 47.9 47.9 47.9 47.9-21.3 47.9-47.9a47.84 47.84 0 0 0-47.9-47.9zM911.8 512c0-55.2.5-109.9-2.6-165-3.1-64-17.7-120.8-64.5-167.6-46.9-46.9-103.6-61.4-167.6-64.5-55.2-3.1-109.9-2.6-165-2.6-55.2 0-109.9-.5-165 2.6-64 3.1-120.8 17.7-167.6 64.5C132.6 226.3 118.1 283 115 347c-3.1 55.2-2.6 109.9-2.6 165s-.5 109.9 2.6 165c3.1 64 17.7 120.8 64.5 167.6 46.9 46.9 103.6 61.4 167.6 64.5 55.2 3.1 109.9 2.6 165 2.6 55.2 0 109.9.5 165-2.6 64-3.1 120.8-17.7 167.6-64.5 46.9-46.9 61.4-103.6 64.5-167.6 3.2-55.1 2.6-109.8 2.6-165zm-88 235.8c-7.3 18.2-16.1 31.8-30.2 45.8-14.1 14.1-27.6 22.9-45.8 30.2C695.2 844.7 570.3 840 512 840c-58.3 0-183.3 4.7-235.9-16.1-18.2-7.3-31.8-16.1-45.8-30.2-14.1-14.1-22.9-27.6-30.2-45.8C179.3 695.2 184 570.3 184 512c0-58.3-4.7-183.3 16.1-235.9 7.3-18.2 16.1-31.8 30.2-45.8s27.6-22.9 45.8-30.2C328.7 179.3 453.7 184 512 184s183.3-4.7 235.9 16.1c18.2 7.3 31.8 16.1 45.8 30.2 14.1 14.1 22.9 27.6 30.2 45.8C844.7 328.7 840 453.7 840 512c0 58.3 4.7 183.2-16.2 235.8z" />
                    </svg>
                  </a>}
                  {(profile['Discord'] == undefined ? "" : profile['Discord']).toString().length > 0 && <a href={profile['Discord']} rel="nofollow noreferrer" target="_blank" >
                    <svg stroke="currentColor" fill="currentColor" strokeWidth={0} viewBox="0 0 448 512"
                      height="1em" style={{ fill: "#fff", height: "23px", width: "23px" }} xmlns="http://www.w3.org/2000/svg">
                      <path d="M297.216 243.2c0 15.616-11.52 28.416-26.112 28.416-14.336 0-26.112-12.8-26.112-28.416s11.52-28.416 26.112-28.416c14.592 0 26.112 12.8 26.112 28.416zm-119.552-28.416c-14.592 0-26.112 12.8-26.112 28.416s11.776 28.416 26.112 28.416c14.592 0 26.112-12.8 26.112-28.416.256-15.616-11.52-28.416-26.112-28.416zM448 52.736V512c-64.494-56.994-43.868-38.128-118.784-107.776l13.568 47.36H52.48C23.552 451.584 0 428.032 0 398.848V52.736C0 23.552 23.552 0 52.48 0h343.04C424.448 0 448 23.552 448 52.736zm-72.96 242.688c0-82.432-36.864-149.248-36.864-149.248-36.864-27.648-71.936-26.88-71.936-26.88l-3.584 4.096c43.52 13.312 63.744 32.512 63.744 32.512-60.811-33.329-132.244-33.335-191.232-7.424-9.472 4.352-15.104 7.424-15.104 7.424s21.248-20.224 67.328-33.536l-2.56-3.072s-35.072-.768-71.936 26.88c0 0-36.864 66.816-36.864 149.248 0 0 21.504 37.12 78.08 38.912 0 0 9.472-11.52 17.152-21.248-32.512-9.728-44.8-30.208-44.8-30.208 3.766 2.636 9.976 6.053 10.496 6.4 43.21 24.198 104.588 32.126 159.744 8.96 8.96-3.328 18.944-8.192 29.44-15.104 0 0-12.8 20.992-46.336 30.464 7.68 9.728 16.896 20.736 16.896 20.736 56.576-1.792 78.336-38.912 78.336-38.912z" />
                    </svg>
                  </a>}
                  {(profile['Reddit'] == undefined ? "" : profile['Reddit']).toString().length > 0 && <a href={profile['Reddit']} rel="nofollow noreferrer" target="_blank" >
                    <svg stroke="currentColor" fill="currentColor" strokeWidth={0}
                      viewBox="0 0 1024 1024" height="1em" style={{ fill: "#fff", height: "23px", width: "23px" }} xmlns="http://www.w3.org/2000/svg">
                      <path d="M288 568a56 56 0 1 0 112 0 56 56 0 1 0-112 0zm338.7 119.7c-23.1 18.2-68.9 37.8-114.7 37.8s-91.6-19.6-114.7-37.8c-14.4-11.3-35.3-8.9-46.7 5.5s-8.9 35.3 5.5 46.7C396.3 771.6 457.5 792 512 792s115.7-20.4 155.9-52.1a33.25 33.25 0 1 0-41.2-52.2zM960 456c0-61.9-50.1-112-112-112-42.1 0-78.7 23.2-97.9 57.6-57.6-31.5-127.7-51.8-204.1-56.5L612.9 195l127.9 36.9c11.5 32.6 42.6 56.1 79.2 56.1 46.4 0 84-37.6 84-84s-37.6-84-84-84c-32 0-59.8 17.9-74 44.2L603.5 123a33.2 33.2 0 0 0-39.6 18.4l-90.8 203.9c-74.5 5.2-142.9 25.4-199.2 56.2A111.94 111.94 0 0 0 176 344c-61.9 0-112 50.1-112 112 0 45.8 27.5 85.1 66.8 102.5-7.1 21-10.8 43-10.8 65.5 0 154.6 175.5 280 392 280s392-125.4 392-280c0-22.6-3.8-44.5-10.8-65.5C932.5 541.1 960 501.8 960 456zM820 172.5a31.5 31.5 0 1 1 0 63 31.5 31.5 0 0 1 0-63zM120 456c0-30.9 25.1-56 56-56a56 56 0 0 1 50.6 32.1c-29.3 22.2-53.5 47.8-71.5 75.9a56.23 56.23 0 0 1-35.1-52zm392 381.5c-179.8 0-325.5-95.6-325.5-213.5S332.2 410.5 512 410.5 837.5 506.1 837.5 624 691.8 837.5 512 837.5zM868.8 508c-17.9-28.1-42.2-53.7-71.5-75.9 9-18.9 28.3-32.1 50.6-32.1 30.9 0 56 25.1 56 56 .1 23.5-14.5 43.7-35.1 52zM624 568a56 56 0 1 0 112 0 56 56 0 1 0-112 0z" />
                    </svg>
                  </a>}
                </div>


              </div>
              <div style={{
                height: "min-content",
                width: "100%",
                margin: "auto",
                display: "flex",
                gap: "5px",
                fontSize: "15px",

              }}>
                {(checks[0][object.cts] != undefined && checks[0][object.cts].AUDIT) && <button onClick={() => { window.open(checks[0][object.cts].ALINK) }} style={{
                  display: "grid", gridTemplateColumns: "auto", height: "35px", width: "60px", background: "#00bdd6", borderRadius: "10px", border: "1px solid #00000020", color: "#fff"
                }}>
                  <p style={{ textAlign: "left", height: "min-content", margin: "auto" }}><span style={{ textAlign: "left", fontWeight: "bold", fontSize: "12px" }}>AUDIT</span></p>
                </button>}
                {(checks[0][object.cts] != undefined && checks[0][object.cts].KYC) && <button style={{
                  display: "grid", gridTemplateColumns: "auto", height: "35px", width: "60px", color: "#fff", background: "#45c874", borderRadius: "10px", border: "1px solid #00000020",
                }}>
                  <p style={{ textAlign: "left", height: "min-content", margin: "auto" }}><span style={{ textAlign: "left", fontWeight: "bold", fontSize: "12px" }}>KYC</span></p>
                </button>}
              </div>
            </div>



          </div>


        </div>
        <div style={{
          height: "min-content",
          width: "90%",
          margin: "auto",
          display: "grid",
          gap: "5px",
          padding: "5px 0",
          textAlign: "justify",
          color: "#fff",
          fontSize: "14px"
        }}>
          {profile['Description']}
        </div>
        {getyoutubeVideo(object?.YoutubeVideo) != "" && (
          <div
            className="img-banner"
            style={{
              height: "min-content",
              borderRadius: "10px",
            }}
          >
            {getyoutubeVideo(object?.YoutubeVideo)
              .toString()
              .startsWith("img:") ? (
              <img
                style={{ borderRadius: "4px" }}
                src={
                  getyoutubeVideo(object?.YoutubeVideo)
                    .toString()
                    .split("img:")[1]
                }
                width="100%"
              />
            ) : (
              <iframe
                style={{ borderRadius: "4px" }}
                width="100%"
                height="315"
                src={
                  "https://www.youtube.com/embed/" +
                  getyoutubeVideo(object?.YoutubeVideo)
                }
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            )}
          </div>
        )}
        <div style={{
          height: "min-content",
          width: "95%",
          margin: "auto",
          display: "grid",
          gap: "20px",
          background: "#ffffff10",
          borderRadius: "10px",
          padding: "20px 0"
        }}>
          <SAFE what={"Presale Address"} result={object.cts} />
          <SAFE what={"Token name"} result={object.tokenname} />
          <SAFE what={"Token symbol"} result={object.tokesymbol} />
          <SAFE what={"Token decimals"} result={object.tokendec} />
          <SAFE what={"Token Total Supply"} result={nextblock(object.tokents, object.tokendec) + " " + object.tokesymbol} />
          <SAFE what={"Token Contract"} result={object.contract} />
          <SAFE what={"Presale rate"} result={(object.uToken == 0 ? "1 BNB =" : object.uToken == 1 ? "1 BUSD =" : object.uToken == 2 ? "1 USDT =" : "1 BNB =") + nextblock(object.presale_rate, object.tokendec) + " " + object.tokesymbol} />
          <SAFE what={"Listing rate"} result={(object.uToken == 0 ? "1 BNB =" : object.uToken == 1 ? "1 BUSD =" : object.uToken == 2 ? "1 USDT =" : "1 BNB =") + nextblock(object.listRate, object.tokendec) + " " + object.tokesymbol} />
          <SAFE what={"Sale method"} result={"Pre-Sale"} />
          <SAFE what={"Softcap"} result={nextblock(object.softcap, 18) + (object.uToken == 0 ? " BNB" : object.uToken == 1 ? " BUSD" : object.uToken == 2 ? " USDT" : " BNB")} />
          <SAFE what={"Hardcap"} result={nextblock(object.hardcap, 18) + (object.uToken == 0 ? " BNB" : object.uToken == 1 ? " BUSD" : object.uToken == 2 ? " USDT" : " BNB")} />
          <SAFE what={"Unsold tokens"} result={object.refundType == 0 ? "Burn" : 'Refund'} />
          <SAFE what={"Minimum buy"} result={nextblock(object.minbuy, 18) + (object.uToken == 0 ? " BNB" : object.uToken == 1 ? " BUSD" : object.uToken == 2 ? " USDT" : " BNB")} />
          <SAFE what={"Maximum buy"} result={nextblock(object.maxbuy, 18) + (object.uToken == 0 ? " BNB" : object.uToken == 1 ? " BUSD" : object.uToken == 2 ? " USDT" : " BNB")} />
          <SAFE what={"Liquidity Percent"} result={object.LiquidifyPercent + " %"} />
          <SAFE what={"Start time"} result={object.starttime != undefined || object.starttime != null ? (new Date(parseInt(object.starttime * 1000))).toLocaleString() : "..."} />
          <SAFE what={"End time"} result={object.endtime != undefined || object.endtime != null ? (new Date(parseInt(object.endtime * 1000))).toLocaleString() : "..."} />
          <SAFE what={"Liquidity lockup time"} result={parseInt(object.Locktime) > 59 ? (parseInt(object.Locktime) / 60) + " Hours" : object.Locktime + " minutes"} />
        </div>
      </div>
      <Modal obj={object} />


    </div>

    <p style={{ height: "10vh" }}></p>
  </div >

  return (
    <>

      {stakeColumns}

    </>


  );
}

export default Page;