import React, { useState, useEffect } from 'react';
import ProjectData from '../../config';
let projectName = "Martik"
const {
    Name,
    Logo,
    backgroundColor,
    buttonsColor,
    buttonsBorders,
    inputsColor,
    inputsBorders,
    outbuttonsColor,
    Borders,
    outFont,
    innerFont,
    Routers
} = ProjectData[window.location.host.split('.')[0] != undefined 
? window.location.host.split('.')[0] == "app" 
? window.location.host.split('.')[0] + "." + window.location.host.split('.')[1] 
: window.location.host.split('.')[0] : "Martik"]
const Timer = (props) => {
    const [seconds, setSeconds] = useState(0);
    const [time, setTime] = useState([0, 0, 0, 0]);
    useEffect(() => {
        let interval = null;
        interval = setInterval(() => {
            setSeconds(seconds => seconds + 1);
            getTimeRemaining(props.endtime)
        }, 1000);

        return () => clearInterval(interval);
    }, [seconds]);


    function getTimeRemaining(endtime) {

        const total = Date.parse(endtime) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
        const days = Math.floor(total / (1000 * 60 * 60 * 24));

        if (total < 0) {
            setTime([0, 0, 0, 0]);
        } else {
            setTime([days, hours, minutes, seconds])
        }

    }
    
    return (
        <div style={{display:"grid", gridTemplateColumns:"25% 25% 25% 25%"}}>
            <div>
                <div style={{ height: "90%", width: "90%", display: "grid", background: inputsColor, borderRadius: "10px", margin: "auto" }}>
                    <p style={{ color: "#fff", margin: "auto", fontWeight: "bold", fontSize: "16px" }}>{time[0]}</p>
                </div>
                <p style={{ color: innerFont, margin: "auto", fontSize: "10px" }}>{'Days'}</p>
            </div>
            <div>
                <div style={{ height: "90%", width: "90%", display: "grid", background: inputsColor, borderRadius: "10px", margin: "auto" }}>
                    <p style={{ color: "#fff", margin: "auto", fontWeight: "bold", fontSize: "16px" }}>{time[1]}</p>
                </div>
                <p style={{ color: innerFont, margin: "auto", fontSize: "10px" }}>{'Hours'}</p>
            </div>
            <div>
                <div style={{ height: "90%",width: "90%", display: "grid", background: inputsColor, borderRadius: "10px", margin: "auto" }}>
                    <p style={{ color: "#fff", margin: "auto", fontWeight: "bold", fontSize: "16px" }}>{time[2]}</p>
                </div>
                <p style={{ color: innerFont, margin: "auto", fontSize: "10px" }} className=''>{'Min'}</p>
            </div>
            <div>
                <div style={{ height: "90%",width: "90%",display: "grid", background: inputsColor, borderRadius: "10px", margin: "auto" }}>
                    <p style={{ color: "#fff", margin: "auto", fontWeight: "bold", fontSize: "16px" }}>{time[3]}</p>
                </div>
                <p style={{ color: innerFont, margin: "auto", fontSize: "10px" }}>{'Secs'}</p>
            </div>

        </div>
    );
}

export default Timer;