import React, { useState, useEffect } from 'react';
import SAFE from './textpar'
import { ethers } from 'ethers';
import ProjectData from '../../config';
import Button from './Button';
let projectName = "Martik"
const {
    Name,
    Logo,
    backgroundColor,
    buttonsColor,
    buttonsBorders,
    inputsColor,
    inputsBorders,
    outbuttonsColor,
    Borders,
    outFont,
    innerFont,
    Routers
} = ProjectData[window.location.host.split('.')[0] != undefined 
? window.location.host.split('.')[0] == "app" 
? window.location.host.split('.')[0] + "." + window.location.host.split('.')[1] 
: window.location.host.split('.')[0] : "Martik"]
const AffiliateHUB = (props) => {
    const [_ct, setCt] = useState("");
    const [refs, setrefs] = useState([]);
    const [data, setdata] = useState({
        uToken: 0,
        Affiliated: 0,
        AffiliatedList: [],
    });

    useEffect(() => {
        getdata()
    }, []);

    async function getdata() {
        const sale = await props.Tokencontract(props.ctAbi, props.ct);
        let _tlist = []
        const obj = {
            uToken: await sale.methods.uToken().call(),
            Affiliated: await sale.methods.Affiliated().call(),
            AffiliatedList: await sale.methods.Refs().call(),
        }

        for (let index = 0; index < obj.AffiliatedList.length; index++) {

            if (obj.AffiliatedList[index] != '0x0000000000000000000000000000000000000000') {
                let balance = await sale.methods.Affiliated_balance(obj.AffiliatedList[index]).call()
                let t = {
                    who: obj.AffiliatedList[index],
                    amount: parseFloat(nextblock(balance, 18))
                }
                _tlist.push(t)
            }

        }
        setrefs(_tlist)
        setdata(obj)
    }

    function nextblock(accountBalancemctTB, d) {
        return accountBalancemctTB == undefined ? 0 : parseFloat(ethers.utils.formatUnits(accountBalancemctTB, d)).toFixed(2)
    }
    return (
        <>
            {data.Affiliated > 0
                ? <div className="dark:shadow-md dark:shadow-slate-500 rounded-xl dark:bg-[#212b36] bg-white shadow-xl w-full mt-4" >
                    <div className="w-full  flex-cols items-center justify-center pt-4">
                        <div className="dark:text-slate-200 text-xs laptop:text-sm font-semibold px-2 text-center">
                            Affiliate program
                        </div>
                    </div>
                    <div className="w-full px-2 pb-10">
                        <div className="w-full rounded-xl px-2 mt-4 space-y-3 " style={{ display: "grid", gap: "10px" }}>
                            <div className={'w-full flex space-y-1 text-[' + outFont + ']'}>
                                <input value={window.location.href + "?refFrom=" + props.account} className='inpult-primary' disabled></input>
                                <svg onClick={() => { navigator.clipboard.writeText(window.location.href + "?refFrom=" + props.account) }} viewBox="0 0 24 24" fill={outFont} style={{ height: "16px", width: "16px", margin: "auto", cursor: "pointer" }} xmlns="http://www.w3.org/2000/svg" className="sc-231a1e38-0 gUXTeE"><path d="M15 1H4C2.9 1 2 1.9 2 3V16C2 16.55 2.45 17 3 17C3.55 17 4 16.55 4 16V4C4 3.45 4.45 3 5 3H15C15.55 3 16 2.55 16 2C16 1.45 15.55 1 15 1ZM19 5H8C6.9 5 6 5.9 6 7V21C6 22.1 6.9 23 8 23H19C20.1 23 21 22.1 21 21V7C21 5.9 20.1 5 19 5ZM18 21H9C8.45 21 8 20.55 8 20V8C8 7.45 8.45 7 9 7H18C18.55 7 19 7.45 19 8V20C19 20.55 18.55 21 18 21Z" /></svg>
                            </div>

                          {props.claimable &&  <div style={{ width: "100%", display: "grid", gridTemplateColumns: "auto auto" }}>
                                <p className='border-none' > {props.claimable ? "Claimable" : "Received"}</p>

                                <p className='border-none font-semibold' >{parseFloat(nextblock(data.isOwnerAffiliate, 18)) + (data.uToken == 0 ? " BNB " : data.uToken == 1 ? " BUSD" : data.uToken == 2 ? " USDT " : " BNB")}</p>
                            </div>}

                            {(data.isOwnerAffiliate > 0 && props.status() == 'FINALIZED' && props.claimable) && <Button func={() => {
                                props.nSendTX(
                                    props.ct,
                                    props.ctAbi,
                                    props.netId,
                                    "afiliadeClaim",
                                    '0',
                                ).then(a => {

                                }).catch(e => {

                                })
                            }} text={'CLAIM'} next={props.status() == 'FINALIZED'} />}
                            {refs.length > 0 && <div className="w-full  flex-cols items-center justify-center pt-4">
                                <div className="dark:text-slate-200 text-xs laptop:text-sm font-semibold px-2 text-center">
                                    Affiliate Ranking
                                </div>
                            </div>}
                            {refs.length > 0 && refs.sort((a, b) => a.amount - b.amount).map((e, i) => <div style={{ width: "100%", display: "grid", gap: "5px", gridTemplateColumns: "auto auto auto" }}>
                                <p >{i}</p>
                                <p >{e.who.slice(0, 4) + '...' + e.who.slice(-4)}</p>
                                <p >{e.amount + (data.uToken == 0 ? " BNB " : data.uToken == 1 ? " BUSD" : data.uToken == 2 ? " USDT " : " BNB")}</p>
                            </div>)}

                        </div>
                    </div>
                </div >
                : <></>
            }
        </>

    )
}
export default AffiliateHUB;