import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';

const Rewards = (props) => {
  const {
    setAccountNRSA,
    stakect,
    nSendTX,
    STAKECTS,
    stakesabi,
    getID,
    poolfee,
    accounts,
    update,
    Tokencontract,
    nextblock,
    getmkerror
  } = props.obj
  const [accountNRSCI, setAccountNRSCI] = useState(0);
  const [seconds, setSeconds] = useState(0);
  useEffect(() => {
    hasStak()
  }, []);
 useEffect(() => {
    let interval = null;
    interval = setInterval(() => {
      setSeconds(seconds => seconds + 1);
      hasStak()
    }, 30000);

    return () => clearInterval(interval);
  }, [seconds]);
  function toBig(value) {
    let a = ['']
    let e = value + ""
    if (e.toString().includes(",")) {
      a = e.toString().split(",")
      e = ethers.utils.parseUnits(a[0] + "." + a[1], 18).toString()
    } else {
      e = ethers.utils.parseUnits(e, 18).toString()
    }
    return e;
  }
  async function hasStak() {
    if (accounts[0] != undefined && stakect != undefined) {
      try {
        const ress = await stakect.methods.hasStake(accounts[0], props.pos).call()
        setAccountNRSA(ress.total_amount)
        setAccountNRSCI(ress.total_toclaim)
      } catch (error) {
        
      }
      //const ress = await stakect.methods._Rewards(accounts[0], props.pos).call()
      //const price = props.ct != '0x0000000000000000000000000000000000000000' ?  await stakect.methods.maintokenprice(ress, props.pos).call() : 0


      /*
      const rpr = await stakect.methods._rewardperhour().call()
      const since = await stakect.methods.sinces(accounts[0], props.ct).call()
      const ttimes = await stakect.methods.ttimes(props.ct).call()
      const ress = await stakect.methods.hasStake(accounts[0], props.pos).call()
      let res = 0;
      let resc = 0;
      for (var i = 0; i < ress.stakes.length; i++) {
        res += parseFloat(nextblock(ress.stakes[i].amountbnb, 18))
        resc += parseFloat(nextblock(ress.stakes[i].amount, 18))
      }
      setAccountNRSA(toBig(resc))
      const aux = await Tokencontract([
        {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "since",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "ttimes",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "amountbnb",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "rpr",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "p",
              "type": "uint256"
            }
          ],
          "name": "calculateStakeReward",
          "outputs": [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        }
      ], '0x23300Bd24266BB370ddce307c2D4AC94f5b8Ae9f');
      const claimable = await aux.methods.calculateStakeReward(since, ttimes, toBig(res), rpr, props.pos).call()
      if (claimable == "0") {
        setAccountNRSCI(0)
      } else {
        const price = await stakect.methods.maintokenprice(claimable, props.pos).call()
        setAccountNRSCI(price)
      }*/
    }
  }


  function openpoocoin() {
    window.open("https://poocoin.app/tokens/" + props.ct)
  }
  function nwRstakem(e) {
    if (accounts != null) {
      if (accounts[0] != undefined) {
        nSendTX(STAKECTS, stakesabi, getID(), '_withdrawStakeR', poolfee, 0,
          e
        )
          .then(gg => {
            update()
            alert("Success")
          })
          .catch(error => {
            alert(getmkerror(error.message))
          });


      }
    }

  }

  return (
    <button style={{
      display: "grid", gridTemplateColumns: "auto", backgroundColor: "#ffffff20",
      borderRadius: "10px", height: "min-content", width: "90%", margin: "auto", border: "1px solid #ffffff00", marginBottom: "1vh", marginTop: "1vh", color: "#fff", fontSize: "13px", padding: "1vh",
    }}>
      <div id={props.pos + "logo"} src={props.logo} onClick={() => { openpoocoin() }} style={{
        height: "70px",
        width: "70px",
        borderRadius: "360px",

        margin: "auto",
        cursor: "pointer",
        background: 'url(' + props.logo + ')  center center / 75% no-repeat',
      }}>
      </div>
      {false && <p style={{ fontSize: "12px", textAlign: "center", height: "min-content", color: "#fff", margin: "auto", padding: "1vh" }}>{props.pos}</p>}
      <p style={{ fontSize: "12px", textAlign: "center", height: "min-content", color: "#fff", margin: "auto", padding: "1vh" }}>{nextblock(accountNRSCI, props.dec)}<br /> {props.symbol}</p>
      <button onClick={() => { nwRstakem(props.pos) }} style={{ display: "grid", gridTemplateColumns: "auto", backgroundColor: "#ffffff20", border: "none", height: "min-content", width: "90%", borderRadius: "90px", margin: "auto", color: "#fff", fontSize: "13px", boxShadow: "1px 1px 1px 1px #33415540" }}>
        <div style={{
          height: "100%",
          width: "100%",
          margin: "auto",
          display: "grid",
          gridTemplateColumns: " auto",
          cursor: "pointer",
          borderRadius: "10px",

        }}>
          <p style={{ fontSize: "12px", textAlign: "center", height: "min-content", color: "#fff", padding: "10px" }}>CLAIM</p>
        </div>

      </button>


    </button>
  );
}
export default Rewards;