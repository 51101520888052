import React, { useState, useEffect } from 'react';
import '../../pages/trade.css';
import '../../App.css';
import tokenslist from "../../tokens.json";
const Web3 = require('web3-eth');
let tokenB = undefined;
const Modal = (props) => {

  let provider = props.array[0]
  let contract = props.array[1]
  let stakesabi = props.array[2]
  let tokenabi = props.array[4]


  const [STAKECTS, setSTAKECTS] = useState(contract);
  const [AllowA, setAllowA] = useState(0);
  const [accountNRSA, setAccountNRSA] = useState(0);
  const [accountNRSC, setAccountNRSC] = useState(0);
  const [totalstaked, settotalstaked] = useState(0);
  const [accountBalancemTB, setAccountBalanceTB] = useState(0);
  const [hubinteract, setHint] = useState(false);
  const [isa, setIsA] = useState(false);
  const [tokenAname, setTokenAname] = useState("MTK");
  const [tokenACT, setTokenACT] = useState("0x116526135380E28836C6080f1997645d5A807FAE");
  const [BalancemTA, setBalanceTA] = useState(0);
  const [BalancemTB, setBalanceTB] = useState(0);
  const [listsearch, setlistsearch] = useState(null);
  const [APR, setAPR] = useState(0);
  const [APRB, setAPRB] = useState(0);
  const [ctfind, setctcatch] = useState(null);
  const [team, setteam] = useState(0);
  const [time, settime] = useState(0);
  const [winner, setwinner] = useState(0);
  const [poofee, setpoolfee] = useState(0);
  const [userlist, setul] = useState([]);
  const [gettime, settimea] = useState(0);

  const [symbola, setsymbolA] = useState("");
  const [symbolb, setsymbolB] = useState("");
  const [onbet, setonbet] = useState(false);
  const [deca, setdecA] = useState("");
  const [decb, setdecB] = useState("");
  const [ia, setiA] = useState(0);
  const [ib, setiB] = useState(0);
  const [catchh, setcatchh] = useState(false);
  const [tlock, setTlock] = useState(1);
  const [STAKE, setSTAKE] = useState(0);

  const [totalSupplyB, setTotalSupplyB] = useState(0);
  let web3 = null
  if (provider == "https://bsc-dataseed.binance.org") {
    web3 = new Web3(new Web3.providers.HttpProvider(provider));

  } else {
    web3 = new Web3(provider);

  }

  let stakect = 0;
  let accounts = null
  if (provider != null) {
    accounts = [props.array[5]]
    stakect = new web3.Contract(stakesabi, STAKECTS);
    //call(stakect.methods.BEP20Mtoken, gettokendataA);
    // call(stakect.methods.BEP20Rtoken, gettokendataB);
    gettokendataA(tokenACT)
    getapr()


  }

  async function gettokendataA(e) {
    let token = new web3.Contract(tokenabi, e);
    tokenB = token
    //settokenB(token)
    call(token.methods.decimals, setdecA);
    call(token.methods.totalSupply, setTotalSupplyB);
    if (accounts[0] != undefined) {
      if (e == "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c") {
        if (AllowA == 0) {
          setAllowA(1)
          web3.getBalance(accounts[0])
          .then(valor => {
            setAccountBalanceTB(valor)
          })
          .catch(error => { })
  
        }
      } else {
        call(token.methods.allowance, setAllowA, accounts[0], STAKECTS);
        call(token.methods.balanceOf, setAccountBalanceTB, accounts[0]);
      }
    }


  }

  function nextpercent(accountBalancemctTB, d) {
    if ((accountBalancemctTB / 10).toString().includes('.')) {
      if (accountBalancemctTB.toString().length >= d) {
        return (
          (accountBalancemctTB / 10).toString().split('.')[0] +
          '.' +
          (accountBalancemctTB / 10).toString().split('.')[1].slice(0, 2)
        );
      } else {
        return (
          '0.' +
          '0'.repeat(d - accountBalancemctTB.toString().length) +
          accountBalancemctTB.toString().slice(0, 2)
        );
      }
    } else {
      return accountBalancemctTB / 10;
    }
  }
  function getmkerror(e) {
    let a = e



    return a;
  }
  function nextblock(accountBalancemctTB, d) {
    if ((accountBalancemctTB / (10 ** d)).toString().includes('.')) {
      if (accountBalancemctTB.toString().length >= d) {
        return (
          (accountBalancemctTB / (10 ** d)).toString().split('.')[0] +
          '.' +
          (accountBalancemctTB / (10 ** d)).toString().split('.')[1].slice(0, 2)
        );
      } else {
        return (
          '0.' +
          '0'.repeat(d - accountBalancemctTB.toString().length) +
          accountBalancemctTB.toString().slice(0, 2)
        );
      }
    } else {
      return accountBalancemctTB / (10 ** d);
    }
  }


  function call(func, callback, ...args) {
    // Trigger the function with the arguments
    func(...args).call()
      .then((result) => {
        // Apply given callback, this is our stateSetters
        callback(result);
      })
      .catch((error) => {
        //throw new Error(error);
      })
  }
  //new stake
  function nstake(value, team) {
    let a = []
    let e = value
    let b = false
    if (e.toString().includes(".")) {
      a = e.toString().split(".")
      e = a[0] + a[1] + "0".repeat((deca - a[1].toString().length))
    } else {
      if (e.toString().includes(",")) {
        a = e.toString().split(",")
        e = a[0] + a[1] + "0".repeat((deca - a[1].toString().length))
      } else {
        if (e <= 0) {
          e = e.toString() + "0".repeat(deca)
          b = true
        } else {
          e = e.toString() + "0".repeat(deca)
        }
      }
    }
  
    if (b) {
      alert("Input invalid")
    } else {
      if (tokenACT == "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c") {
        stakect.methods._betting(props.pos, e, team, tokenACT).estimateGas({ from: accounts[0], value: e })
          .then((gas) => {
            stakect.methods._betting(props.pos, e, team, tokenACT).send({
              from: accounts[0],
              value: e,
              gas: gas
            })
              .then(gg => {
                alert("Success")
              })
              .catch(error => {
                alert(getmkerror(error))
              });
          }).catch((error) => {
            alert(getmkerror(error))
          });
      } else {
        stakect.methods._betting(props.pos, e, team, tokenACT).estimateGas({ from: accounts[0], value: poofee })
          .then((gas) => {
            stakect.methods._betting(props.pos, e, team, tokenACT).send({
              from: accounts[0],
              value: poofee,
              gas: gas
            })
              .then(gg => {
                alert("Success")
              })
              .catch(error => {
                alert(getmkerror(error))
              });
          }).catch((error) => {
            alert(getmkerror(error))
          });
      }

    }
  }

  function nwRstake() {
    if (accounts != null) {
      if (accounts[0] != undefined) {
        if (team == 1) {
          stakect.methods._withdrawA(props.pos).estimateGas({ from: accounts[0], value: poofee })
            .then((gas) => {
              stakect.methods._withdrawA(props.pos).send({
                from: accounts[0],
                value: poofee,
                gas: gas
              })
                .then(gg => {
                  alert("Success")
                })
                .catch(error => {
                  alert(getmkerror(error))
                });
            }).catch((error) => {
              alert(getmkerror(error))
            });
        } else {
          if (team == 2) {
            stakect.methods._withdrawB(props.pos).estimateGas({ from: accounts[0], value: poofee })
              .then((gas) => {
                stakect.methods._withdrawB(props.pos).send({
                  from: accounts[0],
                  value: poofee,
                  gas: gas
                })
                  .then(gg => {
                    alert("Success")
                  })
                  .catch(error => {
                    alert(getmkerror(error))
                  });
              }).catch((error) => {
                alert(getmkerror(error))
              });
          }

        }

      }
    }

  }
  function getapr() {
    //call(stakect.methods._teamA, setsymbolA, props.pos);
    //call(stakect.methods._teamB, setsymbolB, props.pos);

    call(stakect.methods._FLEXAPRA, setAPR, props.pos);
    call(stakect.methods._FLEXAPRB, setAPRB, props.pos);

    call(stakect.methods.poolfee, setpoolfee);
    //call(stakect.methods.finishin, settime, props.pos);
    call(stakect.methods.winner, setwinner, props.pos);

    call(stakect.methods.time, settimea);

    stakect.methods._totalstaked(props.pos, 1).call().then(string => {
      setBalanceTA(string)
    }).catch(error => {

    })
    stakect.methods._totalstaked(props.pos, 2).call().then(string => {
      setBalanceTB(string)
    }).catch(error => {

    })

    if (ia == 0) {
      stakect.methods.poolsview().call().then(string => {
        setsymbolA(string[props.pos][0])
        setsymbolB(string[props.pos][1])
        setiA(string[props.pos][2])
        setiB(string[props.pos][3])
      }).catch(error => {

      })
    }
    if (time == 0) {
      stakect.methods.poolstimes(props.pos).call().then(string => {
        settime(string)
      }).catch(error => {

      })
    }




  }

  function hasStak() {
    if (accounts[0] != undefined) {
      stakect.methods.amount(props.pos, accounts[0]).call().then(string => {
        stakect.methods.offbet(props.pos, accounts[0]).call().then(str => {
          stakect.methods.team(props.pos, accounts[0]).call().then(strin => {
            if (str) {
              setAccountNRSC(0)
            }else {
              if (strin == 1) {
                stakect.methods._rewardperhour(APR).call().then(stri => {
                  setAccountNRSC(((8640 * string) / stri))
                  //console.log(((8640 * string) / stri))
                  //return ((8640 * string) / stri);
                }).catch(error => {
      
                })
              } else {
                if (strin == 2) {
                  stakect.methods._rewardperhour(APRB).call().then(stri => {
                    setAccountNRSC(((8640 * string) / stri))
                    //console.log(((8640 * string) / stri))
                    //return ((8640 * string) / stri);
                  }).catch(error => {
        
                  })
                } else {
                  setAccountNRSC(0)
                }
              }
            }
           
           
          }).catch(error => {

          })
        }).catch(error => {

        })
       
        setAccountNRSA(string)
      }).catch(error => {

      })
      /*stakect.methods.calculateReward(props.pos, accounts[0]).call().then(strin => {
        setAccountNRSC(strin)
        //console.log(strin)
      }).catch(error => {

      })*/
      stakect.methods.team(props.pos, accounts[0]).call().then(strin => {
        setteam(strin)
      }).catch(error => {

      })
      stakect.methods.onbet(props.pos, accounts[0]).call().then(strin => {
        setonbet(strin)
      }).catch(error => {

      })
    }
  }
  hasStak()

  function approveta() {
    tokenB.methods.approve(STAKECTS, totalSupplyB).send({
      from: accounts[0],
      gas: 50000
    })
      .then(gg => {
        alert("Success")
      })
      .catch(error => {
        alert(getmkerror(error))

      });
  }
  let Stakebutton = null
  let claimbutton = null

  claimbutton = <div style={{ display: "grid", gridTemplateColumns: "auto 5% auto", backgroundColor: "#090e1600 ", border: "1px solid #090e1650", height: "70px", width: "100%", margin: "auto" }}>
    <div style={{
      height: props.heighti,
      width: "100%",
      margin: "auto",
      display: "grid", gridTemplateColumns: "70px auto",

      borderRadius: "10px",

    }}>
      <i style={{
        height: "100%",
        width: "100%",
        margin: "auto",
        background: "url('/icons/nlogo.png ')  center center / 50% no-repeat",
      }}>
      </i>
      <p style={{ fontWeight: "bold", textAlign: "right", height: "min-content", marginTop: "auto", marginBottom: "auto", color: "#fff" }}>{accountNRSC > 0 && parseFloat( nextblock(accountNRSC, 18)) + parseFloat( nextblock(accountNRSA, 18))} {accountNRSC <= 0 && 0}</p>
    </div>
    <p></p>
    <p onClick={() => {
      nwRstake()
    }
    } style={{
      margin: "auto",
      backgroundColor: props.buttoncolor,
      borderRadius: "2px",
      border: "1px solid #334155",
      height: '65px',
      fontSize: "12px",
      width: "100%",
      display: "grid",
      margin: "auto",
      cursor: "pointer"
    }}><p style={{ margin: "auto", fontWeight: "bold" }}>CLAIM</p></p>

  </div>

  if (AllowA > 0) {
    Stakebutton = <div style={{ display: "flex", gap: "5px", height: "65px", width: "100%", margin: "auto" }}>
      {getteambutton(1) && <div style={{ display: "grid", gridTemplateColumns: "auto", height: "'65px'", width: "98%", margin: "auto" }}>
        <p onClick={() => {
          if (document.getElementById(symbola + symbolb) != null) {
            nstake(document.getElementById(symbola + symbolb).value, 1)
          } else {
            alert("Erro, Please report")
          }
        }
        } style={{
          margin: "auto",
          border: "1px solid #334155",
          backgroundColor: props.buttoncolor,
          borderRadius: "2px",
          height: '65px',
          width: "100%",
          display: "grid",
          margin: "auto",
          cursor: "pointer"
        }}><p style={{ margin: "auto", fontWeight: "bold" }}>{symbola}</p></p>
      </div>}
      {getteambutton(2) && <div style={{ display: "grid", gridTemplateColumns: "auto", height: '65px', width: "98%", margin: "auto", }}>
        <p onClick={() => {
          if (document.getElementById(symbola + symbolb) != null) {
            nstake(document.getElementById(symbola + symbolb).value, 2)
          } else {
            alert("Erro, Please report")
          }
        }
        } style={{
          margin: "auto",
          backgroundColor: props.buttoncolor,
          border: "1px solid #334155",
          borderRadius: "2px",
          height: '65px',
          width: "100%",
          display: "grid",
          margin: "auto",
          cursor: "pointer"
        }}><p style={{ margin: "auto", fontWeight: "bold" }}>{symbolb}</p></p>
      </div>}
    </div>
  } else {
    Stakebutton = <div style={{ display: "grid", gridTemplateColumns: "auto", height: '65px', width: "99%", margin: "auto" }}>
      <p onClick={() => {
        approveta()
      }
      } style={{
        margin: "auto",
        backgroundColor: props.buttoncolor,
        borderRadius: "2px",
        border: "1px solid #334155",
        height: '65px',
        width: "100%",
        display: "grid",
        margin: "auto",
        cursor: "pointer"
      }}><p style={{ margin: "auto", fontWeight: "bold" }}>APPROVE</p></p>
    </div>
  }



  function getresult() {
    if (onbet) {
      if (team == winner) {
        if (accountNRSC > 0) {
          return nextblock(accountNRSC, 18);
        } else {
          return "Your reward has already been redeemed";
        }
      } else {
        return "-" + nextblock(accountNRSA, 18);
      }
    } else {
      return "You didn't bet!";
    }

  }

  //new Date(time * 1000).toLocaleString()
  function getteambutton(e) {
    if (accountNRSA > 0) {
      if (team == e) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }

  }


  function getclaim() {
    if (onbet) {
      if (team == winner) {
        if (accountNRSC > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  function getfisish() {
    if (onbet) {
      if (winner != 0) {
        return false;
      } else {
        if (gettime > time) {
          return false;
        } else {
          return true;
        }
      }
    } else {
      if (gettime > time) {
        return false;
      } else {
        return true;
      }
    }
  }
  function getteam() {
    if (team == 1) {
      return symbola
    } else {
      if (team == 2) {
        return symbolb
      }
    }
  }
  function getwinner() {
    if (winner == 1) {
      return symbola
    } else {
      if (winner == 2) {
        return symbolb
      } else {

      }
    }
  }
  //{winner == 2 && "Winner"}{winner == 1 && "Loser"}
  /*<br />
              IN A DRAW EVERYONE LOSES<br />
              BET FEE {nextblock(poofee, 18)} BNB <br />
              <br /> BET WITH RESPONSIBILITY!!
                          {winner == 0 && "$DC " + nextblock(BalancemTB, 18)} */
  function getTimeRemaining(endtime) {
    const total = Date.parse(endtime) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    const days = Math.floor(total / (1000 * 60 * 60 * 24));
    if (total < 0) {

      return "EXPIRED";
    } else {
      return days + "D " + hours + "H "
        + minutes + "M " + seconds + "S"
    }

  }
  function hv() {
    let a = tokenslist

    return a;
  }
  if (catchh) {
  } else {
    setul(hv())
    setcatchh(true)
  }
  function choicecoin(e, c) {
    setTokenAname(e)

    gettokendataA(c)
    setTokenACT(c)
  }
  const search = async (e) => {
    if (e.length <= 0) {
      setlistsearch(null)
    } else {
      if (e.length == 42) {
        if (e.includes("0x")) {
          const bota = await new web3.Contract(tokenabi, e);

          bota.methods.symbol().call()
            .then(ress => {
              bota.methods.name().call()
                .then(res => {
                  setlistsearch({
                    name: res,
                    symbol: ress
                  })
                  setctcatch(e)
                })
                .catch(error => {
                  //console.log(error)
                });
            })
            .catch(error => {
              //console.log(error)
            });

        } else {

        }

      } else {
        setlistsearch(null)
      }
    }

  }
  const cars = userlist;

  function Car(props) {
    return <li onClick={(e) => {
      choicecoin(props.brand, props.ct)
      setHint(!hubinteract)

    }}>
      <img src={props.icon}></img>
      <h1>{props.brand}</h1>
      <span className="dropdown-content">
        <a href="#">Buy</a>
        <a href="#">Sell</a>
      </span>
    </li>;
  }

  function Cares(props) {
    return <li onClick={(e) => {
      choicecoin(props.brands, props.ct)
      // tokenname(props.brand)
      setHint(!hubinteract)
    }}>
      <img src={props.icon}></img>
      <h1>{props.brand}</h1>
    </li>;
  }
  let si = null
  let ulconfig = null

  if (listsearch != null) {
    ulconfig = <ul id='list'>
      {<Cares brand={listsearch.name} brands={listsearch.symbol} icon={"https://bscscan.com/images/main/empty-token.png"} ct={ctfind} />}
      {cars.map((car) => <Car brand={car.nome} icon={car.icon} ct={car.contract} />)}
    </ul>

  } else {
    ulconfig = <ul id='list'>
      {cars.map((car) => <Car brand={car.nome} icon={car.icon} ct={car.contract} />)}
    </ul>
  }

  let modaltokensls = null
  modaltokensls = <div className='Trademainboletamodalls' style={{position:"absolute", left:"40%", top:"25%"}} >
    <div>
      <h1>
        Select token to bet
      </h1>
      <span className="fas fa-times" onClick={() => setHint(!hubinteract)}></span>
    </div>
    <input placeholder="0x..." onChange={(e) => { search(e.target.value) }} />
    {ulconfig}

  </div>
  return (
    <div title={"BET " + props.pos} style={{
      height: "min-content",
      width: "100%",

      display: "grid",
      padding: "1vh",
      margin: "auto",
      border: "1px solid " + props.bgcolor,
      borderRadius: "10px",
      backgroundColor: props.bgcolor,
      boxShadow: "1px 5px 5px 5px #16203120",
      color: "#fff",
      fontSize: "1.8vh",
    }}>
      <div style={{
        height: "min-content",
        width: "100%",
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(25vh, 1fr)",
        gap: "10px",
      }}>

        <div style={{ display: "grid", gridTemplateColumns: "auto min-content auto", width: "100%", padding: "auto", height: "10vh" }}>
          <div style={{
            height: "8vh",
            width: "16vh",
            margin: "auto",
          }}>
            <p style={{ margin: "auto", fontWeight: "bold", textAlign: "center", fontSize: "1.5vh" }}>{symbola}</p>
            <div style={{
              borderRadius: "360px",
              height: "5vh",
              width: "5vh",
              margin: "auto",
              background: "url('" + ia + " ')  center center / 80% no-repeat",
            }}>

            </div>

            <button onClick={() => { }} style={{
              display: "grid", gridTemplateColumns: "auto", height: "2vh", width: "8vh", border: "1px solid #7CFC00", margin: "auto ",
              background: "#7CFC0010", borderRadius: "2px", color: "#7CFC00"
            }}>
              <p style={{ fontSize: "1.5vh", height: "min-content", margin: "auto" }}>{parseFloat((nextpercent(APR, 2) / 100)).toFixed(4)}x</p>
            </button>
          </div>
          <p onClick={() => { }} style={{

            height: "min-content",
            width: "4vh",
            display: "grid",
            margin: "auto",
          }}><p style={{ margin: "auto", fontWeight: "bold", fontSize: "1.8vh" }}>VS</p></p>
          <div style={{


            height: "8vh",
            width: "16vh",

            margin: "auto",

          }}>
            <p style={{ margin: "auto", fontWeight: "bold", textAlign: "center", fontSize: "1.5vh" }}>{symbolb}</p>
            <div style={{

              borderRadius: "360px",
              height: "5vh",
              width: "5vh",

              margin: "auto",
              background: "url('" + ib + " ')  center center / 80% no-repeat",
            }}>
            </div>

            <button onClick={() => { }} style={{
              display: "grid", gridTemplateColumns: "auto", height: "2vh", width: "8vh", border: "1px solid #7CFC00", margin: "auto ",
              background: "#7CFC0010", borderRadius: "2px", color: "#7CFC00"
            }}>
              <p style={{ fontSize: "1.5vh", height: "min-content", margin: "auto" }}>{(parseFloat(nextpercent(APRB, 2) / 100)).toFixed(4)}x</p>
            </button>

          </div>

        </div>
        <div style={{ display: "grid", gap: "5px", gridTemplateColumns: "auto", height: "10vh", width: "100%", margin: "auto", borderRadius: "10px" }}>
          <div style={{
            height: "min-content",
            width: "100%",
            margin: "auto",
            display: "grid", gridTemplateColumns: "auto",

            borderRadius: "10px",
          }}>
            <div onClick={() => { }} style={{
              display: "grid", gridTemplateColumns: "5vh auto", height: "5vh", width: "100%", border: "1px solid #33415550", margin: "auto",
              background: "#33415520", borderRadius: "2px", color: "#fff"
            }}>
              <div style={{
                borderRadius: "360px",
                height: "5vh",
                width: "5vh",
                margin: "auto",

                background: "url('/icons/nlogo.png')  center center / 50% no-repeat",
              }}>

              </div>
              <p style={{ fontSize: "1.5vh", textAlign: "left", height: "min-content", margin: "auto 0" }}>{"You place "}<p />{nextblock(accountNRSA, 18)}</p>
            </div>


          </div>
          <div style={{
            height: "min-content",
            width: "100%",
            margin: "auto",
            display: "grid", gridTemplateColumns: "auto",
            borderRadius: "10px",
          }}>
            <div onClick={() => { }} style={{
              display: "grid", gridTemplateColumns: "5vh auto", height: "5vh", width: "100%", border: "1px solid #33415550", margin: "auto",
              background: "#33415520", borderRadius: "2px", color: "#fff"
            }}>
              <svg style={{
                height: "2.5vh",
                width: "2.5vh",
                margin: "auto",
              }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clock" viewBox="0 0 16 16">
                <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
              </svg>
              <p style={{ fontSize: "1.5vh", textAlign: "left", height: "min-content", margin: "auto 0" }}>{"Ends in "}<p /> {getTimeRemaining(new Date(time * 1000))}</p>
            </div>


          </div>

        </div>


        <div style={{ display: "grid", gap: "5px", gridTemplateColumns: "auto", height: "10vh", width: "100%", margin: "auto", borderRadius: "10px" }}>
          <div style={{
            height: "min-content",
            width: "100%",
            margin: "auto",
            display: "grid", gridTemplateColumns: "auto",

            borderRadius: "10px",
          }}>
            <div onClick={() => { }} style={{
              display: "grid", gridTemplateColumns: "5vh auto", height: "5vh", width: "100%", border: "1px solid #33415550", margin: "auto",
              background: "#33415520", borderRadius: "2px", color: "#fff"
            }}>
              <div style={{

                borderRadius: "360px",
                height: "5vh",
                width: "5vh",

                margin: "auto",
                background: "url('" + ia + " ')  center center / 80% no-repeat",
              }}>
              </div>
              <p style={{ fontSize: "1.5vh", textAlign: "left", height: "min-content", margin: "auto 0" }}>{"$MTK in " + symbola}<p />{nextblock(BalancemTA, 18)}</p>
            </div>


          </div>
          <div style={{
            height: "min-content",
            width: "100%",
            margin: "auto",
            display: "grid", gridTemplateColumns: "auto",
            borderRadius: "10px",
          }}>
            <div onClick={() => { }} style={{
              display: "grid", gridTemplateColumns: "5vh auto", height: "5vh", width: "100%", border: "1px solid #33415550", margin: "auto",
              background: "#33415520", borderRadius: "2px", color: "#fff"
            }}>
              <div style={{

                borderRadius: "360px",
                height: "5vh",
                width: "5vh",

                margin: "auto",
                background: "url('" + ib + " ')  center center / 80% no-repeat",
              }}>
              </div>
              <p style={{ fontSize: "1.5vh", textAlign: "left", height: "min-content", margin: "auto 0" }}>{"$MTK in " + symbolb}<p />{nextblock(BalancemTB, 18)}</p>
            </div>


          </div>

        </div>
        <div style={{ display: "grid", gridTemplateColumns: "auto", gap: "5px", height: "10vh", width: "98%", margin: "auto", borderRadius: "2px" }}>
          {getfisish() && <div style={{
            height: "5vh",
            width: "100%",
            margin: "auto",
            display: "grid", gridTemplateColumns: "10vh auto 10vh",
            backgroundColor: "#1e293b50",
            border: "1px solid #090e1650",
          }}>
            <button style={{
              cursor: "pointer", display: "grid", gridTemplateColumns: "100%", height: "5vh", width: "100%", border: "1px solid #33415550", margin: "auto",
              background: "#33415520", borderRadius: "2px", color: "#fff", overflow: "hidden"
            }} onClick={() => {
              setHint(!hubinteract)
              setIsA(true)
            }}><p style={{ margin: "auto", fontSize: "1.5vh", fontWeight: "bold" }}>{tokenAname}</p> </button>
            <div style={{ height: props.heighti, width: "100%", display: "grid", gridTemplateColumns: "100%" }}>
              <input type="number" id={symbola + symbolb} placeholder='Amount to BET' style={{ backgroundColor: "#1e293b00", outline: "none", textAlign: "right", border: "none", height: props.heighti, width: "100%", margin: "auto", borderRadius: "10px 0px 0px 10px", color: "#fff", fontSize: "1.5vh" }} />
            </div>
            <p onClick={() => {
              if (document.getElementById(symbola + symbolb) != null) {
                document.getElementById(symbola + symbolb).value = nextblock(accountBalancemTB, deca)
              } else {
                alert("Erro, Please report")
              }
            }} style={{
              margin: "auto",

              height: "min-content",

              width: "100%",
              display: "grid",
              margin: "auto",
              cursor: "pointer"
            }}><p style={{ margin: "auto", fontWeight: "bold" }}>MAX</p></p>
          </div>}

          {getfisish() && Stakebutton}
          {winner != 0 && <div style={{ display: "grid", gap: "5px", gridTemplateColumns: "auto", height: "10vh", width: "100%", margin: "auto", borderRadius: "10px" }}>
            <div style={{
              height: "min-content",
              width: "100%",
              margin: "auto",
              display: "grid", gridTemplateColumns: "auto",

              borderRadius: "10px",
            }}>
              <div onClick={() => { }} style={{
                display: "grid", gridTemplateColumns: "5vh auto auto", height: "5vh", width: "100%", border: "1px solid #33415550", margin: "auto",
                background: "#33415520", borderRadius: "2px", color: "#fff"
              }}>
                <div style={{
                  borderRadius: "360px",
                  height: "5vh",
                  width: "5vh",
                  margin: "auto",

                  background: "url('/icons/nlogo.png')  center center / 50% no-repeat",
                }}>

                </div>
                <p style={{ fontSize: "1.5vh", textAlign: "left", height: "min-content", margin: "auto 0" }}>{"You Result"}<p />{!getresult().toString().includes("-") && getresult()}</p>
                {getresult().toString().includes("-") && <p style={{ fontSize: "2vh", textAlign: "Center", height: "min-content", margin: "auto", fontWeight: "bold", color: "#FF000099" }}>LOSS</p>}

              </div>
            </div>
            {getclaim() && claimbutton}

          </div>
          }

        </div>
      </div>
      {hubinteract && modaltokensls}
    </div>
  );
}
export default Modal;
