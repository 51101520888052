import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ethers } from 'ethers';
import SAFE from "./textpar";
import Timer from '../Timer/timer';
import checks from '../../listverifys.json'

const Modal = (props) => {
    const navigate = useNavigate();
    function nextblock(accountBalancemctTB, d) {
        return accountBalancemctTB == undefined ? 0 : parseFloat(ethers.utils.formatUnits(accountBalancemctTB, d)).toFixed(2)
    }
    function nextpercent(accountBalancemctTB, d) {
        return parseFloat((accountBalancemctTB) + "").toFixed(2)
    }
    function progress() {
        if (props.obj.balance == undefined) { return '0' }
        const max = props.obj.hardcap == undefined ? props.obj.softcap : props.obj.hardcap
        return ((nextblock(props.obj.bal > 0 ? props.obj.bal : props.obj.balance, 18) / nextblock(max, 18)) * 100).toString()
    }
    return (
        <>
            {(props.inlist && props.isOnFilter) && (props.nameSearch.toString() == '' ? true : props.obj.tokenname.toString().includes(props.nameSearch))
                ?
                <div style={{
                    height: "min-content",
                    minHeight: "70px",
                    width: "280px",
                    display: "grid",
                    gridTemplateRows: "auto",

                    margin: "auto",
                    marginTop: "15px",
                    border: "1px solid #ffffff10",
                    background: "#ffffff10",
                    marginBottom: "15px",
                    color: "#fff",
                    borderRadius: "10px",

                }}>

                    <div style={{
                        height: "min-height",
                        width: "100%",
                        display: "grid",
                        margin: "auto",
                        gridTemplateColumns: "repeat(auto-fit, minmax(280px, 1fr)",
                        gap: "10px",
                    }}>
                        <div style={{
                            height: "min-content",

                            margin: "auto",
                            display: "flex",
                            gap: "5px"
                        }}>

                            {props.obj.Affiliated > 0 &&
                                <div style={{
                                    height: "25px",
                                    width: "80px",
                                    display: "inline-flex",
                                    background: "#00000020",
                                    borderRadius: "90px",
                                    paddingRight: "10px"
                                }}>
                                    <div style={{
                                        height: "10px",
                                        width: "10px",
                                        margin: "auto",
                                        background: "#fff",
                                        borderRadius: "360px",
                                    }}></div>
                                    <p style={{ textAlign: "center", height: "min-content", fontSize: "10px", margin: "auto 0" }}>Affiliate {props.obj.Affiliated} %</p>

                                </div>
                            }
                            <div style={{
                                height: "25px",
                                width: "80px",
                                display: "inline-flex",
                                background: "#00000020",
                                borderRadius: "90px",
                                paddingRight: "10px"
                            }}>
                                <div style={{
                                    height: "10px",
                                    width: "10px",
                                    margin: "auto",
                                    background: parseInt(Date.parse(new Date()) / 1000) >= parseInt(props.obj.starttime) ? parseInt(Date.parse(new Date()) / 1000) < parseInt(props.obj.endtime) ? "#0000ff" : "#ff0000" : "#ffff00",
                                    borderRadius: "360px",
                                }}></div>
                                <p style={{ textAlign: "center", height: "min-content", fontSize: "10px", margin: "auto 0" }}>{props.status}</p>

                            </div>
                        </div>

                        <div style={{
                            height: "min-content",
                            width: "100%",
                            margin: "auto",
                            display: "grid",
                            gap: "5px"
                        }}>

                            <div style={{
                                height: "min-content",
                                width: "100%",
                                margin: "auto",
                                display: "grid",
                                gridTemplateColumns: "75px auto",
                                gap: "5px",
                                fontSize: "15px",

                            }}>
                                <div style={{
                                    height: "50px",
                                    width: "50px",
                                    color: "#fff",
                                    margin: "auto",
                                    background: "url('" + props.obj['LogoURL'] + " ') center center / 50px no-repeat",

                                    display: "grid",
                                    borderRadius: "360px",

                                }}>

                                </div>
                                <div style={{
                                    height: "min-content",
                                    width: "100%",
                                    margin: "auto",
                                    display: "grid",

                                    gap: "5px",
                                    fontSize: "15px",

                                }}>
                                    <div style={{
                                        height: "min-content",
                                        width: "100%",
                                        margin: "auto",
                                        display: "grid",
                                        gridTemplateColumns: "auto 90px",
                                        gap: "5px",


                                    }}>
                                        <p style={{
                                            textAlign: "left",
                                            width: "100%",
                                            height: "min-content",
                                            fontSize: "15px",
                                            margin: "auto",
                                            color: "#e5e5e5",
                                            paddingTop: "5px"
                                        }}>{props.obj.tokenname}

                                        </p>



                                    </div>


                                    <div style={{
                                        height: "min-content",
                                        width: "100%",
                                        margin: "auto",
                                        display: "flex",
                                        gap: "5px",
                                        fontSize: "15px",

                                    }}>
                                        {(checks[0][props.obj.cts] != undefined && checks[0][props.obj.cts].AUDIT) && <button onClick={() => { navigate(checks[0][props.obj.cts].ALINK) }} style={{
                                            display: "grid", gridTemplateColumns: "auto", height: "35px", width: "60px", background: "#00bdd6", borderRadius: "10px", border: "1px solid #00000020", color: "#fff"
                                        }}>
                                            <p style={{ textAlign: "left", height: "min-content", margin: "auto" }}><span style={{ textAlign: "left", fontWeight: "bold", fontSize: "12px" }}>AUDIT</span></p>
                                        </button>}
                                        {(checks[0][props.obj.cts] != undefined && checks[0][props.obj.cts].KYC) && <button style={{
                                            display: "grid", gridTemplateColumns: "auto", height: "35px", width: "60px", color: "#fff", background: "#45c874", borderRadius: "10px", border: "1px solid #00000020",
                                        }}>
                                            <p style={{ textAlign: "left", height: "min-content", margin: "auto" }}><span style={{ textAlign: "left", fontWeight: "bold", fontSize: "12px" }}>KYC</span></p>
                                        </button>}
                                    </div>
                                </div>
                            </div>
                            <br />
                            <SAFE what={'Soft Cap'} result={<p>{nextblock(props.obj.softcap, 18)} {(props.obj.uToken == 0 ? " BNB" : props.obj.uToken == 1 ? " BUSD" : props.obj.uToken == 2 ? " USDT" : " BNB")}</p>} />
                            <div style={{
                                position: " relative",
                                width: "90%",
                                height: "20px",
                                backgroundColor: "#000",
                                margin: "auto",
                                marginTop: "10px",
                                marginBottom: "2px",
                                borderRadius: "5px",

                            }}>

                                <div style={{
                                    position: "absolute",
                                    width: parseFloat(progress()) > 100 ? "100%" : progress() + "%",
                                    height: "100%",
                                    backgroundColor: "#999",
                                    borderRadius: "5px"
                                }}></div>
                                {parseFloat(progress()) > 100 ? <div style={{
                                    position: "absolute",
                                    width: "20%",
                                    height: "100%",
                                    backgroundColor: "#44444450",
                                    borderRadius: "5px"
                                }}></div> : <></>}


                            </div>
                            {props.obj.url != '/fair-launch/' && <SAFE what={nextblock(props.obj.bal > 0 ? props.obj.bal : props.obj.balance, 18) + (props.obj.uToken == 0 ? " BNB" : props.obj.uToken == 1 ? " BUSD" : props.obj.uToken == 2 ? " USDT" : " BNB")} result={nextblock(props.obj.hardcap, 18) + (props.obj.uToken == 0 ? " BNB" : props.obj.uToken == 1 ? " BUSD" : props.obj.uToken == 2 ? " USDT" : " BNB")} />}
                            {props.obj.url == '/fair-launch/' && <SAFE what={nextblock(props.obj.bal > 0 ? props.obj.bal : props.obj.balance, 18) + (props.obj.uToken == 0 ? " BNB" : props.obj.uToken == 1 ? " BUSD" : props.obj.uToken == 2 ? " USDT" : " BNB")} result={nextblock(props.obj.softcap, 18) + (props.obj.uToken == 0 ? " BNB" : props.obj.uToken == 1 ? " BUSD" : props.obj.uToken == 2 ? " USDT" : " BNB")} />}

                            {props.obj.LiquidifyPercent != undefined && <SAFE what={"Liquidify"} result={nextpercent(props.obj.LiquidifyPercent, 2) + " %"} />}
                            {props.obj.Locktime != undefined && <SAFE what={"Lockup Time"} result={parseInt(props.obj.Locktime) > 59 ? (parseInt(props.obj.Locktime) / 60).toFixed(2) + " Hours" : props.obj.Locktime + " minutes"} />}
                            <div style={{
                                height: "100px",
                                width: "100%",
                                margin: "auto",
                                display: "grid",
                                gridTemplateColumns: "auto auto",
                                gap: "5px",
                                fontSize: "15px",

                            }}>
                                <button style={{
                                    display: "grid", gridTemplateColumns: "auto", width: "90%", color: "#fff", background: "#00000000", borderRadius: "10px", border: "1px solid #00000020", margin: "auto"
                                }}>
                                    <Timer endtime={parseInt(Date.parse(new Date()) / 1000) >= parseInt(props.obj.starttime) ? (new Date(parseInt(props.obj.endtime * 1000))).toString() : (new Date(parseInt(props.obj.starttime * 1000))).toString()} />
                                </button>

                                <button onClick={() => navigate(props.obj.url + props.obj.cts)} style={{
                                    cursor: "pointer", display: "grid", gridTemplateColumns: "auto", height: "45px", width: "90%", color: "#fff", background: "#00000020", borderRadius: "10px", border: "1px solid #00000020", margin: "auto"
                                }}>
                                    <p style={{ textAlign: "left", height: "min-content", margin: "auto" }}><span style={{ textAlign: "left", fontWeight: "bold", fontSize: "12px" }}>VIEW SALE</span></p>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                : <></>}
        </>
    );


}

export default Modal;