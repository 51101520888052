import React, { useState, useEffect, useContext } from 'react';
import { Contract, ethers } from 'ethers';
import 'balloon-css';
import { useNavigate } from "react-router-dom";
import { toChecksumAddress } from 'ethereum-checksum-address';
import mtkswap from '../../abis/martikswap.json'
import pancakeabi from "../../abis/pankakeRouter.json";
import abiToken from "../../abis/bnbabi.json";
import TokenNomics from '../../components/Launchpad/TokenNomics';
import AppContext from '../../framework/helpers/AppContext';
import env from '../../hooks/data/Config';
import TokenInfo from '../swap/tokenInfo';
import ModalSwap from '../swap/modalSwap';
import ModalSettings from '../swap/modalsettings';
import Tokens from '../swap/tokens.json';
import profiles from '../swap/profileTokens.json';
import ModalSniper from '../swap/modalSniper';
const Web3 = require('web3-eth');

const MiniSwap = (props) => {
  const GLOBAL = useContext(AppContext)
  const {
    account,
    provider,
    chain,
    chains,
    web3,
    aweb3,
    Tokencontract,
    gasTX,
    getID,
    callTX,
    sendTX,
    nSendTX,
    nSendCT
} = useContext(AppContext)
GLOBAL.provider = aweb3()
GLOBAL.chain = {
    chainId: getID()
}
  return (
    <Swap title={"sdgsg"} tokenM={props.token} nSendTX={GLOBAL['nSendTX']} Tokencontract={GLOBAL['Tokencontract']} heighti={"6vh"} bgcolor={"#ffffff20"} buttoncolor={"#ffffff20"} chainId={GLOBAL['chain']['chainId']} array={[GLOBAL['provider'], env.CONTRACTS[GLOBAL['chain']['chainId']].router, pancakeabi, abiToken, GLOBAL['account']]} />
  );
}


const Swap = (props) => {
  let contract = props.array[1]
  let cakeabi = props.array[2]
  let tokenabi = props.array[3]
  const {
    account,
    provider,
    chain,
    chains,
    web3,
    Tokencontract,
    gasTX,
    getID,
    callTX,
    sendTX,
    nSendTX,
    nSendCT
  } = useContext(AppContext)
  const martikRouter = '0xA1a39C041221765eb52BaEA2FDBbc77aa92c0dF3'
  //const martikRouter= 0xd9152d3EAAc57301678C92f746281539F62e0613
  const tokens_default = Tokens;

  const GLOBAL = {
    provider: props.array[0],
    account: props.array[4],
    netid: props.chainId
  };

  const [hubinteract, setHint] = useState(false);
  const [settings, setSettings] = useState(false);
  const [active_mode, setactiveMode] = useState(0);
  const [cake, setCake] = useState(undefined);
  const [isa, setIsA] = useState(false);
  const [accountBNB, setBNBbalance] = useState(0);
  const [tokenAname, setTokenAname] = useState(tokens_default[GLOBAL['netid']][0].nome);
  const [tokenBname, setTokenBname] = useState(tokens_default[GLOBAL['netid']][2].nome);
  const [tokenA, setTokenA] = useState(0);
  const [tokenB, setTokenB] = useState(0);
  const [WBNB, setWBNB] = useState(tokens_default[GLOBAL['netid']][0].contract);
  const [tokenACT, setTokenACT] = useState(tokens_default[GLOBAL['netid']][0].contract);
  const [tokenBCT, setTokenBCT] = useState(tokens_default[GLOBAL['netid']][2].contract);
  const [accountBalancemTA, setAccountBalanceTA] = useState(0);
  const [accountBalancemTB, setAccountBalanceTB] = useState(0);
  const [decimalsTA, setCdecimalsTA] = useState(18);
  const [decimalsTB, setCdecimalsTB] = useState(18);
  const [iTA, setiTA] = useState(tokens_default[GLOBAL['netid']][0].icon);
  const [iTB, setiTB] = useState(tokens_default[GLOBAL['netid']][2].icon);
  const [totalSupplyA, setTotalSupplyA] = useState(0);
  const [price, setprice] = useState("");
  const [AllowA, setAllowA] = useState(0);
  const [totalSupplyB, setTotalSupplyB] = useState(0);
  const [splippage, setSlip] = useState(15);

  const [amountinswap, setAmountinSwap] = useState(0);
  const [amountoutswap, setAmountoutSwap] = useState(0);
  const [listsearch, setlistsearch] = useState(null);
  const [ctfind, setctcatch] = useState(null);
  const [profile, setprofile] = useState({});
  const [values, setvalues] = useState([]);
  const [tokenomics, settokenomics] = useState(<></>);
  const [Gwei, setGWEI] = useState(5);

  const [dead, setDead] = useState(20);


  const [usdprices, setusdprices] = useState({
    token: undefined,
    lpBNB: undefined,
    lpBNBprice: undefined
  });
  const [seconds, setSeconds] = useState(0);
  useEffect(() => {
    connectToSelectedNetwork()
    let interval = setInterval(() => {
      setSeconds(seconds => seconds + 1)
      connectToSelectedNetwork()
      //
    }, 20000);
    return () => clearInterval(interval);
  }, [seconds]);
  const navigate = useNavigate();
  useEffect(() => {
    gettokenfromlink(props.tokenM)
  }, []);
  useEffect(() => {
    connectToSelectedNetwork()
  }, [tokenACT, tokenBCT, price]);
  async function usdpricebnb(token, v) {
    try {
      const cake = await new web3.Contract(cakeabi, props.array[1]);
      const p1 = await cake.methods.getAmountsOut(v, [tokens_default[GLOBAL['netid']][0].contract, tokens_default[GLOBAL['netid']][1].contract]).call()
      return nextblock(p1[1], 18)
    } catch (error) {
      return 0.00
    }
  }
  async function usdprice(token, v) {
    try {
      if (token == tokens_default[GLOBAL['netid']][0].contract) {
        return await usdpricebnb(token, v)
      } else {

        const cake = await new web3.Contract(cakeabi, props.array[1]);
        const p0 = await cake.methods.getAmountsOut(v, [token, tokens_default[GLOBAL['netid']][0].contract]).call()
        const p1 = await cake.methods.getAmountsOut(p0[1], [tokens_default[GLOBAL['netid']][0].contract, tokens_default[GLOBAL['netid']][1].contract]).call()
        return nextblock(p1[1], 18)
      }
    } catch (error) {
      return 0.00
    }
  }
  function getIcon(contract) {
    for (let index = 0; index < tokens_default[GLOBAL['netid']].length; index++) {
      if (tokens_default[GLOBAL['netid']][index].contract == contract) {
        return tokens_default[GLOBAL['netid']][index].icon
        break;
      } else {
        if (index == tokens_default[GLOBAL['netid']].length - 1) {
          return "https://bscscan.com/images/main/empty-token.png"
          break;
        }
      }
    }
  }
  async function connectToSelectedNetwork() {
    const web3 = new Web3(GLOBAL['provider']);
    const _cake = await props.Tokencontract(cakeabi, props.array[1]);
    const ta = await props.Tokencontract(tokenabi, tokenACT);
    const tb = await props.Tokencontract(tokenabi, tokenBCT);
    const W = await props.Tokencontract(tokenabi, tokens_default[GLOBAL['netid']][0].contract);
    const account = GLOBAL['account'] != null ? GLOBAL['account'] : null
    const bal = GLOBAL['account'] != null ? await web3['getBalance'](GLOBAL['account']) : 0
    if (account != null) {
      call(ta['methods']['balanceOf'], setAccountBalanceTA, GLOBAL['account']);
      call(tb['methods']['balanceOf'], setAccountBalanceTB, GLOBAL['account']);
    }

    setCake(_cake);
    setTokenA(ta);
    setTokenB(tb);
    setBNBbalance(bal)

    if (tokenAname == "BNB") {
      setAllowA(1)
    } else {
      if (account != null) {
        if (
          tokenACT == '0x116526135380E28836C6080f1997645d5A807FAE'//mtk
          && tokenBCT == '0xA44145FB8962bc5f2458e94139e7e88C09Ef54A6'//wmtk
        ) {
          call(ta.methods.allowance, setAllowA, GLOBAL['account'], '0xA44145FB8962bc5f2458e94139e7e88C09Ef54A6');
        } else {
          if (
            tokenACT == '0xA44145FB8962bc5f2458e94139e7e88C09Ef54A6'//wmtk
            && tokenBCT == '0x116526135380E28836C6080f1997645d5A807FAE'//mtk
          ) {
            setAllowA(1)
          } else {
            if (
              tokenACT == '0x2B44EC9952ba53291f2Ea253cfF33dAA1DE1385B'//peg
              && tokenBCT == '0xA44145FB8962bc5f2458e94139e7e88C09Ef54A6'//wmtk
            ) {
              setAllowA(1)
            } else {
              if (
                tokenACT == '0xA44145FB8962bc5f2458e94139e7e88C09Ef54A6'//wmtk
                && tokenBCT == '0x2B44EC9952ba53291f2Ea253cfF33dAA1DE1385B'//peg
              ) {
                call(ta.methods.allowance, setAllowA, GLOBAL['account'], '0x2B44EC9952ba53291f2Ea253cfF33dAA1DE1385B');
              } else {
                if (
                  tokenACT == '0x4D71a51AfBF46327a02a4fA6ED7BbE5379eE8c9c'
                  && tokenBCT == '0x116526135380E28836C6080f1997645d5A807FAE'
                ) {
                  setAllowA(1)
                } else {
                  if (
                    tokenACT == '0x116526135380E28836C6080f1997645d5A807FAE'
                    && tokenBCT == '0x4D71a51AfBF46327a02a4fA6ED7BbE5379eE8c9c'
                  ) {
                    call(ta.methods.allowance, setAllowA, GLOBAL['account'], '0x4D71a51AfBF46327a02a4fA6ED7BbE5379eE8c9c');
                  } else {
                    if (
                      tokenACT == '0xeB35C077DBB3bbfdceD43A16E3c6E7De746b6A81'
                      && tokenBCT == '0x116526135380E28836C6080f1997645d5A807FAE'
                    ) {
                      setAllowA(1)
                    } else {
                      if (
                        tokenACT == '0x116526135380E28836C6080f1997645d5A807FAE'
                        && tokenBCT == '0xeB35C077DBB3bbfdceD43A16E3c6E7De746b6A81'
                      ) {
                        call(ta.methods.allowance, setAllowA, GLOBAL['account'], '0xeB35C077DBB3bbfdceD43A16E3c6E7De746b6A81');
                      } else {
                        if (
                          tokenACT == '0x4179e0fa00B516a6B2a8fa9F6bF88dB3aA962F4F'
                          && tokenBCT == '0x116526135380E28836C6080f1997645d5A807FAE'
                        ) {
                          setAllowA(1)
                        } else {
                          if (
                            tokenACT == '0x116526135380E28836C6080f1997645d5A807FAE'
                            && tokenBCT == '0x4179e0fa00B516a6B2a8fa9F6bF88dB3aA962F4F'
                          ) {
                            call(ta.methods.allowance, setAllowA, GLOBAL['account'], '0x4179e0fa00B516a6B2a8fa9F6bF88dB3aA962F4F');
                          } else {
                            if (
                              tokenACT == '0xa5b3011d8D42766Aa7C28B734EAF88c0415006cB'
                              && tokenBCT == '0x116526135380E28836C6080f1997645d5A807FAE'
                            ) {
                              setAllowA(1)
                            } else {
                              if (
                                tokenACT == '0x116526135380E28836C6080f1997645d5A807FAE'
                                && tokenBCT == '0xa5b3011d8D42766Aa7C28B734EAF88c0415006cB'
                              ) {
                                call(ta.methods.allowance, setAllowA, GLOBAL['account'], '0xa5b3011d8D42766Aa7C28B734EAF88c0415006cB');
                              } else {
                                if (
                                  tokenACT == '0x1CA9ACFCCAa9720530a7130DE562C70b80c72b7B'
                                  && tokenBCT == '0x116526135380E28836C6080f1997645d5A807FAE'
                                ) {
                                  setAllowA(1)
                                } else {
                                  if (
                                    tokenACT == '0x116526135380E28836C6080f1997645d5A807FAE'
                                    && tokenBCT == '0x1CA9ACFCCAa9720530a7130DE562C70b80c72b7B'
                                  ) {
                                    call(ta.methods.allowance, setAllowA, GLOBAL['account'], '0x1CA9ACFCCAa9720530a7130DE562C70b80c72b7B');
                                  } else {
                                    if (
                                      tokenACT == '0x4188B8d8Ed645615Ee08e8764699A124351b3013'
                                      && tokenBCT == '0x116526135380E28836C6080f1997645d5A807FAE'
                                    ) {
                                      setAllowA(1)
                                    } else {
                                      if (
                                        tokenACT == '0x116526135380E28836C6080f1997645d5A807FAE'
                                        && tokenBCT == '0x4188B8d8Ed645615Ee08e8764699A124351b3013'
                                      ) {
                                        call(ta.methods.allowance, setAllowA, GLOBAL['account'], '0x4188B8d8Ed645615Ee08e8764699A124351b3013');
                                      } else {
                                        if (
                                          tokenACT == '0x922473F4A28095f9cddF9dFb44D616C935416077'
                                          && tokenBCT == '0x116526135380E28836C6080f1997645d5A807FAE'
                                        ) {
                                          setAllowA(1)
                                        } else {
                                          if (
                                            tokenACT == '0x116526135380E28836C6080f1997645d5A807FAE'
                                            && tokenBCT == '0x922473F4A28095f9cddF9dFb44D616C935416077'
                                          ) {
                                            call(ta.methods.allowance, setAllowA, GLOBAL['account'], '0x922473F4A28095f9cddF9dFb44D616C935416077');
                                          } else {
                                            if (
                                              tokenACT == '0x3BCAB3CcA6901D827fce01EDE5b41d3340cd61eF'
                                              && tokenBCT == '0x116526135380E28836C6080f1997645d5A807FAE'
                                            ) {
                                              setAllowA(1)
                                            } else {
                                              if (
                                                tokenACT == '0x116526135380E28836C6080f1997645d5A807FAE'
                                                && tokenBCT == '0x3BCAB3CcA6901D827fce01EDE5b41d3340cd61eF'
                                              ) {
                                                call(ta.methods.allowance, setAllowA, GLOBAL['account'], '0x3BCAB3CcA6901D827fce01EDE5b41d3340cd61eF');
                                              } else {
                                                if (
                                                  tokenACT == '0x9Df92A74bC31B40C34Bb5e92Bd48a18d3887ee12'
                                                  && tokenBCT == '0x116526135380E28836C6080f1997645d5A807FAE'
                                                ) {
                                                  setAllowA(1)
                                                } else {
                                                  if (
                                                    tokenACT == '0x116526135380E28836C6080f1997645d5A807FAE'
                                                    && tokenBCT == '0x9Df92A74bC31B40C34Bb5e92Bd48a18d3887ee12'
                                                  ) {
                                                    call(ta.methods.allowance, setAllowA, GLOBAL['account'], '0x9Df92A74bC31B40C34Bb5e92Bd48a18d3887ee12');
                                                  } else {
                                                    if (
                                                      tokenACT == '0xa071818BcB54B51b9a24aD6F8f13A3F481C2AF58'
                                                      && tokenBCT == '0x116526135380E28836C6080f1997645d5A807FAE'
                                                    ) {
                                                      setAllowA(1)
                                                    } else {
                                                      if (
                                                        tokenACT == '0x116526135380E28836C6080f1997645d5A807FAE'
                                                        && tokenBCT == '0xa071818BcB54B51b9a24aD6F8f13A3F481C2AF58'
                                                      ) {
                                                        call(ta.methods.allowance, setAllowA, GLOBAL['account'], '0xa071818BcB54B51b9a24aD6F8f13A3F481C2AF58');
                                                      } else {
                                                        call(ta.methods.allowance, setAllowA, GLOBAL['account'], martikRouter);
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }

                                          }
                                        }

                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

      }
    }

    { ta != undefined && call(ta.methods.totalSupply, setTotalSupplyA) };
    { tb != undefined && call(tb.methods.totalSupply, setTotalSupplyB) };
    { ta != undefined && call(ta.methods.decimals, setCdecimalsTA) };
    { tb != undefined && call(tb.methods.decimals, setCdecimalsTB) };
    { cake != undefined && _tprice() }
  }
  async function gettokenfromlink(e) {
    const bota = await props.Tokencontract(tokenabi, e);
    bota.methods.symbol().call()
      .then(ress => {
        setIsA(false)
        choicecoin(ress, e)
        setiTB(getIcon(e))
      })
      .catch(error => {
        //console.log(error)
      });
  }

  function choicecoin(e, c) {
    if (isa) {
      setTokenAname(e)
      setTokenACT(c);
    } else {
      setTokenBname(e)
      setTokenBCT(c);


    }
    setAmountinSwap(0)
    setAmountoutSwap(0)
  }

  function nextblock(accountBalancemctTB, d) {
    if (accountBalancemctTB != undefined && accountBalancemctTB != 0) {
      if (accountBalancemctTB.toString().length >= d) {
        return parseFloat(ethers.utils.formatUnits(accountBalancemctTB, d)).toFixed(5)
      } else {
        let cutin = (d - accountBalancemctTB.toString().length) + 2
        return parseFloat(ethers.utils.formatUnits(accountBalancemctTB, d)).toFixed(cutin)
      }
    } else {
      return 0.00
    }
  }
  const balancetokenA = () => {
    if (tokenAname == "BNB") {
      return nextblock(accountBNB, decimalsTA, 5);
    } else {
      return nextblock(accountBalancemTA, decimalsTA, 5)
    }
  }
  const balancetokenB = () => {
    if (tokenBname == "BNB") {
      return nextblock(accountBNB, decimalsTB, 5);
    } else {
      return nextblock(accountBalancemTB, decimalsTB, 5)
    }
  }

  const deadlinevalue = () => {
    return Date.now() + 1000 * 60 * dead
  }
  async function swap() {
    let a = ['']
    let b = ['']
    let e = amountinswap + ""
    //let amountout = (amountoutswap - ((amountoutswap * splippage) / 100)) + ""
    let amountout = (amountoutswap / ((parseFloat(splippage) / 100) + 1)) + ""
    if (e.toString().includes(",")) {
      a = e.toString().split(",")
      e = ethers.utils.parseUnits(a[0] + "." + a[1], decimalsTA).toString()
    } else {
      e = ethers.utils.parseUnits(e, decimalsTA).toString()
    }

    if (amountout.toString().includes(",")) {
      b = amountout.toString().split(",")
      amountout = ethers.utils.parseUnits(b[0] + "." + b[1], decimalsTB).toString()
    } else {
      b = amountout.toString().split(".")
      const dec = b[1].length <= decimalsTB ? b[1] : b[1].slice(0, decimalsTB)
      amountout = ethers.utils.parseUnits(b[0] + "." + dec, decimalsTB).toString()
    }
    if (
      tokenACT == '0x116526135380E28836C6080f1997645d5A807FAE'
      && tokenBCT == '0xA44145FB8962bc5f2458e94139e7e88C09Ef54A6'
    ) {

      await props.nSendTX('0xA44145FB8962bc5f2458e94139e7e88C09Ef54A6',
        [{ "inputs": [{ "internalType": "uint256", "name": "amount", "type": "uint256" }], "name": "toKitram", "outputs": [], "stateMutability": "nonpayable", "type": "function" }, { "inputs": [{ "internalType": "uint256", "name": "amount", "type": "uint256" }], "name": "toMartik", "outputs": [], "stateMutability": "nonpayable", "type": "function" }]
        , GLOBAL['netid'], 'toKitram', '0', Gwei, e)
      return true;
    }
    if (
      tokenACT == '0xA44145FB8962bc5f2458e94139e7e88C09Ef54A6'
      && tokenBCT == '0x116526135380E28836C6080f1997645d5A807FAE'
    ) {

      await props.nSendTX('0xA44145FB8962bc5f2458e94139e7e88C09Ef54A6',
        [{ "inputs": [{ "internalType": "uint256", "name": "amount", "type": "uint256" }], "name": "toKitram", "outputs": [], "stateMutability": "nonpayable", "type": "function" }, { "inputs": [{ "internalType": "uint256", "name": "amount", "type": "uint256" }], "name": "toMartik", "outputs": [], "stateMutability": "nonpayable", "type": "function" }]
        , GLOBAL['netid'], 'toMartik', '0', Gwei, e)
      return true;
    }

    if (
      tokenACT == '0x2B44EC9952ba53291f2Ea253cfF33dAA1DE1385B'
      && tokenBCT == '0xA44145FB8962bc5f2458e94139e7e88C09Ef54A6'
    ) {

      await props.nSendTX('0x2B44EC9952ba53291f2Ea253cfF33dAA1DE1385B',
        [{ "inputs": [{ "internalType": "uint256", "name": "amount", "type": "uint256" }], "name": "toWrapped", "outputs": [], "stateMutability": "nonpayable", "type": "function" }, { "inputs": [], "name": "totalSupply", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" }, { "inputs": [{ "internalType": "uint256", "name": "amount", "type": "uint256" }], "name": "towmartik", "outputs": [], "stateMutability": "nonpayable", "type": "function" }]
        , GLOBAL['netid'], 'toWrapped', '0', Gwei, e)
      return true;
    }
    if (
      tokenACT == '0xA44145FB8962bc5f2458e94139e7e88C09Ef54A6'
      && tokenBCT == '0x2B44EC9952ba53291f2Ea253cfF33dAA1DE1385B'
    ) {

      await props.nSendTX('0x2B44EC9952ba53291f2Ea253cfF33dAA1DE1385B',
        [{ "inputs": [{ "internalType": "uint256", "name": "amount", "type": "uint256" }], "name": "toWrapped", "outputs": [], "stateMutability": "nonpayable", "type": "function" }, { "inputs": [], "name": "totalSupply", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" }, { "inputs": [{ "internalType": "uint256", "name": "amount", "type": "uint256" }], "name": "towmartik", "outputs": [], "stateMutability": "nonpayable", "type": "function" }]
        , GLOBAL['netid'], 'towmartik', '0', Gwei, e)
      return true;
    }

    if (
      tokenACT == '0x4D71a51AfBF46327a02a4fA6ED7BbE5379eE8c9c'
      && tokenBCT == '0x116526135380E28836C6080f1997645d5A807FAE'
    ) {

      await props.nSendTX('0x4D71a51AfBF46327a02a4fA6ED7BbE5379eE8c9c',
        [{ "inputs": [{ "internalType": "uint256", "name": "amount", "type": "uint256" }], "name": "toKitram", "outputs": [], "stateMutability": "nonpayable", "type": "function" }, { "inputs": [{ "internalType": "uint256", "name": "amount", "type": "uint256" }], "name": "toMartik", "outputs": [], "stateMutability": "nonpayable", "type": "function" }]
        , GLOBAL['netid'], 'toMartik', '0', Gwei, e)
      return true;
    }
    if (
      tokenACT == '0x116526135380E28836C6080f1997645d5A807FAE'
      && tokenBCT == '0x4D71a51AfBF46327a02a4fA6ED7BbE5379eE8c9c'
    ) {

      await props.nSendTX('0x4D71a51AfBF46327a02a4fA6ED7BbE5379eE8c9c',
        [{ "inputs": [{ "internalType": "uint256", "name": "amount", "type": "uint256" }], "name": "toKitram", "outputs": [], "stateMutability": "nonpayable", "type": "function" }, { "inputs": [{ "internalType": "uint256", "name": "amount", "type": "uint256" }], "name": "toMartik", "outputs": [], "stateMutability": "nonpayable", "type": "function" }]
        , GLOBAL['netid'], 'toKitram', '0', Gwei, e)
      return true;
    }

    if (
      tokenACT == '0xeB35C077DBB3bbfdceD43A16E3c6E7De746b6A81'
      && tokenBCT == '0x116526135380E28836C6080f1997645d5A807FAE'
    ) {

      await props.nSendTX('0xeB35C077DBB3bbfdceD43A16E3c6E7De746b6A81',
        [{ "inputs": [{ "internalType": "uint256", "name": "amount", "type": "uint256" }], "name": "toKitram", "outputs": [], "stateMutability": "nonpayable", "type": "function" }, { "inputs": [{ "internalType": "uint256", "name": "amount", "type": "uint256" }], "name": "toMartik", "outputs": [], "stateMutability": "nonpayable", "type": "function" }]
        , GLOBAL['netid'], 'toMartik', '0', Gwei, e)
      return true;
    }
    if (
      tokenACT == '0x116526135380E28836C6080f1997645d5A807FAE'
      && tokenBCT == '0xeB35C077DBB3bbfdceD43A16E3c6E7De746b6A81'
    ) {

      await props.nSendTX('0xeB35C077DBB3bbfdceD43A16E3c6E7De746b6A81',
        [{ "inputs": [{ "internalType": "uint256", "name": "amount", "type": "uint256" }], "name": "tomini", "outputs": [], "stateMutability": "nonpayable", "type": "function" }, { "inputs": [{ "internalType": "uint256", "name": "amount", "type": "uint256" }], "name": "toMartik", "outputs": [], "stateMutability": "nonpayable", "type": "function" }]
        , GLOBAL['netid'], 'tomini', '0', Gwei, e)
      return true;
    }
    if (
      tokenACT == '0x4179e0fa00B516a6B2a8fa9F6bF88dB3aA962F4F'
      && tokenBCT == '0x116526135380E28836C6080f1997645d5A807FAE'
    ) {

      await props.nSendTX('0x4179e0fa00B516a6B2a8fa9F6bF88dB3aA962F4F',
        [{ "inputs": [{ "internalType": "uint256", "name": "amount", "type": "uint256" }], "name": "toKitram", "outputs": [], "stateMutability": "nonpayable", "type": "function" }, { "inputs": [{ "internalType": "uint256", "name": "amount", "type": "uint256" }], "name": "toMartik", "outputs": [], "stateMutability": "nonpayable", "type": "function" }]
        , GLOBAL['netid'], 'toMartik', '0', Gwei, e)
      return true;
    }
    if (
      tokenACT == '0x116526135380E28836C6080f1997645d5A807FAE'
      && tokenBCT == '0x4179e0fa00B516a6B2a8fa9F6bF88dB3aA962F4F'
    ) {

      await props.nSendTX('0x4179e0fa00B516a6B2a8fa9F6bF88dB3aA962F4F',
        [{ "inputs": [{ "internalType": "uint256", "name": "amount", "type": "uint256" }], "name": "tomini", "outputs": [], "stateMutability": "nonpayable", "type": "function" }, { "inputs": [{ "internalType": "uint256", "name": "amount", "type": "uint256" }], "name": "toMartik", "outputs": [], "stateMutability": "nonpayable", "type": "function" }]
        , GLOBAL['netid'], 'tomini', '0', Gwei, e)
      return true;
    }
    if (
      tokenACT == '0xa5b3011d8D42766Aa7C28B734EAF88c0415006cB'
      && tokenBCT == '0x116526135380E28836C6080f1997645d5A807FAE'
    ) {

      await props.nSendTX('0xa5b3011d8D42766Aa7C28B734EAF88c0415006cB',
        [{ "inputs": [{ "internalType": "uint256", "name": "amount", "type": "uint256" }], "name": "toKitram", "outputs": [], "stateMutability": "nonpayable", "type": "function" }, { "inputs": [{ "internalType": "uint256", "name": "amount", "type": "uint256" }], "name": "toMartik", "outputs": [], "stateMutability": "nonpayable", "type": "function" }]
        , GLOBAL['netid'], 'toMartik', '0', Gwei, e)
      return true;
    }
    if (
      tokenACT == '0x116526135380E28836C6080f1997645d5A807FAE'
      && tokenBCT == '0xa5b3011d8D42766Aa7C28B734EAF88c0415006cB'
    ) {

      await props.nSendTX('0xa5b3011d8D42766Aa7C28B734EAF88c0415006cB',
        [{ "inputs": [{ "internalType": "uint256", "name": "amount", "type": "uint256" }], "name": "toWormhole", "outputs": [], "stateMutability": "nonpayable", "type": "function" }, { "inputs": [{ "internalType": "uint256", "name": "amount", "type": "uint256" }], "name": "toMartik", "outputs": [], "stateMutability": "nonpayable", "type": "function" }]
        , GLOBAL['netid'], 'toWormhole', '0', Gwei, e)
      return true;
    }
    if (
      tokenACT == '0x1CA9ACFCCAa9720530a7130DE562C70b80c72b7B'
      && tokenBCT == '0x116526135380E28836C6080f1997645d5A807FAE'
    ) {

      await props.nSendTX('0x1CA9ACFCCAa9720530a7130DE562C70b80c72b7B',
        [{ "inputs": [{ "internalType": "uint256", "name": "amount", "type": "uint256" }], "name": "toKitram", "outputs": [], "stateMutability": "nonpayable", "type": "function" }, { "inputs": [{ "internalType": "uint256", "name": "amount", "type": "uint256" }], "name": "toMartik", "outputs": [], "stateMutability": "nonpayable", "type": "function" }]
        , GLOBAL['netid'], 'toMartik', '0', Gwei, e)
      return true;
    }
    if (
      tokenACT == '0x116526135380E28836C6080f1997645d5A807FAE'
      && tokenBCT == '0x1CA9ACFCCAa9720530a7130DE562C70b80c72b7B'
    ) {

      await props.nSendTX('0x1CA9ACFCCAa9720530a7130DE562C70b80c72b7B',
        [{ "inputs": [{ "internalType": "uint256", "name": "amount", "type": "uint256" }], "name": "toAIFINANCE", "outputs": [], "stateMutability": "nonpayable", "type": "function" }, { "inputs": [{ "internalType": "uint256", "name": "amount", "type": "uint256" }], "name": "toMartik", "outputs": [], "stateMutability": "nonpayable", "type": "function" }]
        , GLOBAL['netid'], 'toAIFINANCE', '0', Gwei, e)
      return true;
    }
    if (
      tokenACT == '0x4188B8d8Ed645615Ee08e8764699A124351b3013'
      && tokenBCT == '0x116526135380E28836C6080f1997645d5A807FAE'
    ) {

      await props.nSendTX('0x4188B8d8Ed645615Ee08e8764699A124351b3013',
        [{ "inputs": [{ "internalType": "uint256", "name": "amount", "type": "uint256" }], "name": "toKitram", "outputs": [], "stateMutability": "nonpayable", "type": "function" }, { "inputs": [{ "internalType": "uint256", "name": "amount", "type": "uint256" }], "name": "toMartik", "outputs": [], "stateMutability": "nonpayable", "type": "function" }]
        , GLOBAL['netid'], 'toMartik', '0', Gwei, e)
      return true;
    }
    if (
      tokenACT == '0x116526135380E28836C6080f1997645d5A807FAE'
      && tokenBCT == '0x4188B8d8Ed645615Ee08e8764699A124351b3013'
    ) {

      await props.nSendTX('0x4188B8d8Ed645615Ee08e8764699A124351b3013',
        [{ "inputs": [{ "internalType": "uint256", "name": "amount", "type": "uint256" }], "name": "toburn30", "outputs": [], "stateMutability": "nonpayable", "type": "function" }, { "inputs": [{ "internalType": "uint256", "name": "amount", "type": "uint256" }], "name": "toMartik", "outputs": [], "stateMutability": "nonpayable", "type": "function" }]
        , GLOBAL['netid'], 'toburn30', '0', Gwei, e)
      return true;
    }
    if (
      tokenACT == '0x922473F4A28095f9cddF9dFb44D616C935416077'
      && tokenBCT == '0x116526135380E28836C6080f1997645d5A807FAE'
    ) {

      await props.nSendTX('0x922473F4A28095f9cddF9dFb44D616C935416077',
        [{ "inputs": [{ "internalType": "uint256", "name": "amount", "type": "uint256" }], "name": "toKitram", "outputs": [], "stateMutability": "nonpayable", "type": "function" }, { "inputs": [{ "internalType": "uint256", "name": "amount", "type": "uint256" }], "name": "toMartik", "outputs": [], "stateMutability": "nonpayable", "type": "function" }]
        , GLOBAL['netid'], 'toMartik', '0', Gwei, e)
      return true;
    }
    if (
      tokenACT == '0x116526135380E28836C6080f1997645d5A807FAE'
      && tokenBCT == '0x922473F4A28095f9cddF9dFb44D616C935416077'
    ) {

      await props.nSendTX('0x922473F4A28095f9cddF9dFb44D616C935416077',
        [{ "inputs": [{ "internalType": "uint256", "name": "amount", "type": "uint256" }], "name": "toWMartik", "outputs": [], "stateMutability": "nonpayable", "type": "function" }, { "inputs": [{ "internalType": "uint256", "name": "amount", "type": "uint256" }], "name": "toMartik", "outputs": [], "stateMutability": "nonpayable", "type": "function" }]
        , GLOBAL['netid'], 'toWMartik', '0', Gwei, e)
      return true;
    }
    if (
      tokenACT == '0x3BCAB3CcA6901D827fce01EDE5b41d3340cd61eF'
      && tokenBCT == '0x116526135380E28836C6080f1997645d5A807FAE'
    ) {

      await props.nSendTX('0x3BCAB3CcA6901D827fce01EDE5b41d3340cd61eF',
        [{ "inputs": [{ "internalType": "uint256", "name": "amount", "type": "uint256" }], "name": "topepe", "outputs": [], "stateMutability": "nonpayable", "type": "function" }, { "inputs": [{ "internalType": "uint256", "name": "amount", "type": "uint256" }], "name": "toMartik", "outputs": [], "stateMutability": "nonpayable", "type": "function" }]
        , GLOBAL['netid'], 'toMartik', '0', Gwei, e)
      return true;
    }
    if (
      tokenACT == '0x116526135380E28836C6080f1997645d5A807FAE'
      && tokenBCT == '0x3BCAB3CcA6901D827fce01EDE5b41d3340cd61eF'
    ) {

      await props.nSendTX('0x3BCAB3CcA6901D827fce01EDE5b41d3340cd61eF',
        [{ "inputs": [{ "internalType": "uint256", "name": "amount", "type": "uint256" }], "name": "topepe", "outputs": [], "stateMutability": "nonpayable", "type": "function" }, { "inputs": [{ "internalType": "uint256", "name": "amount", "type": "uint256" }], "name": "toMartik", "outputs": [], "stateMutability": "nonpayable", "type": "function" }]
        , GLOBAL['netid'], 'topepe', '0', Gwei, e)
      return true;
    }
    if (
      tokenACT == '0x9Df92A74bC31B40C34Bb5e92Bd48a18d3887ee12'
      && tokenBCT == '0x116526135380E28836C6080f1997645d5A807FAE'
    ) {

      await props.nSendTX('0x9Df92A74bC31B40C34Bb5e92Bd48a18d3887ee12',
        [{ "inputs": [{ "internalType": "uint256", "name": "amount", "type": "uint256" }], "name": "topepe", "outputs": [], "stateMutability": "nonpayable", "type": "function" }, { "inputs": [{ "internalType": "uint256", "name": "amount", "type": "uint256" }], "name": "toMartik", "outputs": [], "stateMutability": "nonpayable", "type": "function" }]
        , GLOBAL['netid'], 'toMartik', '0', Gwei, e)
      return true;
    }
    if (
      tokenACT == '0x116526135380E28836C6080f1997645d5A807FAE'
      && tokenBCT == '0x9Df92A74bC31B40C34Bb5e92Bd48a18d3887ee12'
    ) {

      await props.nSendTX('0x9Df92A74bC31B40C34Bb5e92Bd48a18d3887ee12',
        [{ "inputs": [{ "internalType": "uint256", "name": "amount", "type": "uint256" }], "name": "toPegMartik", "outputs": [], "stateMutability": "nonpayable", "type": "function" }, { "inputs": [{ "internalType": "uint256", "name": "amount", "type": "uint256" }], "name": "toMartik", "outputs": [], "stateMutability": "nonpayable", "type": "function" }]
        , GLOBAL['netid'], 'toPegMartik', '0', Gwei, e)
      return true;
    }
    if (
      tokenACT == '0xa071818BcB54B51b9a24aD6F8f13A3F481C2AF58'
      && tokenBCT == '0x116526135380E28836C6080f1997645d5A807FAE'
    ) {

      await props.nSendTX('0xa071818BcB54B51b9a24aD6F8f13A3F481C2AF58',
        [{ "inputs": [{ "internalType": "uint256", "name": "amount", "type": "uint256" }], "name": "topepe", "outputs": [], "stateMutability": "nonpayable", "type": "function" }, { "inputs": [{ "internalType": "uint256", "name": "amount", "type": "uint256" }], "name": "toMartik", "outputs": [], "stateMutability": "nonpayable", "type": "function" }]
        , GLOBAL['netid'], 'toMartik', '0', Gwei, e)
      return true;
    }
    if (
      tokenACT == '0x116526135380E28836C6080f1997645d5A807FAE'
      && tokenBCT == '0xa071818BcB54B51b9a24aD6F8f13A3F481C2AF58'
    ) {

      await props.nSendTX('0xa071818BcB54B51b9a24aD6F8f13A3F481C2AF58',
        [{ "inputs": [{ "internalType": "uint256", "name": "amount", "type": "uint256" }], "name": "toDGiroto", "outputs": [], "stateMutability": "nonpayable", "type": "function" }, { "inputs": [{ "internalType": "uint256", "name": "amount", "type": "uint256" }], "name": "toMartik", "outputs": [], "stateMutability": "nonpayable", "type": "function" }]
        , GLOBAL['netid'], 'toDGiroto', '0', Gwei, e)
      return true;
    }
    if (tokenACT == tokenBCT) {
      let bnbabi = [{
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "name": "dst",
            "type": "address"
          },
          {
            "indexed": false,
            "name": "wad",
            "type": "uint256"
          }
        ],
        "name": "Deposit",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "name": "src",
            "type": "address"
          },
          {
            "indexed": false,
            "name": "wad",
            "type": "uint256"
          }
        ],
        "name": "Withdrawal",
        "type": "event"
      }
      ]
      if (tokenAname == "BNB" && tokenBname == "WBNB") {
        await props.nSendTX(WBNB, bnbabi, GLOBAL['netid'], 'deposit', e, Gwei)
      } else {
        if (tokenBname == "BNB" && tokenAname == "WBNB") {
          await props.nSendTX(WBNB, bnbabi, GLOBAL['netid'], 'withdraw', "0", Gwei, e)
        } else {
          alert("Invalid pair")
        }
      }
    } else {
      if (tokenAname == "BNB") {
        await props.nSendTX(martikRouter, mtkswap, GLOBAL['netid'], 'SBNBPT', ethers.utils.parseUnits(((parseFloat(nextblock(e, 18)) + 0.003).toString()), decimalsTA).toString(), Gwei, amountout, [WBNB, tokenBCT], GLOBAL['account'], deadlinevalue(), getQuery('refFrom') == null ? '0xA4de20856640d59A146393ac8CcF3F8e2133DF33' : getQuery('refFrom'))
      } else {
        if (tokenBname == "BNB") {
          await props.nSendTX(martikRouter, mtkswap, GLOBAL['netid'], 'STPBNB', "3000000000000000", Gwei, e, amountout, [tokenACT, WBNB], GLOBAL['account'], deadlinevalue(), getQuery('refFrom') == null ? '0xA4de20856640d59A146393ac8CcF3F8e2133DF33' : getQuery('refFrom'))
        } else {

          await props.nSendTX(martikRouter, mtkswap, GLOBAL['netid'], 'STPT', '3000000000000000', Gwei, e, amountout, [tokenACT, tokenBCT], GLOBAL['account'], deadlinevalue(), getQuery('refFrom') == null ? '0xA4de20856640d59A146393ac8CcF3F8e2133DF33' : getQuery('refFrom'))
        }
      }
    }
  }
  function getQuery(query) {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const foo = params.get(query);
    return foo
  }
  function getPairMigration(query) {

    let toapprove = undefined
    if (
      tokenACT == '0x116526135380E28836C6080f1997645d5A807FAE'
      && tokenBCT == '0xA44145FB8962bc5f2458e94139e7e88C09Ef54A6'
    ) {
      toapprove = '0xA44145FB8962bc5f2458e94139e7e88C09Ef54A6'
    }
    if (
      tokenACT == '0xA44145FB8962bc5f2458e94139e7e88C09Ef54A6'
      && tokenBCT == '0x2B44EC9952ba53291f2Ea253cfF33dAA1DE1385B'
    ) {
      toapprove = '0x2B44EC9952ba53291f2Ea253cfF33dAA1DE1385B'
    }

    if (
      tokenACT == '0x116526135380E28836C6080f1997645d5A807FAE'
      && tokenBCT == '0x4D71a51AfBF46327a02a4fA6ED7BbE5379eE8c9c'
    ) {
      toapprove = '0x4D71a51AfBF46327a02a4fA6ED7BbE5379eE8c9c'
    }
    if (
      tokenACT == '0x116526135380E28836C6080f1997645d5A807FAE'
      && tokenBCT == '0xeB35C077DBB3bbfdceD43A16E3c6E7De746b6A81'
    ) {
      toapprove = '0xeB35C077DBB3bbfdceD43A16E3c6E7De746b6A81'
    }
    if (
      tokenACT == '0x116526135380E28836C6080f1997645d5A807FAE'
      && tokenBCT == '0x4179e0fa00B516a6B2a8fa9F6bF88dB3aA962F4F'
    ) {
      toapprove = '0x4179e0fa00B516a6B2a8fa9F6bF88dB3aA962F4F'
    }
    if (
      tokenACT == '0x116526135380E28836C6080f1997645d5A807FAE'
      && tokenBCT == '0xa5b3011d8D42766Aa7C28B734EAF88c0415006cB'
    ) {
      toapprove = '0xa5b3011d8D42766Aa7C28B734EAF88c0415006cB'
    }
    if (
      tokenACT == '0x116526135380E28836C6080f1997645d5A807FAE'
      && tokenBCT == '0x1CA9ACFCCAa9720530a7130DE562C70b80c72b7B'
    ) {
      toapprove = '0x1CA9ACFCCAa9720530a7130DE562C70b80c72b7B'
    }
    if (
      tokenACT == '0x116526135380E28836C6080f1997645d5A807FAE'
      && tokenBCT == '0x4188B8d8Ed645615Ee08e8764699A124351b3013'
    ) {
      toapprove = '0x4188B8d8Ed645615Ee08e8764699A124351b3013'
    }
    if (
      tokenACT == '0x116526135380E28836C6080f1997645d5A807FAE'
      && tokenBCT == '0x922473F4A28095f9cddF9dFb44D616C935416077'
    ) {
      toapprove = '0x922473F4A28095f9cddF9dFb44D616C935416077'
    }
    if (
      tokenACT == '0x116526135380E28836C6080f1997645d5A807FAE'
      && tokenBCT == '0x3BCAB3CcA6901D827fce01EDE5b41d3340cd61eF'
    ) {
      toapprove = '0x3BCAB3CcA6901D827fce01EDE5b41d3340cd61eF'
    }
    if (
      tokenACT == '0x116526135380E28836C6080f1997645d5A807FAE'
      && tokenBCT == '0x9Df92A74bC31B40C34Bb5e92Bd48a18d3887ee12'
    ) {
      toapprove = '0x9Df92A74bC31B40C34Bb5e92Bd48a18d3887ee12'
    }
    if (
      tokenACT == '0x116526135380E28836C6080f1997645d5A807FAE'
      && tokenBCT == '0xa071818BcB54B51b9a24aD6F8f13A3F481C2AF58'
    ) {
      toapprove = '0xa071818BcB54B51b9a24aD6F8f13A3F481C2AF58'
    }
    return {

      toapprove: toapprove,
    }
  }

  function call(func, callback, ...args) {
    func(...args).call()
      .then((result) => {
        callback(result);
      })
      .catch((error) => {
        ////console.log(...args, error)
      })
  }
  function bnbtext() {
    let a = null

    let obj = getPairMigration()
    if (obj.toapprove != undefined) {
      return 'MIGRATE';
    }
    if (tokenACT == tokenBCT) {
      if (tokenAname == "BNB" && tokenBname == "WBNB") {
        //bnb ~~ wbnb call wrap function
        a = "WRAP"
      } else {
        if (tokenBname == "BNB" && tokenAname == "WBNB") {
          //bnb ~~ wbnb call unwrap function
          a = "UNWRAP"
        } else {
          a = "PAR INVALIDO"
        }
      }
    } else {
      a = "SWAP"
    }
    return a
  }
  async function approve() {
    let toapprove = martikRouter
    let obj = getPairMigration()
    if (obj.toapprove != undefined) {
      toapprove = obj.toapprove
    }
    await props.nSendTX(tokenACT, tokenabi, GLOBAL['netid'], 'approve', '0', Gwei, toapprove, "1" + "0".repeat(50))
  }
  async function getAmountOut(value) {
    setAmountinSwap(value)
    try {
      if (cake != undefined) {
        let a = ['']
        let e = value + ""
        if (e.toString().includes(",")) {
          a = e.toString().split(",")
          e = ethers.utils.parseUnits(a[0] + "." + a[1], decimalsTA).toString()
        } else {
          e = ethers.utils.parseUnits(e, decimalsTA).toString()
        }
        let obj = getPairMigration()
        if (obj.toapprove != undefined) {
          setAmountoutSwap(value)
          return 'MIGRATE';
        }

        if (tokenACT != WBNB && tokenBCT != WBNB) {
          const _price = parseFloat(e) > 0 ? await cake.methods.getAmountsOut(e, [tokenACT, WBNB]).call() : 0
          const price = parseFloat(_price) > 0 ? await cake.methods.getAmountsOut(_price[1], [WBNB, tokenBCT]).call() : 0
          setAmountoutSwap(price[1] != 0 ? nextblock(price[1], decimalsTB, 5) : 0)
        } else {
          if (tokenACT == WBNB && tokenBCT == WBNB) {
            setAmountoutSwap(value)
          } else {
            const price = parseFloat(e) > 0 ? await cake.methods.getAmountsOut(e, [tokenACT, tokenBCT]).call() : 0
            setAmountoutSwap(price[1] != 0 ? nextblock(price[1], decimalsTB, 5) : 0)
          }
        }
      }
    } catch (error) {

    }

  }
  async function getAmountIn(value) {
    setAmountoutSwap(value)
    try {
      if (cake != undefined) {
        let a = ['']
        let e = value + ""
        if (e.toString().includes(",")) {
          a = e.toString().split(",")
          e = ethers.utils.parseUnits(a[0] + "." + a[1], decimalsTB).toString()
        } else {
          e = ethers.utils.parseUnits(e, decimalsTB).toString()
        }
        let obj = getPairMigration()
        if (obj.toapprove != undefined) {
          setAmountinSwap(value)
          return 'MIGRATE';
        }
        if (tokenBCT != WBNB && tokenACT != WBNB) {
          const _price = parseFloat(e) > 0 ? await cake.methods.getAmountsOut(e, [tokenBCT, WBNB]).call() : 0
          const price = parseFloat(_price) > 0 ? await cake.methods.getAmountsOut(_price[1], [WBNB, tokenACT]).call() : 0
          setAmountinSwap(price[1] != 0 ? nextblock(price[1], decimalsTA, 5) : 0)
        } else {
          if (tokenACT == WBNB && tokenBCT == WBNB) {
            setAmountinSwap(value)
          } else {
            const price = parseFloat(e) > 0 ? await cake.methods.getAmountsOut(e, [tokenBCT, tokenACT]).call() : 0
            setAmountinSwap(price[1] != 0 ? nextblock(price[1], decimalsTA, 5) : 0)
          }
        }
      }
    } catch (error) {

    }



  }
  async function _tprice() {
    try {
      if (cake != undefined) {
        let obj = getPairMigration()
        if (obj.toapprove != undefined) {
          setprice("1" + " " + tokenAname + " per " + tokenBname)
          return 'MIGRATE';
        }

        if (tokenBCT != WBNB && tokenACT != WBNB) {
          const _price = await cake.methods.getAmountsOut("1" + "0".repeat(decimalsTB), [tokenBCT, WBNB]).call()
          const price = parseFloat(_price) > 0 ? await cake.methods.getAmountsOut(_price[1], [WBNB, tokenACT]).call() : 0
          setprice(nextblock(price[1], decimalsTA, parseInt(decimalsTA / 2)) + " " + tokenAname + " per " + tokenBname)
        } else {
          if (tokenACT == WBNB && tokenBCT == WBNB) {
            setprice("1" + " " + tokenAname + " per " + tokenBname)
          } else {
            const price = await cake.methods.getAmountsOut("1" + "0".repeat(decimalsTB), [tokenBCT, tokenACT]).call()
            setprice(nextblock(price[1], decimalsTA, parseInt(decimalsTA / 2)) + " " + tokenAname + " per " + tokenBname)
          }
        }
      }
    } catch (error) {

    }

  }

  const SAFE = (props) => {
    return (
      <div style={{ width: "95%", display: "grid", gridTemplateColumns: "auto auto", margin: "auto", marginTop: "5px" }}>
        <div onClick={() => {
          if (props.what == tokenAname) {
            setHint(!hubinteract)
            setIsA(true)
          } else {
            setHint(!hubinteract)
            setIsA(false)
          }
        }} style={{
          cursor: "pointer",
          textAlign: "left",
          height: "20px",
          width: "100%",
          fontWeight: "bold",
          borderRadius: "2px",
          color: "#ddd",
          fontSize: "13px",
          display: "grid", gridTemplateColumns: "40px 20px",
        }}> <span style={{
          overflow: "hidden"
        }}>
            {props.what}
          </span>
          <svg xmlns="http://www.w3.org/2000/svg" style={{ height: "12px", width: "12px", margin: "auto" }} fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
            <path fillRule={"evenodd"} d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
          </svg>
        </div>
        <span style={{
          textAlign: "right",
          width: "100%",
          fontSize: "13px",
          color: "#ddd",
        }}>
          {props.result}
        </span></div>
    );
  }
  const ButtonI = (props) => {
    return (
      <div style={{ display: "grid", gridTemplateColumns: "auto", height: '50px', width: "95%", margin: "auto", marginBottom: "2vh", marginTop: "20px" }}>
        <p onClick={props.func} style={{
          border: "1px solid #000",
          backgroundColor: '#ffffff',
          borderRadius: "10px",
          height: '50px',
          width: "100%",
          display: "grid",
          margin: "auto",
          cursor: "pointer"
        }}><p style={{ margin: "auto", fontWeight: "bold", color: "#000" }}>{props.title}</p></p>
      </div>
    );
  }
  const Desc = (props) => {
    return (
      <p style={{ width: "95%", display: "grid", gridTemplateColumns: "auto auto", margin: "auto", marginTop: "5px" }}><span style={{
        textAlign: "left",
        width: "100%",
        fontWeight: "bold",
        color: "#ddd",
        fontSize: "13px",

      }}>{props.what} <Hint desc={props.desc} /> </span><span style={{
        textAlign: "right",
        width: "100%",
        fontSize: "15px",
        color: "#ddd",
      }}>
          {props.result}
        </span></p>
    );
  }
  const Set = (props) => {
    return (
      <p style={{ width: "100%", height: "40px", display: "grid", gridTemplateColumns: "auto auto", margin: "auto", marginTop: "5px" }}><span style={{
        textAlign: "left",
        width: "100%",
        fontWeight: "bold",
        color: "#ddd",
        fontSize: "13px",
        margin: "auto",
        marginLeft: "25px"
      }} >{props.what} <Hint desc={props.desc} /> </span><span style={{

        width: "100%",
        fontSize: "15px",
        color: "#ddd",
      }}>
          {props.result}
        </span></p>
    );
  }
  const aaaaa = (an, bn, ac, bc, ia, ib) => {
    setTokenAname(an)
    setTokenACT(ac);
    setTokenBname(bn)
    setTokenBCT(bc);
    setiTA(ia)
    setiTB(ib)
    connectToSelectedNetwork()
  }
  const search = async (e) => {
    if (e.length <= 0) {
      setlistsearch(null)
    } else {
      if (e.length == 42) {
        if (e.includes("0x")) {
          const bota = await props.Tokencontract(tokenabi, ethers.utils.getAddress(e));
          const name = await bota.methods.name().call()
          const symbol = await bota.methods.symbol().call()
          setlistsearch({
            name: name,
            symbol: symbol
          })
          setctcatch(ethers.utils.getAddress(e))
        }
      } else {
        setlistsearch(null)
      }
    }
  }

  function Hint(props) {
    return (
      <>
        {props.desc != "" && <button style={{ border: "none", background: "transparent", position: "relative" }} aria-label={props.desc}
          data-balloon-pos='up'
          data-balloon-length='large'>
          <svg
            style={{ margin: "auto ", height: "25px", width: "25px", borderRadius: "5px", padding: "5px" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#fff" className="bi bi-question-circle" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" />
          </svg>
        </button>}
      </>
    )
  }
  function Token_lis(props) {
    return <li onClick={(e) => {
      if(!isa){
        setHint(false)
      }else{
        choicecoin(props.brands, props.ct)
        setHint(false)
        setAmountinSwap(0)
        setAmountoutSwap(0)
        setiTA(isa ? props.icon : iTA)
        setiTB(!isa ? props.icon : iTB)
      }
      // setiTA("https://r.poocoin.app/smartchain/assets/" + ethers.utils.getAddress(tokenACT) + "/logo.png")
      //setiTB("https://r.poocoin.app/smartchain/assets/" + ethers.utils.getAddress(tokenBCT) + "/logo.png")

    }} style={{ cursor: "pointer", height: '40px', width: "340px", display: "grid", gridTemplateColumns: "50px auto ", margin: "auto", marginTop: "10px" }}>
      <img style={{ height: '30px', width: "30px", margin: "auto" }} src={props.icon}></img>
      <h1 style={{ width: "100%", height: "min-content", margin: "auto", textAlign: "left", fontSize: "14px" }} >{props.brand}</h1>
    </li>;
  }

  function Tokens_Find(props) {
    return <li onClick={(e) => {
      if(!isa){
        setHint(false)
      }else{
        choicecoin(props.brands, props.ct)
        setHint(false)
        setAmountinSwap(0)
        setAmountoutSwap(0)
        setiTA(isa ? props.icon : iTA)
        setiTB(!isa ? props.icon : iTB)
      }
    
      /* var obj = {
         "nome": props.brands,
         "bname": props.brand,
         "icon": props.icon,
         "contract": props.ct,
       };*/

    }} style={{ cursor: "pointer", height: '40px', width: "100%", display: "grid", gridTemplateColumns: "50px auto ", margin: "auto", borderBottom: "1px solid #ffffff20", marginTop: "10px" }}>
      <img style={{ height: '30px', width: "30px", margin: "auto" }} src={props.icon}></img>
      <h1 style={{ width: "100%", height: "min-content", margin: "auto", textAlign: "left", fontSize: "14px" }}>{props.brand}</h1>
    </li>;
  }


  let ulconfig = listsearch != null
    ? <ul id='list' style={{ overflow: "auto", height: "80%" }}>
      {<Tokens_Find brand={listsearch.name} brands={listsearch.symbol} icon={getIcon(ctfind)} ct={ctfind} />}
      {tokens_default[GLOBAL['netid']].map((Token_li) => <Token_lis brand={Token_li.nome} icon={Token_li.icon} ct={Token_li.contract} />)}
    </ul>
    : <ul id='list' style={{ overflow: "auto", height: "80%" }}>
      {tokens_default[GLOBAL['netid']].map((Token_li) => <Token_lis brand={Token_li.nome} icon={Token_li.icon} ct={Token_li.contract} />)}
    </ul>
  let modaltokensls =
    <div style={{ height: "100%", width: "100%", position: "fixed", display: "grid", background: "#00000050", top: "0", left: "0", zIndex: "4000" }}>
      <div style={{ height: '480px', width: "360px", background: "#222", borderRadius: "10px", zIndex: "10000", margin: "auto" }}>
        <div style={{ height: '40px', width: "340px", display: "grid", gridTemplateColumns: "auto 50px", margin: "auto" }}>
          <h1 style={{ width: "100%", height: "min-content", margin: "auto", textAlign: "left", fontSize: "14px" }}>{isa ? "FROM" : "TO"}</h1>
          <svg onClick={() => { setHint(!hubinteract) }} xmlns="http://www.w3.org/2000/svg" style={{ cursor: "pointer", margin: "auto" }} width="25" height="25" fill="#fff" className="bi bi-x" viewBox="0 0 16 16">
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
          </svg>
        </div>
        {<input style={{ height: '40px', width: "340px", display: "grid", gridTemplateColumns: "auto 50px", margin: "auto", background: "#00000000", color: "#ddd", border: "1px solid #dddddd20", borderRadius: "10px", paddingLeft: "10px" }} placeholder="Enter token Address ex: 0x..." onChange={(e) => { search(e.target.value) }} />}
        {ulconfig}
      </div>
    </div>


  /*  
  <Chart obj={
              {
                Contract: tokenBCT,
                Name: tokenBname,
                Symbol: tokenBname,
                Decimals: decimalsTB,
                balance: balancetokenB()
              }
            } setpriceAction={//console.log} /> */

  let toModalSettings = {
    Hint,
    setGWEI,
    Gwei,
    setSlip,
    Set,
    setDead,
    dead
  }
  
  return (

    <ModalSwap data={{
      hubinteract,
      modaltokensls,
      settings,
      ModalSettings,
      setSettings,
      SAFE,
      tokenAname,
      tokenBname,
      balancetokenA,
      balancetokenB,
      iTA,
      iTB,
      props,
      getAmountIn,
      getAmountOut,
      amountinswap,
      amountoutswap,
      aaaaa,
      AllowA,
      ButtonI,
      swap,
      bnbtext,
      price,
      Desc,
      approve,
      tokenBCT,
      tokenACT,
      splippage,
      toModalSettings,

    }} />

  );
}
export default MiniSwap;