import React, { useState, useEffect, useContext } from "react";
import { ethers } from 'ethers';
import abiToken from "../../abis/bnbabi.json";
import stakesabi from "../../abis/nftpoolabi.json";
import stkfacabi from "../../abis/stakefactory.json";
import Modal from '../stake/components/modal';
import ModalH from '../stake/components/modalH';
import AppContext from "../../framework/helpers/AppContext";
import ProjectData from '../../config';
import Button from "../../components/Launchpad/Button";
let projectName = "Martik"
const {
    Name,
    Logo,
    backgroundColor,
    buttonsColor,
    buttonsBorders,
    inputsColor,
    inputsBorders,
    outbuttonsColor,
    Borders,
    outFont,
    innerFont,
    Routers,
    screens
} = ProjectData[window.location.host.split('.')[0] != undefined
    ? window.location.host.split('.')[0] == "app"
        ? window.location.host.split('.')[0] + "." + window.location.host.split('.')[1]
        : window.location.host.split('.')[0] : "Martik"]
const Web3 = require('web3-eth');
const Minimized = (props) => {

    const {
        account,
        provider,
        chain,
        chains,
        web3,
        Tokencontract,
        gasTX,
        getID,
        callTX,
        sendTX,
        nSendTX,
        nSendCT
    } = useContext(AppContext)

    const [address, setAddress] = useState(account);

    const [list, setList] = useState([]);
    const [oflist, setoflist] = useState([]);
    const [disable_list, setDisable_list] = useState([{}]);
    const [list_mode, setListMode] = useState(0);
    const [active_mode, setactiveMode] = useState(0);
    const [only_staked_mode, setOnly_Staked_Mode] = useState(false);
    const [filter, setFilter] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const [poofee, setpoofee] = useState(0);

    const [filterlay, setFilterLay] = useState('none');
    useEffect(() => {
        loadlist()
    }, []);
    useEffect(() => {
        loadlist()
    }, [props.data]);
    useEffect(() => {
        loadlist()
    }, [props.active_mode]);
    function nextpercent(accountBalancemctTB, d) {
        return parseFloat((accountBalancemctTB) + "").toFixed(2)
    }
    function nextblock(accountBalancemctTB, d) {
        if (accountBalancemctTB != undefined && accountBalancemctTB != 0) {
            if (accountBalancemctTB.toString().length >= d) {
                return parseFloat(ethers.utils.formatUnits(accountBalancemctTB, d)).toFixed(5)
            } else {
                let cutin = (d - accountBalancemctTB.toString().length) + 2
                return parseFloat(ethers.utils.formatUnits(accountBalancemctTB, d)).toFixed(cutin)
            }
        } else {
            return 0.00
        }
    }
    async function getmeta(uri) {
        uri = uri.replace("https://hvgatohost.000webhostapp.com", "https://martikstorage.onrender.com")
        try {
            const json = await fetch(uri, {
                method: "GET"
            }).then(e => e.json())
            return json
        } catch (error) {

        }

    }
    const excludes = screens["nftpools"].excludes
    async function loadlist() {
        try {

            const toofline = {
                "0x9321eeE089364182C2A148FEb493CA0931d4Dab4": true,
                '0x4A51BA1Bb9526aa31b967b06Ec2F3557C3e1d3Cc': true,
                "0x650e5B875035420C872b90cCE0Ee777De30D8C47": true,
                "0xFC765F0C445b8a6436db9D3D9750C3799Bf58Fd0": true,
                "0x025dC95E02e33c711B509485BA9233fb228036c4": true,
                "0x7E6fDfF5487b0e9b97F3B618D3f76410779aA04f": true,
                "0xEe664C9F3Ed8b4479eE4D98f6919bBc50a285082": true,
                "0xC41D0610763dc677F704d6A14009Ca3954E4eA26": true,
                "0xB4C3f82754c97FC43c2f01528BA26E131bd63ec8": true,
                "0x590B534523dDDa612c58Bd05cdEb05bC1DE013ca": true,
                "0x8dEc373bbcA468168c3E0bd2BEb5Bcd66731DDd8": true,
                "0x436080202a6cDAdF2cbf83D6D5dFD33860ecC7cB": true,
                "0x65D95b37Dd46D3Ab60715637aE91E5801AcABf39": true,
                "0xC1122978D8cc23F06B26ade13fAdc10bB16aa0E4": true,
                "0xcc730BB1306e17A6DB7C16a44180F5C1C4B90Cd7": true,
                "0x98DF35D07f55A1e8885c6D23641546E73009001C": true,
                "0x701f1254c5cCb95a09Ed82C6b23e19782fAa47A4": true,
                "0x46b821064370bDaD28eaA2ECdf2629384CECF820": true,
                "0xD15618c89e7B64C2f6dE564B66dd128403C0428B": true,

                "0x27A634CaF303ec10D5558e585A3dD6354d8ea389": true,
                "0xAef0332741872259EC50A3cbA1DE82d5790D0bC0": true,
                "0x583D5Ece5e83816689d2FbD0543f6B962474C1fd": true,
                "0x41897De6fCa8B42C7233eB42f27756CE0E85cC9e": true,
                "0xB7d3D8f6D8cCaF58744dF547AAdFFe0CFC48f729": true,
                "0xcfCf1E93c2E38d2F3328cBDA6b4f22410749aF01": true,
            }


            const stakefactory = await Tokencontract(stkfacabi, screens["nftpools"].factory)
            const poofee = await stakefactory.methods.pfee().call()
            setpoofee(poofee)
            let _list = await stakefactory.methods.poolsview().call()
            if (screens["nftpools"].factoryv2 != undefined) {
                const stakefactoryv2 = await Tokencontract(stkfacabi, screens["nftpools"].factoryv2)
                const l =     await stakefactoryv2.methods.poolsview().call()
                _list = [
                    ..._list,
                ...l
                ]
            }
           /// console.log(_list)
            // console.log(poofee)
            /*  if (screens["nftpools"].factory == "0x7FD6bb4b075fd4932655F417bFaaf4528D2dbcfb") {
                  _list.push("0x9321eeE089364182C2A148FEb493CA0931d4Dab4")
              }*/

            const _t_list = []


            for (let index = 0; index < _list.length; index++) {
                const stake = await Tokencontract(stakesabi, _list[index])

                const finishAt = await stake['methods']['finishAt']().call()


                const token0 = await stake['methods']['BEP20Mtoken']().call()
                const token1 = await stake['methods']['BEP20Rtoken']().call()

                const i0 = await stake['methods']['_icon']().call()
                const i1 = await stake['methods']['_iconb']().call()
                const tokenA = await Tokencontract(abiToken, token0);
                const tokenB = await Tokencontract(abiToken, token1);
                const isForOnline = toofline[_list[index]] ? false : parseInt(finishAt) >= parseInt(Date.now() / 1000)
                const meta = await getmeta(i0);

                const user = address != undefined ? await stake.methods.viewdata(address).call() : []

                try {
                    const obj = {
                        title: '',
                        cts: _list[index],
                        apr: await stake.methods._FLEXAPR().call(),
                        ts: 0,
                        ia: meta.image,
                        i1: i1,
                        act: token0,
                        bct: token1,
                        taName: meta.name,
                        tbName: await tokenB['methods']['symbol']().call(),
                        dec: await tokenB['methods']['decimals']().call(),
                        min: 0,
                        viewdata: user,
                        so: false,
                        desc: "",
                        apydesc: "",
                        poofee: poofee,
                        official: 1,
                        online: true
                    }

                    // console.log(obj)
                    if (isForOnline) {
                        _t_list.push(obj)
                    } else {
                        // of_t_list.push(obj)
                    }


                } catch (error) {
                    //  console.log(error)

                }
            }

            props.setModal(_t_list[0])
            //   localStorage.setItem("mtk-stkcache", JSON.stringify(_t_list))
            setList(_t_list)

            //console.log(_t_list)
        } catch (error) {
            console.log(error)
        }

    }
    const SAFE = (props) => {
        return (
            <p style={{ width: "95%", display: "grid", gridTemplateColumns: "auto auto", margin: "auto", marginTop: "5px" }}><span style={{
                textAlign: "left",
                width: "100%",
                color: outFont,
                fontSize: "15px",
            }}>{props.what} </span><span style={{
                textAlign: "right",
                width: "100%",
                fontSize: "16px",
                color: outFont,
            }}>
                    {props.result}
                </span></p>
        );
    }
    const buypool = [
        {
            "inputs": [
                {
                    "internalType": "uint256",
                    "name": "_amount",
                    "type": "uint256"
                }
            ],
            "name": "_stake",
            "outputs": [],
            "stateMutability": "payable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "_contract",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "id",
                    "type": "uint256"
                }
            ],
            "name": "Harvest",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "contract IBEP20",
                    "name": "MAINTOKEN",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "_price",
                    "type": "uint256"
                },
                {
                    "internalType": "contract StakeFactory",
                    "name": "fac",
                    "type": "address"
                }
            ],
            "name": "init",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "uint256",
                    "name": "__price",
                    "type": "uint256"
                },
                {
                    "internalType": "uint256",
                    "name": "_nftPoolLimit",
                    "type": "uint256"
                },
                {
                    "internalType": "uint256",
                    "name": "timeinhours",
                    "type": "uint256"
                }
            ],
            "name": "setPoolRules",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [],
            "stateMutability": "nonpayable",
            "type": "constructor"
        },
        {
            "inputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "name": "_icon",
            "outputs": [
                {
                    "internalType": "string",
                    "name": "",
                    "type": "string"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "_iconb",
            "outputs": [
                {
                    "internalType": "string",
                    "name": "",
                    "type": "string"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "_name",
            "outputs": [
                {
                    "internalType": "string",
                    "name": "",
                    "type": "string"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "_symbol",
            "outputs": [
                {
                    "internalType": "string",
                    "name": "",
                    "type": "string"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "_totalstakedIR",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                }
            ],
            "name": "Auth",
            "outputs": [
                {
                    "internalType": "bool",
                    "name": "",
                    "type": "bool"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "BEP20Mtoken",
            "outputs": [
                {
                    "internalType": "contract IBEP20",
                    "name": "",
                    "type": "address"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "BEP20Rtoken",
            "outputs": [
                {
                    "internalType": "contract IBEP20",
                    "name": "",
                    "type": "address"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "buys",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                }
            ],
            "name": "claimable",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "duration",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "factory",
            "outputs": [
                {
                    "internalType": "contract StakeFactory",
                    "name": "",
                    "type": "address"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "finishAt",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "FIXAPR",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "who",
                    "type": "address"
                }
            ],
            "name": "getUserDetails",
            "outputs": [
                {
                    "components": [
                        {
                            "internalType": "uint256",
                            "name": "id",
                            "type": "uint256"
                        },
                        {
                            "internalType": "string",
                            "name": "uri",
                            "type": "string"
                        },
                        {
                            "internalType": "uint256",
                            "name": "claimable",
                            "type": "uint256"
                        },
                        {
                            "internalType": "address",
                            "name": "pool",
                            "type": "address"
                        }
                    ],
                    "internalType": "struct StakePool.data[]",
                    "name": "",
                    "type": "tuple[]"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                }
            ],
            "name": "latestHavest",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "minStakeTime",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "nft",
            "outputs": [
                {
                    "internalType": "contract NFT",
                    "name": "",
                    "type": "address"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "nftTotalLimit",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "owner",
            "outputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "price",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "priceIR",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "rewardPerTokenStored",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "rewardRate",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "router",
            "outputs": [
                {
                    "internalType": "contract IDEXRouter",
                    "name": "",
                    "type": "address"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                }
            ],
            "name": "since",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "updatedAt",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "user",
                    "type": "address"
                }
            ],
            "name": "userInfo",
            "outputs": [
                {
                    "components": [
                        {
                            "internalType": "uint256",
                            "name": "claimable",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "since",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "latestHavest",
                            "type": "uint256"
                        }
                    ],
                    "internalType": "struct BuyPool.userInfoS",
                    "name": "",
                    "type": "tuple"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                }
            ],
            "name": "userRewardPerTokenPaid",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "wallet",
            "outputs": [
                {
                    "internalType": "contract wall",
                    "name": "",
                    "type": "address"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "WBNB",
            "outputs": [
                {
                    "internalType": "contract IBEP20",
                    "name": "",
                    "type": "address"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        }
    ]
    async function hstake(addr, id) {
        await props.nSendTX(screens["nftpools"].buypool, buypool, props.netId, 'Harvest', props.poofee, 0, addr, id)
        // data()
    }
    let stakeHorizontal = <div style={{ width: "100%", margin: "auto", display: "grid" }}>
        <div style={{
            height: "min-content",
            width: "100%",
            display: "grid",
            background: "transparent",
            gap: "10px",
            borderRadius: "10px",
            marginTop: "auto",
            color: outFont,
            gridTemplateColumns: "repeat(auto-fill, minmax(280px, 1fr)",
        }}>

            {
                list.map((e, index) =>
                    <>
                        {
                            e.viewdata.map((a, index) =>
                                <div style={{ border: "1px solid" + Borders, height: "min-content", width: "280px", margin: "auto", marginTop: "0px", padding: "15px 5px", cursor: "pointer", borderRadius: "10px" }}>
                                    <div onClick={() => {
                                        props.setModal(undefined)
                                        setTimeout(() => {
                                            props.setModal(e)
                                        }, 1000);

                                    }} style={{ cursor: "pointer", display: "grid", gridTemplateColumns: " auto 40px", borderRadius: "5px", padding: "5px 0px" }}>
                                        <div style={{ display: "grid", gridTemplateColumns: "100px auto ", width: "100%", height: "70px", marginBottom: "20px", padding: "auto" }}>

                                            <div style={{
                                                height: "min-content",
                                                width: "100%",
                                                margin: "auto 0",
                                                display: "grid",
                                                gridTemplateColumns: "200px",

                                            }}>
                                                <p style={{ textAlign: "left", height: "min-content", fontWeight: "bold", fontSize: "15px", margin: "auto 0", color: outFont }}>
                                                    Owner of {e.taName} id {a.id}
                                                </p>

                                                <p />
                                                <p style={{ textAlign: "left", height: "min-content", fontSize: "12px", marginBottom: "0px", color: outFont }}>
                                                    to Earn {e.tbName}
                                                </p>
                                            </div>

                                        </div>
                                        <div style={{ display: "grid", gridTemplateColumns: "100px auto ", width: "100%", height: "70px", marginBottom: "20px", padding: "auto" }}>

                                            <div style={{
                                                height: "min-content",
                                                width: "100%",
                                                margin: "auto 0",
                                                display: "grid",
                                                gridTemplateColumns: "200px",

                                            }}>
                                                <p style={{ textAlign: "left", height: "min-content", fontWeight: "bold", fontSize: "15px", margin: "auto 0", color: outFont }}>
                                                    ID
                                                </p>

                                                <p />
                                                <p style={{ textAlign: "left", height: "min-content", fontSize: "12px", marginBottom: "0px", color: outFont }}>
                                                    {a.id}
                                                </p>
                                            </div>

                                        </div>

                                    </div>
                                    <img src={e.ia} height="100%" width="100%" style={{ borderRadius: "10px", }}>

                                    </img>

                                    <br />
                                    {true &&
                                        <div style={{ display: "grid", gridTemplateColumns: "auto", height: "min-content", width: "95%", margin: "auto", marginTop: "5px" }}>
                                            {<p style={{
                                                display: "grid", margin: "auto", textAlign: "left",
                                                width: "100%",
                                                fontSize: "12px",

                                                paddingTop: "20px"
                                            }}>
                                                <span style={{ fontSize: "14px", fontWeight: "bold", }}>
                                                    <SAFE what={'Earned'} result={a.claimable > 0 ? nextblock(a.claimable, e.dec) + " " + e.tbName : '-'} />
                                                </span>
                                            </p>}
                                            {<div style={{ display: "grid", height: "50px", width: "100%", gap: "5px", margin: "auto", fontSize: "20px", marginRight: "0", marginLeft: "0" }}>
                                                <Button func={() => { hstake(a.pool, a.id) }} text={"Harvest"} />
                                            </div>}
                                        </div>}
                                </div>
                            )
                        }


                    </>
                )
            }

        </div>

        <p style={{ height: "10vh" }}></p>
    </div>

    return (
        <>
            {stakeHorizontal}
        </>
    );
}

export default Minimized;