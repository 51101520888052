import React, { useState, useEffect, useContext } from "react";
import abiToken from "../../abis/bnbabi.json";
import stakesabi from "../../abis/stakesn.json";
import stkfacabi from "../../abis/stakefactory.json";
import Modal from './components/modalEmbed';

import Modalwallet from "../../components/wallet/modalwallet";
import WalletConnectProvider from '@walletconnect/web3-provider';
import { ethers } from 'ethers';
const Web3 = require('web3-eth');
const wprovider = new WalletConnectProvider({
    rpc: {
        56: 'https://bsc-dataseed.binance.org/',
    },
    bridge: 'https://bridge.walletconnect.org',
});
const Stake = (props) => {

    const [hubtx, sethubtx] = useState(false);
    const [accounts, setAccounts] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const [provider, setWCrovider] = useState(wprovider);
    const [errornet, seterrornet] = useState(false);
    const [texterror, seterrormenssage] = useState(<h1 className='errormsg'></h1>);

    function acc() {
        if (accounts != 0) {
            return accounts[0];
        } else {
            return undefined;
        }
    }
    function prove() {
        let prov;
        let wpp = localStorage.getItem('wprovider');
        if (wpp != null) {
            let wp = wpp.split(',');
            if (wp[0] === 'wc') {
                prov = provider;
            } else {
                prov = window.ethereum;
            }
        } else {
            prov = "https://bsc-dataseed.binance.org";

        }
        return prov;
    }
    useEffect(() => {
        let wpp = localStorage.getItem('wprovider');
        if (wpp != null) {
            let wp = wpp.split(',');
            if (wp != null) {
                if (wp[0] === 'wc') {
                    if (wp[1] !== 0) {
                        connecttowalletconnect();
                    }
                } else {
                    if (wp[1] !== 0) {
                        connecttometamask();
                    }
                }
            }
        } else {

        }
    }, []);
    useEffect(() => {
        if (loaded && accounts !== 0) {
            let options = {
                filter: {
                    address: [accounts[0]],
                },
            };
        } else {
            setTimeout(setLoaded(true), 500);
        }

        let wpp = localStorage.getItem('wprovider');
        if (wpp != null) {
            let wp = wpp.split(',');
            if (wp[0] === 'wc') {
                provider.on('disconnect', (code, reason) => {
                    localStorage.removeItem('wprovider');
                    window.location.reload();
                });
            } else {
                if (window.ethereum != null || window.ethereum != undefined)
                    window.ethereum.on('accountsChanged', (accounts) => {
                        if (accounts.length > 0) {
                            setAccounts(accounts)
                        } else {
                            Disconnect();
                        }
                    });
            }
        }
    }, [loaded, accounts]);

    function connectMetaMask() {
        window.web3
            .requestAccounts()
            .then((result) => {
                setAccounts(result);

                const a = result;
                if (loaded && accounts !== 0) {
                    let accounts = localStorage.getItem('wprovider');
                    if (accounts !== null) {
                        let account = accounts.split(',');
                        if (account[1] === a[0]) {
                        } else {
                            window.location.reload();
                        }
                    }
                }
                localStorage.setItem('wprovider', ['mt', a[0]]);
            })
            .catch((error) => {

            });
    }


    function Disconnect() {
        if (localStorage.getItem('wprovider') != null) {
            let wpp = localStorage.getItem('wprovider');
            if (wpp != null) {
                let wp = wpp.split(',');
                if (wp[0] === 'wc') {
                    provider.disconnect()
                } else {

                }
            }
            localStorage.removeItem('wprovider');
            window.location.reload();
        } else {
        }
    }

    function connecttometamask() {
        window.web3 = new Web3(window.ethereum);
        connectMetaMask();
    }
    async function connecttowalletconnect() {
        if (typeof web3 !== 'undefined') {
            //infuraId: "27e484dcd9e3efcfd25a83a78777cdf1",
            provider.on('chainChanged', (chainId) => {
                if (chainId !== 56) {
                    window.location.reload();
                } else {
                    setLoaded(true);
                }
            });
            await provider
                .enable()
                .then(() => {
                    const web3 = new Web3(provider);
                    window.web3 = web3;
                    setAccounts([provider.accounts[0]]);
                    const a = provider.accounts[0];
                    if (loaded && accounts !== 0) {
                        let accounts = localStorage.getItem('wprovider');
                        if (accounts != null) {
                            let account = accounts.split(',');
                            if (account[1] === a) {
                            } else {
                                window.location.reload();
                            }
                        }
                    }
                    localStorage.setItem('wprovider', ['wc', a]);
                })
                .catch((error) => {

                    setWCrovider(
                        new WalletConnectProvider({
                            rpc: {
                                56: 'https://bsc-dataseed.binance.org/',
                            },
                            bridge: 'https://bridge.walletconnect.org',
                        })
                    );
                });
        } else {
            provider.on('chainChanged', (chainId) => {
                if (chainId !== 56) {
                    window.location.reload();
                } else {
                    setLoaded(true);
                }
            });
            await provider
                .enable()
                .then(() => {
                    const web3 = new Web3(provider);
                    window.web3 = web3;
                    setAccounts([provider.accounts[0]]);
                    const a = provider.accounts[0];
                    if (loaded && accounts !== 0) {
                        let accounts = localStorage.getItem('wprovider');
                        if (accounts != null) {
                            let account = accounts.split(',');
                            if (account[1] === a) {
                            } else {
                                window.location.reload();
                            }
                        }
                    }
                    localStorage.setItem('wprovider', ['wc', a]);
                })
                .catch((err) => {
                    //console.log('Error when connecting via walletConnect');
                    //console.log(err);
                    setWCrovider(
                        new WalletConnectProvider({
                            rpc: {
                                56: 'https://bsc-dataseed.binance.org/',
                            },
                            bridge: 'https://bridge.walletconnect.org',
                        })
                    );
                });
        }
    }
    const buttoconect = () => {
        let text = null;

        if (loaded && (accounts !== 0)) {
            // get user info
            text = accounts[0];
            text = text.slice(0, 4) + '...' + text.slice(-4);
        } else {
            text = "Connect";
        }
        return text;
    }


    const buttoncolor = () => {
        let buttonwallet = null
        if (errornet) {
            // get user info
            buttonwallet = <div id="wallet" className="Wallet" onClick={() => {
                sethubtx(true);
            }}>
                <svg style={{ height: "30px", width: "30px", margin: "auto" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-wallet" viewBox="0 0 16 16">
                    <path d="M0 3a2 2 0 0 1 2-2h13.5a.5.5 0 0 1 0 1H15v2a1 1 0 0 1 1 1v8.5a1.5 1.5 0 0 1-1.5 1.5h-12A2.5 2.5 0 0 1 0 12.5V3zm1 1.732V12.5A1.5 1.5 0 0 0 2.5 14h12a.5.5 0 0 0 .5-.5V5H2a1.99 1.99 0 0 1-1-.268zM1 3a1 1 0 0 0 1 1h12V2H2a1 1 0 0 0-1 1z" />
                </svg>
                <h1>{texterror}</h1>
            </div>
        } else {
            buttonwallet = <div id="wallet" className="Wallet" onClick={() => {
                sethubtx(true);
            }}>
                <svg style={{ height: "30px", width: "30px", margin: "auto" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-wallet" viewBox="0 0 16 16">
                    <path d="M0 3a2 2 0 0 1 2-2h13.5a.5.5 0 0 1 0 1H15v2a1 1 0 0 1 1 1v8.5a1.5 1.5 0 0 1-1.5 1.5h-12A2.5 2.5 0 0 1 0 12.5V3zm1 1.732V12.5A1.5 1.5 0 0 0 2.5 14h12a.5.5 0 0 0 .5-.5V5H2a1.99 1.99 0 0 1-1-.268zM1 3a1 1 0 0 0 1 1h12V2H2a1 1 0 0 0-1 1z" />
                </svg>
                <h1 >{buttoconect()}</h1>
            </div>
        }
        return buttonwallet;
    }

    let modalres = <Modalwallet updateModals={(e) => { sethubtx(!hubtx) }} Disconnect={Disconnect} connecttometamask={connecttometamask} sethubtx={sethubtx} connecttowalletconnect={connecttowalletconnect} />


    const LogoB = () => {
        return (
            <div style={{
                height: "50px",
                width: "100%",
                overflow: "hidden",
                display: "grid",
                gridTemplateColumns: "50% 50%",
                cursor: "pointer",
                margin: "auto"
            }}>

                <svg onClick={() => { window.location.href = "/" }} style={{
                    height: "50px",
                    width: "50px",
                    margin: "auto",
                    borderRadius: "90px"
                }} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                    width="100%" viewBox="0 0 512 512" enableBackground="new 0 0 512 512" >
                    <path fill="#171B1E" opacity="1.000000" stroke="none"
                        d="
    M1.000002,278.000000 
        C1.000000,263.312439 1.000000,248.624878 1.387354,233.375961 
        C2.500387,228.851822 3.429312,224.913971 3.918932,220.922226 
        C7.224019,193.976761 15.036845,168.316879 27.083546,144.102798 
        C41.233334,115.661491 59.781677,90.453018 83.404778,68.868477 
        C105.372139,48.796783 129.903915,33.080418 157.146301,21.424257 
        C180.479950,11.440520 204.805969,5.295873 230.089050,2.918592 
        C231.110458,2.822552 232.031952,1.663684 233.000000,1.000003 
        C248.020889,1.000000 263.041779,1.000000 278.623993,1.387359 
        C283.152374,2.500634 287.094696,3.428169 291.090607,3.919375 
        C314.993469,6.857679 338.014252,13.178589 359.902191,23.068634 
        C392.347778,37.729149 421.027008,57.713017 445.013702,84.358612 
        C462.501770,103.785248 476.804657,125.103470 487.985809,148.728119 
        C501.012421,176.251846 508.763367,205.219070 510.645660,235.395340 
        C511.767059,253.373199 510.661194,271.644226 508.869507,289.615265 
        C505.785614,320.547607 495.697998,349.484406 480.673889,376.703156 
        C467.169525,401.168640 450.537689,423.087677 429.749023,441.944366 
        C417.136475,453.384766 403.734680,463.565277 389.262299,472.472961 
        C354.492615,493.873627 316.572266,505.736816 276.059723,508.756256 
        C261.040222,509.875702 245.791687,508.864258 230.704956,507.914032 
        C209.398926,506.572174 188.806152,501.512268 168.776199,494.095398 
        C129.914383,479.705231 96.332054,457.599518 68.277985,426.922577 
        C47.622231,404.335602 31.411585,379.079254 19.935560,350.775787 
        C10.904083,328.501373 4.940612,305.440308 2.923140,281.417023 
        C2.823668,280.232544 1.665301,279.136963 1.000002,278.000000 
    M97.000000,313.500000 
        C97.000000,344.257080 97.000000,375.014160 97.000000,405.700409 
        C127.318954,405.700409 157.051147,405.700409 186.707062,405.700409 
        C186.707062,310.677582 186.707062,215.954620 186.707062,121.299629 
        C156.680969,121.299629 126.948761,121.299629 97.000000,121.299629 
        C97.000000,185.232666 97.000000,248.866333 97.000000,313.500000 
    M327.000000,252.500000 
        C327.000000,303.253845 327.000000,354.007721 327.000000,404.704437 
        C356.977478,404.704437 386.376312,404.704437 415.711182,404.704437 
        C415.711182,310.019043 415.711182,215.629501 415.711182,121.295517 
        C386.022583,121.295517 356.623749,121.295517 327.000000,121.295517 
        C327.000000,164.893768 327.000000,208.196884 327.000000,252.500000 
    M215.000000,268.500000 
        C215.000000,287.924225 215.000000,307.348480 215.000000,326.704315 
        C243.315964,326.704315 271.045776,326.704315 298.707489,326.704315 
        C298.707489,280.682617 298.707489,234.957184 298.707489,189.295624 
        C270.684143,189.295624 242.954330,189.295624 215.000000,189.295624 
        C215.000000,215.560394 215.000000,241.530197 215.000000,268.500000 
    z"/>
                    <path fill="#000000" opacity="1.000000" stroke="none"
                        d="
    M1.000002,278.468658 
        C1.665301,279.136963 2.823668,280.232544 2.923140,281.417023 
        C4.940612,305.440308 10.904083,328.501373 19.935560,350.775787 
        C31.411585,379.079254 47.622231,404.335602 68.277985,426.922577 
        C96.332054,457.599518 129.914383,479.705231 168.776199,494.095398 
        C188.806152,501.512268 209.398926,506.572174 230.704956,507.914032 
        C245.791687,508.864258 261.040222,509.875702 276.059723,508.756256 
        C316.572266,505.736816 354.492615,493.873627 389.262299,472.472961 
        C403.734680,463.565277 417.136475,453.384766 429.749023,441.944366 
        C450.537689,423.087677 467.169525,401.168640 480.673889,376.703156 
        C495.697998,349.484406 505.785614,320.547607 508.869507,289.615265 
        C510.661194,271.644226 511.767059,253.373199 510.645660,235.395340 
        C508.763367,205.219070 501.012421,176.251846 487.985809,148.728119 
        C476.804657,125.103470 462.501770,103.785248 445.013702,84.358612 
        C421.027008,57.713017 392.347778,37.729149 359.902191,23.068634 
        C338.014252,13.178589 314.993469,6.857679 291.090607,3.919375 
        C287.094696,3.428169 283.152374,2.500634 279.092651,1.387359 
        C356.977142,1.000000 434.954315,1.000000 512.965698,1.000000 
        C512.965698,171.593811 512.965698,342.187622 512.965698,512.890747 
        C342.456238,512.890747 171.912384,512.890747 1.000000,512.890747 
        C1.000000,434.979492 1.000000,356.958405 1.000002,278.468658 
    z"/>
                    <path fill="#000000" opacity="1.000000" stroke="none"
                        d="
    M232.531342,1.000003 
        C232.031952,1.663684 231.110458,2.822552 230.089050,2.918592 
        C204.805969,5.295873 180.479950,11.440520 157.146301,21.424257 
        C129.903915,33.080418 105.372139,48.796783 83.404778,68.868477 
        C59.781677,90.453018 41.233334,115.661491 27.083546,144.102798 
        C15.036845,168.316879 7.224019,193.976761 3.918932,220.922226 
        C3.429312,224.913971 2.500387,228.851822 1.387354,232.907303 
        C1.000000,155.739624 1.000000,78.479240 1.000000,1.000000 
        C78.020760,1.000000 155.041718,1.000000 232.531342,1.000003 
    z"/>
                    <path fill="#FFFFFF" opacity="1.000000" stroke="none"
                        d="
    M97.000000,313.000000 
        C97.000000,248.866333 97.000000,185.232666 97.000000,121.299629 
        C126.948761,121.299629 156.680969,121.299629 186.707062,121.299629 
        C186.707062,215.954620 186.707062,310.677582 186.707062,405.700409 
        C157.051147,405.700409 127.318954,405.700409 97.000000,405.700409 
        C97.000000,375.014160 97.000000,344.257080 97.000000,313.000000 
    z"/>
                    <path fill="#FFFFFF" opacity="1.000000" stroke="none"
                        d="
    M327.000000,252.000000 
        C327.000000,208.196884 327.000000,164.893768 327.000000,121.295517 
        C356.623749,121.295517 386.022583,121.295517 415.711182,121.295517 
        C415.711182,215.629501 415.711182,310.019043 415.711182,404.704437 
        C386.376312,404.704437 356.977478,404.704437 327.000000,404.704437 
        C327.000000,354.007721 327.000000,303.253845 327.000000,252.000000 
    z"/>
                    <path fill="#FFFFFF" opacity="1.000000" stroke="none"
                        d="
    M215.000000,268.000000 
        C215.000000,241.530197 215.000000,215.560394 215.000000,189.295624 
        C242.954330,189.295624 270.684143,189.295624 298.707489,189.295624 
        C298.707489,234.957184 298.707489,280.682617 298.707489,326.704315 
        C271.045776,326.704315 243.315964,326.704315 215.000000,326.704315 
        C215.000000,307.348480 215.000000,287.924225 215.000000,268.000000 
    z"/>
                </svg>
            </div>
        );
    }
    function menustyle(bool) {
        return <div style={{
            height: "70px",
            width: "100%",
            margin: "auto",
            overflow: "hidden",
            display: "grid",
            gridTemplateColumns: "150px auto 150px",

        }}>
            <LogoB />
            <div></div>
            {buttoncolor()}
        </div>
    }
    const [list, setList] = useState([{}]);
    const [poofee, setpoofee] = useState(0);
    useEffect(() => {
        loadlist()
    }, []);
    async function Tokencontract(abi, address) {
        const web3 = new Web3(prove());
        return new web3.Contract(abi, address);
    }
    function getQuery(query) {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const foo = params.get(query);
        return foo
    }

    const colorData = {
        background: getQuery('bg') == null ? '#000000' : '#' + getQuery('bg'),
        fontColor: getQuery('fontcolor') == null ? '#ffffff' : '#' + getQuery('fontcolor'),
        iColor: getQuery('icolor') == null ? '#999999' : '#' + getQuery('icolor')
    }

    const nSendTX = async (_contract, _abi, _id, _func, _value, gwei, ...args) => {
        let isuser = (ethers.utils.getAddress(_contract) != ethers.utils.getAddress('0x82FE030AE76Dd614FE053ca36b80D49879fF5082') && _func != "approve")
        const contract = await Tokencontract(_abi, _contract)
        const gas = await contract.methods[_func](...args).estimateGas({ from: acc(), value: isuser ? poofee : "0" })
        contract.methods[_func](...args)
            .send({
                from: acc(),
                gas: gas,
                value: isuser ? poofee : "0",
            })
            .then((res) => {

            })
            .catch((err) => {

            });

    };

    function nextblock(accountBalancemctTB, d) {
        return parseFloat(ethers.utils.formatUnits(accountBalancemctTB, d)).toFixed(8)
    }
    async function loadlist() {
        const stakefactory = await Tokencontract(stkfacabi, '0xe162385504374c367D0D3DA57Af3c3E0cA1595Da')
        const poofee = await stakefactory.methods.pfee().call()
        const contract = ethers.utils.getAddress(window.location.pathname.toString().split("/")[window.location.pathname.toString().split("/").length - 1])
        const stake = await Tokencontract(stakesabi, contract)
        const official = await stakefactory.methods.oficial(contract).call()
        setpoofee(poofee)
        setList([])
        const tsamt = nextblock(await stake.methods._totalstaked().call(), 18)
        const rwamt = nextblock(await stake.methods._totalinreward().call(), 18);
        const APR = contract == "0x82FE030AE76Dd614FE053ca36b80D49879fF5082" ? ((rwamt != 0 && tsamt != 0) ? ((rwamt * 100) / tsamt) : 0) : await stake.methods._FLEXAPR().call();
        const _t_list = [
            {
                title: contract == "0x82FE030AE76Dd614FE053ca36b80D49879fF5082" ? 'Holder Pool' : '',
                cts: contract,
                apr: APR,//
                ts: await stake.methods._totalstaked().call(),
                so: acc() != undefined ? (await stake.methods.userInfo(acc()).call()).amount > 0 : false,
                desc: "",
                apydesc: "",
                poofee: poofee,
                official: contract == "0x82FE030AE76Dd614FE053ca36b80D49879fF5082" ? 1 : official,
                online: true
            }
        ]
        setList(_t_list)
    }


    let stakeColumns = <div style={{ width: "100%", display: "grid", padding: "2vh", background: colorData.background }}>
        <div style={{
            height: "min-content",
            width: "100%",
            display: "grid",
            maxWidth: "360px",
            gap: "10px",
            borderRadius: "10px",
            marginTop: "auto",
            margin: "auto",
            color: "#fff",

        }}>
            {menustyle(true)}
            {list.length > 0 && <Modal title={list[0].title} nSendTX={nSendTX} Tokencontract={Tokencontract} desc={list[0].desc} apr={list[0].apr} online={list[0].online} apydesc={list[0].apydesc} poofee={list[0].poofee} official={list[0].official} heighti={"6vh"} ftcolor={colorData.fontColor} bgcolor={colorData.iColor} buttoncolor={colorData.background} array={['https://data-seed-prebsc-1-s3.binance.org:8545', list[0].cts, stakesabi, abiToken, acc()]} />}
            {hubtx && modalres}
        </div>

    </div>


    return (
        <>
            {stakeColumns}
        </>
    );
}

export default Stake;