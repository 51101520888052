import React, { useState, useEffect, useContext } from "react";
import "./css/jso.scss";
import { ethers } from 'ethers';
import abiToken from "../../abis/bnbabi.json";
import stakesabi from "../../abis/nftpoolabi.json";
import stkfacabi from "../../abis/stakefactory.json";
import Modal from '../stake/components/modal';
import ModalH from '../stake/components/modalH';
import AppContext from "../../framework/helpers/AppContext";
import CheckStake from "../swap/checkStakePools";
import Minimized from "./minimized";
import ModalStake from "./components/modalStake";
import ModalRemove from "./components/modalRemoveStake";
import ModalCalc from "./components/modalCalc";
import MiniSwap from "./miniSwap";
import ProjectData from '../../config';
import Button from "../../components/Launchpad/Button";
import Timer from "../../components/Timer/timer";
let projectName = "Martik"
const {
    Name,
    Logo,
    backgroundColor,
    buttonsColor,
    buttonsBorders,
    inputsColor,
    inputsBorders,
    outbuttonsColor,
    Borders,
    outFont,
    innerFont,
    innerBFont,
    Routers, screens
} = ProjectData[window.location.host.split('.')[0] != undefined
    ? window.location.host.split('.')[0] == "app"
        ? window.location.host.split('.')[0] + "." + window.location.host.split('.')[1]
        : window.location.host.split('.')[0] : "Martik"]
const Web3 = require('web3-eth');

const NModal = (props) => {

    const buypool = [
        {
            "inputs": [
                {
                    "internalType": "uint256",
                    "name": "_amount",
                    "type": "uint256"
                }
            ],
            "name": "_stake",
            "outputs": [],
            "stateMutability": "payable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "_contract",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "id",
                    "type": "uint256"
                }
            ],
            "name": "Harvest",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "contract IBEP20",
                    "name": "MAINTOKEN",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "_price",
                    "type": "uint256"
                },
                {
                    "internalType": "contract StakeFactory",
                    "name": "fac",
                    "type": "address"
                }
            ],
            "name": "init",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "uint256",
                    "name": "__price",
                    "type": "uint256"
                },
                {
                    "internalType": "uint256",
                    "name": "_nftPoolLimit",
                    "type": "uint256"
                },
                {
                    "internalType": "uint256",
                    "name": "timeinhours",
                    "type": "uint256"
                }
            ],
            "name": "setPoolRules",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [],
            "stateMutability": "nonpayable",
            "type": "constructor"
        },
        {
            "inputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "name": "_icon",
            "outputs": [
                {
                    "internalType": "string",
                    "name": "",
                    "type": "string"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "_iconb",
            "outputs": [
                {
                    "internalType": "string",
                    "name": "",
                    "type": "string"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "_name",
            "outputs": [
                {
                    "internalType": "string",
                    "name": "",
                    "type": "string"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "_symbol",
            "outputs": [
                {
                    "internalType": "string",
                    "name": "",
                    "type": "string"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "_totalstakedIR",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                }
            ],
            "name": "Auth",
            "outputs": [
                {
                    "internalType": "bool",
                    "name": "",
                    "type": "bool"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "BEP20Mtoken",
            "outputs": [
                {
                    "internalType": "contract IBEP20",
                    "name": "",
                    "type": "address"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "BEP20Rtoken",
            "outputs": [
                {
                    "internalType": "contract IBEP20",
                    "name": "",
                    "type": "address"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "buys",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                }
            ],
            "name": "claimable",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "duration",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "factory",
            "outputs": [
                {
                    "internalType": "contract StakeFactory",
                    "name": "",
                    "type": "address"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "finishAt",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "FIXAPR",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "who",
                    "type": "address"
                }
            ],
            "name": "getUserDetails",
            "outputs": [
                {
                    "components": [
                        {
                            "internalType": "uint256",
                            "name": "id",
                            "type": "uint256"
                        },
                        {
                            "internalType": "string",
                            "name": "uri",
                            "type": "string"
                        },
                        {
                            "internalType": "uint256",
                            "name": "claimable",
                            "type": "uint256"
                        },
                        {
                            "internalType": "address",
                            "name": "pool",
                            "type": "address"
                        }
                    ],
                    "internalType": "struct StakePool.data[]",
                    "name": "",
                    "type": "tuple[]"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                }
            ],
            "name": "latestHavest",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "minStakeTime",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "nft",
            "outputs": [
                {
                    "internalType": "contract NFT",
                    "name": "",
                    "type": "address"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "nftTotalLimit",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "owner",
            "outputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "price",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "priceIR",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "rewardPerTokenStored",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "rewardRate",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "router",
            "outputs": [
                {
                    "internalType": "contract IDEXRouter",
                    "name": "",
                    "type": "address"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                }
            ],
            "name": "since",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "updatedAt",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "user",
                    "type": "address"
                }
            ],
            "name": "userInfo",
            "outputs": [
                {
                    "components": [
                        {
                            "internalType": "uint256",
                            "name": "claimable",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "since",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "latestHavest",
                            "type": "uint256"
                        }
                    ],
                    "internalType": "struct BuyPool.userInfoS",
                    "name": "",
                    "type": "tuple"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                }
            ],
            "name": "userRewardPerTokenPaid",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "wallet",
            "outputs": [
                {
                    "internalType": "contract wall",
                    "name": "",
                    "type": "address"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "WBNB",
            "outputs": [
                {
                    "internalType": "contract IBEP20",
                    "name": "",
                    "type": "address"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        }
    ]

    let provider = props.array[0]
    let contract = props.array[1]
    let stakesabi = props.array[2]
    let tokenabi = props.array[3]
    let accounts = [props.array[4]]
    const [tokenACT, settokenACT] = useState(undefined);
    const [tokenBCT, settokenBCT] = useState(undefined);
    const [STAKECTS, setSTAKECTS] = useState(contract);
    const [tokenB, settokenB] = useState('0x00000000000000000000000000000000');
    const [stakect, setStake] = useState([{}]);
    const [AllowA, setAllowA] = useState(0);
    const [account, setAccount] = useState([{}]);
    const [accountNRSC, setAccountNRSC] = useState(0);
    const [totalstaked, settotalstaked] = useState(0);
    const [accountBalancemTB, setAccountBalanceTB] = useState(0);
    const [APR, setAPR] = useState(0);
    const [poofee, setpoolfee] = useState(0);
    const [withdrawFee, setWithdrawFee] = useState(0);
    const [stakelimit, setstakelimit] = useState(0);
    const [symbola, setsymbolA] = useState("");
    const [symbolb, setsymbolB] = useState("");
    const [deca, setdecA] = useState(0);
    const [decb, setdecB] = useState(0);
    const [ia, setiA] = useState(0);
    const [ib, setiB] = useState(0);
    const [mult, setmult] = useState(1);
    const [totalSupplyB, setTotalSupplyB] = useState(0);
    const [finish, setfinish] = useState(0);
    const [view_details, setview_details] = useState(true);
    const [view_Add, setview_Add] = useState(false);
    const [view_Remove, setview_Remove] = useState(false);
    const [view_Roi, setview_Roi] = useState(false);
    const [seconds, setSeconds] = useState(0);

    useEffect(() => {
        data()
    }, [])

    useEffect(() => {
        let interval = setInterval(() => {
            setSeconds(seconds => seconds + 1)
            data()
        }, 5000);
        return () => clearInterval(interval);
    }, [seconds]);
    async function getmeta(uri) {
        uri = uri.replace("https://hvgatohost.000webhostapp.com", "https://martikstorage.onrender.com")
        const json = await fetch(uri, {
            method: "GET"
        }).then(e => e.json())
        return json
    }
    async function data() {

        if (STAKECTS != null) {
            const _stakect = await props.Tokencontract(stakesabi, STAKECTS, provider);
            setStake(_stakect)
            call(_stakect.methods.BEP20Mtoken, gettokendataA);
            call(_stakect.methods.BEP20Rtoken, gettokendataB);
            if (ia == "https://bscscan.com/images/main/empty-token.png" || ia == 'https://martik.site/icons/stake/' + symbola + '.png') {
                setiA('https://martik.site/icons/stake/' + symbola + '.png')
            } else {
                const i0 = await _stakect['methods']['_icon']().call()
                const meta = await getmeta(i0);
                setiA(meta.image)
            }
            call(_stakect.methods.BEP20Mtoken, gettokendataA);
            call(_stakect.methods.BEP20Rtoken, gettokendataB);

            if (ib == "https://bscscan.com/images/main/empty-token.png" || ib == 'https://martik.site/icons/stake/' + symbolb + '.png') {
                setiB('https://martik.site/icons/stake/' + symbolb + '.png')
            } else {
                setiB(await _stakect['methods']['_iconb']().call())
            }

            call(_stakect.methods._totalstaked, settotalstaked);
            call(_stakect.methods.WithdrawFee, setWithdrawFee);
            call(_stakect.methods.price, (e) => {
               // console.log(e);
                setstakelimit(e)
            });
            call(_stakect.methods.finishAt, setfinish);
            try {
                if (props.title == "Holder Pool") { setAPR(props.apr) } else { call(_stakect.methods._FLEXAPR, setAPR) };
                /*  if (accounts[0] != undefined) {
                      const _acc = await _stakect['methods']['userInfo'](accounts[0]).call()
  
                      setAccount(_acc)
                    console.log(_acc)
                      const ria = await _stakect['methods']['rewardinstake'](accounts[0]).call()
                      console.log(ria)
                      setAccountNRSC(ria)
                  }*/
            } catch (error) {
                console.log(error)
            }
        }
    }
    async function gettokendataA(e) {
        settokenACT(e)
        const token = await props.Tokencontract(tokenabi, e, provider);
        settokenB(token)
        call(token.methods.symbol, setsymbolA);
        call(token.methods.decimals, setdecA);
        call(token.methods.totalSupply, setTotalSupplyB);

        if (accounts[0] != undefined) {
            call(token.methods.allowance, setAllowA, accounts[0], STAKECTS);
            call(token.methods.balanceOf, setAccountBalanceTB, accounts[0]);
        }
    }
    async function gettokendataB(e) {
        settokenBCT(e)
        const token = await props.Tokencontract(tokenabi, e, provider);
        call(token.methods.symbol, setsymbolB);
        call(token.methods.decimals, setdecB);
    }
    function call(func, callback, ...args) {
        try {
            func(...args).call()
                .then((result) => {
                    callback(result);
                })
                .catch((error) => {
                  //  console.log(error)
                })
        } catch (error) {
           // console.log(error)
        }

    }
    function nextpercent(accountBalancemctTB, d) {
        return parseFloat((accountBalancemctTB) + "").toFixed(2)

    }

    function nextblock(accountBalancemctTB, d) {
        if (accountBalancemctTB != undefined && accountBalancemctTB != 0) {
            if (accountBalancemctTB.toString().length >= d) {
                return parseFloat(ethers.utils.formatUnits(accountBalancemctTB, d)).toFixed(5)
            } else {
                let cutin = (d - accountBalancemctTB.toString().length) + 2
                return parseFloat(ethers.utils.formatUnits(accountBalancemctTB, d)).toFixed(cutin)
            }
        } else {
            return 0.00
        }
    }
    async function nstake(value) {
       // (_contractA, _ABI, NetId, funcN, _value, GWEI, ...args)
        try {
            await props.nSendTX(screens["nftpools"].buypool, buypool, props.netId, '_stake', props.poofee, 0, value)
            data()
        } catch (error) {
            console.log(error)
            try {
              await props.nSendTX(tokenB['_address'], tokenabi, props.netId, 'approve', '0', 0, screens["nftpools"].buypool, "1" + "0".repeat(50))
                await props.nSendTX(screens["nftpools"].buypool, buypool, props.netId, '_stake', props.poofee, 0, value)
                data()
            } catch (error) {
                console.log("internal error")
            }

        }
    }
    async function rstake(value) {
        let a = ['']
        let e = value + ""
        if (e.toString().includes(",")) {
            a = e.toString().split(",")
            e = ethers.utils.parseUnits(a[0] + "." + a[1], deca).toString()
        } else {
            e = ethers.utils.parseUnits(e, deca).toString()
        }
        await props.nSendTX(STAKECTS, stakesabi, props.netId, 'unStake', props.poofee, 0, e)
        data()
    }
    async function hstake() {
        await props.nSendTX(screens["nftpools"].buypool, buypool, props.netId, 'Harvest', props.poofee, 0)
        data()
    }
    async function approve() {
        await props.nSendTX(tokenB['_address'], tokenabi, props.netId, 'approve', '0', 0, STAKECTS, "1" + "0".repeat(50))
        data()
    }
    async function getAllowance(callback) {
        const token = await props.Tokencontract(tokenabi, tokenB['_address'], provider);
        if (accounts[0] != undefined) {
            call(token.methods.allowance, callback, accounts[0], STAKECTS);
        }
    }
    const SAFE = (props) => {
        return (
            <p style={{ width: "95%", display: "grid", gridTemplateColumns: "auto auto", margin: "auto", marginTop: "5px" }}><span style={{
                textAlign: "left",
                width: "100%",
                color: outFont,
                fontSize: "15px",
            }}>{props.what} </span><span style={{
                textAlign: "right",
                width: "100%",
                fontSize: "16px",
                color: outFont,
            }}>
                    {props.result}
                </span></p>
        );
    }

    return (
        <div style={{
            height: "min-content",
            width: "100%",
            minWidth: "280px",
            listStyleType: "none",
            display: "grid",
            margin: "auto",
            borderRadius: "10px",
            color: outFont,
            fontSize: "12px",
            paddingTop: "10px"
        }}>
            {view_Add && <ModalStake
                ia={ia}
                sethubtx={setview_Add}
                nextpercent={nextpercent}
                nstake={nstake}
                nextblock={nextblock}
                symbola={symbola}
                symbolb={symbolb}
                id={symbola + symbolb}
                accountBalancemTB={accountBalancemTB}
                deca={deca}
                tokenA={tokenB}
                getAllowance={getAllowance}
                approve={approve} />}
            {view_Remove && <ModalRemove
                ia={ia}
                sethubtx={setview_Remove}
                nextpercent={nextpercent}
                nstake={rstake}
                nextblock={nextblock}
                id={symbola + symbolb}
                symbola={symbola}
                symbolb={symbolb}
                accountBalancemTB={account['amount']}
                deca={deca} />}
            {view_Roi && <ModalCalc
                ia={ia}
                sethubtx={setview_Roi}
                APR={APR}
                symbola={symbola}
                symbolb={symbolb}
                nextpercent={nextpercent} />}

            <div style={{ height: "min-content", display: "grid", gridTemplateColumns: window.innerWidth < 720 ? "auto" : "auto", width: "100%", margin: "auto", padding: "15px 0px", cursor: "pointer", borderRadius: "10px", gap: "10px" }}>


                <div style={{ border: "1px solid " + Borders, display: "grid", gridTemplateColumns: window.innerWidth < 720 ? "auto" : "auto auto auto", gap: "5px", padding: "20px 0px", height: "100%", width: "90%", margin: "auto", borderRadius: "10px", paddingBottom: "20px", zIndex: "1", paddingRight: "20px", paddingLeft: "20px" }}>

                    <div class="frame">
                        <div class="center">
                            {
                                screens["nftpools"].nfts.map((e, i) =>
                                    <div id={"card" + (i + 1)} class="card">
                                        <img src={e} />
                                    </div>
                                )
                            }


                        </div>
                    </div>

                    <p />
                    <div style={{ display: "grid", gridTemplateColumns: window.innerWidth < 720 ? "auto" : "auto auto", gap: "5px", height: "min-content", width: "95%", margin: "auto", }}>
                        <div style={{ display: "grid", gridTemplateColumns: "auto", width: "100%", height: "70px", marginBottom: "20px", padding: "auto" }}>

                            <div style={{
                                height: "min-content",
                                width: "100%",
                                margin: "auto 0",
                                display: "grid",
                                gridTemplateColumns: "auto",

                            }}>
                                <p style={{ textAlign: "left", height: "min-content", fontWeight: "bold", fontSize: "15px", margin: "auto 0", color: outFont }}>Price {nextblock(stakelimit, deca) + ` ${symbola}`} </p>

                                <p />
                                <p style={{ textAlign: "left", height: "min-content", fontSize: "12px", marginBottom: "0px", color: outFont }}>Fee {props.poofee > 0 ? nextblock(props.poofee, 18) + " BNB" : '0 %'}</p>


                            </div>

                        </div>
                        <div style={{ display: "grid", gridTemplateColumns: window.innerWidth < 720 ? "auto auto" : "auto auto", gap: "5px" }}>
                            <div style={{ display: "grid", gridTemplateColumns: "auto auto auto", height: "50px", width: "auto", gap: "5px", margin: "auto", fontSize: "20px", marginRight: "0", color: innerBFont }}>
                                {props.online && <button onClick={() => {
                                    setmult(mult + 1)
                                }} style={{ cursor: "pointer", display: "grid", background: outbuttonsColor, border: "1px solid" + Borders, borderRadius: "10px", height: "50px", width: "50px", margin: "auto", color: innerBFont }} disabled={!(mult != 5)}>
                                    <p style={{ margin: "auto" }}>+</p>
                                </button>}
                                <p style={{ textAlign: "left", height: "min-content", fontSize: "15px", marginBottom: "0px", color: outFont, margin: "auto" }}>{mult}</p>
                                <button onClick={() => {
                                    setmult(mult - 1)
                                }} style={{ cursor: "pointer", display: "grid", border: "1px solid" + Borders, background: mult != 0 ? outbuttonsColor : outbuttonsColor + "50", borderRadius: "10px", height: "50px", width: "50px", margin: "auto", color: innerBFont }} disabled={!(mult != 1)}>
                                    <p style={{ margin: "auto" }}>-</p>
                                </button>
                            </div>
                            <button onClick={() => {
                             //   console.log("sdafd")
                                nstake(mult)
                            }} disabled={false} style={{ cursor: "pointer", display: "grid", background: outbuttonsColor, border: "1px solid" + Borders, borderRadius: "10px", height: "50px", width: "160px", margin: "auto", color: innerBFont }}>
                                <p style={{ margin: "auto" }}>BUY {mult} NFTS</p>
                            </button>
                        </div>


                    </div>

                </div>




            </div>

            {/**/}
        </div >
    );

}
//BUY {mult} NFTS
const Stake = (props) => {

    const {
        account,
        provider,
        chain,
        chains,
        web3,
        Tokencontract,
        gasTX,
        getID,
        callTX,
        sendTX,
        nSendTX,
        nSendCT
    } = useContext(AppContext)

    const [address, setAddress] = useState(account);

    const [modal, setModal] = useState(undefined);
    const [disable_list, setDisable_list] = useState([{}]);
    const [list_mode, setListMode] = useState(0);
    const [active_mode, setactiveMode] = useState(0);
    const [only_staked_mode, setOnly_Staked_Mode] = useState(false);
    const [filter, setFilter] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const [poofee, setpoofee] = useState(0);
    const [filterlay, setFilterLay] = useState('none');
    useEffect(() => {
        //document.title = "MARTIK | POOLS"
    }, []);
    useEffect(() => {
    }, [modal]);
    useEffect(() => {
        let tmodal = modal
        setModal(undefined)
        setTimeout(() => {
            setModal(tmodal)
        }, 1000);
    }, [active_mode]);

    const xnSendTX = async (_contract, _abi, _id, _func, _value, Gwei, ...args) => {
        let isuser = (_contract != '0x82FE030AE76Dd614FE053ca36b80D49879fF5082' && _func != "approve")

        await nSendTX(_contract, _abi, _id, _func, isuser ? _value : poofee, Gwei, ...args)
      //  (_contractA, _ABI, NetId, funcN, _value, GWEI, ...args)

    };
    // ["0x98DF35D07f55A1e8885c6D23641546E73009001C", "0x71C65050D2B48b911F6F869fBDd6d822eE15FF6b", "0x560fF6a296D5F84b480aEbf57CFc54E9Faec6354", "0xD7984989E7693a19C2358BE526266A76b8A2b7BC", "0x4316380ad94458DF90f527b790eDBC81292846db", "0x650e5B875035420C872b90cCE0Ee777De30D8C47", "0x590B534523dDDa612c58Bd05cdEb05bC1DE013ca", "0x46006BEbd30f3864A2B2555f6D88fa3EE8BE342E", "0x4A51BA1Bb9526aa31b967b06Ec2F3557C3e1d3Cc", "0xFC765F0C445b8a6436db9D3D9750C3799Bf58Fd0", "0x025dC95E02e33c711B509485BA9233fb228036c4", "0x0427d0497fd82507666c186B50188526e02F2701", "0xEe664C9F3Ed8b4479eE4D98f6919bBc50a285082", "0xC41D0610763dc677F704d6A14009Ca3954E4eA26", "0xB4C3f82754c97FC43c2f01528BA26E131bd63ec8", "0x8dEc373bbcA468168c3E0bd2BEb5Bcd66731DDd8", "0x7E6fDfF5487b0e9b97F3B618D3f76410779aA04f", "0x8423995001780bB80ABBbF24845507e21cA56061", "0xC1122978D8cc23F06B26ade13fAdc10bB16aa0E4", "0x7B30A0C75a9d0bC3849f6D5BD2F6Bdc921573aa9", "0x701f1254c5cCb95a09Ed82C6b23e19782fAa47A4"]
    // ["0x98DF35D07f55A1e8885c6D23641546E73009001C","0x71C65050D2B48b911F6F869fBDd6d822eE15FF6b","0x560fF6a296D5F84b480aEbf57CFc54E9Faec6354","0xD7984989E7693a19C2358BE526266A76b8A2b7BC","0x4316380ad94458DF90f527b790eDBC81292846db","0x650e5B875035420C872b90cCE0Ee777De30D8C47","0x590B534523dDDa612c58Bd05cdEb05bC1DE013ca","0x46006BEbd30f3864A2B2555f6D88fa3EE8BE342E","0x4A51BA1Bb9526aa31b967b06Ec2F3557C3e1d3Cc","0xFC765F0C445b8a6436db9D3D9750C3799Bf58Fd0","0x025dC95E02e33c711B509485BA9233fb228036c4","0x0427d0497fd82507666c186B50188526e02F2701","0xEe664C9F3Ed8b4479eE4D98f6919bBc50a285082","0xC41D0610763dc677F704d6A14009Ca3954E4eA26","0xB4C3f82754c97FC43c2f01528BA26E131bd63ec8","0x8dEc373bbcA468168c3E0bd2BEb5Bcd66731DDd8","0x7E6fDfF5487b0e9b97F3B618D3f76410779aA04f","0x8423995001780bB80ABBbF24845507e21cA56061","0xC1122978D8cc23F06B26ade13fAdc10bB16aa0E4","0x7B30A0C75a9d0bC3849f6D5BD2F6Bdc921573aa9","0x701f1254c5cCb95a09Ed82C6b23e19782fAa47A4","0x436080202a6cDAdF2cbf83D6D5dFD33860ecC7cB","0x46b821064370bDaD28eaA2ECdf2629384CECF820","0xAef0332741872259EC50A3cbA1DE82d5790D0bC0","0x65D95b37Dd46D3Ab60715637aE91E5801AcABf39","0x583D5Ece5e83816689d2FbD0543f6B962474C1fd","0xB7d3D8f6D8cCaF58744dF547AAdFFe0CFC48f729"]
    return (
        <div style={{ width: "100%", margin: "auto", maxWidth: "1280px", display: "grid" }}>
            {modal != undefined && <NModal tokenM={modal.act} title={modal.title} minimo={modal.min} nSendTX={xnSendTX} netId={getID()} Tokencontract={Tokencontract} desc={modal.desc} apr={modal.apr} online={modal.online} apydesc={modal.apydesc} poofee={modal.poofee} official={modal.official} heighti={"6vh"} bgcolor={"#ffffff20"} buttoncolor={"#ffffff20"} array={['https://data-seed-prebsc-1-s3.binancmodal.org:8545', modal.cts, stakesabi, abiToken, address]} />}
            {<Minimized setModal={setModal} nSendTX={nSendTX} netId={getID()} active_mode={active_mode} poofee={modal?.poofee} />}


        </div>
    );
}

export default Stake;