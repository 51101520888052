import React, { useState, useEffect } from 'react';

const Nfts = (props) => {

  const {
    getdatacapa,
    ccap,
    getdatalogo,
    cava,
    getdatanm,
    cnm,
    getdatadesc,
    cdesc,
    list,
    MODAs,
  } = props.data
  return (
    <>
      {props.view == 3 && getdatacapa(ccap)}
      {props.view == 3 && <div style={{
        height: "min-content",
        width: "100%",
        display: "grid",

        borderBottom: "1px solid #ffffff20",
        marginTop: "auto",
        color: "#fff",
      }}>
        <div style={{ display: "grid", gridTemplateColumns: "min-content auto", height: "80px", width: "100%", padding: "auto" }}>
          <i style={{
            height: "70px",
            width: "70px",
            margin: "auto",
            background: 'url(' + getdatalogo(cava) + ') center center / 70px no-repeat',

            borderRadius: "360px",
          }}>
          </i>

          <p style={{ textAlign: "left", fontWeight: "bold", height: "min-content", margin: "auto", marginLeft: "18px", fontSize: "18px" }}>{getdatanm(cnm)}
            <br /> <span style={{ fontSize: "15px", color: "#f5f5f5", }}>{getdatadesc(cdesc)}</span></p>

        </div>
      </div>}
      <div style={{
        height: "min-content",
        width: "100%",
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(280px, 1fr)",

        marginTop: "auto",
        color: "#fff",
      }}>

        {list.map((e) => <MODAs pos={e.pos} view={props.view} />).reverse()}
      </div>
    </>
  );


}
export default Nfts