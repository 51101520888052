import React, { useState, useEffect, useContext } from 'react';
import { Contract, ethers } from 'ethers';
import 'balloon-css';
import { useNavigate } from "react-router-dom";
import { toChecksumAddress } from 'ethereum-checksum-address';
import mtkswap from '../../abis/martikswap.json'
import Chart from './chart';
import TokenNomics from '../../components/Launchpad/TokenNomics';
import AppContext from '../../framework/helpers/AppContext';
import env from '../../hooks/data/Config';
import TokenInfo from './tokenInfo';
function ModalSettings(data) {
    const {
        hubinteract,
        modaltokensls,
        settings,
        modalSettings,
        setSettings,
        SAFE,
        tokenAname,
        tokenBname,
        balancetokenA,
        balancetokenB,
        iTA,
        iTB,
        props,
        getAmountIn,
        getAmountOut,
        amountinswap,
        amountoutswap,
        aaaaa,
        AllowA,
        ButtonI,
        swap,
        bnbtext,
        price,
        Desc,
        approve,
        tokenBCT,
        tokenACT,
        splippage,
        toModalSettings
    } = data.data
    const {
        Hint,
        setGWEI,
        Gwei,
        setSlip,
        Set,
        setDead,
        dead
    } = toModalSettings

    return (<div style={{ height: "100%", width: "100%", position: "fixed", display: "grid", background: "#00000050", top: "0", left: "0", zIndex: "4000" }}>

        <div style={{ height: 'min-content', width: "360px", background: "#222", borderRadius: "10px", zIndex: "10000", margin: "auto", paddingBottom: "20px" }}>
            <div style={{ display: "grid", gridTemplateColumns: "25px 280px 50px", width: "100%", height: "70px", marginBottom: "10px", padding: "auto" }}>
                <p></p>
                <div style={{
                    height: "min-content",
                    width: "100%",
                    margin: "auto"
                }}>
                    <div style={{
                        height: "30px",
                        width: "100%",
                        display: "inline-flex"
                    }}>
                        <p style={{ textAlign: "left", height: "min-content", fontWeight: "bold", fontSize: "17px", margin: "auto 0" }}>{"Settings"}</p>
                    </div>

                </div>
                <svg onClick={() => { setSettings(!settings) }} xmlns="http://www.w3.org/2000/svg" style={{ cursor: "pointer", margin: "auto" }} width="25" height="25" fill="#fff" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
            </div>
            <div style={{ display: "grid", width: "100%", height: "70px", marginBottom: "10px", marginLeft: "25px" }}>
                <div style={{
                    height: "30px",
                    width: "100%",
                    display: "inline-flex"
                }}>
                    <p style={{ textAlign: "left", height: "min-content", fontSize: "16px", margin: "auto 0" }}>{"Default Transaction Speed (GWEI)"} <Hint desc={"Adjusts the gas price (transaction fee) for your transaction. Higher GWEI = higher speed = higher fees"} /> </p>
                </div>
                <div style={{ minWidth: "260px", margin: "auto", marginLeft: "0", display: "grid", gridTemplateColumns: " auto auto auto auto ", height: '40px', borderRadius: "10px", gap: "5px" }}>
                    <div onClick={() => { setGWEI(1) }} style={{ cursor: "pointer", width: "100%", background: Gwei == 1 ? "#ffffff" : "#ffffff00", margin: "auto", display: "grid", height: '32px', borderRadius: "5px", color: Gwei == 1 ? "#000" : "#ffffff", border: Gwei == 1 ? "1px solid #00000000" : "1px solid #ffffff" }}>
                        <p style={{ margin: "auto", fontSize: "12px", fontWeight: "bold" }}>Standard (1)</p>
                    </div>
                    <div onClick={() => { setGWEI(2) }} style={{ cursor: "pointer", width: "100%", background: Gwei == 2 ? "#ffffff" : "#ffffff00", margin: "auto", display: "grid", height: '32px', borderRadius: "5px", color: Gwei == 2 ? "#000" : "#ffffff", border: Gwei == 2 ? "1px solid #00000000" : "1px solid #ffffff" }}>
                        <p style={{ margin: "auto", fontSize: "12px", fontWeight: "bold" }}>Fast (2)</p>
                    </div>
                    <div onClick={() => { setGWEI(3) }} style={{ cursor: "pointer", width: "100%", background: Gwei == 3 ? "#ffffff" : "#ffffff00", margin: "auto", display: "grid", height: '32px', borderRadius: "5px", color: Gwei == 3 ? "#000" : "#ffffff", border: Gwei == 3 ? "1px solid #00000000" : "1px solid #ffffff" }}>
                        <p style={{ margin: "auto", fontSize: "12px", fontWeight: "bold" }}>Instant (3)</p>
                    </div>

                </div>

            </div>
            <div style={{ display: "grid", width: "100%", height: "70px", marginBottom: "10px", marginLeft: "25px" }}>
                <div style={{
                    height: "30px",
                    width: "100%",
                    display: "inline-flex"
                }}>
                    <p style={{ textAlign: "left", height: "min-content", fontSize: "16px", margin: "auto 0" }}>{"Slippage Tolerance"} <Hint desc={"Setting a high slippage tolerance can help transactions succeed, but you may not get such a good price. Use with caution."} /> </p>
                </div>
                <div style={{ minWidth: "260px", margin: "auto", marginLeft: "0", display: "grid", gridTemplateColumns: " auto auto auto auto ", height: '40px', borderRadius: "10px", gap: "5px" }}>
                    <div onClick={() => { setSlip(0.1) }} style={{ cursor: "pointer", width: "100%", background: splippage == 0.1 ? "#ffffff" : "#ffffff00", margin: "auto", display: "grid", height: '32px', borderRadius: "5px", color: splippage == 0.1 ? "#000" : "#ffffff", border: splippage == 0.1 ? "1px solid #00000000" : "1px solid #ffffff" }}>
                        <p style={{ margin: "auto", fontSize: "12px", fontWeight: "bold" }}>0.1%</p>
                    </div>
                    <div onClick={() => { setSlip(0.5) }} style={{ cursor: "pointer", width: "100%", background: splippage == 0.5 ? "#ffffff" : "#ffffff00", margin: "auto", display: "grid", height: '32px', borderRadius: "5px", color: splippage == 0.5 ? "#000" : "#ffffff", border: splippage == 0.5 ? "1px solid #00000000" : "1px solid #ffffff" }}>
                        <p style={{ margin: "auto", fontSize: "12px", fontWeight: "bold" }}>0.5%</p>
                    </div>
                    <div onClick={() => { setSlip(1) }} style={{ cursor: "pointer", width: "100%", background: splippage == 1 ? "#ffffff" : "#ffffff00", margin: "auto", display: "grid", height: '32px', borderRadius: "5px", color: splippage == 1 ? "#000" : "#ffffff", border: splippage == 1 ? "1px solid #00000000" : "1px solid #ffffff" }}>
                        <p style={{ margin: "auto", fontSize: "12px", fontWeight: "bold" }}>1.0%</p>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "auto min-content", gap: "5px", height: "100%", width: "100%", margin: "auto" }}>
                        <div style={{
                            height: "40px",
                            width: "100%",
                            margin: "auto",
                            display: "grid",
                            gridTemplateColumns: "35px auto",
                            backgroundColor: "#00000020",
                            borderRadius: "10px"
                        }}>
                            <div style={{ height: '40px', width: "100%", display: "grid", gridTemplateColumns: "100%" }}>
                                <input type="number" id={props.id} onChange={(e) => setSlip((e.target.value <= 999 && e.target.value > 0) ? e.target.value : e.target.value > 999 ? 999 : 0)} placeholder='0.0' min={0} max="999" style={{ backgroundColor: "#00000000", outline: "none", textAlign: "right", border: "none", height: "100%", width: "100%", margin: "auto", borderRadius: "10px", color: "#eee", fontSize: "14px", paddingRight: "10px" }} value={splippage} />
                            </div>
                        </div>
                        <p style={{
                            height: "40px",
                            border: "1px solid #00000010",
                            borderRadius: "10px",
                            margin: "auto",
                            width: "100%",
                            display: "grid",
                            cursor: "pointer"
                        }}><p style={{ margin: "auto", fontWeight: "bold" }}>%</p></p>
                    </div>
                </div>

            </div>
            <Set what={"Tx deadline (mins)"} desc={"Your transaction will revert if it is left confirming for longer than this time."} result={<div style={{ display: "grid", height: "100%", margin: "auto", marginLeft: "25px" }}>
                <div style={{
                    height: "40px",
                    width: "100px",
                    margin: "auto",
                    marginRight: "25px",
                    display: "grid",
                    gridTemplateColumns: "auto",
                    backgroundColor: "#00000020",
                    borderRadius: "10px"
                }}>
                    <div style={{ height: '40px', width: "100%", display: "grid", gridTemplateColumns: "100%" }}>
                        <input type="number" id={props.id} onChange={(e) => setDead((e.target.value < 59 && e.target.value > 0) ? e.target.value : e.target.value > 59 ? 59 : 0)} placeholder='0.0' min={0} max="59" style={{ backgroundColor: "#00000000", outline: "none", textAlign: "center", border: "none", height: "100%", width: "100%", margin: "auto", borderRadius: "10px", color: "#eee", fontSize: "14px", paddingRight: "10px" }} value={dead} />
                    </div>
                </div>

            </div>} />
        </div>
    </div>)
}
export default ModalSettings;