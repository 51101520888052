import React, { useState, useEffect, useContext } from "react";
import { ethers } from 'ethers';
import abiToken from "../../abis/bnbabi.json";
import stakesabi from "../../abis/stakesn.json";
import stkfacabi from "../../abis/stakefactory.json";
import Modal from '../stake/components/modal';
import ModalH from '../stake/components/modalH';
import AppContext from "../../framework/helpers/AppContext";
import ProjectData from '../../config';
let projectName = "Martik"
const {
    Name,
    Logo,
    backgroundColor,
    buttonsColor,
    buttonsBorders,
    inputsColor,
    inputsBorders,
    outbuttonsColor,
    modalsColor,
    Borders,
    outFont,
    innerFont,
    Routers,
    screens
} = ProjectData[window.location.host.split('.')[0] != undefined 
? window.location.host.split('.')[0] == "app" 
? window.location.host.split('.')[0] + "." + window.location.host.split('.')[1] 
: window.location.host.split('.')[0] : "Martik"]
const Web3 = require('web3-eth');
const Minimized = (props) => {

    const {
        account,
        provider,
        chain,
        chains,
        web3,
        Tokencontract,
        gasTX,
        getID,
        callTX,
        sendTX,
        nSendTX,
        nSendCT
    } = useContext(AppContext)

    const [address, setAddress] = useState(account);

    const [list, setList] = useState([]);
    const [oflist, setoflist] = useState([]);
    const [disable_list, setDisable_list] = useState([{}]);
    const [list_mode, setListMode] = useState(0);
    const [active_mode, setactiveMode] = useState(0);
    const [only_staked_mode, setOnly_Staked_Mode] = useState(false);
    const [filter, setFilter] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const [poofee, setpoofee] = useState(0);

    const [filterlay, setFilterLay] = useState('none');
    useEffect(() => {
        loadlist()
    }, []);
    useEffect(() => {
        loadlist()
    }, [props.data]);
    useEffect(() => {
        loadlist()
    }, [props.active_mode]);
    function nextpercent(accountBalancemctTB, d) {
        return parseFloat((accountBalancemctTB) + "").toFixed(2)
    }
    function nextblock(accountBalancemctTB, d) {
        if (accountBalancemctTB != undefined && accountBalancemctTB != 0) {
            if (accountBalancemctTB.toString().length >= d) {
                return parseFloat(ethers.utils.formatUnits(accountBalancemctTB, d)).toFixed(5)
            } else {
                let cutin = (d - accountBalancemctTB.toString().length) + 2
                return parseFloat(ethers.utils.formatUnits(accountBalancemctTB, d)).toFixed(cutin)
            }
        } else {
            return 0.00
        }
    }
    const excludes = screens["stakepools"].excludes
    async function loadlist() {
        try {
            if (localStorage.getItem("mtk-stkcache") != undefined) {
                setList(JSON.parse(localStorage.getItem("mtk-stkcache")))
            }
            const toofline = {
                "0x9321eeE089364182C2A148FEb493CA0931d4Dab4": true,
                '0x4A51BA1Bb9526aa31b967b06Ec2F3557C3e1d3Cc': true,
                "0x650e5B875035420C872b90cCE0Ee777De30D8C47": true,
                "0xFC765F0C445b8a6436db9D3D9750C3799Bf58Fd0": true,
                "0x025dC95E02e33c711B509485BA9233fb228036c4": true,
                "0x7E6fDfF5487b0e9b97F3B618D3f76410779aA04f": true,
                "0xEe664C9F3Ed8b4479eE4D98f6919bBc50a285082": true,
                "0xC41D0610763dc677F704d6A14009Ca3954E4eA26": true,
                "0xB4C3f82754c97FC43c2f01528BA26E131bd63ec8": true,
                "0x590B534523dDDa612c58Bd05cdEb05bC1DE013ca": true,
                "0x8dEc373bbcA468168c3E0bd2BEb5Bcd66731DDd8": true,
                "0x436080202a6cDAdF2cbf83D6D5dFD33860ecC7cB": true,
                "0x65D95b37Dd46D3Ab60715637aE91E5801AcABf39": true,
                "0xC1122978D8cc23F06B26ade13fAdc10bB16aa0E4": true,
                "0xcc730BB1306e17A6DB7C16a44180F5C1C4B90Cd7": true,

                "0x98DF35D07f55A1e8885c6D23641546E73009001C": true,






              "0x701f1254c5cCb95a09Ed82C6b23e19782fAa47A4": true,
                "0x46b821064370bDaD28eaA2ECdf2629384CECF820": true,
                "0xD15618c89e7B64C2f6dE564B66dd128403C0428B": true,
                
                "0x27A634CaF303ec10D5558e585A3dD6354d8ea389": true,
                "0xAef0332741872259EC50A3cbA1DE82d5790D0bC0": true,
                "0x583D5Ece5e83816689d2FbD0543f6B962474C1fd": true,
                "0x41897De6fCa8B42C7233eB42f27756CE0E85cC9e": true,
                "0xB7d3D8f6D8cCaF58744dF547AAdFFe0CFC48f729": true,
                "0xcfCf1E93c2E38d2F3328cBDA6b4f22410749aF01": true,
            }
            const stakefactory = await Tokencontract(stkfacabi, screens["stakepools"].factory)
            const poofee = await stakefactory.methods.pfee().call()
            setpoofee(poofee)
            const _list = await stakefactory.methods.poolsview().call()
            console.log(screens["stakepools"].factory, _list)
            /*  if (screens["stakepools"].factory == "0x7FD6bb4b075fd4932655F417bFaaf4528D2dbcfb") {
                  _list.push("0x9321eeE089364182C2A148FEb493CA0931d4Dab4")
              }*/
            const hpct = '0x82FE030AE76Dd614FE053ca36b80D49879fF5082'
            const stakeHP = await Tokencontract(stakesabi, hpct)
            const tsamt = nextblock(await stakeHP.methods._totalstaked().call(), 18)
            const rwamt = nextblock(await stakeHP.methods._totalinreward().call(), 18);
            const APR = (rwamt != 0 && tsamt != 0) ? ((rwamt * 100) / tsamt) : 0;



            const token0 = await stakeHP['methods']['BEP20Mtoken']().call()
            const token1 = await stakeHP['methods']['BEP20Rtoken']().call()

            const i0 = await stakeHP['methods']['_icon']().call()
            const i1 = await stakeHP['methods']['_iconb']().call()
            const tokenA = await Tokencontract(abiToken, token0);
            const tokenB = await Tokencontract(abiToken, token1);

            const finishAt = await stakeHP['methods']['finishAt']().call()
            const _isForOnline = toofline["0x82FE030AE76Dd614FE053ca36b80D49879fF5082"] ? false : parseInt(finishAt) >= parseInt(Date.now() / 1000)

            const _t_list = !excludes[hpct] && _isForOnline ? [
                {
                    title: 'Holder Pool',
                    cts: hpct,
                    apr: await stakeHP.methods._FLEXAPR().call(),
                    ts: await stakeHP.methods._totalstaked().call(),
                    ia: i0,
                    i1: i1,
                    act: token0,
                    bct: token1,
                    taName: await tokenA['methods']['symbol']().call(),
                    tbName: await tokenB['methods']['symbol']().call(),
                    min: 0,
                    so: address != undefined ? (await stakeHP.methods.userInfo(address).call()).amount > 0 : false,
                    desc: "",
                    apydesc: "",
                    poofee: poofee,
                    official: 1,
                    online: true
                }

            ] : []
            const of_t_list = (!excludes[hpct] && !_isForOnline) ? [
                {
                    title: 'Holder Pool',
                    cts: hpct,
                    apr: await stakeHP.methods._FLEXAPR().call(),
                    ts: await stakeHP.methods._totalstaked().call(),
                    ia: i0,
                    i1: i1,
                    act: token0,
                    bct: token1,
                    taName: await tokenA['methods']['symbol']().call(),
                    tbName: await tokenB['methods']['symbol']().call(),
                    min: 0,
                    so: address != undefined ? (await stakeHP.methods.userInfo(address).call()).amount > 0 : false,
                    desc: "",
                    apydesc: "",
                    poofee: poofee,
                    official: 1,
                    online: true
                }

            ] : []

            for (let index = 0; index < _list.length; index++) {
                let off = []
                if (!off.includes(index)) {
                    if (!excludes[_list[index]]) {


                        const stake = await Tokencontract(stakesabi, _list[index])

                        const finishAt = await stake['methods']['finishAt']().call()


                        const token0 = await stake['methods']['BEP20Mtoken']().call()
                        const token1 = await stake['methods']['BEP20Rtoken']().call()

                        const i0 = await stake['methods']['_icon']().call()
                        const i1 = await stake['methods']['_iconb']().call()
                        const tokenA = await Tokencontract(abiToken, token0);
                        const tokenB = await Tokencontract(abiToken, token1);
                        const isForOnline = toofline[_list[index]] ? false : parseInt(finishAt) >= parseInt(Date.now() / 1000)
                        try {
                            const obj = {
                                title: '',
                                cts: _list[index],
                                apr: await stake.methods._FLEXAPR().call(),
                                ts: await stake.methods._totalstaked().call(),
                                min: await stake.methods.minStakeAmount().call(),
                                ia: i0,
                                i1: i1,
                                act: token0,
                                bct: token1,
                                taName: await tokenA['methods']['symbol']().call(),
                                tbName: await tokenB['methods']['symbol']().call(),
                                so: address != undefined ? (await stake.methods.userInfo(address).call()).amount > 0 : false,
                                desc: "",
                                apydesc: "",
                                poofee: poofee,
                                official: 1,
                                online: true
                            }
                            if (isForOnline) {
                                _t_list.push(obj)
                            } else {
                                of_t_list.push(obj)
                            }


                        } catch (error) {
                            const obj = {
                                title: '',
                                cts: _list[index],
                                apr: await stake.methods._FLEXAPR().call(),
                                ts: await stake.methods._totalstaked().call(),
                                ia: i0,
                                i1: i1,
                                act: token0,
                                bct: token1,
                                taName: await tokenA['methods']['symbol']().call(),
                                tbName: await tokenB['methods']['symbol']().call(),
                                min: 0,
                                so: address != undefined ? (await stake.methods.userInfo(address).call()).amount > 0 : false,
                                desc: "",
                                apydesc: "",
                                poofee: poofee,
                                official: 1,
                                online: true
                            }
                            if (isForOnline) {
                                _t_list.push(obj)
                            } else {
                                of_t_list.push(obj)
                            }
                        }
                    }
                }
            }

            props.setModal(_t_list[0])
            localStorage.setItem("mtk-stkcache", JSON.stringify(_t_list))
            setList(_t_list)
            setoflist(of_t_list)
            console.log(_t_list, of_t_list)
        } catch (error) {
            console.log(error)
        }

    }

    let stakeHorizontal = <div style={{ width: "100%", margin: "auto", display: "grid", padding: "2vh" }}>
        <div style={{
            height: "min-content",
            width: "100%",
            display: "grid",
            background: "transparent",
            gap: "10px",
            borderRadius: "10px",
            marginTop: "auto",
            color: outFont,

        }}>

            {
                props.active_mode == 0 ?
                    list.map((e, index) =>
                        <div onClick={() => {
                            props.setModal(undefined)
                            setTimeout(() => {
                                props.setModal(e)
                            }, 1000);

                        }} style={{ cursor: "pointer", display: "grid", gridTemplateColumns: "40px 40px auto 40px" }}>
                            <div style={{
                                height: "35px",
                                width: "35px",
                                margin: "auto",
                                borderRadius: "90px",
                                background: "url('" + e.ia + " ') center center / 35px no-repeat",
                            }}>

                            </div>
                            <div style={{
                                height: "35px",
                                width: "35px",
                                margin: "auto",
                                borderRadius: "90px",
                                background: "url('" + e.i1 + " ')  center center / 35px no-repeat",
                            }}>

                            </div>
                            <p style={{ margin: "auto 0", fontSize: "11px" }}>
                                Stake {e.taName}
                                <p /> to Earn {e.tbName}
                            </p>
                            <p style={{ fontSize: "10px", margin: "auto" }}>
                                APR %
                                <p style={{ fontSize: "15px", margin: "auto" }}>
                                    {parseFloat(e.apr).toFixed(2)}
                                </p>
                            </p>
                        </div>)
                    : oflist.map((e, index) =>
                        <div onClick={() => {
                            props.setModal(undefined)
                            setTimeout(() => {
                                props.setModal(e)
                            }, 1000);

                        }} style={{ cursor: "pointer", display: "grid", gridTemplateColumns: "40px 40px auto 40px" }}>
                            <div style={{
                                height: "35px",
                                width: "35px",
                                margin: "auto",
                                borderRadius: "90px",
                                background: "url('" + e.ia + " ') center center / 35px no-repeat",
                            }}>

                            </div>
                            <div style={{
                                height: "35px",
                                width: "35px",
                                margin: "auto",
                                borderRadius: "90px",
                                background: "url('" + e.i1 + " ')  center center / 35px no-repeat",
                            }}>

                            </div>
                            <p style={{ margin: "auto 0", fontSize: "11px" }}>
                                Stake {e.taName}
                                <p /> to Earn {e.tbName}
                            </p>
                            <p style={{ fontSize: "10px", margin: "auto" }}>
                                APR %
                                <p style={{ fontSize: "15px", margin: "auto" }}>
                                    {parseFloat(e.apr).toFixed(2)}
                                </p>
                            </p>
                        </div>)
            }

        </div>

        <p style={{ height: "10vh" }}></p>
    </div>

    return (
        <>
            {<div style={{ border: "1px solid " + Borders, background:modalsColor, marginTop: "25px", borderRadius: "10px", gap: "10px", height: "min-content", width: "100%", display: "grid", paddingTop: "10px", paddingLeft: "10px" }}>
                <p style={{ textAlign: "left", fontSize: "12px" }}>
                    <span style={{ fontSize: "13px", color: outFont, fontWeight: "bold" }}>{"Stakes Pools "}</span>
                    <p />
                    {stakeHorizontal}
                </p>
            </div>}
        </>
    );
}

export default Minimized;