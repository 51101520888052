import React, { useState, useEffect, useContext } from 'react';

import './farm.css';

import bnbabi from "../abis/bnbabi.json";
import Cnfts from "./cnfts";
import nmmktabi from "../abis/mkt.json";
import AppContext from "../framework/helpers/AppContext";
const Marketplace = (props) => {
  const GLOBAL = useContext(AppContext)
  const {
    account,
    provider,
    chain,
    chains,
    web3,
    aweb3,
    Tokencontract,
    gasTX,
    getID,
    callTX,
    sendTX,
    nSendTX,
    nSendCT
  } = useContext(AppContext)
  GLOBAL.provider = aweb3()
  GLOBAL.chain = {
    chainId: getID()
  }
  useEffect(() => {
    //document.title = "MARTIK | MARKETPLACE"
  }, []);

  let titlessss = <div style={{
    width: "100%",
    display: "grid",
    borderRadius: "2px",
    margin: "auto",
    color: "#fff"
  }}>


    {true && <div style={{
      height: "100%",
      width: "100%",
      display: "grid",
      gridTemplateColumns: "repeat(auto-fit, minmax(280px, 1fr)",

      gap: "100px",
    }}>
      <div style={{
        height: "min-content",
        width: "100%",
        padding: "2vh",
        display: "grid",
        margin: "auto"
      }}>
        <Cnfts view={props.view} array={[GLOBAL['provider'], "0xc0Be5e92096059CBA921488199aAa37eA304370D", nmmktabi, "0xb195d56DB980b7128FA5D94Ef912904E5C818f41", bnbabi, "/icons/nlogo.png", "/icons/nlogo.png", [], ["DC", "ALL"], account, 18, 18, true]} />
      </div>

    </div>}

  </div>


  return (
    <div className="farms">

      {titlessss}
    </div>
  );
}

export default Marketplace;