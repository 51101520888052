import React from "react";
import CheckList from "./ListCheck";
import InputBlock from "./InputBlock";
function FilterSale(props) {
    /*
      <div className="flex-col items-center">
            <div className="text-filter">Corrente</div>
            <select className="inpult-select">
                <option >Sem filtro</option>
                <option className="text-black">BSC</option>
                <option className="text-black">Ethereum</option>
                <option className="text-black">Poligono</option>
                <option className="text-black">Avalanche</option>
                <option className="text-black">Fantasma</option>
                <option className="text-black">Cronos</option>
                <option className="text-black">DOGE</option>
                <option className="text-black">AVL</option>
                <option className="text-black">Arbitrum</option>
                <option className="text-black">pulso</option>
            </select>
        </div>
    */

    const {
        setSearchValue,
        setstatusFilter,
        setFilter,
    } = props.opts
    return (
        <div style={{
            margin: "auto",
            width: "100%",
            maxWidth: "1280px",
            display: "grid",
            gap:"10px",
            gridTemplateColumns: window.innerWidth > 700 ? "auto 280px" : "auto",
        }}>
            <InputBlock title={"Search"} place={"Enter the token name or token symbol"} variavel={undefined} setvariavel={setSearchValue} desc={""} />

           
            <div style={{
                margin: "auto",
                width: "100%",
                display: "flex",
        
                gap:"10px",
            }}>
                <CheckList title={"Filter"} untitle={"No filter"} ops={['Comming', 'Sale live', 'Finalized', 'Ended', 'Canceled']} setvariavel={(e) => { setstatusFilter(e) }} />
                <CheckList title={"Order"} untitle={"No filter"} ops={['Hard Cap', 'Soft Cap', 'LP percent', 'Start Time', 'End Time']} setvariavel={(e) => {setFilter(e) }} />
            </div>

        </div>
    );
}

export default FilterSale;