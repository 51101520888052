import { useState } from "react";

const ModalStake = (props) => {
    const [value, setVal] = useState(0)
    const [AllowA, setAllowA] = useState(props.AllowA);

    async function setValue(e) {
        setVal(e)
        await props.getAllowance(setAllowA)

    }

    return (
        <div style={{ color: "#000", height: "100%", width: "100%", position: "fixed", display: "grid", background: "#00000050", top: "0", left: "0", zIndex: "999999999" }} >

            <div style={{ display: "grid", gap: "10px", margin: "auto", width: "360px", height: "min-content", background: "#fff", borderRadius: "10px", padding: "25px" }}>

                <div style={{ display: "grid", gridTemplateColumns: "auto 50px", height: "50px", width: "100%", gap: "5px", margin: "auto", fontSize: "20px", }}>
                    <p style={{ width: "100%", margin: "auto", textAlign: "left", fontSize: "20px", fontWeight: "bold" }}>Stake {props.symbola} tokens</p>
                    <svg onClick={() => { props.sethubtx(false) }} xmlns="http://www.w3.org/2000/svg" style={{ cursor: "pointer", margin: "auto" }} width="25" height="25" fill="#000" className="bi bi-x" viewBox="0 0 16 16">
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                </div>
                <div style={{ display: "grid", gridTemplateColumns: "auto 50px", gap: "5px", height: "100%", width: "100%", margin: "auto" }}>
                    <div style={{
                        height: "40px",
                        width: "100%",
                        margin: "auto",
                        display: "grid",
                        gridTemplateColumns: "35px auto",
                        backgroundColor: "#00000020",
                        borderRadius: "10px"
                    }}>
                        <i style={{
                            height: "100%",
                            width: "100%",
                            margin: "auto",
                            background: "url('" + props.ia + " ')  center center / 50% no-repeat",
                        }}>
                        </i>
                        <div style={{ height: '40px', width: "100%", display: "grid", gridTemplateColumns: "100%" }}>
                            <input type="number" id={props.id} onChange={(e) => setValue(parseFloat(e.target.value))} placeholder='0.0' style={{ backgroundColor: "#00000000", outline: "none", textAlign: "left", border: "none", height: "100%", width: "100%", margin: "auto", borderRadius: "10px", color: "#262626", fontSize: "14px" }} value={value} />
                        </div>
                    </div>
                    <p onClick={() => {
                        setValue((props.nextblock(props.accountBalancemTB, props.deca) - ((props.nextblock(props.accountBalancemTB, props.deca) * 1) / 100)))
                    }} style={{
                        height: "40px",
                        border: "1px solid #00000010",
                        borderRadius: "10px",
                        margin: "auto",
                        width: "100%",
                        display: "grid",
                        cursor: "pointer"
                    }}><p style={{ margin: "auto", fontWeight: "bold" }}>MAX</p></p>
                </div>
                <div style={{ display: "grid", gridTemplateColumns: "auto auto", height: "50px", width: "100%", gap: "5px", margin: "auto", fontSize: "20px", }}>
                    <button onClick={() => { props.sethubtx(false) }} style={{ cursor: "pointer", display: "grid", background: "#00000020", borderRadius: "10px", height: "50px", width: "100%", margin: "auto", color: "#000" }}>
                        <p style={{ margin: "auto" }}>{'Cancel'}</p>
                    </button>
                    {(parseFloat(props.nextblock(AllowA, props.deca)) <= value)
                        ? <button onClick={() => {
                            props.approve(props.tokenA['_address'])
                            props.sethubtx(false)
                        }} style={{ cursor: "pointer", display: "grid", background: "#00000085", borderRadius: "10px", height: "50px", width: "100%", margin: "auto", color: "#fff" }} disabled={!(value > 0)}>
                            <p style={{ margin: "auto" }}>Approve</p>
                        </button>
                        : <button onClick={() => {
                            props.nstake(value)
                            props.sethubtx(false)
                        }} style={{ cursor: "pointer", display: "grid", background: "#00000085", borderRadius: "10px", height: "50px", width: "100%", margin: "auto", color: "#fff" }} disabled={!(value > 0)}>
                            <p style={{ margin: "auto" }}>{'Confirm'}</p>
                        </button>}


                </div>
            </div>


        </div>

    );
}

export default ModalStake;