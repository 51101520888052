import React, { useState, useEffect } from 'react';
import InputBlock from '../../components/Launchpad/InputBlock';
import { ethers } from 'ethers';
import { useNavigate } from "react-router-dom";
import SAFE from '../../components/Launchpad/textpar';
import CheckList from '../../components/Launchpad/ListCheck';
import cakeabi from '../../abis/pankakeRouter.json'
import { syscoin } from 'viem/chains';
const Web3 = require('web3-eth');
const Systoken = "tMTK"
const Sysrpc ="https://rpc.martik.site" // "http://localhost:5500" //
function nextblock(accountBalancemctTB, d) {
    if ((accountBalancemctTB / (10 ** d)).toString().includes('.')) {
        if (accountBalancemctTB.toString().length >= d) {
            return (
                (accountBalancemctTB / (10 ** d)).toString().split('.')[0] +
                '.' +
                (accountBalancemctTB / (10 ** d)).toString().split('.')[1].slice(0, 2)
            );
        } else {
            return (
                '0.' +
                '0'.repeat(d - accountBalancemctTB.toString().length) +
                accountBalancemctTB.toString().slice(0, 2)
            );
        }
    } else {
        if ((accountBalancemctTB / (10 ** d)).toString().includes(',')) {
            if (accountBalancemctTB.toString().length >= d) {
                return (
                    (accountBalancemctTB / (10 ** d)).toString().split(',')[0] +
                    '.' +
                    (accountBalancemctTB / (10 ** d)).toString().split(',')[1].slice(0, 2)
                );
            } else {
                return (
                    '0.' +
                    '0'.repeat(d - accountBalancemctTB.toString().length) +
                    accountBalancemctTB.toString().slice(0, 2)
                );
            }
        } else {
            return accountBalancemctTB / (10 ** d);
        }
    }
}
const TXT = (props) => {
    return (
        <div onClick={() => { props.onClick != undefined && props.onClick() }} style={{ cursor: "pointer", width: "95%", display: "grid", height: "min-content", gridTemplateColumns: "auto auto", margin: "auto", borderBottom: "1px solid #ffffff00" }}><span style={{
            textAlign: "left",
            width: "100%",
            fontWeight: "bold",
            color: "#999",
            fontSize: "14px",
            margin: "auto"
        }}>{props.what} </span><span style={{
            textAlign: "right",
            width: "100%",
            fontSize: "14px",
            color: "#fff",
            margin: "auto"
        }}>{props.result}</span></div>

    );
}
function Address(props) {
    const [data, setData] = useState("");
    const [txList, settxList] = useState([]);
    const [prices, setprice] = useState(0);
    const navigate = useNavigate();
    useEffect(() => {
        getData()
    }, [])

    async function getData() {

        const web3 = new Web3(Sysrpc)
        const block = await web3.getBlockNumber()
        const ether = new ethers.providers.JsonRpcProvider(Sysrpc)
        const address = window.location.pathname.toString().split("/")[window.location.pathname.toString().split("/").length - 1]

        //const txs = await web3.getPastLogs({ fromBlock: block - (block <= 100 ? block : 100), address: address })
        let txs = []
        let blocks = []
        let blk = (block <= 100 ? block : 100)
        for (let index = 0; index < (block <= 100 ? block : 100); index++) {
            if (blk <= 0) {
                break;
            } else {
                const lB = await web3.getBlock(blk)
                for (let i = 0; i < lB.transactions.length; i++) {
                    const tx = await web3.getTransaction(lB.transactions[i])
                    if (tx.from == address || tx.to == address) {
                        txs.push({
                            number: blk,
                            timestamp: lB.timestamp,
                            hash: lB.transactions[i],
                            from: tx.from,
                            to: tx.to
                        })
                    }
                }
                blk -= 1
            }
        }

        settxList(txs)
        //console.log(txs)
        let obj = {
            balance: await web3.getBalance(address)
        }
        //console.log(obj)
        setData(obj)
        price()
    }
    async function price() {
        const web3 = new Web3(new Web3.providers.HttpProvider("https://bsc-dataseed.binance.org"));
        const cake = await new web3.Contract(cakeabi, '0x10ed43c718714eb63d5aa57b78b54704e256024e');
        const p0 = await cake.methods.getAmountsOut("1" + "0".repeat(18), ['0x116526135380E28836C6080f1997645d5A807FAE', "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c"]).call()
        const p1 = await cake.methods.getAmountsOut(p0[1], ["0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c", '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56']).call()
        setprice(p1[1])
    }
    return (
        <div style={{ display: "grid", alignItems: "center", gap: "10px" }}>

            <div style={{ display: "grid", gridTemplateColumns: "repeat(auto-fit, minmax(280px, 1fr)", width: "100%" }}>

                <div style={{ display: "grid", width: "95%", margin: "auto", border: "1px solid #ffffff20", borderRadius: "10px", padding: "10px", gap: "10px" }}>
                    <SAFE what={'Overview'} result={''} />
                    <SAFE what={'Balance:'} result={nextblock(data.balance, 18) + ' ' + Systoken} />
                    <SAFE what={Systoken + ' Value:'} result={'$' + nextblock(nextblock(data.balance, 18) * prices, 18) + ' (@ $' + nextblock(prices, 18) + '/' + Systoken + '  '} />
                    {false && <SAFE what={'Token:'} result={<CheckList title={""} untitle={"Burn"} ops={['Refund', 'Burn']} setvariavel={() => { }} />} />}
                </div>
                <div style={{ display: "grid", width: "95%", margin: "auto", border: "1px solid #ffffff20", borderRadius: "10px", padding: "10px", gap: "10px" }}>
                    <SAFE what={'More Info'} result={''} />
                    <SAFE what={'Name tag'} result={'Not Avaliable'} />
                    <SAFE what={''} result={''} />
                    <SAFE what={''} result={''} />
                </div>

            </div>
            <div style={{ display: "grid", width: "98%", gridTemplateRows: "repeat(auto-fill, minmax(40px, 1fr)", margin: "0 auto", border: "1px solid #ffffff20", borderRadius: "10px", padding: "10px", gap: "10px" }}>

                <SAFE what={'Transactions'} result={''} />
                {txList.length > 0 && txList.map((e) => <TXT onClick={() => { navigate("/explorer/tx/" + e.hash) }} what={
                    <div style={{ display: "grid", width: "min-content", gridTemplateColumns: "auto auto", gap: "5px" }}>
                        <div style={{
                            background: "#222", borderRadius: "5px", display: "grid", width: "40px", height: "40px"
                        }}>
                            <p style={{ margin: "auto", color: "#fff", fontSize: "14px" }}>TX</p>
                        </div>
                        <p style={{ margin: "auto", color: "#fff", fontSize: "14px" }}>
                            {e.hash == null ? "..." : e.hash.slice(0, 4) + '...' + e.hash.slice(-4)}
                            <p />
                            <span style={{ margin: "auto", color: "#262626", fontSize: "10px" }}>

                            </span>
                        </p>
                    </div>

                } result={<p style={{ margin: "auto", color: "#fff", fontSize: "14px" }}>
                    from: {e.from == null ? "..." : e.from.slice(0, 4) + '...' + e.from.slice(-4)}
                    <p />
                    to:  {e.to == null ? "..." : e.to.slice(0, 4) + '...' + e.to.slice(-4)}
                </p>} />)}
            </div>
        </div>
    );

}
function TX(props) {
    const [data, setData] = useState("");
    useEffect(() => {
        getData()
    }, [])

    async function getData() {
        const web3 = new Web3(Sysrpc)
        const address = window.location.pathname.toString().split("/")[window.location.pathname.toString().split("/").length - 1]
        const obj = await web3.getTransaction(address)
        const block = await web3.getBlock(obj.blockNumber)
        obj.timestamp = block.timestamp
        setData(obj)
    }
    return (
        <div style={{ display: "grid", alignItems: "center" }}>
            <TXT what={'Transaction #' + data.hash} result={''} />
            <div style={{ display: "grid", width: "95%", margin: "auto", border: "1px solid #ffffff20", borderRadius: "10px", padding: "10px", gap: "10px", padding: "20px 0px" }}>
                <TXT what={'Transaction Hash:'} result={data.hash} />
                <TXT what={'Block:'} result={data.blockNumber} />
                <TXT what={'Timestamp:'} result={(new Date(data.timestamp * 1000)).toString()} />
                <TXT what={'From:'} result={data.from} />
                {data.to != null && <TXT what={'To:'} result={data.to} />}
                <TXT what={'Value:'} result={nextblock(data.value, 18) + " " + Systoken} />
                <TXT what={'Transaction Fee:'} result={nextblock(data.gas * data.gasPrice, 18)} />
                <TXT what={'Gas Price:'} result={nextblock(data.gasPrice, 18) + " " + Systoken + " (" + ((data.gasPrice / 10 ** 9).toFixed(2) + "Gwei)")} />
                <TXT what={'Nonce:'} result={data.nonce} />
                <TXT what={'Position:'} result={data.transactionIndex} />
                {false && <TXT what={'Input Data:'} result={
                    <div style={{ fontSize: "15px", outline: "none", minHeight: "150px", color: "#fff", textAlign: "left", height: "35px", width: "100%", display: "grid", gridTemplateColumns: "auto", margin: "auto", border: "1px solid #22222250", background: "#00000050", borderRadius: "10px", paddingLeft: "10px" }} disabled={true}>
                        {data.input}
                    </div>} />}
            </div>
        </div>
    );

}
function Block(props) {
    const [data, setData] = useState("");
    useEffect(() => {
        getData()
    }, [])

    async function getData() {
        const web3 = new Web3(Sysrpc)
        const address = window.location.pathname.toString().split("/")[window.location.pathname.toString().split("/").length - 1]
        const obj = await web3.getBlock(address)
        setData(obj)
    }
    return (
        <div style={{ display: "grid", alignItems: "center" }}>
            <TXT what={'Block #' + data.number} result={''} />
            <div style={{ display: "grid", width: "95%", margin: "auto", border: "1px solid #ffffff20", borderRadius: "10px", padding: "10px", gap: "10px", padding: "20px 0px" }}>
                <TXT what={'Block Height:'} result={data.number} />
                <TXT what={'Timestamp:'} result={(new Date(data.timestamp * 1000)).toString()} />
                <TXT what={'Transactions'} result={data.transactions != undefined && data.transactions.length + " transactions in this block"} />
                <TXT what={'Validated by:'} result={data.miner} />
                <TXT what={'Difficulty:'} result={data.difficulty} />
                <TXT what={'Total Difficulty:'} result={data.totalDifficulty} />
                <TXT what={'Size:'} result={data.size + " bytes"} />
                <TXT what={'Gas Used:'} result={data.gasUsed} />
                <TXT what={'Gas Limit:'} result={data.gasLimit} />
                <TXT what={'Nonce:'} result={data.nonce} />
                <TXT what={'Extra Data:'} result={<textarea style={{ fontSize: "15px", outline: "none", minHeight: "150px", color: "#999", textAlign: "left", height: "35px", width: "100%", display: "grid", gridTemplateColumns: "auto", margin: "auto", border: "1px solid #22222250", background: "#00000050", borderRadius: "10px", paddingLeft: "10px" }} disabled={true}>
                    {data.extraData}
                </textarea>} />
            </div>
        </div>
    );

}
function Token(props) {
    const [data, setData] = useState("");
    useEffect(() => {
        getData()
    }, [])

    function getData() {

    }
    return (
        <div style={{ display: "grid", alignItems: "center" }}>

            <p>{data}</p>

        </div>
    );

}
function Explorer() {
    const navigate = useNavigate();
    const [Search, setSearch] = useState("");
    const [BlockList, setBlockList] = useState([]);
    const [txList, settxList] = useState([]);
    const [is, setType] = useState(0); // 0 == nada

    useEffect(() => {
        getData()
    }, [])

    async function getData() {
        // const contract = window.location.pathname.toString().split("/")[window.location.pathname.toString().split("/").length - 1]
        const type = window.location.pathname.toString().split("/")[window.location.pathname.toString().split("/").length - 2]
        setType(type == "explorer" ? 0 :
            type == "address" ? 1 :
                type == "tx" ? 2 :
                    type == "block" ? 3 :
                        type == "token" ? 4 : 0)
        const web3 = new Web3(Sysrpc)
        const lBlock = await web3.getBlockNumber() // isso pega dados do block
        const latest = await web3.getBlock(lBlock)
        let blocks = []
        let blk = lBlock
        for (let index = 0; index < 10; index++) {
            if (blk <= 0) {
                break;
            } else {
                const lB = await web3.getBlock(blk)
                blocks.push({
                    number: blk,
                    timestamp: lB.timestamp,
                    txcount: lB.transactions.length
                })
                blk -= 1
            }
        }
        let txs = []
        for (let index = 0; index < latest.transactions.length; index++) {
            if (index >= 10) {
                break;
            } else {

                const tx = await web3.getTransaction(latest.transactions[index])

                txs.push({
                    hash: latest.transactions[index],
                    from: tx.from,
                    to: tx.to
                })
            }
        }
        setBlockList(blocks)
        settxList(txs)
        //  setSearch(contract == "explorer" ? '' : contract)
    }
    function receiveType(e) {
        if (isAddress(e)) {
            navigate("/explorer/address/" + e)
        } else {
            if (isHash(e)) {
                navigate("/explorer/tx/" + e)
            } else {
                if (isNumber(e)) {
                    navigate("/explorer/block/" + e)
                } else {

                }
            }
        }
    }
    function isAddress(ct) {
        if (ct.length <= 0) {
            return false
        } else {
            if (ct.length == 42) {
                if (ct.includes("0x")) {
                    return true
                } else {
                    return false
                }
            } else {
                return false
            }
        }
    }

    function isHash(addr) {
        return /^0x([A-Fa-f0-9]{64})$/.test(addr);
    }
    function isNumber(addr) {
        if (typeof value === 'number') {
            //it's a number
            return true
        } else {
            return false
        }
    }


    return (
        <div style={{ display: "grid", alignItems: "center", gap: "10px" }}>
            <div style={{ display: "grid", gridTemplateColumns: "repeat(auto-fit, minmax(280px, 1fr)", width: "100%", height: "200px", borderRadius: "10px", background: "#00000020" }}>
                <div></div>
                <InputBlock title={"MARTIK BLOCK EXPLORER"} place={"Search by Address / Txn Hash / Block / Token"} variavel={Search} setvariavel={receiveType} />
            </div>

            {
                is == 0 &&
                <div style={{ display: "grid", alignItems: "center" }}>

                    <div style={{ display: "grid", gridTemplateColumns: "repeat(auto-fit, minmax(280px, 1fr)", width: "100%" }}>

                        <div style={{ display: "grid", gridTemplateRows: "repeat(auto-fill, minmax(40px, 1fr)", width: "95%", margin: "auto", border: "1px solid #ffffff20", borderRadius: "10px", padding: "10px", gap: "15px" }}>
                            <SAFE what={'Latest Blocks'} result={''} />
                            {BlockList.map((e) => <TXT onClick={() => { navigate("/explorer/block/" + e.number) }} what={
                                <div style={{ display: "grid", width: "min-content", gridTemplateColumns: "auto auto", gap: "5px" }}>
                                    <div style={{
                                        background: "#222", borderRadius: "5px", display: "grid", width: "40px", height: "40px"
                                    }}>
                                        <p style={{ margin: "auto", color: "#fff", fontSize: "14px" }}>BK</p>
                                    </div>
                                    <p style={{ margin: "auto", color: "#fff", fontSize: "14px" }}>
                                        {e.number}
                                        <p />
                                        <span style={{ margin: "auto", color: "#262626", fontSize: "10px" }}>
                                            {e.timestamp}
                                        </span>
                                    </p>
                                </div>

                            } result={e.txcount + " TXs"} />)}
                        </div>
                        <div style={{ display: "grid", width: "95%", gridTemplateRows: "repeat(auto-fill, minmax(40px, 1fr)", margin: "0 auto", border: "1px solid #ffffff20", borderRadius: "10px", padding: "10px", gap: "10px" }}>

                            <SAFE what={'Latest transactions'} result={''} />
                            {txList > 0 && txList.map((e) => <TXT onClick={() => { navigate("/explorer/tx/" + e.hash) }} what={
                                <div style={{ display: "grid", width: "min-content", gridTemplateColumns: "auto auto", gap: "5px" }}>
                                    <div style={{
                                        background: "#222", borderRadius: "5px", display: "grid", width: "40px", height: "40px"
                                    }}>
                                        <p style={{ margin: "auto", color: "#fff", fontSize: "14px" }}>TX</p>
                                    </div>
                                    <p style={{ margin: "auto", color: "#fff", fontSize: "14px" }}>
                                        {e.hash.slice(0, 4) + '...' + e.hash.slice(-4)}
                                        <p />
                                        <span style={{ margin: "auto", color: "#262626", fontSize: "10px" }}>

                                        </span>
                                    </p>
                                </div>

                            } result={<p style={{ margin: "auto", color: "#fff", fontSize: "14px" }}>
                                from: {e.from.slice(0, 4) + '...' + e.from.slice(-4)}
                                <p />
                                to:  {e.to.slice(0, 4) + '...' + e.to.slice(-4)}
                            </p>} />)}
                        </div>

                    </div>

                </div>
            }
            {
                is == 1 && <Address data={Search} />
            }
            {
                is == 2 && <TX data={Search} />
            }
            {
                is == 3 && <Block data={Search} />
            }
            {
                is == 4 && <Token data={Search} />
            }

        </div>
    );

}

export default Explorer;