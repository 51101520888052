import React, { useState, useEffect, useContext } from "react";
import { ethers } from 'ethers';
import abiToken from "../../abis/bnbabi.json";

import ModalH from '../p2p/components/modalH';
import AppContext from "../../framework/helpers/AppContext";

const w = window.innerWidth
let stkfacabi = [
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "pool",
                "type": "uint256"
            },
            {
                "internalType": "address",
                "name": "token",
                "type": "address"
            }
        ],
        "name": "_buy",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "priceUNIT",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "amountMTK",
                "type": "uint256"
            },
            {
                "internalType": "address",
                "name": "token",
                "type": "address"
            }
        ],
        "name": "createPool",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "gas",
                "type": "uint256"
            }
        ],
        "name": "getTax",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "pool",
                "type": "uint256"
            },
            {
                "internalType": "address",
                "name": "token",
                "type": "address"
            }
        ],
        "name": "remove",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "tax",
                "type": "uint256"
            }
        ],
        "name": "setTax",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "_tax",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "name": "_tokens",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "name": "inlist",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "owner",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "name": "pools",
        "outputs": [
            {
                "internalType": "contract MartikSale",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "token",
                "type": "address"
            }
        ],
        "name": "poolsview",
        "outputs": [
            {
                "internalType": "contract MartikSale[]",
                "name": "",
                "type": "address[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "tokens",
        "outputs": [
            {
                "internalType": "address[]",
                "name": "",
                "type": "address[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    }
]
let stakesabi = [
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "own",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "priceUNIT",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "amountMTK",
                "type": "uint256"
            },
            {
                "internalType": "address",
                "name": "_tokenA",
                "type": "address"
            }
        ],
        "stateMutability": "payable",
        "type": "constructor"
    },
    {
        "inputs": [],
        "name": "BUSD",
        "outputs": [
            {
                "internalType": "contract IBEP20",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "WBNB",
        "outputs": [
            {
                "internalType": "contract IBEP20",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "__amount",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "__price",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "_buy",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "_owner",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "avaliable",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "factory",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "mtoken",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "owner",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "price",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_priceUNIT",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "_amountMTK",
                "type": "uint256"
            }
        ],
        "name": "priceEstimate",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "address",
                "name": "o",
                "type": "address"
            }
        ],
        "name": "remove",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "router",
        "outputs": [
            {
                "internalType": "contract Router",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "tokenA",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "tokenB",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    }
]

const Web3 = require('web3-eth');
const Stake = (props) => {
    const {
        account,
        provider,
        chain,
        chains,
        web3,
        Tokencontract,
        gasTX,
        getID,
        callTX,
        sendTX,
        nSendTX,
        nSendCT
    } = useContext(AppContext)

    const [address, setAddress] = useState(account);
    const [token, setToken] = useState('0x116526135380E28836C6080f1997645d5A807FAE');
    const [tokenn, setTokenn] = useState('MTK');
    const [modal, setmodal] = useState(false);
    const [tokens, setTokens] = useState([

    ]);

    const [list, setList] = useState([{}]);
    const [poofee, setpoofee] = useState(0);

    useEffect(() => {
        //document.title = "MARTIK | P2P"
        loadlist()
    }, []);

    function nextblock(accountBalancemctTB, d) {
        if (accountBalancemctTB != null) {
            return parseFloat(ethers.utils.formatUnits(accountBalancemctTB, d)).toFixed(parseInt(d / 2))
        }
    }
    /*const nSendTX = async (_contract, _abi, _id, _func, _value, ...args) => {

        const contract = await Tokencontract(stkfacabi, '0x19213da18cA445ea455dB96b5271d61519FdAF5b')
        const value = _value == '0' ? _value : ethers.utils.parseUnits((parseFloat(nextblock(_value, 18)) + (parseFloat(nextblock(_value, 18)) * 1) / 100).toString(), 18).toString()
        const gas = await contract.methods[_func](...args).estimateGas({ from: address, value: value })
        contract.methods[_func](...args)
            .send({
                from: address,
                gas: gas,
                value: _func == 'remove' ? '5000000000000000' : value,
            })
            .then((res) => {

            })
            .catch((err) => {
                alert("Error:  reload the page")
            });

    };*/

    async function loadlist() {
        const stakefactory = await Tokencontract(stkfacabi, '0x19213da18cA445ea455dB96b5271d61519FdAF5b')

        const t_token = tokens
        const toks = await stakefactory.methods.tokens().call()

        for (let index = 0; index < toks.length; index++) {
            try {
                const tk = await Tokencontract(abiToken, toks[index])
                const name = await tk.methods.symbol().call()

                const __list = await stakefactory.methods.poolsview(toks[index]).call()

                const __t_list = []
                let avaliables = 0
                for (let _index = 0; _index < __list.length; _index++) {
                    const stake = await Tokencontract(stakesabi, __list[_index])
                    const price = await stake.methods.__price().call()
                    const avaliable = await stake.methods.avaliable().call()
                    avaliables = avaliables + avaliable
                    __t_list.push(nextblock(price, 18))
                }
                const menor = __t_list.sort((a, b) => a - b)
                if (avaliables > 0) {
                    t_token.push({
                        name: name,
                        ct: toks[index],
                        bestprice: menor.length > 0 ? menor[0] : 0
                    })
                }


            } catch (error) {
                break
            }
        }
        setTokens(t_token)


        const _list = await stakefactory.methods.poolsview(token).call()

        setList([])
        const _t_list = []

        for (let index = 0; index < _list.length; index++) {
            const stake = await Tokencontract(stakesabi, _list[index])
            const obj = {
                title: '',
                cts: _list[index],
                apr: await stake.methods.__price().call(),
                ts: 0, // await stake.methods._totalstaked().call(),
                so: true,// address != undefined ? (await stake.methods.userInfo(address).call()).amount > 0 : false,
                desc: "",
                apydesc: "",
                poofee: 0,
                official: 0,
                online: true
            }
            _t_list.push(obj)
        }

        setList(_t_list)
    }
    async function settk(ct, name) {
        const stakefactory = await Tokencontract(stkfacabi, '0x19213da18cA445ea455dB96b5271d61519FdAF5b')

        const _list = await stakefactory.methods.poolsview(ct).call()
        setList([])
        const _t_list = []
        setTokenn(name)
        setToken(ct)
        for (let index = 0; index < _list.length; index++) {
            const stake = await Tokencontract(stakesabi, _list[index])
            const obj = {
                title: '',
                cts: _list[index],
                apr: await stake.methods.__price().call(),
                ts: 0, // await stake.methods._totalstaked().call(),
                so: true,// address != undefined ? (await stake.methods.userInfo(address).call()).amount > 0 : false,
                desc: "",
                apydesc: "",
                poofee: 0,
                official: 0,
                online: true
            }
            _t_list.push(obj)
        }

        setList(_t_list)
    }
    const Horizontal = () => {
        return (
            <div style={{ width: "100%", margin: "auto",  display: "grid", marginTop: "5px", }}>
                <div style={{
                    height: "min-content",
                    width: "100%",
                    display: "grid",
                    background: "transparent",
                    gap: "10px",
                    borderRadius: "10px",

                    color: "#fff",

                }}>
                    {list.sort((a, b) => a.apr - b.apr).map((e, index) => <ModalH  title={e.title} factoryABI={stkfacabi} facAdress={'0x19213da18cA445ea455dB96b5271d61519FdAF5b'} netId={getID()} nSendTX={nSendTX} tokenname={tokenn} token={token} Tokencontract={Tokencontract} pos={index} desc={e.desc} apr={e.apr} online={e.online} apydesc={e.apydesc} poofee={e.poofee} official={e.official} heighti={"6vh"} bgcolor={"#ffffff20"} buttoncolor={"#ffffff20"} array={['https://data-seed-prebsc-1-s3.binance.org:8545', e.cts, stakesabi, abiToken, address]} />)}
                </div>

                <p style={{ height: "10vh" }}></p>
            </div >
        )
    }


    return (
        <div style={{ display: "grid", gridTemplateColumns: w < 720 ? 'auto' : "280px auto" }}>
            <div style={{
                height: "min-content",
                width: "100%",
                display: "grid",
                background: "transparent",
                gap: "10px",
                borderRadius: "10px",
                marginTop: "15px",
                color: "#fff",

            }}>
                <p style={{ color: "#FFF", fontWeight: "bold", textAlign: "left", padding: "0px 15px" }}>TOKENS IN OFFER</p>
                {tokens.map((e, index) => <div onClick={() => {
                    settk(e.ct, e.name); setmodal(true)
                }} style={{ cursor: "pointer", borderRadius: "10px", display: "grid", gridTemplateColumns: "auto auto", height: "40px", width: "90%", margin: "auto", border: "1px solid #ffffff20" }}>
                    <p style={{ margin: "auto", textAlign: "left" }}>{e.name} / BNB</p>
                    <p style={{ margin: "auto", color: "#28a244", fontWeight: "bold" }}>$ {parseFloat(e.bestprice).toFixed(6)}</p>
                </div>)}

            </div>
            {
                w < 720
                    ? modal && <div style={{ position: "fixed", background: "#00000050", height: "100%", width: "100%", display: "grid", gridTemplateRows: "60px auto" }}>
                        <div style={{ background: "#000000", height: "50px", width: "100%", display: "grid", gridTemplateColumns: "auto 50px" }}>
                            <p style={{ color: "#fff", margin: "auto" }}>
                                ORDERS
                            </p>
                            <svg onClick={() => { setmodal(false) }} xmlns="http://www.w3.org/2000/svg" width="25" height="25" style={{ margin: "auto" }} fill="#fff" class="bi bi-x-lg" viewBox="0 0 16 16">
                                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                            </svg>
                        </div>
                        <Horizontal />
                    </div>
                    : <Horizontal />
            }
        </div>
    );
}

export default Stake;