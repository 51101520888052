import React, { useState, useEffect, Component } from 'react';
import '../../pages/trade.css';
import '../../App.css';
import cakeabi from "../../abis/pankakeRouter.json";
import botabi from "../../abis/botbuyabi.json";
import factoryabi from "../../abis/factoryabi.json";
import miniabi from "../../abis/coin.json";
import bnbabi from "../../abis/bnbabi.json";
import tokenslist from "../../tokens.json";
import { ethers } from 'ethers';

const Web3 = require('web3-eth');
const HDWalletProvider = require('@truffle/hdwallet-provider');
let provider = null
let w3 = null

const Trade = (props) => {

    const {
        account,
        provider,
        chain,
        chains,
        web3,
        Tokencontract,
        gasTX,
        getID,
        callTX,
        sendTX,
        nSendTX,
        nSendCT
    } = props.interact

    const [conerror, setconerror] = useState(true);
    const [texttoconnect, setText] = useState("");
    const [accountBNB, setBNBbalance] = useState(0);
    const [tokenAname, setTokenBname] = useState("MTK");
    const [tokenA, setTokenA] = useState(0);
    const [tokenACT, setTokenACT] = useState("0x116526135380E28836C6080f1997645d5A807FAE");
    const [accountBalancemTA, setAccountBalanceTA] = useState(0);
    const [decimalsTA, setCdecimalsTA] = useState(18);
    const [totalSupplyA, setTotalSupplyA] = useState(0);

    const [AllowA, setAllowA] = useState(0);
    const [amountin, setAmountin] = useState(0);
    const [amountminout, setAmountout] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const [amountinswap, setAmountinSwap] = useState(0);
    const [amountoutswap, setAmountoutSwap] = useState(0);



    useEffect(() => {
        let interval = null;
        interval = setInterval(() => {
            setSeconds(seconds => seconds + 1);
            connectToSelectedNetwork();
        }, 1000);
        //setSeconds(seconds => seconds + 1);
        return () => clearInterval(interval);
    }, [seconds]);


    function getContractAddress() {
        return props.array[1];
    }

    async function connectToSelectedNetwork() {
        if (account !== undefined) {

            const ta = await Tokencontract(bnbabi, tokenACT);
            setTokenA(ta);

            call(ta.methods.totalSupply, setTotalSupplyA);
            call(ta.methods.allowance, setAllowA, account, getContractAddress());
            call(ta.methods.balanceOf, setAccountBalanceTA, account);
            call(ta.methods.decimals, setCdecimalsTA);
        }

    }
    const search = async (e) => {
        //console.log(e)
        if (e.length <= 0) {

        } else {
            if (e.length == 42) {

                if (e.includes("0x")) {

                    const ta = await Tokencontract(bnbabi, ethers.utils.getAddress(e));
                    setTokenA(ta);
                    call(ta.methods.totalSupply, setTotalSupplyA);
                    call(ta.methods.allowance, setAllowA, account, getContractAddress());
                    call(ta.methods.balanceOf, setAccountBalanceTA, account);
                    call(ta.methods.decimals, setCdecimalsTA);
                    call(ta.methods.symbol, setTokenBname);
                    setTokenACT(ethers.utils.getAddress(e))
                    //  setctcatch(ethers.utils.getAddress(e))
                }
            } else {

            }
        }
    }

    // call takes in a function to execute and runs a given callback on the response
    function call(func, callback, ...args) {
        // Trigger the function with the arguments
        func(...args).call()
            .then((result) => {
                // Apply given callback, this is our stateSetters
                callback(result);

                setconerror(false)
            })
            .catch((error) => {
                //throw new Error(error);
                errorpage("Invalid Network");
                setconerror(true)
            })
    }

    function update() {
        setSeconds(0)
        connectToSelectedNetwork()
        setconerror(false)
    }

    function lowfunctionjkljs() {
        nSendTX(getContractAddress(), props.array[2], getID(), 'createPool', '5000000000000000', 0,
            amountoutswap,
            amountinswap,
            tokenACT
        )
            .then(gg => {
                update()
                window.location.href = "/p2p"
            })
            .catch(error => {
            });


    }

    function approveta() {
        nSendTX(tokenA['_address'], bnbabi, getID(), 'approve', '0', 0, getContractAddress(), "1" + "0".repeat(50))
            .then(gg => {
                update()
                setconfirmationtext("Concluded")

            })
            .catch(error => {
                setconfirmationtext(error.message)

            });

    }

    const balancetokenA = () => {
        let value = "";
        if (tokenAname == "BNB") {
            if (account != undefined) {
                const web3 = new Web3(provider);
                web3.getBalance(account)
                    .then(valor => {
                        setBNBbalance(nextblock(valor, decimalsTA))
                    })
                    .catch(error => {
                         //console.log(error) 
                        })
                value = accountBNB;
            }
        } else {
            value = nextblock(accountBalancemTA, decimalsTA)
        }
        return value;
    }

    const defineain = (e) => {
        let array = []
        if (e.toString().includes(".")) {
            array = e.toString().split(".")
            if (e <= 0) {
                setAmountout(0);
                errormenssage("valor digitado invalido")

            } else {
                if (tokenACT == '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c') {
                    setAmountinSwap((array[0].toString() + array[1].toString()) + "0".repeat((decimalsTA - array[1].length)))

                } else {
                    setAmountinSwap((array[0].toString() + array[1].toString()) + "0".repeat((decimalsTA - array[1].length)))
                }
            }

        } else {
            if (e <= 0) {
                errormenssage("valor digitado invalido")

            } else {
                if (tokenACT == '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c') {
                    setAmountinSwap(e.toString() + "0".repeat(decimalsTA))
                } else {
                    setAmountinSwap(e.toString() + "0".repeat(decimalsTA))

                }

            }

        }

        setAmountin(e)
    }

    const defineainusd = (e) => {
        let array = []
        if (e.toString().includes(".")) {
            array = e.toString().split(".")
            if (e <= 0) {
                setAmountout(0);
                errormenssage("valor digitado invalido")

            } else {
                if (tokenACT == '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c') {
                    setAmountoutSwap((array[0].toString() + array[1].toString()) + "0".repeat((decimalsTA - array[1].length)))

                } else {
                    setAmountoutSwap((array[0].toString() + array[1].toString()) + "0".repeat((decimalsTA - array[1].length)))
                }
            }

        } else {
            if (e <= 0) {
                errormenssage("valor digitado invalido")

            } else {
                if (tokenACT == '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c') {
                    setAmountoutSwap(e.toString() + "0".repeat(decimalsTA))
                } else {
                    setAmountoutSwap(e.toString() + "0".repeat(decimalsTA))

                }
            }
        }
        setAmountout(e)
    }

    function errormenssage(e) {

    }

    function errorpage(e) {
        setText(e)
    }

    function textswapjs() {
        let a = null
        if (conerror) {
            a = texttoconnect
        } else {
            a = bnbtextjs()
        }
        return a;
    }



    function bnbtextjs() {
        let a = null
        a = "Post you " + tokenAname;
        return a
    }

    function nextblock(accountBalancemctTB, d) {
        return parseFloat(ethers.utils.formatUnits(accountBalancemctTB, d)).toFixed(4)
    }

    function setconfirmationtext(e) {

    }

    const SAFE = (props) => {
        return (
            <p style={{ width: "95%", display: "grid", gridTemplateColumns: "auto auto", margin: "auto", marginTop: "5px" }}><span style={{
                textAlign: "left",
                width: "100%",
                fontWeight: "bold",
                color: "#ddd",
                fontSize: "11px",

            }}>{props.what} </span><span style={{
                textAlign: "right",
                width: "100%",
                fontSize: "11px",
                color: "#ddd",
            }}>
                    {props.result}
                </span></p>
        );
    }

    let datatransaction = <div className='details' style={{ background: "#111", color: "#888", fontSize: "12px", marginBottom: "10px", height: "min-content" }}>
        <SAFE what={"OFFER DETAILS:"} result={""} />
        <br />
        <SAFE what={"USD per " + tokenAname + ":"} result={nextblock(amountoutswap, decimalsTA) + " " + "USD"} />
        <SAFE what={"" + tokenAname + " OFFER:"} result={nextblock(amountinswap, decimalsTA) + " " + tokenAname} />

    </div>


    let boleta = <div className="Trademainboletam" style={{ minWidth: "280px", background: '#22222210', border: "1px solid #ffffff10", borderRadius: "10px", gap: "10px" }}>
        <div style={{ height: props.heighti, display: "grid" }}>
            <p style={{ margin: "auto", fontSize: "18px", marginTop: "10px" }}>SELL MARTIK IN P2P</p>
        </div>
        <span>
            <h3 style={{ fontSize: "11px", color: "#888" }}>Token</h3>
            <div style={{ gridTemplateColumns: " auto", height: "40px", marginBottom: "10px", borderRadius: "10px" }} >
                <input type="text" style={{ background: "#111", fontSize: "12px", border: "1px solid #ffffff20", borderRadius: "10px", width: "100%", height: "40px" }} placeholder='0x123' onChange={(e) => search(e.target.value)} />
            </div>

            <h3 style={{ fontSize: "10px", textAlign: "left", color: "#888" }}>Token to sell (BNB or WBNB not suported)</h3>
        </span>
        <div style={{ display: "grid", gridTemplateColumns: "auto auto", height: "100px", width: "95% ", margin: "auto" }}>
            <span>
                <h3 style={{ fontSize: "11px", color: "#888" }}>Price</h3>
                <div style={{ gridTemplateColumns: " auto 35px", height: "40px", marginBottom: "10px", borderRadius: "10px" }} >
                    <input type="number" style={{ background: "#111", fontSize: "12px", border: "1px solid #ffffff20", borderRadius: "10px", width: "100%", height: "40px" }} onChange={(e) => defineainusd(e.target.value)} min={0} value={amountminout} />
                    <button style={{ fontSize: "12px", background: "#ffffff00" }}>USD</button>
                </div>

                <h3 style={{ fontSize: "10px", textAlign: "left", color: "#888" }}>Unit price</h3>
            </span>
            <span>
                <h3 style={{ fontSize: "11px", color: "#888" }}>${tokenAname} TO SELLING</h3>
                <div style={{ gridTemplateColumns: " auto 36px", height: "40px", marginBottom: "10px", borderRadius: "10px" }} >
                    <input type="number" style={{ background: "#111", fontSize: "12px", border: "1px solid #ffffff20", borderRadius: "10px", height: "40px" }} onChange={(e) => defineain(e.target.value)} min={0} max={totalSupplyA} value={amountin} />
                    <button style={{ fontSize: "12px", background: "#ffffff00" }} onClick={() => {
                        setAmountin(balancetokenA())
                        defineain(balancetokenA())
                    }}>MAX</button>
                </div>
                <h3 style={{ fontSize: "10px", textAlign: "left", color: "#888" }}>Balance: {balancetokenA()}</h3>
            </span>

        </div>


        <input className="tradebb" type="button" style={{ background: "#111", borderRadius: "10px", height: "50px", fontSize: "12px", maxWidth: "200px" }} onClick={() => {
            { AllowA > 0 ? lowfunctionjkljs() : approveta() }
        }} value={AllowA > 0 ? textswapjs() : "Approve " + tokenAname} />
        {datatransaction}
    </div>
    let botokens = <div className='botokens'>
        {boleta}
    </div>

    let corpo = <div style={{ width: "100%" }} >
        <div style={{ margin: "auto", width: "100%" }}>
            {botokens}
        </div>

    </div >

    return (
        <div >

            {corpo}
            <p>YOU WILL RECEIVE YOUR SALE IN BUSD AS SOON AS ANOTHER PERSON BUYS IN YOUR HAND</p>
            <p>THIS IS P2P !!</p>
        </div>

    );
}

export default Trade;