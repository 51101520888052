import React, { useState, useEffect, useContext } from 'react';
import bnbabi from "../../../abis/bnbabi.json";
import Lockmodal from "./modal";
import LockmodalA from "./modal_a";
import Createmodal from "./lockcreator";
import Updatemodal from "./lockupdate";
import Transfermodal from "./locktransfer";
import facabi from "../../../abis/lock/lock.json";


import AppContext from "../../../framework/helpers/AppContext"; import env from '../../../hooks/data/Config';
import ProjectData from '../../../config';
let projectName = "Martik"
const {
    Name,
    Logo,
    backgroundColor,
    buttonsColor,
    buttonsBorders,
    inputsColor,
    inputsBorders,
    outbuttonsColor,
    Borders,
    outFont,
    innerFont,
    Routers,
    screens
} = ProjectData[window.location.host.split('.')[0] != undefined 
? window.location.host.split('.')[0] == "app" 
? window.location.host.split('.')[0] + "." + window.location.host.split('.')[1] 
: window.location.host.split('.')[0] : "Martik"]
const Web3 = require('web3-eth');



const Lockpools = (props) => {
    const [list, setList] = useState([]);

    const {
        account,
        provider,
        chain,
        chains,
        web3,
        Tokencontract,
        gasTX,
        getID,
        callTX,
        sendTX,
        nSendTX,
        nSendCT
    } = useContext(AppContext)

    useEffect(() => {
        //document.title = "MARTIK | LOCKER"
        loadlist()
    }, []);



    const Views = (props) => {
        return (
            <div style={{ width: "100%", margin: "auto", maxWidth: "1920px", display: "grid", padding: "2vh" }}>
                <div style={{
                    height: "min-content",
                    width: "100%",
                    maxWidth: "1920px",
                    display: "grid",
                    gap: "10px",
                    borderRadius: "10px",
                    marginTop: "auto",
                    color: "#fff",

                }}>
                    {props.elements}
                </div>
                <p style={{ height: "10vh" }}></p>
            </div>
        );
    }
    async function loadlist() {
        setList([])
        const factory = await Tokencontract(facabi, env.CONTRACTS[getID()].lockContract)
        const _list = await factory.methods.locksview().call()
        let _t_list = []
        for (var i = 0; i < _list.length; i++) {
            let obj = {
                pos: i,
                cts: _list[i],
            }
            _t_list.push(obj)
        };
        setList(_t_list)
    }

    let stakeh = <div style={{ width: "100%", margin: "auto", maxWidth: "1920px", display: "grid", padding: "2vh" }}>
        <div style={{ display: "grid", gridTemplateColumns: "20vh auto 20vh", width: "100%", padding: "auto", marginBottom: "5vh" }}>
            <p style={{ textAlign: "left", height: "min-content", padding: "1vh", fontWeight: "bold" }}>{Name} LOCK</p>
            <p></p>
            <div id="walletplace" style={{ display: "grid" }}>

            </div>
        </div>
        <div style={{
            height: "min-content",
            width: "100%",
            maxWidth: "1920px",
            display: "grid",
            gap: "10px",
            borderRadius: "10px",
            marginTop: "auto",
            color: "#fff",

        }}>
            {list.map((e) => <Lockmodal ct={env.CONTRACTS[getID()].lockContract} account={account} Tokencontract={Tokencontract} ctAbi={facabi} Contract={e.cts} tkAbi={bnbabi} />).reverse()}
        </div>
        <p style={{ height: "10vh" }}></p>
        <div style={{ display: "grid", gridTemplateColumns: "20vh auto 20vh", width: "100%", padding: "auto", marginBottom: "5vh" }}>
            <p style={{ textAlign: "left", height: "min-content", padding: "1vh", fontWeight: "bold" }}></p>
            <p></p>
            <div id="walletplace" style={{ display: "grid", gridTemplateColumns: "auto" }}>
                <div id="wallet" style={{ background: "#00FF0080", display: "grid", gridTemplateColumns: "auto" }} className="Wallet" onClick={() => { window.open("/lock/create") }}>
                    <h1>CREATE LOCK</h1>
                </div>
            </div>
        </div>
        <p style={{ height: "10vh" }}></p>
    </div>


    return (
        <div className="farms" style={{ maxWidth: "1024px" }} >
            {props.view == 0 && stakeh}
            {props.view == 2 && <Views elements={<LockmodalA netId={getID} posi={window.location.href.toString().split("?=")[1]} heighti={"8vh"} bgcolor={"#ffffff80"} buttoncolor={"#1e1f26"} ct={env.CONTRACTS[getID()].lockContract} account={account} nSendTX={nSendTX} Tokencontract={Tokencontract} ctAbi={facabi} Contract={(window.location.pathname.toString().split("/")[window.location.pathname.toString().split("/").length - 1])} tkAbi={bnbabi} />} />}
            {props.view == 1 && <Views elements={<Createmodal interact={{
                account,
                provider,
                chain,
                chains,
                web3,
                Tokencontract,
                gasTX,
                getID,
                callTX,
                sendTX,
                nSendTX,
                nSendCT
            }} array={[undefined, env.CONTRACTS[getID()].lockContract, facabi, account]} />} />}
            {props.view == 3 && <Views elements={<Updatemodal interact={{
                account,
                provider,
                chain,
                chains,
                web3,
                Tokencontract,
                gasTX,
                getID,
                callTX,
                sendTX,
                nSendTX,
                nSendCT
            }} posi={window.location.href.toString().split("?=")[1]} heighti={"7vh"} bgcolor={"#ffffff80"} buttoncolor={"#1e1f26"} ct={env.CONTRACTS[getID()].lockContract} account={account} nSendTX={nSendTX} Tokencontract={Tokencontract} ctAbi={facabi} Contract={(window.location.pathname.toString().split("/")[window.location.pathname.toString().split("/").length - 1])} tkAbi={bnbabi} />} />}
            {props.view == 4 && <Views elements={<Transfermodal interact={{
                account,
                provider,
                chain,
                chains,
                web3,
                Tokencontract,
                gasTX,
                getID,
                callTX,
                sendTX,
                nSendTX,
                nSendCT
            }} posi={window.location.href.toString().split("?=")[1]} heighti={"7vh"} bgcolor={"#ffffff80"} buttoncolor={"#1e1f26"} ct={env.CONTRACTS[getID()].lockContract} account={account} nSendTX={nSendTX} Tokencontract={Tokencontract} ctAbi={facabi} Contract={(window.location.pathname.toString().split("/")[window.location.pathname.toString().split("/").length - 1])} tkAbi={bnbabi} />} />}
        </div>
    );
}

export default Lockpools;