import React, { useState, useEffect, useContext } from 'react';
import { ethers } from 'ethers';
import bnbabi from "../../../abis/bnbabi.json";
import Numberinput from "../../../components/Launchpad/numberInput";
import SAFE from "../../../components/Launchpad/textpar";
import InputBlock from "../../../components/Launchpad/InputBlock";
import DescBlock from "../../../components/Launchpad/descBlock";
import Button from "../../../components/Launchpad/Button";
import abifac from "../../../abis/fairlaunch/fairFactory.json";
import abi from "../../../abis/fairlaunch/fairAbi.json";
import AppContext from "../../../framework/helpers/AppContext";
import env from '../../../hooks/data/Config';
const Web3 = require('web3-eth');

const Editor = (props) => {
    const {
        account,
        provider,
        chain,
        chains,
        web3,
        Tokencontract,
        gasTX,
        getID,
        callTX,
        sendTX,
        nSendTX,
        nSendCT
    } = useContext(AppContext)

    const [address, setaddress] = useState(account);

    const [contract, setcontract] = useState(env.CONTRACTS[getID()].fairSaleContract);
    const [startTime, setstartTime] = useState(0);

    //step3
    const [logoURL, setlogoURL] = useState('');
    const [Website, setWebsite] = useState('');
    const [Facebook, setFacebook] = useState('');
    const [Twitter, setTwitter] = useState('');
    const [Github, setGithub] = useState('');
    const [Telegram, setTelegram] = useState('');
    const [Instagram, setInstagram] = useState('');
    const [Discord, setDiscord] = useState('');
    const [Reddit, setReddit] = useState('');
    const [Youtube, setYoutube] = useState('');
    const [Description, setDescription] = useState('');
    //step4

    useEffect(() => {
        getprofile()
    }, [])


    async function Save() {
        const contractSALE = ethers.utils.getAddress(window.location.pathname.toString().split("/")[window.location.pathname.toString().split("/").length - 1])
        nSendTX(contract, abifac, getID(), 'editPAD', '0', 0,
            contractSALE,
            [
                logoURL,
                Website,
                Facebook,
                Twitter,
                Github,
                Telegram,
                Discord,
                Instagram,
                Reddit,
                Youtube,
                Description,
            ]).then(a => {

            }).catch(e => {
                //console.log(e)
            })
    }

    async function getprofile(ct) {
        const contractSALE = ethers.utils.getAddress(window.location.pathname.toString().split("/")[window.location.pathname.toString().split("/").length - 1])
        const fac = await Tokencontract(abifac, contract);
        const a = await fac.methods.profile(contractSALE, 0).call()
        const b = await fac.methods.profile(contractSALE, 1).call()
        const c = await fac.methods.profile(contractSALE, 2).call()
        const d = await fac.methods.profile(contractSALE, 3).call()
        const e = await fac.methods.profile(contractSALE, 4).call()
        const f = await fac.methods.profile(contractSALE, 5).call()
        const g = await fac.methods.profile(contractSALE, 6).call()
        const h = await fac.methods.profile(contractSALE, 7).call()
        const i = await fac.methods.profile(contractSALE, 8).call()
        const j = await fac.methods.profile(contractSALE, 9).call()
        const k = await fac.methods.profile(contractSALE, 10).call()
        setlogoURL(a)
        setWebsite(b)
        setFacebook(c)
        setTwitter(d)
        setGithub(e)
        setTelegram(f)
        setDiscord(g)
        setInstagram(h)
        setReddit(i)
        setYoutube(j)
        setDescription(k)

    }

    return (
        <div style={{ height: "min-content", padding: "20px 10px", width: "100%", border: "1px solid #ffffff10", borderRadius: "10px" }}>
            <div style={{ display: "grid", gap: "10px" }}>
                <div style={{ height: "min-content", width: "100%", display: "grid", gridTemplateColumns: "auto auto", gap: "8px", margin: "auto" }}>
                    <InputBlock title={"Logo URL *"} place={"https://..."} variavel={logoURL} setvariavel={setlogoURL} desc={""} />
                    <InputBlock title={"Website *"} place={"https://..."} variavel={Website} setvariavel={setWebsite} desc={""} />
                </div>
                <div style={{ height: "min-content", width: "100%", display: "grid", gridTemplateColumns: "auto auto", gap: "8px", margin: "auto" }}>
                    <InputBlock title={"Facebook"} place={"https://..."} variavel={Facebook} setvariavel={setFacebook} desc={""} />
                    <InputBlock title={"Twitter"} place={"https://..."} variavel={Twitter} setvariavel={setTwitter} desc={""} />
                </div>
                <div style={{ height: "min-content", width: "100%", display: "grid", gridTemplateColumns: "auto auto", gap: "8px", margin: "auto" }}>
                    <InputBlock title={"Github"} place={"https://..."} variavel={Github} setvariavel={setGithub} desc={""} />
                    <InputBlock title={"Telegram"} place={"https://..."} variavel={Telegram} setvariavel={setTelegram} desc={""} />
                </div>
                <div style={{ height: "min-content", width: "100%", display: "grid", gridTemplateColumns: "auto auto", gap: "8px", margin: "auto" }}>
                    <InputBlock title={"Instagram"} place={"https://..."} variavel={Instagram} setvariavel={setInstagram} desc={""} />
                    <InputBlock title={"Discord"} place={"https://..."} variavel={Discord} setvariavel={setDiscord} desc={""} />
                </div>
                <InputBlock title={"Reddit"} place={"https://..."} variavel={Reddit} setvariavel={setReddit} desc={""} />
                <InputBlock title={"Youtube Video"} place={"https://..."} variavel={Youtube} setvariavel={setYoutube} desc={""} />
                <DescBlock title={"Description"} place={""} variavel={Description} setvariavel={setDescription} desc={""} />


                <div style={{ height: "min-content", width: "auto", display: "grid", gridTemplateColumns: "auto auto", gap: "2px", margin: "auto" }}>
                    <Button func={() => { Save() }} text={'Save'} next={true} />
                </div>


            </div>
        </div>

    );

}
export default Editor;