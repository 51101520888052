export default {
    "martik": [
        {
            "title": <p>
                INTRODUCTION
            </p>,
            "content": <p>
                With advances in technology and digital resources, the globalized world by continuous transformations, which has given rise in the financial market to the accelerating transactions in the most diverse phases of production, profit, competition and a constant evolution of digital assets, which can be used in different ways, such as, as a store of value, investment or means transactional. It is notable that the growing cryptocurrency market is becoming more and more known worldwide, as many companies will already issue their own coins, often called tokens, and these can be traded specifically for the good or service that the company provides. Thus, considering this “crypto-assets market”, the tokens that are built on blockchains which is a decentralized method spread across many computers that manage and record transactions. In this conditions I present to you the MARTIK project, the best of the DeFi ecosystem. The project team will make every effort to achieve the targets established in this Whitepaper, our proposal is to adapt elements to achieve profitability to token holders.
            </p>
        },
        {
            "title": <p>
                PURPOSE
            </p>,
            "content": <p>
                We at the MARTIK project believe in the evolution of the economy Through technology, we understand the progress of digital assets in potentialization of this new decentralized financial system. By investing in MARTIK project, investors will profit from the exchange rate appreciation that occurs naturally with the entry of new holders to the project, causing a immense appreciation in the medium and long term on your investment, in addition to other advantages and gains through NTFs, launchpad, games, referral programs, payment and transfer system with 0% fee and single NFT system.
            </p>
        },
        {
            "title": <p>
                TECHNOLOGY AND SECURITY
            </p>,
            "content": <p>
                Blockchain Technology is the basis of digital assets, which has been emerging potential in projects and adaptations with positive results in several sectors of the different niche markets. The Binance Smart Chain - BSC blockchain is built to run applications based on smart contracts. BSC works in parallel with Binance Chain - BC, native to Binance, but which did not allow the functionality of contracts smart. Blockchain allows users to get the best of both worlds. The high ability to transact Binance Chain and the functionality of contracts smart, yet Binance Smart Chain - BSC is a fully independent, which can continue to function even if Binance Chain is offline. The purpose of the platform is to allow developers to build applications (dApps), to give users the ability to manage your digital assets between different blockchains, all done very quickly. The MARTIK project uses the Binance Smart Chain – BSC blockchain guaranteeing great rates of fees and the best celerity in the market to the investors, in addition to its security that is very resistant to attacks.
            </p>
        },

        {
            "title": <p>
                TECHNICAL INFORMATION AND MECHANISMS
            </p>,
            "content": <p>
                Smart Contract: 0x116526135380E28836C6080f1997645d5A807FAE <p />
                <br />
                Token name: MARTIK<p />
                <br />
                Token symbol: MTK<p />
                <br />
                Token decimal: 18<p />
                <br />
                Total supply: 10.000<p />
                <br />
                Network: Binance Smart Chain - BSC<p />
                <br />
                Standard: BEP20<p />
            </p>
        },

    ],
    "xshiba": [
        {
            "title": <p>
                Executive Summary
            </p>,
            "content": <p>
                Project xshiba is an initiative aimed at creating a truly decentralized cryptocurrency driven by the community, with a 10% transaction fee directed entirely to the burn wallet, making the token increasingly scarce. The name "Xtiger" represents the idea of "X-change" (exchange) and "I-hub" (innovation hub), reflecting our commitment to creating a platform where community members can exchange value and innovation collaboratively and equitably.
                <br />
                xshiba is a blockchain-based cryptocurrency that will be governed and managed democratically by token holders. It strives to provide effective, accessible, and secure payment solutions for communities worldwide.
                <br />
                This whitepaper details the objectives, technology, governance, and vision of Project xshiba, demonstrating our commitment to transparency and inclusion throughout the process.
            </p>

        },
        {
            "title": <p>
                Table of Contents
            </p>,
            "content": <p>

            </p>
        },
        {
            "title": <p>
                Introduction
            </p>,
            "content": <p>
                1.1 Context and Motivation <p />
                1.2 Project Objectives <p />
            </p>
        },
        {
            "title": <p>
                xshiba Technology
            </p>,
            "content": <p>
                2.1 Blockchain and Consensus <p />
                2.2 Technical Architecture <p />
                2.3 Smart Contracts <p />
            </p>
        },
        {
            "title": <p>
                xshiba Economy
            </p>,
            "content": <p>
                3.1 Total Token Supply <p />
                3.2 Initial Distribution <p />
                3.3 Incentives for Participants <p />
            </p>
        },
        {
            "title": <p>
                Community Governance
            </p>,
            "content": <p>
                4.1 Decision-Making <p />
                4.2 Proposals and Voting <p />
                4.3 Continuous Improvement <p />
            </p>
        },
        {
            "title": <p>
                Growth and Adoption
            </p>,
            "content": <p>
                5.1 Marketing Strategies <p />
                5.2 Integration with Services and Commerce <p />
                5.3 Strategic Partnerships <p />
            </p>
        },
        {
            "title": <p>
                Security and Privacy
            </p>,
            "content": <p>
                6.1 Security Measures <p />
                6.2 Data Protection <p />
            </p>
        },
        {
            "title": <p>
                Introduction
            </p>,
            "content": <p>
                Context and Motivation<p />
                xshiba emerges as a response to the growing need for a truly decentralized cryptocurrency governed by the community. Many cryptocurrencies are controlled by centralized entities or corporations, which can limit equity and transparency. Xtiger aims to empower the global community by offering an accessible, secure, and democratically governed digital currency.<p />
                <br />
                Project Objectives<p />
                The main objectives of Project xshiba are:<p />
                <br />
                Decentralization:<p /> Establish a truly decentralized blockchain network without centralized control by any entity or organization.<p />
                <br />
                Community Governance:<p /> Enable xshiba token holders to actively participate in governance and decision-making regarding the cryptocurrency's future.<p />
                <br />
                Accessibility:<p /> Provide an affordable payment solution for communities worldwide, regardless of their geographic location or economic status.<p />
                <p />      <br />
                Innovation: <p />Encourage innovation and the development of applications and services using Xtiger as a means of exchange.
            </p>

        },
        {
            "title": <p>
                xshiba Technology
            </p>,
            "content": <p>
                Blockchain and Consensus
                xshiba utilizes an open-source blockchain based on a Proof of Stake (PoS) consensus algorithm to ensure scalability and efficiency. This algorithm allows token holders to participate in transaction validation and network security.
                <br />
                Technical Architecture
                xshiba's architecture is highly modular and designed to facilitate interoperability with other blockchains and services. It is based on a layered framework that includes the consensus layer, smart contract layer, and application layer.
                <br />
                Smart Contracts
                Xtxshibaiger supports smart contracts that can be used to create various decentralized applications (dApps). This opens up possibilities for financial services, gaming, digital identity, and more.
            </p>

        },
        {
            "title": <p>
                xshiba Economy
            </p>,
            "content": <p>
                Total Token Supply
                The total supply of xshiba tokens is limited to a fixed number of units with a supply of 100,000, with over 94% burned and a 10% fee applied to each transaction, sending tokens to the burn address to ensure scarcity and potential long-term value appreciation.
                <br />
                Initial Distribution
                The initial token distribution will be conducted through a public sale process, ensuring broad distribution and avoiding excessive token concentration in the hands of a few.
                <br />
                Incentives for Participants
                Incentives such as staking and validation rewards will be offered to token holders who contribute to network security and stability.
            </p>

        },
        {
            "title": <p>
                Community Governance
            </p>,
            "content": <p>
                Decision-Making
                Important decisions related to xshiba will be made through voting by token holders, ensuring truly democratic governance.
                <br />
                Proposals and Voting
                Token holders can propose improvements, changes, or development initiatives through a transparent proposal and voting system.
                <br />
                Continuous Improvement
                xshiba is committed to evolving continuously, responding to community needs, and incorporating technical and governance improvements.
            </p>

        },
        {
            "title": <p>
                Growth and Adoption
            </p>,
            "content": <p>
                Marketing Strategies
                Marketing strategies will be implemented to increase awareness of xshiba and promote its adoption in various communities and sectors.
                <br />
                Integration with Services and Commerce
                xshiba will seek partnerships with companies and services that accept the cryptocurrency as a form of payment, encouraging its use in commerce and daily transactions.
                <br />
                Strategic Partnerships
                Collaboration with other organizations and blockchain projects will be encouraged to promote growth and interoperability.
            </p>

        },
        {
            "title": <p>
                Security and Privacy
            </p>,
            "content": <p>
                Security Measures
                Stringent security measures, including regular smart contract audits and protocol updates, will be implemented to mitigate risks.
                <br />
                Data Protection
                xshiba values user privacy and will implement solutions to protect the personal and financial information of token holders.
            </p>

        },
        {
            "title": <p>
                Conclusio
            </p>,
            "content": <p>
                Project xshiba aims to create a truly community-driven cryptocurrency that promotes decentralization, inclusion, and innovation. We are committed to building a platform where the community can actively participate in governance and development, ensuring a digital currency that meets the needs of global communities.
                <br />
                Join us on this journey to create a fairer and more inclusive financial ecosystem for all.
            </p>

        },


    ],
    "xtiger": [
        {
            "title": <p>
                Executive Summary
            </p>,
            "content": <p>
                Project Xtiger is an initiative aimed at creating a truly decentralized cryptocurrency driven by the community, with a 10% transaction fee directed entirely to the burn wallet, making the token increasingly scarce. The name "Xtiger" represents the idea of "X-change" (exchange) and "I-hub" (innovation hub), reflecting our commitment to creating a platform where community members can exchange value and innovation collaboratively and equitably.
                <br />
                Xtiger is a blockchain-based cryptocurrency that will be governed and managed democratically by token holders. It strives to provide effective, accessible, and secure payment solutions for communities worldwide.
                <br />
                This whitepaper details the objectives, technology, governance, and vision of Project Xtiger, demonstrating our commitment to transparency and inclusion throughout the process.
            </p>

        },
        {
            "title": <p>
                Table of Contents
            </p>,
            "content": <p>

            </p>
        },
        {
            "title": <p>
                Introduction
            </p>,
            "content": <p>
                1.1 Context and Motivation <p />
                1.2 Project Objectives <p />
            </p>
        },
        {
            "title": <p>
                Xtiger Technology
            </p>,
            "content": <p>
                2.1 Blockchain and Consensus <p />
                2.2 Technical Architecture <p />
                2.3 Smart Contracts <p />
            </p>
        },
        {
            "title": <p>
                Xtiger Economy
            </p>,
            "content": <p>
                3.1 Total Token Supply <p />
                3.2 Initial Distribution <p />
                3.3 Incentives for Participants <p />
            </p>
        },
        {
            "title": <p>
                Community Governance
            </p>,
            "content": <p>
                4.1 Decision-Making <p />
                4.2 Proposals and Voting <p />
                4.3 Continuous Improvement <p />
            </p>
        },
        {
            "title": <p>
                Growth and Adoption
            </p>,
            "content": <p>
                5.1 Marketing Strategies <p />
                5.2 Integration with Services and Commerce <p />
                5.3 Strategic Partnerships <p />
            </p>
        },
        {
            "title": <p>
                Security and Privacy
            </p>,
            "content": <p>
                6.1 Security Measures <p />
                6.2 Data Protection <p />
            </p>
        },
        {
            "title": <p>
                Introduction
            </p>,
            "content": <p>
                Context and Motivation<p />
                Xtiger emerges as a response to the growing need for a truly decentralized cryptocurrency governed by the community. Many cryptocurrencies are controlled by centralized entities or corporations, which can limit equity and transparency. Xtiger aims to empower the global community by offering an accessible, secure, and democratically governed digital currency.<p />
                <br />
                Project Objectives<p />
                The main objectives of Project Xtiger are:<p />
                <br />
                Decentralization:<p /> Establish a truly decentralized blockchain network without centralized control by any entity or organization.<p />
                <br />
                Community Governance:<p /> Enable Xtiger token holders to actively participate in governance and decision-making regarding the cryptocurrency's future.<p />
                <br />
                Accessibility:<p /> Provide an affordable payment solution for communities worldwide, regardless of their geographic location or economic status.<p />
                <p />      <br />
                Innovation: <p />Encourage innovation and the development of applications and services using Xtiger as a means of exchange.
            </p>

        },
        {
            "title": <p>
                Xtiger Technology
            </p>,
            "content": <p>
                Blockchain and Consensus
                Xtiger utilizes an open-source blockchain based on a Proof of Stake (PoS) consensus algorithm to ensure scalability and efficiency. This algorithm allows token holders to participate in transaction validation and network security.
                <br />
                Technical Architecture
                Xtiger's architecture is highly modular and designed to facilitate interoperability with other blockchains and services. It is based on a layered framework that includes the consensus layer, smart contract layer, and application layer.
                <br />
                Smart Contracts
                Xtiger supports smart contracts that can be used to create various decentralized applications (dApps). This opens up possibilities for financial services, gaming, digital identity, and more.
            </p>

        },
        {
            "title": <p>
                Xtiger Economy
            </p>,
            "content": <p>
                Total Token Supply
                The total supply of Xtiger tokens is limited to a fixed number of units with a supply of 100,000, with over 94% burned and a 10% fee applied to each transaction, sending tokens to the burn address to ensure scarcity and potential long-term value appreciation.
                <br />
                Initial Distribution
                The initial token distribution will be conducted through a public sale process, ensuring broad distribution and avoiding excessive token concentration in the hands of a few.
                <br />
                Incentives for Participants
                Incentives such as staking and validation rewards will be offered to token holders who contribute to network security and stability.
            </p>

        },
        {
            "title": <p>
                Community Governance
            </p>,
            "content": <p>
                Decision-Making
                Important decisions related to Xtiger will be made through voting by token holders, ensuring truly democratic governance.
                <br />
                Proposals and Voting
                Token holders can propose improvements, changes, or development initiatives through a transparent proposal and voting system.
                <br />
                Continuous Improvement
                Xtiger is committed to evolving continuously, responding to community needs, and incorporating technical and governance improvements.
            </p>

        },
        {
            "title": <p>
                Growth and Adoption
            </p>,
            "content": <p>
                Marketing Strategies
                Marketing strategies will be implemented to increase awareness of Xtiger and promote its adoption in various communities and sectors.
                <br />
                Integration with Services and Commerce
                Xtiger will seek partnerships with companies and services that accept the cryptocurrency as a form of payment, encouraging its use in commerce and daily transactions.
                <br />
                Strategic Partnerships
                Collaboration with other organizations and blockchain projects will be encouraged to promote growth and interoperability.
            </p>

        },
        {
            "title": <p>
                Security and Privacy
            </p>,
            "content": <p>
                Security Measures
                Stringent security measures, including regular smart contract audits and protocol updates, will be implemented to mitigate risks.
                <br />
                Data Protection
                Xtiger values user privacy and will implement solutions to protect the personal and financial information of token holders.
            </p>

        },
        {
            "title": <p>
                Conclusion
            </p>,
            "content": <p>
                Project Xtiger aims to create a truly community-driven cryptocurrency that promotes decentralization, inclusion, and innovation. We are committed to building a platform where the community can actively participate in governance and development, ensuring a digital currency that meets the needs of global communities.
                <br />
                Join us on this journey to create a fairer and more inclusive financial ecosystem for all.
            </p>

        },


    ]
}