import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import tkAbi from '../../abis/bnbabi.json';
import ctAbi from '../../abis/lock/lock.json';
import { ethers } from 'ethers';

const TokenNomics = (props) => {
    const [_ct, setCt] = useState("");
    const [symbola, setsymbolA] = useState("");
    const [deca, setdecA] = useState("");
    const [SupplyA, setSupplyA] = useState("");


    const [chartData, setChartData] = useState({
        series: [44, 55, 13, 43, 22],
        options: {
            chart: {
                width: 380,
                type: 'pie',
            },
            labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E'],
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        },
    }
    )

    useEffect(() => {
        getdata()
    }, []);

    async function getdata() {
        const ct = await props.Tokencontract(ctAbi, props.ct);
        const tkdata = await gettokendataA(props.Contract)
        let obj = {
            series: [tkdata.burn],
            options: {
                chart: {
                    width: 380,
                    type: 'pie',
                },
                labels: ['Dead'],
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            },
        }
        let totalLock = tkdata.burn
        for (let index = 0; index < 10; index++) {
            try {
                const o = await ct.methods.ContractLock(props.Contract, index).call();
                const data = await ct.methods.Locks(o, props.Contract).call();
                const amount = nextblock(data.amount, tkdata.decimal)
                totalLock += parseFloat(amount)
                obj.series.push(parseFloat(amount));
                obj.options.labels.push(data.name);
            } catch (error) {
                break;
            }
        }
        try {
            if (props.obj.listRate != undefined) {
                const listRate = parseFloat(nextblock(props.obj.listRate, tkdata.decimal))
                totalLock += listRate
                obj.series.push(listRate);
                obj.options.labels.push("To liquidity");
            }
            if (props.obj.cts != undefined) {
                const _pre = parseFloat(nextblock(tkdata.pre, tkdata.decimal))
                totalLock += _pre
                obj.series.push(_pre);
                obj.options.labels.push("Sale");
            }
        } catch (error) {
            
        }
      
        if (props.external != undefined) {
            for (let index = 0; index < props.external.length; index++) {
                try {
                    const amount = parseFloat(nextblock(props.external[index].value, tkdata.decimal))
                    totalLock += amount
                    obj.series.push(amount);
                    obj.options.labels.push(props.external[index].what);
                } catch (error) {
                    break;
                }
            }
        }


        /* quanto foi disponibilizado pra pre e o quanto vai pra liquidez */
        let totalFree = (tkdata.Supply - totalLock).toFixed(2)
        obj.series.push(parseFloat(totalFree));
        obj.options.labels.push("Free");
        setChartData(obj)

        setCt(ct)


    }

    async function gettokendataA(e) {
        const token = await props.Tokencontract(tkAbi, e);
        const a = await token.methods.symbol().call()
        const t = await token.methods.decimals().call()
        const s = await token.methods.totalSupply().call()
        const zero = await token.methods.balanceOf('0x000000000000000000000000000000000000dEaD').call()
        let pre
        try {
            pre = await token.methods.balanceOf(props.obj.cts).call()
        } catch (error) {

        }

        setsymbolA(a)
        setdecA(t)
        setSupplyA(s)
        return {
            decimal: t,
            Supply: parseFloat(nextblock(s, t)),
            burn: parseFloat(nextblock(zero, t)),
            pre: pre
        }
    }
    function nextblock(accountBalancemctTB, d) {
        return accountBalancemctTB == undefined ? 0 : parseFloat(ethers.utils.formatUnits(accountBalancemctTB, d)).toFixed(2)
    }
    return (
        <div className="dark:shadow-md dark:shadow-slate-500 rounded-xl dark:bg-[#212b36] bg-white shadow-xl w-full mt-8">
            <div id="chart" >
                <ReactApexChart options={chartData.options} series={chartData.series} type="pie" width={280} />
            </div>
        </div>
    )
}
export default TokenNomics;