import React, { useState, useEffect, useContext } from "react";
import { ethers } from 'ethers';
import abiToken from "../../abis/bnbabi.json";
import stakesabi from "../../abis/stakesn.json";
import stkfacabi from "../../abis/stakefactory.json";
import Modal from '../stake/components/modal';
import ModalH from '../stake/components/modalH';
import AppContext from "../../framework/helpers/AppContext";

const Web3 = require('web3-eth');
const CheckStake = (props) => {

    const {
        account,
        provider,
        chain,
        chains,
        web3,
        Tokencontract,
        gasTX,
        getID,
        callTX,
        sendTX,
        nSendTX,
        nSendCT
    } = useContext(AppContext)

    const [address, setAddress] = useState(account);

    const [list, setList] = useState([]);
    const [disable_list, setDisable_list] = useState([{}]);
    const [list_mode, setListMode] = useState(0);
    const [active_mode, setactiveMode] = useState(0);
    const [only_staked_mode, setOnly_Staked_Mode] = useState(false);
    const [filter, setFilter] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const [poofee, setpoofee] = useState(0);
    const [filterlay, setFilterLay] = useState('none');
    useEffect(() => {
        loadlist()
    }, []);
    useEffect(() => {
        loadlist()
    }, [props.data]);
    function nextpercent(accountBalancemctTB, d) {
        return parseFloat((accountBalancemctTB) + "").toFixed(2)
    }

    function nextblock(accountBalancemctTB, d) {
        return parseFloat(ethers.utils.formatUnits(accountBalancemctTB, d)).toFixed(8)
    }
    const excludes = {
        '0x4A51BA1Bb9526aa31b967b06Ec2F3557C3e1d3Cc': true,
        "0x650e5B875035420C872b90cCE0Ee777De30D8C47": true,
        "0xFC765F0C445b8a6436db9D3D9750C3799Bf58Fd0": true,
        "0x025dC95E02e33c711B509485BA9233fb228036c4": true,
    }

    async function loadlist() {
        try {
            const stakefactory = await Tokencontract(stkfacabi, '0xe162385504374c367D0D3DA57Af3c3E0cA1595Da')
            const _list = await stakefactory.methods.poolsview().call()
            const stakeHP = await Tokencontract(stakesabi, '0x82FE030AE76Dd614FE053ca36b80D49879fF5082')
            const tsamt = nextblock(await stakeHP.methods._totalstaked().call(), 18)
            const rwamt = nextblock(await stakeHP.methods._totalinreward().call(), 18);
            const APR = (rwamt != 0 && tsamt != 0) ? ((rwamt * 100) / tsamt) : 0;
            const _t_list = [
                {
                    title: 'Holder Pool',
                    cts: '0x82FE030AE76Dd614FE053ca36b80D49879fF5082',
                    apr: APR,//
                    ts: await stakeHP.methods._totalstaked().call(),
                    ia: await stakeHP['methods']['_icon']().call(),
                    i1: await stakeHP['methods']['_iconb']().call(),
                    taName: "MTK",
                    tbName: "MTK",
                }
            ]

            for (let index = 0; index < _list.length; index++) {
                let off = [0]
                if (!off.includes(index)) {
                    if (!excludes[_list[index]]) {
                        const stake = await Tokencontract(stakesabi, _list[index])

                        const token0 = await stake['methods']['BEP20Mtoken']().call()
                        const token1 = await stake['methods']['BEP20Rtoken']().call()

                        const i0 = await stake['methods']['_icon']().call()
                        const i1 = await stake['methods']['_iconb']().call()
                        const tokenA = await Tokencontract(abiToken, token0);
                        const tokenB = await Tokencontract(abiToken, token1);
                        const obj = {
                            title: '',
                            cts: _list[index],
                            apr: await stake.methods._FLEXAPR().call(),
                            ts: await stake.methods._totalstaked().call(),
                            ia: i0,
                            i1: i1,
                            act: token0,
                            bct: token1,
                            taName: await tokenA['methods']['symbol']().call(),
                            tbName: await tokenB['methods']['symbol']().call(),
                        }
                        _t_list.push(obj)
                    }
                }
            }
            let newlist = []
            for (let index = 0; index < _t_list.length; index++) {
                if (_t_list[index].act == props.data) {
                    newlist.push(_t_list[index])
                }
            }

            setList(newlist)
        } catch (error) {
            //console.log(error)
        }

    }

    let stakeHorizontal = <div style={{ width: "100%", margin: "auto", display: "grid", padding: "2vh" }}>
        <div style={{
            height: "min-content",
            width: "100%",
            display: "grid",
            background: "transparent",
            gap: "10px",
            borderRadius: "10px",
            marginTop: "auto",
            color: "#fff",

        }}>

            {
                list.map((e, index) =>
                    <div style={{ display: "grid", gridTemplateColumns: "40px 40px auto 40px" }}>
                        <div style={{
                            height: "35px",
                            width: "35px",
                            margin: "auto",
                            borderRadius: "90px",
                            background: "url('" + e.ia + " ') center center / 35px no-repeat",
                        }}>

                        </div>
                        <div style={{
                            height: "35px",
                            width: "35px",
                            margin: "auto",
                            borderRadius: "90px",
                            background: "url('" + e.i1 + " ')  center center / 35px no-repeat",
                        }}>

                        </div>
                        <p style={{ margin: "auto 0" }}>
                            Stake {e.taName}
                            <p/> to Earn {e.tbName}
                        </p>
                        <p style={{ fontSize: "10px", margin: "auto" }}>
                            APR %
                            <p style={{ fontSize: "15px", margin: "auto" }}>
                                {e.apr}
                            </p>
                        </p>
                    </div>)
            }

        </div>

        <p style={{ height: "10vh" }}></p>
    </div>

    return (
        <>
            {list.length > 0 && <div style={{ border: "1px solid #ffffff10", borderRadius: "10px", gap: "10px", height: "min-content", width: "100%", display: "grid", paddingTop: "10px", paddingLeft: "10px" }}>
                <p style={{ textAlign: "left", fontSize: "12px" }}>
                    <span style={{ fontSize: "13px", color: "#fff", fontWeight: "bold" }}>{"Stakes Pools "}</span>
                    <p />
                    {stakeHorizontal}
                </p>
            </div>}
        </>
    );
}

export default CheckStake;