import { useState } from "react";

const ModalRemove = (props) => {
    const [value, setValue] = useState(0)

    return (
        <div style={{ color: "#000", height: "100%", width: "100%", position: "fixed", display: "grid", background: "#00000050", top: "0", left: "0", zIndex: "999999999" }} >

            <div style={{ display: "grid", gap: "10px", margin: "auto", width: "360px", height: "min-content", background: "#fff", borderRadius: "10px", padding: "25px" }}>

                <div style={{ display: "grid", gridTemplateColumns: "auto 50px", height: "50px", width: "100%", gap: "5px", margin: "auto", fontSize: "20px", }}>
                    <p style={{ width: "100%", margin: "auto", textAlign: "left", fontSize: "20px", fontWeight: "bold" }}>Remove {props.symbola} from stake</p>
                    <svg onClick={() => { props.sethubtx(false) }} xmlns="http://www.w3.org/2000/svg" style={{ cursor: "pointer", margin: "auto" }} width="25" height="25" fill="#000" className="bi bi-x" viewBox="0 0 16 16">
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                </div>
           

                <div style={{ display: "grid", gridTemplateColumns: "auto auto", height: "50px", width: "100%", gap: "5px", margin: "auto", fontSize: "20px", }}>
                    <button onClick={() => { props.sethubtx(false) }} style={{ border: "none", cursor: "pointer", display: "grid", background: "#00000020", borderRadius: "10px", height: "50px", width: "100%", margin: "auto", color: "#262626" }}>
                        <p style={{ margin: "auto" }}>Cancel</p>
                    </button>
                    <button onClick={() => {
                        props.nstake(value)
                    }} style={{ border: "none", cursor: "pointer", display: "grid", background: "#00000085", borderRadius: "10px", height: "50px", width: "100%", margin: "auto", color: "#fff" }} >
                        <p style={{ margin: "auto" }}>Confirm</p>
                    </button>
                </div>
            </div>


        </div>

    );
}

export default ModalRemove;