import React, { useState, useEffect, useContext } from 'react';
import { ethers } from 'ethers';
import bnbabi from "../../../abis/bnbabi.json";
import Numberinput from "../../../components/Launchpad/numberInput";
import SAFE from "../../../components/Launchpad/textpar";
import InputBlock from "../../../components/Launchpad/InputBlock";
import DescBlock from "../../../components/Launchpad/descBlock";
import Button from "../../../components/Launchpad/Button";
import Check from "../../../components/Launchpad/Checkbox";
import Checklist from "../../../components/Launchpad/ListCheck";
import StepBar from "../../../components/Launchpad/Stepbar";
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import enUS from 'date-fns/locale/en-US';
import "react-datepicker/dist/react-datepicker.css";
import abi from "../../../abis/presale/preFactory.json";
import AppContext from "../../../framework/helpers/AppContext"; import env from '../../../hooks/data/Config';

import ProjectData from '../../../config';
let projectName = "Martik"
const {
    Name,
    Logo,
    backgroundColor,
    buttonsColor,
    buttonsBorders,
    inputsColor,
    inputsBorders,
    outbuttonsColor,
    Borders,
    outFont,
    innerFont,
    Routers,
    screens
} = ProjectData[window.location.host.split('.')[0] != undefined 
? window.location.host.split('.')[0] == "app" 
? window.location.host.split('.')[0] + "." + window.location.host.split('.')[1] 
: window.location.host.split('.')[0] : "Martik"]

const Web3 = require('web3-eth');
const Create = (props) => {
    const {
        account,
        provider,
        chain,
        chains,
        web3,
        Tokencontract,
        gasTX,
        getID,
        callTX,
        sendTX,
        nSendTX,
        nSendCT
    } = useContext(AppContext)

    const [address, setaddress] = useState(account);
    const [_poolfee, setpoofee] = useState(0);
    const [amountTo, setamountTo] = useState(0);
    const [contract, setcontract] = useState(env.CONTRACTS[getID()].preSaleContract);

    const [step, setstep] = useState(0);

    const [currency, setCurrency] = useState(0);
    const [lop, setlop] = useState(0);
    const [Affiliate, setAffiliate] = useState(0);
    const [white, setwhite] = useState(0);

    const [progressv, setProgress] = useState('0%');
    //step1
    const [tokenB, setTokenB] = useState({
        ct: undefined,
        name: "",
        symbol: "",
        dec: 0,
        supply: "",
        allow: 0
    });

    //step2
    const [presale_rate, setpresale_rate] = useState(0);
    const [softcap, setsoftcap] = useState(0);
    const [hardcap, sethardcap] = useState(0);
    const [minbuy, setminbuy] = useState(0);
    const [maxbuy, setmaxbuy] = useState(0);
    const [refundType, setrefundType] = useState(0);
    const [percentLiquidity, setpercentLiquidity] = useState(0);
    const [listRate, setlistRate] = useState(0);
    const [startTime, setstartTime] = useState(new Date());
    const [endTime, setendTime] = useState(new Date());
    const [liquidity_lockup, setliquidity_lockup] = useState(0);
    //step3
    const [logoURL, setlogoURL] = useState('');
    const [Website, setWebsite] = useState('');
    const [Facebook, setFacebook] = useState('');
    const [Twitter, setTwitter] = useState('');
    const [Github, setGithub] = useState('');
    const [Telegram, setTelegram] = useState('');
    const [Instagram, setInstagram] = useState('');
    const [Discord, setDiscord] = useState('');
    const [Reddit, setReddit] = useState('');
    const [Youtube, setYoutube] = useState('');
    const [Description, setDescription] = useState('');
    //step4
    const [approveBut, setapproveBut] = useState(<Button func={() => {
        if (tokenB.allow > 1) {
            nextstep()
        } else {
            approve()
        }
    }} text={tokenB.allow > 0 ? "NEXT" : "APPROVE"} next={true} />);
    useEffect(() => {
        getamount()
    }, [listRate, presale_rate, hardcap, percentLiquidity, tokenB.allow])
    useEffect(() => {
        setstartTime(_toUTC(new Date()))
        setendTime(_toUTC(new Date()))

    }, [])
    function nextblock(accountBalancemctTB, d) {
        if ((accountBalancemctTB / (10 ** d)).toString().includes('.')) {
            if (accountBalancemctTB.toString().length >= d) {
                return (
                    (accountBalancemctTB / (10 ** d)).toString().split('.')[0] +
                    '.' +
                    (accountBalancemctTB / (10 ** d)).toString().split('.')[1].slice(0, 2)
                );
            } else {
                return (
                    '0.' +
                    '0'.repeat(d - accountBalancemctTB.toString().length) +
                    accountBalancemctTB.toString().slice(0, 2)
                );
            }
        } else {
            if ((accountBalancemctTB / (10 ** d)).toString().includes(',')) {
                if (accountBalancemctTB.toString().length >= d) {
                    return (
                        (accountBalancemctTB / (10 ** d)).toString().split(',')[0] +
                        '.' +
                        (accountBalancemctTB / (10 ** d)).toString().split(',')[1].slice(0, 2)
                    );
                } else {
                    return (
                        '0.' +
                        '0'.repeat(d - accountBalancemctTB.toString().length) +
                        accountBalancemctTB.toString().slice(0, 2)
                    );
                }
            } else {
                return accountBalancemctTB / (10 ** d);
            }
        }
    }

    function nf(e) {
        return ('0' + e).slice(-2)
    }
    function progress() {
        return progressv
    }
    function toUTC(func, dateA) {

        func(dateA)
    } function _toUTC(dateA) {
        const time = new Date(
            dateA.getUTCFullYear()
            + "-" +
            nf(dateA.getUTCMonth() + 1)
            + "-" +
            nf(dateA.getUTCDate())
            + "T" +
            nf(dateA.getUTCHours())
            + ":" +
            nf(dateA.getUTCMinutes())
            + ":00");

        return (Date.parse(time))
    }

    function now() {
        const dateA = new Date()
        const time = new Date(
            dateA.getUTCFullYear()
            + "-" +
            nf(dateA.getUTCMonth() + 1)
            + "-" +
            nf(dateA.getUTCDate())
            + "T" +
            nf(dateA.getUTCHours())
            + ":" +
            nf(dateA.getUTCMinutes())
            + ":00");

        return Date.parse(dateA)
    }
    function returnbignumber(value, dec) {
        let a = ['']
        let e = value + ""
        if (e.toString().includes(",")) {
            a = e.toString().split(",")
            e = ethers.utils.parseUnits(a[0] + "." + a[1], dec).toString()
        } else {
            e = ethers.utils.parseUnits(e, dec).toString()
        }
        return e
    }
    async function create() {
        const ct = await Tokencontract(abi, contract);
        const poolfee = await ct.methods.tax().call()
        const start = parseInt(Date.parse(startTime) / 1000)
        const end = parseInt(Date.parse(endTime) / 1000)

        nSendTX(contract, abi, getID(), 'createLauchPads', poolfee, 0,
            tokenB.ct,
            [
                returnbignumber(presale_rate, tokenB.dec),
                returnbignumber(softcap, 18),
                returnbignumber(hardcap, 18),
                returnbignumber(minbuy, 18),
                returnbignumber(maxbuy, 18),
                refundType.toString(),
                percentLiquidity,
                returnbignumber(listRate, tokenB.dec),
                start.toString(),
                end.toString(),
                liquidity_lockup,
                currency,
                lop,
                Affiliate
            ],
            [
                logoURL,
                Website,
                Facebook,
                Twitter,
                Github,
                Telegram,
                Discord,
                Instagram,
                Reddit,
                Youtube,
                Description,
            ],
            []).then(a => {
                getData(a)
            }).catch(e => {
                console.log(e)
            })
    }
    async function getData(hash) {
        const web3 = new Web3(env.CONTRACTS[getID()].providerURL)
        const obj = await web3.getTransactionReceipt(hash)
        const w = new ethers.providers.JsonRpcProvider(env.CONTRACTS[getID()].providerURL)
        const ct = new ethers.Contract(env.CONTRACTS[getID()].preSaleContract, abi, w)
        let _obj = []
        let contractCreated
        for (let _index = 0; _index < obj.logs.length; _index++) {
            try {
                if (obj.logs[_index].address == env.CONTRACTS[getID()].preSaleContract) {

                    _obj.push({
                        decodelog: ct.interface.parseLog(obj.logs[_index])
                    })
                }
            } catch (error) {

            }
        }
        for (let _index = 0; _index < _obj.length; _index++) {
            if (_obj[_index].decodelog.name == "create") {
                contractCreated = _obj[_index].decodelog.args[0]
            }
        }

        window.location.href = "/pre-sale/" + contractCreated

    }
    async function approve() {
        nSendTX(tokenB.ct, bnbabi, getID(), 'approve', '0', 0, contract, '1' + '0'.repeat(50)).then(a => {
            gettoken(tokenB.ct)
        }).catch(e => {
            //console.log(e)
        })
    }

    async function getamount() {
        const ct = await Tokencontract(abi, contract);
        const poolfee = await ct.methods.tax().call()
        setpoofee(poolfee)
        if (
            presale_rate > 0 &&
            hardcap > 0 &&
            listRate > 0 &&
            percentLiquidity > 50
        ) {
            const bota = await Tokencontract(abi, contract);

            const amount = await bota.methods.padAmounts(
                returnbignumber(presale_rate, tokenB.dec),
                returnbignumber(hardcap, 18),
                returnbignumber(listRate, tokenB.dec),
                percentLiquidity
            ).call()

            setamountTo(amount)
        } else {
            setamountTo(0)
        }

    }
    async function gettoken(ct) {
        if (ct.length <= 0) {

        } else {
            if (ct.length == 42) {
                if (ct.includes("0x")) {
                    let obj = tokenB
                    const bota = await Tokencontract(bnbabi, ct);
                    const name = await bota.methods.name().call()
                    const symbol = await bota.methods.symbol().call()
                    const dec = await bota.methods.decimals().call()
                    const totalSupply = await bota.methods.totalSupply().call()
                    const allow = await bota.methods.allowance(address, contract).call()

                    obj.ct = ct
                    obj.name = name
                    obj.symbol = symbol
                    obj.dec = dec
                    obj.supply = totalSupply
                    obj.allow = allow

                    setapproveBut(<Button func={() => {
                        if (allow > 1) {
                            nextstep()
                        } else {
                            approve()
                        }
                    }} text={allow > 0 ? "NEXT" : "APPROVE"} next={true} />)
                }
            } else {

            }
        }
    }
    function nextstep() {
        setProgress(((step + 1) * 33).toString() + "%")
        setstep(step + 1)
    }

    const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
        <button ref={ref} onClick={onClick} className='inpult-primary'>
            {new Date(value).toUTCString()}
        </button>
    ));
    let modalcreat = <div style={{
        height: "min-content",
        minHeight: "480px",
        width: "100%",
        display: "grid",
        gridTemplateRows: "auto",
        margin: "auto",
        marginTop: "10px",
        marginBottom: "10px",
        background: "#ffffff05",
        borderRadius: "2px",
        border: "1px solid #ffffff01",
        color: "#fff",
        paddingTop: "20px",
        paddingBottom: "20px"
    }}>

        {progress() == "0%" && <div style={{
            height: "min-content",
            width: "90%",
            margin: "auto",
            display: "grid",
            gap: "10px"
        }}>

            <InputBlock title={"Token Address *"} place={"0x123..."} variavel={tokenB.ct} setvariavel={gettoken} desc={"Creation fee "+ nextblock(_poolfee, 18)+" BNB and 5% from " + (currency == 0 ? " BNB" : currency == 1 ? " BUSD" : currency == 2 ? " USDT" : " BNB") + " raised"} />
            <SAFE what={"Name"} result={tokenB.name} />
            <SAFE what={"Symbol"} result={tokenB.symbol} />
            <SAFE what={"Decimals"} result={tokenB.dec} />
            <SAFE what={"Supply"} result={nextblock(tokenB.supply, tokenB.dec)} />
            <div style={{ margin: "auto", marginLeft: "0%", textAlign: "left", color: "#FFF", fontSize: "14px" }}>Currency</div>
            <Check title={"BNB"} id={0} variavel={currency} setvariavel={setCurrency} />
            <Check title={"BUSD"} id={1} variavel={currency} setvariavel={setCurrency} />
            <Check title={"USDT"} id={2} variavel={currency} setvariavel={setCurrency} />
            <div style={{ margin: "auto", marginLeft: "0%", textAlign: "left", color: "#e5e5e5", fontSize: "10px" }}>Users will pay with {(currency == 0 ? " BNB" : currency == 1 ? " BUSD" : currency == 2 ? " USDT" : " BNB")} for you token</div>
            <div style={{ margin: "auto", marginLeft: "0%", textAlign: "left", color: "#FFF", fontSize: "14px" }}>Affiliate Program</div>
            <Check title={"Disable Affiliate"} id={0} variavel={Affiliate} setvariavel={setAffiliate} />
            <Check title={"Enable Affiliate"} id={Affiliate == 0 ? 1 : Affiliate} variavel={Affiliate} setvariavel={setAffiliate} />
            {Affiliate != 0 && <div style={{ height: "min-content", width: "100%", display: "grid", gridTemplateColumns: "auto", gap: "2px", margin: "auto" }}>
                <p style={{ margin: "auto", marginLeft: "0%", textAlign: "left", color: "#FFF", fontSize: "14px" }}>{"% to affiliate "}</p>
                <input onChange={(e) => { e.target.value <= 5 ? setAffiliate(e.target.value) : setAffiliate(Affiliate) }} placeholder={"%"} value={Affiliate} style={{ fontSize: "15px", outline: "none", color: "#999", textAlign: "left", height: "35px", width: "100%", display: "grid", gridTemplateColumns: "auto", margin: "auto", border: "1px solid #22222250", background: "#00000050", borderRadius: "10px", paddingLeft: "10px" }}></input>
                <p style={{ margin: "auto", marginLeft: "0%", textAlign: "left", color: "#ddd", fontSize: "10px" }}>{"MAX 5%"}</p>
            </div>}
            {approveBut}

        </div>}
        {progress() == "33%" && <div style={{
            height: "min-content",
            width: "90%",
            margin: "auto",
            display: "grid",
            gap: "20px"
        }}>

            <InputBlock title={"Presale rate *"} place={"0"} variavel={presale_rate} setvariavel={setpresale_rate} desc={"How much 1 " + (currency == 0 ? " BNB" : currency == 1 ? " BUSD" : currency == 2 ? " USDT" : " BNB") + " get from tokens"} />
            <div style={{ margin: "auto", marginLeft: "0%", textAlign: "left", color: "#FFF", fontSize: "14px" }}>Whitelist</div>
            <Check title={"Disable"} id={0} variavel={white} setvariavel={setwhite} />
            <Check title={"Enable"} id={1} variavel={white} setvariavel={setwhite} />
            <div style={{ margin: "auto", marginLeft: "0%", textAlign: "left", color: "#e5e5e5", fontSize: "10px" }}>You can enable/disable whitelist anytime.</div>


            <div style={{ height: "min-content", width: "100%", display: "grid", gridTemplateColumns: "auto auto", gap: "2px", margin: "auto" }}>
                <InputBlock title={"Softcap  " + (currency == 0 ? " BNB" : currency == 1 ? " BUSD" : currency == 2 ? " USDT" : " BNB") + "*"} place={"0"} variavel={softcap} setvariavel={setsoftcap} desc={"Minimum capitalization"} />
                <InputBlock title={"Hardcap  " + (currency == 0 ? " BNB" : currency == 1 ? " BUSD" : currency == 2 ? " USDT" : " BNB") + "*"} place={"0"} variavel={hardcap} setvariavel={sethardcap} desc={"Max capitalization"} />

            </div>
            <div style={{ height: "min-content", width: "100%", display: "grid", gridTemplateColumns: "auto auto", gap: "2px", margin: "auto" }}>
                <InputBlock title={"Minimun buy  " + (currency == 0 ? " BNB" : currency == 1 ? " BUSD" : currency == 2 ? " USDT" : " BNB") + "*"} place={"0"} variavel={minbuy} setvariavel={setminbuy} desc={"Minimum buy per wallet"} />
                <InputBlock title={"Maximum buy  " + (currency == 0 ? " BNB" : currency == 1 ? " BUSD" : currency == 2 ? " USDT" : " BNB") + "*"} place={"0"} variavel={maxbuy} setvariavel={setmaxbuy} desc={"Max buy per wallet"} />
            </div>
            <div style={{ display: "grid", gridTemplateColumns: "repeat(auto-fit, minmax(280px, 1fr)", width: "100%" }}>
                <Checklist title={"Refund Type *"} untitle={"Refund"} ops={['Refund', 'Burn']} setvariavel={setrefundType} />
                <Checklist title={"Router *"} untitle={"Pancake Swap"} ops={['Pancake Swap']} setvariavel={() => { }
                } />
            </div>
            <div style={{ height: "min-content", width: "100%", display: "grid", gridTemplateColumns: "auto auto", gap: "2px", margin: "auto" }}>
                <InputBlock title={"Liquidify Percent (%)*"} place={"0"} variavel={percentLiquidity} setvariavel={setpercentLiquidity} desc={"How much from capitalization going to liquidify?"} />
                <InputBlock title={"Listing rate *"} place={"0"} variavel={listRate} setvariavel={setlistRate} desc={"After Finish 1 BNB buy how much tokens?"} />
            </div>
            <div style={{ display: "grid", gridTemplateColumns: "repeat(auto-fit, minmax(280px, 1fr)", width: "100%" }}>
                <div style={{ height: "min-content", width: "100%", display: "grid", gridTemplateColumns: "auto", gap: "2px", margin: "auto", marginTop: "10px" }}>
                    <p>Start time </p>
                    <DatePicker
                        utcOffset={0}
                        selected={startTime}
                        onChange={(date) => toUTC(setstartTime, date)}
                        touchUi={true} locale="en-US"
                        showTimeSelect
                        timeFormat="HH:mm aa"
                        timeIntervals={5}
                        timeCaption="time"
                        dateFormat="MMMM d, yyyy h:mm aa"
                        customInput={<CustomInput />}
                    />
                    <p style={{ margin: "auto", marginLeft: "0%", textAlign: "left", color: "#999", fontSize: "10px" }}>Sale start time</p>
                </div>
                <div style={{ height: "min-content", width: "100%", display: "grid", gridTemplateColumns: "auto", gap: "2px", margin: "auto", marginTop: "10px" }}>
                    <p>End time </p>
                    <DatePicker
                        selected={endTime}
                        onChange={(date) => toUTC(setendTime, date)}
                        touchUi={true} locale="en-US"
                        showTimeSelect
                        timeFormat="HH:mm aa"
                        timeIntervals={5}
                        timeCaption="time"
                        dateFormat="MMMM d, yyyy h:mm aa"
                        customInput={<CustomInput />}
                    />
                    <p style={{ margin: "auto", marginLeft: "0%", textAlign: "left", color: "#999", fontSize: "10px" }}>Sale End time</p>
                </div>
            </div>
            <InputBlock title={"Liquidity lockup (minutes)"} place={"0"} variavel={liquidity_lockup} setvariavel={setliquidity_lockup} desc={"How much time liquidify is lock by " + Name + " lock"} />

            <div style={{ height: "min-content", width: "100%", display: "grid", gridTemplateColumns: "auto", gap: "2px", margin: "auto" }}>
                <p style={{ margin: "auto", textAlign: "center", fontSize: "14px" }}>This launch request: <br />
                    {nextblock(amountTo, tokenB.dec)} {tokenB.symbol}
                </p>
            </div>
            <div style={{ height: "min-content", width: "100%", display: "grid", gridTemplateColumns: "auto", gap: "2px", margin: "auto" }}>
                <p style={{ margin: "auto", textAlign: "center", color: "#999", fontSize: "14px" }}>* Please check all data before continue *

                </p>
            </div>
            <div className='flex items-center justify-center gap-4 mb-6'>

                <Button func={() => {
                    setProgress(((step - 1) * 33).toString() + "%")
                    setstep(step - 1);
                }} text={'BACK'} next={false} />
                <Button func={() => {
                    if (
                        returnbignumber(presale_rate, tokenB.dec) > 0 &&
                        returnbignumber(softcap, 18) > 0 &&
                        returnbignumber(minbuy, 18) > 0 &&
                        maxbuy > minbuy &&
                        percentLiquidity > 50 &&
                        returnbignumber(listRate, tokenB.dec) > 0 &&
                        parseInt(Date.parse(startTime) / 1000) > parseInt(now() / 1000) &&
                        parseInt(Date.parse(endTime) / 1000) > parseInt(Date.parse(startTime) / 1000) &&
                        liquidity_lockup > 5
                    ) {
                        nextstep()
                    } else {
                        alert(
                            presale_rate <= 0 ? "Presale value invalid" :
                                softcap <= 0 ? "SoftCap value invalid" :
                                    minbuy <= 0 ? "Minimun buy value invalid" :
                                        maxbuy <= minbuy ? "Minimun buy > or equal Maximum buy" :
                                            percentLiquidity <= 50 ? "Liquidfy Percent need be > 50%" :
                                                listRate <= 0 ? "List rate value invalid" :
                                                    parseInt(Date.parse(startTime) / 1000) <= parseInt(now() / 1000) ? "start after now" + parseInt(now() / 1000) + " " + parseInt(Date.parse(startTime) / 1000) :
                                                        parseInt(Date.parse(endTime) / 1000) <= parseInt(Date.parse(startTime) / 1000) ? "finish time < start time" :
                                                            liquidity_lockup <= 5 ? "lock on minimum 5 minutes" : "Strange error"
                        )
                    }

                }} text={'NEXT'} next={true} />
            </div>


        </div>}
        {progress() == "66%" && <div style={{
            height: "min-content",
            width: "90%",
            margin: "auto",
            display: "grid",
            gap: "20px"
        }}>

            <div style={{ height: "min-content", width: "100%", display: "grid", gridTemplateColumns: "auto auto", gap: "8px", margin: "auto" }}>
                <InputBlock title={"Logo URL *"} place={"https://..."} variavel={logoURL} setvariavel={setlogoURL} desc={""} />
                <InputBlock title={"Website *"} place={"https://..."} variavel={Website} setvariavel={setWebsite} desc={""} />
            </div>
            <div style={{ height: "min-content", width: "100%", display: "grid", gridTemplateColumns: "auto auto", gap: "8px", margin: "auto" }}>
                <InputBlock title={"Facebook"} place={"https://..."} variavel={Facebook} setvariavel={setFacebook} desc={""} />
                <InputBlock title={"Twitter"} place={"https://..."} variavel={Twitter} setvariavel={setTwitter} desc={""} />
            </div>
            <div style={{ height: "min-content", width: "100%", display: "grid", gridTemplateColumns: "auto auto", gap: "8px", margin: "auto" }}>
                <InputBlock title={"Github"} place={"https://..."} variavel={Github} setvariavel={setGithub} desc={""} />
                <InputBlock title={"Telegram"} place={"https://..."} variavel={Telegram} setvariavel={setTelegram} desc={""} />
            </div>
            <div style={{ height: "min-content", width: "100%", display: "grid", gridTemplateColumns: "auto auto", gap: "8px", margin: "auto" }}>
                <InputBlock title={"Instagram"} place={"https://..."} variavel={Instagram} setvariavel={setInstagram} desc={""} />
                <InputBlock title={"Discord"} place={"https://..."} variavel={Discord} setvariavel={setDiscord} desc={""} />
            </div>
            <InputBlock title={"Reddit"} place={"https://..."} variavel={Reddit} setvariavel={setReddit} desc={""} />
            <InputBlock title={"Youtube Video or banner URL"} place={"https://..."} variavel={Youtube} setvariavel={setYoutube} desc={""} />
            <DescBlock title={"Description"} place={""} variavel={Description} setvariavel={setDescription} desc={""} />


            <div className='flex items-center justify-center gap-4'>
                <Button func={() => {
                    setProgress(((step - 1) * 33).toString() + "%")
                    setstep(step - 1);
                }} text={'BACK'} next={false} />
                <Button func={() => { nextstep() }} text={'NEXT'} next={true} />
            </div>



        </div>}
        {progress() == "99%" && <div style={{
            height: "min-content",
            width: "90%",
            margin: "auto",
            display: "grid",
            gap: "20px"
        }}>
            <SAFE what={"Total token"} result={nextblock(amountTo, tokenB.dec)} />
            <SAFE what={"Factory Address"} result={contract} />
            <SAFE what={"Token name"} result={tokenB.name} />
            <SAFE what={"Token symbol"} result={tokenB.symbol} />
            <SAFE what={"Token decimals"} result={tokenB.dec} />
            <SAFE what={"Presale rate"} result={presale_rate} />
            <SAFE what={"Listing rate"} result={listRate} />
            <SAFE what={"Sale method"} result={"Pre-Sale"} />
            <SAFE what={"Softcap"} result={softcap} />
            <SAFE what={"Hardcap"} result={hardcap} />
            <SAFE what={"Unsold tokens"} result={refundType == 1 ? "Burn" : 'Refund'} />
            <SAFE what={"Minimum buy"} result={minbuy} />
            <SAFE what={"Maximum buy"} result={maxbuy} />
            <SAFE what={"Liquidity Percent"} result={percentLiquidity} />
            <SAFE what={"Start time"} result={(startTime).toString()} />
            <SAFE what={"End time"} result={(endTime).toString()} />
            <SAFE what={"Liquidity lockup time"} result={liquidity_lockup + " minutes"} />
            <SAFE what={"Website"} result={Website} />
            <div className='w-full border border-gray-400 rounded-lg bg-slate-200 mt-4 h-14 items-center justify-center flex dark:bg-gray-700/40 mb-4'>
                <p className='text-xs text-primary dark:text-slate-200 items-center justify-center px-2 flex'>You have to exclude transfer fee for {contract}.</p>
            </div>
            <div className='flex items-center justify-center gap-4 mb-4'>
                <Button func={() => {
                    setProgress(((step - 1) * 33).toString() + "%")
                    setstep(step - 1);
                }} text={'BACK'} next={false} />
                <Button func={() => { create() }} text={'PUBLISH'} next={true} />
            </div>



        </div>}
    </div>

    return (
        <div style={{
            height: "min-content",
            width: "100%",
            display: "grid",
            margin: "auto",
            marginTop: "auto",
            color: "#fff",

        }}>

            <div style={{
                height: "min-content",
                width: "100%",
                listStyleType: "none",
                display: "grid",
                gridTemplateColumns: "50px auto"
            }}>
                <div style={{
                    position: " relative",
                    width: "10px",

                    height: "90%",
                    backgroundColor: "#888",
                    margin: "auto",
                    marginTop: "25px"
                }}>
                    <div style={{
                        position: "absolute",
                        width: "30px",
                        height: "30px",
                        bottom: "99%",
                        left: "-10px",
                        backgroundColor: progress() == "0%" ? "#ffffff" : "#ffffff50",
                        borderRadius: "360px",
                        display: "grid",
                        zIndex: "2"
                    }}>
                        <div style={{
                            width: "27px",
                            height: "27px",
                            margin: "auto",
                            backgroundColor: "#000",
                            borderRadius: "360px",
                            color: "#ffffff50",
                            display: "grid"
                        }}>
                            <p style={{ margin: "auto" }}>1</p>

                        </div>
                    </div>
                    <div style={{
                        position: "absolute",
                        width: "100%",
                        height: progress(),
                        backgroundColor: "#ffffff"
                    }}></div>
                    <div style={{
                        position: "absolute",
                        width: "30px",
                        height: "30px",
                        bottom: "66%",
                        right: "-10px",
                        right: "-10px",
                        backgroundColor: progress() == "33%" ? "#ffffff" : "#ffffff50",
                        borderRadius: "360px",
                        display: "grid",
                    }}>
                        <div style={{
                            width: "27px",
                            height: "27px",
                            margin: "auto",
                            backgroundColor: "#000",
                            borderRadius: "360px",
                            color: "#ffffff50",
                            display: "grid"
                        }}>
                            <p style={{ margin: "auto" }}>2</p>
                        </div>

                    </div>
                    <div style={{
                        position: "absolute",
                        width: "30px",
                        height: "30px",
                        bottom: "33%",
                        right: "-10px",
                        backgroundColor: progress() == "66%" ? "#ffffff" : "#ffffff50",
                        borderRadius: "360px",
                        display: "grid",
                    }}>
                        <div style={{
                            width: "27px",
                            height: "27px",
                            margin: "auto",
                            backgroundColor: "#000",
                            borderRadius: "360px",
                            color: "#ffffff50",
                            display: "grid"
                        }}>
                            <p style={{ margin: "auto" }}>3</p>
                        </div>

                    </div>
                    <div style={{
                        position: "absolute",
                        width: "30px",
                        height: "30px",
                        bottom: "0%",
                        right: "-10px",
                        backgroundColor: progress() == "99%" ? "#ffffff" : "#ffffff50",
                        borderRadius: "360px",
                        display: "grid",
                    }}>
                        <div style={{
                            width: "27px",
                            height: "27px",
                            margin: "auto",
                            backgroundColor: "#000",
                            borderRadius: "360px",
                            color: "#ffffff50",
                            display: "grid"
                        }}>
                            <p style={{ margin: "auto" }}>4</p>
                        </div>

                    </div>
                </div>
                <div>
                    <p style={{ margin: "auto", marginLeft: "0", fontWeight: "bold", fontSize: "15px", width: "100%", textAlign: "left" }}>{progress() == '0%' && 'Verify Token'}{progress() == '33%' && 'DeFi Launchpad Info'}{progress() == '66%' && 'Add Additional Info'}{progress() == '100%' && 'Finish'}</p>
                    {modalcreat}
                </div>

            </div>

        </div>

    );


}
export default Create;